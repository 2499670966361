import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import ExpenseDisplay from "./ExpenseDisplay";
import OFDTitle from "../layout/OFDTitle";

const ExpenseDetails = ({
  year,
  expenses,
  income,
  title,
  open,
  onClose,
  onChange,
}) => {
  const { getLabel } = useLocalization();
  const { sort } = useData();

  const [sortedExpenses, setSortedExpenses] = useState(undefined);
  const [sortedIncome, setSortedIncome] = useState(undefined);

  useEffect(() => {
    if (Array.isArray(expenses)) {
      setSortedExpenses(() => {
        return sort(expenses, "expenseDate", "desc");
      });
    }

    if (Array.isArray(income)) {
      setSortedIncome(() => {
        return sort(income, "expenseDate", "desc");
      });
    }
  }, [expenses, income]);

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="expenses"
      >
        <Box sx={{ padding: "1rem" }}>
          <Box sx={{ marginBottom: "1rem" }}>
            <OFDTitle title={title} />
          </Box>
          <Stack spacing={2}>
            {sortedIncome !== undefined ? (
              Array.isArray(sortedIncome) && sortedIncome.length > 0 ? (
                <Stack spacing={1}>
                  <Typography variant="h6">{getLabel("income")}</Typography>
                  {sortedIncome.map((income) => (
                    <ExpenseDisplay
                      key={income.id}
                      expense={income}
                      onChange={onChange}
                    />
                  ))}
                </Stack>
              ) : null
            ) : null}

            {sortedExpenses !== undefined ? (
              Array.isArray(sortedExpenses) && sortedExpenses.length > 0 ? (
                <Stack spacing={1}>
                  <Typography variant="h6">{getLabel("expenses")}</Typography>
                  {sortedExpenses.map((expense) => (
                    <ExpenseDisplay
                      key={expense.id}
                      expense={expense}
                      onChange={onChange}
                    />
                  ))}
                </Stack>
              ) : null
            ) : null}
          </Stack>
        </Box>
      </OFDModal>
    </>
  );
};

export default ExpenseDetails;
