import { useEffect, useState } from "react";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  isBefore,
  isAfter,
  isEqual,
  parse,
} from "date-fns";

import useMember from "../../../../hooks/useMember";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDAvatar from "../../../ui/OFDAvatar";
import OFDIcon from "../../../ui/OFDIcon";

import MemberChore from "./MemberChore";
import { ScheduleRounded } from "@mui/icons-material";

const ChoresByMember = ({
  memberChores,
  choreList,
  onChange,
  forDate,
  hideHeader,
}) => {
  const { getMemberAvatar, getMember, getMemberColor } = useMember();
  const { getLabel, getMessage } = useLocalization();

  const [open, setOpen] = useState(false);
  // const [chores, setChores] = useState([]);

  const [dailyChores, setDailyChores] = useState();
  const [weeklyChores, setWeeklyChores] = useState();
  const [monthlyChores, setMonthlyChores] = useState();
  const [oneTimeChores, setOneTimeChores] = useState();
  const [hasChores, setHasChores] = useState(false);

  useEffect(() => {
    if (!memberChores || !memberChores.chores) return;

    if (hideHeader) {
      setOpen(true);
    }

    buildChores();
  }, [memberChores, forDate]);

  const buildChores = () => {
    setHasChores(false);
    setDailyChores(getChoresForFrequency("daily"));
    setWeeklyChores(getChoresForFrequency("weekly"));
    setMonthlyChores(getChoresForFrequency("monthly"));
    setOneTimeChores(getChoresForFrequency("onetime"));
  };

  const getChoresForFrequency = (frequency) => {
    const chores = choreList.filter((c) => c.frequency === frequency);

    if (!chores) {
      return [];
    }

    let list = [];

    let startDate = null,
      endDate = null;

    switch (frequency) {
      case "daily":
        startDate = startOfDay(forDate);
        endDate = endOfDay(forDate);
        break;

      case "weekly":
        startDate = startOfWeek(forDate);
        endDate = endOfWeek(forDate);
        break;

      case "monthly":
        startDate = startOfMonth(forDate);
        endDate = endOfMonth(forDate);
        break;

      case "onetime":
        startDate = startOfDay(forDate);
        endDate = endOfDay(forDate);
        break;
    }

    if (!startDate || !endDate) return [];

    const choreIds = Object.keys(chores);

    for (const id of choreIds) {
      const chore = chores[id];

      const activity = memberChores.chores[chore.id];
      if (!activity) continue;

      for (const scheduledChore of activity) {
        const scheduledDate = parse(
          scheduledChore.date,
          "yyyyMMdd",
          new Date()
        );

        if (
          isEqual(scheduledDate, startDate) ||
          isEqual(scheduledDate, endDate)
        ) {
          list.push({
            chore,
            activity: scheduledChore,
            memberId: memberChores.memberId,
          });
          break;
        }

        if (isAfter(forDate, startDate) && isBefore(forDate, endDate)) {
          list.push({
            chore,
            activity: scheduledChore,
            memberId: memberChores.memberId,
          });
          break;
        }
      }
    }

    if (list.length > 0) {
      setHasChores(true);
    }

    return list;
  };

  const getSummary = () => {
    if (!dailyChores || !weeklyChores || !monthlyChores || !oneTimeChores)
      return "";

    let numChores =
      dailyChores.length +
      weeklyChores.length +
      monthlyChores.length +
      oneTimeChores.length;

    let completedChores = 0;
    for (const chore of dailyChores) {
      if (chore.activity.status !== "outstanding") completedChores++;
    }
    for (const chore of weeklyChores) {
      if (chore.activity.status !== "outstanding") completedChores++;
    }
    for (const chore of monthlyChores) {
      if (chore.activity.status !== "outstanding") completedChores++;
    }
    for (const chore of oneTimeChores) {
      if (chore.activity.status !== "outstanding") completedChores++;
    }

    return `${completedChores.toString()} / ${numChores.toString()}`;
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "4px",
          borderRadius: "4px",
        }}
      >
        {!hideHeader ? (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{ display: "flex" }}
              gap={1}
            >
              <OFDAvatar {...getMemberAvatar(memberChores.memberId)} />
              <Typography sx={{ fontWeight: 900 }}>{`${
                getMember(memberChores.memberId).name
              } (${getSummary()})`}</Typography>
            </Box>

            <OFDIcon
              name={open ? "arrowUp" : "arrowDown"}
              color="grey"
              onClick={() => setOpen(!open)}
            />
          </Box>
        ) : null}

        {open ? (
          <Box sx={{ marginTop: "10px" }}>
            {hasChores ? (
              <Stack spacing={1}>
                {Array.isArray(dailyChores) && dailyChores.length > 0 ? (
                  <Box>
                    <Typography
                      variant="h6"
                      align="center"
                    >
                      {getLabel("daily")}
                    </Typography>

                    <Stack spacing={1}>
                      {dailyChores.map((chore, index) => (
                        <MemberChore
                          key={`chore_${index}`}
                          memberChore={chore}
                          choreList={choreList}
                          onChange={onChange}
                          forDate={forDate}
                        />
                      ))}
                    </Stack>
                  </Box>
                ) : null}

                {Array.isArray(weeklyChores) && weeklyChores.length > 0 ? (
                  <Box>
                    <Typography
                      variant="h6"
                      align="center"
                    >
                      {getLabel("weekly")}
                    </Typography>

                    <Stack spacing={1}>
                      {weeklyChores.map((chore, index) => (
                        <MemberChore
                          key={`chore_${index}`}
                          memberChore={chore}
                          choreList={choreList}
                          onChange={onChange}
                          forDate={forDate}
                        />
                      ))}
                    </Stack>
                  </Box>
                ) : null}

                {Array.isArray(monthlyChores) && monthlyChores.length > 0 ? (
                  <Box>
                    <Typography
                      variant="h6"
                      align="center"
                    >
                      {getLabel("monthly")}
                    </Typography>

                    <Stack spacing={1}>
                      {monthlyChores.map((chore, index) => (
                        <MemberChore
                          key={`chore_${index}`}
                          memberChore={chore}
                          choreList={choreList}
                          onChange={onChange}
                          forDate={forDate}
                        />
                      ))}
                    </Stack>
                  </Box>
                ) : null}
              </Stack>
            ) : (
              <Typography>{getMessage("noChoresForDate")}</Typography>
            )}
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ChoresByMember;
