import { useEffect, useState } from "react";
import {
  Alert,
  Modal,
  Box,
  Slide,
  Typography,
  IconButton,
} from "@mui/material";

import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";
import useWindow from "../../hooks/useWindow";

import OFDIcon from "../ui/OFDIcon";
import OFDMessage from "../ui/OFDMessage";

const OFDModal = ({
  title,
  icon,
  open,
  onClose,
  children,
  variant,
  color,
  shade,
  message,
  resetMessage,
  timeout = 3000,
  allowFullScreen,
}) => {
  const { getTitle, getMessage } = useLocalization();
  const { memberColor } = useMember();
  const { windowHeight, windowWidth, isMobile } = useWindow();

  const [modalHeight, setModalHeight] = useState("100%");
  const [modalWidth, setModalWidth] = useState("100%");
  const [modalStatus, setModalStatus] = useState("default");
  const [modalStyle, setModalStyle] = useState({});

  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setDefaultSize();
  }, [open, windowHeight, windowWidth, isMobile]);

  useEffect(() => {
    if (!message || !message.message || !message.displayToUser) {
      setShowToast(false);

      return;
    }

    setShowToast(true);

    const clear = setTimeout(() => {
      clearToastMessage();
      if (resetMessage) resetMessage();
    }, timeout);

    return () => {
      clearTimeout(clear);
    };
  }, [message]);

  const clearToastMessage = async () => {
    setShowToast(false);
  };

  const setDefaultSize = () => {
    setModalStyle({
      height: isMobile ? `${windowHeight}px` : `${windowHeight * 0.9}px`,
      width: isMobile ? "100%" : `${windowWidth * 0.5}px`,
    });
    setModalStatus("default");
  };

  const setFullScreen = () => {
    setModalStyle({
      height: windowHeight,
      width: windowWidth,
      paddingLeft: isMobile ? "0px" : "100px",
    });
    setModalStatus("fullscreen");
  };

  const handleFullScreen = () => {
    if (modalStatus === "default") {
      setFullScreen();
    } else {
      setDefaultSize();
    }
  };

  return (
    <>
      <Modal open={Boolean(open)}>
        <Box>
          {showToast ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 9000,
                width: "100%",
              }}
            >
              <Alert severity={message.severity}>
                {getMessage(message.message)}
              </Alert>
            </Box>
          ) : null}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // paddingTop: isMobile ? "0px" : "3rem",
              // paddingBottom: isMobile ? "0px" : "3rem",
              // paddingLeft: isMobile ? "0px" : "100px",
            }}
          >
            <Box
              sx={{
                ...modalStyle,
              }}
            >
              <Slide
                in={open}
                timeout={1000}
                mountOnEnter
                unmountOnExit
              >
                {variant === "basic" ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#ebebeb",
                      overflow: "auto",
                    }}
                  >
                    {children}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      // height: isMobile
                      //   ? `${windowHeight}px`
                      //   : `${windowHeight * 0.9}px`,
                      // width: "100%",
                      overflow: "auto",
                      position: "relative",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <Box
                      sx={{
                        position: "sticky",
                        top: 0,
                        left: 0,
                        right: 0,
                        zIndex: 2100,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          ...memberColor({
                            color: color ? color : null,
                            shade: shade ? shade : 500,
                          }),
                          minHeight: "4rem",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          gap={2}
                        >
                          {icon ? (
                            <OFDIcon
                              name={icon}
                              size="medium"
                              style={{ backgroundColor: memberColor().color }}
                            />
                          ) : null}

                          <Typography variant="h5">
                            {getTitle(title)}
                          </Typography>
                        </Box>

                        <Box
                          sx={{ display: "flex" }}
                          gap={2}
                        >
                          {!isMobile ? (
                            <IconButton
                              sx={{
                                backgroundColor: "#ffffff",
                                padding: "4px",
                              }}
                              onClick={handleFullScreen}
                            >
                              <OFDIcon
                                name="fullscreen"
                                color={color ? color : null}
                                shade={shade ? shade : null}
                              />
                            </IconButton>
                          ) : null}

                          {onClose ? (
                            <IconButton
                              sx={{
                                backgroundColor: "#ffffff",
                                padding: "4px",
                              }}
                              onClick={onClose}
                            >
                              <OFDIcon
                                name="close"
                                color={color ? color : null}
                                shade={shade ? shade : null}
                              />
                            </IconButton>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: `${windowHeight - 16 * 4}px`,
                        overflowY: "auto",
                        backgroundColor: "#ebebeb",
                      }}
                    >
                      <Box
                        sx={{
                          height: `${windowHeight - 16 * 4}px`,
                          width: "100%",
                          overflow: "auto",
                          zIndex: 2000,
                        }}
                      >
                        {children}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Slide>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default OFDModal;
