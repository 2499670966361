import { useEffect, useState } from "react";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useDocument from "../../hooks/useDocument";

import { Box } from "@mui/material";

import OFDTitle from "../layout/OFDTitle";
import Search from "../forms/Search";
import SearchCriteria from "../forms/SearchCriteria";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";

import DocumentUpload from "../editors/DocumentUpload";
import FolderDisplay from "../display/FolderDisplay";
import DocumentsFolder from "../editors/DocumentsFolder";
import DocumentViewer from "../display/DocumentViewer";

import Loading from "../layout/Loading";

const Documents = () => {
  const {
    documents,
    getDocuments,
    searchCriteria,
    setSearchCriteria,
    getSearchFields,
    applySearchCriteria,
    deleteDocument,
    deleteFolder,
    getCurrentFolderContents,
    currentFolderId,
    setCurrentFolderId,
    documentMessage,
    setDocumentMessage,
    addFolder,
    currentPath,
    currentDocument,
    setCurrentDocument,
    getFolderStructure,
    moveDocument,
    moveFolder,
    renameDocument,
    renameFolder,
    updateSecurity,
  } = useDocument();

  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();

  const [openUpload, setOpenUpload] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setEntityName("Document");
    setOpenUpload(false);
    getDocuments();
  }, [refresh]);

  useEffect(() => {
    if (toolbarAction === "uploadDocument") {
      setOpenUpload(true);
    }
    if (toolbarAction === "addFolder") {
      setOpenFolder(true);
    }
    if (toolbarAction === "search") {
      setOpenSearch(true);
    }
    resetToolbarAction();
  }, [toolbarAction]);

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleClearSearch = () => {
    setSearchCriteria(null);
    setOpenSearch(false);
  };

  const handleSearch = (newSearch) => {
    setSearchCriteria(newSearch);
    setOpenSearch(false);
  };

  const handleMenuClick = (action, entity) => {
    // setSelectedEntity(entity);
    // if (action === "delete") {
    //   setOpenDeleteConfirmation(true);
    // }
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      let results;

      if (results.isSuccessful) {
        await getDocuments();
      }
    }
    setOpenDeleteConfirmation(false);
  };

  const handleFolderSelect = (selectedFolder) => {
    setCurrentFolderId(selectedFolder.id);
  };

  const handleUpload = async () => {
    setOpenUpload(false);
    await getDocuments();
  };

  const handleCloseFolder = () => {
    setOpenFolder(false);
  };

  const handleSaveFolder = async (newFolder) => {
    const results = await addFolder(newFolder);

    if (results.isSuccessful) {
      setOpenFolder(false);
    }
  };

  const handleDocumentSelect = (selectedDocument) => {
    setCurrentDocument(selectedDocument);
    setOpenViewer(true);
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
    setCurrentDocument(null);
  };

  const handleDeleteDocument = async (document) => {
    await deleteDocument(document);
  };

  const handleMoveDocument = async (document, newFolderId) => {
    await moveDocument(document, newFolderId);
  };

  const handleDeleteFolder = async (folder) => {
    await deleteFolder(folder);
  };

  const handleMoveFolder = async (folder, newFolderId) => {
    await moveFolder(folder, newFolderId);
  };

  const handleRenameDocument = async (document, newName) => {
    await renameDocument(document, newName);
  };

  const handleRenameFolder = async (folder, newName) => {
    await renameFolder(folder, newName);
  };

  const handleSecurityChange = async (entityName, document, newSecurity) => {
    await updateSecurity(entityName, document, newSecurity);
  };

  return (
    <>
      <OFDTitle title="documents" />

      <SearchCriteria
        searchCriteria={searchCriteria}
        onClear={handleClearSearch}
      />

      {documents !== undefined ? (
        <Box sx={{ marginBottom: "2rem" }}>
          <FolderDisplay
            folder={documents ? getCurrentFolderContents() : []}
            path={currentPath}
            onFolderSelect={handleFolderSelect}
            onDocumentSelect={handleDocumentSelect}
            onDocumentDelete={handleDeleteDocument}
            onDocumentMove={handleMoveDocument}
            onDocumentRename={handleRenameDocument}
            onFolderDelete={handleDeleteFolder}
            onFolderMove={handleMoveFolder}
            onFolderRename={handleRenameFolder}
            getFolderStructure={getFolderStructure}
            onSecurityChange={handleSecurityChange}
          />
        </Box>
      ) : (
        <Loading />
      )}

      {openUpload ? (
        <DocumentUpload
          open={openUpload}
          onClose={() => {
            setOpenUpload(false);
          }}
          onUpload={handleUpload}
          folderId={currentFolderId}
          entityName="Document"
        />
      ) : null}

      {openFolder ? (
        <DocumentsFolder
          open={openFolder}
          onClose={handleCloseFolder}
          onSave={handleSaveFolder}
        />
      ) : null}

      {openViewer ? (
        <DocumentViewer
          open={openViewer}
          onClose={handleCloseViewer}
          document={currentDocument}
        />
      ) : null}

      {openSearch ? (
        <Search
          open={openSearch}
          searchCriteria={searchCriteria}
          onClose={handleSearchClose}
          searchFields={getSearchFields()}
          onSearch={handleSearch}
        />
      ) : null}

      <OFDMessage
        message={message}
        onClose={() => {
          setMessage(null);
        }}
      />

      <OFDMessage
        message={documentMessage}
        onClose={() => {
          setDocumentMessage(null);
        }}
      />

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_document"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default Documents;
