import { useEffect, useState } from "react";
import { startOfWeek, startOfToday } from "date-fns";

import useCalendar from "../../../../hooks/useCalendar";
import useWindow from "../../../../hooks/useWindow";
import usePage from "../../../../hooks/usePage";

import { Box } from "@mui/material";

import OFDWeekOf from "../../../ui/OFDWeekOf";
import MemberToDo from "./MemberToDo";

const ToDoDashboard = ({ height }) => {
  const { getCalendarForDashboard } = useCalendar();
  const { windowWidth } = useWindow();
  const { refresh } = usePage();

  const [todoData, setToDoData] = useState(undefined);
  const [weekOf, setWeekOf] = useState(undefined);
  const [memberToDo, setMemberToDo] = useState(undefined);

  useEffect(() => {
    setWeekOf(startOfWeek(startOfToday()));
  }, []);

  useEffect(() => {
    if (!weekOf) return;

    getData();
  }, [weekOf, refresh]);

  const getData = async () => {
    const results = await getCalendarForDashboard(weekOf, "todo");

    if (results.isSuccessful) {
      setToDoData(results.data);
    } else {
      setToDoData(null);
    }
  };

  useEffect(() => {
    if (todoData === undefined) return;

    splitToDoData();
  }, [todoData]);

  const splitToDoData = () => {
    if (todoData === null) {
      setMemberToDo([]);
      return;
    }

    let byMember = [];

    for (const todoItem of todoData) {
      let memberEntry;
      let memberIndex = byMember.findIndex(
        (item) => item.memberId === todoItem.memberId
      );

      if (memberIndex > -1) {
        memberEntry = byMember[memberIndex];
      } else {
        memberEntry = {
          memberId: todoItem.memberId,
          items: [],
        };
      }

      memberEntry.items.push(todoItem);

      if (memberIndex > -1) {
        byMember[memberIndex] = memberEntry;
      } else {
        byMember.push(memberEntry);
      }
    }

    setMemberToDo(byMember);
  };

  if (weekOf === undefined) return;
  if (memberToDo === undefined) return;

  return (
    <Box sx={{ height: { height } }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <OFDWeekOf
          value={weekOf}
          onChange={(newValue) => setWeekOf(newValue)}
        />
      </Box>

      {Array.isArray(memberToDo) && memberToDo.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            paddingTop: "1rem",
            overflowX: "auto",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
          gap={2}
        >
          {memberToDo.map((member) => (
            <MemberToDo
              key={member.id}
              memberToDo={member}
              height={height - 50}
              onUpdated={getData}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default ToDoDashboard;
