import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";
import useMember from "../../hooks/useMember";
import usePage from "../../hooks/usePage";

import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../ui/OFDIcon";

const TokenBank = ({ memberId, member }) => {
  const { getLabel } = useLocalization();
  const { getData } = useApi();
  const { memberColor } = useMember();
  const { refresh } = usePage();

  const theme = useTheme();

  const [memberData, setMemberData] = useState(undefined);

  useEffect(() => {
    getMemberData();
  }, [memberId, refresh]);

  const getMemberData = async () => {
    if (member) {
      setMemberData(member);
      return;
    }

    if (!memberId) return;

    const results = await getData({
      entityName: "Member",
      action: "get",
      id: memberId,
    });

    if (results.isSuccessful) {
      setMemberData(results.data);
    } else {
      setMemberData(null);
    }
  };

  if (memberData === undefined) return null;

  return (
    <Box
      sx={{
        ...memberColor({ shade: 500 }),
        borderRadius: "12px",
        padding: ".5rem",
      }}
    >
      {memberData.tokenObjective && memberData.tokenObjective.name ? (
        <Box
          sx={{
            borderBottom: `1px solid ${
              memberData.color
                ? theme.palette[memberData.color].mainContrast
                : "#fff"
            }`,
            marginBottom: ".5rem",
          }}
        >
          <Typography
            align="center"
            variant="h6"
          >
            {memberData.tokenObjective.name}
          </Typography>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        gap={1}
      >
        {memberData.hasOwnProperty("tokenBank") ? (
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            gap={1}
          >
            {memberData.hasOwnProperty("tokenObjective") ? (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  {memberData.tokenObjective?.image ? (
                    <img
                      src={memberData.tokenObjective.image}
                      width={100}
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <OFDIcon
                      name="savings"
                      size="xlarge"
                      theme="contrast"
                    />
                  )}
                </Box>
              </Box>
            ) : null}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {memberData.hasOwnProperty("tokenObjective") &&
              memberData.tokenObjective?.tokens ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  gap={2}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">{memberData.tokenBank}</Typography>
                    <Typography variant="caption">
                      {getLabel("tokensEarned")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">
                      {memberData.tokenObjective.tokens}
                    </Typography>
                    <Typography variant="caption">
                      {getLabel("tokensNeeded")}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h6">{`${
                    memberData.tokenBank ? memberData.tokenBank : "0"
                  } ${getLabel("inYourBank")}`}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Typography variant="h6">{`0 ${getLabel("inYourBank")}`}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default TokenBank;
