import { useEffect, useState } from "react";

import useDocument from "../../hooks/useDocument";

import { Box } from "@mui/material";

import OFDFileUpload from "./OFDFileUpload";

const OFDDocuments = ({ id, onChange, source }) => {
  return (
    <Box>
      <OFDFileUpload
        source={{ ...source, fieldId: id }}
        onUpload={onChange}
      />
    </Box>
  );
};

export default OFDDocuments;
