import { useEffect, useState } from "react";

import "../../styles/editorStyles.css";

import {
  useEditor,
  EditorContent,
  EditorProvider,
  FloatingMenu,
  BubbleMenu,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";

import { Icon, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import HighlightIcon from "@mui/icons-material/Highlight";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useImage from "../../hooks/useImage";

import OFDModal from "../layout/OFDModal";
import OFDPictureUpload from "./OFDPictureUpload";

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: true,
    },
    orderedList: {
      keepMarks: false,
      keepAttributes: false,
    },
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Image.configure({
    allowBase64: true,
    inline: true,
  }),
  Highlight,
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: "https",
  }),
];

const MenuBar = ({ editor, onImage }) => {
  if (!editor) return null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: ".5rem",
          border: "1px solid #ccc",
          padding: "4px",
          borderRadius: "4px",
          maxWidth: "80%",
          backgroundColor: "#fff",
        }}
        gap={1}
      >
        <Box
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          sx={{
            fontWeight: 900,
            fontSize: "18px",
            color: editor.isActive("heading", { level: 1 })
              ? grey[200]
              : grey[900],
            backgroundColor: editor.isActive("heading", { level: 1 })
              ? grey[900]
              : "#fff",
            padding: "1px",
            borderRadius: "4px",
          }}
        >
          H1
        </Box>
        <Box
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          sx={{
            fontWeight: 900,
            fontSize: "16px",
            color: editor.isActive("heading", { level: 2 })
              ? grey[200]
              : grey[900],
            backgroundColor: editor.isActive("heading", { level: 2 })
              ? grey[900]
              : "#fff",
            padding: "1px",
            borderRadius: "4px",
          }}
        >
          H2
        </Box>
        <Box
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          sx={{
            fontWeight: 900,
            fontSize: "14px",
            color: editor.isActive("heading", { level: 3 })
              ? grey[200]
              : grey[900],
            backgroundColor: editor.isActive("heading", { level: 3 })
              ? grey[900]
              : "#fff",
            padding: "1px",
            borderRadius: "4px",
          }}
        >
          H3
        </Box>

        <FormatBoldIcon
          onClick={() => editor.chain().focus().toggleBold().run()}
          sx={{
            color: editor.isActive("bold") ? grey[200] : grey[900],
            backgroundColor: editor.isActive("bold") ? grey[900] : "#fff",
            borderRadius: "4px",
          }}
        />
        <FormatItalicIcon
          onClick={() => editor.chain().focus().toggleItalic().run()}
          sx={{
            color: editor.isActive("italic") ? grey[200] : grey[900],
            backgroundColor: editor.isActive("italic") ? grey[900] : "#fff",
            borderRadius: "4px",
          }}
        />
        <HighlightIcon
          onClick={() => editor.chain().focus().toggleHighlight().run()}
          sx={{
            color: editor.isActive("highlight") ? grey[200] : grey[900],
            backgroundColor: editor.isActive("highlight") ? grey[900] : "#fff",
            borderRadius: "4px",
          }}
        />

        <FormatListBulletedIcon
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          sx={{
            color: editor.isActive("bulletList") ? grey[200] : grey[900],
            backgroundColor: editor.isActive("bulletList") ? grey[900] : "#fff",
            borderRadius: "4px",
          }}
        />
        <FormatAlignLeftIcon
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          sx={{
            color: editor.isActive({ textAlign: "left" })
              ? grey[200]
              : grey[900],
            backgroundColor: editor.isActive({ textAlign: "left" })
              ? grey[900]
              : "#fff",
            borderRadius: "4px",
          }}
        />
        <FormatAlignCenterIcon
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          sx={{
            color: editor.isActive({ textAlign: "center" })
              ? grey[200]
              : grey[900],
            backgroundColor: editor.isActive({ textAlign: "center" })
              ? grey[900]
              : "#fff",
            borderRadius: "4px",
          }}
        />

        <FormatAlignRightIcon
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          sx={{
            color: editor.isActive({ textAlign: "right" })
              ? grey[200]
              : grey[900],
            backgroundColor: editor.isActive({ textAlign: "right" })
              ? grey[900]
              : "#fff",
            borderRadius: "4px",
          }}
        />

        <AddPhotoAlternateIcon onClick={() => onImage()} />
      </Box>
    </>
  );
};

const OFDNoteEditor = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  fullWidth,
  disabled,
  security,
  entryAddedBy,
  height,
}) => {
  const { userCanView, userCanUpdate } = useSecurity();
  const { getLabel } = useLocalization();

  const [openPicture, setOpenPicture] = useState(false);

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChange(html);
    },
  });

  const handlePictureUpload = (base64) => {
    setOpenPicture(false);
    editor.chain().focus().setImage({ src: base64 }).run();
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <FloatingMenu editor={editor}>
        <MenuBar
          editor={editor}
          onImage={() => setOpenPicture(true)}
        />
      </FloatingMenu>

      <BubbleMenu editor={editor}>
        <MenuBar
          editor={editor}
          onImage={() => setOpenPicture(true)}
        />
      </BubbleMenu>

      <Box>
        <Typography variant="overline">{getLabel(label)}</Typography>
        <Box
          sx={{ border: "1px solid #ccc", height: "480px", overflow: "auto" }}
        >
          <EditorContent editor={editor} />
        </Box>
      </Box>

      {openPicture ? (
        <OFDPictureUpload
          open={openPicture}
          onUpload={handlePictureUpload}
        />
      ) : null}
    </>
  );
};

export default OFDNoteEditor;
