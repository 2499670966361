const useDate = () => {
  const toUTCDate = (date) => {
    let utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes()
    );

    return utcDate;
  };

  return {
    toUTCDate,
  };
};

export default useDate;
