import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useColor from "../../hooks/useColor";

import { Box, Stack, Typography } from "@mui/material";

import ExpenseDetails from "./ExpenseDetails";

const ExpensesForCompany = ({
  year,
  name,
  expenseAmount,
  expenses,
  rank,
  onChange,
}) => {
  const { calendarLists, displayLocalCurrency, getMonthOption, getLabel } =
    useLocalization();
  const { memberColor } = useMember();
  const { getColor } = useColor();

  const [openDetails, setOpenDetails] = useState(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "8px",
        }}
        onClick={() => setOpenDetails(true)}
      >
        <Stack sx={{ width: "100%" }}>
          <Box
            sx={{
              ...memberColor(),
              width: "100%",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              display: "flex",
              justifyContent: "center",
              padding: "2px",
            }}
          >
            <Typography
              noWrap
              sx={{ fontSize: "12px" }}
            >
              {name}
            </Typography>
          </Box>

          <Box
            sx={{ padding: ".5rem", display: "flex", justifyContent: "center" }}
          >
            <Typography>{displayLocalCurrency(expenseAmount)}</Typography>
          </Box>
        </Stack>
      </Box>

      {openDetails ? (
        <ExpenseDetails
          year={year}
          expenses={expenses}
          open={openDetails}
          onClose={() => setOpenDetails(false)}
          onChange={onChange}
          title={`${name} ${year}`}
        />
      ) : null}
    </>
  );
};

export default ExpensesForCompany;
