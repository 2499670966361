import { useEffect, useState } from "react";

import usePage from "../../hooks/usePage";
import useData from "../../hooks/useData";

import { Box } from "@mui/material";

import OFDTitle from "../layout/OFDTitle";

import AppointmentEditor from "../editors/AppointmentEditor";
import ReminderEditor from "../editors/ReminderEditor";
import ToDoEditor from "../editors/ToDoEditor";
import CalendarImport from "../editors/CalendarImport";
import OccasionEditor from "../editors/OccasionEditor";

import CalendarDisplay from "../display/CalendarDisplay";
import WeeklyCalendar from "../display/WeeklyCalendar";

const Calendar = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const { newId } = useData();

  const [openAppointment, setOpenAppointment] = useState(false);
  const [openReminder, setOpenReminder] = useState(false);
  const [openToDo, setOpenToDo] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openOccasion, setOpenOccasion] = useState(false);

  const [refreshCalendar, setRefreshCalendar] = useState(null);

  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [selectedToDo, setSelectedToDo] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [calendarType, setCalendarType] = useState("monthly");
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setEntityName("Calendar");
  }, []);

  useEffect(() => {
    setRefreshCalendar(refresh);
  }, [refresh]);

  useEffect(() => {
    if (toolbarAction === "monthly") {
      setCalendarType("monthly");
    }
    if (toolbarAction === "weekly") {
      setCalendarType("weekly");
    }
    if (toolbarAction === "addCalendar") {
      setSelectedCalendar(null);
      setSelectedOption(null);
      setOpenAppointment(true);
    }
    if (toolbarAction === "addReminder") {
      setSelectedCalendar(null);
      setSelectedOption(null);
      setOpenReminder(true);
    }
    if (toolbarAction === "addToDo") {
      setSelectedCalendar(null);
      setSelectedOption(null);
      setOpenToDo(true);
    }
    if (toolbarAction === "addOccasion") {
      setSelectedCalendar(null);
      setSelectedOption(null);
      setOpenOccasion(true);
    }
    if (toolbarAction === "importCalendar") {
      setSelectedCalendar(null);
      setSelectedOption("import");
      setOpenImport(true);
    }
  }, [toolbarAction]);

  const handleCloseEditor = () => {
    resetToolbarAction();
    setOpenAppointment(false);
    setOpenReminder(false);
    setOpenToDo(false);
    setOpenImport(false);
    setOpenOccasion(false);
    setRefreshCalendar(newId());
  };

  const handleEditAppointment = (item, option) => {
    setSelectedCalendar(item);
    setSelectedOption(option);
    setOpenAppointment(true);
    setRefreshCalendar(newId());
  };

  const handleRefreshCalendar = () => {
    handleCloseEditor();
  };

  const handleEditReminder = (item, option) => {
    setSelectedCalendar(item);
    setSelectedOption(option);
    setOpenReminder(true);
  };

  const handleEditToDo = (item, option) => {
    setSelectedCalendar(item);
    setSelectedOption(option);
    setOpenToDo(true);
  };

  return (
    <>
      <OFDTitle title="calendar" />

      <Box>
        {calendarType === "monthly" ? (
          <CalendarDisplay
            refresh={refreshCalendar}
            onEditAppointment={handleEditAppointment}
            onEditReminder={handleEditReminder}
            onEditToDo={handleEditToDo}
            onSelectedDate={(newValue) => setSelectedDate(newValue)}
          />
        ) : null}

        {calendarType === "weekly" ? (
          <WeeklyCalendar
            refresh={refreshCalendar}
            onEditAppointment={handleEditAppointment}
            onEditReminder={handleEditReminder}
            onEditToDo={handleEditToDo}
          />
        ) : null}
      </Box>

      {openAppointment ? (
        <AppointmentEditor
          open={openAppointment}
          onClose={handleCloseEditor}
          onSave={handleRefreshCalendar}
          calendar={selectedCalendar}
          option={selectedOption}
          selectedDate={selectedDate}
        />
      ) : null}

      {openReminder ? (
        <ReminderEditor
          open={openReminder}
          onClose={handleCloseEditor}
          onSave={handleRefreshCalendar}
          calendar={selectedCalendar}
          option={selectedOption}
          selectedDate={selectedDate}
        />
      ) : null}

      {openToDo ? (
        <ToDoEditor
          open={openToDo}
          onClose={handleCloseEditor}
          onSave={handleRefreshCalendar}
          calendar={selectedCalendar}
          option={selectedOption}
          selectedDate={selectedDate}
        />
      ) : null}

      {openImport ? (
        <CalendarImport
          open={openImport}
          onClose={handleCloseEditor}
        />
      ) : null}

      {openOccasion ? (
        <OccasionEditor
          open={openOccasion}
          onClose={handleCloseEditor}
          onUpdate={handleRefreshCalendar}
        />
      ) : null}
    </>
  );
};

export default Calendar;
