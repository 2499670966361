import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useOccasion from "../../hooks/useOccasion";
import useMessage from "../../hooks/useMessage";

import { Box, Button, Divider, Typography, Stack } from "@mui/material";

import OFDSelect from "../ui/OFDSelect";
import OFDNumber from "../ui/OFDNumber";
import OFDModal from "../layout/OFDModal";
import OFDToolbar from "../layout/OFDToolbar";
import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDFieldGroup from "../ui/OFDFieldGroup";
import OFDDialog from "../layout/OFDDialog";
import OFDAutoComplete from "../ui/OFDAutoComplete";
import OFDMessage from "../ui/OFDMessage";

const OFDOccasion = ({ open, onClose, source }) => {
  const { calendarLists, getLabel, getText, lists } = useLocalization();
  const { getOccasions, saveOccasion, validateOccasion, deleteOccasion } =
    useOccasion();
  const { destructureResults } = useMessage();

  const [occasions, setOccasions] = useState(undefined);
  const [occasion, setOccasion] = useState(null);
  const [message, setMessage] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [editMode, setEditMode] = useState("add");

  const [occasionTypeMessage, setOccasionTypeMessage] = useState("");
  const [nameMessage, setNameMessage] = useState("");
  const [monthMessage, setMonthMessage] = useState("");
  const [dayMessage, setDayMessage] = useState("");

  useEffect(() => {
    if (!source) return;

    getData();
  }, [source]);

  const getData = async () => {
    const results = await getOccasions(source);

    if (results.isSuccessful) {
      setOccasions(results.data);
    } else {
      setOccasions(null);
    }
  };

  const getFieldValue = (fieldName) => {
    if (!occasion) return "";
    if (!occasion.hasOwnProperty(fieldName)) return "";
    return occasion[fieldName];
  };

  const setFieldValue = (fieldName, value) => {
    if (!occasion) {
      setOccasion({ [fieldName]: value });
      return;
    }

    setOccasion((current) => {
      let newOccasion = { ...current };

      newOccasion[fieldName] = value;

      return newOccasion;
    });
  };

  const setMessages = (validationResults) => {
    setOccasionTypeMessage("");
    setNameMessage("");
    setMonthMessage("");
    setDayMessage("");

    const occasionTypeError = validationResults.fieldErrors.find(
      (field) => field.name === "occasionType"
    );
    if (occasionTypeError) setOccasionTypeMessage(occasionTypeError.message);

    const monthError = validationResults.fieldErrors.find(
      (field) => field.name === "month"
    );
    if (monthError) setMonthMessage(monthError.message);

    const dayError = validationResults.fieldErrors.find(
      (field) => field.name === "day"
    );
    if (dayError) setDayMessage(dayError.message);
  };

  const handleSaveItem = async () => {
    const validationResults = validateOccasion(occasion);

    setMessages(validationResults);

    if (!validationResults.isSuccessful) return;

    const data = {
      source,
      ...occasion,
    };
    const results = await saveOccasion(data);

    setMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getData();
      setOccasion(null);
      setEditMode("add");
    }
  };

  const handleUpdateItem = (item) => {
    setEditMode("update");
    setOccasion(item);
  };

  const handleCancel = () => {
    setOccasion(null);
    setEditMode("add");
  };

  const handleDeleteItem = () => {
    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmDelete(false);

    const results = await deleteOccasion(occasion);

    setMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getData();
      setOccasion(null);
      setEditMode("add");
    }
  };

  const getDate = (item) => {
    let month = calendarLists.months[item.month].label;
    let year = item.year ? `, ${item.year}` : "";

    return `${month} ${item.day} ${year}`;
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="occasions"
      >
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box
            sx={{
              height: "calc(100% - 5rem)",
              width: "100%",
              overflow: "auto",
            }}
          >
            <Box sx={{ padding: "1rem" }}>
              <Stack spacing={1}>
                <OFDAutoComplete
                  id="occasionType"
                  label="occasion"
                  options={lists.occasionTypes}
                  value={getFieldValue("occasionType")}
                  onChange={(newValue) =>
                    setFieldValue("occasionType", newValue)
                  }
                  required
                  message={occasionTypeMessage}
                />

                <OFDTextField
                  id="name"
                  label="name"
                  value={getFieldValue("name")}
                  onChange={(newValue) => setFieldValue("name", newValue)}
                  required
                  message={nameMessage}
                />

                <Box
                  sx={{ display: "flex" }}
                  gap={1}
                >
                  <OFDSelect
                    id="month"
                    label="month"
                    options={calendarLists.months}
                    value={getFieldValue("month")}
                    onChange={(newValue) => setFieldValue("month", newValue)}
                    required
                    fullWidth
                    message={monthMessage}
                  />

                  <OFDNumber
                    id="day"
                    label="date"
                    value={getFieldValue("day")}
                    onChange={(newValue) => setFieldValue("day", newValue)}
                    required
                    message={dayMessage}
                  />

                  <OFDNumber
                    id="year"
                    label="year"
                    value={getFieldValue("year")}
                    onChange={(newValue) => setFieldValue("year", newValue)}
                  />
                </Box>
                <OFDTextNote
                  id="note"
                  label="note"
                  value={getFieldValue("note")}
                  onChange={(newValue) => setFieldValue("note", newValue)}
                />
                <Box>
                  <OFDFieldGroup justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleDeleteItem}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleSaveItem}
                    >
                      {editMode === "update" ? "Update" : "Add"}
                    </Button>
                  </OFDFieldGroup>
                </Box>

                <Divider sx={{ marginTop: ".5rem" }}>
                  <Typography variant="overline">
                    {getLabel("entries")}
                  </Typography>
                </Divider>

                <Box>
                  {Array.isArray(occasions) ? (
                    <Stack spacing={1}>
                      {occasions.map((item, index) => (
                        <Box
                          key={`${item.name}-${index}`}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: ".5rem",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleUpdateItem(item)}
                        >
                          <Typography>{item.name}</Typography>
                          <Typography>{getDate(item)}</Typography>
                        </Box>
                      ))}
                    </Stack>
                  ) : (
                    <Typography>{getText("noEntries")}</Typography>
                  )}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </OFDModal>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <OFDMessage message={message} />
    </>
  );
};

export default OFDOccasion;
