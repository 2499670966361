import { Box, Typography } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useSecurity from "../../hooks/useSecurity";

const OFDDisplayColor = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const { getColor } = useColor();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const contentStyle = {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
  };

  const colorStyle = {
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    ...getColor({ color: value, shade: 500 }),
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={onClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>

      <Box sx={contentStyle}>
        <Typography>{value}</Typography>
        <Box sx={colorStyle}></Box>
      </Box>
    </Box>
  );
};

export default OFDDisplayColor;
