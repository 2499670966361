import { useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDSelect from "./OFDSelect";
import OFDMemberPicker from "./OFDMemberPicker";

const defaultSecurityLevel = {
  view: { securityLevel: "family" },
  update: { securityLevel: "admin" },
  delete: { securityLevel: "admin" },
};

const OFDFieldSecurity = ({
  id,
  label,
  value,
  onChange,
  required,
  disabled,
}) => {
  const { labels } = useLocalization();

  const handleChange = (action, field, newValue) => {
    let newSecurity = {};
    if (value) newSecurity = { ...value };
    if (!value) newSecurity = { ...defaultSecurityLevel };

    onChange({
      ...newSecurity,
      [action]: { ...newSecurity[action], [field]: newValue },
    });
  };

  const getValue = (action, field) => {
    if (!value || !value.hasOwnProperty(action)) {
      if (field === "securityLevel") {
        return defaultSecurityLevel[action];
      } else if (field === "members") {
        return [];
      }
    }

    if (value[action].hasOwnProperty(field)) {
      return value[action][field];
    } else {
      if (field === "securityLevel") {
        return defaultSecurityLevel[action];
      } else if (field === "members") {
        return [];
      }
    }

    return null;
  };

  return (
    <>
      <Stack
        id={id}
        spacing={2}
        sx={{ width: "100%" }}
      >
        {/* VIEW */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>{labels.view}</Typography>
          </Box>

          <Box sx={{ width: "80%" }}>
            <OFDSelect
              id="view-security"
              label="viewSecurity"
              value={getValue("view", "securityLevel")}
              onChange={(newValue) =>
                handleChange("view", "securityLevel", newValue)
              }
              listName="fieldSecurityLevels"
              fullWidth
            />

            {getValue("view", "securityLevel") === "members" ? (
              <OFDMemberPicker
                value={getValue("view", "members")}
                onChange={(newValue) =>
                  handleChange("view", "members", newValue)
                }
              />
            ) : null}
          </Box>
        </Box>

        {/* UPDATE */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>{labels.update}</Typography>
          </Box>

          <Box sx={{ width: "80%" }}>
            <OFDSelect
              id="update-security"
              label="updateSecurity"
              value={getValue("update", "securityLevel")}
              onChange={(newValue) =>
                handleChange("update", "securityLevel", newValue)
              }
              listName="fieldSecurityLevels"
              fullWidth
            />

            {getValue("update", "securityLevel") === "members" ? (
              <OFDMemberPicker
                value={getValue("update", "members")}
                onChange={(newValue) =>
                  handleChange("update", "members", newValue)
                }
              />
            ) : null}
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default OFDFieldSecurity;
