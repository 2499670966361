import { useEffect, useState } from "react";
import { format } from "date-fns";

import usePage from "../../../../hooks/usePage";
import useGrocery from "../../../../hooks/useGrocery";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../../ui/OFDIcon";
import OFDModal from "../../OFDModal";
import OFDGroceryItems from "../../../ui/OFDGroceryItems";
import OFDToolbar from "../../OFDToolbar";
import OFDDialog from "../../../layout/OFDDialog";

const GroceryList = ({ groceryList, onChange }) => {
  const { dashboardMode } = usePage();
  const { getMessage } = useLocalization();
  const { groupGroceryItems, updateGroceryItems } = useGrocery();

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [groupedItems, setGroupedItems] = useState(undefined);
  const [departmentOpened, setDepartmentOpened] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [groceryItems, setGroceryItems] = useState();
  const [groceryListChanged, setGroceryListChanged] = useState(false);
  const [openCancelChanges, setOpenCancelChanges] = useState(false);

  useEffect(() => {
    if (!groceryList) return;

    setGroceryItems(groceryList.groceryItems);
    setGroupedItems(groupGroceryItems(groceryList.groceryItems));
  }, [groceryList]);

  useEffect(() => {
    if (!groupedItems) return;

    setDepartmentOpened(() => {
      let list = {};
      for (const department of groupedItems.departments) {
        list[department] = false;
      }

      return list;
    });
  }, [groupedItems]);

  const toggleDepartment = (department) => {
    setDepartmentOpened((current) => {
      return {
        ...current,
        [department]: !current[department],
      };
    });
  };

  const handleUpdate = (newGroceryItems) => {
    setGroceryItems(newGroceryItems);
    setGroceryListChanged(true);
  };

  const handleSave = async () => {
    if (!groceryListChanged) {
      setOpenEditor(false);
      return;
    }

    const results = await updateGroceryItems(groceryList.id, groceryItems);

    if (results.isSuccessful) {
      setGroceryListChanged(false);
      setOpenEditor(false);
      onChange();
    }
  };

  const handleEditorClose = () => {
    if (!groceryListChanged) {
      setOpenEditor(false);
      return;
    }

    setOpenCancelChanges(true);
  };

  const closeConfirmation = () => {
    setGroceryItems(groceryList.groceryItems);
    setGroceryListChanged(false);
    setOpenCancelChanges(false);
    setOpenEditor(false);
  };

  if (groupedItems === undefined) return;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "4px",
          borderRadius: "4px",
          paddingLeft: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{ display: "flex", alignItems: "flex-start" }}
            gap={1}
          >
            <OFDIcon
              name="grocery"
              color="orange"
            />
            <Box>
              <Typography sx={{ fontWeight: 900 }}>
                {groceryList.name}
              </Typography>
              {groceryList.shoppingDate ? (
                <Typography>
                  {format(groceryList.shoppingDate, "EEE, MMM dd")}
                </Typography>
              ) : null}
            </Box>
          </Box>

          <Box>
            <OFDIcon
              name={open ? "arrowUp" : "arrowDown"}
              onClick={() => setOpen(!open)}
              color="grey"
            />
          </Box>
        </Box>

        {open ? (
          <Box sx={{ marginTop: "1rem" }}>
            {dashboardMode === "edit" ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setOpenEditor(true)}
                >
                  Update List
                </Button>
              </Box>
            ) : null}

            {groupedItems.departments.length > 0 ? (
              <Stack spacing={1}>
                {groupedItems.departments.map((department) => (
                  <Box key={department}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{ fontWeight: 900 }}>
                        {department}
                      </Typography>

                      <OFDIcon
                        name={
                          departmentOpened[department] ? "arrowUp" : "arrowDown"
                        }
                        color="grey"
                        onClick={() => {
                          toggleDepartment(department);
                        }}
                      />
                    </Box>

                    {departmentOpened[department] ? (
                      <Stack spacing={1}>
                        {groceryList.groceryItems
                          .filter((item) => item.department === department)
                          .map((departmentItem) => (
                            <Box
                              key={departmentItem.id}
                              sx={{ borderBottom: "1px solid #ccc" }}
                            >
                              <Box
                                sx={{
                                  paddingLeft: ".5rem",
                                  paddingRight: ".5rem",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography>{departmentItem.item}</Typography>

                                <Typography>
                                  {departmentItem.quantity}
                                </Typography>
                              </Box>
                              {departmentItem.note ? (
                                <Box sx={{ paddingLeft: "1rem" }}>
                                  <Typography
                                    variant="caption"
                                    sx={{ whiteSpace: "pre-wrap" }}
                                  >
                                    {departmentItem.note}
                                  </Typography>
                                </Box>
                              ) : null}
                            </Box>
                          ))}
                      </Stack>
                    ) : null}
                  </Box>
                ))}
              </Stack>
            ) : (
              <Typography>{getMessage("noGroceryItems")}</Typography>
            )}
          </Box>
        ) : null}
      </Box>

      <OFDModal
        open={openEditor}
        onClose={handleEditorClose}
        title={groceryList.name}
      >
        <OFDToolbar
          menuItems={[{ name: "save", labe: "save", icon: "save" }]}
          onClick={handleSave}
        />
        <Box sx={{ padding: "1rem", backgroundColor: "#ffffff" }}>
          <OFDGroceryItems
            value={groceryItems}
            onChange={handleUpdate}
          />
        </Box>
      </OFDModal>

      <OFDDialog
        open={openCancelChanges}
        title="cancelChanges"
        description="cancelChanges"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={closeConfirmation}
      />
    </>
  );
};

export default GroceryList;
