import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

import { Box, Stack, Typography } from "@mui/material";

import ExpenseDetails from "./ExpenseDetails";

const ExpensesForMonth = ({
  year,
  month,
  expenseAmount,
  expenses,
  incomeAmount,
  income,
  incomeBudget,
  expenseBudget,
  rank,
  onChange,
}) => {
  const { displayLocalCurrency, getMonthOption, getLabel } = useLocalization();
  const { memberColor } = useMember();

  const [openDetails, setOpenDetails] = useState(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          display: "flex",
          borderRadius: "8px",
        }}
        onClick={() => setOpenDetails(true)}
      >
        <Stack sx={{ width: "100%" }}>
          <Box
            sx={{
              ...memberColor(),
              width: "100%",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2px",
            }}
          >
            <Typography>{getMonthOption(month).label}</Typography>
          </Box>

          <Box sx={{ padding: ".5rem" }}>
            <Stack>
              <Typography variant="h6">{getLabel("income")}</Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="caption">
                    {getLabel("budget")}
                  </Typography>
                  <Typography>
                    {displayLocalCurrency(incomeBudget, 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">
                    {getLabel("actual")}
                  </Typography>
                  <Typography>
                    {displayLocalCurrency(incomeAmount, 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">
                    {getLabel("difference")}
                  </Typography>

                  <Typography
                    sx={{
                      color:
                        incomeAmount - incomeBudget < -1
                          ? "#ff0000"
                          : "inherit",
                    }}
                  >
                    {displayLocalCurrency(incomeAmount - incomeBudget, 0)}
                  </Typography>
                </Box>
              </Box>

              <Typography
                variant="h6"
                mt="1rem"
              >
                {getLabel("expenses")}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="caption">
                    {getLabel("budget")}
                  </Typography>
                  <Typography>
                    {displayLocalCurrency(expenseBudget, 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">
                    {getLabel("actual")}
                  </Typography>
                  <Typography>
                    {displayLocalCurrency(expenseAmount, 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">
                    {getLabel("difference")}
                  </Typography>

                  <Typography
                    sx={{
                      color:
                        expenseAmount - expenseBudget > 1
                          ? "#ff0000"
                          : "inherit",
                    }}
                  >
                    {displayLocalCurrency(expenseAmount - expenseBudget, 0)}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {openDetails ? (
        <ExpenseDetails
          year={year}
          expenses={expenses}
          income={income}
          open={openDetails}
          onClose={() => setOpenDetails(false)}
          onChange={onChange}
          title={`${getMonthOption(month).label} ${year}`}
        />
      ) : null}
    </>
  );
};

export default ExpensesForMonth;
