import { useEffect, useState } from "react";

import "../../styles/editorStyles.css";

const OFDDisplayHtml = ({ value }) => {
  const [html, setHtml] = useState();

  useEffect(() => {
    convertToHtml();
  }, [value]);

  const convertToHtml = () => {
    if (!value) {
      setHtml("");
      return;
    }
    let htmlValue = value.replaceAll("\n", "<br/>");

    setHtml(htmlValue);
  };

  return (
    <div
      className="tiptap"
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

export default OFDDisplayHtml;
