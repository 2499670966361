import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDDisplayHtml from "./OFDDisplayHtml";

const OFDDisplayNoteEditor = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel, displayLocalNumber } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box
        sx={displayStyle}
        onClick={onClick}
      >
        <Typography variant="overline">{getLabel(label)}</Typography>

        {value && value.length > 0 ? (
          <Box
            sx={{
              maxHeight: "200px",
              overflow: "auto",
              border: "1px solid #e0e0e0",
            }}
          >
            <OFDDisplayHtml value={value} />
          </Box>
        ) : (
          <Typography sx={{ whiteSpace: "pre-wrap" }}>-</Typography>
        )}
      </Box>
    </>
  );
};

export default OFDDisplayNoteEditor;
