import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import HelpVideos from "../../configuration/HelpVideos";

import OFDModal from "../layout/OFDModal";
import OFDTabPanel from "../layout/OFDTabPanel";
import SupportForm from "../forms/SupportForm";
import FAQs from "./FAQs";

const Help = ({ subject, open, onClose }) => {
  const { getLabel, getTitle } = useLocalization();
  const { memberColor } = useMember();

  const [tabIndex, setTabIndex] = useState(0);
  const [openVideo, setOpenVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const tabStyle = {
    "& .MuiTabs-indicator": {
      backgroundColor: memberColor().backgroundColor,
    },
    "& .MuiTab-root.Mui-selected": {
      color: memberColor().backgroundColor,
    },
  };

  const handleTabChange = (e, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
    setSelectedVideo(null);
  };

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    setOpenVideo(true);
  };

  return (
    <>
      <OFDModal
        title="help"
        open={open}
        onClose={onClose}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={tabStyle}
          >
            <Tab label={getLabel("videos")} />
            <Tab label={getLabel("faqs")} />
            <Tab label={getLabel("support")} />
          </Tabs>
        </Box>

        <Box sx={{ padding: "1rem" }}>
          <OFDTabPanel
            value={0}
            index={tabIndex}
          >
            <Box>
              <Grid
                container
                spacing={2}
              >
                {HelpVideos?.map((video) => (
                  <Grid
                    key={video.name}
                    mobile={6}
                    desktop={4}
                  >
                    <Box>
                      <Box>
                        <img
                          src={video.thumbnail}
                          width="100%"
                          onClick={() => handleVideoSelect(video)}
                          style={{
                            borderRadius: "20px",
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </OFDTabPanel>
          <OFDTabPanel
            value={1}
            index={tabIndex}
          >
            <FAQs />
          </OFDTabPanel>
          <OFDTabPanel
            value={2}
            index={tabIndex}
          >
            <SupportForm />
          </OFDTabPanel>
        </Box>
      </OFDModal>

      {selectedVideo ? (
        <OFDModal
          open={openVideo}
          onClose={handleCloseVideo}
          title={selectedVideo.name}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <video
              style={{
                maxWidth: "100%",
                height: "100%",
              }}
              poster={selectedVideo.thumbnail}
              controls
            >
              <source
                src={selectedVideo.src}
                type="video/mp4"
              />
            </video>
          </Box>
        </OFDModal>
      ) : null}
    </>
  );
};

export default Help;
