import { useEffect, useState, useRef } from "react";
import { format } from "date-fns";

import useColor from "../../../../hooks/useColor";
import useMember from "../../../../hooks/useMember";
import useCalendar from "../../../../hooks/useCalendar";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../../../ui/OFDIcon";
import MemberColors from "./MemberColors";

const ToDo = ({ todo, onSelect, onUpdated }) => {
  const { getColor } = useColor();
  const { getMember } = useMember();
  const { completeToDo } = useCalendar();

  const [open, setOpen] = useState(false);
  const [textLength, setTextLength] = useState("100px");

  const container = useRef();

  useEffect(() => {
    if (!container || !container.current || !container.current.clientWidth)
      return;

    setTextLength(`${container.current.clientWidth - 104}px`);
  }, [container.current]);

  const updateToDoStatus = async () => {
    let completeStatus = !todo.todoComplete;

    const results = await completeToDo(todo, completeStatus);

    if (results.isSuccessful) {
      onUpdated();
    }
  };

  return (
    <>
      <Box
        ref={container}
        sx={{
          backgroundColor: "#fff",
          padding: "4px",
          paddingLeft: "12px",
          borderBottom: "1px solid #ccc",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "flex-start" }}
            gap={1}
          >
            <OFDIcon
              name={todo.category}
              color={getMember(todo.memberId).color}
            />

            {todo.todoComplete ? (
              <OFDIcon
                name="checkcircle"
                color={getMember(todo.memberId).color}
                onClick={updateToDoStatus}
              />
            ) : (
              <Box
                sx={{
                  width: "22px",
                  height: "22px",
                  border: `1px solid ${
                    getColor({ color: getMember(todo.memberId).color })
                      .backgroundColor
                  }`,
                  borderRadius: "50%",
                }}
                onClick={updateToDoStatus}
              ></Box>
            )}
          </Box>

          <OFDIcon
            name={open ? "arrowUp" : "arrowDown"}
            color="grey"
            onClick={() => setOpen(!open)}
          />
        </Box>

        <Box
          sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}
          gap={1}
        >
          <Typography
            sx={{
              width: textLength,
              whiteSpace: "break-spaces",
            }}
          >
            {todo.name}
          </Typography>
        </Box>

        <MemberColors members={todo.members} />

        {open ? (
          <Box sx={{ marginTop: "10px", paddingLeft: "30px" }}>
            {todo.description ? (
              <Typography variant="caption">{todo.description}</Typography>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ToDo;
