import React, { useState } from "react";

import useLocalization from "../../hooks/useLocalization";

// Material UI
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordField = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  fullWidth,
}) => {
  const { getLabel, getMessage } = useLocalization();

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    onChange(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      id={id}
      variant="outlined"
      fullWidth={fullWidth}
      size="small"
      autoComplete="off"
      required={required || false}
      disabled={disabled || false}
      // sx={{
      //   "& label.Mui-focused": {
      //     color: getColor(color || userColor, 800).backgroundColor,
      //   },
      //   "& .MuiOutlinedInput-root": {
      //     "&.Mui-focused fieldset": {
      //       borderColor: getColor(color || userColor, 800).backgroundColor,
      //     },
      //   },
      // }}
    >
      <InputLabel>{getLabel(label || "password")}</InputLabel>
      <OutlinedInput
        autoComplete="off"
        id="newpasswordField"
        type={showPassword ? "text" : "password"}
        size="small"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={getLabel(label || "password")}
        value={value}
        onChange={handlePasswordChange}
        required={required || false}
      ></OutlinedInput>
      <FormHelperText error={error}>{getMessage(message)}</FormHelperText>
    </FormControl>
  );
};

export default PasswordField;
