import { useEffect, useState } from "react";

import useLocalization from "../../../hooks/useLocalization";
import useMember from "../../../hooks/useMember";

import { Box, Stack, Typography } from "@mui/material";

import Accounts from "../../pages/Accounts";

const MemberAccounts = () => {
  const { getWizard } = useLocalization();
  const { member } = useMember();

  useEffect(() => {}, [member]);

  return (
    <Box sx={{ padding: "1rem" }}>
      <Stack spacing={2}>
        <Typography variant="h5">
          {getWizard("setup").memberAccounts.title}
        </Typography>
        {getWizard("setup").memberAccounts.text.map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}
        <Accounts />
      </Stack>
    </Box>
  );
};

export default MemberAccounts;
