import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDCollapsable from "../layout/OFDCollapsable";
import OFDAvatar from "../ui/OFDAvatar";
import OFDDisplayText from "../ui/OFDDisplayText";

const AccountItem = ({ member, onEdit }) => {
  const { getLabel } = useLocalization();

  const handleMenuClick = (option) => {
    if (option === "edit") {
      onEdit(member);
    }
  };

  return (
    <OFDCollapsable
      header={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            gap={1}
          >
            <OFDAvatar
              avatar={member.avatar}
              name={member.name}
            />

            <Typography>{member.name}</Typography>
          </Box>

          <Typography>
            {member.isUser ? getLabel("isUser") : getLabel("notAUser")}
          </Typography>
        </Box>
      }
      menuItems={[
        {
          value: "edit",
          label: "edit",
          icon: "edit",
        },
      ]}
      onMenuClick={handleMenuClick}
    >
      {member.isUser ? (
        <Box sx={{ padding: "1rem" }}>
          <Stack spacing={2}>
            <OFDDisplayText
              id="username"
              label="username"
              value={member.username}
            />
          </Stack>
        </Box>
      ) : null}
    </OFDCollapsable>
  );
};

export default AccountItem;
