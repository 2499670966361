import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

import { Box, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";

const SummaryBox = ({ id, label, value, icon, isSelected, onClick }) => {
  const { getLabel } = useLocalization();
  const { memberColor } = useMember();

  return (
    <Box
      id={id}
      sx={{
        border: `1px solid ${
          memberColor({ shade: isSelected ? 500 : 200 }).backgroundColor
        }`,
        borderRadius: "8px",
        minWidth: "60px",
      }}
      onClick={() => onClick(id)}
    >
      <Box
        sx={{
          ...memberColor({ shade: isSelected ? 500 : 200 }),
          paddingTop: "4px",
          paddingBottom: "4px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <OFDIcon
          name={icon}
          color="grey"
          shade={50}
        />
      </Box>
      <Box
        sx={{
          paddingTop: "2px",
          paddingBottom: "2px",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        {/* <Typography variant="caption">{getLabel(label)}</Typography> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">{value}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryBox;
