import { useRef, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useFamily from "../../hooks/useFamily";
import useData from "../../hooks/useData";
import useApi from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";

import { Box, Stack, Typography } from "@mui/material";

import OFDButton from "./OFDButton";
import OFDTextField from "./OFDTextField";
import OFDTextNote from "./OFDTextNote";
import OFDFieldSecurity from "./OFDFieldSecurity";
import OFDRecordSecurity from "./OFDRecordSecurity";
import OFDMessage from "./OFDMessage";
import LoadingModal from "../layout/LoadingModal";

const defaultSecurity = {
  view: { securityLevel: "family" },
  update: { securityLevel: "addedBy" },
  delete: { securityLevel: "addedby" },
};

const OFDFileUpload = ({ folderId, source, onUpload, entityName }) => {
  const { displayLocalNumber, getLabel } = useLocalization();
  const { destructureResults } = useMessage();
  const { uploadFile, upload, postData } = useApi();
  const { member } = useMember();
  const { family } = useFamily();
  const { newId } = useData();

  const [file, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [nameMessage, setNameMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [description, setDescription] = useState(null);
  const [security, setSecurity] = useState();
  const [showFileBrowser, setShowFileBrowser] = useState(true);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [message, setMessage] = useState();

  const [showLoading, setShowLoading] = useState(false);

  const fileUploadRef = useRef();
  const fileKey = useRef();

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);

    setShowFileUpload(true);
  };

  const handleFileUpload = async () => {
    if (!isValid()) return;
    setShowLoading(true);

    fileKey.current = `${family.id}/${newId()}`;

    const results = await upload({
      entityName: "Document",
      action: "upload",
      file: file,
      options: {
        filename: fileKey.current,
      },
    });

    if (results.isSuccessful) {
      await updateDocument();
    }

    setFile(null);

    setShowLoading(false);
  };

  const updateDocument = async () => {
    const data = {
      familyId: family.id,
      security,
      folderId: folderId ? folderId : null,
      source: source ? source : null,
      name,
      description,
      fileName: file.name,
      mimeType: file.type,
      fileSize: file.size,
      fileExtension:
        file.name.indexOf(".") > -1 ? file.name.split(".")[1] : null,
      key: fileKey.current,
    };

    const results = await postData({
      entityName: "Document",
      action: "add",
      data,
    });

    setMessage(destructureResults(results));

    if (results.isSuccessful) {
      if (onUpload) onUpload();
    }
  };

  const isValid = () => {
    if (name && name.trim().length > 0) {
      setNameError(false);
      setNameMessage("");
      return true;
    } else {
      setNameError(true);
      setNameMessage("isEmpty");
      return false;
    }
  };

  const handleOpenFileBrowser = () => {
    fileUploadRef.current.click();
  };

  return (
    <>
      <Box sx={{ marginTop: "1rem" }}>
        <Stack spacing={2}>
          <OFDTextField
            id="name"
            label="name"
            value={name}
            onChange={(newValue) => setName(newValue)}
            fullWidth
            required
            error={nameError}
            message={nameMessage}
          />

          <OFDTextNote
            id="description"
            label="description"
            value={description}
            onChange={(newValue) => setDescription(newValue)}
            fullWidth
          />

          <OFDRecordSecurity
            id="security"
            label="security"
            value={security}
            onChange={(newValue) => setSecurity(newValue)}
            entityName={entityName}
          />

          {showFileBrowser ? (
            <OFDButton
              id="fileBrowser"
              label="selectFile"
              onClick={handleOpenFileBrowser}
              color="grey"
              shade={900}
              variant="contained"
            />
          ) : null}

          {showFileUpload ? (
            <>
              <Box>
                <Typography>{`${file.name}`}</Typography>
                <Typography>{`${displayLocalNumber(file.size)} ${getLabel(
                  "bytes"
                )}`}</Typography>
              </Box>

              <OFDButton
                id="uploadFile"
                label="uploadFile"
                onClick={handleFileUpload}
                color="grey"
                shade={900}
                variant="contained"
              />
            </>
          ) : null}
        </Stack>

        <input
          id="fileUploader"
          ref={fileUploadRef}
          type="file"
          onChange={handleFileSelect}
          style={{ display: "none" }}
        />

        <Box></Box>
      </Box>

      <OFDMessage
        message={message}
        onClose={() => setMessage(null)}
      />

      <LoadingModal open={showLoading} />
    </>
  );
};

export default OFDFileUpload;
