import { useEffect, useState } from "react";
import { startOfToday, format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";
import useMember from "../../hooks/useMember";
import usePage from "../../hooks/usePage";
import useEntity from "../../hooks/useEntity";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";
import Loading from "../layout/Loading";
import OFDEntityItem from "../layout/OFDEntityItem";
import EntityEditor from "../editors/EntityEditor";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import Homework from "./Homework";

const HomeworkOutstanding = ({ memberId }) => {
  const { getMessage, getLabel } = useLocalization();
  const { memberColor } = useMember();
  const { postData } = useApi();
  const { refresh } = usePage();
  const {
    entityList,
    getEntityList,
    menuClick,
    entityMessage,
    resetEntityMessage,
    setEntityList,
  } = useEntity("Homework");

  const [homework, setHomework] = useState(undefined);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    getData();
  }, [memberId, refresh]);

  const getData = async () => {
    if (!memberId) return;

    setEntityList(undefined);
    await getEntityList({
      filter: {
        memberId,
        dueDate: { $gte: startOfToday() },
      },
    });

    // const results = await postData({
    //   entityName: "Homework",
    //   action: "get",
    //   filter: {
    //     memberId,
    //     dueDate: { $gte: startOfToday() },
    //   },
    // });

    // if (results.isSuccessful) {
    //   setHomework(results.data);
    // } else {
    //   setHomework(null);
    // }
  };

  const handleChange = async () => {
    await getData();
  };

  const handleFormClose = async () => {
    setOpenForm(false);
    await getData();
  };

  const customHeader = (item) => {
    return (
      <Box>
        <Typography sx={{ fontSize: "12px", fontWeight: 800 }}>
          {item.dueDate ? format(item.dueDate, "EEE MMM dd") : null}
        </Typography>
        <Typography>{item.name}</Typography>
      </Box>
    );
  };

  const handleMenuClick = (action, entity) => {
    setSelectedEntity(entity);

    if (action === "delete") {
      setOpenDeleteConfirmation(true);
    }
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      const results = await menuClick("delete", selectedEntity);

      if (results.isSuccessful) {
        await getData();
      }
    }
    setOpenDeleteConfirmation(false);
  };

  if (entityList === undefined) return <Loading />;
  if (entityList === null || entityList.length === 0) return;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            ...memberColor(),
            padding: ".5rem",
          }}
          gap={1}
        >
          <OFDIcon
            name="homework"
            size="medium"
            color="grey"
            shade={50}
          />
          <Typography variant="h5">{getLabel("homework")}</Typography>
        </Box>

        <Stack
          spacing={1}
          sx={{ padding: "1rem" }}
        >
          {entityList?.map((item) => (
            <OFDEntityItem
              entityName={"Homework"}
              id={item.id}
              key={item.id}
              entity={item}
              onMenuItemClick={handleMenuClick}
              onChange={handleChange}
              customHeader={customHeader}
              headerStyles={{ ...memberColor({ shade: 100 }) }}
            />
          ))}
        </Stack>
      </Box>

      {openForm ? (
        <EntityEditor
          entityName="Homework"
          open={openForm}
          onClose={handleFormClose}
        />
      ) : null}

      <OFDMessage
        message={entityMessage}
        onClose={resetEntityMessage}
      />

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_homework"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default HomeworkOutstanding;
