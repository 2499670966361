import { useEffect, useState, useRef } from "react";
import { format } from "date-fns";

import useSecurity from "../../hooks/useSecurity";
import useLocalization from "../../hooks/useLocalization";
import useForm from "../../hooks/useForm";
import useDebug from "../../hooks/useDebug";
import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useMember from "../../hooks/useMember";
import useColor from "../../hooks/useColor";
import useExpense from "../../hooks/useExpense";
import useWindow from "../../hooks/useWindow";

import { Box, Typography, Menu, IconButton, Popover } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import OFDAvatar from "../ui/OFDAvatar";
import OFDCollapsable from "./OFDCollapsable";
import EntityDisplay from "../display/EntityDisplay";
import OFDMessage from "../ui/OFDMessage";
import OFDMenuItem from "./OFDMenuItem";

import ContextHelp from "../help/ContextHelp";
import FolderWizard from "../wizards/folder/FolderWizard";
import OFDDialog from "./OFDDialog";
import SecurityEditor from "../editors/SecurityEditor";
import ReminderEditor from "../editors/ReminderEditor";
import DocumentUpload from "../editors/DocumentUpload";
import SourceExpenses from "../editors/SourceExpenses";
import OccasionsEditor from "../editors/OccasionsEditor";

const OFDEntityItem = ({
  entityName,
  id,
  entity,
  options,
  onChange,
  onDelete,
  onMenuItemClick,
  headerFields,
  customHeader,
  onOpenRight,
  headerStyles,
  itemIndex,
}) => {
  const {
    userCanView,
    userCanUpdate,
    userCanDelete,
    isAvailable,
    pageDefaultSecurity,
  } = useSecurity();
  const { getLabel } = useLocalization();
  const { consoleLog } = useDebug(false);
  const { hasItemMenu, itemMenu } = useEntity(entityName);
  const { refreshPage } = usePage();
  const { member, memberColor, getMemberAvatar, getMemberColor } = useMember();
  const { getColor } = useColor();
  const { expenses, getSourceExpenses } = useExpense();
  const { isDesktop } = useWindow();

  const {
    data,
    setData,
    getDataById,
    setFieldValue,
    createFolder,
    updateFolder,
    deleteFolder,
    saveFolder,
    saveData,
    formStatus,
    formMessage,
    resetFormMessage,
  } = useForm(entityName);

  const headerRef = useRef();
  const [anchorE1, setAnchorE1] = useState(null);
  const menuOpen = Boolean(anchorE1);

  const [openFolderEditor, setOpenFolderEditor] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openContextHelp, setOpenContextHelp] = useState(false);
  const [helpName, setHelpName] = useState(null);
  const [openDeleteFolder, setOpenDeleteFolder] = useState(false);
  const [header, setHeader] = useState(null);
  const [openSecurityEditor, setOpenSecurityEditor] = useState(false);
  const [openReminder, setOpenReminder] = useState(false);
  const [openFolderSecurity, setOpenFolderSecurity] = useState(false);
  const [menuItems, setMenuItems] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [openExpenses, setOpenExpenses] = useState(false);
  const [openOccasions, setOpenOccasions] = useState(false);

  useEffect(() => {
    getEntityData();
  }, [id, entity]);

  const getEntityData = async () => {
    setData(null);
    if (entity) {
      setData(entity);
    } else {
      await getDataById(id);
    }
  };

  useEffect(() => {
    if (!data) return;

    if (data.hasExpenses) {
      getSourceExpenses({ model: entityName, id: data.id });
    }
  }, [data]);

  const handleMenuIconClick = (e) => {
    setAnchorE1(e.currentTarget);
    // setAnchorE1(entityMenu.current);
  };

  const handleCloseMenu = () => {
    setAnchorE1(null);
  };

  const handleSelectedFolder = (folder) => {
    consoleLog("OFDEntityItem", "handleSelectedFolder", { folder });

    setSelectedFolder(folder);
  };

  const handleOpenFolderEditor = (mode, folder) => {
    consoleLog("OFDEntityItem", "handleOpenFolderEditor", { mode });

    handleCloseMenu();

    if (mode === "add") {
      setSelectedFolder(null);
      setOpenFolderEditor(true);
    } else if (mode === "edit") {
      setSelectedFolder(folder);
      setOpenFolderEditor(true);
    } else if (mode === "delete") {
      setSelectedFolder(folder);
      setOpenDeleteFolder(true);
    } else if (mode === "security") {
      setSelectedFolder(folder);
      setOpenFolderSecurity(true);
    }
  };

  const handleNewFolder = async (folder) => {
    consoleLog("OFDEntityItem", "handleNewFolder", { folder });

    setSelectedFolder(folder);
    createFolder(folder);
    setOpenFolderEditor(false);

    setSelectedFolder(null);
  };

  const handleUpdateFolder = async (folder) => {
    consoleLog("OFDEntityItem", "handleUpdateFolder", { folder });

    updateFolder(folder);
    setOpenFolderEditor(false);

    setSelectedFolder(null);
  };

  const handleDeleteFolder = async (folder) => {
    consoleLog("OFDEntityItem", "handleDeleteFolder", { folder });

    setSelectedFolder(null);
    deleteFolder(folder);
    setOpenFolderEditor(false);
  };

  useEffect(() => {
    consoleLog("OFDEntityItem", "on data changed", { data });

    if (formStatus === "changed") {
      saveEntityItem();
    } else {
      if (selectedFolder && Array.isArray(data.folders)) {
        setSelectedFolder((current) => {
          if (!current) return null;
          if (!data || !Array.isArray(data.folders)) return null;

          let folder = data.folders.find(
            (f) => f.hasOwnProperty("id") && f.id === current.id
          );

          return folder;
        });
      }
    }

    buildHeader();
  }, [data, formStatus]);

  useEffect(() => {
    buildMenuItems();
  }, [expenses]);

  const buildHeader = () => {
    if (!entity) return;

    if (customHeader) {
      setHeader(customHeader(entity));
    } else if (headerFields) {
      let headerValue = [];
      for (const field of headerFields) {
        let variant = "h6";
        if (headerValue.length > 0) {
          variant = "caption";
        }
        headerValue.push(
          <Typography
            key={field.name}
            variant={variant}
            sx={{ lineHeight: "1.5rem" }}
          >
            {fieldValue(field)}
          </Typography>
        );
      }
      setHeader(<Box>{headerValue}</Box>);
    } else {
      setHeader(
        <Typography
          variant="h6"
          sx={{ lineHeight: "1.5rem" }}
        >
          {entity.name}
        </Typography>
      );
    }
  };

  const buildMenuItems = () => {
    if (!entity) return;
    if (!hasItemMenu()) return;
    if (!userCanUpdate(entity.security, entity.addedBy)) return;

    let menuOptions = [];
    for (const menuItem of itemMenu()) {
      if (menuItem.hasOwnProperty("securityLevel")) {
        if (!isAvailable(menuItem.securityLevel, entity.addedBy)) {
          continue;
        }
      }
      let additionalText = null;
      if (menuItem.name === "expenses") {
        if (Array.isArray(expenses)) {
          additionalText = `(${expenses.length})`;
        }
      }
      menuOptions.push(
        <OFDMenuItem
          key={menuItem.name}
          {...menuItem}
          onClick={handleMenuItemClick}
          additionalText={additionalText}
        />
      );
    }

    setMenuItems(menuOptions);
  };

  const fieldValue = (field) => {
    if (!entity[field.name]) return null;
    if (field.type === "string") {
      return entity[field.name];
    }
    if (field.type === "date") {
      return format(entity[field.name], "EEE MMM dd");
    }
  };

  const saveEntityItem = async () => {
    consoleLog("OFDEntityItem", "saveEntityItem");

    const results = await saveData();
  };

  const handleEdit = async () => {
    consoleLog("OFDEntityItem", "handleEdit", { selectedFolder });
    setData(null);
    await getEntityData();
    onMenuItemClick("edit", entity);

    if (onChange) onChange();
  };

  const handleOpenContextHelp = (name) => {
    setHelpName(name);
    setOpenContextHelp(true);
  };

  const handleDeleteConfirmation = async (response) => {
    setOpenDeleteFolder(false);
    if (response === "yes") {
      await handleDeleteFolder(selectedFolder);
    }

    setSelectedFolder(null);
  };

  const handleMenuItemClick = (name) => {
    switch (name) {
      case "addFolder":
        handleOpenFolderEditor("add");
        break;

      case "whatIsAFolder":
        handleOpenContextHelp("whatIsAFolder");
        break;

      case "security":
        setOpenSecurityEditor(true);
        break;

      case "reminder":
        setOpenReminder(true);
        break;

      case "upload":
        setOpenUpload(true);
        break;

      case "expenses":
        setOpenExpenses(true);
        break;

      case "occasions":
        setOpenOccasions(true);
        break;
    }

    if (onMenuItemClick) {
      onMenuItemClick(name, entity);
    }
    handleCloseMenu();
  };

  const handleSecurityChange = async (newSecurity) => {
    setFieldValue("security", newSecurity);

    // await saveEntityItem();
  };

  const handleFolderSecurity = async (newSecurity) => {
    let updatedFolder = { ...selectedFolder, security: { ...newSecurity } };

    await saveFolder(updatedFolder);
    setSelectedFolder(null);
    setOpenFolderSecurity(false);
  };

  const handleDocumentUploaded = async () => {
    setOpenUpload(false);
    setData(null);
    await getEntityData();
    if (onChange) onChange();
  };

  const handleOpenRight = (open) => {
    if (open) {
      onOpenRight(entityName, id, entity);
    } else {
      onOpenRight(null, null, null);
    }
  };

  const getHeaderStyle = () => {
    if (headerStyles) return { ...headerStyles };
    // return { ...getColor({ color: "grey", shade: "300" }) };
    if (entityName === "Homework") {
      return {
        ...getColor({ color: getMemberColor(entity.memberId), shade: 100 }),
      };
    } else {
      return { ...memberColor({ shade: 100 }) };
    }
  };

  if (!entity || !userCanView(entity.security, entity.addedBy)) return null;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <OFDCollapsable
          header={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                ref={headerRef}
                sx={{ display: "flex", alignItems: "center" }}
                gap={1}
              >
                {options &&
                options.showAvatar &&
                (entity.avatar || entity.color) ? (
                  <OFDAvatar
                    avatar={entity.avatar}
                    name={entity.name}
                    borderColor={entity.color || "grey"}
                    size="medium"
                  />
                ) : null}
                {entityName === "Homework" && entity.memberId ? (
                  <OFDAvatar
                    avatar={getMemberAvatar(entity.memberId).avatar}
                    name={getMemberAvatar(entity.memberId).name}
                    borderColor={getMemberAvatar(entity.memberId).borderColor}
                    size="medium"
                  />
                ) : null}
                {header}
              </Box>

              {menuItems ? (
                <>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={menuOpen ? "long-menu" : undefined}
                    aria-expanded={menuOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleMenuIconClick}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Popover
                    id="long-menu"
                    anchorEl={headerRef.current}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    {menuItems}
                  </Popover>
                </>
              ) : null}
            </Box>
          }
          openRight={isDesktop ? true : false}
          onOpenRight={handleOpenRight}
          headerStyle={getHeaderStyle()}
        >
          {!isDesktop ? (
            <EntityDisplay
              entityName={entityName}
              id={id}
              entityData={data}
              onChange={handleEdit}
              onFolderSelected={handleSelectedFolder}
              onFolderEdit={handleOpenFolderEditor}
            />
          ) : null}
        </OFDCollapsable>
      </Box>

      {openFolderEditor ? (
        <FolderWizard
          open={openFolderEditor}
          onClose={() => setOpenFolderEditor(false)}
          entityName={entityName}
          folder={selectedFolder}
          onCreateFolder={handleNewFolder}
          onUpdateFolder={handleUpdateFolder}
        />
      ) : null}

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <ContextHelp
        open={openContextHelp}
        onClose={() => setOpenContextHelp(false)}
        name={helpName}
      />

      <OFDDialog
        open={openDeleteFolder}
        title="confirmDelete"
        description="deleteFolder"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleDeleteConfirmation}
      />

      {openSecurityEditor ? (
        <SecurityEditor
          open={openSecurityEditor}
          onClose={() => setOpenSecurityEditor(false)}
          document={entity}
          onChange={handleSecurityChange}
          entityName={entityName}
        />
      ) : null}

      {openFolderSecurity ? (
        <SecurityEditor
          open={openFolderSecurity}
          onClose={() => setOpenFolderSecurity(false)}
          folder={selectedFolder}
          onChange={handleFolderSecurity}
          entityName={entityName}
        />
      ) : null}

      {openReminder ? (
        <ReminderEditor
          open={openReminder}
          onClose={() => setOpenReminder(false)}
          source={{
            model: entityName,
            id: id,
          }}
          onSave={handleEdit}
        />
      ) : null}

      {openUpload ? (
        <DocumentUpload
          open={openUpload}
          onClose={() => setOpenUpload(false)}
          source={{ model: entityName, id: id }}
          onUpload={handleDocumentUploaded}
        />
      ) : null}

      {openExpenses ? (
        <SourceExpenses
          open={openExpenses}
          onClose={() => setOpenExpenses(false)}
          source={{ model: entityName, id: id }}
        />
      ) : null}

      {openOccasions ? (
        <OccasionsEditor
          open={openOccasions}
          onClose={() => setOpenOccasions(false)}
          source={{ model: entityName, id: id }}
          onChange={onChange}
          name={data.name}
        />
      ) : null}
    </>
  );
};

export default OFDEntityItem;
