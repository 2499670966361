import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";

import { Box, Typography } from "@mui/material";

import GoldenToken from "../../assets/images/GoldToken_Star.png";
import OFDButton from "../ui/OFDButton";
import OFDIcon from "../ui/OFDIcon";

const AssignedChore = ({ chore, onChange }) => {
  const { getLabel } = useLocalization();
  const { postData } = useApi();

  const handleButtonClick = async () => {
    const data = {
      memberId: chore.memberId,
      choreId: chore.choreId,
      activityId: chore.activityId,
      status: "completed",
    };

    const results = await postData({
      entityName: "Member",
      action: "updateChoreActivity",
      data,
    });

    onChange();
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <Typography sx={{ fontWeight: 700 }}>{chore.name}</Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            gap={1}
          >
            <Typography>{chore.tokenValue}</Typography>
            <img
              src={GoldenToken}
              height="24px"
            />

            <Box
              sx={{ width: "70px", display: "flex", justifyContent: "center" }}
            >
              {chore.status === "outstanding" ? (
                <OFDButton
                  variant="outlined"
                  label={getLabel("done")}
                  onClick={handleButtonClick}
                />
              ) : null}

              {chore.status === "completed" ? (
                <OFDIcon
                  name="checkcircle"
                  color="grey"
                />
              ) : null}

              {chore.status === "approved" ? (
                <OFDIcon
                  name="checkcircle"
                  color="green"
                />
              ) : null}
            </Box>
          </Box>
        </Box>

        {chore.description ? (
          <Typography
            ml=".5rem"
            variant="caption"
          >
            {chore.description}
          </Typography>
        ) : null}
      </Box>
    </>
  );
};

export default AssignedChore;
