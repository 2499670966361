import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography } from "@mui/material";

import OFDAvatar from "./OFDAvatar";

const OFDDisplayMember = ({
  label,
  value,
  styleoverride,
  onClick,
  message,
  error,
  security,
  entryAddedBy,
}) => {
  const { getMemberAvatar } = useMember();
  const { getLabel, getMessage } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box>
      <Box sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}>
        <Box
          onClick={onClick}
          sx={{ width: "100%" }}
        >
          <Typography variant="overline">{getLabel(label)}</Typography>

          <Box sx={{ display: "flex" }}>
            {value ? (
              <Box key={value}>
                <OFDAvatar {...getMemberAvatar(value)} />
              </Box>
            ) : (
              <Typography>-</Typography>
            )}
          </Box>
        </Box>
      </Box>
      {message ? (
        <Box sx={{ marginLeft: "1rem" }}>
          <Typography
            variant="caption"
            sx={{ color: error ? "#ff0000" : "#ccc" }}
          >
            {getMessage(message)}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDDisplayMember;
