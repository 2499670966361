import { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import useMember from "../../../hooks/useMember";
import useWindow from "../../../hooks/useWindow";
import useSecurity from "../../../hooks/useSecurity";
import useLocalization from "../../../hooks/useLocalization";

import { Box, Button, Typography } from "@mui/material";

import OFDModal from "../../layout/OFDModal";

import OFDButton from "../../ui/OFDButton";
import OFDFieldGroup from "../../ui/OFDFieldGroup";

import FolderIntro from "./FolderIntro";
import AvailableFields from "./AvailableFields";
import FolderName from "./FolderName";
import FolderSecurity from "./FolderSecurity";

const defaultSecurityLevel = {
  view: { securityLevel: "family" },
  update: { securityLevel: "addedBy" },
  delete: { securityLevel: "addedBy" },
};

const steps = [
  { step: 0, label: "intro" },
  { step: 1, label: "availableFields" },
  { step: 2, label: "folderName" },
  { step: 3, label: "folderSecurity" },
];

const FolderWizard = ({
  entityName,
  folder,
  open,
  onClose,
  onCreateFolder,
  onUpdateFolder,
}) => {
  const { member } = useMember();
  const { windowWidth } = useWindow();
  const { userIsCreator } = useSecurity();
  const { getLabel, getMessage } = useLocalization();

  const [currentStep, setCurrentStep] = useState(!folder ? 0 : 1);
  const [firstStep, setFirstStep] = useState(!folder ? 0 : 1);
  const [availableSteps, setAvailableSteps] = useState(steps.length - 1);

  const [fields, setFields] = useState(undefined);
  const [folderName, setFolderName] = useState(undefined);
  const [folderSecurity, setFolderSecurity] = useState(undefined);

  useEffect(() => {
    if (folder) {
      setFields(folder.fields);
      setFolderName(folder.name);
      setFolderSecurity(folder.security || { ...defaultSecurityLevel });
      if (userIsCreator(folder.addedBy)) {
        setAvailableSteps(steps.length - 1);
      } else {
        setAvailableSteps(steps.length - 2);
      }
    } else {
      setFields([]);
      setFolderName("");
      setFolderSecurity({ ...defaultSecurityLevel });
      setAvailableSteps(steps.length - 1);
    }
  }, [folder]);

  const handleBack = () => {
    setCurrentStep((current) => {
      if (current === firstStep) return current;

      return current - 1;
    });
  };

  const handleNext = () => {
    setCurrentStep((current) => {
      if (current >= steps.length - 1) return current;

      return current + 1;
    });
  };

  const handleChangeFields = (newFields) => {
    setFields(newFields);
  };

  const handleSave = () => {
    let newFolder;
    let newFields = [];

    for (const field of fields) {
      newFields.push({
        id: field.id,
        label: field.label,
        fieldType: field.fieldType,
        security: field.security,
        value: field.value,
        addedBy: field.addedBy ? field.addedBy : member.id,
      });
    }

    if (!folder) {
      newFolder = {
        id: uuidV4(),
        name: folderName,
        fields: newFields,
        security: folderSecurity ? folderSecurity : { ...defaultSecurityLevel },
        addedBy: member.id,
      };

      onCreateFolder(newFolder);
    } else {
      newFolder = {
        ...folder,
        name: folderName,
        fields: newFields,
        security: folder.security
          ? folder.security
          : folderSecurity
          ? folderSecurity
          : { ...defaultSecurityLevel },
        addedBy: folder.addedBy ? folder.addedBy : member.id,
      };

      onUpdateFolder(newFolder);
    }
  };

  if (fields === undefined || folderName === undefined) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="folderEditor"
        color="grey"
        shade="900"
      >
        <Box sx={{ padding: "1rem", height: "100%", backgroundColor: "#fff" }}>
          {currentStep === 0 && !folder ? (
            <FolderIntro entityName={entityName} />
          ) : null}

          {currentStep === 1 ? (
            <AvailableFields
              entityName={entityName}
              open={true}
              isNewFolder={!Boolean(folder)}
              onChange={handleChangeFields}
              fields={fields}
            />
          ) : null}

          {currentStep === 2 ? (
            <FolderName
              folderName={folderName}
              onChange={(newValue) => setFolderName(newValue)}
            />
          ) : null}

          {currentStep === 3 && (!folder || userIsCreator(folder.addedBy)) ? (
            <FolderSecurity
              folderSecurity={folderSecurity}
              onChange={(newValue) => setFolderSecurity(newValue)}
            />
          ) : null}

          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              left: "1rem",
              right: "1rem",
              height: "4rem",
              borderTop: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "1rem",
              paddingTop: ".5rem",
            }}
            gap={2}
          >
            <OFDButton
              id="back"
              variant="outlined"
              disabled={currentStep === firstStep}
              onClick={handleBack}
              label={getLabel("back")}
              width="auto"
              color="grey"
              shade={900}
            />

            <OFDButton
              id="next"
              variant="contained"
              onClick={currentStep < availableSteps ? handleNext : handleSave}
              color="grey"
              shade={900}
              label={getLabel(
                currentStep < availableSteps
                  ? "next"
                  : folder
                  ? "updateFolder"
                  : "createFolder"
              )}
              width="auto"
            />
          </Box>
        </Box>
      </OFDModal>
    </>
  );
};

export default FolderWizard;
