import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDTextField from "../../ui/OFDTextField";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const GroceryName = ({ name, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">{getWizard("grocery").name.title}</Typography>

      <Stack spacing={2}>
        {getWizard("grocery").name.instructions.map((instruction, index) => (
          <Typography key={`instruction-${index}`}>{instruction}</Typography>
        ))}

        <OFDFieldContainer>
          <OFDTextField
            id="name"
            label="name"
            value={name}
            onChange={(newValue) => onChange(newValue)}
            error={error}
            message={message}
            fullWidth
          />
        </OFDFieldContainer>
      </Stack>
    </Box>
  );
};

export default GroceryName;
