import { useEffect } from "react";
import { format, parseISO } from "date-fns";

import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

import { Box, Modal, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";

const ReminderDisplay = ({ reminder }) => {
  const { memberColor } = useMember();
  const { getLabel } = useLocalization();

  if (Array.isArray(reminder) || !reminder) return;

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "12px",
        marginTop: "3rem",
        padding: ".5rem",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="caption">
          {format(parseISO(reminder.datetime), "hh:mma")}
        </Typography>
      </Box>

      <Box
        sx={{
          borderBottom: "1px solid #ccc",
          display: "flex",
        }}
        gap={1}
      >
        <OFDIcon name={reminder.category} />
        <Typography
          variant="h6"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          {reminder.name}
        </Typography>
      </Box>

      {reminder.description ? (
        <Box>
          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            {reminder.description}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default ReminderDisplay;
