import React from "react";

import useLocalization from "../../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDGroceryItems from "../../ui/OFDGroceryItems";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const GroceryItems = ({ groceryItems, onChange }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  const handleChange = (newItems) => {
    onChange(newItems);
  };

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <Typography
          variant="h5"
          sx={{ marginBottom: "1rem" }}
        >
          {getWizard("grocery").groceryItems.title}
        </Typography>

        <OFDGroceryItems
          value={groceryItems}
          onChange={handleChange}
        />
      </Box>
    </>
  );
};

export default GroceryItems;
