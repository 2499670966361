import { useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

const OFDDisplayNote = ({
  label,
  value,
  styleoverride,
  onClick,
  textOnly,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      {textOnly ? (
        <Typography
          style={{ width: "100%", whiteSpace: "pre-wrap", ...styleoverride }}
        >
          {value}
        </Typography>
      ) : (
        <Box
          sx={displayStyle}
          onClick={onClick}
        >
          <Typography variant="overline">{getLabel(label)}</Typography>

          {value && value.length > 0 ? (
            value.length > 5 && value.substring(0, 5) === "<div>" ? (
              <div dangerouslySetInnerHTML={{ __html: value }}></div>
            ) : (
              <Typography sx={{ whiteSpace: "pre-wrap" }}>{value}</Typography>
            )
          ) : (
            <Typography>-</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default OFDDisplayNote;
