import { useEffect, useState } from "react";

import useLocalization from "../../../hooks/useLocalization";
import useSecurity from "../../../hooks/useSecurity";

import { Box, Button } from "@mui/material";

import OFDModal from "../../layout/OFDModal";
import RecipeIntro from "./RecipeIntro";
import RecipeName from "./RecipeName";
import RecipeIngredients from "./RecipeIngredients";
import RecipeInstructions from "./RecipeInstructions";
import RecipePreparation from "./RecipePreparation";
import RecipeImage from "./RecipeImage";
import RecipeSummary from "./RecipeSummary";
import RecipeLink from "./RecipeLink";
import RecipeSecurity from "./RecipeSecurity";

import OFDButton from "../../ui/OFDButton";

const steps = [
  { step: 0, label: "intro" },
  { step: 1, label: "name" },
  { step: 2, label: "link" },
  { step: 3, label: "ingredients" },
  { step: 4, label: "instructions" },
  { step: 5, label: "preparation" },
  { step: 6, label: "image" },
  { step: 7, label: "security" },
];

const RecipeWizard = ({
  recipe,
  open,
  onClose,
  onCreateRecipe,
  onUpdateRecipe,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { pageDefaultSecurity } = useSecurity();

  const [currentStep, setCurrentStep] = useState(0);
  const [firstStep, setFirstStep] = useState(0);

  const [name, setName] = useState(null);
  const [category, setCategory] = useState(null);
  const [ingredients, setIngredients] = useState(null);
  const [instructions, setInstructions] = useState(null);
  const [preparation, setPreparation] = useState(null);
  const [image, setImage] = useState(null);
  const [externalUrl, setExternalUrl] = useState(null);
  const [security, setSecurity] = useState(null);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (open) {
      if (recipe) {
        setCurrentStep(1);
        setFirstStep(1);
      } else {
        setName(null);
        setCategory("");
        setIngredients(null);
        setInstructions(null);
        setPreparation(null);
        setExternalUrl(null);
        setImage(null);
        setSecurity(pageDefaultSecurity("Recipe"));

        setCurrentStep(0);
        setFirstStep(0);
      }
    }
  }, [open]);

  useEffect(() => {
    if (recipe) {
      setName({ name: recipe.name, description: recipe.description || null });
      setCategory(recipe.category);
      setIngredients(recipe.ingredients);
      setInstructions(recipe.instrictions);
      setPreparation({
        preparationTime: recipe.preparationTime || null,
        servings: recipe.servings || null,
        mealType: recipe.mealType || [],
      });
      setImage(recipe.image || null);
      setExternalUrl(recipe.externalUrl || null);
      setSecurity(recipe.security || pageDefaultSecurity("Recipe"));

      setCurrentStep(1);
      setFirstStep(1);
    }
  }, [recipe]);

  const handleBack = () => {
    setCurrentStep((current) => {
      if (current === firstStep) return current;

      return current - 1;
    });
  };

  const handleNext = () => {
    if (!stepValidated()) return;

    if (currentStep === steps.length - 1) {
      handleSave();
    } else {
      setCurrentStep((current) => {
        if (current >= steps.length - 1) return current;

        return current + 1;
      });
    }
  };

  const stepValidated = () => {
    setError(false);
    setMessage(null);

    if (currentStep === 0) return true;

    if (currentStep === 1) {
      if (!name) {
        setError(true);
        setMessage(getMessage("isEmpty"));
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    let newRecipe;

    if (!recipe) {
      newRecipe = {
        ...name,
        category,
        ingredients,
        instructions,
        ...preparation,
        image,
        externalUrl,
        security,
      };

      onCreateRecipe(newRecipe);
    } else {
      newRecipe = {
        ...recipe,
        ...name,
        category,
        ingredients,
        instructions,
        ...preparation,
        image,
        externalUrl,
        security,
      };
      onUpdateRecipe(newRecipe);
    }
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="recipeWizard"
        color="grey"
        shade="900"
      >
        <Box sx={{ padding: "1rem", height: "calc(100% - 3rem)" }}>
          {currentStep === 0 && !recipe ? <RecipeIntro /> : null}

          {currentStep === 1 ? (
            <RecipeName
              value={name}
              onChange={(newValue) => setName(newValue)}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep === 2 ? (
            <RecipeLink
              value={externalUrl}
              onChange={(newValue) => setExternalUrl(newValue)}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep === 3 ? (
            <RecipeIngredients
              ingredients={ingredients}
              onChange={(newValue) => setIngredients(newValue)}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep === 4 ? (
            <RecipeInstructions
              instructions={instructions}
              onChange={(newValue) => setInstructions(newValue)}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep === 5 ? (
            <RecipePreparation
              preparation={preparation}
              onChange={(newValue) => setPreparation(newValue)}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep === 6 ? (
            <RecipeImage
              image={image}
              onChange={(newValue) => setImage(newValue)}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep === 7 ? (
            <RecipeSecurity
              open={true}
              entityName="Recipe"
              security={security}
              onChange={(newValue) => setSecurity(newValue)}
            />
          ) : null}
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "1rem",
            right: "1rem",
            height: "4rem",
            borderTop: "1px solid #ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "1rem",
            paddingTop: ".5rem",
          }}
          gap={2}
        >
          <OFDButton
            id="back"
            variant="outlined"
            disabled={currentStep === firstStep}
            onClick={handleBack}
            label={getLabel("back")}
            width="auto"
            color="grey"
            shade={900}
          />

          <OFDButton
            id="next"
            variant="contained"
            onClick={currentStep < steps.length - 1 ? handleNext : handleSave}
            color="grey"
            shade={900}
            label={getLabel(
              currentStep < steps.length - 1
                ? "next"
                : recipe
                ? "updateRecipe"
                : "createRecipe"
            )}
            width="auto"
          />
        </Box>
      </OFDModal>
    </>
  );
};

export default RecipeWizard;
