import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { useSelector, useDispatch } from "react-redux";
import { pageActions } from "./store/page";

import "./firebase.js";

import { useChannel } from "ably/react";

import useMember from "./hooks/useMember";
import useSecurity from "./hooks/useSecurity";
import useFamily from "./hooks/useFamily";
import useMessaging from "./hooks/useMessaging";
import useApi from "./hooks/useApi";
import useDebug from "./hooks/useDebug.js";
import useNotifications from "./hooks/useNotifications.js";

import OFD from "./components/pages/OFD";
import Signup from "./components/pages/public/signup/Signup";
import Login from "./components/pages/public/login/Login";
import DisplayReminders from "./components/display/DisplayReminders";
import DisplayNotifications from "./components/display/DisplayNotifications";
import PasswordReset from "./components/pages/public/passwordReset/PasswordReset";
import PrivacyPolicy from "./components/pages/public/privacy/PrivacyPolicy";
import VerifyEmail from "./components/pages/public/VerifyEmail";
import Test from "./components/pages/public/Test";
import Monitor from "./components/pages/public/Monitor";

function App() {
  const { getMemberState, refreshMemberState } = useMember();
  const { isAuthenticated } = useSecurity();
  const { addReminder, addNotification } = useMessaging();
  const { family } = useFamily();
  const { getData, postData } = useApi();
  const member = useSelector((state) => state.member);
  const dispatch = useDispatch();
  const { logToServer } = useDebug(true);
  const { displayNotification } = useNotifications();

  const [message, setMessage] = useState([]);
  const [pushToken, setPushToken] = useState(undefined);

  const { channel } = useChannel(member.id, (channelMessage) => {
    setMessage(channelMessage);
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    refreshState();

    const refreshPage = () => {
      dispatch(pageActions.refreshPage());
    };

    if (document.readyState === "complete") {
      refreshPage();
    } else {
      window.addEventListener("load", refreshPage);
      window.addEventListener("push-notification", receivePushNotification);
    }

    return () => {
      window.removeEventListener("load", refreshPage);
      window.removeEventListener("push-notification", receivePushNotification);
    };
  }, [document.readyState]);

  const receivePushNotification = (event) => {
    displayNotification(event.detail);
  };

  useEffect(() => {
    if (!message || Array.isArray(message)) return;

    if (message.name === "reminder") {
      addReminder(message.data);
    } else if (message.name === "notification") {
      addNotification(message.data);
    }
  }, [message]);

  const refreshState = async () => {
    await getMemberState();
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/login/*"
              element={<Login />}
            />

            <Route
              path="/signup"
              element={<Signup />}
            />

            <Route
              path="/signup/*"
              element={<Signup />}
            />

            <Route
              path="/passwordreset"
              element={<PasswordReset />}
            />

            <Route
              path="/passwordreset/*"
              element={<PasswordReset />}
            />

            <Route
              path="/privacy"
              element={<PrivacyPolicy />}
            />

            <Route
              path="privacy"
              element={<PrivacyPolicy />}
            />

            <Route
              path="verifyemail"
              element={<VerifyEmail />}
            />

            <Route
              path="test"
              element={<Test />}
            />

            <Route
              path="monitor"
              element={<Monitor />}
            />

            <Route
              path="/*"
              element={<Login />}
            />
          </Route>

          <Route element={<ProtectedRoutes />}>
            <Route
              path="/app/*"
              element={<OFD />}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>

      <DisplayReminders />

      <DisplayNotifications />
    </>
  );
}

export default App;
