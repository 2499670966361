import React from "react";

import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

const Final = () => {
  const { getWizard } = useLocalization();

  return (
    <Box sx={{ padding: "1rem" }}>
      <Stack spacing={2}>
        <Typography variant="h5">{getWizard("setup").final.title}</Typography>
        {getWizard("setup").final.text.map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default Final;
