import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayNote from "./OFDDisplayNote";
import OFDCollapsable from "../layout/OFDCollapsable";
import OFDDisplayText from "./OFDDisplayText";
import OFDDisplayCurrency from "./OFDDisplayCurrency";
import OFDButton from "./OFDButton";
import ExpenseEditor from "../editors/ExpenseEditor";

const OFDDisplayGasLog = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel, displayLocalCurrency, getListItemLabel } =
    useLocalization();
  const { userCanView } = useSecurity();

  const [showExpense, setShowExpense] = useState(false);
  const [expenseId, setExpenseId] = useState(null);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const getHeader = (item) => {
    let text = "";
    if (item.date) {
      text += `${format(item.date, "MMM dd, yyyy")} : `;
    }

    if (item.cost) {
      text += `${displayLocalCurrency(item.cost)}`;
    }

    return text;
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleOpenExpense = (item) => {
    setExpenseId(item.expenseId);
    setShowExpense(true);
  };

  const handleCloseExpense = () => {
    setShowExpense(false);
    setExpenseId(null);
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={displayStyle}>
        <Box onClick={handleClick}>
          <Typography variant="overline">{getLabel(label)}</Typography>
        </Box>

        <Box>
          <Stack spacing={0.5}>
            {Array.isArray(value) && value.length > 0 ? (
              value?.map((item) => (
                <OFDCollapsable
                  key={item.id}
                  compressed
                  header={
                    <Typography onClick={handleClick}>
                      {getHeader(item)}
                    </Typography>
                  }
                  styleOverride={{ backgroundColor: "#e0e0e0" }}
                >
                  <Box
                    sx={{ display: "flex", marginTop: ".5rem" }}
                    gap={1}
                  >
                    <Box sx={{ width: "33%" }}>
                      <OFDDisplayText
                        value={item.odometer}
                        label="odometer"
                      />
                    </Box>
                    <Box sx={{ width: "33%" }}>
                      <OFDDisplayText
                        value={item.volume}
                        label="volume"
                      />
                    </Box>
                    <Box sx={{ width: "33%" }}>
                      <OFDDisplayText
                        value={getListItemLabel("volumeTypes", item.volumeType)}
                        label="type"
                      />
                    </Box>
                  </Box>

                  {item.note ? (
                    <OFDDisplayNote
                      label="note"
                      value={item.note}
                      styleoverride={{ fontSize: "12px", color: "#535353" }}
                    />
                  ) : null}

                  {item.expenseId ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: ".5rem",
                        paddingBottom: ".5rem",
                      }}
                    >
                      <OFDButton
                        variant="contained"
                        label="expense"
                        color="grey"
                        shade={900}
                        iconName="addExpense"
                        onClick={() => handleOpenExpense(item)}
                      />
                    </Box>
                  ) : null}
                </OFDCollapsable>
              ))
            ) : (
              <Typography>-</Typography>
            )}
          </Stack>
        </Box>
      </Box>

      {expenseId ? (
        <ExpenseEditor
          open={showExpense}
          expenseId={expenseId}
          onClose={handleCloseExpense}
        />
      ) : null}
    </>
  );
};

export default OFDDisplayGasLog;
