import { parseISO } from "date-fns";

import useSecurity from "../../hooks/useSecurity";
import useWindow from "../../hooks/useWindow";

import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";

import OFDFormControl from "./OFDFormControl";

const OFDDateRange = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { userCanView, userCanUpdate } = useSecurity();
  const { isMobile } = useWindow();

  const getDateRange = () => {
    if (!value || !Array.isArray(value) || value.length < 2) return [];

    let dateRange = [];
    for (const date of value) {
      if (typeof date === "string") {
        dateRange.push(parseISO(date));
      } else {
        dateRange.push(date);
      }
    }

    return dateRange;
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <OFDFormControl
      label={label ? label : null}
      message={message}
      error={error}
      sx={{ padding: "0px", border: "none" }}
    >
      <DateRangeCalendar
        id={id}
        calendars={1}
        value={getDateRange()}
        onChange={onChange}
        disabled={isDisabled()}
      />
    </OFDFormControl>
  );
};

export default OFDDateRange;
