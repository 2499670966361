import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import {
  Box,
  Autocomplete,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";

const OFDAutoComplete = ({
  id,
  value,
  label,
  onChange,
  required,
  disabled,
  options,
  security,
  entryAddedBy,
  message,
  error,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box id={id}>
      <FormControl
        fullWidth
        size="small"
      >
        <Autocomplete
          freeSolo
          options={options || []}
          value={value}
          required={required || false}
          onInputChange={(e, newValue) => {
            onChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={getLabel(label)}
              size="small"
              required={required || false}
              disabled={isDisabled()}
            />
          )}
        />

        <FormHelperText error={error}>{getMessage(message)}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default OFDAutoComplete;
