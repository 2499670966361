import { useEffect, useState, useCallback, useRef } from "react";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useData from "../../hooks/useData";
import useSecurity from "../../hooks/useSecurity";
import useWindow from "../../hooks/useWindow";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDTextNote from "./OFDTextNote";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDAutoComplete from "./OFDAutoComplete";
import OFDDisplayPicture from "./OFDDisplayPicture";

import OFDDialog from "../layout/OFDDialog";
import OFDDisplayGroceryItem from "./OFDDisplayGroceryItem";
import OFDDisplayGroceryItems from "./OFDDisplayGroceryItems";
import FieldEditorModal from "../editors/FieldEditorModal";
import OFDFieldContainer from "./OFDFieldContainer";

const fieldsTemplate = {
  id: null,
  department: "",
  item: "",
  quantity: "",
  note: "",
  image: null,
};

const OFDGroceryItems = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
  color,
}) => {
  const { getLabel, grocery } = useLocalization();
  const { getColor } = useColor();
  const { sortByFields, newId } = useData();
  const { userCanView, userCanUpdate } = useSecurity();
  const { windowHeight, isMobile } = useWindow();

  const [fields, setFields] = useState({ ...fieldsTemplate });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");
  const [addImage, setAddImage] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [fieldMessages, setFieldMessages] = useState({});

  const [fieldsHeight, setFieldsHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [listHeight, setListHeight] = useState(200);

  // const fieldsRef = useCallback((node) => {
  //   if (!node) return;

  //   setFieldsHeight(node.clientHeight);
  // });

  // const containerRef = useCallback((node) => {
  //   if (!node) return;
  //   setContainerHeight(node.clientHeight);
  // });

  const fieldsRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (!containerRef.current || !fieldsRef.current) {
      if (isMobile) {
        setListHeight(windowHeight * 0.5);
      } else {
        setListHeight(windowHeight * 0.4);
      }
    } else {
      setListHeight(
        containerRef.current.clientHeight - fieldsRef.current.clientHeight - 20
      );
    }
  }, [containerRef.current, fieldsRef.current, windowHeight]);

  // const getListHeight = () => {
  //   if (!containerRef.current || !fieldsRef.current) return 600;

  //   return containerRef.current.clientHeight - fieldsRef.current.clientHeight;
  // };

  const handleCancel = () => {
    setEditMode("add");
    setFields({ ...fieldsTemplate });
    setSelectedItem(null);
    setFieldMessages({});
  };

  const validItem = () => {
    if (!fields) return false;

    let messages = {};

    let isValid = true;

    if (fields.item === undefined || fields.item.trim().length === 0) {
      messages.item = { error: true, message: "isEmpty" };
      isValid = false;
    }

    if (editMode === "add") {
      let currentIndex = -1;
      if (Array.isArray(value)) {
        currentIndex = value.findIndex((item) => item.item === fields.item);
      }
      if (currentIndex > -1) {
        messages.item = { error: true, message: "alreadyExists" };
        isValid = false;
      }
    }

    setFieldMessages(messages);
    return isValid;
  };

  const handleAddItem = () => {
    if (!validItem()) return;

    setEditMode("add");
    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields, id: newId() }];
    } else {
      list = [{ ...fields, id: newId() }];
    }

    handleChange(list);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
    setFieldMessages({});
  };

  const handleUpdateItem = () => {
    if (!validItem()) return;

    let list = [];

    for (const item of value) {
      if (item.id !== selectedItem.id) {
        list.push({ ...item });
      } else {
        list.push({ ...item, ...fields });
      }
    }

    handleChange(list);
  };

  const handleDeleteItem = () => {
    if (!selectedItem) return;

    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const budgetItem of value) {
        if (budgetItem.id !== selectedItem.id) {
          list.push({ ...budgetItem });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const handleChange = (list) => {
    let newList = sortByFields(list, ["department", "item"]);

    onChange(newList);
    setFields({ ...fieldsTemplate });
    setEditMode("add");
    setSelectedItem(null);
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      let months = current.months;
      if (field === "months") months = newValue;
      if (field === "budgetFrequency" && newValue !== "specified")
        months = null;

      return {
        ...current,
        [field]: newValue,
        months,
      };
    });
  };

  const getDepartments = () => {
    let options = [];

    if (Array.isArray(value)) {
      for (const item of value) {
        if (item.department && !options.includes(item.department)) {
          options.push(item.department);
        }
      }
    }

    for (const department of grocery.departments) {
      if (!options.includes(department)) {
        options.push(department);
      }
    }

    options.sort();

    return options;
  };

  const getItems = () => {
    let selectedDepartent = getFieldValue("department");
    const items = grocery.items.filter(
      (item) => item.department === selectedDepartent
    );
    let list = items.map((item) => item.item);

    list.sort();

    return list;
  };

  const handleAddImage = () => {
    setAddImage((current) => {
      let useImage = !current;

      if (useImage) {
        updateFieldValue("image", null);
      }

      return useImage;
    });
  };

  const handleUploadImage = () => {
    setUploadImage(true);
  };

  const handleCancelImage = () => {
    setUploadImage(false);
  };

  const handleChangeImage = (newImage) => {
    setUploadImage(false);
    updateFieldValue("image", newImage);
  };

  const getFieldMessage = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return null;

    return fieldMessages[field].message;
  };

  const getFieldError = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return false;

    return fieldMessages[field].error;
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box
        ref={containerRef}
        id={id}
        sx={{
          height: `${windowHeight - 176}px`,
        }}
      >
        <Box ref={fieldsRef}>
          <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Typography
              variant="h5"
              align="center"
            >
              {getLabel(label)}
            </Typography>
          </Box>

          <Stack
            sx={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
            spacing={1}
          >
            <Box
              sx={{ display: "flex" }}
              gap={1}
            >
              <Box sx={{ width: "45%" }}>
                <OFDAutoComplete
                  id="department"
                  label="department"
                  value={getFieldValue("department") || ""}
                  onChange={(newValue) =>
                    updateFieldValue("department", newValue)
                  }
                  options={getDepartments()}
                  fullWidth
                />
              </Box>
              <Box sx={{ width: "65%" }}>
                <OFDAutoComplete
                  id="item"
                  label="item"
                  value={getFieldValue("item") || ""}
                  onChange={(newValue) => updateFieldValue("item", newValue)}
                  options={getItems()}
                  fullWidth
                  required
                  error={getFieldError("item")}
                  message={getFieldMessage("item")}
                />
              </Box>
            </Box>
            <Box
              sx={{ display: "flex" }}
              gap={1}
            >
              <Box sx={{ width: "50%" }}>
                <OFDTextField
                  id="quantity"
                  label="quantity"
                  value={getFieldValue("quantity") || ""}
                  onChange={(newValue) =>
                    updateFieldValue("quantity", newValue)
                  }
                  color={color}
                />
              </Box>
              <OFDTextNote
                id="note"
                label="note"
                value={getFieldValue("note") || ""}
                onChange={(newValue) => updateFieldValue("note", newValue)}
                color={color}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleAddImage}>
                {getLabel(addImage ? "imageRemove" : "imageAdd")}
              </Button>
            </Box>

            {addImage ? (
              <Box>
                <OFDDisplayPicture
                  id="image"
                  label="image"
                  value={getFieldValue("image") || ""}
                  onChange={(newValue) => updateFieldValue("image", newValue)}
                  onClick={handleUploadImage}
                  imageDimensions={{
                    height: "auto",
                    width: "100px",
                  }}
                />
              </Box>
            ) : null}
          </Stack>

          <Box sx={{ marginTop: "1rem" }}>
            <OFDFieldGroup justifyContent="flex-end">
              <Button
                variant="outlined"
                size="small"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleDeleteItem}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={selectedItem ? handleUpdateItem : handleAddItem}
              >
                {selectedItem ? "Update" : "Add"}
              </Button>
            </OFDFieldGroup>
          </Box>

          <Divider sx={{ marginTop: ".5rem" }}>
            <Typography variant="overline">{getLabel("list")}</Typography>
          </Divider>
        </Box>

        <OFDFieldContainer
          sx={{
            overflow: "auto",
            width: "100%",
            height: `${listHeight}px`,
            paddingBottom: "4rem",
          }}
        >
          <OFDDisplayGroceryItems
            value={value}
            onClick={handleEditItem}
            styleoverride={{ maxHeight: "none" }}
          />
        </OFDFieldContainer>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <FieldEditorModal
        entityName="Grocery"
        entityData={{ image: value }}
        field={{
          id: "picture",
          label: "picture",
          icon: "picture",
          fieldType: "picture",
        }}
        open={uploadImage}
        onCancel={handleCancelImage}
        onChange={handleChangeImage}
        returnValue
      />
    </>
  );
};

export default OFDGroceryItems;
