import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageActions } from "../store/page";

import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfWeek,
  endOfMonth,
  format,
  getDay,
  getMonth,
  getYear,
  setDay,
  setMonth,
  setYear,
  isDate,
  isEqual,
  isBefore,
  isAfter,
  parse,
  setDate,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subWeeks,
  parseISO,
  parseJSON,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  addMinutes,
  addYears,
  formatISO,
  startOfToday,
  getDate,
} from "date-fns";

import useApi from "./useApi";
import useData from "./useData";
import useDate from "./useDate";
import useMessage from "./useMessage";
import useMember from "./useMember";
import useSecurity from "./useSecurity";
import useFamily from "./useFamily";
import useToDo from "./useToDo";
import useReminder from "./useReminder";
import useLocalization from "./useLocalization";
import useRecurrence from "./useRecurrence";

const useCalendar = () => {
  const { getData, postData } = useApi();
  const { destructureResults, newError, newSuccess } = useMessage();
  const { member } = useMember();
  const { sort, isISODateString } = useData();
  const { userCanView } = useSecurity();
  const { family } = useFamily();
  const { transformToDo, validateToDo } = useToDo();
  const { transformReminder, validateReminder } = useReminder();
  const { transformRecurrence, validateRecurrence } = useRecurrence();
  const { getLabel } = useLocalization();
  const { toUTCDate } = useDate();

  const dispatch = useDispatch();
  const page = useSelector((state) => state.page.page);
  const selectedDate = useSelector((state) => state.page.selectedDate);

  const [data, setData] = useState(null);
  const [calendarData, setCalendarData] = useState(undefined);
  const [dailyItems, setDailyItems] = useState(null);
  const [message, setMessage] = useState(null);
  const [dailyReminders, setDailyReminders] = useState(null);
  const [dailyToDos, setDailyToDos] = useState(null);
  const [validationResults, setValidationResults] = useState();

  const getCalendarData = async (args) => {
    setData(null);
    setCalendarData(undefined);
    setDailyItems(null);
    setDailyReminders(null);
    setMessage(null);

    const results = await getData({
      entityName: "Calendar",
      action: "get",
      filter: args.filter,
    });

    if (results.isSuccessful) {
      filterBySecurity(results);
    } else {
      setData(null);
      setCalendarData(null);
    }
  };

  const getCalendarForDashboard = async (weekOf, calendarType) => {
    const filter = {
      calendarType,
      "security.view.securityLevel": "family",
      startDate: { $gte: startOfWeek(weekOf), $lte: endOfWeek(weekOf) },
    };
    const results = await getData({
      entityName: "Calendar",
      action: "get",
      filter,
    });

    return results;
  };

  const filterBySecurity = (results) => {
    let returnedData = [];

    for (const calendar of results.data) {
      if (userCanView(calendar.security, calendar.addedBy)) {
        returnedData.push({ ...calendar });
      }
    }

    setData(sort(returnedData, "startDate"));
    setCalendarData(sort(returnedData, "startDate"));
  };

  const yearMonthFilter = (year, month) => {
    let startDate = parse(`${year}-${month + 1}-1`, "yyyy-M-d", new Date());
    let endDate = endOfMonth(startDate);

    return dateRangeFilter(startDate, endDate);
  };

  const dateRangeFilter = (startDate, endDate) => {
    return {
      $or: [
        { startDate: { $gte: startDate, $lte: endDate } },
        { endDate: { $gte: startDate, $lte: endDate } },
      ],
    };
  };

  const filterCalendarData = (filter) => {
    if (!filter || !data) return;

    setCalendarData(() => {
      let newData = data.filter((record) => {
        if (!filter.members.includes(record.memberId)) return false;
        if (!filter.categories.includes(record.category)) return false;

        return true;
      });

      return newData;
    });
  };

  const storeSelectedDate = (selectedDate) => {
    dispatch(
      pageActions.setCalendarDate({
        selectedDate: format(selectedDate, "yyyy-MM-dd hh:mma"),
      })
    );
  };

  const getSelectedDate = () => {
    if (selectedDate) {
      return parse(selectedDate, "yyyy-MM-dd hh:mma", new Date());
    } else {
      return null;
    }
  };

  const clearFilter = () => {
    setCalendarData(data);
  };

  const getUniqueItems = (items) => {
    let ids = [];
    let uniqueItems = [];

    for (const item of items) {
      if (item.calendarType === "reminder" && item.memberId !== member.id) {
        continue;
      }

      let idx = ids.findIndex(
        (i) =>
          i.sourceId === item.source.id &&
          isEqual(i.date, startOfDay(item.startDate))
      );
      if (idx > -1) continue;
      ids.push({
        sourceId: item.source.id,
        date: startOfDay(item.startDate),
      });
      uniqueItems.push({ ...item });
    }

    return uniqueItems;
  };

  const getDailyItems = (date) => {
    if (!calendarData) return;

    setDailyItems(() => {
      let items = calendarData.filter((item) => {
        if (item.calendarType === "appointment") {
          if (isEqual(startOfDay(item.startDate), startOfDay(date)))
            return true;
        } else if (item.calendarType === "event") {
          if (
            format(date, "yyyyMMdd") >= format(item.startDate, "yyyyMMdd") &&
            format(date, "yyyyMMdd") <= format(item.endDate, "yyyyMMdd")
          ) {
            return true;
          }
        }
      });

      let ids = new Set();
      let uniqueItems = [];

      for (const item of items) {
        if (ids.has(item.source.id)) {
          continue;
        }
        ids.add(item.source.id);
        uniqueItems.push({ ...item });
      }

      return uniqueItems;
    });
  };

  const getDailyReminders = (date) => {
    if (!calendarData) return;

    setDailyReminders(() => {
      let remindersAdded = new Set();

      let items = calendarData.filter((item) => {
        if (
          item.calendarType === "reminder" &&
          (item.memberId === member.id || item.addedBy === member.id)
        ) {
          if (isEqual(startOfDay(item.startDate), date)) {
            if (remindersAdded.has(item.source.id)) return false;
            if (
              item.members &&
              item.members.includes(item.memberId) &&
              item.memberId !== member.id
            )
              return false;
            remindersAdded.add(item.source.id);

            return true;
          }
        }
        return false;
      });

      return items;
    });
  };

  const getDailyToDos = (date) => {
    if (!calendarData) return;

    setDailyToDos(() => {
      let todosAdded = new Set();
      let items = calendarData.filter((item) => {
        if (
          item.calendarType === "todo"
          // && (item.memberId === member.id || item.addedBy === member.id)
        ) {
          if (isEqual(startOfDay(item.startDate), startOfDay(date))) {
            if (todosAdded.has(item.source.id)) return false;
            todosAdded.add(item.source.id);

            return true;
          }
        }
        return false;
      });

      return items;
    });
  };

  const dayHasReminders = (date) => {
    if (!calendarData) return false;

    let remindersAdded = new Set();

    const reminders = calendarData.filter((item) => {
      if (item.calendarType !== "reminder") return false;

      if (item.memberId !== member.id && item.addedBy !== member.id)
        return false;
      if (isEqual(startOfDay(item.startDate), date)) {
        if (remindersAdded.has(item.source.id)) return false;
        remindersAdded.add(item.source.id);

        return true;
      }

      return false;
    });

    if (reminders && reminders.length > 0) return true;
    return false;
  };

  const dayHasToDos = (date) => {
    if (!calendarData) return false;

    const todos = calendarData.filter((item) => {
      if (item.calendarType !== "todo") return false;
      // if (item.memberId !== member.id && item.addedBy !== member.id)
      //   return false;

      if (isEqual(startOfDay(item.startDate), startOfDay(date))) {
        return true;
      }

      return false;
    });

    if (todos && todos.length > 0) return true;
    return false;
  };

  const deleteEntity = async (calendar, seriesFilter = null) => {
    setMessage(null);

    const results = await postData({
      entityName: calendar.source.model,
      action: "delete",
      data: {
        id: calendar.source.id,
        seriesFilter: seriesFilter,
      },
    });

    setMessage(destructureResults(results));
  };

  const deleteCalendar = async (calendar, option) => {
    setMessage(null);

    let action = "deleteCalendar";
    if (option === "occurrence") action = "deleteOccurrence";

    const results = await postData({
      entityName: "Calendar",
      action,
      data: {
        calendarId: calendar.id,
        source: calendar.source,
      },
    });

    setMessage(destructureResults(results));
  };

  const deleteSeries = async (calendar, seriesFilter) => {
    setMessage(null);

    const results = await postData({
      entityName: "Calendar",
      action: "deleteSeries",
      data: {
        source: calendar.source,
        seriesFilter,
      },
    });

    setMessage(destructureResults(results));
  };

  const transformCalendar = (calendar, option) => {
    let newCalendar = { ...calendar };

    if (calendar.frequency === "onetime") {
      if (!newCalendar.allDay) {
        if (isDate(newCalendar.startDate) && isDate(newCalendar.endDate)) {
          let fromTime = format(newCalendar.startDate, "hh:mm a");
          let toTime = format(newCalendar.endDate, "hh:mm a");

          if (fromTime === "12:00 AM" && toTime === fromTime) {
            newCalendar.allDay = true;
            newCalendar.fromTime = null;
            newCalendar.toTime = null;
          }
        }
      }
    }

    if (calendar.frequency !== "onetime" && calendar.frequency !== "schedule") {
      newCalendar.forever = Boolean(calendar.recurrence.forever);
    }

    if (option === "series") {
      newCalendar.overridden = false;
    }

    newCalendar.calendarType = "appointment";

    newCalendar.recurrence = transformRecurrence(calendar.recurrence);

    newCalendar.fromTimeString = calendar.fromTime
      ? format(calendar.fromTime, "HH:mm")
      : null;

    newCalendar.toTimeString = calendar.toTime
      ? format(calendar.toTime, "HH:mm")
      : null;

    newCalendar.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const today = new Date();
    const utcDate = toUTCDate(today);
    const offset = (utcDate.getTimezoneOffset() / 60) * -1;

    newCalendar.tzOffset = offset;

    return newCalendar;
  };

  const clearData = () => {
    setData(null);
  };

  // validation routines return the following:
  // isSuccessful: Boolean
  // message: String
  // displayToUser: Boolean
  // fieldErrors: Array of field errors
  // severity: error | warning | info

  const validateCalendar = (calendar, option) => {
    try {
      let results = {
        isSuccessful: true,
        message: "ok",
        displayToUser: false,
        fieldErrors: [],
        severity: "info",
      };

      results = validateRecurrence(calendar.recurrence);

      if (calendar.frequency !== "import") {
        // name cannot be empty
        if (!calendar.name || calendar.name.trim().length === 0) {
          results.isSuccessful = false;
          results.fieldErrors.push({
            name: "name",
            message: "isEmpty",
            severity: "error",
            error: true,
          });
        }
      }

      // final steps
      if (!results.isSuccessful) {
        results.message = "fieldErrors";
        results.displayToUser = true;
        results.severity = "error";
      }

      return results;
    } catch (err) {
      console.log(err);
    }
  };

  const fieldValidationResults = (field) => {
    if (!validationResults) return null;
  };

  const getCurrentMembers = () => {
    if (!data) return [];

    let members = new Set();
    for (const calendar of data) {
      if (
        calendar.calendarType === "appointment" ||
        calendar.calendarType === "todo"
      ) {
        members.add(calendar.memberId);

        continue;
      }
      if (calendar.memberId === member.id) {
        members.add(calendar.memberId);

        continue;
      }
    }

    return Array.from(members);
  };

  const getCurrentCategories = () => {
    if (!data) return [];

    let categories = new Set();
    for (const calendar of data) {
      categories.add(calendar.category);

      // if (calendar.calendarType === "appointment") {
      //   categories.add(calendar.category);
      //   continue;
      // }
      // if (calendar.memberId === member.id) {
      //   categories.add(calendar.category);
      //   continue;
      // }
    }

    return Array.from(categories);
  };

  const getEntityDates = (entityData, option) => {
    if (entityData.frequency === "onetime") {
      return oneTimeDates(entityData, option);
    } else if (entityData.frequency === "daily") {
      return dailyDates(entityData, option);
    } else if (entityData.frequency === "weekly") {
      return weeklyDates(entityData, option);
    } else if (entityData.frequency === "monthly") {
      return monthlyDates(entityData, option);
    } else if (entityData.frequency === "schedule") {
      return scheduleDates(entityData, option);
    } else if (entityData.frequency === "yearly") {
      return yearlyDates(entityData, option);
    }
  };

  const oneTimeDates = (entityData) => {
    let entityDates = [];

    let entityDate;
    if (entityData.calendarType === "appointment") {
      entityDate = {
        startDate: entityData.startDate,
        endDate: entityData.endDate,
        allDay: entityData.allDay,
      };
    } else if (entityData.calendarType === "reminder") {
      entityDate = {
        startDate: entityData.startDate,
      };
    } else if (entityData.calendarType === "todo") {
      entityDate = {
        startDate: entityData.startDate,
      };
    }

    if (entityDate) {
      entityDates.push({ ...entityDate });
    }

    return entityDates;
  };

  const dailyDates = (entityData, option) => {
    let entityDates = [];

    if (option !== "occurrence") {
      let endDate = entityData.endDate
        ? entityData.endDate
        : endOfMonth(entityData.startDate);

      for (
        let date = startOfDay(entityData.startDate);
        date <= startOfDay(endDate);
        date = addDays(date, 1)
      ) {
        let entityDate = getEntityDate(entityData, date);

        if (entityDate) {
          entityDates.push(entityDate);
        }
      }
    } else {
      let entityDate = getEntityDate(entityData, entityData.startDate);
      entityDates.push(entityDate);
    }

    return entityDates;
  };

  const weeklyDates = (entityData, option) => {
    let entityDates = [];

    if (option !== "occurrence") {
      let endDate = entityData.endDate
        ? entityData.endDate
        : endOfMonth(entityData.startDate);

      for (
        let date = startOfWeek(entityData.startDate);
        date <= startOfWeek(endDate);
        date = addWeeks(date, 1)
      ) {
        for (let dow = 0; dow < entityData.dow.length; dow++) {
          if (entityData.dow[dow] === false) continue;

          let day = addDays(date, dow);
          let entityDate = getEntityDate(entityData, day);

          if (entityDate) {
            entityDates.push(entityDate);
          }
        }
      }
    } else {
      let entityDate = getEntityDate(entityData, entityData.startDate);

      entityDates.push(entityDate);
    }

    return entityDates;
  };

  const monthlyDates = (entityData, option) => {
    let entityDates = [];

    if (option !== "occurrence") {
      let endDate = entityData.endDate
        ? entityData.endDate
        : endOfMonth(entityData.startDate);

      for (
        let date = startOfMonth(entityData.startDate);
        date <= startOfMonth(endDate);
        date = addMonths(date, 1)
      ) {
        if (entityData.monthlyType === "specific") {
          const daysOfMonth = getDaysOfMonth(date, entityData);
          for (const day of daysOfMonth) {
            let entityDate = getEntityDate(entityData, day);

            if (entityDate) {
              entityDates.push(entityDate);
            }
          }
        } else if (entityData.monthlyType === "relative") {
          for (const relativeDays of entityData.days) {
            let week = startOfWeek(date);

            let monthStartsDOW = getDay(date);

            for (let dow = 0; dow < relativeDays.dow.length; dow++) {
              if (relativeDays.dow[dow] === false) continue;

              if (monthStartsDOW > dow) {
                week = addWeeks(week, 1);
              }

              switch (relativeDays.week) {
                case "second":
                  week = addWeeks(week, 1);
                  break;

                case "third":
                  week = addWeeks(week, 2);
                  break;

                case "fourth":
                  week = addWeeks(week, 3);
                  break;

                case "last":
                  week = startOfWeek(endOfMonth(date));
                  break;
              }

              let dowDate = addDays(week, dow);
              let yyyymm_date = format(dowDate, "yyyyMM");
              let yyyymm_week = format(week, "yyyyMM");

              if (relativeDays.week === "last" && yyyymm_date > yyyymm_week) {
                dowDate = subWeeks(dowDate, 1);
              }

              if (
                relativeDays.week === "first" &&
                getMonth(dowDate) < getMonth(addDays(week, 7))
              ) {
                dowDate = addWeeks(dowDate, 1);
              }

              if (
                isAfter(startOfDay(dowDate), startOfDay(entityData.endDate)) ||
                isBefore(startOfDay(dowDate), startOfDay(entityData.startDate))
              ) {
                continue;
              }
              let entityDate = getEntityDate(entityData, dowDate);
              entityDates.push(entityDate);

              // let exists = false;
              // for (const existingDate of entityDates) {
              //   if (isEqual(startOfDay(existingDate), startOfDay(dowDate))) {
              //     exists = true;
              //   }
              // }
              // if (!exists) {
              //   entityDates.push(dowDate);
              // }
            }
          }
        }
      }
    } else {
      let entityDate = getEntityDate(entityData, entityData.startDate);
      entityDates.push(entityDate);
    }

    return entityDates;
  };

  const yearlyDates = (entityData, option) => {
    let entityDates = [];

    if (option !== "occurrence") {
      let newDate = new Date(
        getYear(entityData.startDate),
        entityData.monthDay.month,
        entityData.monthDay.day
      );
      let entityDate = getEntityDate(entityData, newDate);

      entityDates.push(entityDate);
    } else {
      let entityDate = getEntityDate(entityData, entityData.startDate);
      entityDates.push(entityDate);
    }

    return entityDates;
  };

  const scheduleDates = (entityData, option) => {
    let entityDates = [];

    if (option !== "occurrence") {
      for (const date of entityData.schedule) {
        let startDate = typeof date === "string" ? parseISO(date) : date;
        let endDate = startDate;

        let allDay = true;

        if (entityData.calendarType === "appointment") {
          if (entityData.fromTime) {
            startDate = applyTimeToDate(startDate, entityData.fromTime);
            allDay = false;
          }
          if (entityData.toTime) {
            endDate = applyTimeToDate(startDate, entityData.toTime);
            allDay = false;
          }
        } else if (entityData.calendarType === "reminder") {
          if (entityData.time) {
            startDate = applyTimeToDate(startDate, entityData.time);
            allDay = false;
          }
        }

        entityDates.push({
          startDate,
          endDate,
          allDay,
        });
      }
    } else {
      let entityDate = getEntityDate(entityData, entityData.startDate);
      entityDates.push(entityDate);
    }

    return entityDates;
  };

  const getEntityDate = (entityData, date) => {
    let startDate = date;
    let endDate = date;

    let allDay = true;
    let fromTime;
    if (entityData.calendarType === "appointment") {
      fromTime = entityData.fromTime;
    } else if (entityData.calendarType === "reminder") {
      fromTime = entityData.time;
    }

    if (fromTime) {
      startDate = applyTimeToDate(startDate, fromTime);
      allDay = false;
    }
    if (entityData.toTime) {
      endDate = applyTimeToDate(endDate, entityData.toTime);
      allDay = false;
    }

    if (
      startDate < startOfDay(entityData.startDate) ||
      endDate > endOfDay(entityData.endDate)
    ) {
      return null;
    }

    if (entityData.calendarType === "appointment") {
      return {
        startDate,
        endDate,
        allDay,
      };
    } else if (entityData.calendarType === "reminder") {
      return {
        startDate,
        time: fromTime,
      };
    } else if (entityData.calendarType === "todo") {
      return {
        startDate,
      };
    }

    return null;
  };

  const applyTimeToDate = (date, time) => {
    let yyyymmdd = format(date, "yyyy-MM-dd");
    let hhmma = format(time, "hh:mm a");

    let newDate = parse(
      `${yyyymmdd} ${hhmma}`,
      "yyyy-MM-dd hh:mm a",
      new Date()
    );

    return newDate;
  };

  const getDaysOfMonth = (monthStart, entityData) => {
    let days = [];

    if (entityData.monthlyType === "specific") {
      for (const dom of entityData.dates) {
        if (dom === "last") {
          days.push(endOfMonth(monthStart));
        } else {
          days.push(setDate(monthStart, parseInt(dom)));
        }
      }
    } else if (entityData.monthlyType === "relative") {
      for (const relativeDays of entityData.days) {
        let week = startOfWeek(monthStart);

        let monthStartsDOW = getDay(monthStart);

        for (let dow = 0; dow < relativeDays.dow.length; dow++) {
          if (relativeDays.dow[dow] === false) continue;

          if (monthStartsDOW > dow) {
            week = addWeeks(week, 1);
          }

          switch (relativeDays.week) {
            case "second":
              week = addWeeks(week, 1);
              break;

            case "third":
              week = addWeeks(week, 2);
              break;

            case "fourth":
              week = addWeeks(week, 3);
              break;

            case "last":
              week = startOfWeek(endOfMonth(monthStart));
              break;
          }

          let date = addDays(week, dow);
          let yyyymm_date = format(date, "yyyyMM");
          let yyyymm_week = format(week, "yyyyMM");

          if (relativeDays.week === "last" && yyyymm_date > yyyymm_week) {
            date = subWeeks(date, 1);
          }
          if (
            relativeDays.week === "first" &&
            getMonth(date) < getMonth(addDays(week, 7))
          ) {
            date = addWeeks(date, 1);
          }

          if (date > entityData.endDate || date < entityData.startDate)
            continue;

          let exists = false;
          for (const existingDate of days) {
            if (isEqual(existingDate, date)) {
              exists = true;
            }
          }
          if (!exists) {
            days.push(date);
          }
        }
      }
    }

    return days;
  };

  const completeToDo = async (entityData, completed = true) => {
    const results = await postData({
      entityName: "ToDo",
      action: "complete",
      data: {
        id: entityData.id,
        todoComplete: completed,
      },
    });

    console.log({ results });

    setMessage(destructureResults(results));

    return results;
  };

  const importCalendar = async (fileData, fileType) => {
    let results = null;

    if (fileType === "text/calendar") {
      results = await importICal(fileData);
    } else if ((fileType = "text/csv")) {
      results = await importCSV(fileData);
    }

    return results;
  };

  const importICal = async (fileData) => {
    try {
      const data = await parseICal(fileData);
      if (!data) {
        return newError("invalidData");
      }

      let vEvents = [];
      let vToDos = [];
      let vReminders = [];
      let vCalendar, vTimezone;

      for (const key of Object.keys(data)) {
        const item = data[key];
        if (item.type === "VEVENT") {
          vEvents.push({ ...item });
        } else if (item.type === "VTODO") {
          vToDos.push({ ...item });
        } else if (item.type === "VALARM") {
          vReminders.push({ ...item });
        } else if (item.type === "VTIMEZONE") {
          vTimezone = { ...item };
        } else if (item.type === "VCALENDAR") {
          vCalendar = { ...item };
        }
      }

      // rrule.freq : 0=yearly, 1=monthly, 2=weekly, 3=daily
      // no rrule = onetime

      let eventsSuccessful = null;
      let todosSuccessful = null;
      let remindersSuccessful = null;
      let success = true;

      if (vEvents.length > 0) {
        eventsSuccessful = await importICalEvents(vEvents);
        if (!eventsSuccessful) {
          success = false;
        }
      }

      // REMOVE FOR NOW
      // if (vToDos.length > 0) {
      //   todosSuccessful = await importICalToDos(vToDos);
      //   if (!todosSuccessful) {
      //     success = false;
      //   }
      // }

      // if (vReminders.length > 0) {
      //   remindersSuccessful = await importICalReminders(vReminders);
      //   if (!remindersSuccessful) {
      //     success = false;
      //   }
      // }

      if (success) {
        return newSuccess("saved", true);
      } else {
        return newError("invalidData");
      }
    } catch (err) {
      return newError(err.message);
    }
  };

  const importICalEvents = async (vEvents) => {
    try {
      let success = true;

      for (const vEvent of vEvents) {
        let appointment = createCalendarType(vEvent, "appointment");

        let option =
          appointment.frequency === "onetime" ? "occurrence" : "series";

        let newData = transformCalendar(appointment, option);

        const validationResults = validateCalendar(newData, "import");

        if (!validationResults.isSuccessful) {
          success = false;
          break;
        }

        let entityDates = getEntityDates(newData, option);

        let options = null;
        options = {
          option: option ? option : null,
          calendarId: null,
          entityDates,
        };

        const results = await postData({
          entityName: "Appointment",
          action: "addAppointment",
          data: {
            ...newData,
            options,
          },
        });

        setMessage(destructureResults(results));

        if (!results.isSuccessful) {
          success = false;
        }
      }

      return success;
    } catch (err) {
      return false;
    }
  };

  const importICalToDos = async (vToDos) => {
    try {
      let success = true;

      for (const vEvent of vToDos) {
        let todo = createCalendarType(vEvent, "todo");

        let option = todo.frequency === "onetime" ? "occurrence" : "series";

        let newData = transformToDo(todo, option);
        const validationResults = validateToDo(newData);

        if (!validationResults.isSuccessful) {
          success = false;
          break;
        }

        let entityDates = getEntityDates(newData, option);

        let options = null;
        options = {
          option: option ? option : null,
          calendarId: null,
          entityDates,
        };

        const results = await postData({
          entityName: "ToDo",
          action: "addToDo",
          data: {
            ...newData,
            options,
          },
        });

        if (!results.isSuccessful) {
          success = false;
        }
      }

      return success;
    } catch (err) {
      return false;
    }
  };

  const importICalReminders = async (vReminders) => {
    try {
      let success = true;

      for (const vReminder of vReminders) {
        let reminder = createCalendarType(vReminder, "reminder");

        let option = reminder.frequency === "onetime" ? "occurrence" : "series";

        let newData = transformReminder(reminder, option);
        const validationResults = validateReminder(newData);

        if (!validationResults.isSuccessful) {
          success = false;
          break;
        }

        let entityDates = getEntityDates(newData, option);

        let options = null;
        options = {
          option: option ? option : null,
          calendarId: null,
          entityDates,
        };

        const results = await postData({
          entityName: "Reminder",
          action: "addReminder",
          data: {
            ...newData,
            options,
          },
        });

        if (!results.isSuccessful) {
          success = false;
        }
      }

      return success;
    } catch (err) {
      return false;
    }
  };

  const parseICal = async (fileData) => {
    const results = await postData({
      entityName: "Calendar",
      action: "parseICal",
      data: {
        calendarData: fileData,
      },
    });

    if (results.isSuccessful && results.data.parsedICal) {
      return results.data.parsedICal;
    } else {
      return null;
    }
  };

  const parseRecurrence = (recurrence, startDate) => {
    if (!recurrence || recurrence.length === 0) return [];

    //'FREQ=MONTHLY;INTERVAL=1;BYDAY=3TH'
    let rruleString;
    if (Array.isArray(recurrence) && recurrence.length > 0) {
      rruleString = recurrence[0].substring(6);
    } else {
      rruleString = recurrence;
    }

    const properties = rruleString.split(";");

    let options = {
      freq: null,
    };

    const weekDays = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

    for (const property of properties) {
      const keyValue = property.split("=");
      const key = keyValue[0];
      const value = keyValue[1];

      switch (key) {
        case "FREQ":
          options.freq = value.toLowerCase();
          break;

        case "UNTIL":
          options.until = parseISO(value);
          break;

        case "COUNT":
          options.count = parseInt(value);
          break;

        case "BYDAY":
          const byday = value.split(",");

          if (options.freq !== "monthly") {
            const byweekday = [];
            for (const day of byday) {
              let dayIndex = weekDays.findIndex((d) => d === day);
              byweekday.push(dayIndex);
            }
            options.byweekday = byweekday;
          } else {
            const bynweekday = [];
            for (const item of byday) {
              let relativeWeek = parseInt(item.substring(0, item.length - 2));
              if (relativeWeek < 0) {
                relativeWeek = 6;
              }
              const dow = weekDays.findIndex(
                (d) => d === item.substring(item.length - 2)
              );
              bynweekday.push([dow, relativeWeek]);
            }
            options.bynweekday = bynweekday;
          }

          break;

        case "INTERVAL":
          options.interval = parseInt(value);
          break;

        case "BYMONTHDAY":
          const bymonthday = value.split(",");
          options.bymonthday = bymonthday;
          break;
      }
    }

    if (options.freq === "monthly") {
      if (!options.bymonthday && !options.bynweekday) {
        options.bymonthday = [getDate(startDate)];
      }
    }

    const rrule = {
      options,
    };

    return rrule;
  };

  const isValidDate = (date) => {
    return (
      date &&
      Object.prototype.toString.call(date) === "[object Date]" &&
      !isNaN(date)
    );
  };

  const createCalendarType = (vEvent, calendarType) => {
    try {
      let frequency = "onetime";
      const frequencies = ["yearly", "monthly", "weekly", "daily"];

      let rrule = null;

      let startDate = null;
      let endDate = null;
      let allDay = false;
      let fromTime = null;
      let toTime = null;
      let dow = null;
      let dates = null;
      let days = null;
      let monthlyType = null;
      let schedule = null;
      let forever = false;
      let monthDay = null;

      if (vEvent.start) {
        if (typeof vEvent.start !== "object") {
          if (typeof vEvent.start !== "string") {
            startDate = parseISO(vEvent.start.toISOString());
          } else {
            startDate = parseISO(vEvent.start);
          }
        } else if (vEvent.start.dateTime) {
          startDate = parseISO(vEvent.start.dateTime);
        } else if (vEvent.start.date) {
          startDate = parse(vEvent.start.date, "yyyy-MM-dd", new Date());
        }
      }

      if (vEvent.end) {
        if (typeof vEvent.end !== "object") {
          if (typeof vEvent.end !== "string") {
            endDate = parseISO(vEvent.end.toISOString());
          } else {
            endDate = parseISO(vEvent.end);
          }
        } else if (vEvent.end.dateTime) {
          endDate = parseISO(vEvent.end.dateTime);
        } else if (vEvent.end.date) {
          endDate = parse(vEvent.end.date, "yyyy-MM-dd", new Date());
        }
      }

      if (differenceInHours(endDate, startDate) === 24) {
        allDay = true;
        endDate = startDate;
      }

      if (vEvent.rrule !== undefined) {
        rrule = vEvent.rrule;
        frequency = frequencies[rrule.options.freq];
      } else if (vEvent.recurrence !== undefined) {
        rrule = parseRecurrence(vEvent.recurrence, startDate);
        frequency = rrule.options.freq;
      }

      if (frequency === "onetime") {
        let duration = differenceInCalendarDays(endDate, startDate);
        if (duration > 1) {
          frequency = "daily";
          // endDate = parseISO(vEvent.end.toISOString());
          let startTime = startDate;
          let endTime = endDate;
          endTime = setYear(endTime, getYear(startTime));
          endTime = setMonth(endTime, getMonth(startTime));
          endTime = setDay(endTime, getDay(startTime));

          if (!isEqual(startTime, endTime)) {
            let duration = differenceInMinutes(endDate, startDate);
            fromTime = startDate;
            toTime = addMinutes(fromTime, duration);
          } else {
            allDay = true;
          }
        }
      } else if (frequency === "daily") {
        if (allDay) {
          fromTime = null;
          toTime = null;
        } else {
          let duration = differenceInMinutes(endDate, startDate);
          fromTime = startDate;
          toTime = addMinutes(fromTime, duration);
        }
        startDate = startOfDay(startDate);
        if (rrule.options.count) {
          endDate = addDays(startDate, rrule.options.count - 1);
        } else if (rrule.options.until) {
          endDate = rrule.options.until;
        } else {
          endDate = null;
          forever = true;
        }
      } else if (frequency === "weekly") {
        if (allDay) {
          fromTime = null;
          toTime = null;
        } else {
          let duration = differenceInMinutes(endDate, startDate);
          fromTime = startDate;
          toTime = addMinutes(fromTime, duration);
        }
        startDate = startOfDay(startDate);
        if (rrule.options.until) {
          endDate = parseISO(rrule.options.until);
        } else {
          endDate = null;
          forever = true;
        }

        if (Array.isArray(rrule.options.byweekday)) {
          dow = [false, false, false, false, false, false, false];
          for (let x = 0; x < rrule.options.byweekday.length; x++) {
            let weekday = rrule.options.byweekday[x];
            // if (weekday === 6) {
            //   weekday = 0;
            // } else {
            //   weekday = weekday + 1;
            // }
            dow[weekday] = true;
          }
        }
      } else if (frequency === "monthly") {
        if (allDay) {
          fromTime = null;
          toTime = null;
        } else {
          let duration = differenceInMinutes(endDate, startDate);
          fromTime = startDate;
          toTime = addMinutes(fromTime, duration);
        }
        startDate = startOfDay(startDate);
        if (rrule.options.until) {
          endDate = parseISO(rrule.options.until);
        } else {
          endDate = null;
          forever = true;
        }

        if (
          Array.isArray(rrule.options.bymonthday) &&
          rrule.options.bymonthday.length > 0
        ) {
          monthlyType = "specific";
          dates = [];
          for (const day of rrule.options.bymonthday) {
            dates.push(day);
          }
        } else if (
          Array.isArray(rrule.options.bynweekday) &&
          rrule.options.bynweekday.length > 0
        ) {
          monthlyType = "relative";
          const week = [
            "none",
            "first",
            "second",
            "third",
            "fourth",
            "fifth",
            "last",
          ];
          days = [];

          let weekday;
          for (const item of rrule.options.bynweekday) {
            weekday = item[0];
            // if (weekday === 6) {
            //   weekday = 0;
            // } else {
            //   weekday++;
            // }

            let index = days.findIndex((d) => d.week === week[item[1]]);
            if (index === -1) {
              let workdow = [false, false, false, false, false, false, false];
              workdow[weekday] = true;

              days.push({
                week: week[item[1]],
                dow: workdow,
              });

              continue;
            }

            let currentdow = days[index].dow;
            currentdow[weekday] = true;
            days[index].dow = currentdow;
          }
        }
      } else if (frequency === "yearly") {
        if (allDay) {
          fromTime = null;
          toTime = null;
        } else {
          let duration = differenceInMinutes(endDate, startDate);
          fromTime = startDate;
          toTime = addMinutes(fromTime, duration);
        }
        startDate = startOfDay(startDate);
        if (rrule.options.until) {
          endDate = parseISO(rrule.options.until);
        } else {
          endDate = null;
          forever = true;
        }

        monthDay = {
          month: getMonth(startDate),
          day: getDate(startDate),
        };
      }

      let address = null;
      if (vEvent.location) {
        address = {
          streetAddress: vEvent.location,
        };
      }

      let iCalUID = null;
      if (vEvent.UID) {
        iCalUID = vEvent.UID;
      } else if (vEvent.iCalUID) {
        iCalUID = vEvent.iCalUID;
      }

      let interval = 1;
      if (vEvent.interval) {
        interval = vEvent.interval;
      }

      let appointment = {
        familyId: family.id,
        security: {
          view: { securityLevel: "family" },
          update: { securityLevel: "addedBy" },
          delete: { securityLevel: "addedBy" },
        },
        name: vEvent.summary ? vEvent.summary : "No Subject",
        description: vEvent.description ? vEvent.description : null,
        calendarType,
        frequency,
        startDate,
        endDate,
        forever,
        allDay,
        fromTime,
        toTime,
        dow,
        dates,
        days,
        monthlyType,
        monthDay,
        schedule,
        attendees: [{ entityName: "Member", id: member.id }],
        address,
        iCalUID,
        interval,
      };

      return appointment;
    } catch (err) {
      console.log(err);
    }
  };

  const saveLink = async (data) => {
    const results = await postData({
      entityName: "Appointment",
      action: "addAppointment",
      data: {
        frequency: "import",
        ...data,
      },
    });

    return destructureResults(results);
  };

  const importCSV = async (fileData) => {
    try {
    } catch (err) {
      return newError(err.message);
    }
  };

  return {
    data,
    calendarData,
    getCalendarData,
    importCalendar,
    clearData,
    dateRangeFilter,
    filterCalendarData,
    yearMonthFilter,
    clearFilter,
    getDailyItems,
    dailyItems,
    deleteCalendar,
    deleteSeries,
    message,
    setMessage,
    transformCalendar,
    validateCalendar,
    getCurrentMembers,
    getCurrentCategories,
    dayHasReminders,
    dayHasToDos,
    getDailyReminders,
    dailyReminders,
    getDailyToDos,
    dailyToDos,
    getEntityDates,
    storeSelectedDate,
    getSelectedDate,
    getUniqueItems,
    completeToDo,
    validationResults,
    saveLink,
    getCalendarForDashboard,
    importICalEvents,
    deleteEntity,
  };
};

export default useCalendar;
