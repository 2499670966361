import { useEffect, useState, useRef } from "react";

import { Box } from "@mui/material";

import OFDButton from "./OFDButton";

const OFDFileUploader = ({ onUpload, accept }) => {
  const [file, setFile] = useState(null);
  const fileUploadRef = useRef();

  const handleOpenFileBrowser = () => {
    fileUploadRef.current.click();
  };

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
    onUpload(event.target.files[0]);
  };

  useEffect(() => {
    if (!file) return;

    onUpload(file);
  }, [file]);

  return (
    <Box>
      <OFDButton
        id="fileBrowser"
        label="selectFile"
        onClick={handleOpenFileBrowser}
        variant="contained"
      />

      <input
        id="fileUploader"
        ref={fileUploadRef}
        type="file"
        onChange={handleFileSelect}
        style={{ display: "none" }}
        accept={accept ? accept : null}
      />
    </Box>
  );
};

export default OFDFileUploader;
