import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDField from "../ui/OFDField";
import OFDFieldGroup from "../ui/OFDFieldGroup";
import OFDButton from "../ui/OFDButton";

const Search = ({
  entityName,
  searchCriteria,
  searchFields,
  open,
  onClose,
  onSearch,
}) => {
  const { getLabel } = useLocalization();

  const [searchValues, setSearchValues] = useState(null);
  const [fields, setFields] = useState(null);

  useEffect(() => {
    buildSearchFields();
  }, [searchFields, searchValues]);

  const buildSearchFields = () => {
    let list = [];

    for (const field of searchFields) {
      if (
        field.fieldType === "textField" ||
        field.fieldType === "textNote" ||
        field.fieldType === "noteEditor" ||
        field.fieldType === "select" ||
        field.fieldType === "multiSelect" ||
        field.fieldType === "ingredients" ||
        field.fieldType === "date" ||
        field.fieldType === "budgetItem" ||
        field.fieldType === "switch"
      ) {
        let fieldType = field.fieldType;
        let label = field.label;

        if (field.fieldType === "noteEditor") fieldType = "textNote";
        if (field.fieldType === "ingredients") fieldType = "textField";

        let value = getSearchValue(field.id);
        if (fieldType === "multiSelect") {
          if (!value) {
            value = [];
          }
        }

        if (field.fieldType === "date") {
          fieldType = "dateRange";
          label = `${getLabel(label)} ${getLabel("range")}`;
        }

        list.push(
          <OFDField
            key={field.id}
            id={field.id}
            fieldType={fieldType}
            entityName={entityName}
            fieldProps={{
              label: label,
              value: value,
              onChange: (newValue) => setSearchValue(field, newValue),
              fullWidth: true,
              listName: field.listName,
              hidden: false,
            }}
            mode="edit"
          />
        );
      }
    }

    setFields(list);
  };

  useEffect(() => {
    setSearchValues(searchCriteria);
  }, [searchCriteria]);

  const getSearchValue = (id) => {
    if (!searchValues) return null;

    let fieldIndex = searchValues.findIndex((field) => field.id === id);
    if (fieldIndex === -1) return null;

    return searchValues[fieldIndex].value;
  };

  const setSearchValue = (field, newValue) => {
    setSearchValues((current) => {
      let newSearch = [];

      if (!current) return [{ ...field, value: newValue }];

      let fieldExists = false;
      for (const currentField of current) {
        if (currentField.id !== field.id) {
          newSearch.push({ ...currentField });
          continue;
        }

        if (!newValue) {
          fieldExists = true;
          continue;
        }

        newSearch.push({
          ...currentField,
          value: newValue,
        });
        fieldExists = true;
      }

      if (!fieldExists) {
        newSearch.push({
          ...field,
          value: newValue,
        });
      }

      return newSearch;
    });
  };

  const handleClear = () => {
    setSearchValues(null);
    onSearch(null);
  };

  const handleSearch = () => {
    onSearch(searchValues);
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="search"
      >
        <Box sx={{ padding: "1rem" }}>
          <Stack
            sx={{
              backgroundColor: "#fff",
              padding: "1rem",
              borderRadius: "1rem",
            }}
            spacing={2}
          >
            {fields}
          </Stack>

          <OFDFieldGroup
            sx={{ padding: "1rem" }}
            justifyContent="flex-end"
          >
            <OFDButton
              variant="outlined"
              onClick={handleClear}
              label={getLabel("clear")}
              color="grey"
              shade={900}
              width="auto"
            />
            <OFDButton
              variant="contained"
              onClick={handleSearch}
              label={getLabel("apply")}
              color="grey"
              shade={900}
              width="auto"
            />
          </OFDFieldGroup>
        </Box>
      </OFDModal>
    </>
  );
};

export default Search;
