import { useEffect, useState } from "react";
import { startOfToday, getYear, parse } from "date-fns";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useForm from "../../hooks/useForm";
import useBudget from "../../hooks/useBudget";
import useWindow from "../../hooks/useWindow";

import { Box } from "@mui/material";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import BudgetWizard from "../wizards/budget/BudgetWizard";
import OFDMessage from "../ui/OFDMessage";
import Loading from "../layout/Loading";
import OFDYear from "../ui/OFDYear";
import OFDDialog from "../layout/OFDDialog";
import OFDListAndDetails from "../layout/OFDListAndDetails";
import EntityDisplay from "../display/EntityDisplay";

const Budgets = () => {
  const {
    setEntityName,
    toolbarAction,
    resetToolbarAction,
    refresh,
    refreshPage,
    setIsSaving,
  } = usePage();
  const {
    entityList,
    getEntityList,
    setEntityList,
    menuClick,
    entityMessage,
    resetEntityMessage,
  } = useEntity("Budget");
  const { data, setData, saveData, formMessage, formStatus, resetFormMessage } =
    useForm("Budget");
  const { getBudgets, budgets } = useBudget();
  const { isDesktop } = useWindow();

  const [openWizard, setOpenWizard] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    setOpenWizard(false);
    setEntityName("Budget");
    setSelectedYear(getYear(startOfToday()));
  }, []);

  useEffect(() => {
    getData();
  }, [refresh, selectedYear]);

  const getData = async () => {
    setEntityList(undefined);
    setData(null);
    await getBudgets(selectedYear);
  };

  useEffect(() => {
    if (budgets === undefined) return;

    setData(budgets);
  }, [budgets]);

  useEffect(() => {
    if (toolbarAction === "addBudget") {
      setData(null);
      setOpenWizard(true);
    }

    resetToolbarAction();
  }, [toolbarAction]);

  const handleEditEntity = async () => {
    await getData();
  };

  const handleWizardClose = async () => {
    setOpenWizard(false);
    resetToolbarAction();
    await getData();
    setData(null);
  };

  const handleCreateBudget = async (newBudget) => {
    await saveBudget(newBudget);
  };

  const handleUpdateBudget = async (updatedBudget) => {
    await saveBudget(updatedBudget);
  };

  const saveBudget = async (budgetData) => {
    setIsSaving(true);
    const results = await saveData({ data: budgetData });

    if (results.isSuccessful) {
      setOpenWizard(false);
    }

    refreshPage();
    setIsSaving(false);
  };

  const handleMenuClick = (action, entity) => {
    setSelectedEntity(entity);

    if (action === "delete") {
      setOpenDeleteConfirmation(true);
    }
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      const results = await menuClick("delete", selectedEntity);

      if (results.isSuccessful) {
        await getData();
      }
    }
    setOpenDeleteConfirmation(false);
  };

  const handleOpenRight = (entityName, id, entity) => {
    setSelectedEntity(entity);
  };

  const handleEdit = () => {};

  const handleSelectedFolder = () => {};

  const handleOpenFolderEditor = () => {};

  return (
    <>
      <OFDTitle title="budgets" />

      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}
      >
        <OFDYear
          id="year"
          label="year"
          value={selectedYear}
          onChange={(newValue) => setSelectedYear(newValue)}
        />
      </Box>

      {budgets !== undefined ? (
        isDesktop ? (
          <OFDListAndDetails
            list={
              <OFDEntityList
                entityName="Budget"
                entityList={budgets}
                onEdit={handleEditEntity}
                options={{
                  showAvatar: true,
                }}
                onMenuItemClick={handleMenuClick}
                onOpenRight={handleOpenRight}
              />
            }
            details={
              selectedEntity ? (
                <EntityDisplay
                  entityName="Budget"
                  id={selectedEntity.id}
                  entityData={selectedEntity}
                  onChange={handleEdit}
                  onFolderSelected={handleSelectedFolder}
                  onFolderEdit={handleOpenFolderEditor}
                />
              ) : null
            }
          />
        ) : (
          <OFDEntityList
            entityName="Budget"
            entityList={budgets}
            onEdit={handleEditEntity}
            options={{
              showAvatar: true,
            }}
            onMenuItemClick={handleMenuClick}
          />
        )
      ) : (
        <Loading />
      )}

      {/* {budgets !== undefined ? (
        <OFDEntityList
          entityName="Budget"
          entityList={budgets}
          onEdit={handleEditEntity}
          onMenuItemClick={handleMenuClick}
          options={{
            showAvatar: false,
          }}
        />
      ) : (
        <Loading />
      )} */}

      {openWizard ? (
        <BudgetWizard
          year={selectedYear}
          budget={data}
          open={openWizard}
          onClose={handleWizardClose}
          onCreateBudget={handleCreateBudget}
          onUpdateBudget={handleUpdateBudget}
        />
      ) : null}

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <OFDMessage
        message={entityMessage}
        onClose={resetEntityMessage}
      />

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_budget"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default Budgets;
