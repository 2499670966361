import { useEffect, useState } from "react";
import { startOfToday, endOfToday, startOfDay, addDays } from "date-fns";

import useApi from "../../hooks/useApi";
import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";
import useMember from "../../hooks/useMember";
import usePage from "../../hooks/usePage";
import useChore from "../../hooks/useChore";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography } from "@mui/material";

import Loading from "../layout/Loading";
import OFDTitle from "../layout/OFDTitle";

import CalendarItems from "./CalendarItems";
import SummaryBox from "./SummaryBox";
import FamilyChores from "./FamilyChores";

const CurrentActivities = ({ onSelect }) => {
  const { getMessage, getTitle } = useLocalization();
  const { getFamilyChores, familyChores, numberOfChores, updateChoreActivity } =
    useChore();
  const { getData } = useApi();
  const { sort } = useData();
  const { member } = useMember();
  const { refresh } = usePage();
  const { userCanUpdate } = useSecurity();

  const [calendarItems, setCalendarItems] = useState(undefined);
  const [appointments, setAppointments] = useState(undefined);
  const [reminders, setReminders] = useState(undefined);
  const [todos, setTodos] = useState(undefined);

  const [selectedSummary, setSelectedSummary] = useState(null);

  useEffect(() => {
    if (!selectedSummary) {
      setSelectedSummary("appointment");
    }
    getCalendarItems();
  }, [member]);

  useEffect(() => {
    getCalendarItems();
    getFamilyChores();
  }, [refresh]);

  const getCalendarItems = async () => {
    setCalendarItems(undefined);
    setAppointments(undefined);
    setReminders(undefined);
    setTodos(undefined);

    const results = await getData({
      entityName: "Calendar",
      action: "get",
      filter: {
        // memberId: member.id,
        $or: [
          {
            startDate: {
              $gte: startOfToday(),
              $lte: endOfToday(),
            },
          },
          {
            startDate: {
              $lte: startOfToday(),
            },
            endDate: {
              $gte: endOfToday(),
            },
          },
          {
            $and: [
              { calendarType: "todo" },
              { startDate: { $gte: startOfToday(), $lte: endOfToday() } },
              // { $or: [{ todoComplete: false }, { todoComplete: null }] },
            ],
          },
        ],
      },
    });

    if (results.isSuccessful) {
      let currentData = [];
      for (const item of results.data) {
        if (item.memberId === member.id) {
          currentData.push({ ...item });
        } else if (item.calendarType === "todo") {
          if (userCanUpdate(item.security, item.addedBy)) {
            currentData.push({ ...item });
          }
        }
      }

      sortItems(currentData);
    } else {
      setCalendarItems(null);
    }
  };

  const sortItems = (data) => {
    let sortedItems = [];

    for (const item of data) {
      // if (item.memberId !== member.id) {
      //   continue;
      // }

      let sort = item.startDate;
      if (item.allDay) {
        sort = startOfDay(item.startDate);
      }
      sortedItems.push({ ...item, sort });
    }

    sortedItems = sort(sortedItems, "sort", "asc");

    setCalendarItems(sortedItems);
    splitCalendarItems(sortedItems);
  };

  const splitCalendarItems = (items) => {
    let appt = [];
    let rmdr = [];
    let tds = [];

    for (const item of items) {
      if (item.calendarType === "appointment") {
        appt.push({ ...item });
      } else if (item.calendarType === "reminder") {
        if (!item.sent) {
          rmdr.push({ ...item });
        }
      } else if (item.calendarType === "todo") {
        tds.push({ ...item });
      }
    }

    setAppointments(appt);
    setReminders(rmdr);
    setTodos(tds);

    if (!selectedSummary) {
      setSelectedSummary("appointments");
    }
  };

  const handleSummaryClick = (id) => {
    setSelectedSummary(id);
  };

  const handleSelect = (calendar) => {
    onSelect(calendar);
  };

  const handleUpdateChore = async (activity) => {
    await updateChoreActivity(activity);
  };

  const handleChange = async () => {
    await getCalendarItems();
  };

  if (
    appointments === undefined ||
    reminders === undefined ||
    todos === undefined ||
    familyChores === undefined
  )
    return <Loading />;

  return (
    <>
      <Box>
        <OFDTitle title={getTitle("currentActivities")} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <SummaryBox
            id="appointments"
            label="appointments"
            icon="appointment"
            value={appointments ? appointments.length : 0}
            onClick={handleSummaryClick}
            isSelected={selectedSummary === "appointments"}
          />

          <SummaryBox
            id="reminders"
            label="reminders"
            icon="reminder"
            value={reminders ? reminders.length : 0}
            onClick={handleSummaryClick}
            isSelected={selectedSummary === "reminders"}
          />

          <SummaryBox
            id="todos"
            label="todos"
            icon="todo"
            value={todos ? todos.length : 0}
            onClick={handleSummaryClick}
            isSelected={selectedSummary === "todos"}
          />

          {familyChores && familyChores.activity && familyChores.isApprover ? (
            <SummaryBox
              id="familyChores"
              label="chores"
              icon="chores"
              value={numberOfChores}
              onClick={handleSummaryClick}
              isSelected={selectedSummary === "familyChores"}
            />
          ) : null}
        </Box>

        {selectedSummary === "appointments" ? (
          <CalendarItems
            calendarType={selectedSummary}
            calendarItems={appointments}
            onSelect={(calendar) => handleSelect(calendar)}
          />
        ) : null}

        {selectedSummary === "reminders" ? (
          <CalendarItems
            calendarType={selectedSummary}
            calendarItems={reminders}
            onSelect={(calendar) => handleSelect(calendar)}
          />
        ) : null}

        {selectedSummary === "todos" ? (
          <CalendarItems
            calendarType={selectedSummary}
            calendarItems={todos}
            onSelect={(calendar) => handleSelect(calendar)}
            onChange={handleChange}
          />
        ) : null}

        {selectedSummary === "familyChores" ? (
          <FamilyChores
            familyChores={familyChores}
            onChange={handleUpdateChore}
          />
        ) : null}
      </Box>
    </>
  );
};

export default CurrentActivities;
