import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";

const ContextHelp = ({ open, onClose, name }) => {
  const { getHelpText, getLabel } = useLocalization();

  if (!name) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={getHelpText(name).title}
      >
        <Box sx={{ padding: "1rem" }}>
          <Typography variant="h5">{getLabel("definition")}</Typography>
          <Stack spacing={1}>
            {getHelpText(name).definition.map((definition, index) => (
              <Typography
                key={index}
                variant="body1"
              >
                {definition}
              </Typography>
            ))}
          </Stack>

          <Typography
            variant="h5"
            marginTop={1}
          >
            {getLabel("examples")}
          </Typography>
          <Stack spacing={1}>
            {getHelpText(name).examples.map((example, index) => (
              <Typography
                key={index}
                variant="body1"
              >
                {example}
              </Typography>
            ))}
          </Stack>
        </Box>
      </OFDModal>
    </>
  );
};

export default ContextHelp;
