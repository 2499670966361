import { useEffect, useState } from "react";
import {
  startOfToday,
  addDays,
  subDays,
  startOfDay,
  endOfDay,
  format,
  isEqual,
  startOfWeek,
  addWeeks,
  isAfter,
  isBefore,
  endOfToday,
  endOfWeek,
} from "date-fns";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useLocalization from "../../hooks/useLocalization";
import useCalendar from "../../hooks/useCalendar";
import useMember from "../../hooks/useMember";
import useApi from "../../hooks/useApi";
import useData from "../../hooks/useData";

import { Box, Typography } from "@mui/material";

import ReminderEditor from "../editors/ReminderEditor";

import OFDTitle from "../layout/OFDTitle";
import ReminderList from "../display/ReminderList";
import OFDContainer from "../ui/OFDContainer";
import OFDDialog from "../layout/OFDDialog";
import OFDWeekOf from "../ui/OFDWeekOf";

import Loading from "../layout/Loading";

const Reminders = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const { entityList, getEntityList, setEntityList } = useEntity("Calendar");
  const { getLabel } = useLocalization();
  const { deleteCalendar } = useCalendar();
  const { member } = useMember();
  const { getData } = useApi();
  const { sort } = useData();

  const [openReminder, setOpenReminder] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [weekOf, setWeekOf] = useState(null);

  const [weekReminders, setWeekReminders] = useState([]);

  useEffect(() => {
    setEntityName("Reminder");
    getReminders();
    setWeekOf(startOfWeek(addWeeks(startOfToday(), 1)));
  }, [refresh]);

  const getReminders = async () => {
    setEntityList(undefined);

    const gte = startOfToday();
    const lte = endOfDay(addDays(gte, 1));

    const filter = {
      memberId: member.id,
      calendarType: "reminder",
      startDate: {
        $gte: gte,
        $lte: lte,
      },
      dismissed: false,
      sent: false,
    };

    const sortCriteria = {
      field: "startDate",
      order: "asc",
    };

    await getEntityList({ filter, sortCriteria });
    getWeeklyReminders();
  };

  useEffect(() => {
    getWeeklyReminders();
  }, [weekOf]);

  const getWeeklyReminders = async () => {
    const filter = {
      memberId: member.id,
      calendarType: "reminder",
      startDate: {
        $gte: weekOf,
        $lte: endOfWeek(weekOf),
      },
    };
    const results = await getData({
      entityName: "Calendar",
      action: "get",
      memberId: member.id,
      calendarType: "reminder",
      filter,
    });

    if (results.isSuccessful) {
      setWeekReminders(sort(results.data, "startDate", "asc"));
    } else {
      setWeekReminders([]);
    }
  };

  useEffect(() => {
    if (toolbarAction === "addReminder") {
      setSelectedReminder(null);
      setSelectedOption(null);
      setOpenReminder(true);
    }
    resetToolbarAction();
  }, [toolbarAction]);

  const todaysReminders = () => {
    if (!entityList) return [];

    let todays = [];

    for (const item of entityList) {
      if (isBefore(item.startDate, startOfToday())) continue;
      if (isAfter(item.startDate, endOfToday())) continue;

      todays.push({ ...item });
    }

    return todays;
  };

  const tomorrowsReminders = () => {
    if (!entityList) return [];

    let startOfTomorrow = startOfDay(addDays(new Date(), 1));
    let endOfTomorrow = endOfDay(startOfTomorrow);

    let tomorrows = [];

    for (const item of entityList) {
      if (isBefore(item.startDate, startOfTomorrow)) continue;
      if (isAfter(item.startDate, endOfTomorrow)) continue;

      tomorrows.push({ ...item });
    }

    return tomorrows;
  };

  const weekOfReminders = () => {
    if (!entityList) return [];

    let weeksReminders = entityList.filter((item) =>
      isEqual(startOfWeek(item.startDate), weekOf)
    );

    return weeksReminders;
  };

  const handleEditReminder = (calendar, option) => {
    setSelectedReminder(calendar);
    setSelectedOption(option);
    setOpenReminder(true);
  };

  const handleDeleteReminder = (calendar, option) => {
    setSelectedReminder(calendar);
    setSelectedOption(option);
    setOpenDelete(true);
  };

  const handleConfirmDelete = async (response) => {
    if (response === "yes") {
      await deleteCalendar(selectedReminder, selectedOption);
      await getReminders();
    }
    setSelectedReminder(null);
    setSelectedOption(null);
    setOpenDelete(false);
  };

  const handleCloseEditor = () => {
    setOpenReminder(false);
    setSelectedReminder(null);
    setSelectedOption(null);
  };

  const handleRefresh = async () => {
    await getReminders();
    setOpenReminder(false);
    setSelectedReminder(null);
    setSelectedOption(null);
  };

  return (
    <>
      <OFDTitle title="reminders" />

      {entityList !== undefined ? (
        <Box sx={{ paddingBottom: "2rem" }}>
          <OFDContainer
            label={getLabel("today")}
            sx={{ marginTop: "1rem" }}
          >
            {todaysReminders().length > 0 ? (
              <ReminderList
                reminders={todaysReminders()}
                onEdit={handleEditReminder}
                onDelete={handleDeleteReminder}
              />
            ) : (
              <Typography align="center">
                {getLabel("noneScheduled")}
              </Typography>
            )}
          </OFDContainer>

          <OFDContainer
            label={getLabel("tomorrow")}
            sx={{ marginTop: "1rem" }}
          >
            {tomorrowsReminders().length > 0 ? (
              <ReminderList
                reminders={tomorrowsReminders()}
                onEdit={handleEditReminder}
                onDelete={handleDeleteReminder}
              />
            ) : (
              <Typography align="center">
                {getLabel("noneScheduled")}
              </Typography>
            )}
          </OFDContainer>

          <Box sx={{ marginTop: "1rem" }}>
            <OFDWeekOf
              value={weekOf}
              onChange={(newValue) => setWeekOf(newValue)}
            />

            <OFDContainer sx={{ marginTop: "1rem" }}>
              {weekReminders && weekReminders.length > 0 ? (
                <ReminderList
                  reminders={weekReminders}
                  onEdit={handleEditReminder}
                  onDelete={handleDeleteReminder}
                />
              ) : (
                <Typography align="center">
                  {getLabel("noneScheduled")}
                </Typography>
              )}
            </OFDContainer>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}

      {openReminder ? (
        <ReminderEditor
          open={openReminder}
          onClose={handleCloseEditor}
          onSave={handleRefresh}
          calendar={selectedReminder}
          option={selectedOption}
        />
      ) : null}

      <OFDDialog
        open={openDelete}
        title="confirmDelete"
        description={`confirmDelete_reminder_${
          selectedOption ? selectedOption : "occurrence"
        }`}
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default Reminders;
