import useCryptography from "./useCyptography";

const useLocalStorage = () => {
  const { encrypt, decrypt } = useCryptography();

  const setLocal = (key, value) => {
    try {
      if (typeof Storage === "undefined") {
        return false;
      }

      localStorage.setItem(key, value);

      return true;
    } catch (err) {
      console.error({ ERROR: err });
    }

    return false;
  };

  const getLocal = (key) => {
    let value = null;

    try {
      if (typeof Storage === "undefined") {
        return null;
      }

      const localValue = localStorage.getItem(key);

      if (localValue && localValue !== null) {
        value = localValue;
      }
    } catch (err) {
      console.error({ ERROR: err });
    }

    return value;
  };

  const removeLocal = (key) => {
    if (typeof Storage === "undefined") {
      return;
    }

    localStorage.removeItem(key);
  };

  const clearLocalData = () => {
    removeLocal("token");
    removeLocal("data");
    removeLocal("appVersion");
  };

  const setData = (data) => {
    if (!data) return;

    const stringData = JSON.stringify(data);
    const encryptedData = encrypt(stringData);
    localStorage.setItem("data", encryptedData);
  };

  const getData = () => {
    const encryptedData = localStorage.getItem("data");

    if (!encryptedData) return null;

    const decryptedData = decrypt(encryptedData);
    const data = JSON.parse(decryptedData);
    return data;
  };

  const memberState = () => {
    let initialState = {
      id: null,
      name: null,
      email: null,
      avatar: null,
      color: null,
      preferences: null,
    };

    let data = getData();

    if (!data) {
      return initialState;
    }

    return {
      ...initialState,
      ...data,
    };
  };

  return {
    setLocal,
    getLocal,
    removeLocal,
    clearLocalData,
    setData,
    getData,
    memberState,
  };
};

export default useLocalStorage;
