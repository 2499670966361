import { useEffect, useState } from "react";

import usePage from "../../../../hooks/usePage";
import useFamily from "../../../../hooks/useFamily";
import useGrocery from "../../../../hooks/useGrocery";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import GroceryListContainer from "./GroceryListContainer";
import GroceryWizard from "../../../wizards/grocery/GroceryWizard";

import OFDButton from "../../../ui/OFDButton";

const GroceryDashboard = ({ height }) => {
  const { getGroceryLists, groceryLists, addGrocery } = useGrocery();
  const { refresh, dashboardMode } = usePage();
  const { getMessage } = useLocalization();
  const { family } = useFamily();

  const [openWizard, setOpenWizard] = useState(false);

  useEffect(() => {
    getGrocery();
  }, [refresh]);

  const getGrocery = async () => {
    await getGroceryLists({ completedAt: null });
  };

  const newGrocery = async (grocery) => {
    setOpenWizard(false);

    let data = {
      familyId: family.id,
      ...grocery,
    };

    const results = await addGrocery(data);

    getGrocery();
  };

  const handleOpenWizard = () => {
    setOpenWizard(true);
  };

  if (groceryLists === undefined) return;

  return (
    <>
      <Box sx={{ height: height }}>
        {dashboardMode === "edit" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "1rem",
            }}
          >
            <OFDButton
              name="new"
              label="new"
              iconName="grocery"
              color="orange"
              onClick={handleOpenWizard}
            />
          </Box>
        ) : null}

        {Array.isArray(groceryLists) && groceryLists.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                paddingTop: "1rem",
                overflowX: "auto",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
              gap={2}
            >
              {groceryLists.map((groceryList) => (
                <GroceryListContainer
                  key={groceryList.id}
                  groceryList={groceryList}
                  height={height - 100}
                  onUpdated={getGrocery}
                />
              ))}
            </Box>
          </>
        ) : (
          <Box sx={{ paddingTop: "1rem" }}>
            <Typography
              variant="h5"
              align="center"
            >
              {getMessage("noGroceryLists")}
            </Typography>
          </Box>
        )}
      </Box>

      <GroceryWizard
        open={openWizard}
        onClose={() => setOpenWizard(false)}
        onCreateGrocery={newGrocery}
        onUpdateGrocery={() => {}}
      />
    </>
  );
};

export default GroceryDashboard;
