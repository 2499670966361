import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useCalendar from "../../hooks/useCalendar";

import { Box, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";
import OFDDisplayAttendees from "../ui/OFDDisplayAttendees";
import OFDDisplayAddress from "../ui/OFDDisplayAddress";
import OFDButton from "../ui/OFDButton";
import OFDCheckBox from "../ui/OFDCheckBox";
import OFDDisplayHtml from "../ui/OFDDisplayHtml";
import GroceryShop from "../editors/GroceryShop";

const CalendarItem = ({ calendar, onClick, onChange }) => {
  const { getLabel } = useLocalization();
  const { completeToDo } = useCalendar();

  const [complete, setComplete] = useState(false);
  const [openGrocery, setOpenGrocery] = useState(false);

  const handleComplete = async (newValue) => {
    await completeToDo(calendar, newValue);
    if (onChange) onChange();
  };

  const isHtml = () => {
    if (!calendar.description) return false;
    if (calendar.description.substring(0, 5) === "<div>") return true;
    return false;
  };

  const handleCloseGrocery = () => {
    if (onChange) onChange();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: ".5rem",
          borderRadius: "12px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            gap={1}
            onClick={onClick}
          >
            {calendar.todoComplete ? (
              <OFDIcon name="checkCircle" />
            ) : (
              <OFDIcon
                name={
                  calendar.category ? calendar.category : calendar.calendarType
                }
              />
            )}

            <Typography variant="h6">{calendar.name}</Typography>
          </Box>

          {calendar.calendarType === "todo" ? (
            <Box sx={{ display: "flex" }}>
              {calendar.source.groceryId ? (
                <Box>
                  <OFDButton
                    label={getLabel("shop")}
                    onClick={() => setOpenGrocery(true)}
                  />
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <OFDCheckBox
                    id="complete"
                    value={calendar.todoComplete}
                    onChange={handleComplete}
                  />
                  <OFDIcon name={calendar.calendarType} />
                </Box>
              )}
            </Box>
          ) : null}
        </Box>

        {/* {calendar.calendarType === "appointment" &&
        calendar.frequency === "onetime" &&
        !calendar.allDay ? (
          <Box
            sx={{ marginLeft: "32px" }}
            onClick={onClick}
          >
            <Typography sx={{ fontWeight: 500 }}>{`${
              calendar.startDate ? format(calendar.startDate, "h:mm a") : ""
            } : ${
              calendar.endDate ? format(calendar.endDate, "h:mm a") : ""
            }`}</Typography>
          </Box>
        ) : null} */}

        {calendar.calendarType === "appointment" && calendar.allDay ? (
          <Box
            sx={{ marginLeft: "32px" }}
            onClick={onClick}
          >
            <Typography sx={{ fontWeight: 500 }}>
              {getLabel("allDay")}
            </Typography>
          </Box>
        ) : null}

        {calendar.calendarType === "reminder" ? (
          <Box
            sx={{ marginLeft: "32px" }}
            onClick={onClick}
          >
            <Typography sx={{ fontWeight: 500 }}>{`${
              calendar.startDate ? format(calendar.startDate, "h:mm a") : ""
            }`}</Typography>
          </Box>
        ) : null}

        {calendar.calendarType === "todo" ? (
          <Box
            sx={{
              marginLeft: "32px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontWeight: 500 }}
              onClick={onClick}
            >{`${
              calendar.startDate ? format(calendar.startDate, "EEE MMM dd") : ""
            }`}</Typography>

            {/* <OFDButton
            id="complete"
            label="done"
            icon="complete"
            size="small"
            variant="contained"
            onClick={handleComplete}
          /> */}
          </Box>
        ) : null}

        {calendar.fromTime || calendar.toTime ? (
          <Box
            sx={{ marginLeft: "32px" }}
            onClick={onClick}
          >
            <Typography sx={{ fontWeight: 500 }}>{`${
              calendar.fromTime ? format(calendar.fromTime, "h:mm a") : ""
            } : ${
              calendar.toTime ? format(calendar.toTime, "h:mm a") : ""
            }`}</Typography>
          </Box>
        ) : null}

        {calendar.description ? (
          <Box
            sx={{
              marginLeft: "32px",
              paddingTop: ".5rem",
              paddingBottom: ".5rem",
            }}
            onClick={onClick}
          >
            {isHtml ? (
              <OFDDisplayHtml value={calendar.description} />
            ) : (
              <Typography
                variant="caption"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {calendar.description}
              </Typography>
            )}
          </Box>
        ) : null}

        {Array.isArray(calendar.attendees) ? (
          <Box
            sx={{ marginLeft: "32px" }}
            onClick={onClick}
          >
            <OFDDisplayAttendees value={calendar.attendees} />
          </Box>
        ) : null}

        {calendar.address ? (
          <Box
            sx={{ marginLeft: "32px" }}
            onClick={onClick}
          >
            <OFDDisplayAddress value={calendar.address} />
          </Box>
        ) : null}
      </Box>

      <GroceryShop
        groceryId={calendar.source.groceryId}
        open={openGrocery}
        onClose={handleCloseGrocery}
      />
    </>
  );
};

export default CalendarItem;
