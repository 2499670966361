import { useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

const OFDDisplayGallery = ({
  label,
  value,
  styleoverride,
  onClick,
  onSelect,
  security,
  entryAddedBy,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleSelect = (image) => {
    if (onSelect) onSelect(image);
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}
      onClick={onClick}
    >
      <Box sx={{ width: "100%" }}>
        <Typography variant="overline">{getLabel(label)}</Typography>

        {Array.isArray(value) && value.length > 0 ? (
          <Grid
            container
            columnGap={1}
            rowGap={1}
          >
            {value.map((image, index) => (
              <Grid
                key={image.id}
                mobile={4}
              >
                <img
                  alt="image"
                  src={image.src}
                  width="100%"
                  height="auto"
                  onClick={() => handleSelect(image)}
                  loading="lazy"
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>{getMessage("noImages")}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default OFDDisplayGallery;
