import React, { useEffect, useState, useRef, Suspense } from "react";
import { startOfWeek, startOfToday, endOfToday } from "date-fns";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useForm from "../../hooks/useForm";
import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import MealPlanWizard from "../wizards/mealplan/MealPlanWizard";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import SecurityEditor from "../editors/SecurityEditor";
import MealPlanDisplay from "../display/MealPlanDisplay";
import OFDWeekOf from "../ui/OFDWeekOf";
import Loading from "../layout/Loading";

const DailyPlans = React.lazy(() => import("../wizards/mealplan/DailyPlans"));
// import DailyPlans from "../wizards/mealplan/DailyPlans";

const MealPlans = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const { entityList, getEntityList, setEntityList } = useEntity("MealPlan");
  const { getMessage } = useLocalization();
  const {
    data,
    setData,
    saveData,
    setFieldValue,
    getFieldValue,
    formMessage,
    formStatus,
    resetFormMessage,
    deleteDocument,
  } = useForm("MealPlan");

  const [openWizard, setOpenWizard] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  const [openMealPlan, setOpenMealPlan] = useState(false);
  const [openSecurityEditor, setOpenSecurityEditor] = useState(false);
  const [weekOf, setWeekOf] = useState(startOfWeek(startOfToday()));

  const urlRef = useRef();

  useEffect(() => {
    setOpenWizard(false);
    setEntityName("MealPlan");
    getMealPlans();
  }, [refresh]);

  useEffect(() => {
    if (toolbarAction === "addMealPlan") {
      setData(null);
      setOpenWizard(true);
      setSelectedMealPlan(null);
    }

    resetToolbarAction();
  }, [toolbarAction]);

  useEffect(() => {
    getMealPlans();
  }, [weekOf]);

  const getMealPlans = async () => {
    setEntityList(null);
    setData(null);

    const results = await getEntityList({
      filter: {
        weekOf,
      },
    });

    if (results.isSuccessful) {
      if (Array.isArray(results.data)) {
        setData(results.data[0]);
      } else {
        setData(results.data);
      }
    } else {
      setData(null);
    }
  };

  const handleWizardClose = async () => {
    setOpenWizard(false);
    resetToolbarAction();
    await getEntityList();
    setData(null);
  };

  const handleCreateMealPlan = async (newMealPlan) => {
    await saveMealPlan(newMealPlan);
    getMealPlans();
  };

  const handleUpdateMealPlan = async (updatedMealPlan) => {
    await saveMealPlan(updatedMealPlan);
    getMealPlans();
  };

  const saveMealPlan = async (mealPlanData) => {
    const results = await saveData({ data: mealPlanData });

    if (results.isSuccessful) {
      await getMealPlans();
      setOpenWizard(false);
    }
  };

  const handleDelete = async (item) => {
    setSelectedMealPlan(item);
    setOpenDelete(true);
  };

  const handleConfirmDelete = async (response) => {
    if (response === "yes") {
      await deleteDocument({ data: selectedMealPlan });
      await getMealPlans();
    }
    setSelectedMealPlan(null);
    setOpenDelete(false);
  };

  const handleMenuItemClick = (name, entity) => {
    setSelectedMealPlan(entity);

    switch (name) {
      case "delete":
        handleDelete(entity);
        break;

      case "security":
        handleSecutity(entity);
        break;

      case "open":
        handleShowMealPlan(entity);
        break;
    }
  };

  const handleSecutity = (item) => {
    setSelectedMealPlan(item);
    setOpenSecurityEditor(true);
  };

  const handleCloseSecurity = () => {
    setOpenSecurityEditor(false);
  };

  const handleChangeSecurity = async (newSecurity) => {
    handleCloseSecurity();

    let newMealPlan = { ...selectedMealPlan, security: { ...newSecurity } };

    await saveMealPlan(newMealPlan);
  };

  const handleShowMealPlan = (entity) => {
    setSelectedMealPlan(entity);

    if (entity.externalUrl) {
      urlRef.current.click();
    } else {
      setOpenMealPlan(true);
    }
  };

  const handleDailyPlanChange = async (newValue) => {
    let updatedMealPlan = { ...data, dailyPlans: newValue };
    await handleUpdateMealPlan(updatedMealPlan);
  };

  return (
    <>
      <Box sx={{ width: "100%", paddingBottom: "2rem" }}>
        <OFDTitle title="mealPlan" />

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <OFDWeekOf
            id="weekOf"
            value={weekOf || null}
            onChange={(newValue) => setWeekOf(newValue)}
          />
        </Box>

        {data && data.dailyPlans ? (
          <Box sx={{ marginTop: "1rem" }}>
            <Suspense fallback={<div></div>}>
              <DailyPlans
                dailyPlans={data.dailyPlans}
                onChange={(newValue) => handleDailyPlanChange(newValue)}
                weekOf={weekOf}
              />
            </Suspense>
          </Box>
        ) : (
          <Typography
            align="center"
            sx={{ marginTop: "1rem" }}
          >
            {getMessage("noMealPlan")}
          </Typography>
        )}
      </Box>

      {openWizard ? (
        <MealPlanWizard
          grocery={data}
          open={openWizard}
          onClose={handleWizardClose}
          onCreateMealPlan={handleCreateMealPlan}
          onUpdateMealPlan={handleUpdateMealPlan}
          selectedWeek={weekOf}
        />
      ) : null}

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <OFDDialog
        open={openDelete}
        title="confirmDelete"
        description={`confirmDelete_groceryList`}
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      {openSecurityEditor ? (
        <SecurityEditor
          open={openSecurityEditor}
          onClose={handleCloseSecurity}
          document={selectedMealPlan}
          onChange={handleChangeSecurity}
        />
      ) : null}

      {openMealPlan ? (
        <MealPlanDisplay
          open={openMealPlan}
          onClose={() => setOpenMealPlan(false)}
          recipe={selectedMealPlan}
        />
      ) : null}
    </>
  );
};

export default MealPlans;
