import { useEffect, useState } from "react";
import {
  startOfToday,
  getDate,
  addYears,
  addMonths,
  subDays,
  getYear,
  getMonth,
  endOfDay,
  isAfter,
} from "date-fns";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useLocalization from "../../hooks/useLocalization";
import useWindow from "../../hooks/useWindow";
import useApi from "../../hooks/useApi";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import EntityEditor from "../editors/EntityEditor";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import OFDListAndDetails from "../layout/OFDListAndDetails";
import EntityDisplay from "../display/EntityDisplay";
import OFDModal from "../layout/OFDModal";
import OFDSwitch from "../ui/OFDSwitch";
import OFDButton from "../ui/OFDButton";
import OFDDate from "../ui/OFDDate";

import Loading from "../layout/Loading";
import { Box, Stack, Typography } from "@mui/material";

const SubscriptionManager = () => {
  const { postData } = useApi();
  const { getText, getLabel } = useLocalization();
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const {
    entityList,
    getEntityList,
    menuClick,
    entityMessage,
    resetEntityMessage,
    updateEntityMessage,
    setEntityList,
  } = useEntity("Subscription");
  const { isDesktop } = useWindow();

  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setOpenForm(false);
    setEntityName("Subscription");
    getData();
  }, [refresh, showCancelled]);

  const getData = async () => {
    setEntityList(undefined);
    let filter = {
      cancelled: showCancelled,
    };
    await getEntityList({ filter, sortFields: { field: "name" } });
  };

  useEffect(() => {
    if (toolbarAction === "addSubscription") {
      setOpenForm(true);
    }

    resetToolbarAction();
  }, [toolbarAction]);

  const handleEditEntity = async () => {
    await getData();
  };

  const handleFormClose = async () => {
    setOpenForm(false);
    resetToolbarAction();
    await getData();
  };

  const handleMenuClick = (action, entity) => {
    setSelectedEntity(entity);

    if (action === "delete") {
      if (entity.hasExpenses) {
        setOpenDeleteModal(true);
      } else {
        setOpenDeleteConfirmation(true);
      }
    } else if (action === "cancel") {
      calcSubscriptionEnd(entity);
      setOpenCancelModal(true);
    }
  };

  const calcSubscriptionEnd = (entity) => {
    let today = startOfToday();
    let currentYear = getYear(today);
    let subscriptionEnd;
    let subscriptionStart;

    if (entity.frequency === "year") {
      subscriptionStart = new Date(
        currentYear,
        getMonth(entity.startDate),
        getDate(entity.startDate)
      );

      subscriptionEnd = addYears(subscriptionStart, 1);
      subscriptionEnd = endOfDay(subDays(subscriptionEnd, 1));
    } else {
      let subscriptionYear = currentYear;
      let subscriptionMonth = getMonth(today);

      if (getDate(today) > getDate(entity.startDate)) {
        subscriptionMonth++;
        if (subscriptionMonth === 12) {
          subscriptionMonth = 0;
          subscriptionYear++;
        }
      }

      subscriptionEnd = new Date(
        subscriptionYear,
        subscriptionMonth,
        getDate(entity.startDate)
      );

      subscriptionEnd = endOfDay(subDays(subscriptionEnd, 1));
    }

    setEndDate(subscriptionEnd);
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      const results = await menuClick("delete", selectedEntity);

      if (results.isSuccessful) {
        await getData();
      }
    }
    setOpenDeleteConfirmation(false);
    setOpenDeleteModal(false);
  };

  const cancelSubscription = async () => {
    setOpenCancelModal(false);

    const results = await postData({
      entityName: "Subscription",
      action: "cancel",
      data: {
        ...selectedEntity,
        endDate,
      },
    });

    updateEntityMessage(results);
  };

  const handleOpenRight = (entityName, id, entity) => {
    setSelectedEntity(entity);
  };

  const handleEdit = () => {};

  const handleSelectedFolder = () => {};

  const handleOpenFolderEditor = () => {};

  return (
    <>
      <OFDTitle title="subscriptions" />

      <OFDSwitch
        id="showCancelled"
        value={showCancelled}
        label={getLabel("showCancelled")}
        onChange={(newValue) => setShowCancelled(newValue)}
      />

      {entityList !== undefined ? (
        isDesktop ? (
          <OFDListAndDetails
            list={
              <OFDEntityList
                entityName="Subscription"
                entityList={entityList}
                onEdit={handleEditEntity}
                options={{
                  showAvatar: true,
                }}
                onMenuItemClick={handleMenuClick}
                onOpenRight={handleOpenRight}
              />
            }
            details={
              selectedEntity ? (
                <EntityDisplay
                  entityName="Subscription"
                  id={selectedEntity.id}
                  entityData={selectedEntity}
                  onChange={handleEdit}
                  onFolderSelected={handleSelectedFolder}
                  onFolderEdit={handleOpenFolderEditor}
                />
              ) : null
            }
          />
        ) : (
          <OFDEntityList
            entityName="Subscription"
            entityList={entityList}
            onEdit={handleEditEntity}
            options={{
              showAvatar: true,
            }}
            onMenuItemClick={handleMenuClick}
          />
        )
      ) : (
        <Loading />
      )}

      {openForm ? (
        <EntityEditor
          entityName="Subscription"
          open={openForm}
          onClose={handleFormClose}
        />
      ) : null}

      <OFDMessage
        message={entityMessage}
        onClose={resetEntityMessage}
      />

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_subscription"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />

      {openDeleteModal ? (
        <OFDModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title={selectedEntity.name}
        >
          <Box sx={{ padding: "1rem" }}>
            <Stack spacing={2}>
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {getText("subscriptions_hasExpenses")}
              </Typography>

              <OFDButton
                label="delete"
                onClick={() => deleteConfirmation("yes")}
              />
            </Stack>
          </Box>
        </OFDModal>
      ) : null}

      {openCancelModal ? (
        <OFDModal
          open={openCancelModal}
          onClose={() => setOpenCancelModal(false)}
          title={selectedEntity.name}
        >
          <Box sx={{ padding: "1rem" }}>
            <Stack spacing={2}>
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {getText("subscriptions_cancel")}
              </Typography>

              <OFDDate
                id="endDate"
                label="subscriptionEnd"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />

              <OFDButton
                id="cancelSubscription"
                label="cancelSubscription"
                onClick={cancelSubscription}
              />
            </Stack>
          </Box>
        </OFDModal>
      ) : null}
    </>
  );
};

export default SubscriptionManager;
