import { useEffect, useState } from "react";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useLocalization from "../../hooks/useLocalization";
import useWindow from "../../hooks/useWindow";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import EntityEditor from "../editors/EntityEditor";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import Loading from "../layout/Loading";
import { Typography } from "@mui/material";

import OFDListAndDetails from "../layout/OFDListAndDetails";
import EntityDisplay from "../display/EntityDisplay";

const PasswordManager = () => {
  const { getText } = useLocalization();
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const {
    entityList,
    getEntityList,
    menuClick,
    entityMessage,
    resetEntityMessage,
    setEntityList,
  } = useEntity("Password");
  const { isDesktop } = useWindow();

  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    setOpenForm(false);
    setEntityName("Password");
    getData();
  }, [refresh]);

  const getData = async () => {
    setEntityList(undefined);
    await getEntityList({ sortFields: { field: "name" } });
  };

  useEffect(() => {
    if (toolbarAction === "addPassword") {
      setOpenForm(true);
    }

    resetToolbarAction();
  }, [toolbarAction]);

  const handleEditEntity = async () => {
    await getData();
  };

  const handleFormClose = async () => {
    setOpenForm(false);
    resetToolbarAction();
    await getData();
  };

  const handleMenuClick = (action, entity) => {
    setSelectedEntity(entity);

    if (action === "delete") {
      setOpenDeleteConfirmation(true);
    }
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      const results = await menuClick("delete", selectedEntity);

      if (results.isSuccessful) {
        await getData();
      }
    }
    setOpenDeleteConfirmation(false);
  };

  const handleOpenRight = (entityName, id, entity) => {
    setSelectedEntity(entity);
  };

  const handleEdit = () => {};

  const handleSelectedFolder = () => {};

  const handleOpenFolderEditor = () => {};

  return (
    <>
      <OFDTitle title="passwordManager" />

      {entityList !== undefined ? (
        isDesktop ? (
          <OFDListAndDetails
            list={
              <OFDEntityList
                entityName="Password"
                entityList={entityList}
                onEdit={handleEditEntity}
                options={{
                  showAvatar: true,
                }}
                onMenuItemClick={handleMenuClick}
                onOpenRight={handleOpenRight}
              />
            }
            details={
              selectedEntity ? (
                <EntityDisplay
                  entityName="Password"
                  id={selectedEntity.id}
                  entityData={selectedEntity}
                  onChange={handleEdit}
                  onFolderSelected={handleSelectedFolder}
                  onFolderEdit={handleOpenFolderEditor}
                />
              ) : null
            }
          />
        ) : (
          <OFDEntityList
            entityName="Password"
            entityList={entityList}
            onEdit={handleEditEntity}
            options={{
              showAvatar: true,
            }}
            onMenuItemClick={handleMenuClick}
          />
        )
      ) : (
        <Loading />
      )}

      {openForm ? (
        <EntityEditor
          entityName="Password"
          open={openForm}
          onClose={handleFormClose}
        />
      ) : null}

      <OFDMessage
        message={entityMessage}
        onClose={resetEntityMessage}
      />

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_password"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default PasswordManager;
