import useApi from "./useApi";

const useMealPlan = () => {
  const { getData, postData } = useApi();

  const getMealPlanForWeek = async (weekOf) => {
    const results = await getData({
      entityName: "MealPlan",
      action: "get",
      filter: {
        weekOf,
      },
    });

    return results;
  };

  const addMealPlan = async (data) => {
    const results = await postData({
      entityName: "MealPlan",
      action: "add",
      data,
    });

    return results;
  };

  const updateMeal = async (data) => {
    const results = await postData({
      entityName: "MealPlan",
      action: "updateMeal",
      data,
    });

    return results;
  };

  return {
    getMealPlanForWeek,
    addMealPlan,
    updateMeal,
  };
};

export default useMealPlan;
