import useApi from "./useApi";
import useFamily from "./useFamily";

const useOccasion = () => {
  const { getData, postData, deleteData } = useApi();
  const { family } = useFamily();

  const getOccasions = async (source) => {
    const results = await getData({
      entityName: "Occasion",
      action: "get",
      filter: {
        source,
      },
    });

    return results;
  };

  const getOccasion = async (occasionId) => {
    const results = await getData({
      entityName: "Occasion",
      action: "get",
      id: occasionId,
    });

    return results;
  };

  const saveOccasion = async (occasion) => {
    const action = occasion.id ? "updateOccasion" : "addOccasion";

    let data = { ...occasion };
    data.day = parseInt(data.day);
    data.familyId = family.id;

    const results = await postData({
      entityName: "Occasion",
      action,
      data,
    });

    return results;
  };

  const deleteOccasion = async (occasion) => {
    if (!occasion || !occasion.id) return null;

    const results = await deleteData({
      entityName: "Occasion",
      action: "deleteOccasion",
      id: occasion.id,
    });

    return results;
  };

  const validateOccasion = (occasion) => {
    let results = {
      isSuccessful: true,
      message: "ok",
      displayToUser: false,
      fieldErrors: [],
      severity: "info",
    };

    if (!occasion || !occasion.occasionType) {
      results.isSuccessful = false;
      results.fieldErrors.push({
        name: "occasionType",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    if (!occasion || !occasion.name) {
      results.isSuccessful = false;
      results.fieldErrors.push({
        name: "name",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    if (!occasion || !occasion.month) {
      results.isSuccessful = false;
      results.fieldErrors.push({
        name: "month",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    if (!occasion || !occasion.day) {
      results.isSuccessful = false;
      results.fieldErrors.push({
        name: "day",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    return results;
  };

  const getOccasionCalendar = async (filter) => {
    const results = await getData({
      entityName: "Calendar",
      action: "get",
      filter: {
        calendarType: "occasion",
        ...filter,
      },
    });

    return results;
  };

  return {
    getOccasions,
    saveOccasion,
    deleteOccasion,
    validateOccasion,
    getOccasionCalendar,
    getOccasion,
  };
};

export default useOccasion;
