import { format } from "date-fns";

import useBloodPressure from "../../hooks/useBloodPressure";
import useColor from "../../hooks/useColor";

import { Box, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDDisplayBPItem = ({ bloodPressure, onClick }) => {
  const { bpColor } = useBloodPressure();
  const { getColor } = useColor();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...getColor(bpColor(bloodPressure)),
        width: "100%",
      }}
      gap={1}
      onClick={onClick}
    >
      <Typography sx={{ width: "50%", fontSize: "14px" }}>{`${format(
        bloodPressure.datetime,
        "EEE, MMM d h:mma"
      )} `}</Typography>

      <Typography
        sx={{ fontSize: "14px" }}
      >{`${bloodPressure.systolic}/${bloodPressure.diastolic}`}</Typography>

      <Box
        sx={{ display: "flex", alignItems: "center" }}
        gap={1}
      >
        <Typography
          sx={{ fontSize: "14px" }}
        >{`${bloodPressure.heartRate}`}</Typography>
        <OFDIcon
          name="heartrate"
          size="small"
          color="grey"
          shade={50}
        />
      </Box>
    </Box>
  );
};

export default OFDDisplayBPItem;
