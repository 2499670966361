import React, { useState, useEffect, useRef } from "react";

// MATERIAL UI
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";

// HOOKS
import useWindow from "../../hooks/useWindow";

// ASSETS
import FamilyImage from "../../assets/images/family/FamilyImage";

// Custom UI
import OFDHeader from "./OFDHeader";

const FamilyContentSlider = ({ children, title }) => {
  const [displayImage, setDisplayImage] = useState(null);
  const [component, setComponent] = useState(null);

  const { direction, isVertical, windowHeight, windowWidth, isMobile } =
    useWindow();

  const containerRef = useRef();

  useEffect(() => {
    if (displayImage) {
      return;
    }

    setDisplayImage(FamilyImage());
  }, []);

  useEffect(() => {
    if (!direction || isVertical === undefined) {
      return;
    }

    buildComponent();
  }, [children, direction, isVertical]);

  const imageRadiusStyles = {
    borderBottomLeftRadius: isVertical ? "0" : "0",
    borderBottomRightRadius: isVertical ? "0" : "0",
  };

  const imageDimensionStyles = {
    height: isVertical ? `${windowHeight / 3}px` : `${windowHeight}px`,
    width: isVertical ? `${windowWidth}px` : `${windowWidth / 2}px`,
  };

  const contentSliderStyles = {};

  const buildComponent = () => {
    setComponent(
      <Box
        id="container"
        ref={containerRef}
        sx={{
          width: `${windowWidth}px`,
          height: `${windowHeight}px`,
          backgroundColor: "#f0f0f0",

          overflow: "hidden",
        }}
      >
        <Stack
          id="body"
          spacing={0}
          direction={direction}
          sx={{ height: `${windowHeight}px` }}
        >
          <Slide
            id="familyImageContainer"
            in={true}
            direction={isVertical ? "down" : "right"}
            timeout={1000}
            mountOnEnter
            unmountOnExit
            sx={{
              height: isVertical
                ? `${windowHeight * 0.3}px`
                : `${windowHeight}px`,
            }}
          >
            <Box
              id="familyImage"
              sx={{
                ...imageDimensionStyles,
                ...imageRadiusStyles,

                overflow: "hidden",
              }}
            >
              <img
                alt="Family Image"
                src={displayImage}
                loading="lazy"
                style={{
                  ...imageDimensionStyles,

                  ...imageRadiusStyles,

                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            </Box>
          </Slide>

          <Slide
            id="content"
            in={true}
            direction={isVertical ? "up" : "left"}
            timeout={1500}
            sx={{
              height: isVertical
                ? `${windowHeight * 0.7}px`
                : `${windowHeight}px`,

              width: isVertical
                ? `${windowWidth}px`
                : `${windowWidth / 2 + 32}px`,

              overflow: "auto",

              zIndex: 500,
              backgroundColor: "#fff",
              marginLeft: isVertical ? `0px` : `-32px`,
              borderTopLeftRadius: isVertical ? "2rem" : "2rem",
              borderTopRightRadius: isVertical ? "2rem" : "0",
              borderBottomLeftRadius: isVertical ? "0" : "2rem",
            }}
            mountOnEnter
            unmountOnExit
          >
            <Box
              id="content"
              sx={{
                backgroundColor: "#ffffff",

                // height: "100%",
                // width: "100%",

                marginTop: isVertical ? "-3rem" : "0",
                marginLeft: isVertical ? "0" : "-3rem",

                borderTopLeftRadius: isVertical ? "2rem" : "2rem",
                borderTopRightRadius: isVertical ? "2rem" : "0",

                borderBottomLeftRadius: isVertical ? "0" : "2rem",

                // overflow: "auto",
              }}
            >
              <Box>
                <OFDHeader title={title} />
                <Box
                  sx={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    paddingLeft: `${windowWidth / 16}px`,
                    paddingRight: `${windowWidth / 16}px`,
                  }}
                >
                  {children}
                </Box>
              </Box>
            </Box>
          </Slide>
        </Stack>
      </Box>
    );
  };

  // if (!direction) {
  //   return <div></div>;
  // }

  return <Box sx={{ width: "100%", height: "100%" }}>{component}</Box>;
};

export default FamilyContentSlider;
