import { useEffect, useState } from "react";
import { startOfToday, endOfToday, endOfDay } from "date-fns";

import useCalendar from "../../../../hooks/useCalendar";
import usePage from "../../../../hooks/usePage";

import { Box } from "@mui/material";

import Appointments from "./Appointments";

const CalendarWidget = ({ forDate }) => {
  const { getCalendarData, calendarData, getUniqueItems } = useCalendar();
  const { refresh } = usePage();

  const [appointments, setAppointments] = useState(null);

  useEffect(() => {
    getData();
  }, [forDate, refresh]);

  const getData = async () => {
    const filter = {
      calendarType: "appointment",
      startDate: { $gte: forDate, $lte: endOfDay(forDate) },
      "security.view.securityLevel": "family",
    };

    await getCalendarData({ filter });
  };

  useEffect(() => {
    if (!calendarData) return;

    setAppointments(getUniqueItems(calendarData));
  }, [calendarData]);

  return (
    <>
      <Box>
        <Appointments appointments={appointments} />
      </Box>
    </>
  );
};

export default CalendarWidget;
