import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageActions } from "../store/page";

import useApi from "./useApi";
import useSecurity from "./useSecurity";
import useFamily from "./useFamily";
import useMember from "./useMember";
import useSetting from "./useSetting";
import useLocalStorage from "./useLocalStorage";

import pages from "../configuration/Pages";

const usePage = () => {
  const { isAuthenticated, userCanView } = useSecurity();
  const { postData } = useApi();
  const dispatch = useDispatch();
  const { familyPagePreferences, family } = useFamily();
  const { getMemberState, member } = useMember();
  const { getModules, modules } = useSetting();
  const { setLocal, getLocal } = useLocalStorage();

  const page = useSelector((state) => state.page.page);
  const entityName = useSelector((state) => state.page.entityName);
  const toolbarAction = useSelector((state) => state.page.toolbarAction);
  const refresh = useSelector((state) => state.page.refresh);
  const isSaving = useSelector((state) => state.page.isSaving);
  const mealPlans = useSelector((state) => state.page.mealPlans);
  const pageMode = useSelector((state) => state.page.mode);
  // const dashboardMode = useSelector((state) => state.page.dashboardMode);
  // const navMenu = useSelector((state) => state.page.navMenu);

  const [navigation, setNavigation] = useState([]);
  const [readyToReload, setReadyToReload] = useState(false);
  const [navMenu, setNavMenu] = useState([]);
  const [dashboardMode, setDashboardMode] = useState();

  useEffect(() => {
    const mode = getLocal("dashbaordMode");
    if (!mode) {
      setDashboardMode("user");
    } else {
      setDashboardMode(mode);
    }

    if (isAuthenticated) {
      createNavigation();
    }
  }, [family]);

  useEffect(() => {
    setLocal("dashboardMode", dashboardMode);
  }, [dashboardMode]);

  const createNavigation = async () => {
    if (modules) return;

    await getModules();
  };

  useEffect(() => {
    if (!modules) return;

    buildNavMenu();
  }, [modules, familyPagePreferences]);

  useEffect(() => {
    // dispatch(pageActions.setNavMenu({ navMenu: null }));
    buildNavMenu();
  }, [refresh]);

  const setPage = async (args) => {
    if (!isAuthenticated) return;

    const results = await postData({
      entityName: "PageVisit",
      action: "logPageVisit",
      data: {
        page: args.page,
      },
    });

    dispatch(pageActions.setPage(args.page));
  };

  const setPageMode = (mode) => {
    dispatch(pageActions.setMode(mode));
  };

  const setIsSaving = (value) => {
    dispatch(pageActions.setIsSaving(value));
  };

  const setEntityName = (entityName) => {
    dispatch(pageActions.setEntityName({ entityName }));
  };

  const setToolbarAction = (toolbarAction) => {
    dispatch(pageActions.setToolbarAction({ toolbarAction }));
  };

  const resetToolbarAction = () => {
    dispatch(pageActions.setToolbarAction({ toolbarAction: null }));
  };

  // const setDashboardMode = (mode) => {
  //   dispatch(pageActions.setDashboardMode(mode));
  // };

  const refreshMenu = () => {
    dispatch(pageActions.setNavMenu({ navMenu: null }));
    buildNavMenu();
  };

  const buildNavMenu = async () => {
    // if (navMenu) return;
    if (!modules) return;

    let availablePages = [];

    for (const module of pages) {
      const familyPreference = familyPagePreferences?.find(
        (page) => page.name === module.name
      );

      if (
        !familyPreference ||
        (familyPreference.enabled && userCanView(familyPreference.security))
      ) {
        availablePages.push({
          name: module.name,
          icon: module.name,
          url: module.url,
          subscribed: true,
        });
      }
    }

    // dispatch(pageActions.setNavMenu({ navMenu: availablePages }));
    setNavMenu(availablePages);
  };

  const getPageSecurity = (name) => {
    if (name) {
      const familyPreference = familyPagePreferences?.find(
        (page) => page.name === name
      );

      if (familyPreference) {
        return familyPreference.security;
      }
    }

    return {
      view: { securityLevel: "family" },
      add: { securityLevel: "adult" },
      update: { securityLevel: "adult" },
      delete: { securityLevel: "adult" },
    };
  };

  const getPageSecurityByEntityName = (entityName) => {
    const pageConfig = pages.find((entry) => entry.entityName === entityName);

    if (pageConfig) {
      return getPageSecurity(pageConfig.name);
    }

    return {
      view: { securityLevel: "family" },
      add: { securityLevel: "adult" },
      update: { securityLevel: "adult" },
      delete: { securityLevel: "adult" },
    };
  };

  const reload = async () => {
    await getMemberState();
    resetToolbarAction();
    setReadyToReload(true);
  };

  useEffect(() => {
    if (!readyToReload) return;
    setReadyToReload(false);

    window.location.reload();
    // navigate("/home");
    dispatch(pageActions.refreshPage());
  }, [readyToReload]);

  const refreshPage = async () => {
    dispatch(pageActions.refreshPage());
    await getMemberState();
  };

  const getMainPages = () => {
    if (!pages) return [];

    let mainPages = pages.filter((page) => page.main);

    return mainPages;
  };

  const defaultPageSecurity = () => {
    return {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "family", members: [] },
      update: { securityLevel: "addedBy", members: [] },
      delete: { securityLevel: "addedBy", members: [] },
    };
  };

  const openMealPlanDay = (id) => {
    let openDays = [...mealPlans.daysOpen];

    if (openDays.includes(id)) return;

    openDays.push(id);

    dispatch(pageActions.setDaysOpen(openDays));
  };

  const closeMealPlanDay = (id) => {
    let openDays = [...mealPlans.daysOpen];

    if (openDays.includes(id)) {
      let index = openDays.indexOf(id);
      openDays.splice(index, 1);
    }

    dispatch(pageActions.setDaysOpen(openDays));
  };

  const isMealPlanDayOpen = (id) => {
    let index = mealPlans.daysOpen.indexOf(id);

    return index > -1;
  };

  const openMealPlanMeals = (id) => {
    let openMeals = [...mealPlans.mealsOpen];

    if (openMeals.includes(id)) return;

    openMeals.push(id);

    dispatch(pageActions.setMealsOpen(openMeals));
  };

  const closeMealPlanMeals = (id) => {
    let openMeals = [...mealPlans.mealsOpen];

    if (openMeals.includes(id)) {
      let index = openMeals.indexOf(id);
      openMeals.splice(index, 1);
    }

    dispatch(pageActions.setMealsOpen(openMeals));
  };

  const isMealPlanMealsOpen = (id) => {
    let index = mealPlans.mealsOpen.indexOf(id);

    return index > -1;
  };

  return {
    page,
    setPage,
    pages,
    navigation,
    navMenu,
    reload,
    refreshPage,
    refresh,
    entityName,
    setEntityName,
    toolbarAction,
    setToolbarAction,
    resetToolbarAction,
    getMainPages,
    defaultPageSecurity,
    setIsSaving,
    isSaving,
    refreshMenu,
    getPageSecurity,
    getPageSecurityByEntityName,
    openMealPlanDay,
    closeMealPlanDay,
    isMealPlanDayOpen,
    openMealPlanMeals,
    closeMealPlanMeals,
    isMealPlanMealsOpen,
    pageMode,
    setPageMode,
    dashboardMode,
    setDashboardMode,
  };
};

export default usePage;
