import { useEffect, useState, useCallback, useRef } from "react";
import { startOfToday } from "date-fns";

import useGrocery from "../../hooks/useGrocery";
import useWindow from "../../hooks/useWindow";
import useApi from "../../hooks/useApi";

import { Box, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDTitle from "../layout/OFDTitle";
import OFDToolbar from "../layout/OFDToolbar";

import OFDDate from "../ui/OFDDate";

import GroceryItemsShop from "./GroceryItemsShop";
import ExpenseEditor from "./ExpenseEditor";
import OFDButton from "../ui/OFDButton";
import OFDDialog from "../layout/OFDDialog";
import OFDMessage from "../ui/OFDMessage";

const GroceryShop = ({ grocery, groceryId, open, onClose }) => {
  const { isDesktop, windowHeight } = useWindow();
  const { getData } = useApi();
  const {
    setGroceryList,
    groceryList,
    updateGroceryItem,
    saveGrocery,
    setField,
    formMessage,
    resetFormMessage,
  } = useGrocery();

  const [openExpense, setOpenExpense] = useState(false);
  const [expense, setExpense] = useState(null);

  const [shoppingDate, setShoppingDate] = useState(startOfToday());
  const [expenseCalendarId, setExpenseCalendarId] = useState(null);

  const [changesMade, setChangesMade] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const [itemsHeight, setItemsHeight] = useState(300);

  // const containerRef = useCallback((node) => {
  //   if (!node) return;
  //   setContainerHeight(node.offsetHeight);
  // });

  // const titleRef = useCallback((node) => {
  //   if (!node) return;
  //   setTitleHeight(node.offsetHeight);
  // });

  const containerRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    if (!grocery) return;

    setGroceryList(grocery);
    if (grocery.expenseCalendarId) {
      setExpenseCalendarId(grocery.expenseCalendarId);
    } else {
      setExpenseCalendarId(null);
    }

    setChangesMade(false);
  }, [grocery]);

  useEffect(() => {
    if (!groceryId) return;

    getGrocery();
  }, [groceryId]);

  const getGrocery = async () => {
    const results = await getData({
      entityName: "Grocery",
      action: "get",
      id: groceryId,
    });

    if (results.isSuccessful) {
      setGroceryList(results.data);
      if (results.data.expenseCalendarId) {
        setExpenseCalendarId(results.data.expenseCalendarId);
      } else {
        setExpenseCalendarId(null);
      }

      setChangesMade(false);
    } else {
      setGroceryList(null);
    }
  };

  useEffect(() => {
    if (!containerRef.current || !titleRef.current) {
      setItemsHeight(300);
      return;
    }

    setItemsHeight(
      containerRef.current.clientHeight - titleRef.current.clientHeight - 40
    );
  }, [containerRef.current, titleRef.current]);

  const handleItemChange = (groceryItem) => {
    updateGroceryItem(groceryItem);
    setChangesMade(true);
  };

  const handleOpenExpense = () => {
    if (expenseCalendarId) {
      setExpense(null);
    } else {
      setExpense({
        name: groceryList.name,
        expenseDate: shoppingDate,
        budgetItem: groceryList.budgetItem,
        frequency: "onetime",
      });
    }
    setOpenExpense(true);
  };

  const handleSaveExpense = async (updatedExpense) => {
    if (updatedExpense && updatedExpense.id) {
      const expenseCalendar = await getData({
        entityName: "ExpenseCalendar",
        action: "get",
        filter: {
          expenseId: updatedExpense.id,
        },
      });
      if (
        expenseCalendar.isSuccessful &&
        Array.isArray(expenseCalendar.data) &&
        expenseCalendar.data.length > 0
      ) {
        setField("expenseCalendarId", expenseCalendar.data[0].id);
        setExpenseCalendarId(expenseCalendar.data[0].id);
        setChangesMade(true);
      }
    }
    setOpenExpense(false);
  };

  const handleDateChange = (newValue) => {
    setShoppingDate(newValue);
    setChangesMade(true);
  };

  const handleSave = async () => {
    const data = {
      shoppingDate,
      completedAt: new Date(),
    };

    const results = await saveGrocery(data);

    if (results.isSuccessful) {
      setChangesMade(false);
    }
  };

  const handleClose = () => {
    if (changesMade) {
      setConfirmClose(true);
    } else {
      handleConfirmClose("yes");
    }
  };

  const handleConfirmClose = (response) => {
    if (response === "yes") {
      onClose();
    }

    setConfirmClose(false);
  };

  if (!groceryList) return null;

  return (
    <>
      <OFDModal
        title="groceryShopping"
        open={open}
        onClose={handleClose}
      >
        <OFDToolbar
          sticky={true}
          menuItems={[{ name: "save", label: "save", icon: "save" }]}
          onClick={handleSave}
        />

        <Box
          ref={containerRef}
          sx={{ height: `${windowHeight - 120}px`, padding: "1rem" }}
        >
          <Box ref={titleRef}>
            <OFDTitle title={groceryList.name} />
            <Box sx={{ marginTop: "1rem" }}>
              <Box
                sx={{ display: "flex" }}
                gap={1}
              >
                <Box sx={{ width: "50%" }}>
                  <OFDDate
                    id="shoppingDate"
                    label="shoppingDate"
                    value={shoppingDate}
                    onChange={handleDateChange}
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <OFDButton
                    variant="contained"
                    label="expense"
                    color="grey"
                    shade={900}
                    iconName="addExpense"
                    onClick={handleOpenExpense}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ height: itemsHeight }}>
            <GroceryItemsShop
              grocery={groceryList}
              onItemChange={handleItemChange}
            />
          </Box>
        </Box>
      </OFDModal>

      <ExpenseEditor
        open={openExpense}
        onClose={() => setOpenExpense(false)}
        expense={expense}
        onSave={handleSaveExpense}
        source={{
          model: "Grocery",
          id: groceryList.id,
        }}
        expenseCalendarId={expenseCalendarId}
      />

      <OFDDialog
        open={confirmClose}
        title="cancelChanges"
        description="cancelChanges"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmClose}
      />

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />
    </>
  );
};

export default GroceryShop;
