import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { pageActions } from "../store/page";

import useApi from "./useApi";

const useSetting = () => {
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.page.modules);

  const { getData } = useApi();

  const getModules = async () => {
    if (modules) return modules;

    const results = await getData({
      entityName: "Setting",
      action: "get",
      filter: {
        name: "modules",
      },
    });

    if (results && results.isSuccessful) {
      dispatch(pageActions.setModules({ modules: results.data[0].value }));
    }
  };

  return {
    getModules,
    modules,
  };
};

export default useSetting;
