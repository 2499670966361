const BloodPressure = [
  {
    label: "normal",
    systolicLow: 0,
    systolicHigh: 119,
    diastolicLow: 0,
    diastolicHigh: 79,
    color: "green",
    shade: 200,
  },
  {
    label: "elevated",
    systolicLow: 120,
    systolicHigh: 129,
    diastolicLow: 0,
    diastolicHigh: 79,
    color: "lime",
    shade: 300,
  },
  {
    label: "high1",
    systolicLow: 130,
    systolicHigh: 139,
    diastolicLow: 80,
    diastolicHigh: 89,
    color: "orange",
    shade: 300,
  },
  {
    label: "high2",
    systolicLow: 140,
    systolicHigh: 179,
    diastolicLow: 90,
    diastolicHigh: 119,
    color: "orange",
    shade: 900,
  },
  {
    label: "high3",
    systolicLow: 180,
    systolicHigh: 999,
    diastolicLow: 120,
    diastolicHigh: 999,
    color: "red",
    shade: 900,
  },
];

export default BloodPressure;
