import { useEffect, useState, useRef, useCallback } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";

import useForm from "../../hooks/useForm";
import useEntity from "../../hooks/useEntity";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import usePage from "../../hooks/usePage";
import useWindow from "../../hooks/useWindow";

import OFDField from "../ui/OFDField";
import OFDModal from "../layout/OFDModal";
import OFDFieldGroup from "../ui/OFDFieldGroup";
import OFDIcon from "../ui/OFDIcon";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import OFDButton from "../ui/OFDButton";
import OFDFieldContainer from "../ui/OFDFieldContainer";
import OFDToolbar from "../layout/OFDToolbar";

const FieldEditorModal = ({
  entityName,
  entityData,
  field,
  folder,
  open,
  onCancel,
  onChange,
  returnValue,
  source,
}) => {
  const { labels, titles, getLabel } = useLocalization();
  const { getMemberState, member } = useMember();
  const { entityConfig } = useEntity(entityName);
  const { setIsSaving } = usePage();
  const { windowHeight } = useWindow();
  const {
    data,
    setData,
    setOriginalData,
    originalData,
    setFieldValue,
    getFieldValue,
    getFieldMessage,
    getFieldLabel,
    getFieldError,
    getFieldValueFromFolder,
    updateFieldInFolder,
    deleteFieldFromFolder,
    saveData,
    formMessage,
    formStatus,
    resetFormMessage,
  } = useForm(entityName);

  const [showDialog, setShowDialog] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [headerHeight, setHeaderHeight] = useState();
  const [buttonHeight, setButtonHeight] = useState();

  const mode = "editor";

  const headerRef = useCallback((node) => {
    if (!node) return;
    setHeaderHeight(node.clientHeight);
  });

  const buttonRef = useCallback((node) => {
    if (!node) return;
    setButtonHeight(node.clientHeight);
  });

  useEffect(() => {
    setChangesMade(false);
    resetFormMessage();
    if (!entityData) return;

    setData(entityData);
    setOriginalData(entityData);
  }, [entityData]);

  const handleSave = async () => {
    setIsSaving(true);
    if (!returnValue) {
      const results = await saveData();

      if (results.isSuccessful) {
        if (entityName === "Member" && !folder) {
          await getMemberState();
        }
        onChange();
        resetFormMessage(null);
      } else {
        setData(originalData);
      }
    } else {
      let fieldValue = null;

      if (folder) {
        fieldValue = getFieldValueFromFolder(folder.id, field);
      } else {
        fieldValue = getFieldValue(field.id);
      }

      onChange(fieldValue);
    }
    setIsSaving(false);
  };

  const handleCancel = () => {
    if (changesMade) {
      setShowDialog(true);
    } else {
      handleDialogClose("yes");
    }
  };

  const handleChange = async (newValue) => {
    if (newValue === field.value) return;

    setChangesMade(true);

    if (folder) {
      updateFieldInFolder(folder.id, field, newValue);
    } else {
      setFieldValue(field.id, newValue);
    }

    if (field.fieldType === "documents") {
      await handleSave();
    }
  };

  const getValue = () => {
    if (folder) {
      return getFieldValueFromFolder(folder.id, field);
    } else {
      return getFieldValue(field.id);
    }
  };

  const handleDelete = () => {
    setDeleteConfirmed(false);
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = async (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      deleteFieldFromFolder(folder.id, field);
      setDeleteConfirmed(true);
    }
  };

  const handleDialogClose = (response) => {
    setShowDialog(false);

    if (response === "yes") {
      setData(entityData);
      onCancel();
    }
  };

  useEffect(() => {
    if (formStatus === "changed" && data && deleteConfirmed) {
      handleSave();
    }
  }, [data, deleteConfirmed]);

  const handleMenuItemClick = (action) => {
    if (action === "save") {
      handleSave();
    }
  };

  if (!data || !field) return <></>;

  return (
    <>
      <OFDModal
        open={open}
        onClose={handleCancel}
        title={entityData?.name}
      >
        <Box
          sx={{
            height: `${windowHeight - 80}px`,
          }}
        >
          {field.fieldType !== "documents" ? (
            <OFDToolbar
              menuItems={[{ name: "save", label: "save", icon: "save" }]}
              onClick={handleMenuItemClick}
              sticky
            />
          ) : null}
          <Box
            sx={{
              height: "calc(100% - 5rem)",
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                marginBottom: "1rem",
                width: "100%",
                height: `calc(100%)`,
                overflow: "auto",
                paddingTop: ".5rem",
              }}
            >
              <OFDFieldContainer sx={{ maxHeight: "100%", overflow: "hidden" }}>
                <OFDField
                  key={field.id}
                  fieldType={field.fieldType}
                  mode={mode}
                  fieldProps={{
                    id: field.id,
                    label: getLabel(field.label),
                    value: getValue(),
                    onChange: (newValue) => handleChange(newValue),
                    message: getFieldMessage(field.id),
                    error: getFieldError(field.id) || false,
                    fullWidth: true,
                    memberData: entityName === "Member" ? data : null,
                    entityData: entityData,
                    listName: field.listName,
                    source: source,
                  }}
                />
              </OFDFieldContainer>
            </Box>
          </Box>
        </Box>
      </OFDModal>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteField"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <OFDDialog
        open={showDialog}
        title="cancelChanges"
        description="cancelChanges"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default FieldEditorModal;
