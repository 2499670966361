import { useEffect, useState } from "react";
import { format } from "date-fns";

import useApi from "../../hooks/useApi";
import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDTitle from "../layout/OFDTitle";

import OFDMessage from "../ui/OFDMessage";

const MealPlanDisplay = ({ mealPlanId, mealPlan, open, onClose }) => {
  const { getLabel } = useLocalization();
  const { getData } = useApi();

  const [data, setData] = useState();

  useEffect(() => {
    getMealPlan();
  }, [mealPlanId, mealPlan]);

  const getMealPlan = async () => {
    if (mealPlan) {
      setData(mealPlan);
      return;
    }

    const results = await getData({
      entityName: "MealPlan",
      action: "get",
      id: mealPlanId,
    });

    if (results.isSuccessful) {
      setData(results.data);
    } else {
      setData(null);
    }
  };

  if (!data) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="mealPlan"
      >
        <Stack
          spacing={1}
          sx={{ padding: "1rem" }}
        >
          <OFDTitle title={format(data.weekOf, "MMM dd, yyyy")} />
        </Stack>
      </OFDModal>
    </>
  );
};

export default MealPlanDisplay;
