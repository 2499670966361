import { useEffect, useState } from "react";

import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDToolbar from "../layout/OFDToolbar";
import SecurityAction from "./SecurityAction";
import OFDFieldContainer from "../ui/OFDFieldContainer";
import OFDSwitch from "../ui/OFDSwitch";

const defaultEntitySecurity = {
  view: { securityLevel: "family" },
  add: { securityLevel: "admin" },
  update: { securityLevel: "admin" },
  delete: { securityLevel: "admin" },
};

const defaultDocumentSecurity = {
  view: { securityLevel: "family" },
  update: { securityLevel: "admin" },
  delete: { securityLevel: "admin" },
};

const defaultFolderSecurity = {
  view: { securityLevel: "family" },
  update: { securityLevel: "addedBy" },
  delete: { securityLevel: "addedBy" },
};

const defaultFieldSecurity = {
  view: { securityLevel: "family" },
  update: { securityLevel: "admin" },
};

const SecurityEditor = ({
  entity,
  document,
  folder,
  field,
  open,
  onClose,
  onChange,
  entityName,
}) => {
  const { securityLevels, pageDefaultSecurity } = useSecurity();

  const [security, setSecurity] = useState(null);
  const [level, setLevel] = useState(null);

  useEffect(() => {
    const defaultSecurity = pageDefaultSecurity(entityName);
    if (entity) {
      setSecurity(entity.security ? entity.security : defaultSecurity);
      setLevel("entity");
    } else if (document) {
      setSecurity(document.security ? document.security : defaultSecurity);
      setLevel("document");
    } else if (folder) {
      setSecurity(folder.security ? folder.security : defaultSecurity);
      setLevel("folder");
    } else if (field) {
      setSecurity(field.security ? folder.security : defaultSecurity);
      setLevel("field");
    }
  }, [entity, document, folder, field]);

  const handleMenuClick = (action) => {
    if (action === "save") {
      onChange(security);
      onClose();
    }
  };

  const handleChange = (action, value) => {
    setSecurity((current) => {
      let newSecurity = { ...current };
      newSecurity[action] = value;

      return newSecurity;
    });
  };

  const isPrivate = () => {
    if (!security || !security.view) return false;
    if (security.view.securityLevel === "addedBy") return true;
    return false;
  };

  const setPrivate = (newValue) => {
    let newSecurity = {};
    if (security) newSecurity = { ...security };

    if (newValue) {
      newSecurity.view.securityLevel = "addedBy";
      newSecurity.update.securityLevel = "addedBy";
      newSecurity.delete.securityLevel = "addedBy";
    } else {
      newSecurity.view.securityLevel = "family";
    }

    setSecurity(newSecurity);
  };

  if (!security) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="securityEditor"
      >
        <OFDToolbar
          id="toolbar"
          menuItems={[{ name: "save", label: "save", icon: "save" }]}
          onClick={handleMenuClick}
        />
        <OFDFieldContainer sx={{ margin: "1rem" }}>
          <Stack spacing={2}>
            <OFDSwitch
              id="isPrivate"
              label="isPrivate"
              value={isPrivate()}
              onChange={setPrivate}
            />

            <SecurityAction
              action="view"
              value={security.view}
              onChange={handleChange}
            />
            <SecurityAction
              action="update"
              value={security.update}
              onChange={handleChange}
            />
            <SecurityAction
              action="delete"
              value={security.delete}
              onChange={handleChange}
            />
            {level === "entity" ? (
              <SecurityAction
                action="add"
                value={security.add}
                onChange={handleChange}
              />
            ) : null}
          </Stack>
        </OFDFieldContainer>
      </OFDModal>
    </>
  );
};

export default SecurityEditor;
