import { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import useLocalization from "../../../hooks/useLocalization";
import useSecurity from "../../../hooks/useSecurity";
import useBudget from "../../../hooks/useBudget";
import useData from "../../../hooks/useData";

import { Box, Button } from "@mui/material";

import OFDModal from "../../layout/OFDModal";

import OFDButton from "../../ui/OFDButton";

import BudgetIntro from "./BudgetIntro";
import BudgetCopy from "./BudgetCopy";
import BudgetItems from "./BudgetItems";
import BudgetName from "./BudgetName";
import BudgetSecurity from "./BudgetSecurity";
// import BudgetSummary from "./BudgetSummary";

import BudgetTemplates from "../../../configuration/BudgetTemplates";

const steps = [
  { step: 0, name: "intro" },
  { step: 1, name: "copy" },
  { step: 2, name: "name" },
  { step: 3, name: "budgetItems" },
  { step: 4, name: "security" },
];

const BudgetWizard = ({
  budget,
  open,
  onClose,
  onCreateBudget,
  onUpdateBudget,
  year,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { pageDefaultSecurity } = useSecurity();
  const { getBudget } = useBudget();
  const { newId } = useData();

  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [firstStep, setFirstStep] = useState(steps[0]);

  const [name, setName] = useState("");
  const [category, setCategory] = useState(null);
  const [budgetItems, setBudgetItems] = useState(null);
  const [security, setSecurity] = useState(null);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const [budgetItemErrors, setBudgetItemErrors] = useState(null);

  useEffect(() => {
    if (open) {
      if (budget) {
        setCurrentStep(steps[2]);
        setFirstStep(steps[2]);
      } else {
        setName("");
        setCategory("");
        setBudgetItems(null);
        setSecurity(pageDefaultSecurity("Budget"));

        setCurrentStep(steps[0]);
        setFirstStep(steps[0]);
      }
    }
  }, [open]);

  useEffect(() => {
    if (budget) {
      setName(budget.name);
      setCategory(budget.category);
      setBudgetItems(budget.budgetItems);
      setSecurity(budget.security);
      setCurrentStep(1);
      setFirstStep(1);
    }
  }, [budget]);

  const handleBack = () => {
    setCurrentStep((current) => {
      if (current === firstStep) return current;

      return steps[(current.step = 1)];
    });
  };

  const handleNext = () => {
    if (!stepValidated()) return;

    if (currentStep.step === steps.length - 1) {
      handleSave();
    } else {
      setCurrentStep((current) => {
        if (current >= steps.length - 1) return current;

        return steps[current.step + 1];
      });
    }
  };

  const stepValidated = () => {
    setError(false);
    setMessage(null);

    if (currentStep.step === 0) return true;

    if (currentStep.name === "name") {
      if (!name) {
        setError(true);
        setMessage(getMessage("isEmpty"));
        return false;
      }
    } else if (currentStep.name === "budgetItems") {
      if (!Array.isArray(budgetItems) || budgetItems.length === 0) {
        setError(true);
        setMessage(getMessage("isEmpty"));
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    let newBudget;

    if (!budget) {
      newBudget = {
        name,
        category,
        budgetItems,
        year,
        security,
      };

      onCreateBudget(newBudget);
    } else {
      newBudget = {
        ...budget,
        name,
        category,
        budgetItems,
        security,
      };
      onUpdateBudget(newBudget);
    }
  };

  const handleCopyBudget = async (source, fromBudget) => {
    if (source === "scratch") {
      setName(getLabel("household"));
      setCategory("house");
      setBudgetItems(null);
    } else if (source === "copy") {
      const copyFromBudget = await getBudget(fromBudget);
      setName(copyFromBudget.name);
      setCategory(copyFromBudget.category);
      setBudgetItems(copyFromBudget.budgetItems);
    } else if (source === "template") {
      setName(getLabel("household"));
      setCategory("house");
      createBudgetItemsFromTemplate(BudgetTemplates[fromBudget]);
    }
  };

  const createBudgetItemsFromTemplate = (template) => {
    const items = template.map((item) => {
      return { id: newId(), ...item };
    });

    setBudgetItems(items);
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="budgetWizard"
        color="grey"
        shade="900"
      >
        <Box
          sx={{
            padding: "1rem",
            height: "calc(100% - 3rem)",
          }}
        >
          {currentStep.name === "intro" && !budget ? <BudgetIntro /> : null}

          {currentStep.name === "copy" ? (
            <BudgetCopy
              year={year}
              onChange={handleCopyBudget}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep.name === "name" ? (
            <BudgetName
              name={name}
              onChange={(newValue) => setName(newValue)}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep.name === "budgetItems" ? (
            <BudgetItems
              open={true}
              onChange={(newValue) => setBudgetItems(newValue)}
              budgetItems={budgetItems}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep.name === "security" ? (
            <BudgetSecurity
              open={true}
              entityName="Budget"
              security={security}
              onChange={(newValue) => setSecurity(newValue)}
            />
          ) : null}
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "1rem",
            right: "1rem",
            height: "4rem",
            borderTop: "1px solid #ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "1rem",
            paddingTop: ".5rem",
          }}
          gap={2}
        >
          <OFDButton
            id="back"
            variant="outlined"
            disabled={currentStep === firstStep}
            onClick={handleBack}
            label={getLabel("back")}
            width="auto"
            color="grey"
            shade={900}
          />

          <OFDButton
            id="next"
            variant="contained"
            onClick={
              currentStep.step < steps.length - 1 ? handleNext : handleSave
            }
            color="grey"
            shade={900}
            label={getLabel(
              currentStep.step < steps.length - 1
                ? "next"
                : budget
                ? "updateBudget"
                : "createBudget"
            )}
            width="auto"
          />
        </Box>
      </OFDModal>
    </>
  );
};

export default BudgetWizard;
