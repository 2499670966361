import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import useApi from "../../../hooks/useApi";
import useLocalization from "../../../hooks/useLocalization";

import { Box, Button, Stack, Typography } from "@mui/material";

import FamilyContentSlider from "../../layout/FamilySliderLayout";

const VerifyEmail = () => {
  const { postData } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const { titles, labels, text, getText, getLabel, getMessage } =
    useLocalization(process.env.REACT_APP_LOCALE);

  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    const signupId = searchParams.get("id");
    const email = searchParams.get("email");

    const results = await postData({
      entityName: "Signup",
      action: "verifyEmail",
      data: {
        signupId,
        email,
      },
    });

    if (results.isSuccessful) {
      setTitle("Email Verified!");
      setMessage("You can continue with picking a plan!");
    } else {
      setTitle("Error Encountered");
      setMessage(results.message);
    }
  };

  return (
    <FamilyContentSlider>
      <Box>
        <Typography
          variant="h4"
          align="center"
        >
          {title}
        </Typography>

        <Typography
          mt="2rem"
          align="center"
          variant="h6"
          whiteSpace="pre-wrap"
        >
          {message}
        </Typography>

        <Box
          sx={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}
        >
          <Button onClick={() => window.close()}>Close Window</Button>
        </Box>
      </Box>
    </FamilyContentSlider>
  );
};

export default VerifyEmail;
