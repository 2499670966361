import { Navigate, Outlet } from "react-router-dom";
import useSecurity from "../hooks/useSecurity";

const PublicRoutes = () => {
  const { isAuthenticated } = useSecurity();

  return !isAuthenticated ? <Outlet /> : <Navigate to="/app" />;
};

export default PublicRoutes;
