import { useEffect } from "react";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";

import useApi from "./useApi";
import useSecurity from "./useSecurity";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.AUTHDOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FB_APPID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

const useNotifications = () => {
  const { postData } = useApi();
  const { isAuthenticated } = useSecurity();

  const iOSPushCapability =
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers["push-permission-request"] &&
    window.webkit.messageHandlers["push-token"];

  useEffect(() => {
    if (iOSPushCapability) {
      window.addEventListener("push-permission-request", permissionRequest);
      window.addEventListener("push-token", pushToken);
    }

    return () => {
      if (iOSPushCapability) {
        window.removeEventListener("push-token", pushToken);
        window.removeEventListener(
          "push-permission-request",
          permissionRequest
        );
      }
    };
  }, []);

  const notificationsAvailable = () => {
    if ("Notification" in window) return true;
    return false;
  };

  const permissionRequest = async (event) => {
    if (event && event.detail && event.detail === "granted") {
      window.webkit.messageHandlers["push-token"].postMessage("push-token");
    }
  };

  const pushToken = async (event) => {
    const currentToken = event.detail ? event.detail : null;

    await updateMemberSubscription(currentToken);
  };

  const askForNotificationPermission = async () => {
    try {
      if (iOSPushCapability) {
        window.webkit.messageHandlers["push-permission-request"].postMessage(
          "push-permission-request"
        );
      } else {
        Notification.requestPermission()
          .then(async (permission) => {
            if (permission === "granted") {
              return getToken(messaging, {
                vapidKey: process.env.REACT_APP_VAPID,
              })
                .then(async (currentToken) => {
                  if (currentToken) {
                    await updateMemberSubscription(currentToken);
                  } else {
                    await updateMemberSubscription(null);
                  }
                })
                .catch(async (err) => {
                  await updateMemberSubscription(null);
                });
            } else {
              await updateMemberSubscription(null);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const displayNotification = (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: "../assets/images/logo.png",
      badge: "../assets/images/family-of-3-only_EA751F_Org.png",
    };

    if (!("serviceWorker" in navigator)) return;
    navigator.serviceWorker.ready.then((swreg) => {
      swreg.showNotification(notificationTitle, notificationOptions);
    });
  };

  const updateMemberSubscription = async (pushToken) => {
    if (!isAuthenticated) return;

    const results = await postData({
      entityName: "Member",
      action: "updatePushSubscription",
      data: {
        pushToken: pushToken,
      },
    });
  };

  return {
    notificationsAvailable,
    askForNotificationPermission,
    updateMemberSubscription,
    displayNotification,
  };
};

export default useNotifications;
