import { useEffect, useState } from "react";

import useExpense from "../../hooks/useExpense";
import useLocalization from "../../hooks/useLocalization";
import usePage from "../../hooks/usePage";
import useSearch from "../../hooks/useSearch";

import { Box, Stack, Typography } from "@mui/material";

import Loading from "../layout/Loading";
import RecurringExpense from "./RecurringExpense";

const RecurringExpenses = ({ year, filter }) => {
  const { getRecurring, recurring } = useExpense();
  const { getText } = useLocalization();
  const { refresh, refreshPage } = usePage();
  const {
    data,
    setData,
    searchData,
    searchResults,
    searchCriteria,
    setSearchCriteria,
  } = useSearch();

  useEffect(() => {
    getData();
    setSearchCriteria(filter);
  }, [year, refresh, filter]);

  const getData = async () => {
    await getRecurring(year);
  };

  useEffect(() => {
    setData(recurring);
  }, [recurring]);

  useEffect(() => {
    searchData();
  }, [data]);

  const handleOnChange = async () => {
    await getData();
  };

  if (searchResults === undefined) return <Loading />;

  return (
    <>
      <Box sx={{ marginTop: "1rem" }}>
        {Array.isArray(searchResults) && searchResults.length > 0 ? (
          <Stack spacing={1}>
            {searchResults?.map((expense) => (
              <RecurringExpense
                key={expense.id}
                expense={expense}
                onChange={handleOnChange}
              />
            ))}
          </Stack>
        ) : (
          <Typography>{getText("noRecurringExpenses")}</Typography>
        )}
      </Box>
    </>
  );
};

export default RecurringExpenses;
