import { useEffect, useState } from "react";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import EntityEditor from "../editors/EntityEditor";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import Loading from "../layout/Loading";
import OFDModal from "../layout/OFDModal";
import TokenBanks from "../dashboards/TokenBanks";

const Chores = () => {
  const { getMessage, getLabel } = useLocalization();
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const {
    entityList,
    getEntityList,
    menuClick,
    entityMessage,
    resetEntityMessage,
    setEntityList,
  } = useEntity("Chore");

  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [openTokens, setOpenTokens] = useState(false);
  const [tokenMessage, setTokenMessage] = useState();

  useEffect(() => {
    setOpenForm(false);
    setEntityName("Chore");
    getData();
  }, [refresh]);

  const getData = async () => {
    setEntityList(undefined);
    await getEntityList({ sortFields: { field: "name" } });
  };

  useEffect(() => {
    if (toolbarAction === "addChore") {
      setOpenForm(true);
    } else if (toolbarAction === "tokens") {
      setOpenTokens(true);
    }

    resetToolbarAction();
  }, [toolbarAction]);

  const handleEditEntity = async () => {
    await getData();
  };

  const handleFormClose = async () => {
    setOpenForm(false);
    resetToolbarAction();
    await getData();
  };

  const handleMenuClick = (action, entity) => {
    setSelectedEntity(entity);

    if (action === "delete") {
      setOpenDeleteConfirmation(true);
    }
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      const results = await menuClick("delete", selectedEntity);

      if (results.isSuccessful) {
        await getData();
      }
    }
    setOpenDeleteConfirmation(false);
  };

  const handleSaveTokens = () => {
    setOpenTokens(false);
    setTokenMessage({
      message: "saved",
      displayToUser: true,
    });
  };

  return (
    <>
      <OFDTitle title="chores" />

      {entityList !== undefined ? (
        <Box sx={{ paddingTop: "1rem" }}>
          {entityList.length > 0 ? (
            <OFDEntityList
              entityName="Chore"
              entityList={entityList}
              onEdit={handleEditEntity}
              onMenuItemClick={handleMenuClick}
              options={{
                showAvatar: false,
              }}
            />
          ) : (
            <Typography align="center">{getMessage("noChores")}</Typography>
          )}
        </Box>
      ) : (
        <Loading />
      )}

      {openForm ? (
        <EntityEditor
          entityName="Chore"
          open={openForm}
          onClose={handleFormClose}
        />
      ) : null}

      {openTokens ? (
        <OFDModal
          open={openTokens}
          onClose={() => setOpenTokens(false)}
          title={getLabel("tokens")}
        >
          <Box sx={{ padding: "1rem" }}>
            <TokenBanks
              fullSize={true}
              onSave={handleSaveTokens}
            />
          </Box>
        </OFDModal>
      ) : null}

      <OFDMessage
        message={entityMessage || tokenMessage}
        onClose={resetEntityMessage}
      />

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_chore"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default Chores;
