import { useEffect, useState } from "react";

import { Box, Typography, Divider } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";

import OFDIcon from "./OFDIcon";
import OFDDisplayReceipts from "./OFDDisplayReceipts";

const OFDReceipts = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
}) => {
  const { getLabel, getMessage } = useLocalization();

  const [openPicture, setOpenPicture] = useState(false);

  const handleCancelPicture = () => {
    setOpenPicture(false);
  };

  const handleChangePicture = () => {};

  return (
    <>
      <Box>
        <Typography
          variant="h6"
          align="center"
        >
          Receipts
        </Typography>
      </Box>
    </>
  );
};

export default OFDReceipts;
