import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDTextField from "../../ui/OFDTextField";

const FolderName = ({ folderName, onChange }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">{getTitle("folderName")}</Typography>

      <Stack spacing={2}>
        {getWizard("folder").folderName.instructions.map(
          (instruction, index) => (
            <Typography key={`instruction-${index}`}>{instruction}</Typography>
          )
        )}

        <OFDTextField
          id="folderName"
          label="folderName"
          value={folderName}
          onChange={(newValue) => onChange(newValue)}
        />
      </Stack>
    </Box>
  );
};

export default FolderName;
