import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useData from "../../hooks/useData";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayBudgetItem from "./OFDDisplayBudgetItem";

const OFDDisplayBudgetItems = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();
  const { sortByFields } = useData();

  const [sortedList, setSortedList] = useState([]);

  useEffect(() => {
    if (!value || !Array.isArray(value)) return;

    setSortedList(sortByFields(value, ["type", "name"]));
  }, [value]);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    onClick();
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={handleClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>

      <Box>
        <Stack>
          {sortedList?.map((item) => (
            <OFDDisplayBudgetItem
              key={item.id}
              value={item}
              noLabel
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayBudgetItems;
