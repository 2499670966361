import { useEffect, useState } from "react";
import { format, parse } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";
import useData from "../../hooks/useData";
import useMember from "../../hooks/useMember";
import usePage from "../../hooks/usePage";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";
import Loading from "../layout/Loading";
import AssignedChore from "./AssignedChore";

const AssignedChores = ({ memberId }) => {
  const { getMessage, getLabel } = useLocalization();
  const { memberColor } = useMember();
  const { postData } = useApi();
  const { refresh } = usePage();

  const [chores, setChores] = useState(undefined);
  const [daily, setDaily] = useState(null);
  const [weekly, setWeekly] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [oneTime, setOneTime] = useState(null);

  useEffect(() => {
    getChores();
  }, [memberId, refresh]);

  const getChores = async () => {
    if (!memberId) return;

    const results = await postData({
      entityName: "Member",
      action: "getMemberChores",
      data: {
        memberId,
      },
    });

    if (results.isSuccessful) {
      if (Array.isArray(results.data)) {
        setChores(results.data);
        setDaily(results.data.filter((chore) => chore.frequency === "daily"));
        setWeekly(results.data.filter((chore) => chore.frequency === "weekly"));
        setMonthly(
          results.data.filter((chore) => chore.frequency === "monthly")
        );
        setOneTime(
          results.data.filter((chore) => chore.frequency === "onetime")
        );
      } else {
        setChores(null);
      }
    } else {
      setChores(null);
    }
  };

  const handleChange = async () => {
    await getChores();
  };

  if (chores === undefined) return <Loading />;
  if (!Array.isArray(chores) || chores.length === 0) return null;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          ...memberColor(),
          padding: ".5rem",
        }}
        gap={1}
      >
        <OFDIcon
          name="chores"
          size="medium"
          color="grey"
          shade={50}
        />
        <Typography variant="h5">{getLabel("chores")}</Typography>
      </Box>

      <Stack
        sx={{ padding: "1rem" }}
        spacing={2}
      >
        {Array.isArray(daily) && daily.length > 0 ? (
          <Stack
            spacing={1}
            sx={{ borderBottom: "1px solid #ccc", paddingBottom: "5px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{`${getLabel("today")}`}</Typography>
              <Typography>
                {format(parse(daily[0].date, "yyyyMMdd", new Date()), "EEE dd")}
              </Typography>
            </Box>
            {daily?.map((chore) => (
              <AssignedChore
                key={chore.choreId}
                chore={chore}
                onChange={handleChange}
              />
            ))}
          </Stack>
        ) : null}

        {Array.isArray(weekly) && weekly.length > 0 ? (
          <Stack
            spacing={1}
            sx={{ borderBottom: "1px solid #ccc", paddingBottom: "5px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{`${getLabel("thisWeek")}`}</Typography>
              <Typography>
                {format(
                  parse(weekly[0].date, "yyyyMMdd", new Date()),
                  "EEE dd"
                )}
              </Typography>
            </Box>
            {weekly?.map((chore) => (
              <AssignedChore
                key={chore.choreId}
                chore={chore}
                onChange={handleChange}
              />
            ))}
          </Stack>
        ) : null}

        {Array.isArray(monthly) && monthly.length > 0 ? (
          <Stack
            spacing={1}
            sx={{ borderBottom: "1px solid #ccc", paddingBottom: "5px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{`${getLabel("thisMonth")}`}</Typography>
              <Typography>
                {format(
                  parse(monthly[0].date, "yyyyMMdd", new Date()),
                  "EEE dd"
                )}
              </Typography>
            </Box>
            {monthly?.map((chore) => (
              <AssignedChore
                key={chore.choreId}
                chore={chore}
                onChange={handleChange}
              />
            ))}
          </Stack>
        ) : null}

        {Array.isArray(oneTime) && oneTime.length > 0 ? (
          <Stack
            spacing={1}
            sx={{ borderBottom: "1px solid #ccc", paddingBottom: "5px" }}
          >
            {oneTime?.map((chore) => (
              <Box key={chore.id}>
                <Typography variant="h6">
                  {format(parse(chore.date, "yyyyMMdd"), "EEE MMM dd")}
                </Typography>

                <AssignedChore
                  key={chore.choreId}
                  chore={chore}
                  onChange={handleChange}
                />
              </Box>
            ))}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};

export default AssignedChores;
