import { useEffect, useState } from "react";

import useLocalization from "../../../../hooks/useLocalization";
import useWindow from "../../../../hooks/useWindow";

import { Box } from "@mui/material";

import HomeDashboard from "./HomeDashboard";
import CalendarDashboard from "./CalendarDashboard";
import ChoresDashboard from "./ChoresDashboard";
import ToDoDashboard from "./ToDoDashboard";
import HomeworkDashboard from "./HomeworkDashboard";
import GroceryDashboard from "./GroceryDashboard";
import MealPlanDashboard from "./MealPlanDashboard";
import RecipeDashboard from "./RecipeDashboard";

const DashboardBody = ({ selectedMenuItem }) => {
  const { windowHeight, windowWidth } = useWindow();

  const [height, setHeight] = useState();

  useEffect(() => {
    setHeight(windowHeight - 170);
  }, [windowHeight, windowWidth]);

  return (
    <Box
      sx={{
        width: `${windowWidth}px`,
        height: `${height}px`,
        overflow: "auto",
      }}
    >
      {selectedMenuItem === "home" ? (
        <HomeDashboard height={height - 40} />
      ) : null}

      {selectedMenuItem === "calendar" ? (
        <CalendarDashboard height={height} />
      ) : null}

      {selectedMenuItem === "chores" ? (
        <ChoresDashboard height={height} />
      ) : null}

      {selectedMenuItem === "homework" ? (
        <HomeworkDashboard height={height} />
      ) : null}

      {selectedMenuItem === "groceries" ? (
        <GroceryDashboard height={height} />
      ) : null}

      {selectedMenuItem === "mealplan" ? (
        <MealPlanDashboard height={height} />
      ) : null}

      {selectedMenuItem === "recipes" ? (
        <RecipeDashboard height={height} />
      ) : null}

      {selectedMenuItem === "todo" ? <ToDoDashboard height={height} /> : null}
    </Box>
  );
};

export default DashboardBody;
