import React from "react";

import useLocalization from "../../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import MemberWidget from "./MemberWidget";
import GoldToken from "../../../../assets/images/GoldToken.png";
import ChoresByMember from "./ChoresByMember";

const MemberChores = ({ activity, choreList, onChange, height, dayOf }) => {
  const { getLabel } = useLocalization();

  return (
    <>
      <MemberWidget
        memberId={activity.memberId}
        height={height}
        headerContent={
          <Box sx={{ position: "relative", height: "50px" }}>
            <img
              src={GoldToken}
              style={{ height: "50px", width: "auto" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "0px",
                left: "0px",
                bottom: "0px",
                right: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 900, color: "#000000" }}>
                {activity.tokenBank}
              </Typography>
            </Box>
          </Box>
        }
      >
        <Box sx={{ padding: "1rem" }}>
          <Stack spacing={2}>
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: ".5rem",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {activity.tokenObjective && activity.tokenObjective.image ? (
                <Box>
                  <img
                    src={activity.tokenObjective.image}
                    style={{ height: "50px", width: "auto" }}
                  />
                </Box>
              ) : null}

              {activity.tokenObjective.tokens ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">
                    {activity.tokenObjective.tokens}
                  </Typography>
                  <Typography variant="caption">
                    {getLabel("tokens")}
                  </Typography>
                </Box>
              ) : null}

              {activity.tokenObjective.tokens ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">
                    {activity.tokenObjective.tokens -
                      (activity.tokenBank ? activity.tokenBank : 0)}
                  </Typography>
                  <Typography variant="caption">
                    {getLabel("tokensNeeded")}
                  </Typography>
                </Box>
              ) : null}
            </Box>

            <ChoresByMember
              memberChores={activity}
              choreList={choreList}
              onChange={onChange}
              forDate={dayOf}
              hideHeader={true}
            />
          </Stack>
        </Box>
      </MemberWidget>
    </>
  );
};

export default MemberChores;
