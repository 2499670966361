import { useEffect, useState } from "react";
import { startOfToday, endOfToday } from "date-fns";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useForm from "../../hooks/useForm";
import useWindow from "../../hooks/useWindow";
import useApi from "../../hooks/useApi";
import useData from "../../hooks/useData";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import GroceryWizard from "../wizards/grocery/GroceryWizard";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import GroceryShop from "../editors/GroceryShop";
import SecurityEditor from "../editors/SecurityEditor";
import OFDListAndDetails from "../layout/OFDListAndDetails";
import EntityDisplay from "../display/EntityDisplay";
import Search from "../forms/Search";
import SearchCriteria from "../forms/SearchCriteria";
import Loading from "../layout/Loading";

const Groceries = () => {
  const {
    setEntityName,
    toolbarAction,
    resetToolbarAction,
    refresh,
    refreshPage,
  } = usePage();
  const {
    entityList,
    getEntityList,
    setEntityList,
    getSearchFields,
    searchResults,
    searchCriteria,
    setSearchCriteria,
  } = useEntity("Grocery");
  const {
    data,
    setData,
    saveData,
    setFieldValue,
    getFieldValue,
    formMessage,
    formStatus,
    resetFormMessage,
    deleteDocument,
  } = useForm("Grocery");
  const { isDesktop } = useWindow();

  const { getData } = useApi();
  const { sortByFields } = useData();

  const [openWizard, setOpenWizard] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedGrocery, setSelectedGrocery] = useState(null);
  const [openGroceryShop, setOpenGroceryShop] = useState(false);
  const [openSecurityEditor, setOpenSecurityEditor] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    setOpenWizard(false);
    setEntityName("Grocery");
    getGrocery();
  }, [refresh]);

  useEffect(() => {
    if (toolbarAction === "add") {
      setData(null);
      setOpenWizard(true);
      setSelectedGrocery(null);
    }
    if (toolbarAction === "search") {
      setOpenSearch(true);
      setEntityList(fullList);
    }

    resetToolbarAction();
  }, [toolbarAction]);

  useEffect(() => {
    if (!entityList) return;
    if (selectedGrocery) {
      for (const grocery of entityList) {
        if (grocery.id === selectedGrocery.id) {
          setSelectedGrocery(grocery);
        }
      }
    }
  }, [entityList]);

  const getGrocery = async () => {
    setEntityList(undefined);
    setFullList([]);

    const results = await getData({
      entityName: "Grocery",
      action: "get",
      filter: {},
    });

    if (results.isSuccessful) {
      setFullList(results.data);
    } else {
      setEntityList([]);
    }
  };

  useEffect(() => {
    if (!fullList) return;
    setCurrentList();
  }, [fullList]);

  const setCurrentList = () => {
    let list = fullList.filter((item) => item.isCurrent);
    list = sortByFields(list, ["shoppingDate"]);
    setEntityList(list);
  };

  const handleWizardClose = async () => {
    setOpenWizard(false);
    resetToolbarAction();
    await getGrocery();
    setData(null);
  };

  const handleCreateGrocery = async (newGrocery) => {
    await saveGrocery(newGrocery);
  };

  const handleUpdateGrocery = async (updatedGrocery) => {
    await saveGrocery(updatedGrocery);
  };

  const saveGrocery = async (groceryData) => {
    const results = await saveData({ data: groceryData });

    if (results.isSuccessful) {
      await getGrocery();
      setOpenWizard(false);
    }
  };

  const handleDelete = async (item) => {
    setSelectedGrocery(item);
    setOpenDelete(true);
  };

  const handleConfirmDelete = async (response) => {
    if (response === "yes") {
      await deleteDocument({ data: selectedGrocery });
      await getGrocery();
    }
    setSelectedGrocery(null);
    setOpenDelete(false);
  };

  const handleGroceryShop = (groceryshop) => {
    setSelectedGrocery(groceryshop);
    setOpenGroceryShop(true);
  };

  const handleCloseGroceryShop = () => {
    setOpenGroceryShop(false);
    refreshPage();
  };

  const handleMenuItemClick = (name, entity) => {
    setSelectedGrocery(entity);

    switch (name) {
      case "delete":
        handleDelete(entity);
        break;

      case "shop":
        handleGroceryShop(entity);
        break;

      case "security":
        handleSecutity(entity);
        break;
    }
  };

  const handleSecutity = (item) => {
    setSelectedGrocery(item);
    setOpenSecurityEditor(true);
  };

  const handleCloseSecurity = () => {
    setOpenSecurityEditor(false);
  };

  const handleChangeSecurity = async (newSecurity) => {
    handleCloseSecurity();

    let newGrocery = { ...selectedGrocery, security: { ...newSecurity } };

    await saveGrocery(newGrocery);
  };

  const handleOpenRight = (entityName, id, entity) => {
    setSelectedGrocery(entity);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleClearSearch = () => {
    setCurrentList();
    setSearchCriteria(null);
    setOpenSearch(false);
  };

  const handleSearch = (newSearch) => {
    if (!newSearch) {
      setCurrentList();
    }
    setSearchCriteria(newSearch);
    setOpenSearch(false);
  };

  const handleEdit = async () => {
    await getGrocery();
  };

  const handleSelectedFolder = () => {};

  const handleOpenFolderEditor = () => {};

  return (
    <>
      <OFDTitle title="grocery" />

      <SearchCriteria
        searchCriteria={searchCriteria}
        onClear={handleClearSearch}
      />

      {entityList !== undefined ? (
        isDesktop ? (
          <OFDListAndDetails
            list={
              <OFDEntityList
                entityName="Grocery"
                entityList={searchResults}
                onMenuItemClick={handleMenuItemClick}
                headerFields={[
                  { name: "name", type: "string" },
                  { name: "shoppingDate", type: "date" },
                ]}
                options={{
                  showAvatar: false,
                }}
                onOpenRight={handleOpenRight}
              />
            }
            details={
              selectedGrocery ? (
                <EntityDisplay
                  entityName="Grocery"
                  id={selectedGrocery.id}
                  entityData={selectedGrocery}
                  onChange={handleEdit}
                  onFolderSelected={handleSelectedFolder}
                  onFolderEdit={handleOpenFolderEditor}
                />
              ) : null
            }
          />
        ) : (
          <OFDEntityList
            entityName="Grocery"
            entityList={searchResults}
            onMenuItemClick={handleMenuItemClick}
            headerFields={[
              { name: "name", type: "string" },
              { name: "shoppingDate", type: "date" },
            ]}
            options={{
              showAvatar: false,
            }}
            onEdit={handleEdit}
          />
        )
      ) : (
        <Loading />
      )}

      {openWizard ? (
        <GroceryWizard
          grocery={data}
          open={openWizard}
          onClose={handleWizardClose}
          onCreateGrocery={handleCreateGrocery}
          onUpdateGrocery={handleUpdateGrocery}
        />
      ) : null}

      {openGroceryShop ? (
        <GroceryShop
          grocery={selectedGrocery}
          open={openGroceryShop}
          onClose={handleCloseGroceryShop}
        />
      ) : null}

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <OFDDialog
        open={openDelete}
        title="confirmDelete"
        description={`confirmDelete_groceryList`}
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <SecurityEditor
        open={openSecurityEditor}
        onClose={handleCloseSecurity}
        document={selectedGrocery}
        onChange={handleChangeSecurity}
      />

      {openSearch ? (
        <Search
          open={openSearch}
          searchCriteria={searchCriteria}
          onClose={handleSearchClose}
          searchFields={getSearchFields()}
          onSearch={handleSearch}
        />
      ) : null}
    </>
  );
};

export default Groceries;
