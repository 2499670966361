import { useEffect, useState } from "react";

import useApi from "./useApi";
import useFamily from "./useFamily";
import useData from "./useData";

const useRecipe = () => {
  const { sort } = useData();
  const { getData } = useApi();
  const { family } = useFamily();

  const [recipes, setRecipes] = useState(undefined);
  const [filteredRecipes, setFilteredRecipes] = useState(undefined);

  const getRecipes = async () => {
    const results = await getData({
      entityName: "Recipe",
      action: "get",
      filter: {
        familyId: family.id,
      },
    });

    if (results.isSuccessful) {
      setRecipes(sort(results.data, "name"));
    } else {
      setRecipes(null);
    }
  };

  useEffect(() => {
    setFilteredRecipes(recipes);
  }, [recipes]);

  const filterRecipes = (filter) => {
    if (!recipes) return [];
    if (!filter || (!filter.searchValue && !filter.mealTypes)) {
      setFilteredRecipes([...recipes]);
      return;
    }

    const searchValue = filter.searchValue
      ? filter.searchValue.toLowerCase()
      : null;

    const mealTypes = filter.mealTypes ? filter.mealTypes : null;

    let recipesFound = [];

    for (const recipe of recipes) {
      let isMealType = true;

      if (
        Array.isArray(mealTypes) &&
        mealTypes.length > 0 &&
        Array.isArray(recipe.mealType) &&
        recipe.mealType.length > 0
      ) {
        isMealType = false;

        for (const mealType of recipe.mealType) {
          if (mealTypes.includes(mealType)) {
            isMealType = true;
          }
        }
      }

      if (!isMealType) {
        setFilteredRecipes(null);
        continue;
      }

      if (searchValue) {
        if (recipe.name.toLowerCase().indexOf(searchValue) > -1) {
          recipesFound.push({ ...recipe });
          continue;
        }

        if (
          recipe.hasOwnProperty("description") &&
          recipe.description?.toLowerCase().indexOf(searchValue) > -1
        ) {
          recipesFound.push({ ...recipe });
          continue;
        }

        if (
          recipe.hasOwnProperty("ingredients") &&
          Array.isArray(recipe.ingredients)
        ) {
          let foundInIngredients = false;
          for (const ingredient of recipe.ingredients) {
            if (ingredient.name.toLowerCase().indexOf(searchValue) > -1) {
              foundInIngredients = true;
              break;
            }
          }
          if (foundInIngredients) {
            recipesFound.push({ ...recipe });
            continue;
          }
        }
      } else if (mealTypes && !searchValue && isMealType) {
        recipesFound.push({ ...recipe });
      }
    }

    setFilteredRecipes(recipesFound);
  };

  const clearFilter = () => {
    setFilteredRecipes([...recipes]);
  };

  const getRecipe = async (recipeId) => {
    const results = await getData({
      entityName: "Recipe",
      action: "get",
      id: recipeId,
    });

    if (results.isSuccessful) {
      return results.data;
    } else {
      return null;
    }
  };

  return {
    getRecipes,
    recipes,
    filterRecipes,
    filteredRecipes,
    clearFilter,
    getRecipe,
  };
};

export default useRecipe;
