import React from "react";

import { Box, Typography } from "@mui/material";

const Offering = ({ offer, onSelect, packageSelected }) => {
  const handleSelect = () => {
    onSelect(offer.productId);
  };

  return (
    <>
      <Box
        id={offer.identifier}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor:
            packageSelected === offer.productId ? "#ea751f" : "#fff",
          color: packageSelected === offer.productId ? "#fff" : "#000",
        }}
        onClick={handleSelect}
      >
        <Typography>{offer.name.en}</Typography>
        <Typography>{offer.price.en}</Typography>
      </Box>
    </>
  );
};

export default Offering;
