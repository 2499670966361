import { useState, useEffect } from "react";

import useSearch from "../../hooks/useSearch";

import { Box, Typography } from "@mui/material";

import OFDButton from "../ui/OFDButton";
import OFDContainer from "../ui/OFDContainer";

const SearchCriteria = ({ searchCriteria, onClear }) => {
  const { displaySearchCriteria } = useSearch();

  const [display, setDisplay] = useState(null);

  useEffect(() => {
    setDisplay(displaySearchCriteria(searchCriteria));
  }, [searchCriteria]);

  if (!display) return null;

  return (
    <>
      <OFDContainer label="search">
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ whiteSpace: "pre-wrap" }}>{display}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <OFDButton
            label="clear"
            onClick={onClear}
            variant="contained"
            width="auto"
            color="grey"
            shade={900}
          />
        </Box>
      </OFDContainer>
    </>
  );
};

export default SearchCriteria;
