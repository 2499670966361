import { useState } from "react";

import { Box, Typography } from "@mui/material";

import OFDCheckBox from "../../../ui/OFDCheckBox";

const RecipeInstruction = ({ instruction, fontSize }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Box sx={{ borderBottom: "1px solid #ccc" }}>
      <Box
        sx={{ display: "flex", alignItems: "flex-start" }}
        gap={1}
      >
        <OFDCheckBox
          value={checked}
          onChange={(newValue) => setChecked(newValue)}
          color="orange"
          shade={900}
          size="large"
        />

        <Typography
          sx={{
            fontSize: fontSize ? fontSize : "20px",
            color: checked ? "#ccc" : "#000",
          }}
        >
          {instruction.instruction}
        </Typography>
      </Box>
    </Box>
  );
};

export default RecipeInstruction;
