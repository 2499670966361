import { useEffect, useState } from "react";

import useWindow from "../../hooks/useWindow";

import { Box, Button, Modal, TextField, Typography } from "@mui/material";

import Logo from "../../assets/images/OFD_hrzntl_logo_Black.svg";
import Family from "../../assets/images/family/Family_4_Couch_Tablet_Orange Wall-min.jpg";
import family from "../../store/family";

const VideoThumbnail = () => {
  const { windowWidth } = useWindow();

  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");

  return (
    <>
      <Box sx={{ padding: "1rem" }}>
        <TextField
          id="title"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />

        <Button onClick={() => setOpenModal(true)}>Display</Button>
      </Box>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            width: `${windowWidth}px`,
            height: `${windowWidth}px`,
            overflow: "hidden",
          }}
          onClick={() => setOpenModal(false)}
        >
          <Box sx={{ zIndex: 500 }}>
            <img
              src={Family}
              width="100%"
              style={{
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fff",
              marginTop: "-50px",
              zindex: 1000,
            }}
          >
            <img
              src={Logo}
              width="200px"
              style={{
                backgroundColor: "#fff",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                paddingTop: ".5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            />
          </Box>

          <Box sx={{ padding: "1rem" }}>
            <Typography
              variant="h4"
              align="center"
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default VideoThumbnail;
