import { useEffect } from "react";
import { format } from "date-fns";

import { Box, Typography } from "@mui/material";

const ChildReminder = ({ reminder }) => {
  return (
    <Box
      sx={{
        padding: ".5rem",
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid #ccc",
        borderTop: "1px solid #ccc",
      }}
    >
      <Typography variant="h6">
        {format(reminder.startDate, "h:mm a")}
      </Typography>

      <Typography sx={{ fontWeight: 700 }}>{reminder.name}</Typography>

      {reminder.description ? (
        <Typography>
          <Typography
            variant="caption"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {reminder.description}
          </Typography>
        </Typography>
      ) : null}
    </Box>
  );
};

export default ChildReminder;
