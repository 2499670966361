import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useOccasion from "../../hooks/useOccasion";
import useMessage from "../../hooks/useMessage";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";

import OFDAutoComplete from "../ui/OFDAutoComplete";
import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDSelect from "../ui/OFDSelect";
import OFDNumber from "../ui/OFDNumber";
import OFDToolbar from "../layout/OFDToolbar";
import OFDMessage from "../ui/OFDMessage";

const OccasionEditor = ({ occasionId, open, onClose, onUpdate }) => {
  const { calendarLists, getLabel, getText, lists } = useLocalization();
  const { saveOccasion, validateOccasion, getOccasion } = useOccasion();
  const { destructureResults } = useMessage();

  const [occasion, setOccasion] = useState(null);
  const [occasionTypeMessage, setOccasionTypeMessage] = useState("");
  const [nameMessage, setNameMessage] = useState("");
  const [monthMessage, setMonthMessage] = useState("");
  const [dayMessage, setDayMessage] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    getData();
  }, [occasionId]);

  const getData = async () => {
    if (!occasionId) {
      setOccasion(null);
      return;
    }

    const results = await getOccasion(occasionId);

    if (results.isSuccessful) {
      setOccasion(results.data);
    } else {
      setOccasion(null);
    }
  };

  const getFieldValue = (fieldName) => {
    if (!occasion) return "";
    if (!occasion.hasOwnProperty(fieldName)) return "";
    return occasion[fieldName];
  };

  const setFieldValue = (fieldName, value) => {
    if (!occasion) {
      setOccasion({ [fieldName]: value });
      return;
    }

    setOccasion((current) => {
      let newOccasion = { ...current };

      newOccasion[fieldName] = value;

      return newOccasion;
    });
  };

  const setMessages = (validationResults) => {
    setOccasionTypeMessage("");
    setNameMessage("");
    setMonthMessage("");
    setDayMessage("");

    const occasionTypeError = validationResults.fieldErrors.find(
      (field) => field.name === "occasionType"
    );
    if (occasionTypeError) setOccasionTypeMessage(occasionTypeError.message);

    const monthError = validationResults.fieldErrors.find(
      (field) => field.name === "month"
    );
    if (monthError) setMonthMessage(monthError.message);

    const dayError = validationResults.fieldErrors.find(
      (field) => field.name === "day"
    );
    if (dayError) setDayMessage(dayError.message);
  };

  const handleMenuItemClick = async (name) => {
    if (name !== "save") {
      return;
    }

    const validationResults = validateOccasion(occasion);
    setMessages(validationResults);

    if (validationResults.isSuccessful) {
      await save();
    }
  };

  const save = async () => {
    const results = await saveOccasion(occasion);

    setMessage(destructureResults(results));

    if (results.isSuccessful) {
      onUpdate();
    }
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
      >
        <Box>
          <OFDToolbar
            menuItems={[{ name: "save", label: "save", icon: "save" }]}
            onClick={handleMenuItemClick}
          />
          <Box sx={{ padding: "1rem" }}>
            <Stack spacing={1}>
              <OFDAutoComplete
                id="occasionType"
                label="occasion"
                options={lists.occasionTypes}
                value={getFieldValue("occasionType")}
                onChange={(newValue) => setFieldValue("occasionType", newValue)}
                required
                message={occasionTypeMessage}
              />

              <OFDTextField
                id="name"
                label="name"
                value={getFieldValue("name")}
                onChange={(newValue) => setFieldValue("name", newValue)}
                required
                message={nameMessage}
              />

              <Box
                sx={{ display: "flex" }}
                gap={1}
              >
                <OFDSelect
                  id="month"
                  label="month"
                  options={calendarLists.months}
                  value={getFieldValue("month")}
                  onChange={(newValue) => setFieldValue("month", newValue)}
                  required
                  fullWidth
                  message={monthMessage}
                />

                <OFDNumber
                  id="day"
                  label="date"
                  value={getFieldValue("day")}
                  onChange={(newValue) => setFieldValue("day", newValue)}
                  required
                  message={dayMessage}
                />

                <OFDNumber
                  id="year"
                  label="year"
                  value={getFieldValue("year")}
                  onChange={(newValue) => setFieldValue("year", newValue)}
                />
              </Box>
              <OFDTextNote
                id="note"
                label="note"
                value={getFieldValue("note")}
                onChange={(newValue) => setFieldValue("note", newValue)}
              />
            </Stack>
          </Box>
        </Box>
      </OFDModal>

      <OFDMessage message={message} />
    </>
  );
};

export default OccasionEditor;
