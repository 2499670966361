import { useEffect, useState } from "react";

import useFamily from "../../hooks/useFamily";
import useApi from "../../hooks/useApi";

import { Box } from "@mui/material";

const SubscriptionPlan = () => {
  const { family } = useFamily();
  const { getData } = useApi();

  const [familyData, setFamilyData] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  useEffect(() => {
    if (!family) return;

    getFamilyData();
  }, [family]);

  const getFamilyData = async () => {
    const results = await getData({
      entityName: "Family",
      action: "get",
      id: family.id,
    });

    if (results.isSuccessful) {
      setFamilyData(results.data);
      await getSubscriptionPlan(results.data.subscriptionPlanId);
    } else {
      setFamilyData(null);
      setSubscriptionPlan(null);
    }
  };

  const getSubscriptionPlan = async (subscriptionPlanId) => {
    const results = await getData({
      entityName: "SubscriptionPlan",
      action: "get",
      id: subscriptionPlanId,
    });

    if (results.isSuccessful) {
      setSubscriptionPlan(results.data);
    } else {
      setSubscriptionPlan(null);
    }
  };

  return (
    <>
      <Box>
        <a
          href={process.env.REACT_APP_CUSTOMER_PORTAL}
          target="_blank"
          rel="noopener"
        >
          Manage Subscription
        </a>
      </Box>
    </>
  );
};

export default SubscriptionPlan;
