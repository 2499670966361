import { useEffect, useState } from "react";
import vCard from "vcard-parser";

import useData from "./useData";

const useVCard = () => {
  const { newId } = useData();

  const parseCard = (card) => {
    const cardObj = vCard.parse(card);
    const contact = convertToContact(cardObj);

    return contact;
  };

  const convertToContact = (card) => {
    const contact = {
      id: newId(),
      name: "",
      company: [],
      email: [],
      phone: [],
      address: [],
      url: [],
      selected: true,
    };

    if (card.hasOwnProperty("fn")) {
      contact.name = card.fn[0].value;
    } else if (card.hasOwnProperty("org") && card.org.length > 0) {
      contact.name = card.org[0].value;
    }

    if (card.hasOwnProperty("org")) {
      contact.company = convertProperty(card.org);
    }
    if (card.hasOwnProperty("email")) {
      contact.email = convertProperty(card.email);
    }
    if (card.hasOwnProperty("tel")) {
      contact.phone = convertProperty(card.tel);
    }
    if (card.hasOwnProperty("note")) {
      contact.note = convertProperty(card.note);
    }
    if (card.hasOwnProperty("adr")) {
      contact.address = formatAddress(convertProperty(card.adr));
    }
    return contact;
  };

  const convertProperty = (prop) => {
    let list = [];

    let entryTemplate = {
      isMain: false,
      type: [],
      value: "",
    };

    let index = 0;
    for (const org of prop) {
      let entry = { ...entryTemplate };

      if (org.hasOwnProperty("meta")) {
        if (org.meta.hasOwnProperty("type")) {
          entry.type = org.meta.type;
        }
      }

      entry.value = org.value;

      if (prop.length === 1 || index === 0) {
        entry.isMain = true;
      }

      list.push({ ...entry });

      index++;
    }

    return list;
  };

  const formatAddress = (addressList) => {
    let addresses = [];

    for (const item of addressList) {
      addresses.push({ ...item, value: valueToAddress(item.value) });
    }

    return addresses;
  };

  const valueToAddress = (value) => {
    let address = {
      streetAddress: null,
      otherAddress: null,
      city: null,
      province: null,
      postal: null,
      country: null,
    };

    address.streetAddress = value[2];
    address.otherAddress = value[0] ? value[0] : value[1] ? value[1] : null;
    address.city = value[3];
    address.province = value[4];
    address.postal = value[5];
    address.country = value[6];

    return address;
  };

  const parseCards = (list) => {
    let contacts = [];

    for (const contact of list) {
      const contactCard = parseCard(contact);
      if (contactCard.name) {
        contacts.push(contactCard);
      }
    }

    return contacts;
  };

  return {
    parseCard,
    parseCards,
  };
};

export default useVCard;
