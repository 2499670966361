import React from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

const RecurrenceLabel = ({ recurrence }) => {
  const { getListItemLabel, getLabel } = useLocalization();

  if (!recurrence) return null;

  return (
    <Box>
      {recurrence.frequency !== "schedule" ? (
        <Typography
          variant="caption"
          sx={{ textTransform: "capitalize" }}
        >
          {getListItemLabel(
            `${recurrence.frequency}Intervals`,
            recurrence.interval
          )}
        </Typography>
      ) : (
        <Typography variant="caption">{getLabel("schedule")}</Typography>
      )}
    </Box>
  );
};

export default RecurrenceLabel;
