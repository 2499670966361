import { useState } from "react";

import useSecurity from "../../hooks/useSecurity";
import useLocalization from "../../hooks/useLocalization";
import useDocument from "../../hooks/useDocument";

import { Box, Popover, Stack, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";
import OFDDialog from "../layout/OFDDialog";
import SecurityEditor from "../editors/SecurityEditor";

const DocumentDisplay = ({ document, onClick, onDelete, onChange }) => {
  const { userCanView, userCanUpdate, userCanDelete, userIsCreator } =
    useSecurity();
  const { getLabel } = useLocalization();
  const { deleteDocument, updateSecurity, renameDocument } = useDocument();

  const [openDocumentMenu, setOpenDocumentMenu] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);
  const [openSecurity, setOpenSecurity] = useState(false);
  const [openRenameDocument, setOpenRenameDocument] = useState(false);

  const menuItems = () => {
    if (!document.security) return [];
    if (!onDelete && !onChange) return [];
    if (!userCanUpdate(document.security, document.addedBy)) return [];

    let items = [];

    items.push(
      <Box
        key="rename"
        onClick={() => handleMenuItemClick("rename")}
        sx={{ display: "flex" }}
        gap={1}
      >
        <OFDIcon
          name="rename"
          color="grey"
        />
        <Typography noWrap>{getLabel("rename")}</Typography>
      </Box>
    );

    if (userCanDelete(document.security, document.addedBy)) {
      items.push(
        <Box
          key="delete"
          onClick={() => handleMenuItemClick("delete")}
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDIcon
            name="delete"
            color="grey"
          />
          <Typography noWrap>{getLabel("delete")}</Typography>
        </Box>
      );
    }

    if (userIsCreator(document.addedBy)) {
      items.push(
        <Box
          key="security"
          onClick={() => handleMenuItemClick("security")}
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDIcon
            name="security"
            color="grey"
          />
          <Typography noWrap>{getLabel("security")}</Typography>
        </Box>
      );
    }

    return items;
  };

  const handleOpenDocumentMenu = (e) => {
    setAnchor(e.target);
    setOpenDocumentMenu(true);
  };

  const handleCloseDocumentMenu = () => {
    setAnchor(null);
    setOpenDocumentMenu(false);
  };

  const handleMenuItemClick = (action) => {
    setAnchor(null);
    setOpenDocumentMenu(false);

    if (action === "delete") {
      setOpenDelete(true);
    } else if (action === "security") {
      setOpenSecurity(true);
    } else if (action === "rename") {
      setOpenRenameDocument(true);
    }
  };

  const handleConfirmDelete = async (response) => {
    setOpenDelete(false);
    if (response === "no") return;

    await deleteDocument(document);
    if (onDelete) {
      onDelete();
    } else if (onChange) {
      onChange();
    }
  };

  const handleChangeSecurity = async (newSecurity) => {
    await updateSecurity("Document", document, newSecurity);
  };

  const handleConfirmRenameDocument = async (response, formData) => {
    if (response === "cancel") {
      setOpenRenameDocument(false);
      return;
    }
    if (!formData || !formData.name) return;

    setOpenRenameDocument(false);
    await renameDocument(document, formData.name);
    onChange();
  };

  if (!userCanView(document.security, document.addedBy)) return null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#fff",
          padding: "4px",
          borderRadius: "4px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          gap={0.5}
          onClick={() => onClick(document)}
        >
          <OFDIcon
            name="document"
            color="grey"
            shade={800}
          />
          <Typography>{document.name}</Typography>
        </Box>

        {menuItems().length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            onClick={handleOpenDocumentMenu}
          >
            <OFDIcon
              name="verticalEllipse"
              color="grey"
            />
          </Box>
        ) : null}

        <Popover
          open={openDocumentMenu}
          onClose={handleCloseDocumentMenu}
          anchorEl={anchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Stack
            sx={{ minWidth: "150px", padding: "4px" }}
            spacing={1}
          >
            {menuItems(document)}
          </Stack>
        </Popover>
      </Box>

      <OFDDialog
        open={openDelete}
        title="confirmDelete"
        description={`confirmDelete_document`}
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <SecurityEditor
        open={openSecurity}
        onClose={() => setOpenSecurity(false)}
        document={document}
        onChange={handleChangeSecurity}
      />

      <OFDDialog
        open={openRenameDocument}
        title="renameDocument"
        description="renameDocument"
        actions={[
          {
            id: "rename",
            iconName: "",
            label: "rename",
          },
          {
            id: "cancel",
            iconName: "",
            label: "cancel",
          },
        ]}
        onClose={handleConfirmRenameDocument}
        fields={[
          {
            id: "name",
            fieldType: "textField",
            fieldProps: {
              id: "name",
              label: "name",
              fullWidth: true,
            },
            mode: "edit",
          },
        ]}
      />
    </>
  );
};

export default DocumentDisplay;
