import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useData from "../../hooks/useData";
import useWindow from "../../hooks/useWindow";

import { Box, Divider, Stack, Typography } from "@mui/material";

import OFDFieldGroup from "./OFDFieldGroup";
import OFDDisplayNote from "./OFDDisplayNote";

import OFDDialog from "../layout/OFDDialog";
import OFDButton from "./OFDButton";

import ItineraryEditor from "../editors/ItineraryEditor";

const fieldsTemplate = {
  id: null,
  sort: null,
  departure: null,
  arrival: null,
  accommodations: null,
  carRental: null,
};

const OFDItinerary = ({ id, label, value, onChange, entityData }) => {
  const { getLabel } = useLocalization();
  const { newId, sort } = useData();
  const { getColor } = useColor();
  const { isDesktop, windowHeight } = useWindow();

  const [fields, setFields] = useState({ ...fieldsTemplate });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(null);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [openEditor, setOpenEditor] = useState(false);

  const handleCancel = () => {
    setEditMode("add");
    setFields(null);
    setSelectedItem(null);
  };

  const handleAddNew = () => {
    setEditMode("add");
    setFields(null);
    setSelectedItem(null);
    setOpenEditor(true);
  };

  const handleSaveEditor = (item) => {
    setSelectedItem(item);
    setFields({ ...item });

    if (editMode === "add") {
      handleAddItem(item);
    } else if (editMode === "edit") {
      handleUpdateItem(item);
    }

    setEditMode(null);
    setOpenEditor(false);
  };

  const handleAddItem = (item) => {
    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...item, id: newId() }];
    } else {
      list = [{ ...item, id: newId() }];
    }

    handleChange(list);
  };

  const handleEditItem = (item) => {
    setEditMode("edit");
    setFields({ ...item });
    setSelectedItem({ ...item });
    setOpenEditor(true);
  };

  const handleUpdateItem = (item) => {
    let list = [];

    for (const segment of value) {
      if (segment.id !== item.id) {
        list.push({ ...segment });
      } else {
        list.push({ ...item });
      }
    }

    handleChange(list);
  };

  const handleDeleteItem = () => {
    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const segment of value) {
        if (segment.id !== selectedItem.id) {
          list.push({ ...segment });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const getSortedList = (list) => {
    let sortedList = [];

    let index = 0;
    for (const segment of list) {
      if (segment.departure && segment.departure.datetime) {
        sortedList.push({
          ...segment,
          sort: format(segment.departure.datetime, "yyyyMMdd"),
        });
      } else {
        sortedList.push({
          ...segment,
          sort: index.toString().padStart(3, "0"),
        });
      }
      index++;
    }

    sortedList = sort(sortedList, "sort", "asc");

    return sortedList;
  };

  const handleChange = (list) => {
    let sortedList = getSortedList(list);

    onChange(sortedList);
    setFields(null);
    setEditMode(null);
    setSelectedItem(null);
  };

  const getItemLabel = (item) => {
    let text = "";

    if (item.departure && item.departure.location) {
      text += item.departure.location;
    }
    if (item.arrival && item.arrival.location) {
      if (text.length > 0) {
        text += " -> ";
      }
      text += item.arrival.location;
    }

    return text;
  };

  const handleCloseEditor = () => {
    setOpenEditor(false);
  };

  return (
    <>
      <Box
        id={id}
        sx={{
          height: `${windowHeight - 200}px`,
          overflow: "auto",
        }}
      >
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {getLabel(label)}
          </Typography>
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="center">
            <OFDButton
              label="addDestination"
              variant="contained"
              size="small"
              onClick={handleAddNew}
            />
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box
          sx={{
            maxHeight: `${windowHeight - 550}px`,
            overflow: "auto",
            paddingBottom: "2rem",
          }}
        >
          <Stack spacing={1}>
            {Array.isArray(value)
              ? value.map((item) => (
                  <Box key={item.id}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#ccc",
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      gap={1}
                      onClick={() => handleEditItem(item)}
                    >
                      <Typography>{getItemLabel(item)}</Typography>
                    </Box>

                    {item.note ? (
                      <Box
                        sx={{
                          backgroundColor: "#ebebeb",
                          borderRadius: "4px",
                          marginTop: "4px",
                          paddingTop: ".25rem",
                          paddingBottom: ".25rem",
                          paddingLeft: ".5rem",
                          maxHeight: "100px",
                          overflow: "auto",
                          fontSize: "12px",
                          color: "#535353",
                        }}
                      >
                        <OFDDisplayNote
                          value={item.note}
                          textOnly={true}
                          styleoverride={{
                            fontSize: "12px",
                            color: "#535353",
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ))
              : null}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <ItineraryEditor
        open={openEditor}
        itineraryItem={selectedItem}
        onClose={handleCloseEditor}
        onSave={handleSaveEditor}
        name="Destination"
        entityData={entityData}
      />
    </>
  );
};

export default OFDItinerary;
