import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useApi from "../../hooks/useApi";

import { Box, Stack, Typography } from "@mui/material";

import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDButton from "../ui/OFDButton";

const SupportForm = () => {
  const { getTitle, getMessage } = useLocalization();
  const { member } = useMember();
  const { postData } = useApi();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async () => {
    const results = await postData({
      entityName: "SupportTicket",
      action: "submitTicket",
      data: {
        memberId: member.id,
        name,
        email,
        subject,
        details,
      },
    });

    if (results.isSuccessful) {
      setSubmitted(true);
      setEmail("");
      setName("");
      setSubject("");
      setDetails("");
    }
  };

  const handleSubmitAnother = () => {
    setSubmitted(false);
    setEmail("");
    setName("");
    setSubject("");
    setDetails("");
  };

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          align="center"
        >
          {getTitle("submitSupportTicket")}
        </Typography>

        {!submitted ? (
          <Box sx={{ marginTop: "1rem" }}>
            <Stack spacing={2}>
              <OFDTextField
                id="name"
                label="name"
                value={name}
                onChange={(newValue) => setName(newValue)}
                fullWidth
              />
              <OFDTextField
                id="email"
                label="email"
                value={email}
                onChange={(newValue) => setEmail(newValue)}
                fullWidth
              />
              <OFDTextField
                id="subject"
                label="subject"
                value={subject}
                onChange={(newValue) => setSubject(newValue)}
                fullWidth
              />
              <OFDTextNote
                id="details"
                label="details"
                value={details}
                onChange={(newValue) => setDetails(newValue)}
                fullWidth
              />

              <OFDButton
                id="submit"
                label="submit"
                variant="contained"
                onClick={handleSubmit}
              />
            </Stack>
          </Box>
        ) : (
          <Box sx={{ marginTop: "1rem" }}>
            <Stack spacing={2}>
              <Box>
                <Typography
                  variant="h6"
                  align="center"
                >
                  {getMessage("supportTicketSubmitted")}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <OFDButton
                  id="submitAnother"
                  label="submitAnother"
                  onClick={handleSubmitAnother}
                  variant="contained"
                />
              </Box>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SupportForm;
