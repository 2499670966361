import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

import Utils from "./Utils";
const { clearAllState } = Utils();

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: !!localStorage.getItem("token"),
  sessionExpired: false,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login(state, action) {
      const { token } = action.payload;
      localStorage.setItem("token", token);
      state.token = token;
      state.isAuthenticated = true;
    },

    logout(state) {
      localStorage.removeItem("token");
      clearAllState();
      state.token = null;
      state.isAuthenticated = false;
    },
    setToken(state, action) {
      const { token } = action.payload;
      state.token = token;
    },
  },
});

export const authenticationActions = authenticationSlice.actions;
export default authenticationSlice.reducer;
