import { useEffect, useRef, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

import { Box, Stack, Typography } from "@mui/material";

const BudgetItemExpenses = ({ budgetItem, onClick }) => {
  const { displayLocalCurrency } = useLocalization();
  const { memberColor } = useMember();

  const [containerWidth, setContainerWidth] = useState("100px");

  const container = useRef();

  const handleClick = () => {
    onClick(budgetItem);
  };

  useEffect(() => {
    if (!container || !container.current || !container.current.clientWidth)
      return;

    setContainerWidth(`${container.current.clientWidth - 8}px`);
  }, [container.current]);

  const getColor = () => {
    let color = "inherit";

    if (budgetItem.budgetType === "income") {
      if (budgetItem.expenseTotal < budgetItem.budgetTotal) {
        color = "#FF0000";
      }
    } else {
      if (budgetItem.budgetTotal < budgetItem.expenseTotal) {
        color = "#FF0000";
      }
    }

    return color;
  };

  const getBackgroundStyle = () => {
    let style = {
      ...memberColor(),
    };

    if (budgetItem.budgetType === "income") {
      if (budgetItem.expenseTotal < budgetItem.budgetTotal) {
        style.backgroundColor = "#FF0000";
        style.color = "#FFFFFF";
      }
    } else {
      if (budgetItem.budgetTotal < budgetItem.expenseTotal) {
        style.backgroundColor = "#FF0000";
        style.color = "#FFFFFF";
      }
    }

    return style;
  };

  return (
    <Box
      ref={container}
      sx={{
        backgroundColor: "#fff",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        borderRadius: "8px",
      }}
      onClick={handleClick}
    >
      <Stack sx={{ width: "100%" }}>
        <Box
          sx={{
            ...getBackgroundStyle(),
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {budgetItem.budgetItemType ? (
            <Typography
              noWrap
              textOverflow="ellipsis"
              overflow="hidden"
              sx={{ maxWidth: containerWidth }}
            >
              {budgetItem.budgetItemType}
            </Typography>
          ) : null}
          <Typography
            noWrap
            textOverflow="ellipsis"
            overflow="hidden"
            sx={{ fontSize: "12px", maxWidth: containerWidth }}
          >
            {budgetItem.budgetItemName}
          </Typography>
        </Box>

        <Box sx={{ backgroundColor: "#ddd" }}>
          <Typography align="center">
            {displayLocalCurrency(budgetItem.budgetTotal, 0)}
          </Typography>
        </Box>

        <Box
          sx={{ padding: ".5rem", display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              sx={{ fontWeight: "bold", color: getColor() }}
            >
              {displayLocalCurrency(budgetItem.expenseTotal, 0)}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default BudgetItemExpenses;
