import { useEffect, useState } from "react";

import useMember from "../../../../hooks/useMember";

import { Box } from "@mui/material";

const MemberColors = ({ members }) => {
  const { memberColor } = useMember();

  const [colors, setColors] = useState(null);

  useEffect(() => {
    if (!Array.isArray(members) || members.length === 0) return;

    getColors();
  }, [members]);

  const getColors = () => {
    let memberColors = [];

    for (const member of members) {
      memberColors.push(memberColor({ id: member }));
    }

    setColors(memberColors);
  };

  if (!Array.isArray(members) || members.length === 0) return;
  if (!colors) return;

  return (
    <Box
      sx={{ display: "flex" }}
      gap={1}
    >
      {colors.map((color, index) => (
        <Box
          key={`color-${index}`}
          sx={{
            ...color,
            height: "10px",
            width: "10px",
            borderRadius: "50%",
          }}
        ></Box>
      ))}
    </Box>
  );
};

export default MemberColors;
