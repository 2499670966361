import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useSubscription from "../../../../hooks/useSubscription";

import { Box, Button, Modal, Stack, Typography } from "@mui/material";

const EmailVerification = () => {
  const { signupId, resendEmailVerification, isEmailVerified } =
    useSubscription();

  const navigate = useNavigate();

  const [message, setMessage] = useState();

  const handleResend = async () => {
    setMessage(null);

    const results = await resendEmailVerification();

    if (results.isSuccessful) {
      if (results.data.verificationEmailResent) {
        setMessage("Email resent successfully");
      } else {
        setMessage("Error resending verification email");
      }
    } else {
      setMessage(results.message);
    }
  };

  const handleContinue = async () => {
    setMessage(null);

    const results = await isEmailVerified();

    if (results.isSuccessful) {
      if (results.data.emailVerified) {
        navigate("/signup/paywall");
      } else {
        setMessage("Email has not been verified.");
      }
    } else {
      setMessage(results.message);
    }
  };

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <Typography
            variant="h5"
            align="center"
          >
            Email Verification
          </Typography>

          <Typography>
            We have sent you an email containing a verification button. Please
            click the verify button and, upon seeing the confirmation screen
            verifying your email address, click the "Continue" button below to
            select your plan.
          </Typography>

          <Typography>
            If you do not receive the email within a few minutes, kindly check
            your junk or spam folders. Alternatively, you can use the "Resend"
            button below.
          </Typography>

          <Button onClick={handleResend}>Resend</Button>

          <Button
            variant="contained"
            onClick={handleContinue}
          >
            Continue
          </Button>

          <Typography
            sx={{ color: "#ff0000" }}
            align="center"
          >
            {message}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default EmailVerification;
