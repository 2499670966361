import { useState, useEffect } from "react";
import { startOfToday, getMonth, getYear } from "date-fns";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDSelect from "./OFDSelect";
import OFDIcon from "./OFDIcon";
import OFDFormControl from "./OFDFormControl";

const OFDYearMonth = ({
  selectedYear,
  selectedMonth,
  onChange,
  options,
  variant,
  message,
  error,
}) => {
  const { calendarLists } = useLocalization();

  const [years, setYears] = useState([]);

  useEffect(() => {
    let startYear = getYear(startOfToday()) - 3;
    let endYear = getYear(startOfToday()) + 5;

    setYears(() => {
      let list = [];

      for (let year = startYear; year <= endYear; year++) {
        list.push({ value: year, label: year });
      }

      return list;
    });
  }, [selectedYear, selectedMonth, options]);

  const handleChange = (field, newValue) => {
    let returnValue = {
      selectedYear,
      selectedMonth,
    };

    returnValue[field] = newValue;

    onChange(returnValue);
  };

  const handlePrevMonth = () => {
    let returnValue = {
      selectedYear,
      selectedMonth,
    };

    if (selectedMonth === 0) {
      returnValue.selectedYear = returnValue.selectedYear - 1;
      returnValue.selectedMonth = 11;
    } else {
      returnValue.selectedMonth = selectedMonth - 1;
    }

    onChange(returnValue);
  };

  const handleNextMonth = () => {
    let returnValue = {
      selectedYear,
      selectedMonth,
    };

    if (selectedMonth === 11) {
      returnValue.selectedYear = returnValue.selectedYear + 1;
      returnValue.selectedMonth = 0;
    } else {
      returnValue.selectedMonth = selectedMonth + 1;
    }

    onChange(returnValue);
  };

  const monthList = () => {
    let list = [];

    for (const month of calendarLists.months) {
      list.push({
        value: month.value,
        label: variant && variant === "short" ? month.short : month.label,
      });
    }

    return list;
  };

  return (
    <OFDFormControl
      message={message}
      error={error}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDSelect
            id="months"
            value={
              selectedMonth !== null ? selectedMonth : getMonth(startOfToday())
            }
            onChange={(newValue) => handleChange("selectedMonth", newValue)}
            options={monthList()}
          />
          <OFDSelect
            id="years"
            value={selectedYear ? selectedYear : getYear(startOfToday())}
            onChange={(newValue) => handleChange("selectedYear", newValue)}
            options={years}
          />
        </Box>

        <Box
          sx={{ display: "flex", alignItems: "center" }}
          gap={1}
        >
          <OFDIcon
            name="arrowLeft"
            size="medium"
            color="grey"
            shade={700}
            onClick={handlePrevMonth}
          />
          <OFDIcon
            name="arrowRight"
            size="medium"
            color="grey"
            shade={700}
            onClick={handleNextMonth}
          />
        </Box>
      </Box>
    </OFDFormControl>
  );
};

export default OFDYearMonth;
