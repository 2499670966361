import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useData from "../../hooks/useData";
import useRecipe from "../../hooks/useRecipe";
import useWindow from "../../hooks/useWindow";
import useApi from "../../hooks/useApi";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDTextNote from "./OFDTextNote";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDDisplayNote from "./OFDDisplayNote";
import OFDButton from "./OFDButton";

import OFDDialog from "../layout/OFDDialog";
import OFDRecipeSelector from "./OFDRecipeSelector";
import GroceryList from "../editors/GroceryList";

import RecipePlaceholder from "../../assets/images/RecipePlaceholder.jpeg";

const fieldsTemplate = {
  name: "",
  recipeId: null,
  note: "",
};

const OFDMealItems = ({ id, label, value, onChange, wizard }) => {
  const { getLabel } = useLocalization();
  const { getRecipe } = useRecipe();
  const { newId } = useData();
  const { isDesktop, windowHeight } = useWindow();
  const { getData } = useApi();

  const [fields, setFields] = useState({ ...fieldsTemplate });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [openRecipeSelector, setOpenRecipeSelector] = useState(false);
  const [recipe, setRecipe] = useState(null);

  const [openGrocery, setOpenGrocery] = useState(false);

  const handleCancel = () => {
    setEditMode("add");
    setFields({ ...fieldsTemplate });
    setSelectedItem(null);
  };

  const handleAddItem = () => {
    setEditMode("add");
    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields, id: newId() }];
    } else {
      list = [{ ...fields, id: newId() }];
    }

    handleChange(list);
  };

  const handleEditItem = async (editItem) => {
    if (editItem.recipeId) {
      let document = await getRecipe(editItem.recipeId);
      setRecipe(document);
    }
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
  };

  const handleUpdateItem = () => {
    let list = [];

    for (const bp of value) {
      if (bp.id !== selectedItem.id) {
        list.push({ ...bp });
      } else {
        list.push({ ...fields });
      }
    }

    handleChange(list);
  };

  const handleDeleteItem = () => {
    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const bp of value) {
        if (bp.id !== selectedItem.id) {
          list.push({ ...bp });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const handleChange = (sortedList) => {
    onChange(sortedList);
    setFields({ ...fieldsTemplate });
    setEditMode("add");
    setSelectedItem(null);
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  const handleFindRecipe = () => {
    setSelectedItem(null);
    setOpenRecipeSelector(true);
  };

  const openGroceryModel = () => {
    setOpenGrocery(true);
  };

  const handleRecipeSelection = (recipe) => {
    setOpenRecipeSelector(false);

    setRecipe(recipe);

    setFields((current) => {
      return { ...current, name: recipe.name, recipeId: recipe.id };
    });
  };

  return (
    <>
      <Box id={id}>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {label}
          </Typography>
        </Box>

        {!fields?.recipeId ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              gap={1}
            >
              <Box sx={{ width: "100%" }}>
                <OFDTextField
                  id="name"
                  label="name"
                  value={getFieldValue("name") || ""}
                  onChange={(newValue) => updateFieldValue("name", newValue)}
                  fullWidth
                />
              </Box>
            </Box>

            <Box
              sx={{ display: "flex" }}
              gap={1}
            >
              <OFDTextNote
                id="note"
                label="note"
                value={getFieldValue("note") || ""}
                onChange={(newValue) => updateFieldValue("note", newValue)}
                fullWidth
              />
            </Box>
          </Box>
        ) : null}

        {fields && fields.recipeId ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              gap={1}
            >
              <Box
                sx={{ display: "flex" }}
                gap={1}
              >
                <img
                  src={recipe.image ? recipe.image : RecipePlaceholder}
                  width="100px"
                  style={{ borderRadius: "8px" }}
                />
                <OFDTextField
                  id="name"
                  label="name"
                  value={getFieldValue("name") || ""}
                  onChange={(newValue) => updateFieldValue("name", newValue)}
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        ) : null}

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <OFDButton
              variant="outlined"
              size="small"
              onClick={handleFindRecipe}
              color="grey"
              shade={900}
              label={getLabel("recipe")}
            />

            <OFDButton
              variant="outlined"
              size="small"
              onClick={handleCancel}
              color="grey"
              shade={900}
              label={getLabel("cancel")}
            />

            <OFDButton
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
              color="grey"
              shade={900}
              label={getLabel("delete")}
            />

            <OFDButton
              variant="contained"
              size="small"
              onClick={selectedItem ? handleUpdateItem : handleAddItem}
              color="grey"
              shade={900}
              label={getLabel(selectedItem ? "update" : "add")}
            />
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box
          sx={{
            maxHeight: `${wizard ? windowHeight - 450 : windowHeight - 500}px`,
            overflow: "auto",
          }}
        >
          <Stack spacing={1}>
            {Array.isArray(value)
              ? value.map((item) => (
                  <Box key={item.id}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#ccc",
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      gap={1}
                      onClick={() => handleEditItem(item)}
                    >
                      <Typography>{item.name}</Typography>
                    </Box>

                    {item.note ? (
                      <Box
                        sx={{
                          backgroundColor: "#ebebeb",
                          borderRadius: "4px",
                          marginTop: "4px",
                          paddingTop: ".25rem",
                          paddingBottom: ".25rem",
                          paddingLeft: ".5rem",
                          maxHeight: "100px",
                          overflow: "auto",
                          fontSize: "12px",
                          color: "#535353",
                        }}
                      >
                        <OFDDisplayNote
                          value={item.note}
                          textOnly={true}
                          styleoverride={{
                            fontSize: "12px",
                            color: "#535353",
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ))
              : null}
          </Stack>

          <Box
            sx={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <OFDButton
              id="addToGrocery"
              label="addToGrocery"
              icon="grocery"
              variant="contained"
              onClick={openGroceryModel}
            />
          </Box>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <OFDRecipeSelector
        open={openRecipeSelector}
        onClose={() => setOpenRecipeSelector(false)}
        onSelect={handleRecipeSelection}
      />

      {openGrocery ? (
        <GroceryList
          open={openGrocery}
          onClose={() => setOpenGrocery(false)}
          meal={value}
        />
      ) : null}
    </>
  );
};

export default OFDMealItems;
