import { useEffect, useState } from "react";

import useLocalization from "../../../hooks/useLocalization";
import useWindow from "../../../hooks/useWindow";
import useSecurity from "../../../hooks/useSecurity";

import { Box, Button } from "@mui/material";

import OFDModal from "../../layout/OFDModal";
import GroceryIntro from "./GroceryIntro";
import GroceryItems from "./GroceryItems";
import GroceryName from "./GroceryName";
import GrocerySummary from "./GrocerySummary";
import GroceryCopyList from "./GroceryCopyList";
import GroceryBudgetItem from "./GroceryBudgetItem";
import GroceryShoppingDate from "./GroceryShoppingDate";
import GrocerySecurity from "./GrocerySecurity";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

import OFDButton from "../../ui/OFDButton";

const steps = [
  { step: 0, label: "intro" },
  { step: 1, label: "name" },
  { step: 2, label: "copyGroceryItems" },
  { step: 3, label: "groceryItems" },
  { step: 4, label: "groceryBudgetItem" },
  { step: 5, label: "shoppingDate" },
  { step: 6, label: "grocerySecurity" },
];

const GroceryWizard = ({
  grocery,
  open,
  onClose,
  onCreateGrocery,
  onUpdateGrocery,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { isMobile, windowHeight } = useWindow();
  const { pageDefaultSecurity } = useSecurity();

  const [currentStep, setCurrentStep] = useState(0);
  const [firstStep, setFirstStep] = useState(0);

  const [name, setName] = useState("");
  const [category, setCategory] = useState(null);
  const [groceryItems, setGroceryItems] = useState(null);
  const [copyList, setCopyList] = useState(null);
  const [budgetItem, setBudgetItem] = useState(null);
  const [groceryList, setGroceryList] = useState(null);
  const [shoppingDate, setShoppingDate] = useState(null);
  const [security, setSecurity] = useState(null);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (open) {
      if (grocery) {
        setCurrentStep(1);
        setFirstStep(1);
      } else {
        setName("");
        setCategory("");
        setGroceryItems(null);
        setBudgetItem(null);
        setShoppingDate(null);
        setCurrentStep(0);
        setFirstStep(0);
        setCopyList(null);
        setSecurity(pageDefaultSecurity("Grocery"));
      }
    }
  }, [open]);

  useEffect(() => {
    if (grocery) {
      setName(grocery.name);
      setCategory(grocery.category);
      setGroceryItems(grocery.groceryItems);
      setBudgetItem(grocery.budgetItem);
      setShoppingDate(grocery.shoppingDate || null);
      setSecurity(grocery.security || pageDefaultSecurity("Grocery"));
      setCurrentStep(1);
      setFirstStep(1);
    }
  }, [grocery]);

  const handleBack = () => {
    setCurrentStep((current) => {
      if (current === firstStep) return current;

      return current - 1;
    });
  };

  const handleNext = () => {
    if (!stepValidated()) return;

    if (currentStep === steps.length - 1) {
      handleSave();
    } else {
      setCurrentStep((current) => {
        if (current >= steps.length - 1) return current;

        return current + 1;
      });
    }
  };

  const stepValidated = () => {
    setError(false);
    setMessage(null);

    if (currentStep === 0) return true;

    if (currentStep === 1) {
      if (!name) {
        setError(true);
        setMessage(getMessage("isEmpty"));
        return false;
      }
    } else if (currentStep === 2) {
      if (groceryList !== null) {
        setGroceryItems(groceryList.groceryItems);
      }
    } else if (currentStep === 3) {
      if (!Array.isArray(groceryItems) || groceryItems.length === 0) {
        setError(true);
        setMessage(getMessage("isEmpty"));
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    let newGrocery;

    if (!grocery) {
      newGrocery = {
        name,
        category,
        groceryItems,
        budgetItem,
        shoppingDate,
        security,
      };

      onCreateGrocery(newGrocery);
    } else {
      newGrocery = {
        ...grocery,
        name,
        category,
        groceryItems,
        budgetItem,
        shoppingDate,
        security,
      };
      onUpdateGrocery(newGrocery);
    }
  };

  const handleCopyList = (groceryId, selectedGrocery) => {
    setCopyList(groceryId);
    setGroceryList(selectedGrocery);
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="groceryWizard"
        color="grey"
        shade="900"
      >
        <Box sx={{ height: `${windowHeight - 64}px` }}>
          <Box
            sx={{
              padding: "1rem",
              height: "calc(100% - 3rem)",
              overflow: "auto",
            }}
          >
            {currentStep === 0 && !grocery ? <GroceryIntro /> : null}

            {currentStep === 1 ? (
              <GroceryName
                name={name}
                onChange={(newValue) => setName(newValue)}
                error={error}
                message={message}
              />
            ) : null}

            {currentStep === 2 ? (
              <GroceryCopyList
                value={copyList}
                onChange={handleCopyList}
                error={error}
                message={message}
              />
            ) : null}

            {currentStep === 3 ? (
              <GroceryItems
                open={true}
                onChange={(newValue) => setGroceryItems(newValue)}
                groceryItems={groceryItems}
                error={error}
                message={message}
              />
            ) : null}

            {currentStep === 4 ? (
              <GroceryBudgetItem
                name={budgetItem}
                onChange={(newValue) => setBudgetItem(newValue)}
                error={error}
                message={message}
              />
            ) : null}

            {currentStep === 5 ? (
              <GroceryShoppingDate
                shoppingDate={shoppingDate}
                onChange={(newValue) => setShoppingDate(newValue)}
                error={error}
                message={message}
              />
            ) : null}

            {currentStep === 6 ? (
              <GrocerySecurity
                open={true}
                entityName="Grocery"
                security={security}
                onChange={(newValue) => setSecurity(newValue)}
              />
            ) : null}
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              left: "1rem",
              right: "1rem",
              height: "4rem",
              borderTop: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "1rem",
              paddingTop: ".5rem",
            }}
            gap={2}
          >
            <OFDButton
              id="back"
              variant="outlined"
              disabled={currentStep === firstStep}
              onClick={handleBack}
              label={getLabel("back")}
              width="auto"
              color="grey"
              shade={900}
            />

            <OFDButton
              id="next"
              variant="contained"
              onClick={currentStep < steps.length - 1 ? handleNext : handleSave}
              color="grey"
              shade={900}
              label={getLabel(
                currentStep < steps.length - 1
                  ? "next"
                  : grocery
                  ? "updateGrocery"
                  : "createGrocery"
              )}
              width="auto"
            />
          </Box>
        </Box>
      </OFDModal>
    </>
  );
};

export default GroceryWizard;
