import { createSlice } from "@reduxjs/toolkit";

import Utils from "./Utils";
import defaultMemberPreferences from "./defaultMemberPreferences";

const { memberState, setState, getState, clearAllState } = Utils();

const initialState = {
  ...memberState(),
  preferences: {
    ...defaultMemberPreferences,
  },
};

const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setMemberState(state, action) {
      const { data } = action.payload;

      if (!data) {
        state = initialState;
        clearAllState();
        return;
      }

      state.id = data.id;
      state.name = data.name;
      state.avatar = data.avatar;
      state.color = data.color;
      state.preferences = data.preferences;
      state.securityLevel = data.securityLevel;
      state.isFirstLogin = data.isFirstLogin;

      setState({ name: "member", data });
    },

    refreshMemberState(state, action) {
      const { data } = action.payload;

      state.id = data.id;
      state.name = data.name;
      state.avatar = data.avatar;
      state.color = data.color;
      state.preferences = data.preferences;
      state.securityLevel = data.securityLevel;
      state.isFirstLogin = data.isFirstLogin;
    },

    async updatePreferences(state, action) {
      const { preferences } = action.payload;

      state.preferences = preferences;
    },
  },
});

export const memberActions = memberSlice.actions;
export default memberSlice.reducer;
