import { useEffect, useState } from "react";
import {
  getMonth,
  getYear,
  startOfToday,
  endOfMonth,
  startOfWeek,
  startOfDay,
  endOfWeek,
  format,
  addDays,
  isEqual,
  parseISO,
} from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

import { Box, Stack, Typography } from "@mui/material";

import OFDYearMonth from "../ui/OFDYearMonth";
import OFDIcon from "../ui/OFDIcon";
import OFDFormControl from "./OFDFormControl";

const OFDMultiDatePicker = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  disabled,
}) => {
  const { calendarLists } = useLocalization();
  const { memberColor, getMemberColor } = useMember();

  const [calendar, setCalendar] = useState(null);
  const [selectedYear, setSelectedYear] = useState(getYear(startOfToday()));
  const [selectedMonth, setSelectedMonth] = useState(getMonth(startOfToday()));
  const [selectedDates, setSelectedDates] = useState(null);

  const dayWidth = 45;

  useEffect(() => {
    if (value) {
      convertStringToDate();
    } else {
      setSelectedDates([]);
    }
  }, [value]);

  const convertStringToDate = () => {
    setSelectedDates(() => {
      let dates = [];

      for (const date of value) {
        if (typeof date === "string") {
          dates.push(parseISO(date));
        } else {
          dates.push(date);
        }
      }

      return dates;
    });
  };

  useEffect(() => {
    buildCalendar();
  }, [selectedDates, selectedYear, selectedMonth]);

  const buildCalendar = () => {
    setCalendar(null);

    const monthStart = new Date(selectedYear, selectedMonth, 1);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const calendarWeeks = [];
    let daysArray = [];
    //

    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate = addDays(currentDate, 1)
    ) {
      let weekStart = startOfWeek(currentDate);
      let weekEnd = endOfWeek(currentDate);

      daysArray.push(buildDay(currentDate));

      if (format(currentDate, "yyyy-MM-dd") === format(weekEnd, "yyyy-MM-dd")) {
        calendarWeeks.push(
          <Box
            key={`weekOF_${format(weekStart, "yyyy-MM-dd")}`}
            sx={{
              display: "flex",
              alignItems: "center",
              ...memberColor({ color: "grey", shade: 50 }),
            }}
          >
            {daysArray}
          </Box>
        );
        daysArray = [];
      }
    }

    const calendarBody = <Stack key="calendarBody">{calendarWeeks}</Stack>;

    const calendarHeader = buildCalendarHeader();
    setCalendar(
      <Box>
        {calendarHeader}
        {calendarBody}
      </Box>
    );
  };

  const buildCalendarHeader = () => {
    const calendarHdrColumns = [];

    for (const dow of calendarLists.weekdays) {
      calendarHdrColumns.push(
        <Box
          key={`hdrCol_${dow.short}`}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: `${dayWidth}px`,
            paddingTop: ".5rem",
            paddingBottom: ".5rem",
          }}
        >
          {dow.letter}
        </Box>
      );
    }

    const calendarHeader = (
      <Box
        key="calendarHeader"
        sx={{
          display: "flex",
          ...memberColor({ color: "grey", shade: 50 }),
        }}
      >
        {calendarHdrColumns}
      </Box>
    );

    return calendarHeader;
  };

  const buildDay = (date) => {
    let dayElement = null;
    const key = format(date, "yyyy-MM-dd");

    const dayStart = startOfDay(date);

    let dayStyle = {
      backgroundColor: "#fff",
      color: "#ddd",
      border: "1px solid #eee",
    };

    if (daySelected(date)) {
      dayStyle = { ...dayStyle, ...memberColor({ shade: 200 }) };
    }

    dayElement = (
      <Box
        key={key}
        sx={{
          width: `${dayWidth}px`,
          aspectRatio: "1/1",
          ...dayStyle,
          color:
            getMonth(dayStart) !== selectedMonth
              ? "#ddd"
              : disabled
              ? "#ccc"
              : "inherit",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: `${dayWidth}px`,
            aspectRatio: "1/1",
          }}
          onClick={() => handleDaySelect(dayStart)}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography>{format(date, "dd")}</Typography>
          </Box>
        </Box>
      </Box>
    );

    return dayElement;
  };

  const handleDaySelect = (date) => {
    if (disabled) return;

    let newDates = Array.isArray(selectedDates) ? [...selectedDates] : [];

    let dateIndex = newDates.findIndex((existingDate) =>
      isEqual(existingDate, date)
    );

    if (dateIndex > -1) {
      newDates.splice(dateIndex, 1);
    } else {
      newDates.push(date);
    }

    onChange(newDates);
  };

  const daySelected = (date) => {
    if (!Array.isArray(selectedDates)) return false;

    let dateIndex = selectedDates.findIndex((existingDate) =>
      isEqual(existingDate, date)
    );

    return dateIndex === -1 ? false : true;
  };

  const handleYearMonthChange = (newValue) => {
    setSelectedYear(newValue.selectedYear);
    setSelectedMonth(newValue.selectedMonth);
  };

  const handleGotoToday = () => {
    setSelectedYear(getYear(startOfToday()));
    setSelectedMonth(getMonth(startOfToday()));
  };

  return (
    <>
      <OFDFormControl
        label={label}
        message={message}
        error={error}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              ...memberColor({ color: "grey", shade: 50 }),
              borderRadius: "8px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "2px",
            }}
          >
            <Box
              sx={{
                marginBottom: ".5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              gap={2}
            >
              <Box sx={{ marginTop: "-12px" }}>
                <OFDIcon
                  name="today"
                  size="medium"
                  color="grey"
                  shade={700}
                  onClick={handleGotoToday}
                />
              </Box>
              <OFDYearMonth
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                onChange={handleYearMonthChange}
                variant="short"
              />
            </Box>

            <Box>{calendar}</Box>
          </Box>
        </Box>
      </OFDFormControl>
    </>
  );
};

export default OFDMultiDatePicker;
