import { useState } from "react";
import { isEqual } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useBloodPressure from "../../hooks/useBloodPressure";
import useColor from "../../hooks/useColor";
import useData from "../../hooks/useData";
import useSecurity from "../../hooks/useSecurity";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDNumber from "./OFDNumber";
import OFDDatetime from "./OFDDatetime";
import OFDTextNote from "./OFDTextNote";
import OFDFieldGroup from "./OFDFieldGroup";

import OFDDisplayNote from "./OFDDisplayNote";

import OFDDialog from "../layout/OFDDialog";
import OFDCollapsable from "../layout/OFDCollapsable";
import OFDDisplayBPItem from "./OFDDisplayBPItem";

const fieldsTemplate = {
  datetime: null,
  systolic: null,
  diastolic: null,
  heartRate: null,
  note: "",
};

const OFDBloodPressure = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { bpColor } = useBloodPressure();
  const { getColor } = useColor();
  const { sort, newId } = useData();
  const { userCanView, userCanUpdate } = useSecurity();

  const [fields, setFields] = useState({ ...fieldsTemplate });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [fieldMessages, setFieldMessages] = useState({});

  const handleCancel = () => {
    setEditMode("add");
    setFields({ ...fieldsTemplate });
    setSelectedItem(null);
    setFieldMessages({});
  };

  const validFields = () => {
    let isValid = true;
    let messages = {};

    if (!fields || !fields.datetime) {
      messages.datetime = { error: true, message: "isEmpty" };

      isValid = false;
    }

    if (editMode === "add") {
      if (Array.isArray(value)) {
        let index = value.findIndex((item) =>
          isEqual(item.datetime, fields.datetime)
        );
        if (index > -1) {
          isValid = false;
          messages.datetime = { error: true, message: "alreadyExists" };
        }
      }
    }

    if (!fields || !fields.systolic) {
      messages.systolic = { error: true, message: "isEmpty" };

      isValid = false;
    }

    if (!fields || !fields.diastolic) {
      messages.diastolic = { error: true, message: "isEmpty" };

      isValid = false;
    }

    setFieldMessages(messages);
    return isValid;
  };

  const handleAddItem = () => {
    if (!validFields()) return;

    setEditMode("add");
    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields, id: newId() }];
    } else {
      list = [{ ...fields, id: newId() }];
    }

    const sortedList = sort(list, "datetime", "desc");

    handleChange(sortedList);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
  };

  const handleUpdateItem = () => {
    if (!validFields()) return;

    let list = [];

    for (const bp of value) {
      if (bp.id !== selectedItem.id) {
        list.push({ ...bp });
      } else {
        list.push({ ...fields });
      }
    }

    const sortedList = sort(list, "datetime", "desc");

    handleChange(sortedList);
  };

  const handleDeleteItem = () => {
    if (!selectedItem) return;

    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const bp of value) {
        if (bp.id !== selectedItem.id) {
          list.push({ ...bp });
          continue;
        }
      }

      const sortedList = sort(list, "severity", "desc");

      handleChange(sortedList);
    }
  };

  const handleChange = (sortedList) => {
    onChange(sortedList);
    setFields({ ...fieldsTemplate });
    setEditMode("add");
    setSelectedItem(null);
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  const getFieldMessage = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return null;

    return fieldMessages[field].message;
  };

  const getFieldError = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return false;

    return fieldMessages[field].error;
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box id={id}>
        <Box>
          <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Typography
              variant="h5"
              align="center"
            >
              {label}
            </Typography>
          </Box>

          <Box sx={{ width: "100%" }}>
            <OFDDatetime
              id="datetime"
              label="datetime"
              value={getFieldValue("datetime")}
              onChange={(newValue) => updateFieldValue("datetime", newValue)}
              size="small"
              disabled={isDisabled()}
              required
              error={getFieldError("datetime")}
              message={getFieldMessage("datetime")}
            />
          </Box>

          <Box
            sx={{ display: "flex", marginTop: "1rem", marginBottom: "1rem" }}
            gap={1}
          >
            <OFDNumber
              id="systolic"
              label="systolic"
              value={getFieldValue("systolic") || ""}
              onChange={(newValue) => updateFieldValue("systolic", newValue)}
              disabled={isDisabled()}
              fullWidth
              required
              error={getFieldError("systolic")}
              message={getFieldMessage("systolic")}
            />
            <OFDNumber
              id="diastolic"
              label="diastolic"
              value={getFieldValue("diastolic") || ""}
              onChange={(newValue) => updateFieldValue("diastolic", newValue)}
              disabled={isDisabled()}
              fullWidth
              required
              error={getFieldError("diastolic")}
              message={getFieldMessage("diastolic")}
            />
            <OFDNumber
              id="heartRate"
              label="heartRate"
              value={getFieldValue("heartRate") || ""}
              onChange={(newValue) => updateFieldValue("heartRate", newValue)}
              disabled={isDisabled()}
              fullWidth
            />
          </Box>

          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <OFDTextNote
              id="note"
              label="note"
              value={getFieldValue("note") || ""}
              onChange={(newValue) => updateFieldValue("note", newValue)}
              disabled={isDisabled()}
              fullWidth
            />
          </Box>

          <Box sx={{ marginTop: "1rem" }}>
            <OFDFieldGroup justifyContent="flex-end">
              <Button
                variant="outlined"
                size="small"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleDeleteItem}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={selectedItem ? handleUpdateItem : handleAddItem}
              >
                {selectedItem ? "Update" : "Add"}
              </Button>
            </OFDFieldGroup>
          </Box>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box
          sx={{
            height: "auto",
            maxHeight: "300px",
            overflow: "auto",
            marginTop: ".5rem",
          }}
        >
          <Stack spacing={1}>
            {value?.map((bp) => (
              <OFDCollapsable
                key={bp.id}
                compressed
                header={
                  <OFDDisplayBPItem
                    onClick={() => handleEditItem(bp)}
                    bloodPressure={bp}
                  />
                }
                styleOverride={{ ...getColor(bpColor(bp)) }}
                arrowColor="grey"
                arrowShade={50}
              >
                {bp.note ? (
                  <OFDDisplayNote
                    value={bp.note}
                    textOnly={true}
                    styleoverride={{
                      fontSize: "12px",
                      color: "#535353",
                    }}
                  />
                ) : null}
              </OFDCollapsable>
            ))}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default OFDBloodPressure;
