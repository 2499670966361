import React from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import MemberChores from "./MemberChores";
import ChoreMatrix from "./ChoreMatrix";

const FamilyChores = ({ familyChores, onChange }) => {
  const { getMessage } = useLocalization();

  return (
    <Box sx={{ marginTop: "1rem" }}>
      {familyChores ? (
        <ChoreMatrix
          familyChores={familyChores}
          onChange={onChange}
        />
      ) : (
        <Typography
          sx={{ marginTop: "1rem" }}
          align="center"
        >
          {getMessage(`noFamilyChores`)}
        </Typography>
      )}
    </Box>
  );
};

export default FamilyChores;
