import React, { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useWindow from "../../hooks/useWindow";

import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import CalendarItem from "./CalendarItem";
import OFDListAndDetails from "../layout/OFDListAndDetails";
import OFDEntityList from "../layout/OFDEntityList";
import EntityDisplay from "../display/EntityDisplay";

const CalendarItems = ({
  calendarType,
  calendarItems,
  onSelect,
  onChange,
  orientation,
}) => {
  const { getMessage } = useLocalization();
  const { isDesktop } = useWindow();

  const [selectedEntity, setSelectedEntity] = useState(null);

  const handleOpenRight = (entityName, id, entity) => {
    setSelectedEntity(entity);
  };

  const handleEditEntity = () => {};

  const handleSelectedFolder = () => {};

  const handleOpenFolderEditor = () => {};

  const handleMenuClick = () => {};

  return (
    <Box>
      {Array.isArray(calendarItems) && calendarItems.length > 0 ? (
        isDesktop && orientation !== "vertical" ? (
          <Box>
            <OFDListAndDetails
              list={
                <Box>
                  <OFDEntityList
                    entityName="Calendar"
                    entityList={calendarItems}
                    onEdit={handleEditEntity}
                    options={{
                      showAvatar: false,
                    }}
                    headerFields={[
                      { name: "name", type: "string" },
                      { name: "startDate", type: "date" },
                    ]}
                    onMenuItemClick={handleMenuClick}
                    onOpenRight={handleOpenRight}
                  />
                </Box>
              }
              details={
                selectedEntity ? (
                  <CalendarItem
                    key={selectedEntity.id}
                    calendar={selectedEntity}
                    onClick={() => onSelect(selectedEntity)}
                    onChange={onChange}
                  />
                ) : null
              }
            />
          </Box>
        ) : (
          <Stack
            spacing={2}
            sx={{ marginTop: "1rem" }}
          >
            {calendarItems.map((calendar) => (
              <CalendarItem
                key={calendar.id}
                calendar={calendar}
                onClick={() => onSelect(calendar)}
                onChange={onChange}
              />
            ))}
          </Stack>
        )
      ) : (
        <Typography
          sx={{ marginTop: "1rem" }}
          align="center"
        >
          {getMessage(`no_${calendarType}_ForToday`)}
        </Typography>
      )}
    </Box>
  );
};

export default CalendarItems;
