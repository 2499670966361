import { useEffect, useState } from "react";
import {
  startOfToday,
  startOfWeek,
  endOfWeek,
  isEqual,
  getDay,
  addDays,
  getDate,
  format,
} from "date-fns";

import useCalendar from "../../hooks/useCalendar";
import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDWeekOf from "../ui/OFDWeekOf";
import Loading from "../layout/Loading";
import OFDCollapsable from "../layout/OFDCollapsable";
import AppointmentDisplay from "./AppointmentDisplay";
import OFDDialog from "../layout/OFDDialog";
import ReminderItem from "./ReminderItem";
import ToDoItem from "./ToDoItem";

const WeeklyCalendar = ({
  refresh,
  onEditAppointment,
  onEditReminder,
  onEditToDo,
}) => {
  const { getMessage, calendarLists } = useLocalization();
  const {
    getCalendarData,
    calendarData,
    dateRangeFilter,
    getUniqueItems,
    deleteCalendar,
  } = useCalendar();

  const [weekOf, setWeekOf] = useState();
  const [calendarItems, setCalendarItems] = useState(undefined);
  const [daily, setDaily] = useState(undefined);
  const [dailyAppointments, setDailyAppointments] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [dailyReminders, setDailyReminders] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [dailyToDos, setDailyToDos] = useState([[], [], [], [], [], [], []]);

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setWeekOf(startOfWeek(startOfToday()));
  }, []);

  useEffect(() => {
    if (!weekOf) return;

    getData();
  }, [weekOf, refresh]);

  const getData = async () => {
    let filter;
    const startOfThisWeek = startOfWeek(startOfToday());

    filter = { ...dateRangeFilter(weekOf, endOfWeek(weekOf)) };

    await getCalendarData({ filter });
  };

  useEffect(() => {
    if (!calendarData) return;

    setCalendarItems(getUniqueItems(calendarData));
  }, [calendarData]);

  useEffect(() => {
    if (!calendarItems) return;

    buildDaily();
  }, [calendarItems]);

  const buildDaily = () => {
    setDaily(() => {
      let items = [[], [], [], [], [], [], []];
      for (const item of calendarItems) {
        items[getDay(item.startDate)].push(item);
      }

      return items;
    });

    setDailyAppointments(() => {
      let items = [[], [], [], [], [], [], []];
      for (const item of calendarItems) {
        if (item.calendarType === "appointment") {
          items[getDay(item.startDate)].push(item);
        }
      }

      return items;
    });

    setDailyReminders(() => {
      let items = [[], [], [], [], [], [], []];
      for (const item of calendarItems) {
        if (item.calendarType === "reminder") {
          items[getDay(item.startDate)].push(item);
        }
      }

      return items;
    });

    setDailyToDos(() => {
      let items = [[], [], [], [], [], [], []];
      for (const item of calendarItems) {
        if (item.calendarType === "todo") {
          items[getDay(item.startDate)].push(item);
        }
      }

      return items;
    });
  };

  const handleDelete = async (item, option) => {
    setSelectedOption(option);
    setSelectedItem(item);
    setOpenDelete(true);
  };

  const handleConfirmDelete = async (response) => {
    if (response === "yes") {
      await deleteCalendar(selectedItem, selectedOption);
      await getData();
    }
    setSelectedItem(null);
    setSelectedOption(null);
    setOpenDelete(false);
  };

  if (!weekOf || calendarItems === undefined || daily === undefined)
    return <Loading />;

  return (
    <>
      <Box>
        <OFDWeekOf
          id="weekOf"
          label="weekOf"
          value={weekOf}
          onChange={(newValue) => setWeekOf(newValue)}
        />

        <Box sx={{ padding: "1rem" }}>
          {calendarItems && calendarItems.length > 0 ? (
            <Stack spacing={1}>
              {daily?.map((day, index) => {
                if (day.length === 0) return;

                return (
                  <OFDCollapsable
                    key={`weekday-${index}`}
                    header={
                      <Typography variant="h6">
                        {`${format(addDays(weekOf, index), "EEEE, MMM dd")} (${
                          day.length
                        })`}
                      </Typography>
                    }
                  >
                    <Box sx={{ padding: ".5rem" }}>
                      {dailyReminders[index].length > 0
                        ? dailyReminders[index].map((dailyRemindersItem) => (
                            <Box
                              key={dailyRemindersItem.id}
                              sx={{
                                marginBottom: "4px",
                              }}
                            >
                              <ReminderItem
                                calendar={dailyRemindersItem}
                                onEdit={(option) =>
                                  onEditReminder(dailyRemindersItem, option)
                                }
                                onDelete={(option) =>
                                  handleDelete(dailyRemindersItem, option)
                                }
                              />
                            </Box>
                          ))
                        : null}

                      {dailyToDos[index].length > 0
                        ? dailyToDos[index].map((dailyToDosItem) => (
                            <Box
                              key={dailyToDosItem.id}
                              sx={{
                                marginBottom: "4px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <ToDoItem
                                calendar={dailyToDosItem}
                                onEdit={(option) =>
                                  onEditToDo(dailyToDosItem, option)
                                }
                                onDelete={(option) =>
                                  handleDelete(dailyToDosItem, option)
                                }
                              />
                            </Box>
                          ))
                        : null}

                      {dailyAppointments[index].length > 0
                        ? dailyAppointments[index].map(
                            (dailyAppointmentsItem) => (
                              <Box
                                key={dailyAppointmentsItem.id}
                                sx={{ marginBottom: "4px" }}
                              >
                                <AppointmentDisplay
                                  calendar={dailyAppointmentsItem}
                                  onEdit={(option) =>
                                    onEditAppointment(
                                      dailyAppointmentsItem,
                                      option
                                    )
                                  }
                                  onDelete={(option) =>
                                    handleDelete(dailyAppointmentsItem, option)
                                  }
                                />
                              </Box>
                            )
                          )
                        : null}
                    </Box>
                  </OFDCollapsable>
                );
              })}
            </Stack>
          ) : (
            <Typography>{getMessage("noAppointmentsThisWeek")}</Typography>
          )}
        </Box>
      </Box>

      <OFDDialog
        open={openDelete}
        title="confirmDelete"
        description={`confirmDelete_${selectedItem?.calendarType}_${
          selectedOption ? selectedOption : "occurrence"
        }`}
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default WeeklyCalendar;
