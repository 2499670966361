import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useApi from "../../hooks/useApi";
import useFamily from "../../hooks/useFamily";
import useMember from "../../hooks/useMember";
import useSecurity from "../../hooks/useSecurity";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";
import OFDTextNote from "./OFDTextNote";
import OFDSelect from "./OFDSelect";

import OFDDialog from "../layout/OFDDialog";
import OFDDisplayMembers from "./OFDDisplayMembers";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDAvatar from "./OFDAvatar";

const OFDMembers = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getMemberAvatar, getMember } = useMember();
  const { getLabel } = useLocalization();
  const { familyMembers } = useFamily();
  const { getColor } = useColor();
  const { getData } = useApi();
  const { userCanView, userCanUpdate } = useSecurity();

  const [member, setMember] = useState();
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleCancel = () => {
    setEditMode("add");
    setMember("");
    setMember(null);
  };

  const handleAddItem = () => {
    setEditMode("add");
    if (!member) return;

    let list = [];
    if (Array.isArray(value)) {
      list = [...value, member];
    } else {
      list = [member];
    }

    onChange(list);
    setMember(null);
  };

  const handleDeleteItem = () => {
    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const entry of value) {
        if (entry !== member) {
          list.push(entry);
          continue;
        }
      }

      onChange(list);

      setMember(null);
    }
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box
        id={id}
        sx={{ padding: "1rem" }}
      >
        <Box>
          <Typography
            variant="h5"
            align="center"
          >
            {getLabel(label)}
          </Typography>
        </Box>

        <Stack
          sx={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          gap={1}
        >
          <OFDSelect
            id="members"
            label={label}
            value={member || ""}
            onChange={(newValue) => setMember(newValue)}
            variant="outlined"
            options={familyMembers?.map((member) => {
              return { value: member.id, label: member.name };
            })}
            fullWidth
            disabled={isDisabled()}
          />
        </Stack>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancel}
            >
              {getLabel("cancel")}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
            >
              {getLabel("delete")}
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleAddItem}
            >
              {getLabel("add")}
            </Button>
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem", marginBottom: ".5rem" }} />

        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          <Stack spacing={1}>
            {value?.map((member) => (
              <Box
                key={member}
                sx={{ display: "flex" }}
                gap={1}
                onClick={() => setMember(member)}
              >
                <OFDAvatar {...getMemberAvatar(member)} />
                <Typography>{getMember(member).name}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default OFDMembers;
