import React, { useState, useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";

// MATERIAL UI
import { Snackbar, Alert, Slide } from "@mui/material";

function TransitionDown(props) {
  return (
    <Slide
      {...props}
      direction="down"
    />
  );
}

const OFDMessage = ({ message, onClose, timeout = 3000 }) => {
  const { getMessage } = useLocalization();

  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (!message || !message.message || !message.displayToUser) {
      setShowToast(false);

      return;
    }

    setShowToast(true);

    const clear = setTimeout(() => {
      clearToastMessage();
      if (onClose) onClose();
    }, timeout);

    return () => {
      clearTimeout(clear);
    };
  }, [message]);

  const clearToastMessage = async () => {
    setShowToast(false);
  };

  if (!message || !message.message) {
    return null;
  }

  return (
    <Snackbar
      open={showToast}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={TransitionDown}
      transitionDuration={1000}
      sx={{ zIndex: 9000 }}
    >
      <Alert severity={message.severity}>{getMessage(message.message)}</Alert>
    </Snackbar>
  );
};

export default OFDMessage;
