import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDHorizontalScroll = ({ children, resetScrollOnChange }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [allowScrollLeft, setAllowScrollLeft] = useState(false);
  const [allowScrollRight, setAllowScrollRight] = useState(true);
  const [showArrows, setShowArrows] = useState(false);

  const content = useRef();
  const container = useRef();
  const scrollDuration = 1000;

  useEffect(() => {
    determineScroll();

    if (resetScrollOnChange) {
      let element = content.current;
      element.scrollLeft = 0;
    }
  }, [children]);

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scroll = (change) => {
    let element = content.current;

    let scrollLength = element.clientWidth * change;
    let start = parseInt(element.scrollLeft);
    let currentTime = 0;
    let increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      var val = Math.easeInOutQuad(
        currentTime,
        start,
        scrollLength,
        scrollDuration
      );
      element.scrollLeft = val;
      if (currentTime < scrollDuration) {
        setTimeout(animateScroll, increment);
      }
      setScrollPosition(parseInt(element.scrollLeft));
    };

    animateScroll();
  };

  useEffect(() => {
    determineScroll();
  }, [scrollPosition]);

  const determineScroll = () => {
    let element = content.current;

    let end = element.scrollWidth;
    let width = element.clientWidth;
    let offset = end - width;
    let position = parseInt(element.scrollLeft);

    let show = end > width;
    setAllowScrollLeft(show);
    setAllowScrollRight(show);
    setShowArrows(show);

    // setAllowScrollLeft(offset > position ? true : false);
    // setAllowScrollRight(position < offset ? true : false);
    // setShowArrows(end > width);
  };

  const scrollRight = () => {
    scroll(1);
  };

  const scrollLeft = () => {
    scroll(-1);
  };

  return (
    <Box
      ref={container}
      sx={{
        width: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        {showArrows ? (
          <OFDIcon
            name="chevronLeft"
            color="grey"
            shade={900}
            onClick={scrollLeft}
            disabled={!allowScrollLeft}
          />
        ) : null}

        <Box
          ref={content}
          sx={{
            width: "95%",
            overflowX: "hidden",
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
          }}
        >
          {children}
        </Box>

        {showArrows ? (
          <OFDIcon
            name="chevronRight"
            color="grey"
            shade={900}
            onClick={scrollRight}
            disabled={!allowScrollRight}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default OFDHorizontalScroll;
