import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { startOfToday, addDays } from "date-fns";

import useApi from "../../../hooks/useApi";

import { Box, Button, Stack, Typography } from "@mui/material";

const Test = () => {
  const { getData, postData } = useApi();

  const [results, setResults] = useState(null);

  const doSomething = async () => {
    const apiResults = await postData({
      entityName: "Asset",
      action: "getToolAssets",
      data: {},
    });

    console.log({ apiResults });

    setResults(JSON.stringify(apiResults));
  };

  return (
    <>
      <Typography
        variant="h5"
        align="center"
        mt="2rem"
      >
        TEST
      </Typography>

      <Typography
        variant="h6"
        align="center"
      >
        Results
      </Typography>

      <Box
        sx={{
          border: "1px solid #ccc",
          marginInline: "1rem",
          minHeight: "3rem",
        }}
      >
        <Typography sx={{ whiteSpace: "pre-wrap", padding: ".5rem" }}>
          {results}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "1rem",
        }}
      >
        <Button onClick={() => setResults(null)}>Clear Results</Button>
      </Box>

      <Stack
        mt="2rem"
        ml="1rem"
        spacing={1}
      >
        <Box>
          <Button onClick={doSomething}>Execute</Button>
        </Box>
      </Stack>
    </>
  );
};

export default Test;
