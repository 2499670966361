import {
  TextField,
  FormControl,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormHelperText,
} from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

const OFDCurrency = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  multiline,
  fullWidth,
  security,
  entryAddedBy,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <FormControl
      id={id}
      fullWidth={fullWidth}
      size="small"
      required={required}
    >
      <InputLabel htmlFor={id}>{getLabel(label)}</InputLabel>

      <OutlinedInput
        id={id}
        label={getLabel(label)}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        error={error || false}
        disabled={isDisabled()}
        multiline={multiline}
        size="small"
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        type="number"
      />
      <FormHelperText error={error || false}>
        {message ? getMessage(message) : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default OFDCurrency;
