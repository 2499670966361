import { useEffect } from "react";

import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { isDate } from "date-fns";

import { Box, Typography } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

const OFDDatetime = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  disabled,
  security,
  entryAddedBy,
  defaultValue,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  useEffect(() => {
    if (!value && defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box>
      <MobileDateTimePicker
        id={id}
        label={getLabel(label)}
        value={
          isDate(value) ? value : isDate(defaultValue) ? defaultValue : null
        }
        onChange={onChange}
        disabled={isDisabled()}
        sx={{
          "& .MuiOutlinedInput-input": {
            border: `1px solid ${error ? "#ff0000" : "#ccc"}`,
            borderRadius: "4px",
          },
          width: "100%",
        }}
        slotProps={{
          toolbar: {
            sx: {
              "& .MuiPickersToolbar-content": {
                "& .MuiDateTimePickerToolbar-timeContainer": {
                  "& .MuiDateTimePickerToolbar-ampmSelection": {
                    "& .MuiButtonBase-root": {
                      padding: "2px",
                      "& .MuiTypography-root.MuiPickersToolbarText-root.Mui-selected":
                        {
                          backgroundColor: "#000000",
                          color: "#ffffff",
                          padding: "4px",
                          borderRadius: "50%",
                        },
                    },
                  },
                },
              },
            },
          },
        }}
      />

      <Typography
        variant="caption"
        sx={{
          marginLeft: "1rem",
          color: error ? "#ff0000" : "#ccc",
        }}
      >
        {message ? getMessage(message) : ""}
      </Typography>
    </Box>
  );
};

export default OFDDatetime;
