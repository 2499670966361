import { useEffect, useState, useRef } from "react";

import useLocalization from "../../hooks/useLocalization";
import useWindow from "../../hooks/useWindow";

import { Box, Stack } from "@mui/material";

import GroceryItemShop from "./GroceryItemShop";
import OFDSwitch from "../ui/OFDSwitch";
import OFDSelect from "../ui/OFDSelect";
import OFDFieldContainer from "../ui/OFDFieldContainer";

const GroceryItemsShop = ({ grocery, onItemChange }) => {
  const { getLabel } = useLocalization();
  const { windowHeight } = useWindow();

  const [groceryItems, setGroceryItems] = useState([]);
  const [hidePicked, setHidePicked] = useState(false);
  const [department, setDepartment] = useState("");
  const [listHeight, setListHeight] = useState();

  const containerRef = useRef();
  const pickerRef = useRef();

  useEffect(() => {
    if (!grocery) return;

    let items = grocery.groceryItems;
    if (department) {
      items = items.filter((item) => item.department === department);
    }

    if (!hidePicked) {
      setGroceryItems(items);
    } else {
      let unpicked = items.filter((item) => !Boolean(item.purchased));
      setGroceryItems(unpicked);
    }
  }, [grocery, hidePicked, department]);

  const getDepartments = () => {
    let departments = [];

    departments.push({
      value: "",
      label: "All",
    });
    for (const item of grocery.groceryItems) {
      if (!item.department) continue;
      let index = departments.findIndex(
        (dept) => dept.value === item.department
      );

      if (index === -1) {
        departments.push({ value: item.department, label: item.department });
      }
    }

    return departments;
  };

  const handleItemChange = (groceryItem) => {
    onItemChange(groceryItem);
  };

  useEffect(() => {
    if (!containerRef.current || !pickerRef.current) {
      setListHeight(500);
      return;
    }

    setListHeight(
      containerRef.current.clientHeight - pickerRef.current.clientHeight - 20
    );
  }, [containerRef.current, pickerRef.current]);

  if (!grocery || !groceryItems) return null;

  return (
    <Box
      ref={containerRef}
      sx={{ height: `${windowHeight - 260}px` }}
    >
      <Box
        ref={pickerRef}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "1rem",
        }}
      >
        <OFDSwitch
          id="hidePicked"
          label={getLabel("hidePicked")}
          value={hidePicked}
          onChange={(newValue) => setHidePicked(newValue)}
        />

        <OFDSelect
          id="departments"
          label="department"
          value={department}
          onChange={(newValue) => setDepartment(newValue)}
          options={getDepartments()}
          variant="outlined"
          fullWidth
        />
      </Box>

      <OFDFieldContainer>
        <Box
          sx={{
            height: `${listHeight}px`,
            overflow: "auto",
            paddingBottom: "2rem",
          }}
        >
          <Stack
            spacing={1}
            sx={{ marginTop: "1rem" }}
          >
            {groceryItems?.map((groceryItem) => (
              <GroceryItemShop
                key={groceryItem.id}
                groceryItem={groceryItem}
                onChange={handleItemChange}
              />
            ))}
          </Stack>
        </Box>
      </OFDFieldContainer>
    </Box>
  );
};

export default GroceryItemsShop;
