import { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import useLocalization from "../../hooks/useLocalization";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";

import OFDTextField from "../ui/OFDTextField";
import OFDButton from "../ui/OFDButton";

const UserPin = ({ open, onClose, onSuccess }) => {
  const { postData } = useApi();
  const { getLabel } = useLocalization();

  const [pin, setPin] = useState();
  const [pinMessage, setPinMessage] = useState(null);

  useEffect(() => {
    resetData();
  }, [open]);

  const resetData = () => {
    setPin(null);
    setPinMessage(null);
  };

  const handleVerifyPin = async () => {
    setPinMessage(null);

    const verify = await postData({
      entityName: "Member",
      action: "verifyPin",
      data: {
        pin,
      },
    });

    if (verify.isSuccessful) {
      if (verify.data.hasPin && verify.data.validPin) {
        onSuccess();
        return;
      }
      if (!verify.data.hasPin) {
        setPinMessage("missingPin");
      } else if (!verify.data.validPin) {
        setPinMessage("invalidPin");
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{getLabel("enterPin")}</DialogTitle>

      <DialogContent>
        <OFDTextField
          label="pin"
          value={pin}
          onChange={(newValue) => setPin(newValue)}
          message={pinMessage}
          error={Boolean(pinMessage)}
          type="password"
        />
      </DialogContent>

      <DialogActions>
        <OFDButton
          variant="outlined"
          label="cancel"
          onClick={handleClose}
        />
        <OFDButton
          variant="contained"
          label="submit"
          onClick={handleVerifyPin}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UserPin;
