import { useEffect } from "react";

import useMember from "../../hooks/useMember";
import useColor from "../../hooks/useColor";

import { Avatar, Typography, Box } from "@mui/material";

const sizes = {
  xsmall: {
    width: 24,
    height: 24,
    border: "1px",
    fontSize: ".75rem",
  },
  small: {
    width: "32px",
    height: "32px",
    border: "1px",
    fontSize: "1rem",
  },
  medium: {
    width: 40,
    height: 40,
    border: "2px",
    fontSize: "1.5rem",
  },
  large: {
    width: 54,
    height: 54,
    border: "3px",
    fontSize: "2rem",
  },
  xlarge: {
    width: 62,
    height: 62,
    border: "4px",
    fontSize: "2.5rem",
  },
};

const OFDAvatar = ({
  id,
  avatar,
  name,
  size = "small",
  borderColor,
  backgroundColor,
  onClick,
}) => {
  const { memberColor } = useMember();
  const { getColor } = useColor();

  const configureLetter = () => {
    if (!name) return "";

    const names = name.split(" ");

    let letters = "";

    let nameCount = names.length > 2 ? 2 : names.length;

    for (let x = 0; x < nameCount; x++) {
      letters += names[x].substring(0, 1).toUpperCase();
    }

    return letters;
  };

  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Box
      id={id}
      onClick={handleClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sizes[size ? size : "medium"],
        backgroundColor: backgroundColor
          ? getColor({ color: backgroundColor }).backgroundColor
          : "transparant",
        borderRadius: "50%",
      }}
    >
      {avatar ? (
        <Box
          sx={{
            ...sizes[size ? size : "medium"],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="avatar"
            srcSet={avatar}
            style={{
              ...sizes[size ? size : "medium"],
              border: borderColor
                ? `4px solid ${
                    memberColor({ color: borderColor }).backgroundColor
                  }`
                : null,
              backgroundColor: backgroundColor
                ? memberColor({ color: backgroundColor })
                : "#ddd",
              borderRadius: "50%",
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            ...sizes[size ? size : "medium"],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {configureLetter()}
        </Box>
      )}
    </Box>
  );
};

export default OFDAvatar;
