import React from "react";

import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import Notifications from "../../editors/Notifications";

const NotificationPreferences = ({ onChange }) => {
  const { getWizard } = useLocalization();

  return (
    <Box sx={{ padding: "1rem" }}>
      <Stack spacing={2}>
        <Typography variant="h5">
          {getWizard("setup").notifications.title}
        </Typography>
        {getWizard("setup").notifications.text.map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}

        <Notifications onChange={onChange} />
      </Stack>
    </Box>
  );
};

export default NotificationPreferences;
