import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import OFDMultiSelect from "./OFDMultiSelect";

const OFDByDay = ({
  value,
  onChange,
  allowPosition = false,
  disabled,
  error,
  message,
}) => {
  const getSelectedValues = () => {
    let list = [];

    if (Array.isArray(value)) {
      for (const byDay of value) {
        list.push(
          `dow_${byDay.dow.toString()}${
            byDay.position ? `_${byDay.position.toString()}` : ""
          }`
        );
      }
    }

    return list;
  };

  const setSelectedValues = (newValue) => {
    let returnValue = [];

    if (Array.isArray(newValue)) {
      for (const item of newValue) {
        let itemValues = item.split("_");

        let byDay = {
          dow: parseInt(itemValues[1]),
          position: itemValues.length === 3 ? parseInt(itemValues[2]) : null,
        };

        returnValue.push(byDay);
      }
    }

    onChange(returnValue);
  };

  return (
    <Box>
      <OFDMultiSelect
        id="byDay"
        label="weekDays"
        value={getSelectedValues()}
        onChange={setSelectedValues}
        listName={allowPosition ? "dayPositions" : "days"}
        fullWidth
        disabled={disabled}
        error={error}
        message={message}
      />
    </Box>
  );
};

export default OFDByDay;
