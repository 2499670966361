import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDUrl from "../../ui/OFDUrl";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const RecipeName = ({ value, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">
        {getWizard("recipe").externalUrl.title}
      </Typography>

      <Stack spacing={2}>
        {getWizard("recipe").externalUrl.instructions.map(
          (instruction, index) => (
            <Typography key={`instruction-${index}`}>{instruction}</Typography>
          )
        )}

        <OFDFieldContainer>
          <OFDUrl
            id="externalUrl"
            label="externalUrl"
            value={value || ""}
            onChange={(newValue) => onChange(newValue)}
            error={error}
            message={message}
            fullWidth
          />
        </OFDFieldContainer>
      </Stack>
    </Box>
  );
};

export default RecipeName;
