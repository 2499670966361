import en_CA from "../localization/en_CA";
import fr_CA from "../localization/fr_CA";
import en_US from "../localization/en_US";

const useLocalization = (localeCode = process.env.REACT_APP_LOCALE) => {
  const localizations = {
    "en-ca": en_CA,
    "en-us": en_US,
    "fr-ca": fr_CA,
  };

  const localization = localizations[localeCode.toLowerCase()];

  const getLabel = (label) => {
    if (label in localization.labels) return localization.labels[label];

    return label;
  };

  const getTitle = (title) => {
    if (title in localization.titles) return localization.titles[title];

    return title;
  };

  const getText = (text) => {
    if (text in localization.text) return localization.text[text];

    return text;
  };

  const getMessage = (message) => {
    if (message in localization.messages) return localization.messages[message];

    return message;
  };

  const getList = (listName) => {
    if (!localization.lists[listName]) return [];

    return localization.lists[listName];
  };

  const getListItemLabel = (listName, value, short = false) => {
    let list = getList(listName);

    let listItem = list.find((item) => item.value === value);
    if (listItem) {
      if (short) {
        return listItem.short ? listItem.short : listItem.label;
      } else {
        return listItem.label;
      }
    }

    return value;
  };

  const getHelpText = (name) => {
    if (name in localization.help) return localization.help[name];

    return null;
  };

  const getWizard = (name) => {
    if (name in localization.wizards) return localization.wizards[name];

    return null;
  };

  const displayLocalCurrency = (number, fractions = 2) => {
    if (isNaN(number)) return "";

    return Intl.NumberFormat(localeCode, {
      style: "currency",
      currency: localization.currency,
      maximumFractionDigits: fractions,
    }).format(number);
  };

  const displayLocalNumber = (number) => {
    if (isNaN(number)) return "";

    return Intl.NumberFormat(localeCode).format(number);
  };

  const getMonthOption = (month) => {
    return localization.calendarLists.months.find(
      (item) => item.value === month
    );
  };

  const getSubscription = () => {
    return localization.subscription;
  };

  const getTerms = () => {
    return localization.terms;
  };

  const getAdvisorTerms = () => {
    return localization.advisorTerms;
  };

  return {
    currency: localization.currency,
    labels: localization.labels,
    messages: localization.messages,
    lists: localization.lists,
    text: localization.text,
    titles: localization.titles,
    securityLevels: localization.securityLevels,
    pageLabels: localization.pages,
    fields: localization.fields,
    instructions: localization.instructions,
    categories: localization.categories,
    faqs: localization.faqs,
    mealTypes: localization.lists.mealTypes,
    getLabel,
    getMessage,
    getList,
    getTitle,
    getText,
    calendarLists: localization.calendarLists,
    getHelpText,
    getWizard,
    displayLocalCurrency,
    displayLocalNumber,
    getListItemLabel,
    getMonthOption,
    grocery: localization.grocery,
    getSubscription,
    getTerms,
    getAdvisorTerms,
    familyDashboard: localization.familyDashboard,
  };
};

export default useLocalization;
