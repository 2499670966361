import React from "react";

import useLocalization from "../../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import Appointment from "./Appointment";

const Appointments = ({ appointments, onSelect }) => {
  const { getMessage } = useLocalization();

  if (appointments === undefined) return null;

  return (
    <>
      <Box>
        {Array.isArray(appointments) && appointments.length > 0 ? (
          <Stack spacing={1}>
            {appointments.map((appointment) => (
              <Appointment
                key={appointment.id}
                appointment={appointment}
              />
            ))}
          </Stack>
        ) : (
          <Typography>{getMessage("no_appointments_ForToday")}</Typography>
        )}
      </Box>
    </>
  );
};

export default Appointments;
