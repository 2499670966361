import { useEffect, useState, useRef } from "react";
import { startOfToday, isBefore } from "date-fns";

import { Box, Stack, Typography } from "@mui/material";

import useForm from "../../hooks/useForm";
import useEntity from "../../hooks/useEntity";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useReminder from "../../hooks/useReminder";
import useCalendar from "../../hooks/useCalendar";
import useApi from "../../hooks/useApi";
import useWindow from "../../hooks/useWindow";
import useSecurity from "../../hooks/useSecurity";

import OFDModal from "../layout/OFDModal";
import OFDMessage from "../ui/OFDMessage";
import OFDDatetime from "../ui/OFDDatetime";
import OFDToolbar from "../layout/OFDToolbar";
import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDSelect from "../ui/OFDSelect";
import OFDDisplayCategory from "../ui/OFDDisplayCategory";
import OFDDisplayMembers from "../ui/OFDDisplayMembers";
import OFDTime from "../ui/OFDTime";
import OFDDialog from "../layout/OFDDialog";
import OFDFieldContainer from "../ui/OFDFieldContainer";
import SeriesFilter from "./SeriesFilter";
import LoadingModal from "../layout/LoadingModal";

import FieldEditorModal from "./FieldEditorModal";

import OFDRecurrence from "../ui/OFDRecurrence";

const initialRecurrence = {
  rrule: null,
  occurrenceStart: null,
  frequency: "onetime",
  interval: null,
  count: null,
  until: null,
  forever: false,
  byDay: null,
  bySetPosition: null,
  byMonthDay: null,
  byMonth: null,
  monthlyType: "relative",
  scheduleDates: null,
};

const ReminderEditor = ({
  calendar,
  open,
  onClose,
  onSave,
  option,
  source,
  selectedDate,
}) => {
  const { entityConfig, defaultValues } = useEntity("Reminder");
  const {
    validateReminder,
    transformReminder,
    getRemindersForSource,
    addReminderToSource,
    deleteReminderFromSource,
  } = useReminder();
  const { getEntityDates } = useCalendar();
  const { getLabel } = useLocalization();
  const { member } = useMember();
  const { getData } = useApi();
  const { isDesktop, windowHeight } = useWindow();
  const { pageDefaultSecurity } = useSecurity();

  const {
    data,
    setData,

    setFieldValue,
    getFieldValue,
    getFieldMessage,
    getFieldLabel,
    getFieldError,
    getDataById,

    saveData,
    formMessage,
    formStatus,
    resetFormMessage,
    resetFieldMessages,
    setFieldMessages,
    setFormMessage,
    validate,
  } = useForm("Reminder");

  const [categorySelector, setCategorySelector] = useState(false);
  const [memberSelector, setMemberSelector] = useState(false);

  const [reminders, setReminders] = useState(null);

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [menuItems, setMenuItems] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);

  const [fieldsHeight, setFieldsHeight] = useState(null);

  const [seriesFilter, setSeriesFilter] = useState(null);

  const [recurrence, setRecurrence] = useState({
    ...initialRecurrence,
    occurrenceStart: selectedDate ? selectedDate : startOfToday(),
  });

  const [fieldErrors, setFieldErrors] = useState(null);

  const containerRef = useRef();

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setFieldsHeight(containerRef.current.clientHeight - 100);
  }, [containerRef]);

  useEffect(() => {
    if (open) {
      resetFormMessage();
      resetFieldMessages();
      setFieldErrors(null);
    }

    if (calendar) {
      getReminder();
    } else if (source) {
      setData(null);
      getReminders();
    } else {
      setData({
        ...defaultValues,
        frequency: "onetime",
        members: [member.id],
        category: "reminder",
        security: pageDefaultSecurity("Reminder"),
      });
    }

    buildMenuItems();
  }, [calendar, source, open]);

  const buildMenuItems = () => {
    let items = [{ name: "save", label: "save", icon: "save" }];

    if (source) {
      items.push({ name: "add", label: "add", icon: "add" });
      items.push({ name: "delete", label: "delete", icon: "delete" });
    }

    setMenuItems(items);
  };

  const getReminders = async () => {
    let sourceReminders = await getRemindersForSource(source);

    setReminders(sourceReminders);

    if (Array.isArray(sourceReminders)) {
      if (sourceReminders.length === 0) {
        setData({
          ...defaultValues,
          frequency: "onetime",
          members: [member.id],
          category: "reminder",
        });
        setRecurrence({ ...initialRecurrence });
      } else {
        setData(sourceReminders[0]);
        setRecurrence(sourceReminders[0].recurrence);
      }
    } else {
      setData({
        ...defaultValues,
        frequency: "onetime",
        members: [member.id],
        category: "reminder",
      });
      setRecurrence({ ...initialRecurrence });
    }
  };

  const handleReminderSelect = (reminderId) => {
    let selectedReminder = reminders.find(
      (reminder) => reminder.id === reminderId
    );

    setData(selectedReminder);
  };

  const getReminder = async (reminderId) => {
    const results = await getData({
      entityName: "Reminder",
      action: "get",
      id: reminderId ? reminderId : calendar.source.id,
    });

    if (results.isSuccessful) {
      if (!reminderId && option === "occurrence") {
        setData({
          ...results.data,
          originalStartDate: calendar.startDate,
          startDate: calendar.startDate,
          time: calendar.time,
          category: calendar.category,
          description: calendar.description,
          name: calendar.name,
          members: calendar.members,
          security: calendar.security,
        });
      } else {
        setData({ ...results.data });
        if (option === "series") {
          let filterStartDate = startOfToday();
          if (isBefore(filterStartDate, results.data.startDate)) {
            filterStartDate = results.data.startDate;
          }
          setSeriesFilter({
            fromDate: selectedDate || results.data.startDate,
            toDate: null,
            fromToday: false,
            entireSeries: false,
            ignoreChanged: true,
          });

          setRecurrence(results.data.recurrence);
        }
      }
    } else {
      setData(null);
      setRecurrence(null);
    }
  };

  const handleToolbarClick = async (name) => {
    switch (name) {
      case "save":
        await saveReminder();
        break;

      case "add":
        addReminder();
        break;

      case "delete":
        await deleteReminder();
        break;
    }
  };

  const addReminder = () => {
    setData({
      ...defaultValues,
      frequency: "onetime",
      members: [member.id],
      category: "reminder",
      security: pageDefaultSecurity("Reminder"),
    });
  };

  const deleteReminder = async () => {
    if (!data.id) return;

    await deleteReminderFromSource(source, data.id);

    onSave();
  };

  const handleMemberSelector = () => {
    setMemberSelector(true);
  };

  const handleCancelMembers = () => {
    setMemberSelector(false);
  };

  const handleChangeMembers = (newValue) => {
    setMemberSelector(false);
    setFieldValue("members", newValue);
  };

  const saveReminder = async () => {
    resetFormMessage();
    resetFieldMessages();

    let newData = transformReminder(data, option);
    const validationResults = validate(validateReminder, newData);

    if (!validationResults.isSuccessful) {
      setFormMessage(validationResults);
      setFieldMessages(validationResults.fieldErrors);
      setFieldErrors(validationResults.fieldErrors);
      return;
    }

    let action = data.id ? `updateReminder` : `addReminder`;

    if (!data.id && source) {
      newData.source = source;
    }

    let options = null;
    options = {
      option: option ? option : null,
      calendarId: calendar ? calendar.id : null,
      seriesFilter,
    };

    const results = await saveData({
      action,
      data: newData,
      options,
    });

    if (results.isSuccessful) {
      await getReminder(results.data.id);
    }

    if (source) {
      await addReminderToSource(source, results.data.id);
    }

    setOpenLoading(false);

    onSave();
  };

  const handleCategorySelector = () => {
    setCategorySelector(true);
  };

  const handleCancelCategory = () => {
    setCategorySelector(false);
  };

  const handleChangeCategory = (newValue) => {
    setCategorySelector(false);
    setFieldValue("category", newValue);
  };

  const handleRecurrenceChange = (newValue) => {
    setRecurrence(newValue);
    setFieldValue("recurrence", newValue);
    setFieldValue("frequency", newValue.frequency);

    if (!data.id && newValue.frequency !== "schedule") {
      setFieldValue("startDate", newValue.occurrenceStart);
    }
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="reminder"
      >
        <Box
          ref={containerRef}
          sx={{ height: `${windowHeight - 64}px` }}
        >
          <OFDToolbar
            sticky={true}
            menuItems={menuItems}
            onClick={handleToolbarClick}
          />

          {option === "series" && data ? (
            <OFDFieldContainer
              sx={{
                padding: "1rem",
                margin: "1rem",
                paddingBottom: "2rem",
              }}
            >
              <SeriesFilter
                id="seriesFilter"
                startDate={data.startDate}
                endDate={data.endDate}
                filter={seriesFilter}
                onChange={(newValue) => setSeriesFilter(newValue)}
              />
            </OFDFieldContainer>
          ) : null}

          <OFDFieldContainer
            sx={{
              height: fieldsHeight ? `${fieldsHeight}px` : "85%",
              overflow: "auto",
              padding: "1rem",
              margin: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <Box>
              {Array.isArray(reminders) && reminders.length > 0 ? (
                <Box sx={{ padding: ".5rem" }}>
                  <OFDSelect
                    id="reminders"
                    label="reminders"
                    value={data ? data.id : ""}
                    onChange={handleReminderSelect}
                    options={reminders?.map((reminder) => {
                      return { value: reminder.id, label: reminder.name };
                    })}
                    fullWidth
                  />
                </Box>
              ) : null}

              <Box>
                <Stack spacing={2}>
                  {getFieldValue("frequency") === "onetime" ||
                  option === "series" ||
                  !option ? (
                    <OFDRecurrence
                      value={recurrence}
                      onChange={handleRecurrenceChange}
                      disabled={calendar ? true : false}
                      fieldErrors={fieldErrors}
                    />
                  ) : null}

                  {getFieldValue("frequency") === "onetime" ||
                  option === "occurrence" ? (
                    <OFDDatetime
                      id="startDate"
                      label="startDate"
                      value={getFieldValue("startDate")}
                      onChange={(newValue) =>
                        setFieldValue("startDate", newValue)
                      }
                      required={true}
                      defaultValue={selectedDate}
                      message={getFieldMessage("startDate")}
                      error={getFieldError("startDate")}
                    />
                  ) : null}

                  {getFieldValue("frequency") !== "onetime" &&
                  option !== "occurrence" ? (
                    <OFDTime
                      id="time"
                      value={getFieldValue("time")}
                      onChange={(newValue) => setFieldValue("time", newValue)}
                    />
                  ) : null}

                  <OFDTextField
                    id="name"
                    label="subject"
                    value={getFieldValue("name")}
                    onChange={(newValue) => setFieldValue("name", newValue)}
                    required={true}
                    message={getFieldMessage("name")}
                    error={getFieldError("name")}
                  />

                  <OFDTextNote
                    id="description"
                    label="description"
                    value={getFieldValue("description")}
                    onChange={(newValue) =>
                      setFieldValue("description", newValue)
                    }
                  />

                  <OFDDisplayCategory
                    label="category"
                    value={getFieldValue("category")}
                    onClick={handleCategorySelector}
                  />

                  <OFDDisplayMembers
                    label="remind"
                    value={getFieldValue("members")}
                    onClick={handleMemberSelector}
                    message={getFieldMessage("members")}
                    error={getFieldError("members")}
                  />
                </Stack>
              </Box>
            </Box>
          </OFDFieldContainer>
        </Box>
      </OFDModal>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <FieldEditorModal
        entityName="Reminder"
        entityData={data}
        field={{
          id: "members",
          label: "remind",
          icon: "family",
          fieldType: "members",
        }}
        open={memberSelector}
        onCancel={handleCancelMembers}
        onChange={handleChangeMembers}
        returnValue
      />

      <FieldEditorModal
        entityName="Reminder"
        entityData={data}
        field={{
          id: "category",
          label: "category",
          icon: "category",
          fieldType: "category",
        }}
        open={categorySelector}
        onCancel={handleCancelCategory}
        onChange={handleChangeCategory}
        returnValue
      />

      <OFDDialog
        open={openSaveDialog}
        title="confirmSaveSeries"
        description="confirmSaveSeries"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={saveReminder}
      />

      <LoadingModal open={openLoading} />
    </>
  );
};

export default ReminderEditor;
