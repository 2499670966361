import { createSlice } from "@reduxjs/toolkit";

import Utils from "./Utils";
const { familyState, setState, getState, clearAllState } = Utils();

const initialState = {
  ...familyState(),
};

const familySlice = createSlice({
  name: "family",
  initialState,
  reducers: {
    setFamilyState(state, action) {
      const { data } = action.payload;
      if (!data) {
        state = initialState;
        clearAllState();
        return;
      }

      state.id = data.id;
      state.name = data.name;
      state.avatar = data.avatar;
      state.color = data.color;
      state.preferences = data.preferences;
      state.members = data.members;
      state.subscriptionPlanId = data.subscriptionPlanId;
      state.tier = data.tier;
      state.loginLimit = data.loginLimit;
      state.isFirstLogin = data.isFirstLogin;
      state.rcUserId = data.rcUserId ? data.rcUserId : null;

      setState({ name: "family", data });
    },

    refreshFamilyState(state, action) {
      const { data } = action.payload;

      state.id = data.id;
      state.name = data.name;
      state.avatar = data.avatar;
      state.color = data.color;
      state.preferences = data.preferences;
      state.members = data.members;
      state.subscriptionPlanId = data.subscriptionPlanId;
      state.tier = data.tier;
      state.loginLimit = data.loginLimit;
      state.isFirstLogin = data.isFirstLogin;
      state.rcUserId = data.rcUserId ? data.rcUserId : null;
    },

    updateIsFirstlogin(state) {
      state.isFirstLogin = false;
    },
  },
});

export const familyActions = familySlice.actions;
export default familySlice.reducer;
