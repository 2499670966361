import { useEffect, useState } from "react";

import useMessaging from "../../hooks/useMessaging";
import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useWindow from "../../hooks/useWindow";
import usePage from "../../hooks/usePage";

import { Box, Stack, Typography, Collapse } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../ui/OFDIcon";
import NotificationDisplay from "./NotificationDisplay";

const DisplayNotifications = () => {
  const { notifications, clearNotifications } = useMessaging();
  const { windowHeight } = useWindow();
  const { getLabel } = useLocalization();
  const { memberColor } = useMember();
  const { getColor } = useColor();
  const { refreshPage } = usePage();

  const theme = useTheme();

  const handleClose = () => {
    clearNotifications();
    refreshPage();
  };

  if (!Array.isArray(notifications)) return null;
  if (notifications.length === 0) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 5000,
      }}
    >
      <Collapse
        orientation="vertical"
        timeout={1500}
        in={notifications.length > 0}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "300px",
              backgroundColor: "#ebebeb",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              boxShadow: theme.shadows[1],
            }}
          >
            <Box
              sx={{
                ...getColor({ color: "grey", shade: 900 }),
                padding: ".5rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">{getLabel("notifications")}</Typography>
              <OFDIcon
                name="close"
                onClick={handleClose}
                color="grey"
                shade={50}
              />
            </Box>

            <Box
              sx={{
                padding: "1rem",
                maxHeight: `${windowHeight - 100}px`,
                overflow: "auto",
              }}
            >
              <Stack spacing={2}>
                {notifications?.map((notification) => {
                  return (
                    <NotificationDisplay
                      key={notification.id}
                      notification={notification}
                    />
                  );
                })}
              </Stack>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default DisplayNotifications;
