import React from "react";

import { Box, Modal } from "@mui/material";

import Loading from "./Loading";

const LoadingModal = ({ open }) => {
  return (
    <Modal open={Boolean(open)}>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Loading />
      </Box>
    </Modal>
  );
};

export default LoadingModal;
