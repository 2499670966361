import { useState } from "react";

import useLocalization from "../../../../hooks/useLocalization";
import usePage from "../../../../hooks/usePage";

import { Box, Typography } from "@mui/material";

import OFDButton from "../../../ui/OFDButton";
import OFDIcon from "../../../ui/OFDIcon";

import DashboardMenuItems from "./DashboardMenuItems";

const DashboardMenu = ({ selectedMenuItem, onSelect, onUserMode }) => {
  const { familyDashboard } = useLocalization();
  const { dashboardMode, setDashboardMode } = usePage();

  const selectedItemLabel = () => {
    const item = familyDashboard.menuItems.find(
      (i) => i.id === selectedMenuItem
    );

    return item.label;
  };

  const handleChange = (id) => {
    onSelect(id);
  };

  return (
    <>
      <Box
        sx={{
          height: "5rem",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#ccc",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }}
      >
        <Typography variant="h4">{selectedItemLabel()}</Typography>

        <DashboardMenuItems
          selectedMenuItem={selectedMenuItem}
          onChange={handleChange}
        />

        <Box>
          <OFDIcon
            name="exittoapp"
            size="large"
            color="grey"
            shade={50}
            onClick={onUserMode}
          />
        </Box>
      </Box>
    </>
  );
};

export default DashboardMenu;
