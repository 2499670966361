import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDDisplayUrl = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="overline"
            onClick={onClick}
          >
            {getLabel(label)}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              noWrap
              onClick={onClick}
            >
              {value ? value : "-"}
            </Typography>
            {value ? (
              <Box>
                <a
                  href={value}
                  target="_blank"
                  rel="noopener"
                >
                  <OFDIcon name="http" />
                </a>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OFDDisplayUrl;
