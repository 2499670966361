import { v4 as uuidV4 } from "uuid";
import { parseISO, isDate } from "date-fns";
import {
  getRecord,
  getAllRecords,
  putRecord,
  deleteRecord,
  getKeys,
} from "../idb";

const CryptoJS = require("crypto-js");

const useData = () => {
  const encrypt = (value) => {
    if (!value instanceof String) {
      return value;
    }

    if (!value || value.length === 0) {
      return value;
    }

    let hashedValue;

    try {
      hashedValue = CryptoJS.AES.encrypt(
        value,
        process.env.REACT_APP_LOCAL_KEY
      ).toString();

      return hashedValue;
    } catch (err) {
      return value;
    }
  };

  const decrypt = (hashedValue) => {
    let bytes;
    try {
      if (!hashedValue instanceof String) {
        return hashedValue;
      }

      if (hashedValue.length === 0) {
        return hashedValue;
      }

      bytes = CryptoJS.AES.decrypt(
        hashedValue,
        process.env.REACT_APP_LOCAL_KEY
      );

      let value = bytes.toString(CryptoJS.enc.Utf8);

      return value;
    } catch (err) {
      return hashedValue;
    }
  };

  const sort = (array, field, order = "asc", parse = (x) => x) => {
    const sortOrder = order === "asc" ? 1 : -1;
    let sortedArray = [...array];

    if (field) {
      sortedArray.sort((a, b) => {
        let result = 0;
        if (parse(a[field] < parse(b[field]))) {
          result = -1;
        }
        if (parse(a[field] > parse(b[field]))) {
          result = 1;
        }
        return result * sortOrder;
      });
    } else {
      if (order === "asc") {
        sortedArray.sort((a, b) => (a < b ? -1 : 1));
      } else {
        sortedArray.reverse();
      }
    }

    return sortedArray;
  };

  const fieldSorter = (fields) => (a, b) =>
    fields
      .map((o) => {
        let dir = 1;
        if (o[0] === "-") {
          dir = -1;
          o = o.substring(1);
        }
        let left = a[o] ? a[o] : " ";
        let right = b[o] ? b[o] : " ";
        return left > right ? dir : left < right ? -dir : 0;
      })
      .reduce((p, n) => (p ? p : n), 0);

  const sortByFields = (array, fields) => {
    let sortedArray = [...array];

    sortedArray.sort(fieldSorter(fields));

    return sortedArray;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const newId = () => {
    return uuidV4();
  };

  const putRecordInIDB = async (storeName, key, data) => {
    if (!data) return;

    const stringData = JSON.stringify(data);
    const encryptedData = encrypt(stringData);
    await putRecord(storeName, key, encryptedData);
  };

  const getRecordFromIDB = async (storeName, key) => {
    const encryptedData = await getRecordFromIDB(storeName, key);

    if (!encryptedData) return null;

    const decryptedData = decrypt(encryptedData);
    const data = JSON.parse(decryptedData);
    return data;
  };

  const deleteRecordFromIDB = async (storeName, key) => {
    await deleteRecord(storeName, key);
  };

  const getAllFromIDB = async (storeName) => {
    const records = await getAllRecords(storeName);
    return records;
  };

  const getAllKeysFromIDB = async (storeName) => {
    const keys = await getKeys(storeName);
    return keys;
  };

  const toDate = (value) => {
    if (!value) return null;

    if (isDate(value)) return value;

    let newValue = parseISO(value);

    if (isDate(newValue)) return newValue;

    return null;
  };

  const toNumber = (value) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") return parseInt(value);
  };

  const isISODateString = (value) => {
    const isoDateFormat =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

    return value && typeof value === "string" && isoDateFormat.test(value);
  };

  const urlBase64ToUint8Array = (base64String) => {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const decodeBase64 = (encoded) => {
    return new Uint8Array(
      atob(encoded)
        .split("")
        .map((c) => c.charCodeAt(0))
    );
  };

  const decode = (input) => {
    try {
      return decodeBase64(
        input.replace(/-/g, "+").replace(/_/g, "/").replace(/\s/g, "")
      );
    } catch (_a) {
      throw new TypeError("The input to be decoded is not correctly encoded.");
    }
  };

  return {
    sort,
    sortByFields,
    newId,
    reorder,
    encrypt,
    decrypt,
    putRecordInIDB,
    getRecordFromIDB,
    deleteRecordFromIDB,
    getAllFromIDB,
    getAllKeysFromIDB,
    toDate,
    toNumber,
    isISODateString,
    urlBase64ToUint8Array,
    decode,
  };
};

export default useData;
