import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

import { Box, IconButton, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";

const OFDToolbarItem = ({ name, label, icon, hide, onClick, sx }) => {
  const { getLabel } = useLocalization();
  const { memberMode } = useMember();

  const iconButtonStyle = {
    backgroundColor: "#fff",
    padding: "4px",
    borderRadius: "8px",
    ...sx,
  };

  const iconBoxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
  };

  const iconLabelStyle = {
    fontSize: "10px",
    paddingTop: "2px",
  };

  if (hide) return null;

  return (
    <Box
      id={name}
      sx={iconBoxStyle}
    >
      <Box
        sx={{ ...iconButtonStyle, cursor: "pointer" }}
        onClick={() => onClick(name)}
      >
        <OFDIcon
          name={icon}
          color="grey"
          shade={900}
        />
      </Box>
      {memberMode === "novice" ? (
        <Typography
          sx={iconLabelStyle}
          align="center"
          noWrap
        >
          {getLabel(label)}
        </Typography>
      ) : null}
    </Box>
  );
};

export default OFDToolbarItem;
