import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useSubscription from "../../../../hooks/useSubscription";

import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import OFDTextField from "../../../ui/OFDTextField";
import { Language } from "@mui/icons-material";

const RegisterEmail = () => {
  const { registerEmail } = useSubscription();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [retype, setRetype] = useState("");
  const [message, setMessage] = useState("");

  const [openWhy, setOpenWhy] = useState(false);

  const handleOpenWhy = () => {
    setOpenWhy(true);
  };

  const handleRegister = async () => {
    setMessage(null);

    if (!isEntryComplete()) return;
    if (!passwordsMatch()) return;

    const data = {
      name,
      email,
      password,
      retype,
      store: "stripe",
      country: process.env.REACT_APP_COUNTRY,
      language: "en",
      localCode: process.env.REACT_APP_LOCALE,
      appId: process.env.REACT_APP_STRIPE_APPID,
    };

    const results = await registerEmail(data);

    if (results.isSuccessful) {
      if (results.data.verificationEmailSent) {
        navigate("/signup/emailverification");
      } else {
        setMessage(
          "Verification email failed to send. Please check that your email is valid."
        );
      }
    } else {
      if (results.data.alreadyRegistered) {
        setMessage("This email has already been registered");
        return;
      }
      setMessage(results.message);
    }
  };

  const isEntryComplete = () => {
    let isComplete = true;

    if (name.trim().length === 0) isComplete = false;
    if (email.trim().length === 0) isComplete = false;
    if (password.trim().length === 0) isComplete = false;
    if (retype.trim().length === 0) isComplete = false;

    if (!isComplete) {
      setMessage("Please complete all fields");
    }

    return isComplete;
  };

  const passwordsMatch = () => {
    let matches = password === retype;

    if (!matches) {
      setMessage("Passwords do not match");
    }

    return matches;
  };

  const gotoLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <Typography
            variant="h5"
            align="center"
          >
            Registration
          </Typography>

          <OFDTextField
            id="name1"
            value={name}
            onChange={(newValue) => setName(newValue)}
            label="Name"
          />

          <OFDTextField
            id="field1"
            value={email}
            onChange={(newValue) => setEmail(newValue)}
            label="Email"
          />

          <OFDTextField
            id="pswd1"
            value={password}
            onChange={(newValue) => setPassword(newValue)}
            label="Password"
            type="password"
          />

          <OFDTextField
            id="pswd2"
            value={retype}
            onChange={(newValue) => setRetype(newValue)}
            label="Retype Password"
            type="password"
          />

          <Button onClick={handleOpenWhy}>Why do I have to register?</Button>

          <Button
            variant="contained"
            onClick={handleRegister}
          >
            Register
          </Button>

          <Typography
            align="center"
            sx={{ color: "#ff0000" }}
          >
            {message}
          </Typography>

          <Button onClick={gotoLogin}>Login</Button>
        </Stack>
      </Box>

      <Modal
        open={openWhy}
        onClose={() => setOpenWhy(false)}
      >
        <Box
          sx={{
            marginTop: "3rem",
            marginLeft: "2rem",
            marginRight: "2rem",
            backgroundColor: "#fff",
            borderRadius: "1rem",
            padding: "1rem",
          }}
        >
          <Stack spacing={2}>
            <Typography
              align="center"
              variant="h6"
            >
              Why Do I Have To Register?
            </Typography>
            <Typography>
              Each family member can have their own login.
            </Typography>
            <Typography>
              This allows each member to recieve their specific reminders, see
              the data they are authorized to see and customize items.
            </Typography>
            <Typography>
              Private items can be added so only the user has access to them.
            </Typography>
            <Typography>
              Security levels are assigned to each login so that they see only
              those items that are visible to their level.
            </Typography>
            <Typography>
              This registration sets you up as the Account Owner, providing you
              full access to configure Our Family Dashboard for you family.
            </Typography>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default RegisterEmail;
