import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useExpense from "../../hooks/useExpense";
import useEntity from "../../hooks/useEntity";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import Loading from "../layout/Loading";
import OFDToolbar from "../layout/OFDToolbar";

import ExpenseEditor from "./ExpenseEditor";
import ExpenseDisplay from "../display/ExpenseDisplay";

const SourceExpenses = ({ source, open, onClose }) => {
  const { sourceEntity, getSourceEntity } = useEntity();
  const { expenses, getSourceExpenses } = useExpense();
  const { getMessage } = useLocalization();

  const [openEditor, setOpenEditor] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);

  useEffect(() => {
    getData();
  }, [source]);

  const getData = async () => {
    if (!source) return;

    await getSourceEntity(source);
    await getSourceExpenses(source);
  };

  const handleMenuClick = (action) => {
    if (action === "addExpense") {
      setSelectedExpense(null);
      setOpenEditor(true);
    }
  };

  const handleSave = async () => {
    setOpenEditor(false);
    await getSourceExpenses(source);
  };

  if (expenses === undefined || !sourceEntity) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={sourceEntity.name}
      >
        <Box>
          <OFDToolbar
            menuItems={[
              { name: "addExpense", label: "expense", icon: "addExpense" },
            ]}
            onClick={handleMenuClick}
          />

          <Box sx={{ padding: "1rem" }}>
            {Array.isArray(expenses) && expenses.length > 0 ? (
              <Stack spacing={1}>
                {expenses?.map((expense) => (
                  <ExpenseDisplay
                    key={expense.id}
                    expense={expense}
                    onChange={handleSave}
                  />
                ))}
              </Stack>
            ) : (
              <Typography align="center">{getMessage("noExpenses")}</Typography>
            )}
          </Box>
        </Box>
      </OFDModal>

      <ExpenseEditor
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        source={source}
        onSave={handleSave}
        expense={selectedExpense}
        expenseCalendar
      />
    </>
  );
};

export default SourceExpenses;
