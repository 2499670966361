import { useEffect, useState } from "react";
import { startOfDay, endOfDay } from "date-fns";

import useCalendar from "../../../../hooks/useCalendar";
import usePage from "../../../../hooks/usePage";

import { Box } from "@mui/material";

import ToDos from "./ToDos";

const ToDoWidget = ({ forDate }) => {
  const { getCalendarData, calendarData, getUniqueItems } = useCalendar();
  const { refresh } = usePage();

  const [todos, setToDos] = useState(null);

  useEffect(() => {
    getData();
  }, [refresh, forDate]);

  const getData = async () => {
    const filter = {
      calendarType: "todo",
      startDate: { $gte: startOfDay(forDate), $lte: endOfDay(forDate) },
      "security.view.securityLevel": "family",
    };

    await getCalendarData({ filter });
  };

  useEffect(() => {
    if (!calendarData) return;

    setToDos(getUniqueItems(calendarData));
  }, [calendarData]);

  return (
    <>
      <Box>
        <ToDos
          todos={todos}
          onUpdated={getData}
        />
      </Box>
    </>
  );
};

export default ToDoWidget;
