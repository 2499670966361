import { useEffect, useState } from "react";

import useWindow from "../../hooks/useWindow";

import { Box, Stack, Typography } from "@mui/material";

const OFDListAndDetails = ({ list, details, detailStyles }) => {
  const { windowWidth, windowHeight } = useWindow();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: `calc(${windowHeight}px - 220px)`,
          width: "100%",
          paddingTop: "1rem",
        }}
        gap={3}
      >
        <Box
          sx={{
            width: "50%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {list}
        </Box>
        <Box
          sx={{
            width: "50%",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            ...detailStyles,
          }}
        >
          {details}
        </Box>
      </Box>
    </>
  );
};

export default OFDListAndDetails;
