import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayNote from "./OFDDisplayNote";
import OFDCollapsable from "../layout/OFDCollapsable";
import OFDDisplayText from "./OFDDisplayText";
import OFDDisplayCurrency from "./OFDDisplayCurrency";
import OFDButton from "./OFDButton";
import ExpenseEditor from "../editors/ExpenseEditor";

const OFDDisplayServiceLog = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const [showExpense, setShowExpense] = useState(false);
  const [expenseId, setExpenseId] = useState(null);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  useEffect(() => {
    console.log({ value });
  }, []);

  const getHeader = (item) => {
    let text = "";
    if (item.date) {
      text += `${format(item.date, "MMM dd, yyyy")} : `;
    }
    text += item.name;

    return text;
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleOpenExpense = (item) => {
    setExpenseId(item.expenseId);
    setShowExpense(true);
  };

  const handleCloseExpense = () => {
    setShowExpense(false);
    setExpenseId(null);
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={displayStyle}>
        <Box onClick={handleClick}>
          <Typography variant="overline">{getLabel(label)}</Typography>
        </Box>

        <Box>
          <Stack spacing={0.5}>
            {Array.isArray(value) && value.length > 0 ? (
              value?.map((item) => (
                <OFDCollapsable
                  key={item.id}
                  compressed
                  header={
                    <Typography onClick={handleClick}>
                      {getHeader(item)}
                    </Typography>
                  }
                  styleOverride={{ backgroundColor: "#e0e0e0" }}
                >
                  <Stack spacing={1}>
                    <Box
                      sx={{ display: "flex", marginTop: ".5rem" }}
                      gap={1}
                    >
                      <Box sx={{ width: "50%" }}>
                        <OFDDisplayText
                          value={item.odometer}
                          label="odometer"
                        />
                      </Box>
                      <Box sx={{ width: "50%" }}>
                        <OFDDisplayCurrency
                          value={item.cost}
                          label="cost"
                        />
                      </Box>
                    </Box>

                    {item.expenseId ? (
                      <Box
                        sx={{ display: "flex", marginTop: ".5rem" }}
                        gap={1}
                      >
                        <Box sx={{ width: "50%" }}></Box>
                        <Box>
                          <OFDButton
                            variant="contained"
                            label="expense"
                            color="grey"
                            shade={900}
                            iconName="addExpense"
                            onClick={() => handleOpenExpense(item)}
                          />
                        </Box>
                      </Box>
                    ) : null}
                  </Stack>

                  {item.note ? (
                    <OFDDisplayNote
                      value={item.note}
                      textOnly={true}
                      styleoverride={{ fontSize: "12px", color: "#535353" }}
                    />
                  ) : null}
                </OFDCollapsable>
              ))
            ) : (
              <Typography>-</Typography>
            )}
          </Stack>
        </Box>
      </Box>

      {expenseId ? (
        <ExpenseEditor
          open={showExpense}
          expenseId={expenseId}
          onClose={handleCloseExpense}
        />
      ) : null}
    </>
  );
};

export default OFDDisplayServiceLog;
