import { useEffect, useState } from "react";

import { isDate } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";
import useColor from "../../hooks/useColor";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Box } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDDate = ({
  id,
  label,
  value,
  onChange,
  required,
  error,
  message,
  disabled,
  security,
  entryAddedBy,
  minDate,
  maxDate,
  defaultValue,
  color,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();
  const { memberColor } = useMember();
  const { getColor } = useColor();

  useEffect(() => {
    if (!value && defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const handleClear = () => {
    onChange(null);
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={{ display: "flex", alignItems: "flex-start" }}
      gap={0.5}
    >
      <DatePicker
        id={id}
        label={getLabel(label)}
        value={
          isDate(value) ? value : isDate(defaultValue) ? defaultValue : null
        }
        minDate={minDate || null}
        maxDate={maxDate || null}
        onChange={onChange}
        disabled={isDisabled()}
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
          "& label.Mui-focused": {
            color: color
              ? getColor({ color }).backgroundColor
              : memberColor().backgroundColor,
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: color
                ? getColor({ color }).backgroundColor
                : memberColor().backgroundColor,
            },
          },
          width: "100%",
        }}
        slotProps={{
          textField: {
            helperText: message ? getMessage(message) : null,
            required: required || false,
            error: error || false,
          },
        }}
      />
      {!required ? (
        <Box sx={{ marginTop: "10px" }}>
          <OFDIcon
            name="backspace"
            color="grey"
            onClick={handleClear}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDDate;
