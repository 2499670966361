import React from "react";
import { format, isDate } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

const OFDDisplayDateTimeRange = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const formatValue = () => {
    if (!Array.isArray(value)) return null;

    const startDate = isDate(value[0]) ? value[0] : null;
    const endDate = isDate(value[1]) ? value[1] : startDate;

    if (!startDate) return "invalid";

    let dateDisplay = format(startDate, "MMM d, yyyy");
    let startTime = format(startDate, "hh:mm a");
    let endTime = format(endDate, "hh:mm a");
    let timeDisplay = "";
    if (startTime === "12:00 AM" && startTime === endTime) {
      timeDisplay = getLabel("allDay");
    } else {
      timeDisplay = `${startTime} - ${endTime}`;
    }

    let displayValue = `${dateDisplay} : ${timeDisplay}`;

    return displayValue;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={onClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>

      <Typography>{formatValue()}</Typography>
    </Box>
  );
};

export default OFDDisplayDateTimeRange;
