import { useEffect, useState } from "react";

import { Box, Checkbox, FormControlLabel } from "@mui/material";

import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";
import useColor from "../../hooks/useColor";

const OFDCheckBox = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  security,
  entryAddedBy,
  color,
  shade,
  size,
}) => {
  const { userCanView, userCanUpdate } = useSecurity();
  const { memberColor } = useMember();
  const { getColor } = useColor();

  const [backgroundColor, setBackgroundColor] = useState(
    memberColor({ shade: 500 }).backgroundColor
  );

  useEffect(() => {
    if (color) {
      setBackgroundColor(getColor({ color, shade }).backgroundColor);
    } else {
      setBackgroundColor(
        memberColor({ shade: shade ? shade : 500 }).backgroundColor
      );
    }
  }, [memberColor]);

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box id={id}>
      {label ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
              disabled={isDisabled()}
              sx={{
                color: backgroundColor,
                "&.Mui-checked": {
                  color: backgroundColor,
                },
              }}
            />
          }
          label={label}
          required={required}
        />
      ) : (
        <Checkbox
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          disabled={isDisabled()}
          size={size}
          sx={{
            color: backgroundColor,
            "&.Mui-checked": {
              color: backgroundColor,
            },
          }}
        />
      )}
    </Box>
  );
};

export default OFDCheckBox;
