import useApi from "./useApi";
import useFamily from "./useFamily";

const useICal = () => {
  const { postData } = useApi();
  const { family } = useFamily();

  const testICalLink = async (url) => {
    const results = await postData({
      entityName: "Appointment",
      action: "iCalTestLink",
      data: {
        url,
      },
    });

    return results;
  };

  const importFile = async (data, fileType) => {
    const results = await postData({
      entityName: "Calendar",
      action: "import",
      data: {
        familyId: family.id,
        fileType,
        calendarData: data,
      },
    });

    return results;
  };

  return {
    testICalLink,
    importFile,
  };
};

export default useICal;
