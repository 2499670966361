import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { familyActions } from "../store/family";
import { authenticationActions } from "../store/authentication";
import { memberActions } from "../store/member";

import useApi from "./useApi";
import useMessage from "./useMessage";
import useMember from "./useMember";
import useData from "./useData";

const useFamily = () => {
  const { postData, getData } = useApi();
  const { getMemberState, member } = useMember();
  const { destructureResults } = useMessage();
  const { newId } = useData();

  const dispatch = useDispatch();

  const family = useSelector((state) => state.family);

  const [familyMessages, setFamilyMessages] = useState(null);

  const updateFamilyPreferences = async (newPreferences) => {
    const results = await postData({
      entityName: "Family",
      action: "update",
      data: {
        id: family.id,
        preferences: { ...newPreferences },
      },
    });

    setFamilyMessages(destructureResults(results));

    if (results.isSuccessful) {
      await getMemberState();
    }

    return results;
  };

  const refreshFamilyPreferences = async () => {
    const results = await getData({
      entityName: "Family",
      action: "familyPreferences",
      id: family.id,
      data: {
        familyId: family.id,
      },
    });

    if (results.isSuccessful) {
      dispatch(
        familyActions.setFamilyState({
          data: {
            ...family,
            preferences: { ...results.data.preferences },
          },
        })
      );
    }
  };

  const updateFamilyFirstLogin = async () => {
    if (!family.isFirstLogin) return;

    const results = await postData({
      entityName: "Family",
      action: "update",
      id: family.id,
      data: {
        id: family.id,
        isFirstLogin: false,
      },
    });

    if (results.isSuccessful) {
      dispatch(familyActions.updateIsFirstlogin());
    }
  };

  const deleteAllData = async () => {
    const deleteConfirmation = newId();

    const familyResults = await postData({
      entityName: "Family",
      action: "update",
      id: family.id,
      data: {
        id: family.id,
        deleteConfirmation,
      },
    });

    if (familyResults.isSuccessful) {
      const results = await postData({
        entityName: "Queue",
        action: "add",
        data: {
          transactionType: "deleteFamily",
          status: "queued",
          version: 1,
          payload: {
            familyId: family.id,
            deleteConfirmation,
            memberId: member.id,
          },
        },
      });

      if (results.isSuccessful) {
        dispatch(memberActions.setMemberState({ data: null }));
        dispatch(familyActions.setFamilyState({ data: null }));
        dispatch(authenticationActions.logout());
      }
    }
  };

  return {
    family,
    familyMembers: family?.members,
    familyPreferences: family?.preferences,
    familyPagePreferences: family?.preferences?.pagePreferences,
    familyModules: family?.preferences?.modules,
    updateFamilyPreferences,
    familyMessages,
    setFamilyMessages,
    refreshFamilyPreferences,
    updateFamilyFirstLogin,
    deleteAllData,
  };
};

export default useFamily;
