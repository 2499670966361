import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../../hooks/useLocalization";
import useGrocery from "../../../hooks/useGrocery";

import { Box, Stack, Typography } from "@mui/material";

import OFDSelect from "../../ui/OFDSelect";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const GroceryCopyList = ({ value, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();
  const { getGroceryLists, groceryLists, last3months } = useGrocery();

  const [options, setOptions] = useState(null);

  useEffect(() => {
    getGroceryLists(last3months);
  }, []);

  useEffect(() => {
    if (!groceryLists) {
      setOptions([
        {
          value: "skip",
          label: getLabel("startFromScratch"),
        },
      ]);
      return;
    }

    setOptions(() => {
      let list = [
        {
          value: "skip",
          label: getLabel("startFromScratch"),
        },
      ];

      for (const groceryList of groceryLists) {
        let date = null;
        if (groceryList.shoppingDate) {
          date = ` (${format(groceryList.shoppingDate, "EEE MMM dd")})`;
        }

        let label = (
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            gap={1}
          >
            <Typography>{groceryList.name}</Typography>
            <Typography variant="caption">{date}</Typography>
          </Box>
        );

        list.push({
          value: groceryList.id,
          label: label,
        });
      }

      return list;
    });
  }, [groceryLists]);

  const handleGrocerySelection = (groceryId) => {
    if (groceryId === "skip") {
      onChange("skip", null);
      return;
    }

    let selectedList = groceryLists.find((grocery) => grocery.id === groceryId);
    const copyOfList = copyGroceryList(selectedList);
    onChange(groceryId, copyOfList);
  };

  const copyGroceryList = (grocery) => {
    let list = [];
    for (const item of grocery.groceryItems) {
      list.push({
        ...item,
        purchased: false,
      });
    }

    const newGroceryList = { ...grocery, groceryItems: [...list] };

    return newGroceryList;
  };

  return (
    <Box>
      <Typography variant="h5">{getWizard("grocery").copy.title}</Typography>

      <Stack
        spacing={2}
        sx={{ marginTop: "1rem" }}
      >
        {getWizard("grocery").copy.instructions.map((instruction, index) => (
          <Typography key={`instruction-${index}`}>{instruction}</Typography>
        ))}

        <OFDFieldContainer>
          <OFDSelect
            id="groceryLists"
            label="groceryLists"
            value={value || "skip"}
            onChange={(newValue) => handleGrocerySelection(newValue)}
            options={options}
            fullWidth
          />
        </OFDFieldContainer>
      </Stack>
    </Box>
  );
};

export default GroceryCopyList;
