import { useState } from "react";

import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../../ui/OFDIcon";
import GroceryItem from "./GroceryItem";

const DepartmentItems = ({ departmentItems }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "auto",
            backgroundColor: "#fff",
            padding: "4px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: open ? "0px" : "8px",
            borderBottomRightRadius: open ? "0px" : "8px",
          }}
          onClick={() => setOpen(!open)}
        >
          <Typography>{`${departmentItems.department} (${departmentItems.items.length})`}</Typography>
          <OFDIcon
            name={open ? "arrowUp" : "arrowDown"}
            color="grey"
            onClick={() => setOpen(!open)}
          />
        </Box>

        {open ? (
          <Box
            sx={{
              paddingTop: ".5rem",
              paddingBottom: ".5rem",
              backgroundColor: "#fff",
              borderTop: "1px solid #ccc",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          >
            <Stack spacing={1}>
              {departmentItems.items.map((groceryItem) => (
                <GroceryItem
                  key={groceryItem.id}
                  groceryItem={groceryItem}
                />
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default DepartmentItems;
