import { useEffect, useState, useRef } from "react";
import { startOfToday, isBefore } from "date-fns";

import { Box, Stack, Typography } from "@mui/material";

import useForm from "../../hooks/useForm";
import useEntity from "../../hooks/useEntity";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useToDo from "../../hooks/useToDo";
import useCalendar from "../../hooks/useCalendar";
import useApi from "../../hooks/useApi";
import useWindow from "../../hooks/useWindow";
import useSecurity from "../../hooks/useSecurity";

import OFDModal from "../layout/OFDModal";
import OFDMessage from "../ui/OFDMessage";
import OFDDatetime from "../ui/OFDDatetime";
import OFDToolbar from "../layout/OFDToolbar";
import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDSelect from "../ui/OFDSelect";
import OFDDisplayCategory from "../ui/OFDDisplayCategory";
import OFDDisplayMembers from "../ui/OFDDisplayMembers";
import OFDDateRange from "../ui/OFDDateRange";
import OFDTime from "../ui/OFDTime";
import OFDDate from "../ui/OFDDate";
import OFDDialog from "../layout/OFDDialog";
import OFDFieldContainer from "../ui/OFDFieldContainer";
import OFDSwitch from "../ui/OFDSwitch";
import OFDContextHelp from "../ui/OFDContextHelp";
import SeriesFilter from "./SeriesFilter";
import LoadingModal from "../layout/LoadingModal";
import OFDMonthDay from "../ui/OFDMonthDay";
import OFDSecurity from "../ui/OFDSecurity";

import FieldEditorModal from "./FieldEditorModal";

import OFDWeekySchedule from "../ui/OFDWeeklySchedule";
import OFDMonthlySchedule from "../ui/OFDMonthlySchedule";
import OFDMultiDatePicker from "../ui/OFDMultiDatePicker";

import OFDRecurrence from "../ui/OFDRecurrence";

const initialRecurrence = {
  rrule: null,
  occurrenceStart: null,
  frequency: "onetime",
  interval: null,
  count: null,
  until: null,
  forever: false,
  byDay: null,
  bySetPosition: null,
  byMonthDay: null,
  byMonth: null,
  monthlyType: "relative",
  scheduleDates: null,
};

const ToDoEditor = ({
  entityData,
  calendar,
  open,
  onClose,
  onSave,
  option,
  selectedDate,
}) => {
  const { entityConfig, defaultValues } = useEntity("ToDo");
  const { validateToDo, transformToDo } = useToDo();
  const { getEntityDates } = useCalendar();
  const { getLabel, getText } = useLocalization();
  const { member } = useMember();
  const { getData } = useApi();
  const { isDesktop, windowHeight } = useWindow();
  const { pageDefaultSecurity } = useSecurity();

  const {
    data,
    setData,

    setFieldValue,
    getFieldValue,
    getFieldMessage,
    getFieldLabel,
    getFieldError,
    getDataById,

    getDailyFields,
    handleFrequencyChange,
    handleDailyChange,
    getWeeklyFields,
    handleWeeklyChange,
    getMonthlyFields,
    handleMonthlyChange,
    handleDateChange,

    saveData,
    formMessage,
    formStatus,
    resetFormMessage,
    resetFieldMessages,
    setFieldMessages,
    setFormMessage,
    validate,
  } = useForm("ToDo");

  const [categorySelector, setCategorySelector] = useState(false);
  const [memberSelector, setMemberSelector] = useState(false);

  const [todo, setToDo] = useState(null);

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [fieldsHeight, setFieldsHeight] = useState(null);

  const [seriesFilter, setSeriesFilter] = useState(null);

  const [recurrence, setRecurrence] = useState({
    ...initialRecurrence,
    occurrenceStart: selectedDate ? selectedDate : startOfToday(),
  });

  const [fieldErrors, setFieldErrors] = useState(null);

  const containerRef = useRef();
  const toolbarRef = useRef();

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setFieldsHeight(containerRef.current.clientHeight - 100);
  }, [containerRef]);

  useEffect(() => {
    if (open) {
      resetFormMessage();
      resetFieldMessages();
    }

    if (entityData) {
      setData(entityData);
    } else if (calendar) {
      getToDo();
    } else {
      setData({
        ...defaultValues,
        frequency: "onetime",
        members: [member.id],
        category: "todo",
        security: pageDefaultSecurity("ToDo"),
      });
    }
  }, [todo, calendar, open]);

  const getToDo = async () => {
    const results = await getData({
      entityName: "ToDo",
      action: "get",
      id: calendar.source.id,
    });

    if (results.isSuccessful) {
      if (option === "occurrence") {
        setData({
          ...results.data,
          originalStartDate: calendar.startDate,
          startDate: calendar.startDate,
          description: calendar.description,
          category: calendar.category,
          members: calendar.members,
          security: calendar.security,
        });
      } else {
        setData({ ...results.data });
        if (option === "series") {
          let filterStartDate = startOfToday();
          if (isBefore(filterStartDate, results.data.startDate)) {
            filterStartDate = results.data.startDate;
          }
          setRecurrence(results.data.recurrence);
        }
      }
    } else {
      setData(null);
    }
  };

  const handleToolbarClick = async (name) => {
    switch (name) {
      case "save":
        await saveToDo();
        break;
    }
  };

  const handleMemberSelector = () => {
    setMemberSelector(true);
  };

  const handleCancelMembers = () => {
    setMemberSelector(false);
  };

  const handleChangeMembers = (newValue) => {
    setMemberSelector(false);
    setFieldValue("members", newValue);
  };

  const saveToDo = async () => {
    await saveConfirmation("yes");

    // if (option === "series" && data.overridden) {
    //   setOpenSaveDialog(true);
    // } else {
    //   await saveConfirmation("yes");
    // }
  };

  const saveConfirmation = async (response) => {
    setOpenSaveDialog(false);
    setOpenLoading(true);

    if (response === "no") {
      return;
    }

    resetFormMessage();
    resetFieldMessages();

    let newData = transformToDo(data, option);
    const validationResults = validate(validateToDo, newData);

    if (!validationResults.isSuccessful) {
      setOpenLoading(false);

      setFormMessage(validationResults);
      setFieldMessages(validationResults.fieldErrors);
      return;
    }

    let action = data.id ? `updateToDo` : `addToDo`;

    let options = null;
    options = {
      option: option ? option : null,
      calendarId: calendar ? calendar.id : null,
      seriesFilter,
    };

    await saveData({
      action,
      data: newData,
      options,
    });

    setOpenLoading(false);

    onSave();
  };

  const handleCategorySelector = () => {
    setCategorySelector(true);
  };

  const handleCancelCategory = () => {
    setCategorySelector(false);
  };

  const handleChangeCategory = (newValue) => {
    setCategorySelector(false);
    setFieldValue("category", newValue);
  };

  const handleRecurrenceChange = (newValue) => {
    setRecurrence(newValue);
    setFieldValue("recurrence", newValue);
    setFieldValue("frequency", newValue.frequency);

    if (!data.id && newValue.frequency !== "schedule") {
      setFieldValue("startDate", newValue.occurrenceStart);
    }
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="todo"
      >
        <Box
          ref={containerRef}
          sx={{ height: `${windowHeight - 64}px` }}
        >
          <OFDToolbar
            sticky={true}
            menuItems={[{ name: "save", label: "save", icon: "save" }]}
            onClick={handleToolbarClick}
          />

          {option === "series" && data?.id ? (
            <OFDFieldContainer
              sx={{
                padding: "1rem",
                margin: "1rem",
                paddingBottom: "2rem",
              }}
            >
              <SeriesFilter
                id="seriesFilter"
                startDate={data.startDate}
                endDate={data.endDate}
                filter={seriesFilter}
                onChange={(newValue) => setSeriesFilter(newValue)}
              />
            </OFDFieldContainer>
          ) : null}

          <OFDFieldContainer
            sx={{
              height: fieldsHeight ? `${fieldsHeight}px` : "85%",
              overflow: "auto",
              padding: "1rem",
              margin: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <Stack spacing={2}>
              {getFieldValue("frequency") !== "onetime" ||
              option === "series" ||
              !option ? (
                <OFDRecurrence
                  value={recurrence}
                  onChange={handleRecurrenceChange}
                  disabled={calendar ? true : false}
                  fieldErrors={fieldErrors}
                />
              ) : null}

              {getFieldValue("frequency") === "onetime" ||
              option === "occurrence" ? (
                <OFDDate
                  id="startDate"
                  label="dueDate"
                  value={getFieldValue("startDate")}
                  onChange={(newValue) => setFieldValue("startDate", newValue)}
                  required={true}
                  message={getFieldMessage("startDate")}
                  error={getFieldError("startDate")}
                />
              ) : null}

              <OFDTextField
                id="name"
                label="subject"
                value={getFieldValue("name")}
                onChange={(newValue) => setFieldValue("name", newValue)}
                required={true}
                message={getFieldMessage("name")}
                error={getFieldError("name")}
              />

              <OFDTextNote
                id="description"
                label="description"
                value={getFieldValue("description")}
                onChange={(newValue) => setFieldValue("description", newValue)}
              />

              <OFDDisplayCategory
                label="category"
                value={getFieldValue("category")}
                onClick={handleCategorySelector}
              />

              <OFDDisplayMembers
                label="familyMembers"
                value={getFieldValue("members")}
                onClick={handleMemberSelector}
                message={getFieldMessage("members")}
                error={getFieldError("members")}
              />

              <Box
                sx={{
                  paddingLeft: ".5rem",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: ".5rem",
                }}
              >
                <Box sx={{ paddingBottom: ".5rem" }}>
                  <Typography variant="overline">
                    {getLabel("security")}
                  </Typography>
                </Box>

                <OFDSecurity
                  value={
                    getFieldValue("security") || {
                      ...pageDefaultSecurity("Calendar"),
                    }
                  }
                  onChange={(newValue) => setFieldValue("security", newValue)}
                  show={{
                    view: true,
                    add: false,
                    update: true,
                    delete: true,
                  }}
                />
              </Box>
            </Stack>
          </OFDFieldContainer>
        </Box>
      </OFDModal>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <FieldEditorModal
        entityName="ToDo"
        entityData={data}
        field={{
          id: "members",
          label: "members",
          icon: "family",
          fieldType: "members",
        }}
        open={memberSelector}
        onCancel={handleCancelMembers}
        onChange={handleChangeMembers}
        returnValue
      />

      <FieldEditorModal
        entityName="ToDo"
        entityData={data}
        field={{
          id: "category",
          label: "category",
          icon: "category",
          fieldType: "category",
        }}
        open={categorySelector}
        onCancel={handleCancelCategory}
        onChange={handleChangeCategory}
        returnValue
      />

      <OFDDialog
        open={openSaveDialog}
        title="confirmSaveSeries"
        description="confirmSaveSeries"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={saveConfirmation}
      />

      <LoadingModal open={openLoading} />
    </>
  );
};

export default ToDoEditor;
