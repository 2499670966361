import { useEffect, useState } from "react";

import useMember from "../../hooks/useMember";
import useNotifications from "../../hooks/useNotifications";
import useLocalization from "../../hooks/useLocalization";
import usePage from "../../hooks/usePage";
import useForm from "../../hooks/useForm";
import useMessage from "../../hooks/useMessage";
import useApi from "../../hooks/useApi";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";

import OFDTitle from "../layout/OFDTitle";
import OFDContainer from "../ui/OFDContainer";
import OFDSwitch from "../ui/OFDSwitch";
import OFDPhone from "../ui/OFDPhone";
import OFDEmail from "../ui/OFDEmail";
import OFDMessage from "../ui/OFDMessage";
import OFDPasswordField from "../ui/OFDPasswordField";
import OFDButton from "../ui/OFDButton";
import OFDFieldGroup from "../ui/OFDFieldGroup";
import OFDTextField from "../ui/OFDTextField";
import OFDToolbar from "../layout/OFDToolbar";

const MyAccount = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const {
    notificationsAvailable,
    askForNotificationPermission,
    updateMemberSubscription,
  } = useNotifications();
  const {
    member,
    memberPreferences,
    verifyPassword,
    validatePassword,
    updatePassword,
    getMemberState,
    defaultMemberPreferences,
  } = useMember();
  const { getMessage, getLabel } = useLocalization();
  const {
    data,
    setData,
    saveData,
    formStatus,
    formMessage,
    resetFormMessage,
    setFormMessage,
  } = useForm("Member");
  const { getData, postData } = useApi();

  const { destructureResults } = useMessage();

  const [preferences, setPreferences] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPasswordMessage, setCurrentPasswordMessage] = useState();
  const [newPasswordMessage, setNewPasswordMessage] = useState();
  const [pin, setPin] = useState(null);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [pinMessage, setPinMessage] = useState("pinReset");

  useEffect(() => {
    setEntityName("MyAccount");
    // setPreferences(null);
    // setPreferences(memberPreferences);
    getMemberPreferences();
  }, [refresh]);

  const getMemberPreferences = async () => {
    const results = await getData({
      entityName: "Member",
      action: "get",
      id: member.id,
    });

    if (results.isSuccessful && results.data) {
      if (results.data.preferences) {
        setPreferences(results.data.preferences);
      } else {
        setPreferences(defaultMemberPreferences);
      }
    }
  };

  useEffect(() => {
    if (toolbarAction === "save") {
      savePreferences();
    }
    resetToolbarAction();
  }, [toolbarAction]);

  const savePreferences = async () => {
    const args = {
      data: {
        id: member.id,
        preferences,
      },
    };

    const results = await saveData(args);

    if (results.isSuccessful) {
      await getMemberState();
    }
  };

  const getNotificationEnabled = (field) => {
    if (preferences.notifications.hasOwnProperty(field)) {
      return preferences.notifications[field].enabled;
    } else {
      return false;
    }
  };

  const setNotificationEnabled = async (field, newValue) => {
    if (field === "push") {
      if (newValue) {
        await askForNotificationPermission();
      } else {
        await updateMemberSubscription(null);
      }
    }

    setPreferences((current) => {
      let newNotifications;

      if (current && current.notifications) {
        newNotifications = {
          ...current.notifications,
          [field]: {
            enabled: newValue,
            value: current.notifications.hasOwnProperty(field)
              ? current.notifications[field].value
              : null,
          },
        };
      } else {
        newNotifications = {
          ...defaultMemberPreferences.notifications,
          [field]: {
            enabled: newValue,
            value: defaultMemberPreferences.notifications[field].value,
          },
        };
      }

      let preferences = current ? current : defaultMemberPreferences;

      return { ...preferences, notifications: { ...newNotifications } };
    });
  };

  const getNotificationValue = (field) => {
    if (preferences.notifications.hasOwnProperty(field)) {
      return preferences.notifications[field].value;
    } else {
      return null;
    }
  };

  const setNotificationValue = (field, newValue) => {
    setPreferences((current) => {
      let newNotifications = {
        ...current.notifications,
        [field]: {
          enabled: current.notifications[field].enabled,
          value: newValue,
        },
      };

      return { ...current, notifications: { ...newNotifications } };
    });
  };

  const handleChangePassword = () => {
    if (!changePassword) {
      setCurrentPasswordMessage("");
      setNewPasswordMessage("");
      setCurrentPassword("");
      setNewPassword("");
    }
    setChangePassword(!changePassword);
  };

  const handleSavePassword = async () => {
    setCurrentPasswordMessage("");
    setNewPasswordMessage("");

    const verified = await verifyPassword(currentPassword);

    if (!verified) {
      setCurrentPasswordMessage("incorrectPassword");
      return;
    }

    const valid = await validatePassword(newPassword);

    if (!valid) {
      setNewPasswordMessage("passwordWeak");
      return;
    }

    const results = await updatePassword(newPassword);
    setFormMessage(destructureResults(results));
    handleChangePassword();
  };

  const handleSavePin = async () => {
    setPassword(null);
    setPasswordMessage(null);
    setOpenPasswordDialog(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
    setPassword(null);
  };

  const handleVerifyPassword = async () => {
    setPasswordMessage(null);

    if (!password) {
      setPasswordMessage("invalidPassword");
      return;
    }

    const verify = await postData({
      entityName: "Member",
      action: "verifyPassword",
      data: {
        id: member.id,
        password,
      },
    });

    if (!verify.isSuccessful || !verify.data.verified) {
      setPasswordMessage("invalidPassword");
      return;
    }

    const update = await postData({
      entityName: "Member",
      action: "setPin",
      data: {
        pin,
      },
    });

    if (!update.isSuccessful) {
      setPasswordMessage(update.message);
      return;
    }

    setPinMessage("pinResetSuccessful");
    setOpenPasswordDialog(false);
    setPassword(null);
  };

  if (!preferences) return null;

  return (
    <>
      <Box>
        <OFDTitle title="myAccount" />

        {/* PASSWORD */}
        <OFDContainer
          label="password"
          sx={{ marginTop: "1rem" }}
        >
          <OFDButton
            label="changePassword"
            width="auto"
            variant="contained"
            onClick={handleChangePassword}
          />

          {changePassword ? (
            <Stack
              spacing={1}
              sx={{ marginTop: "1rem" }}
            >
              <OFDPasswordField
                id="currentPassword"
                label="currentPassword"
                value={currentPassword}
                onChange={(newValue) => setCurrentPassword(newValue)}
                message={currentPasswordMessage}
                fullWidth
              />
              <OFDPasswordField
                id="newPassword"
                label="newPassword"
                value={newPassword}
                onChange={(newValue) => setNewPassword(newValue)}
                message={newPasswordMessage}
                fullWidth
              />
              <OFDFieldGroup
                justifyContent="flex-end"
                sx={{ marginTop: ".5rem" }}
              >
                <OFDButton
                  label="save"
                  iconName="save"
                  width="auto"
                  variant="contained"
                  onClick={handleSavePassword}
                />
              </OFDFieldGroup>
            </Stack>
          ) : null}
        </OFDContainer>

        {/* PIN */}
        <OFDContainer
          label="PIN"
          sx={{ marginTop: "1rem" }}
        >
          <OFDFieldGroup>
            <OFDTextField
              id="pin"
              label="PIN Code"
              value={pin}
              onChange={(newValue) => setPin(newValue)}
              message={pinMessage}
            />

            <OFDButton
              label="save"
              variant="contained"
              onClick={handleSavePin}
            />
          </OFDFieldGroup>
        </OFDContainer>

        {/* NOTIFICATIONS */}
        <OFDContainer
          label="notifications"
          sx={{ marginTop: "1rem" }}
        >
          <Stack spacing={1}>
            <Box>
              <OFDSwitch
                id="app"
                value={getNotificationEnabled("app")}
                label="app"
                onChange={(newValue) => setNotificationEnabled("app", newValue)}
              />

              <Box></Box>
            </Box>

            {notificationsAvailable ? (
              <Box>
                <OFDSwitch
                  id="push"
                  value={getNotificationEnabled("push")}
                  label="push"
                  onChange={(newValue) =>
                    setNotificationEnabled("push", newValue)
                  }
                />

                <Box></Box>
              </Box>
            ) : null}

            <Box>
              <OFDSwitch
                id="sms"
                value={getNotificationEnabled("sms")}
                label="sms"
                onChange={(newValue) => setNotificationEnabled("sms", newValue)}
              />

              {getNotificationEnabled("sms") ? (
                <Box sx={{ marginLeft: "3rem" }}>
                  <OFDPhone
                    id="sms"
                    label="cell"
                    value={getNotificationValue("sms")}
                    onChange={(newValue) =>
                      setNotificationValue("sms", newValue)
                    }
                  />
                </Box>
              ) : null}
            </Box>

            <Box>
              <OFDSwitch
                id="email"
                value={getNotificationEnabled("email")}
                label="email"
                onChange={(newValue) =>
                  setNotificationEnabled("email", newValue)
                }
              />

              {getNotificationEnabled("email") ? (
                <Box sx={{ marginLeft: "3rem" }}>
                  <OFDEmail
                    id="email"
                    label="email"
                    value={getNotificationValue("email")}
                    onChange={(newValue) =>
                      setNotificationValue("email", newValue)
                    }
                    fullWidth
                  />
                </Box>
              ) : null}
            </Box>
          </Stack>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <OFDButton
              name="save"
              label="save"
              variant="contained"
              onClick={savePreferences}
            />
          </Box>
        </OFDContainer>
      </Box>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      {openPasswordDialog ? (
        <Dialog
          open={openPasswordDialog}
          onClose={handleClosePasswordDialog}
        >
          <DialogTitle>{getLabel("enterPassword")}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: "1rem" }}>
              {getMessage("passwordNeededToChangePin")}
            </DialogContentText>

            <OFDPasswordField
              label="password"
              value={password}
              onChange={(newValue) => setPassword(newValue)}
              message={passwordMessage}
              error={Boolean(passwordMessage)}
            />
          </DialogContent>
          <DialogActions>
            <OFDButton
              variant="outlined"
              label="cancel"
              onClick={handleClosePasswordDialog}
            />
            <OFDButton
              variant="contained"
              label="continue"
              onClick={handleVerifyPassword}
            />
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default MyAccount;
