import { useEffect, useState } from "react";
import {
  startOfToday,
  startOfWeek,
  addWeeks,
  subWeeks,
  format,
  parse,
} from "date-fns";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDIconButton from "./OFDIconButton";

const OFDWeekOf = ({ value, onChange }) => {
  const { getLabel } = useLocalization();

  const handlePrevWeek = () => {
    onChange(subWeeks(value, 1));
  };

  const handleNextWeek = () => {
    onChange(addWeeks(value, 1));
  };

  return (
    <>
      <Box sx={{ marginTop: ".5rem" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="overline"
            sx={{ marginBottom: "-.5rem" }}
          >
            {getLabel("weekOf")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          gap={2}
        >
          <OFDIconButton
            name="arrowLeft"
            onClick={handlePrevWeek}
          />
          <Typography variant="h6">{format(value, "MMMM dd, yyyy")}</Typography>
          <OFDIconButton
            name="arrowRight"
            onClick={handleNextWeek}
          />
        </Box>
      </Box>
    </>
  );
};

export default OFDWeekOf;
