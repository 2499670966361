import React, { useEffect, useState, useRef } from "react";

// Material UI
import { Box } from "@mui/material";

const OFDPictureUpload = ({ open, onUpload }) => {
  const [image, setImage] = useState(null);
  const [base64, setBase64] = useState(null);

  const cameraRef = useRef(null);
  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (open) {
      cameraRef.current.click();
    }
  }, []);

  const capturePicture = () => {
    let camera = cameraRef.current;

    setImage(camera.files[0]);
  };

  const convertFileToBase64 = () => {
    let canvas = canvasRef.current;
    let img = imageRef.current;

    let width = img.width;
    let height = img.height;
    let ratio = width / height;

    let newWidth = 800;
    let newHeight = 800 / ratio;

    canvas.width = newWidth;
    canvas.height = newHeight;
    let context = canvas.getContext("2d");
    context.drawImage(img, 0, 0, newWidth, newHeight);

    let dataUrl = canvas.toDataURL(image.type);

    setBase64(dataUrl);
  };

  useEffect(() => {
    if (!base64) return;

    onUpload(base64);
  }, [base64]);

  return (
    <>
      <Box>
        <Box sx={{ marginBottom: "1rem" }}>
          <input
            style={{ display: "none" }}
            ref={cameraRef}
            type="file"
            name="image"
            accept="image/*"
            onChange={capturePicture}
          />
        </Box>

        <canvas
          ref={canvasRef}
          style={{ display: "none" }}
        ></canvas>

        <Box sx={{ display: "none" }}>
          <img
            ref={imageRef}
            src={image ? window.URL.createObjectURL(image) : null}
            onLoad={convertFileToBase64}
          />
        </Box>
      </Box>
    </>
  );
};

export default OFDPictureUpload;
