import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useFamily from "../../hooks/useFamily";
import useCalendar from "../../hooks/useCalendar";
import useICal from "../../hooks/useICal";
import useQueue from "../../hooks/useQueue";

import useGoogle from "../../hooks/useGoogle";
import useApple from "../../hooks/useApple";

import { Box, Stack, Typography, Tab, Tabs } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDTabPanel from "../layout/OFDTabPanel";
import LoadingModal from "../layout/LoadingModal";
import OFDMessage from "../ui/OFDMessage";
import FieldEditorModal from "./FieldEditorModal";
import OFDToolbar from "../layout/OFDToolbar";

import OFDFileUploader from "../ui/OFDFileUploader";
import OFDButton from "../ui/OFDButton";
import OFDTextField from "../ui/OFDTextField";
import OFDDisplayAttendees from "../ui/OFDDisplayAttendees";
import OFDDisplayCategory from "../ui/OFDDisplayCategory";

const CalendarImport = ({ open, onClose }) => {
  const { addToQueue } = useQueue();

  const { getTitle, getLabel, getMessage, getText } = useLocalization();
  const { memberColor, member } = useMember();
  const { family } = useFamily();
  const { importCalendar, saveLink } = useCalendar();
  const { testICalLink } = useICal();
  const {
    authorizeClient,
    events,
    respError,
    signout,
    requestConfig,
    googleReady,
  } = useGoogle("calendar");

  const {
    getCalendar,
    isApple,
    iOSPushCapability,
    eventsReceived,
    appleEvents,
  } = useApple();

  const [tabIndex, setTabIndex] = useState(0);

  const [showLoading, setShowLoading] = useState(false);
  const [importMessage, setImportMessage] = useState(null);

  const [iCalLink, setICalLink] = useState("");
  const [iCalLinkMessage, setICalLinkMessage] = useState(null);
  const [iCalLinkVerified, setICalLinkVerified] = useState(false);
  const [iCalError, setICalError] = useState(false);
  const [iCalMessage, setICalMessage] = useState(null);

  const [importFilename, setImportFilename] = useState("");
  const [fileType, setFileType] = useState(null);
  const [importFileError, setImportFileError] = useState(false);
  const [importFileUploaded, setImportFileUploaded] = useState(false);

  const [attendeeEditor, setAttendeeEditor] = useState(false);
  const [categorySelector, setCategorySelector] = useState(false);

  const [iCalName, setICalName] = useState("");
  const [iCalDates, setICalDates] = useState(null);
  const [iCalEventCount, setICalEventCount] = useState(0);
  const [category, setCategory] = useState(null);
  const [attendees, setAttendees] = useState([]);

  const [source, setSource] = useState("iCal");

  const [fileData, setFileData] = useState(null);

  const [debug, setDebug] = useState([]);

  const tabStyle = {
    "& .MuiTabs-indicator": {
      backgroundColor: memberColor().backgroundColor,
    },
    "& .MuiTab-root.Mui-selected": {
      color: memberColor().backgroundColor,
    },
  };

  useEffect(() => {
    setAttendees([{ entityName: "Member", id: member.id }]);
  }, [member]);

  const addDebug = (message) => {
    setDebug([...debug, message]);
  };

  const handleTabChange = (e, newTabIndex) => {
    switch (newTabIndex) {
      case 0:
        setSource("iCal");
        break;

      case 1:
        setSource("iCalLink");
        break;

      case 2:
        if (isApple) {
          setSource("apple");
        } else {
          setSource("google");
        }
        break;
    }
    setTabIndex(newTabIndex);
  };

  const handleTestICalLink = async () => {
    if (!iCalLink) return;

    const results = await testICalLink(iCalLink);

    if (results.isSuccessful) {
      setICalLinkVerified(true);
      setICalError(false);
      setICalName(results.data.name);
      setICalDates(results.data.dates);
      setICalEventCount(results.data.eventCount);
    } else {
      setICalLinkVerified(false);
      setICalError(true);
    }
  };

  const handleFileUpload = (file) => {
    setFileType(null);
    if (file.type === "text/calendar") {
      setFileType(file.type);
      setImportFileUploaded(true);
      setImportFileError(false);
      setImportFilename(file.name);
      convertFileToText(file);
    } else {
      setImportFileError(true);
      setImportFileUploaded(false);
      setImportFilename(getMessage("invalidCalendarFile"));
    }
  };

  const convertFileToText = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setFileData(reader.result);
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const handleImportFile = async () => {
    setShowLoading(true);
    const results = await importCalendar(fileData, fileType);
    setShowLoading(false);

    setImportMessage(results);
  };

  const handleSaveLink = async () => {
    setShowLoading(true);

    const results = await saveLink({
      iCalLink,
      category,
      attendees,
    });

    setShowLoading(false);

    setImportMessage(results);
  };

  const handleCategorySelector = () => {
    setCategorySelector(true);
  };

  const handleCancelCategory = () => {
    setCategorySelector(false);
  };

  const handleChangeCategory = (newValue) => {
    setCategorySelector(false);
    setCategory(newValue);
  };

  const handleAttendeeEditor = () => {
    setAttendeeEditor(true);
  };

  const handleCancelAttendees = () => {
    setAttendeeEditor(false);
  };

  const handleChangeAttendees = (newValue) => {
    setAttendeeEditor(false);
    setAttendees(newValue);
  };

  const handleToolbarClick = async (action) => {
    if (action === "save") {
      await importEvents();
      onClose();
    }
  };

  const importEvents = async () => {
    addDebug("importEvents");
    if (Array.isArray(events) && events.length > 0) {
      addDebug(`events : ${events.length}`);
    }
    if (Array.isArray(appleEvents) && appleEvents.length > 0) {
      addDebug(`appleEvents : ${appleEvents.length}`);
    }

    let successful = false;

    let results = null;

    if (Array.isArray(events) && events.length > 0) {
      results = await addToQueue("calendarImport", {
        familyId: family.id,
        memberId: member.id,
        source,
        events,
      });

      // successful = await importICalEvents(events);
    } else if (Array.isArray(appleEvents) && appleEvents.length > 0) {
      results = await addToQueue("calendarImport", {
        familyId: family.id,
        memberId: member.id,
        source,
        events: appleEvents,
      });

      // successful = await importICalEvents(appleEvents);
    } else if (fileData) {
      results = await addToQueue("calendarImport", {
        familyId: family.id,
        memberId: member.id,
        source,
        iCalData: fileData,
      });
    }
    addDebug(`results: ${results.isSuccessful} : ${results.message}`);

    if (successful) {
      onClose();
    }
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={getTitle("calendarImport")}
      >
        <Box>
          <OFDToolbar
            menuItems={[{ name: "save", label: "save", icon: "save" }]}
            onClick={handleToolbarClick}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              sx={tabStyle}
            >
              <Tab label={getLabel("uploadFile")} />
              <Tab label={getLabel("sync")} />
              <Tab label={getLabel("myDevice")} />
            </Tabs>
          </Box>

          <OFDTabPanel
            value={0}
            index={tabIndex}
          >
            <Stack
              spacing={2}
              sx={{ padding: "1rem" }}
            >
              <Box>
                <Typography>{getText("calendarUpload")}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <OFDFileUploader onUpload={handleFileUpload} />

                <Typography>{importFilename}</Typography>
              </Box>

              {fileData ? (
                <Box>
                  <OFDButton
                    name="upload"
                    label="upload"
                    onClick={() => importEvents("iCal")}
                    variant="contained"
                  />
                </Box>
              ) : null}
            </Stack>
          </OFDTabPanel>

          <OFDTabPanel
            value={1}
            index={tabIndex}
          >
            <Stack
              spacing={2}
              sx={{ padding: "1rem" }}
            >
              <Box>
                <Typography>{getText("calendarLink")}</Typography>
              </Box>

              <Stack spacing={1}>
                <OFDTextField
                  id="iCalLink"
                  label="externalUrl"
                  value={iCalLink}
                  onChange={(newValue) => setICalLink(newValue)}
                  message={iCalLinkMessage}
                  error={true}
                />

                {!iCalLinkVerified ? (
                  <OFDButton
                    id="textiCalLink"
                    label="testLink"
                    variant="contained"
                    onClick={handleTestICalLink}
                  />
                ) : (
                  <Stack spacing={2}>
                    <Box
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "10px",
                        padding: "1rem",
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        align="center"
                      >
                        {iCalName}
                      </Typography>

                      <Typography align="center">
                        {`${
                          iCalDates?.startDate
                            ? format(iCalDates.startDate, "MMM dd, yyyy")
                            : ""
                        } to ${
                          iCalDates?.endDate
                            ? format(iCalDates.endDate, "MMM dd, yyyy")
                            : ""
                        }`}
                      </Typography>

                      <Typography align="center">{`${iCalEventCount} events`}</Typography>
                    </Box>

                    <OFDDisplayCategory
                      label="category"
                      value={category}
                      onClick={handleCategorySelector}
                    />

                    <OFDDisplayAttendees
                      label="attendees"
                      value={attendees}
                      onClick={handleAttendeeEditor}
                    />

                    <OFDButton
                      id="save"
                      label="import"
                      iconName="upload"
                      variant="contained"
                      onClick={handleSaveLink}
                    />
                  </Stack>
                )}

                {iCalError ? (
                  <Typography>{getMessage(iCalMessage)}</Typography>
                ) : null}
              </Stack>
            </Stack>
          </OFDTabPanel>

          <OFDTabPanel
            value={2}
            index={tabIndex}
          >
            {!isApple ? (
              <Box sx={{ padding: "1rem" }}>
                {events === undefined ? (
                  <Stack spacing={2}>
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {getText("googleCalendarImport")}
                    </Typography>
                    <OFDButton
                      label={getLabel("continue")}
                      onClick={() => authorizeClient("calendar")}
                      disabled={!googleReady}
                    />
                  </Stack>
                ) : (
                  <Stack spacing={2}>
                    <Typography>{`${events.length} ${getLabel(
                      "eventsToImport"
                    )} `}</Typography>

                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {getText("completeImport")}
                    </Typography>
                  </Stack>
                )}
              </Box>
            ) : (
              <Box sx={{ padding: "1rem" }}>
                {appleEvents === undefined ? (
                  <Stack>
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {getText("appleCalendarImport")}
                    </Typography>
                    <OFDButton
                      label={getLabel("continue")}
                      onClick={getCalendar}
                    />
                  </Stack>
                ) : (
                  <Box>
                    <Typography>{`${appleEvents.length} ${getLabel(
                      "eventsToImport"
                    )}`}</Typography>

                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {getText("completeImport")}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </OFDTabPanel>

          <Stack sx={{ marginTop: "1rem" }}>
            {debug.map((item, index) => (
              <Typography key={`debug_${index}`}>{item}</Typography>
            ))}
          </Stack>
        </Box>
      </OFDModal>

      <LoadingModal open={showLoading} />

      <OFDMessage
        message={importMessage}
        onClose={onClose}
      />

      <FieldEditorModal
        entityName="Appointment"
        entityData={{ attendees }}
        field={{
          id: "attendees",
          label: "attendees",
          icon: "members",
          fieldType: "attendees",
        }}
        open={attendeeEditor}
        onCancel={handleCancelAttendees}
        onChange={handleChangeAttendees}
        returnValue
      />

      <FieldEditorModal
        entityName="Appointment"
        entityData={{
          category,
        }}
        field={{
          id: "category",
          label: "category",
          icon: "category",
          fieldType: "category",
        }}
        open={categorySelector}
        onCancel={handleCancelCategory}
        onChange={handleChangeCategory}
        returnValue
      />
    </>
  );
};

export default CalendarImport;
