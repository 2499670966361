import { useEffect, useState } from "react";

import useColor from "../../hooks/useColor";

import { Box, Typography } from "@mui/material";

import OFDCheckBox from "../ui/OFDCheckBox";
import OFDDisplayGroceryItem from "../ui/OFDDisplayGroceryItem";

const GroceryItemShop = ({ groceryItem, onChange }) => {
  const handleClick = () => {
    if (!groceryItem.purchased) {
      onChange({ ...groceryItem, purchased: true });
      return;
    }
    onChange({ ...groceryItem, purchased: !groceryItem.purchased });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <OFDCheckBox
        value={Boolean(groceryItem.purchased)}
        onChange={handleClick}
      />
      <OFDDisplayGroceryItem
        value={groceryItem}
        onClick={handleClick}
      />
    </Box>
  );
};

export default GroceryItemShop;
