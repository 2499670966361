import { useEffect, useState, useRef } from "react";

import useData from "./useData";

const useGoogle = (resource) => {
  const gapi = window.gapi;
  const google = window.google;

  const DISCOVERY_DOC = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
  ];
  const SCOPES =
    "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly";
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const tokenClient = useRef();

  let requestConfig = {
    calendarId: "primary",
    timeMin: new Date().toISOString(),
    showDeleted: false,
  };

  const [respError, setRespError] = useState();
  const [events, setEvents] = useState(undefined);
  const [gapiInited, setGapiInited] = useState(false);
  const [gisInited, setGisInited] = useState(false);
  const [googleReady, setGoogleReady] = useState(false);
  const [importedContacts, setImportedContacts] = useState(undefined);

  const { newId } = useData();

  useEffect(() => {
    gapiLoaded();
    gisLoaded();
  }, []);

  useEffect(() => {
    setGoogleReady(gisInited && gapiInited);
  }, [gapiInited, gisInited]);

  const gapiLoaded = () => {
    gapi.load("client", initializeGapiClient);
  };

  const initializeGapiClient = async () => {
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: DISCOVERY_DOC,
    });

    setGapiInited(true);
  };

  const gisLoaded = () => {
    if (!CLIENT_ID) return;

    tokenClient.current = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: "",
    });

    setGisInited(true);
  };

  const authorizeClient = () => {
    tokenClient.current.callback = async (resp) => {
      if (resp.error !== undefined) {
        setRespError(resp);
      }

      await getResources();
    };

    if (gapi.client.getToken() === null) {
      tokenClient.current.requestAccessToken({ prompt: "consent" });
    } else {
      tokenClient.current.requestAccessToken({ prompt: "" });
    }
  };

  const getResources = async () => {
    if (resource === "calendar") {
      await getEvents();
    } else if (resource === "contacts") {
      await getContacts();
    }
  };

  const signout = () => {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken("");
    }
  };

  const getEvents = async () => {
    let response;
    try {
      response = await gapi.client.calendar.events.list(requestConfig);
    } catch (err) {
      console.log(err);
      return;
    }

    const items = response.result.items;

    if (!items || items.length === 0) {
      setEvents([]);
    } else {
      setEvents(items);
    }
  };

  const getContacts = async () => {
    let response;

    try {
      response = await gapi.client.people.people.connections.list({
        resourceName: "people/me",
        personFields:
          "names,emailAddresses,addresses,birthdays,occupations,organizations,phoneNumbers",
      });

      const items = response.result.connections;

      if (!items || items.length === 0) {
        setImportedContacts([]);
      } else {
        convertToContact(items);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const convertToContact = (items) => {
    const contact = {
      id: null,
      name: "",
      company: [],
      email: [],
      phone: [],
      address: [],
      url: [],
      selected: true,
      externalId: null,
    };

    let list = [];

    for (const item of items) {
      let newContact = { ...contact, id: newId() };
      if (Array.isArray(item.names)) {
        newContact.name = item.names[0].displayName;
      }

      if (item.organizations && item.organizations.length > 0) {
        if (!newContact.name) {
          newContact.name = item.organizations[0].name;
        }
        newContact.company = convertProperty("company", item.organizations);
      }

      if (item.emailAddresses && item.emailAddresses.length > 0) {
        newContact.email = convertProperty("email", item.emailAddresses);
      }

      if (item.phoneNumbers && item.phoneNumbers.length > 0) {
        newContact.phone = convertProperty("phone", item.phoneNumbers);
      }

      if (item.addresses && item.addresses.length > 0) {
        newContact.address = formatAddress(
          convertProperty("address", item.addresses)
        );
      }

      newContact.externalId = item.resourceName;

      list.push(newContact);
    }

    setImportedContacts(list);
  };

  const convertProperty = (fieldName, values) => {
    let list = [];

    let entryTemplate = {
      isMain: false,
      type: [],
      value: "",
    };

    let index = 0;
    for (const prop of values) {
      let entry = { ...entryTemplate };

      if (fieldName === "address") {
        let address = {};

        address.streetAddress = prop.streetAddress ? prop.streetAddress : null;
        address.otherAddress = prop.otherAddress ? prop.otherAddress : null;
        address.city = prop.city ? prop.city : null;
        address.province = prop.region ? prop.region : null;
        address.postal = prop.postalCode ? prop.postalCode : null;
        address.country = prop.country ? prop.country : null;

        entry.value = address;
      } else if (fieldName === "phone") {
        entry.value = prop.canonicalForm;
      } else if (prop.name) {
        entry.value = prop.name;
      } else if (prop.value) {
        entry.value = prop.value;
      }

      if (prop.metadata && prop.metadata.hasOwnProperty("primary")) {
        entry.isMain = prop.metadata.primary;
      } else if (values.length === 1 || index === 0) {
        entry.isMain = true;
      }

      list.push({ ...entry });

      index++;
    }

    return list;
  };

  const formatAddress = (addressList) => {
    let addresses = [];

    for (const item of addressList) {
      addresses.push({ ...item, value: valueToAddress(item.value) });
    }

    return addresses;
  };

  const valueToAddress = (value) => {
    let address = {
      streetAddress: null,
      otherAddress: null,
      city: null,
      province: null,
      postal: null,
      country: null,
    };

    address.streetAddress = value.streetAddress ? value.streetAddress : null;
    address.otherAddress = value.otherAddress ? value.otherAddress : null;
    address.city = value.city ? value.city : null;
    address.province = value.province ? value.province : null;
    address.postal = value.postal ? value.postal : null;
    address.country = value.country ? value.country : null;

    return address;
  };

  return {
    authorizeClient,
    signout,
    requestConfig,
    respError,
    events,
    googleReady,
    importedContacts,
  };
};

export default useGoogle;
