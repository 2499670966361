import { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import useGrocery from "../../hooks/useGrocery";
import useMessage from "../../hooks/useMessage";
import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDTitle from "../layout/OFDTitle";

import OFDDisplayText from "../ui/OFDDisplayText";
import RecipeIngredients from "./RecipeIngredients";
import RecipeInstructions from "./RecipeInstructions";
import OFDButton from "../ui/OFDButton";
import OFDFieldGroup from "../ui/OFDFieldGroup";
import OFDSelect from "../ui/OFDSelect";
import OFDMessage from "../ui/OFDMessage";
import OFDDisplayUrl from "../ui/OFDDisplayUrl";

const RecipeDisplay = ({ recipeId, recipe }) => {
  const { getLabel, getMessage } = useLocalization();
  const { getData } = useApi();
  const {
    getGroceryLists,
    groceryLists,
    addIngredientsToList,
    formMessage,
    resetFormMessage,
  } = useGrocery();
  const { destructureResults } = useMessage();

  const [options, setOptions] = useState([]);
  const [data, setData] = useState();
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedInstructions, setSelectedInstructions] = useState([]);
  const [selectedGroceryList, setSelectedGroceryList] = useState(null);
  const [addedMessage, setAddedMessage] = useState(null);

  useEffect(() => {
    getGroceryLists({
      completedAt: null,
    });
  }, []);

  useEffect(() => {
    setOptions(() => {
      if (!groceryLists || groceryLists.length === 0) {
        return [];
      }

      let list = [{ value: "", label: "" }];
      list = [
        ...list,
        ...groceryLists.map((list) => ({ value: list.id, label: list.name })),
      ];

      return list;
    });
  }, [groceryLists]);

  useEffect(() => {
    if (recipe) {
      setData(recipe);
    } else if (recipeId) {
      getRecipe();
    }
  }, [recipe, recipeId]);

  const getRecipe = async () => {
    const results = await getData({
      entityName: "Recipe",
      action: "get",
      id: recipeId,
    });

    if (results.isSuccessful) {
      setData(results.data);
    } else {
      setData(null);
    }
  };

  const handleIngredientSelection = (item, checked) => {
    setAddedMessage(null);

    setSelectedIngredients((current) => {
      let newSelection = [];

      if (Array.isArray(current)) {
        let exists = false;

        for (const selection of current) {
          if (selection !== item.id) {
            newSelection.push(selection);
            continue;
          }

          exists = true;
        }

        if (!exists) {
          newSelection.push(item.id);
        }
      } else {
        newSelection.push(item.id);
      }

      return newSelection;
    });
  };

  const handleAddToGroceryList = async () => {
    setAddedMessage(null);

    let items = [];
    for (const id of selectedIngredients) {
      let ingredient = data.ingredients.find((i) => i.id === id);

      let note = data.name;
      if (ingredient.note) {
        note += "\n" + ingredient.note;
      }
      if (note.length > 0) {
        note += "\n";
      }

      items.push({
        id: ingredient.id,
        department: getLabel("recipe"),
        item: ingredient.name,
        quantity: ingredient.quantity || null,
        note,
      });
    }

    const results = await addIngredientsToList(selectedGroceryList, items);

    setAddedMessage(results.message);
  };

  const handleInstructionSelection = (item, checked) => {
    setSelectedInstructions((current) => {
      let newSelection = [];

      if (Array.isArray(current)) {
        let exists = false;

        for (const selection of current) {
          if (selection !== item.id) {
            newSelection.push(selection);
            continue;
          }

          exists = true;
        }

        if (!exists) {
          newSelection.push(item.id);
        }
      } else {
        newSelection.push(item.id);
      }

      return newSelection;
    });
  };

  if (!data) return null;

  return (
    <>
      <Stack
        spacing={1}
        sx={{ padding: "1rem" }}
      >
        <OFDTitle title={data.name} />

        {data.image ? (
          <img
            alt="image"
            src={data.image}
            width="100%"
            height="auto"
          />
        ) : null}

        {data.externalUrl ? (
          <OFDDisplayUrl
            value={data.externalUrl}
            label="externalLink"
          />
        ) : null}

        <Typography>{data.description}</Typography>

        {data.servings ? (
          <OFDDisplayText
            label="servings"
            value={data.servings}
          />
        ) : null}

        {data.preparationTime || data.cookTime ? (
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <OFDDisplayText
              label="preparationTime"
              value={data.preparationTime || ""}
            />
            <OFDDisplayText
              label="cookTime"
              value={data.cookTime || ""}
            />
          </Box>
        ) : null}

        {data.ingredients ? (
          <RecipeIngredients
            label="ingredients"
            value={data.ingredients}
            onSelect={handleIngredientSelection}
            selectedItems={selectedIngredients}
          />
        ) : null}

        {selectedIngredients.length > 0 ? (
          <Box>
            <OFDFieldGroup
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box sx={{ width: "50%" }}>
                <OFDSelect
                  id="groceryList"
                  label="groceryList"
                  value={selectedGroceryList}
                  onChange={(newValue) => {
                    setSelectedGroceryList(newValue);
                  }}
                  options={options}
                  fullWidth
                />
              </Box>

              <Box sx={{ width: "50%" }}>
                <OFDButton
                  variant="contained"
                  onClick={handleAddToGroceryList}
                  label="addToGrocery"
                  color="grey"
                  shade={900}
                  width="auto"
                />
              </Box>
            </OFDFieldGroup>
            <Typography align="center">{getMessage(addedMessage)}</Typography>
          </Box>
        ) : null}

        {data.instructions ? (
          <RecipeInstructions
            label="instructions"
            value={data.instructions}
            onSelect={handleInstructionSelection}
            selectedItems={selectedInstructions}
          />
        ) : null}
      </Stack>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
        timeout={5000}
      />
    </>
  );
};

export default RecipeDisplay;
