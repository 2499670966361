import { useEffect, useState } from "react";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useDebug from "../../hooks/useDebug";
import useWindow from "../../hooks/useWindow";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import EntityDisplay from "../display/EntityDisplay";
import EntityEditor from "../editors/EntityEditor";
import OFDDialog from "../layout/OFDDialog";

import OFDListAndDetails from "../layout/OFDListAndDetails";

import Loading from "../layout/Loading";

const Family = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const { entityList, getEntityList, setEntityList, menuClick } =
    useEntity("Member");
  const { isDesktop } = useWindow();

  const { consoleLog } = useDebug(false);

  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    consoleLog("FAMILY", "reload");
    setOpenForm(false);
    setEntityName("Member");
    getData();
  }, [refresh]);

  const getData = async () => {
    setEntityList(undefined);
    getEntityList();
  };

  useEffect(() => {
    consoleLog("FAMILY", "toolbarAction", { toolbarAction });

    if (toolbarAction === "addFamily") {
      setOpenForm(true);
    }
    resetToolbarAction();
  }, [toolbarAction]);

  const handleEditEntity = async (entity) => {
    consoleLog("FAMILY", "handleEditEntity");
    await getEntityList();
  };

  const handleFormClose = async () => {
    consoleLog("FAMILY", "handleFormClose");

    setOpenForm(false);
    resetToolbarAction();
    await getEntityList();
  };

  const handleMenuItemClick = (action, entity) => {
    setSelectedEntity(entity);

    if (action === "delete") {
      setOpenDeleteConfirmation(true);
    }
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      const results = await menuClick("delete", selectedEntity);

      if (results.isSuccessful) {
        await getData();
      }
    }
    setOpenDeleteConfirmation(false);
  };

  const handleOpenRight = (entityName, id, entity) => {
    setSelectedEntity(entity);
  };

  const handleEdit = () => {};

  const handleSelectedFolder = () => {};

  const handleOpenFolderEditor = () => {};

  return (
    <>
      <OFDTitle title="family" />

      {entityList !== undefined ? (
        isDesktop ? (
          <OFDListAndDetails
            list={
              <OFDEntityList
                entityName="Member"
                entityList={entityList}
                onEdit={handleEditEntity}
                options={{
                  showAvatar: true,
                }}
                onMenuItemClick={handleMenuItemClick}
                onOpenRight={handleOpenRight}
              />
            }
            details={
              selectedEntity ? (
                <EntityDisplay
                  entityName="Member"
                  id={selectedEntity.id}
                  entityData={selectedEntity}
                  onChange={handleEdit}
                  onFolderSelected={handleSelectedFolder}
                  onFolderEdit={handleOpenFolderEditor}
                />
              ) : null
            }
          />
        ) : (
          <OFDEntityList
            entityName="Member"
            entityList={entityList}
            onEdit={handleEditEntity}
            options={{
              showAvatar: true,
            }}
            onMenuItemClick={handleMenuItemClick}
          />
        )
      ) : (
        <Loading />
      )}

      {openForm ? (
        <EntityEditor
          entityName="Member"
          open={openForm}
          onClose={handleFormClose}
        />
      ) : null}

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_member"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default Family;
