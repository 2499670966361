import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useAllergies from "../../hooks/useAllergies";
import useColor from "../../hooks/useColor";
import useData from "../../hooks/useData";
import useSecurity from "../../hooks/useSecurity";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDTextNote from "./OFDTextNote";
import OFDSelect from "./OFDSelect";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDDisplayNote from "./OFDDisplayNote";

import OFDDialog from "../layout/OFDDialog";
import OFDDisplayAllergyItem from "./OFDDisplayAllergyItem";
import OFDCollapsable from "../layout/OFDCollapsable";

const fieldsTemplate = {
  name: "",
  severity: "mild",
  note: "",
};

const OFDAllergies = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { allergyColor, severityLabel } = useAllergies();
  const { getColor } = useColor();
  const { sort, newId } = useData();
  const { userCanView, userCanUpdate } = useSecurity();

  const [fields, setFields] = useState({ ...fieldsTemplate });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [fieldMessages, setFieldMessages] = useState({});

  const handleCancel = () => {
    setEditMode("add");
    setFields({ ...fieldsTemplate });
    setSelectedItem(null);
    setFieldMessages({});
  };

  const handleAddItem = () => {
    if (!validFields()) return;

    setEditMode("add");
    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields, id: newId() }];
    } else {
      list = [{ ...fields, id: newId() }];
    }

    const sortedList = sort(list, "severity", "desc");

    handleChange(sortedList);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
    setFieldMessages({});
  };

  const handleUpdateItem = () => {
    if (!validFields()) return;

    let list = [];

    for (const bp of value) {
      if (bp.id !== selectedItem.id) {
        list.push({ ...bp });
      } else {
        list.push({ ...fields });
      }
    }

    const sortedList = sort(list, "severity", "desc");

    handleChange(sortedList);
  };

  const validFields = () => {
    let isValid = true;
    let messages = {};

    if (
      !fields ||
      fields.name === undefined ||
      fields.name.trim().length === 0
    ) {
      messages.name = { error: true, message: "isEmpty" };

      isValid = false;
    }

    if (editMode === "add") {
      if (Array.isArray(value)) {
        let index = value.findIndex((item) => item.name === fields.name);
        if (index > -1) {
          isValid = false;
          messages.name = { error: true, message: "alreadyExists" };
        }
      }
    }

    setFieldMessages(messages);
    return isValid;
  };

  const getFieldMessage = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return null;

    return fieldMessages[field].message;
  };

  const getFieldError = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return false;

    return fieldMessages[field].error;
  };

  const handleDeleteItem = () => {
    if (!selectedItem) return;

    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const bp of value) {
        if (bp.id !== selectedItem.id) {
          list.push({ ...bp });
          continue;
        }
      }

      const sortedList = sort(list, "datetime", "desc");

      handleChange(sortedList);
    }
  };

  const handleChange = (sortedList) => {
    onChange(sortedList);
    setFields({ ...fieldsTemplate });
    setEditMode("add");
    setSelectedItem(null);
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box id={id}>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {getLabel(label)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          gap={1}
        >
          <Box sx={{ width: "60%" }}>
            <OFDTextField
              id="allergy"
              label="allergy"
              value={getFieldValue("name") || ""}
              onChange={(newValue) => updateFieldValue("name", newValue)}
              disabled={isDisabled()}
              fullWidth
              error={getFieldError("name")}
              message={getFieldMessage("name")}
            />
          </Box>
          <Box sx={{ width: "40%" }}>
            <OFDSelect
              id="severity"
              label="severity"
              value={getFieldValue("severity") || ""}
              onChange={(newValue) => updateFieldValue("severity", newValue)}
              listName="allergySeverity"
              variant="outlined"
              disabled={isDisabled()}
              fullWidth
            />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDTextNote
            id="note"
            label="note"
            value={getFieldValue("note") || ""}
            onChange={(newValue) => updateFieldValue("note", newValue)}
            disabled={isDisabled()}
            fullWidth
          />
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={selectedItem ? handleUpdateItem : handleAddItem}
            >
              {selectedItem ? "Update" : "Add"}
            </Button>
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          <Stack spacing={1}>
            {value?.map((allergy) => (
              <OFDCollapsable
                key={allergy.id}
                compressed
                header={
                  <OFDDisplayAllergyItem
                    onClick={() => handleEditItem(allergy)}
                    allergy={allergy}
                  />
                }
                styleOverride={{ ...getColor(allergyColor(allergy)) }}
                arrowColor="grey"
                arrowShade={50}
              >
                {allergy.note ? (
                  <OFDDisplayNote
                    value={allergy.note}
                    textOnly={true}
                    styleoverride={{
                      fontSize: "12px",
                      color: "#535353",
                    }}
                  />
                ) : null}
              </OFDCollapsable>
            ))}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default OFDAllergies;
