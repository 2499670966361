import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";
import OFDMessage from "./OFDMessage";

const OFDDisplayText = ({
  label,
  value,
  styleoverride,
  onClick,
  copyToClipboard,
  listName,
  security,
  entryAddedBy,
}) => {
  const { getLabel, getListItemLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const [message, setMessage] = useState(null);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(value);
      setMessage({
        message: "copiedToClipboard",
        displayToUser: true,
        severity: "info",
      });
    } catch (err) {
      setMessage({
        message: "error",
        displayToUser: true,
        severity: "error",
      });
    }
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}>
        <Box
          onClick={handleClick}
          sx={{ width: "100%" }}
        >
          <Typography variant="overline">{getLabel(label)}</Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography noWrap>
              {value && listName ? getListItemLabel(listName, value) : "-"}
            </Typography>
          </Box>
        </Box>

        {value && copyToClipboard ? (
          <Box>
            <OFDIcon
              name="copy"
              onClick={handleCopy}
            />
          </Box>
        ) : null}
      </Box>

      <OFDMessage
        message={message}
        onClose={() => setMessage(null)}
      />
    </>
  );
};

export default OFDDisplayText;
