import { useEffect, useState, useRef } from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const OfficeViewer = ({ url, onClose }) => {
  const officeUrl = "https://docs.google.com/gview?url=";

  const [docUrl, setDocUrl] = useState();
  const linkRef = useRef();

  const theme = useTheme();

  //https://view.officeapps.live.com/op/view.aspx?src=
  //https://view.officeapps.live.com/op/embed.aspx?src=
  //https://drive.google.com/viewer?embedded=true&hl=en-US&url=
  //https://docs.google.com/gview?url=

  useEffect(() => {
    let documentUrl = `${officeUrl}${encodeURIComponent(url)}`;

    setDocUrl(documentUrl);
  }, [url]);

  useEffect(() => {
    if (!docUrl) return;

    // linkRef.current.click();

    // onClose();
  }, [docUrl]);

  if (!docUrl) return null;

  return (
    <div style={{ height: "100%" }}>
      {/* <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            padding: "8px",
            borderRadius: "8px",
          }}
        >
          <a
            ref={linkRef}
            href={docUrl}
            download
            target="_blank"
            style={{
              textDecoration: "none",
              color: theme.palette.primary.contrastText,
            }}
          >
            Click to view
          </a>
        </Box>
      </Box> */}
      <iframe
        src={`${docUrl}&embedded=true`}
        // src={`${officeUrl}${url.replace(/&/g, "%26")}&embedded=true`}
        style={{
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  );
};

export default OfficeViewer;
