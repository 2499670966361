import { useEffect, useState } from "react";

import useHomework from "../../../../hooks/useHomework";
import useLocalization from "../../../../hooks/useLocalization";
import usePage from "../../../../hooks/usePage";

import { Box, Stack, Typography } from "@mui/material";

import MemberWidget from "./MemberWidget";
import Homework from "./Homework";

const HomeworkDashboard = ({ height }) => {
  const { getMessage } = useLocalization();
  const { getOutstandingHomework, homework, clearHomework } = useHomework();
  const { refresh } = usePage();

  const [memberHomework, setMemberHomework] = useState(undefined);

  useEffect(() => {
    getHomework();
  }, [refresh]);

  const getHomework = async () => {
    clearHomework();
    await getOutstandingHomework();
  };

  useEffect(() => {
    if (!homework) return;

    splitHomeworkByMember();
  }, [homework]);

  const splitHomeworkByMember = () => {
    let byMember = [];

    for (const homeworkItem of homework) {
      let index = byMember.findIndex(
        (item) => item.memberId === homeworkItem.memberId
      );

      if (index === -1) {
        byMember.push({
          memberId: homeworkItem.memberId,
          homework: [{ ...homeworkItem }],
        });
      } else {
        let item = byMember[index];
        item.homework.push({ ...homeworkItem });
        byMember[index] = item;
      }
    }

    setMemberHomework(byMember);
  };

  const handleChange = () => {
    getOutstandingHomework();
  };

  if (memberHomework === undefined) return;

  return (
    <>
      <Box sx={{ height: height }}>
        {Array.isArray(memberHomework) && memberHomework.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              paddingTop: "1rem",
              overflowX: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            gap={2}
          >
            {memberHomework.map((homeworkItem) => (
              <MemberWidget
                key={homeworkItem.memberId}
                memberId={homeworkItem.memberId}
                height={height}
              >
                <Box sx={{ padding: ".5rem" }}>
                  <Stack spacing={2}>
                    {homeworkItem.homework.map((item) => (
                      <Homework
                        key={item.id}
                        homework={item}
                        onChange={handleChange}
                      />
                    ))}
                  </Stack>
                </Box>
              </MemberWidget>
            ))}
          </Box>
        ) : (
          <Typography>{getMessage("noHomework")}</Typography>
        )}
      </Box>
    </>
  );
};

export default HomeworkDashboard;
