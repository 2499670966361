import { useEffect, useState } from "react";
import { startOfToday, startOfWeek } from "date-fns";

import useMealPlan from "../../../../hooks/useMealPlan";
import useFamily from "../../../../hooks/useFamily";
import useLocalization from "../../../../hooks/useLocalization";
import useWindow from "../../../../hooks/useWindow";
import usePage from "../../../../hooks/usePage";

import { Box, Typography } from "@mui/material";

import MealPlanWizard from "../../../wizards/mealplan/MealPlanWizard";
import DailyPlanContainer from "./DailyPlanContainer";

import OFDIcon from "../../../ui/OFDIcon";

import OFDWeekOf from "../../../ui/OFDWeekOf";
import OFDButton from "../../../ui/OFDButton";

const MealPlanDashboard = ({ height }) => {
  const { getMealPlanForWeek, addMealPlan } = useMealPlan();
  const { getMessage } = useLocalization();
  const { windowWidth } = useWindow();
  const { family } = useFamily();
  const { refresh, dashboardMode } = usePage();

  const [openWizard, setOpenWizard] = useState(false);
  const [weekOf, setWeekOf] = useState(startOfWeek(startOfToday()));
  const [mealPlan, setMealPlan] = useState();
  const [action, setAction] = useState(null);

  useEffect(() => {
    setWeekOf(startOfWeek(startOfToday()));
  }, []);

  useEffect(() => {
    if (!weekOf) return;

    getMealPlan();
  }, [weekOf, refresh]);

  const getMealPlan = async () => {
    setMealPlan(null);

    const results = await getMealPlanForWeek(weekOf);

    if (results.isSuccessful) {
      setMealPlan(results.data[0]);
    } else {
      setMealPlan(null);
    }
  };

  const handleNew = () => {
    setAction("new");
    setOpenWizard(true);
  };

  const saveMealPlan = async (mealplan) => {
    setOpenWizard(false);

    let data = {
      familyId: family.id,
      ...mealplan,
    };
    const results = await addMealPlan(data);

    await getMealPlan();
  };

  return (
    <>
      <Box sx={{ height: height }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            height: "80px",
          }}
        >
          <OFDWeekOf
            value={weekOf}
            onChange={(newValue) => setWeekOf(newValue)}
          />

          {dashboardMode === "edit" ? (
            <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
              <OFDButton
                name="new"
                label="new"
                iconName="add"
                onClick={handleNew}
              />
            </Box>
          ) : null}
        </Box>

        {mealPlan && mealPlan.dailyPlans.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              paddingTop: "1rem",
              overflowX: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingBottom: ".5rem",
            }}
            gap={2}
          >
            {mealPlan.dailyPlans.map((dailyPlan, index) => (
              <DailyPlanContainer
                key={`dailyPlan-${index}`}
                dow={index}
                dailyPlan={dailyPlan}
                height={height - 110}
                mealPlanId={mealPlan.id}
                onUpdated={getMealPlan}
              />
            ))}
          </Box>
        ) : (
          <Typography
            variant="h5"
            align="center"
          >
            {getMessage("noMealPlan")}
          </Typography>
        )}
      </Box>

      {openWizard ? (
        <MealPlanWizard
          open={openWizard}
          onClose={() => setOpenWizard(false)}
          onCreateMealPlan={saveMealPlan}
          onUpdateMealPlan={() => {}}
          selectedWeek={weekOf}
        />
      ) : null}
    </>
  );
};

export default MealPlanDashboard;
