import useColor from "../../../hooks/useColor";
import useLocalization from "../../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDIcon from "../../ui/OFDIcon";

const FieldIcon = ({ icon, label, fieldType, selected, disabled, onClick }) => {
  const { getLabel } = useLocalization();
  const { getColor } = useColor();

  const handleClick = () => {
    if (disabled) return;

    onClick(fieldType);
  };

  return (
    <Box>
      <Box
        id={icon}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "48px",
          height: "48px",
          padding: "8px",
          ...getColor({ color: "grey", shade: 300 }),
          borderRadius: "8px",
        }}
        onClick={handleClick}
      >
        <OFDIcon
          name={icon}
          size="large"
          color="grey"
          shade={disabled ? 500 : 900}
        />
      </Box>
      <Box sx={{ width: "48px", display: "flex", justifyContent: "center" }}>
        <Typography
          variant="caption"
          sx={{
            marginTop: ".25rem",
            color: getColor({ color: "grey", shade: disabled ? 500 : 900 })
              .backgroundColor,
          }}
          align="center"
        >
          {getLabel(label)}
        </Typography>
      </Box>
    </Box>
  );
};

export default FieldIcon;
