import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useRecipe from "../../hooks/useRecipe";

import { Box, Typography, Stack } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDTextField from "./OFDTextField";
import OFDIconButton from "./OFDIconButton";
import OFDFieldGroup from "./OFDFieldGroup";

import OFDRecipeDisplay from "./OFDRecipeDisplay";

const OFDRecipeSelector = ({ open, onClose, onSelect }) => {
  const { getTitle, getMessage } = useLocalization();
  const { getRecipes, recipes, filterRecipes, filteredRecipes } = useRecipe();

  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getRecipes();
  };

  const handleSearch = () => {
    filterRecipes({ searchValue: searchValue });
  };

  const handleSelect = (recipe) => {
    onSelect(recipe);
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={getTitle("recipeFinder")}
      >
        <OFDFieldGroup sx={{ padding: "1rem" }}>
          <OFDTextField
            id="searchValue"
            label="search"
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            fullWidth
          />
          <OFDIconButton
            name="search"
            onClick={handleSearch}
          />
        </OFDFieldGroup>

        {Array.isArray(filteredRecipes) ? (
          <Stack
            spacing={1}
            sx={{ padding: "1rem" }}
          >
            {filteredRecipes.length > 0 ? (
              filteredRecipes?.map((recipe) => (
                <OFDRecipeDisplay
                  key={recipe.id}
                  recipe={recipe}
                  onSelect={handleSelect}
                />
              ))
            ) : (
              <Typography
                align="center"
                variant="h6"
              >
                {getMessage("noRecipesFound")}
              </Typography>
            )}
          </Stack>
        ) : null}
      </OFDModal>
    </>
  );
};

export default OFDRecipeSelector;
