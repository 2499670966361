import { useEffect, useState, useRef } from "react";

import { Box } from "@mui/material";

import htm from "./ThirtyDay.htm";

const ThirtyDay = ({ email, name }) => {
  const iframeRef = useRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!email || !name || !loaded) return;
    if (email.substring(0, 4) === "ofd_") {
      return;
    }

    let document = iframeRef.current.contentWindow.document;

    let nameField = document.getElementById("mce-FNAME");
    nameField.value = name;

    let emailField = document.getElementById("mce-EMAIL");
    emailField.value = email;

    let submitButton = document.getElementById("mc-embedded-subscribe");
    submitButton.click();
  }, [email, name, loaded]);

  const iframeLoaded = () => {
    setLoaded(true);
  };

  return (
    <>
      <Box sx={{ display: "none" }}>
        <iframe
          ref={iframeRef}
          id="thirtyday"
          src={htm}
          onLoad={iframeLoaded}
        />
      </Box>
    </>
  );
};

export default ThirtyDay;
