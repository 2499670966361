import React from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

const RecurrenceDays = ({ recurrence }) => {
  const { getLabel, getListItemLabel } = useLocalization();

  if (!recurrence) return null;

  return (
    <Box>
      {recurrence.byMonth ? (
        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <Typography variant="caption">{getLabel("in")}</Typography>
          {recurrence.byMonth.map((month, index) => (
            <Typography
              key={`month_${month}`}
              variant="caption"
            >{`${index > 0 ? "," : ""}${getListItemLabel(
              "months",
              month,
              true
            )}`}</Typography>
          ))}
        </Box>
      ) : null}
      {recurrence.byDay && recurrence.byDay.length > 0 ? (
        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <Typography variant="caption">{`${getLabel("on")} `}</Typography>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {recurrence.byDay.map((day, index) => {
              if (day.position) {
                return (
                  <Typography
                    key={`dow_${day.dow}_${day.position}`}
                    variant="caption"
                  >{`${getListItemLabel(
                    "dayPositions",
                    `dow_${day.dow}_${day.position}`
                  )} ${
                    index < recurrence.byDay.length - 1 ? ", " : " "
                  }`}</Typography>
                );
              } else {
                return (
                  <Typography
                    key={`dow_${day.dow}`}
                    variant="caption"
                  >
                    {`${getListItemLabel("days", `dow_${day.dow}`)} ${
                      index < recurrence.byDay.length - 1 ? ", " : " "
                    }`}
                  </Typography>
                );
              }
            })}
          </Box>
        </Box>
      ) : null}
      {recurrence.byMonthDay ? (
        <Box>
          {recurrence.byMonthDay.map((monthDay, index) => (
            <Typography
              key={`monthDay_${monthDay}`}
              variant="caption"
            >{`${index > 0 ? "," : ""}${monthDay}`}</Typography>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default RecurrenceDays;
