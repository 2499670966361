import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDButton from "../ui/OFDButton";
import OFDFieldGroup from "../ui/OFDFieldGroup";
import SeriesFilter from "../editors/SeriesFilter";

const SeriesDelete = ({ data, open, onClose, onDelete }) => {
  const { getText } = useLocalization();
  const { getData } = useApi();

  const [seriesFilter, setSeriesFilter] = useState(null);
  const [modelData, setModelData] = useState(null);

  useEffect(() => {
    if (!data) return;

    getModelData();
  }, [data]);

  const getModelData = async () => {
    const results = await getData({
      entityName: data.source.model,
      action: "get",
      id: data.source.id,
    });

    if (results.isSuccessful) {
      setModelData(results.data);
    }
  };

  const handleChange = (newFilter) => {
    setSeriesFilter(newFilter);
  };

  const handleDelete = () => {
    onDelete(seriesFilter);
  };

  return (
    <OFDModal
      open={open}
      onClose={onClose}
      title="deleteSeries"
    >
      <Box sx={{ padding: "1rem" }}>
        <Stack spacing={2}>
          <Typography variant="h5">
            {modelData ? modelData.name : null}
          </Typography>

          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            {getText("deleteSeries")}
          </Typography>

          {modelData ? (
            <Stack spacing={2}>
              <SeriesFilter
                startDate={modelData.startDate}
                endDate={modelData.endDate}
                filter={seriesFilter}
                onChange={handleChange}
                deleteMode
              />

              <OFDFieldGroup justifyContent="space-around">
                <OFDButton
                  variant="contained"
                  name="cancel"
                  label="cancel"
                  iconName="cancel"
                  onClick={onClose}
                />

                <OFDButton
                  variant="contained"
                  name="delete"
                  label="delete"
                  iconName="delete"
                  onClick={handleDelete}
                />
              </OFDFieldGroup>
            </Stack>
          ) : null}
        </Stack>
      </Box>
    </OFDModal>
  );
};

export default SeriesDelete;
