import React, { useEffect, useState, Suspense } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useWindow from "../../hooks/useWindow";

import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import Loading from "./Loading";
import OFDEntityItem from "./OFDEntityItem";

const OFDEntityList = ({
  entityName,
  entityList,
  filter,
  onEdit,
  options,
  onMenuItemClick,
  headerFields,
  customHeader,
  onOpenRight,
}) => {
  const { text, getMessage } = useLocalization();
  const { userCanView, isReady } = useSecurity();
  const { isDesktop } = useWindow();

  const [displayList, setDisplayList] = useState();

  const listStyles = {
    marginTop: isDesktop ? "0px" : "1rem",
    marginBottom: "1rem",
  };

  useEffect(() => {
    if (isDesktop) {
      if (Array.isArray(entityList) && entityList.length > 0) {
        onOpenRight(entityName, entityList[0].id, entityList[0]);
      }
    }
  }, [entityList]);

  const handleMenuItemClick = (name, entity) => {
    if (onMenuItemClick) {
      onMenuItemClick(name, entity);
    }
  };

  return (
    <>
      {Array.isArray(entityList) && entityList.length > 0 ? (
        <Grid
          container
          spacing={2}
          sx={listStyles}
        >
          {entityList.map((entity, index) => (
            <Grid
              key={entity.id}
              mobile={12}
              tablet={12}
            >
              <OFDEntityItem
                entityName={entityName}
                id={entity.id}
                key={entity.id}
                entity={entity}
                options={options}
                onMenuItemClick={handleMenuItemClick}
                headerFields={headerFields}
                onChange={onEdit}
                customHeader={customHeader}
                onOpenRight={onOpenRight ? onOpenRight : () => {}}
                itemIndex={index}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ marginTop: "1rem" }}>
          <Typography align="center">{getMessage("noResults")}</Typography>
        </Box>
      )}
    </>
  );
};

export default OFDEntityList;
