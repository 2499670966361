import { useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useData from "../../hooks/useData";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDDate from "./OFDDate";
import OFDTextNote from "./OFDTextNote";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDDisplayNote from "./OFDDisplayNote";
import OFDNumber from "./OFDNumber";

import OFDDialog from "../layout/OFDDialog";

const fieldsTemplate = {
  date: null,
  personal: "",
  business: "",
  note: "",
};

const OFDMileageLog = ({ id, label, value, onChange }) => {
  const { getLabel } = useLocalization();
  const { newId, sort } = useData();

  const [fields, setFields] = useState({ ...fieldsTemplate });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [fieldMessages, setFieldMessages] = useState({});

  const handleCancel = () => {
    setEditMode("add");
    setFields({ ...fieldsTemplate });
    setSelectedItem(null);
    setFieldMessages({});
  };

  const validFields = () => {
    let isValid = true;
    let messages = {};

    if (!fields || !fields.date) {
      messages.date = { error: true, message: "isEmpty" };
      isValid = false;
    }

    setFieldMessages(messages);
    return isValid;
  };

  const handleAddItem = () => {
    setEditMode("add");

    if (!validFields()) return;

    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields, id: newId() }];
    } else {
      list = [{ ...fields, id: newId() }];
    }

    handleChange(list);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
    setFieldMessages({});
  };

  const handleUpdateItem = () => {
    if (!validFields()) return;

    let list = [];

    for (const bp of value) {
      if (bp.id !== selectedItem.id) {
        list.push({ ...bp });
      } else {
        list.push({ ...fields });
      }
    }

    handleChange(list);
  };

  const handleDeleteItem = () => {
    if (!selectedItem) return;

    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const bp of value) {
        if (bp.id !== selectedItem.id) {
          list.push({ ...bp });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const handleChange = (list) => {
    let sortedList = sort(list, "date", "desc");
    onChange(sortedList);
    setFields({ ...fieldsTemplate });
    setEditMode("add");
    setSelectedItem(null);
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  const getFieldMessage = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return null;

    return fieldMessages[field].message;
  };

  const getFieldError = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return false;

    return fieldMessages[field].error;
  };

  const getItemLabel = (item) => {
    let text = "";
    text = `${format(item.date, "MMM dd, yyyy")} - `;

    if (item.personal) {
      text += `${getLabel("personal")} : ${item.personal}`;
    }
    if (item.business) {
      if (item.personal) {
        text += " / ";
      }
      text += `${getLabel("business")} : ${item.business}`;
    }

    return text;
  };

  return (
    <>
      <Box id={id}>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {label}
          </Typography>
        </Box>

        <Box>
          <OFDDate
            id="date"
            label="date"
            value={getFieldValue("date") || ""}
            onChange={(newValue) => updateFieldValue("date", newValue)}
            required
            error={getFieldError("date")}
            message={getFieldMessage("date")}
          />
        </Box>

        <Box
          sx={{ display: "flex", marginTop: ".5rem" }}
          gap={1}
        >
          <Box sx={{ width: "50%" }}>
            <OFDNumber
              id="personal"
              label="personal"
              value={getFieldValue("personal") || ""}
              onChange={(newValue) => updateFieldValue("personal", newValue)}
              fullWidth
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <OFDNumber
              id="business"
              label="business"
              value={getFieldValue("business") || ""}
              onChange={(newValue) => updateFieldValue("business", newValue)}
              fullWidth
            />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", marginTop: ".5rem" }}
          gap={1}
        >
          <OFDTextNote
            id="note"
            label="note"
            value={getFieldValue("note") || ""}
            onChange={(newValue) => updateFieldValue("note", newValue)}
            fullWidth
          />
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                selectedItem ? handleUpdateItem() : handleAddItem()
              }
            >
              {selectedItem ? "Update" : "Add"}
            </Button>
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          <Stack spacing={1}>
            {Array.isArray(value)
              ? value.map((item) => (
                  <Box key={item.id}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#ccc",
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      gap={1}
                      onClick={() => handleEditItem(item)}
                    >
                      <Typography>{getItemLabel(item)}</Typography>
                    </Box>

                    {item.note ? (
                      <Box
                        sx={{
                          backgroundColor: "#ebebeb",
                          borderRadius: "4px",
                          marginTop: "4px",
                          paddingTop: ".25rem",
                          paddingBottom: ".25rem",
                          paddingLeft: ".5rem",
                          maxHeight: "100px",
                          overflow: "auto",
                          color: "#535353",
                        }}
                      >
                        <OFDDisplayNote
                          value={item.note}
                          textOnly={true}
                          styleoverride={{
                            color: "#535353",
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ))
              : null}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default OFDMileageLog;
