import { useEffect, useState } from "react";

import useDocument from "../../../hooks/useDocument";
import useLocalization from "../../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import VideoViewer from "./VideoViewer";
import ImageViewer from "./ImageViewer";
import PDFViewer from "./PDFViewer";
import OfficeViewer from "./OfficeViewer";

const DocumentViewer = ({ document, onClose }) => {
  const { getDocumentUrl } = useDocument();
  const { getMessage } = useLocalization();

  const [documentUrl, setDocumentUrl] = useState(null);

  useEffect(() => {
    if (!document) return;

    getUrl();
  }, [document]);

  const getUrl = async () => {
    let url = await getDocumentUrl(document.id);
    let viewer = viewerType();

    setDocumentUrl({ url, viewer });
  };

  const viewerType = () => {
    if (
      ["jpg", "jpeg", "png", "gif", "heic", "heix", "webp"].includes(
        document.fileExtension
      )
    )
      return "image";

    if (
      ["mp4", "webm", "mov", "ogg", "mpeg", "mpg", "wmv", "avi", "qt"].includes(
        document.fileExtension
      )
    )
      return "video";

    if (["doc", "docx", "xlsx", "pptx"].includes(document.fileExtension))
      return "office";

    if (["pdf"].includes(document.fileExtension)) return "pdf";

    return "unknown";
  };

  if (!documentUrl) return null;

  return (
    <>
      {documentUrl.viewer === "image" ? (
        <ImageViewer url={documentUrl.url} />
      ) : null}

      {documentUrl.viewer === "office" ? (
        <OfficeViewer
          url={documentUrl.url}
          onClose={onClose}
        />
      ) : null}

      {documentUrl.viewer === "pdf" ? (
        <OfficeViewer
          url={documentUrl.url}
          onClose={onClose}
        />
      ) : null}

      {documentUrl.viewer === "video" ? (
        <VideoViewer url={documentUrl.url} />
      ) : null}

      {documentUrl.viewer === "unknown" ? (
        <OfficeViewer
          url={documentUrl.url}
          onClose={onClose}
        />
      ) : null}
    </>
  );
};

export default DocumentViewer;
