import React, { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";

import { Box, Stack } from "@mui/material";

import ExpenseDisplay from "./ExpenseDisplay";
import Loading from "../layout/Loading";

const ExpenseSearchResults = ({ expenses, searchCriteria }) => {
  const { getLabel } = useLocalization();
  const { sort } = useData();

  const [sortedExpenses, setSortedExpenses] = useState(undefined);

  useEffect(() => {
    setSortedExpenses(undefined);
    setSortedExpenses(() => {
      if (!expenses) return null;

      return sort(expenses, "expenseDate", "desc");
    });
  }, [expenses]);

  return (
    <>
      <Box>
        {sortedExpenses !== undefined ? (
          <Stack spacing={2}>
            {sortedExpenses?.map((expense) => (
              <ExpenseDisplay
                key={expense.id}
                expense={expense}
              />
            ))}
          </Stack>
        ) : (
          <Loading />
        )}
      </Box>
    </>
  );
};

export default ExpenseSearchResults;
