import { useEffect } from "react";

import { Box, Typography } from "@mui/material";

import RecipePlaceholder from "../../assets/images/RecipePlaceholder.jpeg";

const OFDRecipeDisplay = ({ recipe, onSelect }) => {
  const handleSelect = () => {
    if (onSelect) onSelect(recipe);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: ".5rem",
        borderRadius: "8px",
      }}
      onClick={handleSelect}
    >
      <Box
        sx={{ display: "flex" }}
        gap={1}
      >
        <Box sx={{ width: "150px" }}>
          <img
            src={recipe.image ? recipe.image : RecipePlaceholder}
            style={{ borderRadius: "8px", width: "150px", height: "auto" }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography>{recipe.name}</Typography>
          <Typography variant="caption">
            {recipe.description ? recipe.description : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OFDRecipeDisplay;
