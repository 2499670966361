import React from "react";

import useLocalization from "../../hooks/useLocalization";

import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQs = () => {
  const { faqs } = useLocalization();

  return (
    <div>
      {faqs?.map((faq, index) => (
        <Accordion key={`faq_${index}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {faq.title}
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                backgroundColor: "#f0f0f0",
                padding: ".5rem",
                borderRadius: "10px",
              }}
            >
              {faq.text.map((text, index) => (
                <p
                  key={`faq-${index}`}
                  style={{ marginBottom: ".5rem" }}
                >
                  {text}
                </p>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQs;
