import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useWindow from "../../hooks/useWindow";
import useColor from "../../hooks/useColor";

import OFDModal from "../layout/OFDModal";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDTextField from "../ui/OFDTextField";
import OFDDate from "../ui/OFDDate";
import OFDTextNote from "../ui/OFDTextNote";
import OFDCollapsable from "../layout/OFDCollapsable";
import OFDSelect from "../ui/OFDSelect";
import OFDAddress from "../ui/OFDAddress";
import OFDPhone from "../ui/OFDPhone";
import OFDCurrency from "../ui/OFDCurrency";
import OFDToolbar from "../layout/OFDToolbar";

import OFDDatetime from "../ui/OFDDatetime";

const fieldsTemplate = {
  id: null,
  sort: null,
  departure: null,
  arrival: null,
  accommodations: null,
  carRental: null,
};

const ItineraryEditor = ({
  open,
  name,
  itineraryItem,
  onClose,
  onSave,
  onDelete,
  entityData,
}) => {
  const { isDesktop, windowHeight, windowWidth } = useWindow();
  const { getLabel, getTitle } = useLocalization();
  const { getColor } = useColor();

  const [fields, setFields] = useState(null);

  useEffect(() => {
    setFields(itineraryItem);
  }, [itineraryItem]);

  const handleToolbarClick = (action) => {
    if (action === "save") {
      onSave(fields);
    }
  };

  const getFieldValue = (object, field) => {
    if (!fields || !fields[object] || !fields[object][field]) return null;

    return fields[object][field];
  };

  const updateFieldValue = (object, field, newValue) => {
    setFields((current) => {
      let newFields = null;

      if (!current) {
        newFields = { [object]: { [field]: newValue } };
      } else if (!current[object]) {
        newFields = { ...current, [object]: { [field]: newValue } };
      } else {
        let newobject = { ...current[object] };
        newobject[field] = newValue;

        newFields = { ...current, [object]: { ...newobject } };
      }

      return newFields;
    });
  };

  const getDepartureHeader = () => {
    if (!fields) {
      return <Typography>{getLabel("departure")}</Typography>;
    }

    let header = (
      <Box>
        <Typography>{getLabel("departure")}</Typography>
        {getFieldValue("departure", "location") ? (
          <Typography variant="caption">
            {getFieldValue("departure", "location")}
            {getFieldValue("departure", "datetime")
              ? ` : ${format(
                  getFieldValue("departure", "datetime"),
                  "MMM dd h:mma"
                )}`
              : null}
          </Typography>
        ) : null}
      </Box>
    );

    return header;
  };

  const getArrivalHeader = () => {
    if (!fields) {
      <Typography>{getLabel("arrival")}</Typography>;
    }

    let header = (
      <Box>
        <Typography>{getLabel("arrival")}</Typography>
        {getFieldValue("arrival", "location") ? (
          <Typography variant="caption">
            {getFieldValue("arrival", "location")}
            {getFieldValue("arrival", "datetime")
              ? ` : ${format(
                  getFieldValue("arrival", "datetime"),
                  "MMM dd h:mma"
                )}`
              : null}
          </Typography>
        ) : null}
      </Box>
    );

    return header;
  };

  const getAccommodationsHeader = () => {
    if (!fields) {
      return <Typography>{getLabel("accommodations")}</Typography>;
    }

    let header = (
      <Box>
        <Typography>{getLabel("accommodations")}</Typography>
        {getFieldValue("accommodations", "address") ? (
          <Typography variant="caption">
            {getFieldValue("accommodations", "address").name}
          </Typography>
        ) : null}
      </Box>
    );

    return header;
  };

  const getCarRentalHeader = () => {
    if (!fields) {
      return <Typography>{getLabel("carRental")}</Typography>;
    }

    let header = (
      <Box>
        <Typography>{getLabel("carRental")}</Typography>
        {getFieldValue("carRental", "name") ? (
          <Typography variant="caption">
            {getFieldValue("carRental", "name")}
          </Typography>
        ) : null}
      </Box>
    );

    return header;
  };

  return (
    <OFDModal
      open={open}
      onClose={onClose}
      title={getTitle(name)}
    >
      <OFDToolbar
        sticky
        menuItems={[
          { name: "save", icon: "save", label: "save" },
          { name: "delete", icon: "delete", label: "delete" },
        ]}
        onClick={handleToolbarClick}
      />

      <Box
        sx={{
          width: `${windowWidth}px`,
          height: `${windowHeight - 135}px`,
          backgroundColor: "#fff",
          overflow: "auto",
          padding: "1rem",
        }}
      >
        <Stack spacing={1}>
          <OFDCollapsable
            header={getDepartureHeader()}
            styleOverride={{ ...getColor({ color: "grey", shade: 200 }) }}
            arrowColor="grey"
            arrowShade={900}
          >
            <Stack
              spacing=".5rem"
              sx={{ padding: ".5rem" }}
            >
              <OFDTextField
                id="location"
                label="location"
                value={getFieldValue("departure", "location") || ""}
                onChange={(newValue) =>
                  updateFieldValue("departure", "location", newValue)
                }
                fullWidth
              />

              <OFDDatetime
                id="datetime"
                label="datetime"
                value={getFieldValue("departure", "datetime") || ""}
                onChange={(newValue) =>
                  updateFieldValue("departure", "datetime", newValue)
                }
              />

              <Box
                sx={{ display: "flex" }}
                gap={0.5}
              >
                <Box sx={{ width: "100%" }}>
                  <OFDSelect
                    id="transportationMode"
                    label="mode"
                    value={
                      getFieldValue("departure", "transportationMode") || ""
                    }
                    onChange={(newValue) =>
                      updateFieldValue(
                        "departure",
                        "transportationMode",
                        newValue
                      )
                    }
                    fullWidth
                    listName="transportationModes"
                    variant="outlined"
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex" }}
                gap={0.5}
              >
                <Box sx={{ width: "60%" }}>
                  <OFDTextField
                    id="carrier"
                    label="carrier"
                    value={getFieldValue("departure", "carrier") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("departure", "carrier", newValue)
                    }
                    fullWidth
                  />
                </Box>
                <Box sx={{ width: "40%" }}>
                  <OFDTextField
                    id="routeNumber"
                    label="routeNumber"
                    value={getFieldValue("departure", "routeNumber") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("departure", "routeNumber", newValue)
                    }
                    fullWidth
                  />
                </Box>
              </Box>

              <OFDTextField
                id="depot"
                label="depot"
                value={getFieldValue("departure", "depot") || ""}
                onChange={(newValue) =>
                  updateFieldValue("departure", "depot", newValue)
                }
                fullWidth
              />

              <Box
                sx={{ display: "flex" }}
                gap={0.5}
              >
                <Box sx={{ width: "50%" }}>
                  <OFDTextField
                    id="terminal"
                    label="terminal"
                    value={getFieldValue("departure", "terminal") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("departure", "terminal", newValue)
                    }
                    fullWidth
                  />
                </Box>

                <Box sx={{ width: "50%" }}>
                  <OFDTextField
                    id="gate"
                    label="gate"
                    value={getFieldValue("departure", "gate") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("departure", "gate", newValue)
                    }
                    fullWidth
                  />
                </Box>
              </Box>

              <OFDTextNote
                id="note"
                label="note"
                value={getFieldValue("departure", "note") || ""}
                onChange={(newValue) =>
                  updateFieldValue("departure", "note", newValue)
                }
                fullWidth
              />
            </Stack>
          </OFDCollapsable>

          <OFDCollapsable
            header={getArrivalHeader()}
            styleOverride={{ ...getColor({ color: "grey", shade: 200 }) }}
            arrowColor="grey"
            arrowShade={900}
          >
            <Stack
              spacing={1}
              sx={{ padding: ".5rem" }}
            >
              <OFDTextField
                id="location"
                label="location"
                value={getFieldValue("arrival", "location") || ""}
                onChange={(newValue) =>
                  updateFieldValue("arrival", "location", newValue)
                }
                fullWidth
              />

              <OFDDatetime
                id="datetime"
                label="datetime"
                value={getFieldValue("arrival", "datetime") || ""}
                defaultValue={getFieldValue("departure", "datetime") || ""}
                onChange={(newValue) =>
                  updateFieldValue("arrival", "datetime", newValue)
                }
              />

              <OFDTextField
                id="depot"
                label="depot"
                value={getFieldValue("arrival", "depot") || ""}
                onChange={(newValue) =>
                  updateFieldValue("arrival", "depot", newValue)
                }
                fullWidth
              />

              <Box
                sx={{ display: "flex" }}
                gap={0.5}
              >
                <Box sx={{ width: "50%" }}>
                  <OFDTextField
                    id="terminal"
                    label="terminal"
                    value={getFieldValue("arrival", "terminal") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("arrival", "terminal", newValue)
                    }
                    fullWidth
                  />
                </Box>

                <Box sx={{ width: "50%" }}>
                  <OFDTextField
                    id="gate"
                    label="gate"
                    value={getFieldValue("arrival", "gate") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("arrival", "gate", newValue)
                    }
                    fullWidth
                  />
                </Box>
              </Box>

              <OFDTextNote
                id="note"
                label="note"
                value={getFieldValue("arrival", "note") || ""}
                onChange={(newValue) =>
                  updateFieldValue("arrival", "note", newValue)
                }
                fullWidth
              />
            </Stack>
          </OFDCollapsable>

          <OFDCollapsable
            header={getAccommodationsHeader()}
            styleOverride={{ ...getColor({ color: "grey", shade: 200 }) }}
            arrowColor="grey"
            arrowShade={900}
          >
            <Stack
              spacing={1}
              sx={{ padding: ".5rem" }}
            >
              <Box
                sx={{ display: "flex" }}
                gap={0.5}
              >
                <Box sx={{ width: "50%" }}>
                  <OFDDate
                    id="fromDate"
                    label="from"
                    value={getFieldValue("accommodations", "fromDate") || ""}
                    defaultValue={
                      entityData && entityData.fromDate
                        ? entityData.fromDate
                        : ""
                    }
                    onChange={(newValue) =>
                      updateFieldValue("accommodations", "fromDate", newValue)
                    }
                  />
                </Box>

                <Box sx={{ width: "50%" }}>
                  <OFDDate
                    id="toDate"
                    label="to"
                    value={getFieldValue("accommodations", "toDate") || ""}
                    defaultValue={
                      entityData && entityData.toDate ? entityData.toDate : ""
                    }
                    onChange={(newValue) =>
                      updateFieldValue("accommodations", "toDate", newValue)
                    }
                  />
                </Box>
              </Box>

              <OFDAddress
                id="address"
                value={getFieldValue("accommodations", "address") || ""}
                onChange={(newValue) =>
                  updateFieldValue("accommodations", "address", newValue)
                }
              />

              <Box
                sx={{ display: "flex" }}
                gap={0.5}
              >
                <Box sx={{ width: "65%" }}>
                  <OFDPhone
                    id="phone"
                    label="phone"
                    value={getFieldValue("accommodations", "phone") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("accommodations", "phone", newValue)
                    }
                  />
                </Box>

                <Box sx={{ width: "35%" }}>
                  <OFDCurrency
                    id="rate"
                    label="rate"
                    value={getFieldValue("accommodations", "rate") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("accommodations", "rate", newValue)
                    }
                  />
                </Box>
              </Box>

              <OFDTextNote
                id="note"
                label="note"
                value={getFieldValue("accommodations", "note") || ""}
                onChange={(newValue) =>
                  updateFieldValue("accommodations", "note", newValue)
                }
                fullWidth
              />
            </Stack>
          </OFDCollapsable>

          <OFDCollapsable
            header={getCarRentalHeader()}
            styleOverride={{ ...getColor({ color: "grey", shade: 200 }) }}
            arrowColor="grey"
            arrowShade={900}
          >
            <Stack
              spacing={1}
              sx={{ padding: ".5rem" }}
            >
              <Box
                sx={{ display: "flex" }}
                gap={0.5}
              >
                <Box sx={{ width: "50%" }}>
                  <OFDDate
                    id="fromDate"
                    label="from"
                    value={getFieldValue("carRental", "fromDate") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("carRental", "fromDate", newValue)
                    }
                  />
                </Box>

                <Box sx={{ width: "50%" }}>
                  <OFDDate
                    id="toDate"
                    label="to"
                    value={getFieldValue("carRental", "toDate") || ""}
                    onChange={(newValue) =>
                      updateFieldValue("carRental", "toDate", newValue)
                    }
                  />
                </Box>
              </Box>

              <OFDTextField
                id="name"
                label="name"
                value={getFieldValue("carRental", "name") || ""}
                onChange={(newValue) =>
                  updateFieldValue("carRental", "name", newValue)
                }
                fullWidth
              />

              <OFDTextNote
                id="description"
                label="description"
                value={getFieldValue("carRental", "description") || ""}
                onChange={(newValue) =>
                  updateFieldValue("carRental", "description", newValue)
                }
                fullWidth
              />

              <OFDCurrency
                id="rate"
                label="rate"
                value={getFieldValue("carRental", "rate") || ""}
                onChange={(newValue) =>
                  updateFieldValue("carRental", "rate", newValue)
                }
                fullWidth
              />

              <OFDTextNote
                id="note"
                label="note"
                value={getFieldValue("carRental", "note") || ""}
                onChange={(newValue) =>
                  updateFieldValue("carRental", "note", newValue)
                }
                fullWidth
              />
            </Stack>
          </OFDCollapsable>
        </Stack>
      </Box>
    </OFDModal>
  );
};

export default ItineraryEditor;
