import { useEffect, useState } from "react";

import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

import { Box, Collapse, Popover, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../ui/OFDIcon";

const OFDCollapsable = ({
  id,
  header,
  startOpen,
  children,
  compressed = false,
  styleOverride,
  headerStyle,
  contentStyle,
  arrowColor,
  arrowShade,
  menuItems,
  onMenuClick,
  openRight,
  onOpenRight,
  onOpen,
  onClose,
}) => {
  const { getLabel } = useLocalization();
  const theme = useTheme();

  const [open, setOpen] = useState();

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);

  const containerStyle = {
    borderRadius: "8px",
    borderBottomLeftRadius: compressed && open ? "0px" : "10px",
    borderBottomRightRadius: compressed && open ? "0px" : "10px",
    backgroundColor: "#fff",
    width: compressed ? "98%" : "100%",
    marginLeft: compressed ? "1%" : "0px",
    boxShadow: theme.shadows[1],
    ...styleOverride,
  };

  const headerBoxStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: ".5rem",
    borderRadius: "8px",
    height: compressed ? "38px" : "auto",
    ...headerStyle,
  };

  const collapseStyle = {
    width: "100%",
  };

  const contentContainerStyle = {
    marginTop: "4px",
    backgroundColor: "#f8f8f8",
    width: compressed ? "98%" : "100%",
    marginLeft: compressed ? "1%" : "0px",
    borderRadius: "10px",
    ...contentStyle,
  };

  useEffect(() => {
    if (startOpen) setOpen(true);
  }, [startOpen]);

  useEffect(() => {
    if (onOpen && open) onOpen(id);
    if (onClose && !open) onClose(id);
  }, [open]);

  const handleOpenMenu = (e) => {
    setAnchorE1(e.target);
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorE1(null);
    setOpenMenu(false);
  };

  const handleMenuClick = (option) => {
    handleCloseMenu();
    onMenuClick(option);
  };

  const handleOpenRight = () => {
    let newState = !open;
    setOpen(newState);
    onOpenRight(true);
  };

  return (
    <Box>
      <Box
        id={id}
        sx={containerStyle}
      >
        <Box sx={headerBoxStyle}>
          <Box
            sx={{ width: "100%", paddingRight: ".5rem", cursor: "pointer" }}
            onClick={openRight ? handleOpenRight : null}
          >
            {header}
          </Box>
          {menuItems || children ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "36px",
              }}
              gap={1}
            >
              {menuItems && menuItems.length > 0 ? (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                    onClick={handleOpenMenu}
                  >
                    <OFDIcon
                      name="verticalEllipse"
                      color="grey"
                    />
                  </Box>

                  <Popover
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorEl={anchorE1}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Stack
                      sx={{ padding: "1rem", minWidth: "150px" }}
                      spacing={3}
                    >
                      {menuItems?.map((item) => (
                        <Box
                          key={item.value}
                          onClick={() => handleMenuClick(item.value)}
                          sx={{ display: "flex" }}
                          gap={1}
                        >
                          {item.icon ? <OFDIcon name={item.icon} /> : null}
                          <Typography noWrap>{getLabel(item.label)}</Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Popover>
                </Box>
              ) : null}

              {children ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <OFDIcon
                    name={open ? "arrowup" : "arrowdown"}
                    color={arrowColor ? arrowColor : "grey"}
                    shade={arrowShade ? arrowShade : null}
                    onClick={() => setOpen((currentState) => !currentState)}
                  />
                </Box>
              ) : (
                <Box sx={{ width: "28px" }}></Box>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Collapse
        in={open}
        timeout={500}
        sx={collapseStyle}
      >
        <Box sx={{ ...contentContainerStyle }}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default OFDCollapsable;
