import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useData from "../../hooks/useData";
import useWindow from "../../hooks/useWindow";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDTextNote from "./OFDTextNote";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDDisplayNote from "./OFDDisplayNote";

import OFDDialog from "../layout/OFDDialog";

const fieldsTemplate = {
  name: "",
  note: "",
};

const OFDIngredients = ({ id, label, value, onChange, wizard }) => {
  const { getLabel } = useLocalization();
  const { newId } = useData();
  const { isDesktop, windowHeight } = useWindow();

  const [fields, setFields] = useState({ ...fieldsTemplate });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleCancel = () => {
    setEditMode("add");
    setFields({ ...fieldsTemplate });
    setSelectedItem(null);
  };

  const handleAddItem = () => {
    setEditMode("add");
    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields, id: newId() }];
    } else {
      list = [{ ...fields, id: newId() }];
    }

    handleChange(list);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
  };

  const handleUpdateItem = () => {
    let list = [];

    for (const bp of value) {
      if (bp.id !== selectedItem.id) {
        list.push({ ...bp });
      } else {
        list.push({ ...fields });
      }
    }

    handleChange(list);
  };

  const handleDeleteItem = () => {
    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const bp of value) {
        if (bp.id !== selectedItem.id) {
          list.push({ ...bp });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const handleChange = (sortedList) => {
    onChange(sortedList);
    setFields({ ...fieldsTemplate });
    setEditMode("add");
    setSelectedItem(null);
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  return (
    <>
      <Box id={id}>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {label}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          gap={1}
        >
          <Box sx={{ width: "60%" }}>
            <OFDTextField
              id="name"
              label="ingredient"
              value={getFieldValue("name") || ""}
              onChange={(newValue) => updateFieldValue("name", newValue)}
              fullWidth
            />
          </Box>
          <Box sx={{ width: "40%" }}>
            <OFDTextField
              id="quantity"
              label="quantity"
              value={getFieldValue("quantity") || ""}
              onChange={(newValue) => updateFieldValue("quantity", newValue)}
              fullWidth
            />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDTextNote
            id="note"
            label="note"
            value={getFieldValue("note") || ""}
            onChange={(newValue) => updateFieldValue("note", newValue)}
            fullWidth
          />
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={selectedItem ? handleUpdateItem : handleAddItem}
            >
              {selectedItem ? "Update" : "Add"}
            </Button>
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box
          sx={{
            maxHeight: `${wizard ? windowHeight - 450 : windowHeight - 500}px`,
            overflow: "auto",
          }}
        >
          <Stack spacing={1}>
            {Array.isArray(value)
              ? value.map((item) => (
                  <Box key={item.id}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#ccc",
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      gap={1}
                      onClick={() => handleEditItem(item)}
                    >
                      <Box
                        sx={{ display: "flex" }}
                        gap={1}
                      >
                        <Box>
                          <Typography>{`${item.name}`}</Typography>
                        </Box>
                        <Box>
                          {item.quantity ? (
                            <Typography noWrap>{item.quantity}</Typography>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>

                    {item.note ? (
                      <Box
                        sx={{
                          backgroundColor: "#ebebeb",
                          borderRadius: "4px",
                          marginTop: "4px",
                          paddingTop: ".25rem",
                          paddingBottom: ".25rem",
                          paddingLeft: ".5rem",
                          maxHeight: "100px",
                          overflow: "auto",
                          fontSize: "12px",
                          color: "#535353",
                        }}
                      >
                        <OFDDisplayNote
                          value={item.note}
                          textOnly={true}
                          styleoverride={{
                            fontSize: "12px",
                            color: "#535353",
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ))
              : null}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default OFDIngredients;
