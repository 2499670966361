import { useEffect } from "react";

import useColor from "../../hooks/useColor";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDCategoryIcon = ({ id, label, onClick, selected, size = "xlarge" }) => {
  const { getColor } = useColor();

  const sizes = {
    xxsmall: 12,
    xsmall: 16,
    small: 24,
    medium: 32,
    large: 40,
    xlarge: 48,
    xxlarge: 56,
    xxxlarge: 128,
  };

  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  if (!id) return null;

  return (
    <Stack sx={{ width: `${sizes[size]}px` }}>
      <Box
        sx={{
          backgroundColor: getColor({
            color: "grey",
            shade: 300,
          }).backgroundColor,
          padding: "6px",
          borderRadius: "4px",
          width: `${sizes[size] + 6}px`,
          height: `${sizes[size] + 6}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleClick}
      >
        <OFDIcon
          name={id}
          size={size}
          shade={selected ? 900 : 500}
          color="grey"
          onClick={handleClick}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="caption"
          noWrap
          sx={{ color: "#666" }}
        >
          {label}
        </Typography>
      </Box>
    </Stack>
  );
};

export default OFDCategoryIcon;
