import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

const BudgetIntro = () => {
  const { getWizard } = useLocalization();

  return (
    <Box sx={{ paddingBottom: "5rem" }}>
      <Stack spacing={2}>
        {getWizard("budget").budgetIntro.map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default BudgetIntro;
