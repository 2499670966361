import { Box, Button, Stack } from "@mui/material";

const Debug = () => {
  const handleThrowError = () => {
    throw new Error("Manual Error");
  };

  return (
    <>
      <Stack spacing={2}>
        <Button onClick={handleThrowError}>Throw Error</Button>
      </Stack>
    </>
  );
};

export default Debug;
