import { createTheme } from "@mui/material/styles";

// Material UI
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from "@mui/material/colors";

const lightFont = "#ffffff";
const darkFont = "#000000";

// main: "#ea751f",
// light: "#f0995c",
// dark: "#91460e",
// contrastText: "#f0f0f0",

const lightTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      desktop: 1024,
      monitor: 1200,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#ea751f",
      light: "#f0995c",
      dark: "#91460e",
      contrastText: "#f0f0f0",
    },
    error: {
      main: "#ff0000",
    },
    default: {
      main: "#ea751f",
      mainContrast: "#f0f0f0",
      light: "#f0995c",
      lightContrast: "#000000",
      dark: "#91460e",
      darkContrast: "#f0f0f0",
    },
    red: {
      main: red[500],
      mainContrast: lightFont,
      light: red[200],
      lightContrast: lightFont,
      dark: red[900],
      darkContrast: lightFont,
    },
    pink: {
      main: pink[500],
      mainContrast: lightFont,
      light: pink[200],
      lightContrast: lightFont,
      dark: pink[900],
      darkContrast: lightFont,
    },
    purple: {
      main: purple[500],
      mainContrast: lightFont,
      light: purple[200],
      lightContrast: lightFont,
      dark: purple[900],
      darkContrast: lightFont,
    },
    deepPurple: {
      main: deepPurple[500],
      mainContrast: lightFont,
      light: deepPurple[200],
      lightContrast: lightFont,
      dark: deepPurple[900],
      darkContrast: lightFont,
    },
    indigo: {
      main: indigo[500],
      mainContrast: lightFont,
      light: indigo[200],
      lightContrast: lightFont,
      dark: indigo[900],
      darkContrast: lightFont,
    },
    blue: {
      main: blue[500],
      mainContrast: lightFont,
      light: blue[200],
      lightContrast: lightFont,
      dark: blue[900],
      darkContrast: lightFont,
    },
    lightBlue: {
      main: lightBlue[500],
      mainContrast: lightFont,
      light: lightBlue[200],
      lightContrast: lightFont,
      dark: lightBlue[900],
      darkContrast: lightFont,
    },
    cyan: {
      main: cyan[500],
      mainContrast: lightFont,
      light: cyan[200],
      lightContrast: lightFont,
      dark: cyan[900],
      darkContrast: lightFont,
    },
    teal: {
      main: teal[500],
      mainContrast: lightFont,
      light: teal[200],
      lightContrast: lightFont,
      dark: teal[900],
      darkContrast: lightFont,
    },
    green: {
      main: green[500],
      mainContrast: lightFont,
      light: green[200],
      lightContrast: lightFont,
      dark: green[900],
      darkContrast: lightFont,
    },
    lightGreen: {
      main: lightGreen[500],
      mainContrast: lightFont,
      light: lightGreen[200],
      lightContrast: lightFont,
      dark: lightGreen[900],
      darkContrast: lightFont,
    },
    lime: {
      main: lime[500],
      mainContrast: darkFont,
      light: lime[200],
      lightContrast: darkFont,
      dark: lime[900],
      darkContrast: lightFont,
    },
    yellow: {
      main: yellow[500],
      mainContrast: darkFont,
      light: yellow[200],
      lightContrast: darkFont,
      dark: yellow[900],
      darkContrast: lightFont,
    },
    amber: {
      main: amber[500],
      mainContrast: lightFont,
      light: amber[200],
      lightContrast: lightFont,
      dark: amber[900],
      darkContrast: lightFont,
    },
    orange: {
      main: orange[500],
      mainContrast: lightFont,
      light: orange[200],
      lightContrast: lightFont,
      dark: orange[900],
      darkContrast: lightFont,
    },
    deepOrange: {
      main: deepOrange[500],
      mainContrast: lightFont,
      light: deepOrange[200],
      lightContrast: lightFont,
      dark: deepOrange[900],
      darkContrast: lightFont,
    },
    brown: {
      main: brown[500],
      mainContrast: lightFont,
      light: brown[200],
      lightContrast: lightFont,
      dark: brown[900],
      darkContrast: lightFont,
    },
    grey: {
      main: grey[500],
      mainContrast: lightFont,
      light: grey[200],
      lightContrast: lightFont,
      dark: grey[900],
      darkContrast: lightFont,
    },
    blueGrey: {
      main: blueGrey[500],
      mainContrast: lightFont,
      light: blueGrey[200],
      lightContrast: lightFont,
      dark: blueGrey[900],
      darkContrast: lightFont,
    },
    white: {
      main: grey[50],
      mainContrast: darkFont,
    },
  },
  page: {
    backgroundColor: "#ebebeb",
    color: darkFont,
  },
});

export default lightTheme;
