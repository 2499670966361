import { useEffect, useState } from "react";

import useRecipe from "../../../../hooks/useRecipe";

import { Box, Typography } from "@mui/material";

import RecipeDisplayModal from "../../../display/RecipeDisplayModal";

const MealItem = ({ mealItem }) => {
  const { getRecipe } = useRecipe();

  const [recipe, setRecipe] = useState(null);
  const [showRecipe, setShowRecipe] = useState(false);

  useEffect(() => {
    if (mealItem.recipeId) {
      getData();
    }
  }, [mealItem]);

  const getData = async () => {
    const recipe = await getRecipe(mealItem.recipeId);
    if (recipe) {
      setRecipe(recipe);
    }
  };

  const handleRecipeClick = () => {
    if (!recipe) return;

    setShowRecipe(true);
  };

  return (
    <>
      <Box sx={{ paddingLeft: "1rem", borderBottom: "1px solid #ccc" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box onClick={handleRecipeClick}>
            <Typography>{mealItem.name}</Typography>
            {mealItem.note ? (
              <Typography
                variant="caption"
                sx={{ paddingLeft: ".5rem", whiteSpace: "pre-wrap" }}
              >
                {mealItem.note}
              </Typography>
            ) : null}
          </Box>

          {recipe ? (
            <Box>
              <img
                src={recipe.image}
                width="70px"
                height="auto"
                style={{ borderRadius: "8px" }}
                onClick={handleRecipeClick}
              />
            </Box>
          ) : null}
        </Box>
      </Box>

      {showRecipe ? (
        <RecipeDisplayModal
          open={showRecipe}
          recipe={recipe}
          onClose={() => setShowRecipe(false)}
        />
      ) : null}
    </>
  );
};

export default MealItem;
