import React from "react";

import useMember from "../../../../hooks/useMember";
import useColor from "../../../../hooks/useColor";

import { Box, Typography } from "@mui/material";

import OFDAvatar from "../../../ui/OFDAvatar";

const MemberWidget = ({ memberId, height, headerContent, children }) => {
  const { getMember, getMemberColor } = useMember();
  const { getColor } = useColor();

  if (!memberId) return;

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "1rem",
            alignItems: "center",
            width: "300px",
            height: "3rem",
            ...getColor({ color: getMemberColor(memberId) }),
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <OFDAvatar avatar={getMember(memberId).avatar} />
            <Typography variant="h6">{getMember(memberId).name}</Typography>
          </Box>

          {headerContent ? <Box>{headerContent}</Box> : null}
        </Box>
        <Box
          sx={{
            height: `${height - 100}px`,
            overflow: "auto",
            backgroundColor: "#f0f0f0",
            borderBottomLeftRadius: "1rem",
            borderBottomRightRadius: "1rem",
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default MemberWidget;
