import { format } from "date-fns";

import useApi from "./useApi";
import { useSelector } from "react-redux";

const useDebug = (enable) => {
  const { postData } = useApi();

  const page = useSelector((state) => state.page.page);
  const member = useSelector((state) => state.member);

  const consoleLog = (source, message, object) => {
    if (!enable) return;

    if (!object) {
      console.log(
        `[${source}] ${message} : ${format(new Date(), "hh:mm:ssa")}`
      );
    } else {
      console.log(
        `[${source}] ${message} : ${format(new Date(), "hh:mm:ssa")}`,
        object
      );
    }
  };

  const logToServer = async (name, details) => {
    const data = {
      environment: process.env.REACT_APP_ENVIRONMENT,
      category: "web",
      name,
      type: "consoleLog",
      details,
      currentPage: page,
      memberId: member.id,
    };

    const results = await postData({
      entityName: "Log",
      action: "add",
      data,
    });
  };

  return {
    consoleLog,
    logToServer,
  };
};

export default useDebug;
