import { useEffect, useState } from "react";
import { getMonth } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";
import useExpense from "../../hooks/useExpense";
import useSearch from "../../hooks/useSearch";
import usePage from "../../hooks/usePage";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import ExpensesForCompany from "./ExpensesForCompany";
import Loading from "../layout/Loading";

const ExpensesByCompany = ({ year, filter, ytd }) => {
  const { displayLocalCurrency, getMessage } = useLocalization();
  const { refresh } = usePage();

  const {
    getExpenseCalendar,
    expenseCalendar,
    calculateCompanyTotals,
    calculateYearlyTotal,
  } = useExpense();

  const {
    data,
    setData,
    searchData,
    searchResults,
    searchCriteria,
    setSearchCriteria,
  } = useSearch();

  const [companyTotals, setCompanyTotals] = useState(undefined);

  useEffect(() => {
    getData();
    setSearchCriteria(filter);
  }, [year, refresh, filter, ytd]);

  const getData = async () => {
    if (!year) return;

    await getExpenseCalendar(year);
  };

  useEffect(() => {
    setData(expenseCalendar);
  }, [expenseCalendar]);

  useEffect(() => {
    searchData();
  }, [data]);

  useEffect(() => {
    setCompanyTotals(undefined);
    setCompanyTotals(calculateCompanyTotals(searchResults, ytd));
  }, [searchResults]);

  if (companyTotals === undefined) return <Loading />;

  return (
    <>
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {Array.isArray(companyTotals) ? (
            <Grid
              container
              spacing={2}
            >
              {companyTotals?.map((companyTotal) => (
                <Grid
                  key={`month_${companyTotal.name}`}
                  mobile={4}
                >
                  <ExpensesForCompany
                    year={year}
                    {...companyTotal}
                    onChange={getData}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default ExpensesByCompany;
