import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  localVersion: localStorage.getItem("localVersion"),
  currentVersion: localStorage.getItem("currentVersion"),
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setLocalVersion(state, action) {
      const { localVersion } = action.payload;
      state.localVersion = localVersion;
      state.currentVersion = localVersion;
    },
    setCurrentVersion(state, action) {
      const { currentVersion } = action.payload;
      state.currentVersion = currentVersion;
    },
  },
});

export const systemActions = systemSlice.actions;
export default systemSlice.reducer;
