import { useState } from "react";

import useWindow from "../../../../hooks/useWindow";

import { Box, Stack, Typography, Modal } from "@mui/material";

import OFDIcon from "../../../ui/OFDIcon";

import OFDLogo from "../../../../assets/images/OFD_hrzntl_logo_Black.svg";
import Tablet from "../../../../assets/images/Tablet_Smartphone_851x315_WhiteBkgrd.jpg";
import Security from "../../../../assets/images/Data-Security-RoundCorners.png";

import ToolView from "./ToolView";

const Tools = [
  {
    label: "Family",
    image: "Family",
    summary:
      "Streamline reminders by scheduling one-time, daily, weekly, or monthly alerts and notifying multiple family members simultaneously for efficiency.",
  },
  {
    label: "Shared Calendar",
    image: "Calendar",
    summary:
      "Effortlessly track family schedules with colour-coded appointments, events, and activities for each member in a centralized view.",
  },
  {
    label: "Reminders",
    image: "Reminders",
    summary:
      "Effortlessly track family schedules with colour-coded appointments, events, and activities for each member in a centralized view.",
  },
  {
    label: "To Dos",
    image: "ToDo",
    summary:
      "Enhance task scheduling with one-time or recurring to-dos, customized folders for material lists, and detailed task information for efficiency.",
  },
  {
    label: "Contacts",
    image: "Contacts",
    summary:
      "Securely manage family contacts with shared or private access, folders for organization, and the ability to upload documents or capture business cards.",
  },
  {
    label: "Grocery Lists",
    image: "Groceries",
    summary:
      "Simplify shopping with preset items, tick off as you go, and seamlessly track expenses by updating your budget in real-time.",
  },
  {
    label: "Recipes",
    image: "Recipes",
    summary:
      "Easily store family recipes, import from the web, or link favorite recipes from websites for convenient access and organization.",
  },
  {
    label: "Meal Plans",
    image: "MealPlans",
    summary:
      "Effortlessly plan weekly meals by choosing recipes and instantly adding required ingredients to your grocery list for seamless shopping.",
  },
  {
    label: "Household Budgeting",
    image: "Budgets",
    summary:
      "Effectively manage finances by crafting annual budgets; differentiate between household and vacation property expenses for clearer financial oversight and planning.",
  },
  {
    label: "ExpenseTracking",
    image: "Expenses",
    summary:
      "Monitor spending against budgets to gain insight into expenditure patterns and ensure your money aligns with your financial goals.",
  },
  {
    label: "Vehicle Management",
    image: "Vehicles",
    summary:
      "Efficiently monitor maintenance, mileage, gas, and service logs. Seamlessly integrate vehicle expenses into your budget for comprehensive financial tracking.",
  },
  {
    label: "Travel / Trips",
    image: "Trips",
    summary:
      "Craft detailed travel itineraries with transportation, car, and accommodation specifics. Easily upload boarding passes, travel insurance documents, and additional essentials.",
  },
  {
    label: "Chores",
    image: "Chores",
    summary:
      "Allocate chores to your kids on a schedule and reward them with tokens redeemable for desired items as incentives.",
  },
  {
    label: "Homework Tracker",
    image: "Homework",
    summary:
      "Efficiently monitor school tasks, tests, and exams. Easily upload documents, include notes, and link reference materials for comprehensive organization.",
  },
  {
    label: "Subscription Manager",
    image: "Subscriptions",
    summary:
      "Effectively oversee subscription dates, costs. Integrate them into your budget and set reminders to review before the next renewal.",
  },
  {
    label: "Password Manager",
    image: "Passwords",
    summary:
      "Effortlessly organize and access logins, passwords, and door codes. Copy them conveniently for seamless use when required.",
  },
  {
    label: "Document Management",
    image: "Documents",
    summary:
      "Centralize and store documents securely. Share with family members or restrict access for increased privacy and security measures.",
  },
  {
    label: "Notes",
    image: "Notes",
    summary:
      "Capture diverse notes with audio clips, addresses, URLs, and more. Share with family members while controlling access levels effectively.",
  },
];

const FeaturesModal = ({ open, onClose }) => {
  const { windowWidth, windowHeight } = useWindow();

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: `${windowWidth - 10}px`,
          height: `${windowHeight - 20}px`,
          backgroundColor: "#FFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "40px",
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
        >
          <Box
            sx={{
              border: "1px solid orange",
              borderRadius: "50%",
            }}
          >
            <OFDIcon
              name="close"
              onClick={onClose}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: `${windowWidth - 10}px`,
            height: `${windowHeight - 60}px`,
            overflow: "auto",
            padding: "1rem",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              alt="logo"
              src={OFDLogo}
              style={{
                height: "70px",
                width: "auto",
              }}
            />
          </Box>

          <Stack
            spacing={1}
            sx={{ marginTop: "1rem" }}
          >
            <Typography
              align="center"
              sx={{ fontSize: "16px", fontWeight: 700, color: "#ea751f" }}
            >
              Integrates family smartphones with a central tablet for a unified
              experience
            </Typography>

            <img
              alt="tablet"
              src={Tablet}
              style={{
                width: `${windowWidth - 10}px`,
                height: "auto",
              }}
            />

            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              Strategically position tablets in commonly frequented rooms within
              your home to provide convenient access for all family members.
            </Typography>

            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              By placing these devices in areas such as the living room,
              kitchen, or shared spaces, everyone can easily track and manage
              daily activities, schedules, and important updates.
            </Typography>

            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              This setup promotes seamless communication, coordination, and
              organization among family members, ensuring that everyone stays
              informed and connected throughout their day.
            </Typography>

            <Typography
              variant="h5"
              align="center"
              sx={{ color: "#ea751f" }}
            >
              TOOLS
            </Typography>

            <Stack spacing={2}>
              {Tools.map((toolInfo) => (
                <ToolView
                  id={toolInfo.label}
                  toolInfo={toolInfo}
                />
              ))}
            </Stack>

            <Typography
              variant="h5"
              align="center"
              sx={{ color: "#ea751f" }}
            >
              SECURITY
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="security"
                src={Security}
                style={{
                  width: `${windowWidth - 40}px`,
                  height: "auto",
                }}
              />
            </Box>

            <Typography variant="body2">
              Your data is securely stored and processed within the region where
              you reside, such as the US or Canada. This localization ensures
              compliance with regional data protection laws and enhances data
              privacy.
            </Typography>

            <Typography variant="body2">
              Additionally, we implement top-level encryption techniques on each
              piece of your data, safeguarding it from unauthorized access or
              malicious intent.
            </Typography>

            <Typography variant="body2">
              This multi-layered security approach guarantees that your
              information remains safe, confidential, and well-protected against
              any potential threats or bad actors in the digital landscape.
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default FeaturesModal;
