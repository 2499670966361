import { TextField } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

const OFDEmail = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  multiline,
  fullWidth,
  security,
  entryAddedBy,
}) => {
  const { labels, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <TextField
      id={id}
      label={labels[label] ? labels[label] : label}
      type="email"
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      helperText={message ? getMessage(message) : ""}
      error={error}
      required={required}
      disabled={isDisabled()}
      multiline={multiline}
      fullWidth={fullWidth}
      size="small"
    />
  );
};

export default OFDEmail;
