import { useEffect, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import useForm from "../../hooks/useForm";
import useEntity from "../../hooks/useEntity";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useApi from "../../hooks/useApi";

import OFDModal from "../layout/OFDModal";
import OFDMessage from "../ui/OFDMessage";
import OFDToolbar from "../layout/OFDToolbar";
import OFDDialog from "../layout/OFDDialog";
import OFDAvatar from "../ui/OFDAvatar";

import OFDSwitch from "../ui/OFDSwitch";
import OFDTextField from "../ui/OFDTextField";
import OFDPasswordField from "../ui/OFDPasswordField";
import OFDSelect from "../ui/OFDSelect";
import OFDEmail from "../ui/OFDEmail";

const AccountEditor = ({ account, open, onClose, onSave }) => {
  const { entityConfig, defaultValues } = useEntity("Member");
  const { validateAccount, member } = useMember();
  const { getLabel } = useLocalization();
  const { getData } = useApi();

  const {
    data,
    setData,

    setFieldValue,
    getFieldValue,
    getFieldMessage,
    getFieldLabel,
    getFieldError,
    getDataById,

    saveData,
    formMessage,
    formStatus,
    resetFormMessage,
    resetFieldMessages,

    validate,
  } = useForm("Member");

  const [categorySelector, setCategorySelector] = useState(false);
  const [accountSelector, setMemberSelector] = useState(false);

  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  useEffect(() => {
    if (open) {
      resetFormMessage();
      resetFieldMessages();
    }

    if (account) {
      setData(account);
    }
  }, [account, open]);

  const handleUserSwitch = (isUser) => {
    setFieldValue("isUser", isUser);

    if (!isUser) {
      setFieldValue("username", "");
      setFieldValue("password", "");
      setFieldValue("securityLevel", "family");
    }
  };

  const handleToolbarClick = async (name) => {
    switch (name) {
      case "save":
        await saveMember();
        break;
    }
  };

  const saveMember = async () => {
    await saveConfirmation("yes");
  };

  const saveConfirmation = async (response) => {
    setOpenSaveDialog(false);

    if (response === "no") {
      return;
    }

    resetFormMessage();
    resetFieldMessages();

    let validationResults = validate(validateAccount, data);

    if (!validationResults.isSuccessful) return;

    console.log({ data });

    const results = await saveData({
      action: "userAccount",
      data: data,
    });

    if (!results.isSuccessful) return;

    onSave();
  };

  if (!account) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="userAccount"
      >
        <Box sx={{ height: "100%" }}>
          <OFDToolbar
            sticky={true}
            menuItems={[{ name: "save", label: "save", icon: "save" }]}
            onClick={handleToolbarClick}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
            gap={1}
          >
            <OFDAvatar
              avatar={account.avatar}
              name={account.name}
              borderColor={account.color}
              size="large"
            />
            <Typography variant="h5">{account.name}</Typography>
          </Box>

          <Box sx={{ padding: "1rem" }}>
            <OFDSwitch
              id="isUser"
              label="isUser"
              value={getFieldValue("isUser")}
              onChange={(newValue) => handleUserSwitch(newValue)}
            />
          </Box>

          {getFieldValue("isUser") ? (
            <Stack
              sx={{ padding: "1rem" }}
              spacing={2}
            >
              <OFDTextField
                id="username"
                label="username"
                value={getFieldValue("username")}
                onChange={(newValue) => setFieldValue("username", newValue)}
                error={getFieldError("username")}
                message={getFieldMessage("username")}
                required
              />

              {account.id === member.id || !account.isUser || !account.email ? (
                <OFDPasswordField
                  id="password"
                  label="newPassword"
                  value={getFieldValue("password")}
                  onChange={(newValue) => setFieldValue("password", newValue)}
                  error={getFieldError("password")}
                  message={getFieldMessage("password")}
                  required
                />
              ) : null}

              <OFDSelect
                id="securityLevel"
                label="securityLevel"
                value={getFieldValue("securityLevel") || "family"}
                onChange={(newValue) =>
                  setFieldValue("securityLevel", newValue)
                }
                error={getFieldError("securityLevel")}
                message={getFieldMessage("securityLevel")}
                listName="securityLevels"
                variant="outlined"
              />

              <OFDTextField
                id="email"
                label="email"
                value={getFieldValue("email")}
                onChange={(newValue) => setFieldValue("email", newValue)}
                error={getFieldError("email")}
                message={getFieldMessage("email")}
              />
            </Stack>
          ) : null}
        </Box>
      </OFDModal>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <OFDDialog
        open={openSaveDialog}
        title="confirmSaveSeries"
        description="confirmSaveSeries"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={saveConfirmation}
      />
    </>
  );
};

export default AccountEditor;
