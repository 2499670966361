import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { messagingActions } from "../store/messaging";

const useMessaging = () => {
  const dispatch = useDispatch();

  const reminders = useSelector((state) => state.messaging.reminders);
  const notifications = useSelector((state) => state.messaging.notifications);

  const addReminder = (reminder) => {
    dispatch(messagingActions.addReminder(reminder));
  };

  const clearReminders = () => {
    dispatch(messagingActions.clearReminders());
  };

  const addNotification = (notification) => {
    dispatch(messagingActions.addNotification(notification));
  };

  const clearNotifications = () => {
    dispatch(messagingActions.clearNotifications());
  };

  return {
    reminders,
    addReminder,
    clearReminders,

    notifications,
    addNotification,
    clearNotifications,
  };
};

export default useMessaging;
