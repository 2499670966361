import { useEffect, useState } from "react";
import { isBefore, startOfToday } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";

import { Box, Stack, Typography } from "@mui/material";

import OFDSelect from "./OFDSelect";
import OFDMultiSelect from "./OFDMultiSelect";
import OFDDate from "./OFDDate";

import OFDDailyOptions from "./OFDDailyOptions";
import OFDWeeklyOptions from "./OFDWeeklyOptions";
import OFDMonthlyOptions from "./OFDMonthlyOptions";
import OFDYearlyOptions from "./OFDYearlyOptions";
import OFDRecurrenceCount from "./OFDRecurrenceCount";
import OFDByDay from "./OFDByDay";
import OFDByMonthDay from "./OFDByMonthDay";
import OFDMultiDatePicker from "./OFDMultiDatePicker";

const OFDRecurrence = ({
  value,
  onChange,
  disabled,
  selectedDate,
  fieldErrors,
}) => {
  const { getLabel } = useLocalization();
  const { sort } = useData();

  const [recurrenceType, setRecurrenceType] = useState("forever");
  const [recurrenceValue, setRecurrenceValue] = useState(true);
  const [occurrenceStart, setOccurrenceStart] = useState(null);

  useEffect(() => {
    if (!value) return;

    if (value.forever) {
      updateRecurrence("forever", true);
    } else if (value.until) {
      updateRecurrence("until", value.until);
    } else if (value.count) {
      updateRecurrence("count", value.count);
    }
  }, [value]);

  const getValue = (property) => {
    let selectedValue = null;

    if (!value || !value.hasOwnProperty(property)) {
      if (property === "frequency") {
        selectedValue = "onetime";
      }
    } else {
      selectedValue = value[property];
    }

    return selectedValue;
  };

  const setValue = (property, newValue) => {
    let newRecurrence = value !== null ? { ...value } : {};
    newRecurrence[property] = newValue;

    if (property === "frequency") {
      newRecurrence.byMonth = null;
      newRecurrence.byMonthDay = null;
      newRecurrence.bySetPosition = null;
      newRecurrence.byDay = [];
      newRecurrence.interval = 1;
      newRecurrence.count = null;
      newRecurrence.until = null;
      newRecurrence.forever = true;
      newRecurrence.scheduleDates = null;
      newRecurrence.monthlyType = null;

      if (newValue === "monthly") {
        newRecurrence.monthlyType = "specific";
      }

      newRecurrence.occurrenceStart = selectedDate ? selectedDate : new Date();
    }

    if (property === "monthlyType") {
      newRecurrence.byDay = null;
      newRecurrence.byMonthDay = null;
    }

    if (property === "occurrenceStart") {
      setOccurrenceStart(newValue);
    }

    if (property === "scheduleDates" && newValue.length > 0) {
      let sortedDates = sort(newValue);
      newRecurrence.occurrenceStart = sortedDates[0];
      newRecurrence.scheduleDates = sortedDates;
      newRecurrence.forever = false;
    }

    onChange(newRecurrence);
  };

  const updateRecurrence = (type, newValue) => {
    setRecurrenceType(type);
    setRecurrenceValue(newValue);

    let newRecurrence = value ? { ...value } : {};

    if (type === "count") {
      newRecurrence.count = parseInt(newValue);
      newRecurrence.until = null;
      newRecurrence.forever = false;
    } else if (type === "until") {
      newRecurrence.until = newValue;
      newRecurrence.count = null;
      newRecurrence.forever = false;
    } else if (type === "forever") {
      newRecurrence.forever = true;
      newRecurrence.count = null;
      newRecurrence.until = null;
    }

    return newRecurrence;
  };

  const setRecurrenceCount = (type, newValue) => {
    const newRecurrence = updateRecurrence(type, newValue);

    onChange(newRecurrence);
  };

  const fieldError = (property) => {
    if (!Array.isArray(fieldErrors) || fieldErrors.length === 0) {
      return {};
    }
    const fieldError = fieldErrors.find((error) => error.name === property);
    if (!fieldError) return {};

    return {
      error: true,
      message: fieldError.message,
    };
  };

  return (
    <Box sx={{ border: "1px solid #ccc", padding: "8px", borderRadius: "8px" }}>
      <Box>
        <Typography
          sx={{ marginBottom: "8px" }}
          align="center"
        >
          {getLabel("recurrenceSettings")}
        </Typography>
      </Box>
      <Stack spacing={1}>
        <OFDSelect
          id="frequency"
          label="frequency"
          listName="calendarFrequency"
          value={getValue("frequency")}
          onChange={(newValue) => setValue("frequency", newValue)}
          disabled={disabled}
        />

        {/* DAILY */}
        {value.frequency === "daily" ? (
          <Box>
            <OFDDailyOptions
              interval={getValue("interval")}
              onChange={(newValue) => setValue("interval", newValue)}
              disabled={disabled}
              {...fieldError("interval")}
            />
          </Box>
        ) : null}

        {/* WEEKLY */}
        {value.frequency === "weekly" ? (
          <Stack spacing={1}>
            <OFDWeeklyOptions
              interval={getValue("interval")}
              onChange={(newValue) => setValue("interval", newValue)}
              disabled={disabled}
              {...fieldError("interval")}
            />

            <OFDByDay
              id="byDay"
              value={getValue("byDay")}
              onChange={(newValue) => setValue("byDay", newValue)}
              disabled={disabled}
              {...fieldError("byDay")}
            />
          </Stack>
        ) : null}

        {/* MONTHLY */}
        {value.frequency === "monthly" ? (
          <Stack spacing={1}>
            <OFDMonthlyOptions
              interval={getValue("interval")}
              onChange={(newValue) => setValue("interval", newValue)}
              disabled={disabled}
              {...fieldError("interval")}
            />

            <OFDSelect
              id="monthlyType"
              label="monthlyType"
              value={getValue("monthlyType")}
              onChange={(newValue) => setValue("monthlyType", newValue)}
              listName="monthlyTypes"
              disabled={disabled}
              {...fieldError("monthlyType")}
            />

            {value.monthlyType === "relative" ? (
              <OFDByDay
                id="byDay"
                value={getValue("byDay")}
                onChange={(newValue) => setValue("byDay", newValue)}
                allowPosition={true}
                disabled={disabled}
                {...fieldError("byDay")}
              />
            ) : (
              <OFDByMonthDay
                id="byMonthDay"
                value={getValue("byMonthDay")}
                onChange={(newValue) => setValue("byMonthDay", newValue)}
                disabled={disabled}
                {...fieldError("byMonthDay")}
              />
            )}
          </Stack>
        ) : null}

        {/* YEARLY */}
        {value.frequency === "yearly" ? (
          <Stack spacing={1}>
            <OFDYearlyOptions
              interval={getValue("interval")}
              onChange={(newValue) => setValue("interval", newValue)}
              disabled={disabled}
              {...fieldError("interval")}
            />

            <OFDMultiSelect
              id="byMonth"
              label="month"
              listName="months"
              value={getValue("byMonth") || []}
              onChange={(newValue) => setValue("byMonth", newValue)}
              disabled={disabled}
              {...fieldError("byMonth")}
            />

            <OFDSelect
              id="monthlyType"
              label="monthlyType"
              value={getValue("monthlyType")}
              onChange={(newValue) => setValue("monthlyType", newValue)}
              listName="monthlyTypes"
              disabled={disabled}
              {...fieldError("monthlyType")}
            />

            {value.monthlyType === "relative" ? (
              <OFDByDay
                id="byDay"
                value={getValue("byDay")}
                onChange={(newValue) => setValue("byDay", newValue)}
                allowPosition={true}
                disabled={disabled}
                {...fieldError("byDay")}
              />
            ) : (
              <OFDByMonthDay
                id="byMonthDay"
                value={getValue("byMonthDay")}
                onChange={(newValue) => setValue("byMonthDay", newValue)}
                disabled={disabled}
                {...fieldError("byMonthDay")}
              />
            )}
          </Stack>
        ) : null}

        {value.frequency === "schedule" ? (
          <OFDMultiDatePicker
            id="scheduleDates"
            value={getValue("scheduleDates")}
            onChange={(newValue) => setValue("scheduleDates", newValue)}
            {...fieldError("scheduleDates")}
          />
        ) : null}

        {value.frequency !== "onetime" && value.frequency !== "schedule" ? (
          <Stack spacing={1}>
            <OFDDate
              id="occurrenceStart"
              label="startsOn"
              value={getValue("occurrenceStart")}
              onChange={(newValue) => setValue("occurrenceStart", newValue)}
              disabled={disabled}
              required
              {...fieldError("occurrenceStart")}
            />

            <OFDRecurrenceCount
              type={recurrenceType}
              value={recurrenceValue}
              onChange={setRecurrenceCount}
              defaultValue={occurrenceStart}
              disabled={disabled}
              {...fieldError("count")}
            />
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};

export default OFDRecurrence;
