import { useEffect, useState } from "react";

import useMember from "../../hooks/useMember";
import useDebug from "../../hooks/useDebug";
import useData from "../../hooks/useData";
import useSecurity from "../../hooks/useSecurity";

import { Box } from "@mui/material";

import OFDFormControl from "./OFDFormControl";
import OFDHorizontalScroll from "./OFDHorizontalScroll";
import OFDAvatar from "./OFDAvatar";

import Colors from "../../configuration/Colors";

const OFDColor = ({
  id,
  label,
  value,
  onChange,
  memberData,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { userCanView, userCanUpdate } = useSecurity();
  const { colorAssignments, getMember, member } = useMember();
  const { consoleLog } = useDebug(false);
  const { sort } = useData();
  const [colorList, setColorList] = useState();
  const [scrollToStart, setScrollToStart] = useState(false);

  useEffect(() => {
    if (!memberData) return;
    consoleLog("OFDColor", "onLoad", { value });

    buildColorList();
  }, [value, memberData]);

  const handleChange = (color) => {
    if (isDisabled()) return;

    consoleLog("OFDColor", "handleChange", { color });

    onChange(color);
  };

  const buildColorList = () => {
    setColorList(() => {
      let list = [];

      for (const color of Colors) {
        let colorAssignment = colorAssignments()[color];

        if (colorAssignment && colorAssignment.memberId !== memberData.id) {
          list.push({ ...colorAssignment });
          continue;
        }

        if (color === value) {
          list.push({
            memberId: memberData.id,
            avatar: memberData.avatar,
            name: memberData.name,
            backgroundColor: color,
            borderColor: color,
          });

          continue;
        }

        list.push({
          memberId: "99999999",
          avatar: null,
          name: "",
          backgroundColor: color,
          borderColor: color,
          onClick: () => handleChange(color),
        });
      }

      let sortedList = sort(list, "memberId");
      consoleLog("OFDColor", "sortedList", sortedList);

      let colorList = [];

      for (const color of sortedList) {
        colorList.push(
          <OFDAvatar
            key={color.backgroundColor}
            {...color}
            size="small"
          />
        );
      }

      return colorList;
    });
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <OFDFormControl
      id={id}
      label={label}
    >
      <OFDHorizontalScroll resetScrollOnChange={true}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
          gap={1}
        >
          {colorList}
        </Box>
      </OFDHorizontalScroll>
    </OFDFormControl>
  );
};

export default OFDColor;
