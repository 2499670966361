import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import DocumentViewer from "./documentViewer/DocumentViewer";

const DocumentDisplay = ({ document, open, onClose }) => {
  if (!document) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="documentViewer"
        allowFullScreen
      >
        <DocumentViewer
          document={document}
          onClose={onClose}
        />
      </OFDModal>
    </>
  );
};

export default DocumentDisplay;
