import { useEffect, useState } from "react";

import useMember from "./useMember";

const useSetup = () => {
  const { member } = useMember();

  const [currentStep, setCurrentStep] = useState(undefined);

  const setupSteps = [
    { step: 0, name: "intro", securityLevel: "*" },
    { step: 1, name: "video", securityLevel: "*" },
    { step: 2, name: "avatarAndColor", securityLevel: "*" },
    { step: 3, name: "notifications", securityLevel: "*" },
    { step: 4, name: "family", securityLevel: "accountOwner" },
    { step: 5, name: "memberAccounts", securityLevel: "accountOwner" },
    { step: 6, name: "final", securityLevel: "*" },
  ];

  useEffect(() => {
    setCurrentStep(setupSteps[0]);
  }, []);

  const prevStep = () => {
    setCurrentStep((current) => {
      let nextStep;
      for (let stepIndex = current.step - 1; stepIndex <= 0; stepIndex--) {
        nextStep = stepIndex;
        if (setupSteps[stepIndex].securityLevel === "*") {
          break;
        }
        if (member.securityLevel === setupSteps[stepIndex].securityLevel) {
          break;
        }
      }

      return setupSteps[nextStep];
    });
  };

  const nextStep = () => {
    setCurrentStep((current) => {
      let nextStep;
      for (
        let stepIndex = current.step + 1;
        stepIndex < setupSteps.length;
        stepIndex++
      ) {
        nextStep = stepIndex;
        if (setupSteps[stepIndex].securityLevel === "*") {
          break;
        }
        if (member.securityLevel === setupSteps[stepIndex].securityLevel) {
          break;
        }
      }

      return setupSteps[nextStep];
    });
  };

  const isFirstStep = () => {
    if (!currentStep) return true;
    return currentStep.step === 0;
  };

  const isLastStep = () => {
    if (!currentStep) return false;

    return currentStep.step === setupSteps.length - 1;
  };

  return {
    setupSteps,
    currentStep,
    setCurrentStep,
    prevStep,
    nextStep,
    isFirstStep,
    isLastStep,
  };
};

export default useSetup;
