import { useEffect, useState } from "react";
import {
  startOfToday,
  startOfDay,
  addDays,
  subDays,
  format,
  parse,
} from "date-fns";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDIconButton from "./OFDIconButton";

const OFDDayOf = ({ value, onChange }) => {
  const { getLabel } = useLocalization();

  const handlePrevDay = () => {
    onChange(subDays(value, 1));
  };

  const handleNextDay = () => {
    onChange(addDays(value, 1));
  };

  const handleToday = () => {
    onChange(startOfToday());
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="overline"
            sx={{ marginBottom: "-1rem" }}
          >
            {getLabel("dayOf")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          gap={2}
        >
          <OFDIconButton
            name="arrowLeft"
            onClick={handlePrevDay}
          />
          <Box>
            <Typography variant="h6">
              {format(value, "MMMM dd, yyyy")}
            </Typography>
          </Box>
          <OFDIconButton
            name="arrowRight"
            onClick={handleNextDay}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              border: "1px solid #ccc",
              paddingTop: "2px",
              paddingBottom: "2px",
              paddingLeft: "4px",
              paddingRight: "4px",
              borderRadius: "8px",
              marginTop: "-.1rem",
              cursor: "pointer",
            }}
            onClick={handleToday}
          >
            <Typography variant="caption">{getLabel("gotoToday")}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OFDDayOf;
