import { Box, FormControl } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";

const OFDTime = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();
  const { memberColor } = useMember();

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box id={id}>
      <FormControl
        fullWidth
        size="small"
      >
        <TimePicker
          size="small"
          label={getLabel(label)}
          value={value}
          onChange={(newValue) => onChange(newValue)}
          sx={{
            "& .MuiOutlinedInput-input": {
              padding: "10px",
            },
            "& label.Mui-focused": {
              color: memberColor().backgroundColor,
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: memberColor().backgroundColor,
              },
            },
            width: "100%",
          }}
          slotProps={{
            toolbar: {
              sx: {
                "& .MuiPickersToolbar-content": {
                  "& .MuiTimePickerToolbar-ampmSelection": {
                    "& .MuiButtonBase-root": {
                      padding: "2px",
                      "& .MuiTypography-root.MuiPickersToolbarText-root.Mui-selected":
                        {
                          backgroundColor: "#000000",
                          color: "#ffffff",
                          padding: "4px",
                          borderRadius: "50%",
                        },
                    },
                  },
                },
              },
            },
          }}
          disabled={isDisabled()}
        />
      </FormControl>
    </Box>
  );
};

export default OFDTime;
