import { useState } from "react";

import { Box } from "@mui/material";

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import OFDSortableItem from "./OFDSortableItem";
import OFDIcon from "./OFDIcon";

const OFDSortableList = ({ list, onChange, onRemove, onEdit }) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      setActiveId(null);
      return;
    }

    const oldIndex = list.findIndex((item) => item.id === active.id);
    const newIndex = list.findIndex((item) => item.id === over.id);
    const newList = arrayMove(list, oldIndex, newIndex);

    onChange(newList);

    setActiveId(null);
  };

  return (
    <div>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={list}
          strategy={verticalListSortingStrategy}
        >
          {list?.map((item) => (
            <Box
              key={item.id}
              sx={{ display: "flex" }}
            >
              <OFDSortableItem
                item={item}
                isDragging={activeId === item.id}
              ></OFDSortableItem>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: ".5rem",
                }}
                gap={1}
              >
                {onEdit ? (
                  <OFDIcon
                    name="edit"
                    color="grey"
                    onClick={() => onEdit(item.id)}
                  />
                ) : null}
                {onRemove ? (
                  <OFDIcon
                    name="delete"
                    color="grey"
                    onClick={() => onRemove(item.id)}
                  />
                ) : null}
              </Box>
            </Box>
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default OFDSortableList;
