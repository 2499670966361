import {
  getLocalState,
  setLocalState,
  clearLocalState,
  deleteLocalState,
} from "../idb";

const CryptoJS = require("crypto-js");

const Utils = () => {
  const encrypt = (value) => {
    if (!value instanceof String) {
      return value;
    }

    if (!value || value.length === 0) {
      return value;
    }

    let hashedValue;

    try {
      hashedValue = CryptoJS.AES.encrypt(
        value,
        process.env.REACT_APP_LOCAL_KEY
      ).toString();

      return hashedValue;
    } catch (err) {
      return value;
    }
  };

  const decrypt = (hashedValue) => {
    let bytes;
    try {
      if (!hashedValue instanceof String) {
        return hashedValue;
      }

      if (hashedValue.length === 0) {
        return hashedValue;
      }

      bytes = CryptoJS.AES.decrypt(
        hashedValue,
        process.env.REACT_APP_LOCAL_KEY
      );

      let value = bytes.toString(CryptoJS.enc.Utf8);

      return value;
    } catch (err) {
      return hashedValue;
    }
  };

  const setData = async (data) => {
    if (!data) return;

    const stringData = JSON.stringify(data);
    const encryptedData = encrypt(stringData);
    // localStorage.setItem("data", encryptedData);
    await setLocalState("data", "state", encryptedData);
  };

  const getData = async () => {
    // const encryptedData = localStorage.getItem("data");
    const encryptedData = await getLocalState("data", "state");

    if (!encryptedData) return null;

    const decryptedData = decrypt(encryptedData);
    const data = JSON.parse(decryptedData);
    return data;
  };

  const setState = async (args) => {
    const stringData = JSON.stringify(args.data);
    const encryptedData = encrypt(stringData);
    await setLocalState(args.name, "state", { value: encryptedData });
  };

  const getState = async (args) => {
    const state = await getLocalState(args.name, "state");

    if (!state) return null;

    const decryptedData = decrypt(state.value);

    const data = JSON.parse(decryptedData);
    return data;
  };

  const getMemberId = async () => {
    const data = await getState({ name: "member" });
    if (data) {
      return data.id;
    }

    return null;
  };

  const clearAllState = async () => {
    await deleteLocalState("session", "state");
    await deleteLocalState("member", "state");
    await deleteLocalState("family", "state");
  };

  const clearState = async (args) => {
    await clearLocalState(args.name);
  };

  const memberState = async () => {
    let initialState = {
      id: null,
      name: null,
      email: null,
      avatar: null,
      color: null,
      preferences: null,
      securityLevel: "family",
      isFirstLogin: false,
    };

    let data = await getState({ name: "member" });

    if (!data) {
      return initialState;
    }

    return {
      ...initialState,
      ...data,
    };
  };

  const familyState = async () => {
    let initialState = {
      id: null,
      name: null,
      avatar: null,
      color: null,
      preferences: null,
      members: [],
      subscriptionPlanId: null,
      tier: null,
      loginLimit: null,
      isFirstLogin: false,
      rcUserId: null,
    };

    let data = await getState({ name: "family" });

    if (!data) {
      return initialState;
    }

    return {
      ...initialState,
      ...data,
    };
  };

  return {
    memberState,
    familyState,
    setData,
    getData,
    getState,
    setState,
    clearState,
    clearAllState,
    getMemberId,
  };
};

export default Utils;
