// Material UI
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from "@mui/material/colors";

const useColor = () => {
  const getColor = (args) => {
    const lightFont = "#ffffff";
    const darkFont = "#000000";

    let { color, shade } = args;

    if (color === "default") {
      color = "orange";
    }

    if (!shade) {
      shade = 500;
    }

    switch (color) {
      case "red":
        return {
          backgroundColor: red[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "pink":
        return {
          backgroundColor: pink[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "purple":
        return {
          backgroundColor: purple[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "deepPurple":
        return {
          backgroundColor: deepPurple[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "indigo":
        return {
          backgroundColor: indigo[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "blue":
        return {
          backgroundColor: blue[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "lightBlue":
        return {
          backgroundColor: lightBlue[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "cyan":
        return {
          backgroundColor: cyan[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "teal":
        return {
          backgroundColor: teal[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "green":
        return {
          backgroundColor: green[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "lightGreen":
        return {
          backgroundColor: lightGreen[shade],
          color: shade < 800 ? darkFont : lightFont,
        };
      case "lime":
        return {
          backgroundColor: lime[shade],
          color: shade < 800 ? darkFont : lightFont,
        };
      case "yellow":
        return {
          backgroundColor: yellow[shade],
          color: shade < 900 ? darkFont : lightFont,
        };
      case "amber":
        return {
          backgroundColor: amber[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "orange":
        return {
          backgroundColor: orange[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "deepOrange":
        return {
          backgroundColor: deepOrange[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "brown":
        return {
          backgroundColor: brown[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "grey":
        return {
          backgroundColor: grey[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
      case "blueGrey":
        return {
          backgroundColor: blueGrey[shade],
          color: shade < 400 ? darkFont : lightFont,
        };
    }
  };

  return {
    getColor,
  };
};

export default useColor;
