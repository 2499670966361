import { useEffect, useState } from "react";
import { format } from "date-fns";

import usePage from "../../../../hooks/usePage";
import useColor from "../../../../hooks/useColor";
import useGrocery from "../../../../hooks/useGrocery";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../../ui/OFDIcon";
import DepartmentItems from "./DepartmentItems";
import GroceryEditor from "./GroceryEditor";
import OFDButton from "../../../ui/OFDButton";
import OFDFieldContainer from "../../../ui/OFDFieldContainer";
import OFDDialog from "../../OFDDialog";

const GroceryListContainer = ({ groceryList, height, onUpdated }) => {
  const { getColor } = useColor();
  const { dashboardMode } = usePage();
  const { getLabel, getMessage } = useLocalization();
  const { deleteGrocery, completeGrocery } = useGrocery();

  const theme = useTheme();

  const [itemsByDepartment, setItemsByDepartment] = useState(undefined);
  const [openEditor, setOpenEditor] = useState(false);
  const [action, setAction] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (!groceryList) return;

    splitGroceryItems();
  }, [groceryList]);

  const splitGroceryItems = () => {
    if (
      !Array.isArray(groceryList.groceryItems) ||
      groceryList.groceryItems.length === 0
    ) {
      setItemsByDepartment(null);
      return;
    }

    let departments = [];
    for (const groceryItem of groceryList.groceryItems) {
      let idx = departments.findIndex(
        (department) => department === groceryItem.department
      );
      if (idx === -1) {
        departments.push(groceryItem.department);
      }
    }
    departments.sort();

    let byDepartment = [];

    for (const department of departments) {
      const items = groceryList.groceryItems.filter(
        (groceryItem) => groceryItem.department === department
      );

      byDepartment.push({
        department,
        items: [...items],
      });
    }

    setItemsByDepartment(byDepartment);
  };

  const handleOpenEditor = () => {
    setAction("editor");
  };

  const handleUpdate = () => {
    setOpenEditor(false);
    onUpdated();
  };

  const closeEditor = () => {
    setOpenEditor(false);
  };

  useEffect(() => {
    handleAction();
  }, [action]);

  const handleAction = async () => {
    if (action === "editor") {
      setOpenEditor(true);
    } else if (action === "delete") {
      setOpenDeleteDialog(true);
    } else if (action === "complete") {
      await completeGrocery(groceryList.id);
      onUpdated();
    }

    setAction(null);
  };

  const deleteGroceryList = async () => {
    setAction("delete");
  };

  const completeGroceryList = async () => {
    setAction("complete");
  };

  const deleteConfirmation = async (response) => {
    setOpenDeleteDialog(false);
    if (response == "yes") {
      await deleteGrocery(groceryList.id);
      onUpdated();
    }
  };

  return (
    <>
      <Box
        sx={{
          height: height,
          width: "300px",
          backgroundColor: "#f0f0f0",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            height: "48px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <OFDIcon
              name="grocery"
              color="grey"
              shade={50}
            />
            <Typography variant="h5">{groceryList.name}</Typography>
          </Box>

          {dashboardMode === "edit" ? (
            <Box
              sx={{
                border: "1px solid #fff",
                padding: "4px",
                borderRadius: "50%",
              }}
            >
              <OFDIcon
                name="edit"
                color="grey"
                shade={50}
                onClick={handleOpenEditor}
              />
            </Box>
          ) : null}
        </Box>

        {dashboardMode === "edit" ? (
          <OFDFieldContainer
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <OFDButton
              name="delete"
              label="delete"
              iconName="delete"
              onClick={deleteGroceryList}
            />

            <OFDButton
              name="complete"
              label="complete"
              iconName="checkCircle"
              onClick={completeGroceryList}
            />
          </OFDFieldContainer>
        ) : null}

        <Box
          sx={{
            height: `${height - 115}px`,
            overflow: "auto",
            padding: "1rem",
          }}
        >
          <Stack spacing={1}>
            <Box
              sx={{ display: "flex" }}
              gap={1}
            >
              <Typography sx={{ fontWeight: 900 }}>
                {getLabel("shoppingDate")} :{" "}
              </Typography>
              {groceryList.shoppingDate ? (
                <Typography>
                  {format(groceryList.shoppingDate, "EEE MMM dd")}
                </Typography>
              ) : (
                <Typography>{getLabel("notScheduled")}</Typography>
              )}
            </Box>

            {Array.isArray(itemsByDepartment) &&
            itemsByDepartment.length > 0 ? (
              <Stack spacing={1}>
                {itemsByDepartment.map((departmentItems, index) => (
                  <DepartmentItems
                    key={`items-${index}`}
                    departmentItems={departmentItems}
                  />
                ))}
              </Stack>
            ) : (
              <Typography>{getMessage("noGroceryItems")}</Typography>
            )}
          </Stack>
        </Box>
      </Box>

      {openEditor ? (
        <GroceryEditor
          groceryList={groceryList}
          open={openEditor}
          onClose={closeEditor}
          onUpdated={handleUpdate}
        />
      ) : null}

      <OFDDialog
        open={openDeleteDialog}
        title="confirmDelete"
        description="confirmDelete_groceryList"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default GroceryListContainer;
