import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDFormControl from "./OFDFormControl";

const OFDDayOfWeek = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { calendarLists } = useLocalization();
  const { memberColor, member } = useMember();
  const { userCanView, userCanUpdate } = useSecurity();

  const handleDowChange = (weekday) => {
    if (isDisabled()) return;

    let newDow = value
      ? [...value]
      : [false, false, false, false, false, false, false];

    let newValue = !newDow[weekday];

    newDow[weekday] = newValue;

    onChange(newDow);
  };

  const isDowSelected = (weekday) => {
    if (!value) return false;

    return value[weekday];
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box>
        <OFDFormControl label={label}>
          <Box sx={{ display: "flex" }}>
            {calendarLists?.weekdays.map((weekDay) => (
              <Box
                key={weekDay.label}
                sx={{
                  padding: "8px",
                  border: `1px solid ${
                    memberColor({ shade: 200 }).backgroundColor
                  }`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "calc(100% / 7)",
                  ...memberColor({
                    color: isDowSelected(weekDay.value)
                      ? member.color
                      : "inherit",
                    shade: 200,
                  }),
                }}
                onClick={() => handleDowChange(weekDay.value)}
              >
                <Typography sx={{ color: disabled ? "#ccc" : "inherit" }}>
                  {weekDay.short}
                </Typography>
              </Box>
            ))}
          </Box>
        </OFDFormControl>
      </Box>
    </>
  );
};

export default OFDDayOfWeek;
