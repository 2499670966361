import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayGroceryItem from "./OFDDisplayGroceryItem";

const OFDDisplayGroceryItems = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    maxHeight: "300px",
    overflow: "auto",
    ...styleoverride,
  };

  const handleClick = (item) => {
    onClick(item);
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={displayStyle}>
      <Typography variant="overline">{getLabel(label)}</Typography>

      <Box>
        <Stack spacing={0.5}>
          {value?.map((item) => (
            <OFDDisplayGroceryItem
              key={item.id}
              value={item}
              onClick={handleClick}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayGroceryItems;
