import { useEffect, useState } from "react";
import { startOfToday, endOfToday } from "date-fns";

import useGrocery from "../../../../hooks/useGrocery";
import useLocalization from "../../../../hooks/useLocalization";
import usePage from "../../../../hooks/usePage";

import { Box, Stack, Typography } from "@mui/material";

import GroceryList from "./GroceryList";

const GroceryWidget = () => {
  const { getGroceryLists, groceryLists } = useGrocery();
  const { getMessage } = useLocalization();
  const { refresh } = usePage();

  useEffect(() => {
    getData();
  }, [refresh]);

  const getData = async () => {
    let filter = {
      $or: [
        { completedAt: null },
        { completedAt: { $gte: startOfToday(), $lte: endOfToday() } },
      ],
    };

    await getGroceryLists(filter);
  };

  if (groceryLists === undefined) return;

  return (
    <>
      <Box>
        {Array.isArray(groceryLists) && groceryLists.length > 0 ? (
          <Stack spacing={1}>
            {groceryLists.map((groceryList) => (
              <GroceryList
                key={groceryList.id}
                groceryList={groceryList}
                onChange={getData}
              />
            ))}
          </Stack>
        ) : (
          <Typography>{getMessage("noGroceryLists")}</Typography>
        )}
      </Box>
    </>
  );
};

export default GroceryWidget;
