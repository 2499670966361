import React from "react";

// MATERIAL UI
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

// ASSETS
import Logo from "../../assets/images/OFD_hrzntl_logo_Black.svg";

const OFDHeader = ({ height, title }) => {
  return (
    <Box
      pt=".5rem"
      pb=".5rem"
      display="flex"
      justifyContent={title ? "space-around" : "center"}
      borderBottom="1px #cccccc solid"
      width="100%"
      alignItems="center"
    >
      <Box>
        <img
          alt="logo"
          src={Logo}
          style={{ height: height || "4rem" }}
          loading="lazy"
        />
      </Box>
      {title ? (
        <Box>
          <Typography variant="h5">{title}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDHeader;
