import usePage from "../../hooks/usePage";

import Page from "../layout/page/Page";
import FamilyDashboard from "../layout/page/familydashboard/FamilyDashboard";

const OFD = () => {
  const { pageMode } = usePage();
  return (
    <>
      {pageMode === "user" ? <Page /> : null}
      {pageMode === "dashboard" ? <FamilyDashboard /> : null}
    </>
  );
};

export default OFD;
