import React from "react";

import OFDLogo from "../../assets/images/logo.png";

import { Box, CircularProgress, Avatar } from "@mui/material";

const Loading = () => {
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop="5rem"
    >
      <Box display="flex">
        <Box position="relative">
          <CircularProgress size={70} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar
              alt="logo"
              src={OFDLogo}
              sx={{ width: "42px", height: "42px", zIndex: 1000 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Loading;
