import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reminders: [],
  notifications: [],
};

const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    addReminder(state, action) {
      if (state.reminders.findIndex((r) => r.id === action.payload.id) > -1)
        return;

      state.reminders.push(action.payload);
    },

    clearReminders(state) {
      state.reminders = [];
    },

    addNotification(state, action) {
      if (state.notifications.findIndex((r) => r.id === action.payload.id) > -1)
        return;

      state.notifications.push(action.payload);
    },

    clearNotifications(state) {
      state.notifications = [];
    },
  },
});

export const messagingActions = messagingSlice.actions;
export default messagingSlice.reducer;
