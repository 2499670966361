import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";
import useMember from "../../hooks/useMember";

import { Box, Stack, Typography } from "@mui/material";

import TokenBank from "./TokenBank";
import AssignedChores from "./AssignedChores";
import HomeworkOutstanding from "./HomeworkOutstanding";
import ChildReminders from "./ChildReminders";
import ChildToDos from "./ChildToDos";
import ChildAppointments from "./ChildAppointments";

const ChildDashboard = ({ onChange }) => {
  const { member } = useMember();
  return (
    <>
      <Box sx={{ paddingBottom: "3rem" }}>
        <Stack spacing={2}>
          <TokenBank memberId={member.id} />
          <ChildAppointments />
          <ChildReminders />
          <ChildToDos />
          <HomeworkOutstanding memberId={member.id} />
          <AssignedChores memberId={member.id} />
        </Stack>
      </Box>
    </>
  );
};

export default ChildDashboard;
