import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayNote from "./OFDDisplayNote";
import OFDCollapsable from "../layout/OFDCollapsable";
import OFDDisplayText from "./OFDDisplayText";

const OFDDisplayMileageLog = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { toNumber } = useData();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const getHeader = (item) => {
    let text = "";
    if (item.date) {
      text += `${format(item.date, "MMM dd, yyyy")} - `;
    }

    let total = 0;
    if (item.personal) {
      total += toNumber(item.personal);
    }
    if (item.business) {
      total += toNumber(item.business);
    }

    text += `${getLabel("total")} : ${total}`;

    return text;
  };

  const handleClick = () => {
    onClick();
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={displayStyle}>
      <Typography
        variant="overline"
        onClick={handleClick}
      >
        {getLabel(label)}
      </Typography>

      <Box>
        <Stack spacing={0.5}>
          {Array.isArray(value) && value.length > 0 ? (
            value?.map((item) => (
              <OFDCollapsable
                key={item.id}
                compressed
                header={
                  <Typography onClick={handleClick}>
                    {getHeader(item)}
                  </Typography>
                }
                styleOverride={{ backgroundColor: "#e0e0e0" }}
              >
                <Box
                  sx={{ display: "flex", marginTop: ".5rem" }}
                  gap={1}
                >
                  <Box sx={{ width: "50%" }}>
                    <OFDDisplayText
                      value={item.personal}
                      label="personal"
                    />
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <OFDDisplayText
                      value={item.business}
                      label="business"
                    />
                  </Box>
                </Box>

                {item.note ? (
                  <OFDDisplayNote
                    value={item.note}
                    label="note"
                    styleoverride={{ fontSize: "12px", color: "#535353" }}
                  />
                ) : null}
              </OFDCollapsable>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayMileageLog;
