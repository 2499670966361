import { Box, Stack, Typography } from "@mui/material";

import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

import Configuration from "../../configuration/Configuration";

const OFDFieldSelector = ({ entityName, id, label, value, onChange }) => {
  const { getLabel } = useLocalization();
  const { memberColor } = useMember();

  return (
    <Stack
      id={id}
      spacing={1}
    >
      <Typography>{getLabel(label)}</Typography>

      {Configuration.entity[entityName]?.availableFields.map((field) => (
        <Box
          id={field.fieldType}
          key={field.fieldType}
          sx={{
            width: "100%",
            padding: "1rem",
            border: `1px solid ${memberColor().backgroundColor}`,
            backgroundColor:
              field.fieldType === value
                ? memberColor().backgroundColor
                : "inherit",
            color: field.fieldType === value ? memberColor().color : "inherit",
            borderRadius: "8px",
          }}
          onClick={() => onChange(field.fieldType)}
        >
          <Typography>{getLabel(field.label)}</Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default OFDFieldSelector;
