import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDBudgetItem from "../../ui/OFDBudgetItem";

const GroceryBudgetItem = ({ name, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">
        {getWizard("grocery").budgetItem.title}
      </Typography>

      <Stack spacing={2}>
        {getWizard("grocery").budgetItem.instructions.map(
          (instruction, index) => (
            <Typography key={`instruction-${index}`}>{instruction}</Typography>
          )
        )}

        <OFDBudgetItem
          id="budgetItem"
          label="budgetItem"
          value={name}
          onChange={(newValue) => onChange(newValue)}
          error={error}
          message={message}
        />
      </Stack>
    </Box>
  );
};

export default GroceryBudgetItem;
