import { Box, Stack, Typography } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useAllergies from "../../hooks/useAllergies";
import useColor from "../../hooks/useColor";
import useSecurity from "../../hooks/useSecurity";

import OFDDisplayNote from "./OFDDisplayNote";
import OFDDisplayAllergyItem from "./OFDDisplayAllergyItem";
import OFDCollapsable from "../layout/OFDCollapsable";

const OFDDisplayAllergies = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { allergySeverity, allergyColor, severityLabel } = useAllergies();
  const { getColor } = useColor();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const contentStyle = {
    maxHeight: "200px",
    overflow: "auto",
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={displayStyle}>
      <Typography
        variant="overline"
        onClick={handleClick}
      >
        {getLabel(label)}
      </Typography>

      <Box sx={contentStyle}>
        <Stack spacing={1}>
          {value?.map((allergy) => (
            <OFDCollapsable
              key={allergy.id}
              compressed
              header={
                <OFDDisplayAllergyItem
                  onClick={onClick}
                  allergy={allergy}
                />
              }
              styleOverride={{ ...getColor(allergyColor(allergy)) }}
              arrowColor="grey"
              arrowShade={50}
            >
              {allergy.note ? (
                <OFDDisplayNote
                  value={allergy.note}
                  textOnly={true}
                  styleoverride={{
                    fontSize: "12px",
                    color: "#535353",
                  }}
                />
              ) : null}
            </OFDCollapsable>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayAllergies;
