import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDIcon from "./OFDIcon";

const fieldsTemplate = {
  name: "",
  streetAddress: "",
  otherAddress: "",
  city: "",
  province: "",
  postal: "",
  country: "",
};

const OFDAddress = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  const [fields, setFields] = useState(null);

  useEffect(() => {
    if (value) {
      setFields(value);
    } else {
      setFields({ ...fieldsTemplate });
    }
  }, [value]);

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    let newFields = { ...fields, [field]: newValue };
    setFields(newFields);

    onChange(newFields);
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  if (!fields) return null;

  return (
    <>
      <Box id={id}>
        <Box
          sx={{ marginTop: "1rem", marginBottom: "1rem", display: "flex" }}
          gap={1}
        >
          <OFDIcon
            name="address"
            color="grey"
            shade={900}
          />
          <Typography variant="h5">{getLabel(label)}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          gap={1}
        >
          <Stack
            spacing={1}
            sx={{ width: "100%" }}
          >
            <OFDTextField
              id="name"
              label="name"
              value={getFieldValue("name") || ""}
              onChange={(newValue) => updateFieldValue("name", newValue)}
              disabled={isDisabled()}
              fullWidth
            />
            <OFDTextField
              id="streetAddress"
              label="streetAddress"
              value={getFieldValue("streetAddress") || ""}
              onChange={(newValue) =>
                updateFieldValue("streetAddress", newValue)
              }
              disabled={isDisabled()}
              fullWidth
            />
            <OFDTextField
              id="otherAddress"
              label="otherAddress"
              value={getFieldValue("otherAddress") || ""}
              onChange={(newValue) =>
                updateFieldValue("otherAddress", newValue)
              }
              disabled={isDisabled()}
              fullWidth
            />
            <OFDFieldGroup>
              <OFDTextField
                id="city"
                label="city"
                value={getFieldValue("city") || ""}
                onChange={(newValue) => updateFieldValue("city", newValue)}
                disabled={isDisabled()}
                fullWidth
              />
              <OFDTextField
                id="province"
                label="province"
                value={getFieldValue("province") || ""}
                onChange={(newValue) => updateFieldValue("province", newValue)}
                disabled={isDisabled()}
                fullWidth
              />
            </OFDFieldGroup>
            <OFDFieldGroup>
              <OFDTextField
                id="country"
                label="country"
                value={getFieldValue("country") || ""}
                onChange={(newValue) => updateFieldValue("country", newValue)}
                disabled={isDisabled()}
                fullWidth
              />
              <OFDTextField
                id="postal"
                label="postal"
                value={getFieldValue("postal") || ""}
                onChange={(newValue) => updateFieldValue("postal", newValue)}
                disabled={isDisabled()}
                fullWidth
              />
            </OFDFieldGroup>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default OFDAddress;
