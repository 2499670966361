import { isDate, startOfToday, isBefore } from "date-fns";

const useRecurrence = () => {
  const transformRecurrence = (recurrence) => {
    if (!recurrence) return null;

    let newRecurrence = { ...recurrence };

    if (!recurrence.occurrenceStart || !isDate(recurrence.occurrenceStart)) {
      newRecurrence.occurrenceStart = startOfToday();
    }

    if (!recurrence.until && !recurrence.count) {
      newRecurrence.forever = true;
    } else {
      newRecurrence.forever = false;
    }

    if (!recurrence.interval) {
      newRecurrence.interval = 1;
    }

    return newRecurrence;
  };

  const validateRecurrence = (recurrence) => {
    let validation = {
      isSuccessful: true,
      message: "ok",
      displayToUser: false,
      fieldErrors: [],
      severity: "info",
    };

    if (!recurrence) return validation;

    switch (recurrence.frequency) {
      case "daily":
        validation = validateDaily(recurrence);
        break;

      case "weekly":
        validation = validateWeekly(recurrence);
        break;

      case "monthly":
        validation = validateMonthly(recurrence);
        break;

      case "yearly":
        validation = validateYearly(recurrence);
        break;

      case "schedule":
        validation = validateSchedule(recurrence);
        break;
    }

    if (!validation.isSuccessful) {
      validation.message = "fieldErrors";
      validation.displayToUser = true;
      validation.severity = "error";
    }

    return validation;
  };

  const validateCommon = (recurrence) => {
    let validation = {
      isSuccessful: true,
      message: "ok",
      displayToUser: false,
      fieldErrors: [],
      severity: "info",
    };

    if (recurrence.interval < 1) {
      validation.isSuccessful = false;
      validation.fieldErrors.push({
        name: "interval",
        message: "invalidData",
        severity: "error",
        error: true,
      });
    }
    if (recurrence.until) {
      if (!isDate(recurrence.until)) {
        validation.isSuccessful = false;
        validation.fieldErrors.push({
          name: "until",
          message: "invalidData",
          severity: "error",
          error: true,
        });
      } else {
        if (isBefore(recurrence.until, recurrence.occurrenceStart)) {
          validation.isSuccessful = false;
          validation.fieldErrors.push({
            name: "until",
            message: "invalidData",
            severity: "error",
            error: true,
          });
        }
      }
    }
    if (recurrence.count) {
      if (recurrence.count < 0) {
        validation.isSuccessful = false;
        validation.fieldErrors.push({
          name: "count",
          message: "invalidData",
          severity: "error",
          error: true,
        });
      }
    }

    return validation;
  };

  const validateDaily = (recurrence) => {
    let validation = validateCommon(recurrence);

    // not sure we need more than common validation
    return validation;
  };

  const validateWeekly = (recurrence) => {
    let validation = validateCommon(recurrence);

    if (!Array.isArray(recurrence.byDay) || recurrence.byDay.length === 0) {
      validation.isSuccessful = false;
      validation.fieldErrors.push({
        name: "byDay",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    return validation;
  };

  const validateMonthly = (recurrence) => {
    let validation = validateCommon(recurrence);

    if (!recurrence.monthlyType) {
      validation.isSuccessful = false;
      validation.fieldErrors.push({
        name: "monthlyType",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    } else {
      if (recurrence.monthlyType === "specific") {
        if (
          !Array.isArray(recurrence.byMonthDay) ||
          recurrence.byMonthDay.length === 0
        ) {
          validation.isSuccessful = false;
          validation.fieldErrors.push({
            name: "byMonthDay",
            message: "isEmpty",
            severity: "error",
            error: true,
          });
        }
      } else if (recurrence.monthlyType === "relative") {
        if (!Array.isArray(recurrence.byDay) || recurrence.byDay.length === 0) {
          validation.isSuccessful = false;
          validation.fieldErrors.push({
            name: "byDay",
            message: "isEmpty",
            severity: "error",
            error: true,
          });
        }
      } else {
        validation.isSuccessful = false;
        validation.fieldErrors.push({
          name: "monthlyType",
          message: "invalidData",
          severity: "error",
          error: true,
        });
      }
    }

    return validation;
  };

  const validateYearly = (recurrence) => {
    let validation = validateMonthly(recurrence);

    if (!Array.isArray(recurrence.byMonth) || recurrence.byMonth.length === 0) {
      validation.isSuccessful = false;
      validation.fieldErrors.push({
        name: "byMonth",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    return validation;
  };

  const validateSchedule = (recurrence) => {
    let validation = validateCommon(recurrence);

    if (
      !Array.isArray(recurrence.scheduleDates) ||
      recurrence.scheduleDates.length === 0
    ) {
      validation.isSuccessful = false;
      validation.fieldErrors.push({
        name: "scheduleDates",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    return validation;
  };

  return {
    transformRecurrence,
    validateRecurrence,
  };
};

export default useRecurrence;
