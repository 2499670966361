import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDPicture from "../../ui/OFDPicture";
import OFDAvatarUpload from "../../ui/OFDAvatarUpload";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const RecipeImage = ({ image, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">{getWizard("recipe").image.title}</Typography>

      <Box>
        {getWizard("recipe").image.instructions.map((instruction, index) => (
          <Typography key={`instruction-${index}`}>{instruction}</Typography>
        ))}
      </Box>

      <OFDFieldContainer sx={{ marginTop: "1rem" }}>
        <OFDAvatarUpload
          id="image"
          value={image || null}
          onChange={onChange}
          error={error}
          message={message}
        />
      </OFDFieldContainer>
    </Box>
  );
};

export default RecipeImage;
