import * as Icons from "@mui/icons-material";
import { SvgIcon } from "@mui/material";

// Custom Icons
import { ReactComponent as AddBudget } from "../../assets/icons/AddBudget.svg";
import { ReactComponent as AddCalendar } from "../../assets/icons/AddCalendar.svg";
import { ReactComponent as AddChore } from "../../assets/icons/AddChore.svg";
import { ReactComponent as AddColumn } from "../../assets/icons/addColumn.svg";
import { ReactComponent as AddContact } from "../../assets/icons/AddContact.svg";
import { ReactComponent as AddDocument } from "../../assets/icons/AddDocument.svg";
import { ReactComponent as AddExpense } from "../../assets/icons/AddExpense.svg";
import { ReactComponent as AddFamilyMember } from "../../assets/icons/AddFamilyMember.svg";
import { ReactComponent as AddGrocery } from "../../assets/icons/AddGrocery.svg";
import { ReactComponent as AddHomework } from "../../assets/icons/AddHomework.svg";
import { ReactComponent as AddMealPlan } from "../../assets/icons/AddMealPlan.svg";
import { ReactComponent as AddNote } from "../../assets/icons/AddNote.svg";
import { ReactComponent as AddOccasion } from "../../assets/icons/AddOccasion.svg";
import { ReactComponent as AddPassword } from "../../assets/icons/AddPassword.svg";
import { ReactComponent as AddRecipe } from "../../assets/icons/AddRecipe.svg";
import { ReactComponent as AddReminder } from "../../assets/icons/AddReminder.svg";
import { ReactComponent as AddSubscription } from "../../assets/icons/AddSubscription.svg";
import { ReactComponent as AddToDo } from "../../assets/icons/AddToDo.svg";
import { ReactComponent as AddTrip } from "../../assets/icons/AddTrip.svg";
import { ReactComponent as AddVehicle } from "../../assets/icons/AddVehicle.svg";
import { ReactComponent as Allergies } from "../../assets/icons/allergies.svg";
import { ReactComponent as BloodPressure } from "../../assets/icons/BloodPressure.svg";
import { ReactComponent as ChoresList } from "../../assets/icons/ChoresList.svg";
import { ReactComponent as DeleteRows } from "../../assets/icons/deleteRows.svg";
import { ReactComponent as Dentist } from "../../assets/icons/DentistNoun.svg";
import { ReactComponent as Doctor } from "../../assets/icons/DoctorStethoscope.svg";
import { ReactComponent as Document } from "../../assets/icons/Document.svg";
import { ReactComponent as Family } from "../../assets/icons/family.svg";
import { ReactComponent as FamilyPref } from "../../assets/icons/FamilyPref.svg";
import { ReactComponent as Friends } from "../../assets/icons/FriendsCustom.svg";
import { ReactComponent as Glucose } from "../../assets/icons/glucose.svg";
import { ReactComponent as InputFieldIcon } from "../../assets/icons/inputField.svg";
import { ReactComponent as Marriage } from "../../assets/icons/MarriageCustom.svg";
import { ReactComponent as MyPref } from "../../assets/icons/MyPref.svg";
import { ReactComponent as MyPref_Alt } from "../../assets/icons/MyPref_Alt.svg";
import { ReactComponent as MoveIcon } from "../../assets/icons/swapHorizontal.svg";
import { ReactComponent as Newborn } from "../../assets/icons/NewbornNoun.svg";
import { ReactComponent as Note } from "../../assets/icons/Note.svg";
import { ReactComponent as RecurringExpense } from "../../assets/icons/RecurringExpense.svg";
import { ReactComponent as School } from "../../assets/icons/SchoolCustom.svg";
import { ReactComponent as SpeedDial } from "../../assets/icons/speedDial.svg";
import { ReactComponent as SpeedDialDrawer } from "../../assets/icons/speedDial_Drawer.svg";
import { ReactComponent as SubscriptionsVrtcl } from "../../assets/icons/SubscriptionsVrtcl.svg";
import { ReactComponent as UpdateApp } from "../../assets/icons/UpdateApp.svg";
import { ReactComponent as UpdateAppTime } from "../../assets/icons/UpdateAppTime.svg";
import { ReactComponent as WebsiteUrl } from "../../assets/icons/WWW_Website.svg";

const OFDIconElement = ({ name, style, size, onClick }) => {
  let IconElement = Icons["HelpOutline"];

  if (!name) {
    return null;
  }

  if (name.toLowerCase() === "accounts") IconElement = Icons["ManageAccounts"];
  if (name.toLowerCase() === "add") IconElement = Icons["AddCircleOutline"];
  if (name.toLowerCase() === "addbudget")
    return (
      <SvgIcon
        component={AddBudget}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addcalendar")
    return (
      <SvgIcon
        component={AddCalendar}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addchore")
    return (
      <SvgIcon
        component={AddChore}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addcontact")
    return (
      <SvgIcon
        component={AddContact}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "adddocument")
    return (
      <SvgIcon
        component={AddDocument}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addexpense")
    return (
      <SvgIcon
        component={AddExpense}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addfamily")
    return (
      <SvgIcon
        component={AddFamilyMember}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addgrocery")
    return (
      <SvgIcon
        component={AddGrocery}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addhomework")
    return (
      <SvgIcon
        component={AddHomework}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addmealplan")
    return (
      <SvgIcon
        component={AddMealPlan}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addnote")
    return (
      <SvgIcon
        component={AddNote}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addoccasion")
    return (
      <SvgIcon
        component={AddOccasion}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addpassword")
    return (
      <SvgIcon
        component={AddPassword}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addrecipe")
    return (
      <SvgIcon
        component={AddRecipe}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addreminder")
    return (
      <SvgIcon
        component={AddReminder}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addsubscription")
    return (
      <SvgIcon
        component={AddSubscription}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addtodo")
    return (
      <SvgIcon
        component={AddToDo}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addtrip")
    return (
      <SvgIcon
        component={AddTrip}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addvehicle")
    return (
      <SvgIcon
        component={AddVehicle}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "account") IconElement = Icons["AccountCircle"];
  if (name.toLowerCase() === "addaccount") IconElement = Icons["PersonAdd"];
  if (name.toLowerCase() === "addcomment") IconElement = Icons["AddComment"];
  if (name.toLowerCase() === "addcolum")
    return (
      <SvgIcon
        component={AddColumn}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "addrow") IconElement = Icons["PlaylistAdd"];
  if (name.toLowerCase() === "address") IconElement = Icons["LocationOn"];
  if (name.toLowerCase() === "all") IconElement = Icons["AllInclusive"];
  if (name.toLowerCase() === "address") IconElement = Icons["LocationOn"];
  if (name.toLowerCase() === "allergies")
    return (
      <SvgIcon
        component={Allergies}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "analysis") IconElement = Icons["Assessment"];
  if (name.toLowerCase() === "appointment") IconElement = Icons["Today"];
  if (name.toLowerCase() === "approvals") IconElement = Icons["FactCheck"];

  if (name.toLowerCase() === "arrowdown")
    IconElement = Icons["KeyboardArrowDown"];
  if (name.toLowerCase() === "arrowleft")
    IconElement = Icons["KeyboardArrowLeft"];
  if (name.toLowerCase() === "arrowright")
    IconElement = Icons["KeyboardArrowRight"];
  if (name.toLowerCase() === "arrowup") IconElement = Icons["KeyboardArrowUp"];
  if (name.toLowerCase() === "attachment") IconElement = Icons["AttachFile"];
  if (name.toLowerCase() === "audio") IconElement = Icons["SpatialAudioOff"];
  if (name.toLowerCase() === "audionote")
    IconElement = Icons["SpatialAudioOff"];
  if (name.toLowerCase() === "babysitting") IconElement = Icons["ChildCare"];
  if (name.toLowerCase() === "backspace") IconElement = Icons["Backspace"];
  if (name.toLowerCase() === "bar") IconElement = Icons["SportsBar"];
  if (name.toLowerCase() === "bank") IconElement = Icons["AccountBalance"];
  if (name.toLowerCase() === "baseball") IconElement = Icons["SportsBaseball"];
  if (name.toLowerCase() === "basketball")
    IconElement = Icons["SportsBasketball"];
  if (name.toLowerCase() === "bbq") IconElement = Icons["OutdoorGrill"];
  if (name.toLowerCase() === "bloodpressure")
    return (
      <SvgIcon
        component={BloodPressure}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "bloodtype") IconElement = Icons["Bloodtype"];
  if (name.toLowerCase() === "cafe") IconElement = Icons["LocalCafe"];
  if (name.toLowerCase() === "budget") IconElement = Icons["Paid"];
  if (name.toLowerCase() === "budgets") IconElement = Icons["Paid"];
  if (name.toLowerCase() === "calendar") IconElement = Icons["CalendarToday"];
  if (name.toLowerCase() === "calendarmonth")
    IconElement = Icons["CalendarMonth"];
  if (name.toLowerCase() === "calendarweek") IconElement = Icons["DateRange"];
  if (name.toLowerCase() === "camera") IconElement = Icons["Camera"];
  if (name.toLowerCase() === "cancel") IconElement = Icons["Close"];
  if (name.toLowerCase() === "carrepair") IconElement = Icons["CarRepair"];
  if (name.toLowerCase() === "celebration") IconElement = Icons["Celebration"];
  if (name.toLowerCase() === "chevronleft") IconElement = Icons["ChevronLeft"];
  if (name.toLowerCase() === "chevronright")
    IconElement = Icons["ChevronRight"];

  if (name.toLowerCase() === "chores-outstanding")
    IconElement = Icons["RadioButtonUnchecked"];
  if (name.toLowerCase() === "chores-completed")
    IconElement = Icons["RadioButtonChecked"];
  if (name.toLowerCase() === "chores-approved")
    IconElement = Icons["CheckCircle"];
  if (name.toLowerCase() === "chores-notdone") IconElement = Icons["Cancel"];

  if (name.toLowerCase() === "chores") IconElement = Icons["CleaningServices"];
  if (name.toLowerCase() === "choreslist")
    return (
      <SvgIcon
        component={ChoresList}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "church") IconElement = Icons["Church"];

  if (name.toLowerCase() === "cleaning")
    IconElement = Icons["CleaningServices"];
  if (name.toLowerCase() === "clearentry") IconElement = Icons["Clear"];
  if (name.toLowerCase() === "clearlist") IconElement = Icons["PlaylistRemove"];
  if (name.toLowerCase() === "close") IconElement = Icons["Close"];
  if (name.toLowerCase() === "checkcircle") IconElement = Icons["CheckCircle"];
  if (name.toLowerCase() === "coffee") IconElement = Icons["Coffee"];
  if (name.toLowerCase() === "construction")
    IconElement = Icons["Construction"];
  if (name.toLowerCase() === "contact") IconElement = Icons["Contacts"];
  if (name.toLowerCase() === "contacts") IconElement = Icons["Contacts"];
  if (name.toLowerCase() === "copy") IconElement = Icons["ContentCopy"];
  if (name.toLowerCase() === "creditcard") IconElement = Icons["CreditCard"];
  if (name.toLowerCase() === "cricket") IconElement = Icons["SportsCricket"];
  if (name.toLowerCase() === "crop") IconElement = Icons["Crop"];
  if (name.toLowerCase() === "currency") IconElement = Icons["MonetizationOn"];
  if (name.toLowerCase() === "date") IconElement = Icons["Event"];
  if (name.toLowerCase() === "delete") IconElement = Icons["Delete"];
  if (name.toLowerCase() === "deletefolder")
    IconElement = Icons["FolderDelete"];
  if (name.toLowerCase() === "deleterows")
    return (
      <SvgIcon
        component={DeleteRows}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "dentist")
    return (
      <SvgIcon
        component={Dentist}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "diversity") IconElement = Icons["Diversity1"];
  if (name.toLowerCase() === "doctor")
    return (
      <SvgIcon
        component={Doctor}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );

  if (name.toLowerCase() === "document")
    return (
      <SvgIcon
        component={Document}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "documents")
    return (
      <SvgIcon
        component={Document}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "download") IconElement = Icons["Download"];
  if (name.toLowerCase() === "drag") IconElement = Icons["DragIndicator"];
  if (name.toLowerCase() === "drive") IconElement = Icons["TimeToLeave"];
  if (name.toLowerCase() === "edit") IconElement = Icons["Edit"];
  if (name.toLowerCase() === "email") IconElement = Icons["MailOutline"];
  if (name.toLowerCase() === "emergency") IconElement = Icons["LocalHospital"];
  if (name.toLowerCase() === "error") IconElement = Icons["Error"];
  if (name.toLowerCase() === "event") IconElement = Icons["DateRange"];
  if (name.toLowerCase() === "exittoapp") IconElement = Icons["ExitToApp"];
  if (name.toLowerCase() === "expense") IconElement = Icons["CreditCard"];
  if (name.toLowerCase() === "expenses") IconElement = Icons["CreditCard"];
  if (name.toLowerCase() === "family")
    return (
      <SvgIcon
        component={Family}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "familypreferences")
    return (
      <SvgIcon
        component={FamilyPref}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "favorite") IconElement = Icons["Favorite"];
  if (name.toLowerCase() === "fitness") IconElement = Icons["FitnessCenter"];
  if (name.toLowerCase() === "flight") IconElement = Icons["Flight"];
  if (name.toLowerCase() === "folder") IconElement = Icons["Folder"];
  if (name.toLowerCase() === "football") IconElement = Icons["SportsFootball"];
  if (name.toLowerCase() === "friend") IconElement = Icons["People"];
  if (name.toLowerCase() === "fullscreen") IconElement = Icons["OpenInFull"];
  if (name.toLowerCase() === "glucose") return <SvgIcon component={Glucose} />;
  if (name.toLowerCase() === "golf") IconElement = Icons["SportsGolf"];
  if (name.toLowerCase() === "groceries") IconElement = Icons["ShoppingCart"];
  if (name.toLowerCase() === "grocery") IconElement = Icons["ShoppingCart"];
  if (name.toLowerCase() === "gymnastics")
    IconElement = Icons["SportsGymnastics"];
  if (name.toLowerCase() === "handball") IconElement = Icons["SportsHandball"];
  if (name.toLowerCase() === "heartrate") IconElement = Icons["MonitorHeart"];
  if (name.toLowerCase() === "help") IconElement = Icons["HelpCenter"];
  if (name.toLowerCase() === "hiking") IconElement = Icons["Hiking"];
  if (name.toLowerCase() === "hockey") IconElement = Icons["SportsHockey"];
  if (name.toLowerCase() === "home") IconElement = Icons["Home"];
  if (name.toLowerCase() === "homerepair")
    IconElement = Icons["HomeRepairService"];
  if (name.toLowerCase() === "homework") IconElement = Icons["LocalLibrary"];
  if (name.toLowerCase() === "horizontalellipse")
    IconElement = Icons["MoreHoriz"];
  if (name.toLowerCase() === "http")
    return (
      <SvgIcon
        component={WebsiteUrl}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );

  if (name.toLowerCase() === "house") IconElement = Icons["House"];
  if (name.toLowerCase() === "image") IconElement = Icons["Image"];
  if (name.toLowerCase() === "income") IconElement = Icons["Money"];
  if (name.toLowerCase() === "input") IconElement = Icons["Input"];
  if (name.toLowerCase() === "inputfield")
    return (
      <SvgIcon
        component={InputFieldIcon}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );

  if (name.toLowerCase() === "interests") IconElement = Icons["Interests"];
  if (name.toLowerCase() === "learning")
    return (
      <SvgIcon
        component={School}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "legal") IconElement = Icons["Gavel"];
  if (name.toLowerCase() === "linechart") IconElement = Icons["ShowChart"];
  if (name.toLowerCase() === "list") IconElement = Icons["List"];
  if (name.toLowerCase() === "localgasstation")
    IconElement = Icons["LocalGasStation"];
  if (name.toLowerCase() === "lock") IconElement = Icons["Lock"];
  if (name.toLowerCase() === "logout") IconElement = Icons["Logout"];
  if (name.toLowerCase() === "luggage") IconElement = Icons["Luggage"];
  if (name.toLowerCase() === "marriage")
    return (
      <SvgIcon
        component={Marriage}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "martialarts")
    IconElement = Icons["SportsMartialArts"];
  if (name.toLowerCase() === "mealplan") IconElement = Icons["RestaurantMenu"];
  if (name.toLowerCase() === "mealplans") IconElement = Icons["RestaurantMenu"];
  if (name.toLowerCase() === "medical") IconElement = Icons["MedicalServices"];
  if (name.toLowerCase() === "medication") IconElement = Icons["Medication"];
  if (name.toLowerCase() === "member") IconElement = Icons["FamilyRestroom"];
  if (name.toLowerCase() === "menu") IconElement = Icons["Menu"];
  if (name.toLowerCase() === "merge") IconElement = Icons["Merge"];
  if (name.toLowerCase() === "medical")
    IconElement = Icons["MedicalInformation"];
  if (name.toLowerCase() === "medication") IconElement = Icons["Medication"];
  if (name.toLowerCase() === "mic") IconElement = Icons["Mic"];
  if (name.toLowerCase() === "mma") IconElement = Icons["SportsMma"];
  if (name.toLowerCase() === "money") IconElement = Icons["MonetizationOn"];
  if (name.toLowerCase() === "monitorweight")
    IconElement = Icons["MonitorWeight"];
  if (name.toLowerCase() === "mosque") IconElement = Icons["Mosque"];
  if (name.toLowerCase() === "movies") IconElement = Icons["LocalMovies"];
  if (name.toLowerCase() === "move")
    return (
      <SvgIcon
        component={MoveIcon}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "music") IconElement = Icons["MusicNote"];
  if (name.toLowerCase() === "mypreferences")
    return (
      <SvgIcon
        component={MyPref}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "mypreferencesalt")
    return (
      <SvgIcon
        component={MyPref_Alt}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "newfolder")
    IconElement = Icons["CreateNewFolder"];
  if (name.toLowerCase() === "nightlife") IconElement = Icons["Nightlife"];
  if (name.toLowerCase() === "note")
    return (
      <SvgIcon
        component={Note}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "notes") IconElement = Icons["TextSnippet"];
  if (name.toLowerCase() === "newborn")
    return (
      <SvgIcon
        component={Newborn}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "nightlife") IconElement = Icons["Nightlife"];
  if (name.toLowerCase() === "number") IconElement = Icons["Numbers"];
  if (name.toLowerCase() === "openpage") IconElement = Icons["OpenInNew"];
  if (name.toLowerCase() === "openfolder") IconElement = Icons["FolderOpen"];
  if (name.toLowerCase() === "password") IconElement = Icons["Password"];
  if (name.toLowerCase() === "passwords") IconElement = Icons["Password"];
  if (name.toLowerCase() === "pet") IconElement = Icons["Pets"];
  if (name.toLowerCase() === "phone") IconElement = Icons["Phone"];
  if (name.toLowerCase() === "play") IconElement = Icons["PlayCircle"];
  if (name.toLowerCase() === "pool") IconElement = Icons["Pool"];
  if (name.toLowerCase() === "project") IconElement = Icons["WaterfallChart"];
  if (name.toLowerCase() === "question") IconElement = Icons["HelpOutline"];
  if (name.toLowerCase() === "receipt") IconElement = Icons["ReceiptLong"];
  if (name.toLowerCase() === "rename")
    return (
      <SvgIcon
        component={InputFieldIcon}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "policy") IconElement = Icons["Policy"];
  if (name.toLowerCase() === "recipe") IconElement = Icons["MenuBook"];
  if (name.toLowerCase() === "recipes") IconElement = Icons["MenuBook"];
  if (name.toLowerCase() === "recurringexpense")
    return (
      <SvgIcon
        component={RecurringExpense}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "refresh") IconElement = Icons["Refresh"];
  if (name.toLowerCase() === "restaurant") IconElement = Icons["Restaurant"];
  if (name.toLowerCase() === "retailer") IconElement = Icons["Store"];
  if (name.toLowerCase() === "reminder") IconElement = Icons["Notifications"];
  if (name.toLowerCase() === "reminders") IconElement = Icons["Notifications"];
  if (name.toLowerCase() === "rugby") IconElement = Icons["SportsRugby"];
  if (name.toLowerCase() === "save") IconElement = Icons["Save"];
  if (name.toLowerCase() === "savings") IconElement = Icons["Savings"];
  if (name.toLowerCase() === "schedule") IconElement = Icons["CalendarMonth"];
  if (name.toLowerCase() === "school") IconElement = Icons["School"];
  if (name.toLowerCase() === "search") IconElement = Icons["Search"];
  if (name.toLowerCase() === "security") IconElement = Icons["Security"];
  if (name.toLowerCase() === "services") IconElement = Icons["Plumbing"];
  if (name.toLowerCase() === "settings") IconElement = Icons["Settings"];
  if (name.toLowerCase() === "send") IconElement = Icons["Send"];
  if (name.toLowerCase() === "shopping") IconElement = Icons["Shop"];
  if (name.toLowerCase() === "speeddial")
    return (
      <SvgIcon
        component={SpeedDialDrawer}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "split") IconElement = Icons["CallSplit"];
  if (name.toLowerCase() === "soccer") IconElement = Icons["SportsSoccer"];
  if (name.toLowerCase() === "spa") IconElement = Icons["Spa"];
  if (name.toLowerCase() === "sports") IconElement = Icons["Sports"];
  if (name.toLowerCase() === "store") IconElement = Icons["Store"];
  if (name.toLowerCase() === "subscriptionplan")
    return (
      <SvgIcon
        component={SubscriptionsVrtcl}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );

  if (name.toLowerCase() === "subscriptions")
    IconElement = Icons["Subscriptions"];
  if (name.toLowerCase() === "synagogue") IconElement = Icons["Synagogue"];
  if (name.toLowerCase() === "task") IconElement = Icons["TaskAlt"];
  if (name.toLowerCase() === "tennis") IconElement = Icons["SportsTennis"];
  if (name.toLowerCase() === "textnote") IconElement = Icons["TextSnippet"];
  if (name.toLowerCase() === "theater") IconElement = Icons["Theaters"];
  if (name.toLowerCase() === "time") IconElement = Icons["AccessTimeFilled"];
  if (name.toLowerCase() === "today") IconElement = Icons["Today"];
  if (name.toLowerCase() === "todo") IconElement = Icons["Checklist"];
  if (name.toLowerCase() === "todos") IconElement = Icons["Checklist"];
  if (name.toLowerCase() === "toolbox") IconElement = Icons["Construction"];
  if (name.toLowerCase() === "touch") IconElement = Icons["TouchApp"];
  if (name.toLowerCase() === "travel") IconElement = Icons["Flight"];
  if (name.toLowerCase() === "trip") IconElement = Icons["Flight"];
  if (name.toLowerCase() === "trips") IconElement = Icons["Flight"];
  if (name.toLowerCase() === "updateapp")
    return (
      <SvgIcon
        component={UpdateApp}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "updateapptime")
    return (
      <SvgIcon
        component={UpdateAppTime}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "upload") IconElement = Icons["CloudUpload"];
  if (name.toLowerCase() === "url")
    return (
      <SvgIcon
        component={WebsiteUrl}
        inheritViewBox
        style={style}
        onClick={onClick}
      />
    );
  if (name.toLowerCase() === "vaccines") IconElement = Icons["Vaccines"];
  if (name.toLowerCase() === "videonote") IconElement = Icons["Videocam"];
  if (name.toLowerCase() === "viewlist") IconElement = Icons["ViewList"];
  if (name.toLowerCase() === "view") IconElement = Icons["Visibility"];
  if (name.toLowerCase() === "viewoff") IconElement = Icons["VisibilityOff"];
  if (name.toLowerCase() === "vehicle") IconElement = Icons["DirectionsCar"];
  if (name.toLowerCase() === "vehicles") IconElement = Icons["DirectionsCar"];
  if (name.toLowerCase() === "verticalellipse") IconElement = Icons["MoreVert"];
  if (name.toLowerCase() === "vollyball")
    IconElement = Icons["SportsVolleyball"];
  if (name.toLowerCase() === "work") IconElement = Icons["Work"];

  return (
    <IconElement
      name={name}
      style={style}
      onClick={onClick}
    />
  );
};

export default OFDIconElement;
