import { useEffect, useState } from "react";

import usePage from "../../../../hooks/usePage";
import useApi from "../../../../hooks/useApi";
import useWindow from "../../../../hooks/useWindow";

import { Box, Typography } from "@mui/material";

import DashboardHeader from "./DashboardHeader";
import DashboardMenu from "./DashboardMenu";
import DashboardBody from "./DashboardBody";

import OFDButton from "../../../ui/OFDButton";
import UserPin from "../../UserPin";

const FamilyDashboard = () => {
  const { deviceType } = useWindow();
  const { setPageMode } = usePage();
  const { postData } = useApi();

  const [openPinDialog, setOpenPinDialog] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("home");

  useEffect(() => {
    // if (deviceType === "Mobile") {
    //   window.screen.orientation.lock("landscape-primary");
    // }
  }, []);

  const handleModeToUser = async () => {
    setOpenPinDialog(true);
  };

  const handleSelect = (id) => {
    setSelectedMenuItem(id);
  };

  const switchToUserMode = () => {
    setPageMode("user");
  };

  return (
    <>
      <Box>
        <DashboardHeader />
        <DashboardMenu
          selectedMenuItem={selectedMenuItem}
          onSelect={handleSelect}
          onUserMode={handleModeToUser}
        />
        <DashboardBody selectedMenuItem={selectedMenuItem} />
      </Box>

      <UserPin
        open={openPinDialog}
        onClose={() => setOpenPinDialog(false)}
        onSuccess={switchToUserMode}
      />
    </>
  );
};

export default FamilyDashboard;
