const Pages = [
  {
    name: "home",
    entityName: "Home",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "na", members: [] },
      update: { securityLevel: "na", members: [] },
      delete: { securityLevel: "na", members: [] },
    },
    icon: "home",
    url: "home",
    tier: 1,
  },
  {
    name: "family",
    entityName: "Member",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "accountOwner", members: [] },
      update: { securityLevel: "admin", members: [] },
      delete: { securityLevel: "accountOwner", members: [] },
    },
    icon: "family",
    url: "family",
    tier: 1,
  },
  {
    name: "calendar",
    entityName: "Calendar",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "admin", members: [] },
      delete: { securityLevel: "admin", members: [] },
    },
    icon: "calendar",
    url: "calendar",
    tier: 1,
  },
  {
    name: "reminders",
    entityName: "Reminder",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "addedBy", members: [] },
      delete: { securityLevel: "addedBy", members: [] },
    },
    icon: "reminder",
    url: "reminders",
    tier: 1,
  },
  {
    name: "todos",
    entityName: "ToDo",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "addedBy", members: [] },
      delete: { securityLevel: "addedBy", members: [] },
    },
    icon: "todo",
    url: "todos",
    tier: 1,
  },
  {
    name: "contacts",
    entityName: "Contact",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "contact",
    url: "contacts",
    tier: 1,
  },
  {
    name: "notes",
    entityName: "Note",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "notes",
    url: "notes",
    tier: 1,
  },
  {
    name: "documents",
    entityName: "Document",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "documents",
    url: "documents",
    tier: 1,
  },
  {
    name: "groceries",
    entityName: "Grocery",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "grocery",
    url: "groceries",
    tier: 1,
  },
  {
    name: "chores",
    entityName: "Chore",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "chores",
    url: "chores",
    tier: 1,
  },
  {
    name: "homework",
    entityName: "Homework",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "homework",
    url: "homework",
    tier: 1,
  },
  {
    name: "recipes",
    entityName: "Recipe",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "recipe",
    url: "recipes",
    tier: 1,
  },
  {
    name: "mealplans",
    entityName: "MealPlan",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "mealplan",
    url: "mealplans",
    tier: 1,
  },
  {
    name: "vehicles",
    entityName: "Vehicle",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "vehicle",
    url: "vehicles",
    tier: 1,
  },
  {
    name: "trips",
    entityName: "Trip",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "family", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "travel",
    url: "trips",
    tier: 1,
  },
  {
    name: "budgets",
    entityName: "Budget",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "adult", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "budget",
    url: "budgets",
    tier: 1,
  },
  {
    name: "expenses",
    entityName: "Expense",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "adult", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "creditCard",
    url: "expenses",
    tier: 1,
  },
  {
    name: "passwords",
    entityName: "Password",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "adult", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "password",
    url: "passwords",
    tier: 1,
  },
  {
    name: "subscriptions",
    entityName: "Subscription",
    main: true,
    preferences: true,
    defaultSecurity: {
      view: { securityLevel: "adult", members: [] },
      add: { securityLevel: "adult", members: [] },
      update: { securityLevel: "adult", members: [] },
      delete: { securityLevel: "adult", members: [] },
    },
    icon: "subscriptions",
    url: "subscriptions",
    tier: 1,
  },
];

export default Pages;
