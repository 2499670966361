import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ga4 from "react-ga4";

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    ga4.initialize(process.env.REACT_APP_GOOGLE_TAG);
  }, []);

  useEffect(() => {
    const path = location.pathname + location.search;
    ga4.send({
      hitType: "pageview",
      path: path,
    });
  }, [location]);
};

export default useAnalytics;
