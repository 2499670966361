import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signupId: null,
  purchases: null,
  appUserId: null,
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    initializeSignup(state, action) {
      const { signupId, email } = action.payload;

      state.signupId = signupId;
      state.appUserId = email;
    },

    setSignupId(state, action) {
      state.signupId = action.payload;
    },

    setPurchases(state, action) {
      state.purchases = action.payload;
    },
  },
});

export const signupActions = signupSlice.actions;
export default signupSlice.reducer;
