import React from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayNote from "./OFDDisplayNote";
import OFDCollapsable from "../layout/OFDCollapsable";
import OFDDisplayText from "./OFDDisplayText";
import OFDDisplayAddress from "./OFDDisplayAddress";
import OFDDisplayCurrency from "./OFDDisplayCurrency";
import OFDDisplayDate from "./OFDDisplayDate";
import OFDDisplayPhone from "./OFDDisplayPhone";

const OFDDisplayItinerary = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
  entiryData,
}) => {
  const { getLabel, getListItemLabel } = useLocalization();
  const { getColor } = useColor();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  const getItemLabel = (item) => {
    let text = "";

    if (item.departure && item.departure.location) {
      text += item.departure.location;
    }
    if (item.arrival && item.arrival.location) {
      if (text.length > 0) {
        text += " - ";
      }
      text += item.arrival.location;
    }

    return text;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={displayStyle}>
      <Typography
        variant="overline"
        onClick={handleClick}
      >
        {getLabel(label)}
      </Typography>

      <Box>
        <Stack spacing={0.5}>
          {value ? (
            value.map((item) => (
              <OFDCollapsable
                key={item.id}
                compressed
                header={
                  <Typography onClick={handleClick}>
                    {getItemLabel(item)}
                  </Typography>
                }
                styleOverride={{ backgroundColor: "#e0e0e0" }}
              >
                <Stack
                  spacing={1}
                  sx={{ padding: ".5rem" }}
                >
                  {/* DEPARTURE */}
                  <OFDCollapsable
                    compressed
                    header={
                      <Typography onClick={handleClick}>
                        {getLabel("departure")}
                      </Typography>
                    }
                    styleOverride={{
                      ...getColor({ color: "grey", shade: 200 }),
                    }}
                    arrowColor="grey"
                    arrowShade={900}
                  >
                    <Stack
                      spacing={1}
                      sx={{ padding: ".5rem" }}
                    >
                      {item.departure && item.departure.location ? (
                        <OFDDisplayText
                          label="location"
                          value={item.departure.location}
                          onClick={handleClick}
                        />
                      ) : null}

                      {item.departure && item.departure.datetime ? (
                        <OFDDisplayText
                          label="datetime"
                          value={format(
                            item.departure.datetime,
                            "MMM dd yyyy h:mm a"
                          )}
                          onClick={handleClick}
                        />
                      ) : null}

                      {item.departure && item.departure.transportationMode ? (
                        <OFDDisplayText
                          label="mode"
                          value={getListItemLabel(
                            "transportationModes",
                            item.departure.transportationMode
                          )}
                          onClick={handleClick}
                        />
                      ) : null}

                      {item.departure &&
                      (item.departure.carrier || item.departure.routeNumber) ? (
                        <Box
                          sx={{ display: "flex" }}
                          gap={0.5}
                        >
                          <Box sx={{ width: "60%" }}>
                            <OFDDisplayText
                              id="carrier"
                              label="carrier"
                              fullWidth
                              value={
                                item.departure && item.departure.carrier
                                  ? item.departure.carrier
                                  : null
                              }
                              onClick={handleClick}
                            />
                          </Box>
                          <Box sx={{ width: "40%" }}>
                            <OFDDisplayText
                              id="routeNumber"
                              label="routeNumber"
                              fullWidth
                              value={
                                item.departure && item.departure.routeNumber
                                  ? item.departure.routeNumber
                                  : null
                              }
                              onClick={handleClick}
                            />
                          </Box>
                        </Box>
                      ) : null}

                      {item.departure && item.departure.depot ? (
                        <OFDDisplayText
                          id="depot"
                          label="depot"
                          fullWidth
                          value={item.departure.depot}
                          onClick={handleClick}
                        />
                      ) : null}

                      {item.departure &&
                      (item.departure.terminal || item.departure.gate) ? (
                        <Box
                          sx={{ display: "flex" }}
                          gap={0.5}
                        >
                          <Box sx={{ width: "60%" }}>
                            <OFDDisplayText
                              id="terminal"
                              label="terminal"
                              fullWidth
                              value={
                                item.departure && item.departure.terminal
                                  ? item.departure.terminal
                                  : null
                              }
                              onClick={handleClick}
                            />
                          </Box>
                          <Box sx={{ width: "40%" }}>
                            <OFDDisplayText
                              id="gate"
                              label="gate"
                              fullWidth
                              value={
                                item.departure && item.departure.gate
                                  ? item.departure.gate
                                  : null
                              }
                              onClick={handleClick}
                            />
                          </Box>
                        </Box>
                      ) : null}

                      {item.departure && item.departure.note ? (
                        <OFDDisplayNote
                          label="note"
                          value={item.departure.note}
                          fullWidth
                          onClick={handleClick}
                        />
                      ) : null}
                    </Stack>
                  </OFDCollapsable>

                  {/* ARRIVAL */}
                  <OFDCollapsable
                    compressed
                    header={
                      <Typography onClick={handleClick}>
                        {getLabel("arrival")}
                      </Typography>
                    }
                    styleOverride={{
                      ...getColor({ color: "grey", shade: 200 }),
                    }}
                    arrowColor="grey"
                    arrowShade={900}
                  >
                    <Stack
                      spacing={1}
                      sx={{ padding: ".5rem" }}
                    >
                      {item.arrival && item.arrival.location ? (
                        <OFDDisplayText
                          label="location"
                          value={item.arrival.location}
                          onClick={handleClick}
                        />
                      ) : null}

                      {item.arrival && item.arrival.datetime ? (
                        <OFDDisplayText
                          label="datetime"
                          value={format(
                            item.arrival.datetime,
                            "MMM dd yyyy h:mm a"
                          )}
                          onClick={handleClick}
                        />
                      ) : null}

                      {item.arrival && item.arrival.depot ? (
                        <OFDDisplayText
                          id="depot"
                          label="depot"
                          fullWidth
                          value={item.arrival.depot}
                          onClick={handleClick}
                        />
                      ) : null}

                      {item.arrival &&
                      (item.arrival.terminal || item.arrival.gate) ? (
                        <Box
                          sx={{ display: "flex" }}
                          gap={0.5}
                        >
                          <Box sx={{ width: "60%" }}>
                            <OFDDisplayText
                              id="terminal"
                              label="terminal"
                              fullWidth
                              value={
                                item.arrival && item.arrival.terminal
                                  ? item.arrival.terminal
                                  : null
                              }
                              onClick={handleClick}
                            />
                          </Box>
                          <Box sx={{ width: "40%" }}>
                            <OFDDisplayText
                              id="gate"
                              label="gate"
                              fullWidth
                              value={
                                item.arrival && item.arrival.gate
                                  ? item.arrival.gate
                                  : null
                              }
                              onClick={handleClick}
                            />
                          </Box>
                        </Box>
                      ) : null}

                      {item.arrival && item.arrival.note ? (
                        <OFDDisplayNote
                          label="note"
                          value={item.arrival.note}
                          fullWidth
                          onClick={handleClick}
                        />
                      ) : null}
                    </Stack>
                  </OFDCollapsable>

                  {/* ACCOMMODATION */}
                  {item.accommodations ? (
                    <OFDCollapsable
                      compressed
                      header={
                        <Typography onClick={handleClick}>
                          {getLabel("accommodations")}
                        </Typography>
                      }
                      styleOverride={{
                        ...getColor({ color: "grey", shade: 200 }),
                      }}
                      arrowColor="grey"
                      arrowShade={900}
                    >
                      <Stack
                        spacing={1}
                        sx={{ padding: ".5rem" }}
                      >
                        {item.accommodations.fromDate ||
                        item.accommodations.toDate ? (
                          <Box
                            sx={{ display: "flex" }}
                            gap={0.5}
                          >
                            <Box sx={{ width: "50%" }}>
                              <OFDDisplayDate
                                id="fromDate"
                                label="from"
                                value={item.accommodations.fromDate || null}
                                onClick={handleClick}
                              />
                            </Box>

                            <Box sx={{ width: "50%" }}>
                              <OFDDisplayDate
                                id="toDate"
                                label="to"
                                value={item.accommodations.toDate || null}
                                onClick={handleClick}
                              />
                            </Box>
                          </Box>
                        ) : null}

                        {item.accommodations.address ? (
                          <OFDDisplayAddress
                            label="address"
                            value={item.accommodations.address}
                            onClick={handleClick}
                          />
                        ) : null}

                        {item.accommodations.phone ||
                        item.accommodations.rate ? (
                          <Box
                            sx={{ display: "flex" }}
                            gap={0.5}
                          >
                            <Box sx={{ width: "65%" }}>
                              <OFDDisplayPhone
                                id="phone"
                                label="phone"
                                value={item.accommodations.phone}
                                onClick={handleClick}
                              />
                            </Box>

                            <Box sx={{ width: "35%" }}>
                              <OFDDisplayCurrency
                                id="rate"
                                label="rate"
                                value={item.accommodations.rate}
                                onClick={handleClick}
                              />
                            </Box>
                          </Box>
                        ) : null}

                        {item.accommodations.note ? (
                          <OFDDisplayNote
                            label="note"
                            value={item.accommodations.note}
                            fullWidth
                            onClick={handleClick}
                          />
                        ) : null}
                      </Stack>
                    </OFDCollapsable>
                  ) : null}

                  {/* CAR RENTAL */}
                  {item.carRental ? (
                    <OFDCollapsable
                      compressed
                      header={
                        <Typography onClick={handleClick}>
                          {getLabel("carRental")}
                        </Typography>
                      }
                      styleOverride={{
                        ...getColor({ color: "grey", shade: 200 }),
                      }}
                      arrowColor="grey"
                      arrowShade={900}
                    >
                      <Stack
                        spacing={1}
                        sx={{ padding: ".5rem" }}
                      >
                        {item.carRental.fromDate || item.carRental.toDate ? (
                          <Box
                            sx={{ display: "flex" }}
                            gap={0.5}
                          >
                            <Box sx={{ width: "50%" }}>
                              <OFDDisplayDate
                                id="fromDate"
                                label="from"
                                value={item.carRental.fromDate || null}
                                onClick={handleClick}
                              />
                            </Box>

                            <Box sx={{ width: "50%" }}>
                              <OFDDisplayDate
                                id="toDate"
                                label="to"
                                value={item.carRental.toDate || null}
                                onClick={handleClick}
                              />
                            </Box>
                          </Box>
                        ) : null}

                        {item.carRental.name ? (
                          <OFDDisplayText
                            label="name"
                            value={item.carRental.name}
                            onClick={handleClick}
                          />
                        ) : null}

                        {item.carRental.description ? (
                          <OFDDisplayText
                            label="description"
                            value={item.carRental.description}
                            onClick={handleClick}
                          />
                        ) : null}

                        {item.carRental.rate ? (
                          <OFDDisplayCurrency
                            label="rate"
                            value={item.carRental.rate}
                            onClick={handleClick}
                          />
                        ) : null}

                        {item.carRental.note ? (
                          <OFDDisplayNote
                            label="note"
                            value={item.carRental.note}
                            fullWidth
                            onClick={handleClick}
                          />
                        ) : null}
                      </Stack>
                    </OFDCollapsable>
                  ) : null}
                </Stack>
              </OFDCollapsable>
            ))
          ) : (
            <Typography onClick={handleClick}>-</Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayItinerary;
