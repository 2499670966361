import { useState } from "react";
import {
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
  startOfMonth,
  endOfMonth,
  startOfToday,
  addMonths,
  subMonths,
  getMonth,
  isAfter,
  endOfDay,
} from "date-fns";

import useFamily from "./useFamily";
import useApi from "./useApi";

const defaultMonthlyTotals = [
  { month: 0, expenseBudget: 0, incomeBudget: 0 },
  { month: 1, expenseBudget: 0, incomeBudget: 0 },
  { month: 2, expenseBudget: 0, incomeBudget: 0 },
  { month: 3, expenseBudget: 0, incomeBudget: 0 },
  { month: 4, expenseBudget: 0, incomeBudget: 0 },
  { month: 5, expenseBudget: 0, incomeBudget: 0 },
  { month: 6, expenseBudget: 0, incomeBudget: 0 },
  { month: 7, expenseBudget: 0, incomeBudget: 0 },
  { month: 8, expenseBudget: 0, incomeBudget: 0 },
  { month: 9, expenseBudget: 0, incomeBudget: 0 },
  { month: 10, expenseBudget: 0, incomeBudget: 0 },
  { month: 11, expenseBudget: 0, incomeBudget: 0 },
];

const useBudget = () => {
  const { family } = useFamily();
  const { getData } = useApi();

  const [budgets, setBudgets] = useState(undefined);
  const [budgetCalendar, setBudgetCalendar] = useState(undefined);
  const [YTDBudgets, setYTDBudgets] = useState(null);
  const [budget, setBudget] = useState(null);

  const getBudgets = async (year) => {
    const results = await getData({
      entityName: "Budget",
      action: "get",
      filter: {
        familyId: family.id,
        year,
      },
    });

    if (results.isSuccessful) {
      setBudgets(results.data);
    } else {
      setBudgets(null);
    }
  };

  const getBudget = async (id) => {
    const results = await getData({
      entityName: "Budget",
      action: "get",
      id,
    });

    if (results.isSuccessful) {
      setBudget(results.data);
    } else {
      setBudget(null);
    }

    return results.data;
  };

  const getBudgetCalendar = async (year) => {
    const results = await getData({
      entityName: "BudgetCalendar",
      action: "get",
      filter: {
        familyId: family.id,
        year,
      },
    });

    if (results.isSuccessful) {
      setBudgetCalendar(results.data);
    } else {
      setBudgetCalendar(null);
    }
  };

  const monthlyBudgetTotals = async (year, ytd) => {
    const results = await getData({
      entityName: "Budget",
      action: "getMonthlyBudget",
      filter: {
        year,
        ytd,
      },
    });

    return results;
  };

  const calcYTDBudgets = (startDate, expenses) => {
    setYTDBudgets(null);

    if (!Array.isArray(budgets)) return;

    let budgetTotals = [];

    for (const budget of budgets) {
      let budgetItems = [];
      let budgetTotal = 0;
      let expenseTotal = 0;
      if (Array.isArray(budget.budgetItems)) {
        for (const budgetItem of budget.budgetItems) {
          let newBudgetItem = { ...YTDBudgetItem(startDate, budgetItem) };
          newBudgetItem.ytdExpenseAmount = YTDExpenseTotal(
            budgetItem,
            expenses
          );
          newBudgetItem.status = "ok";
          if (
            newBudgetItem.ytdBudgetAmount > 0 &&
            newBudgetItem.ytdExpenseAmount > 0
          ) {
            let percent =
              newBudgetItem.ytdExpenseAmount / newBudgetItem.ytdBudgetAmount;

            if (percent > 0.9 && percent <= 1) {
              newBudgetItem.status = "warning";
            } else if (percent > 1) {
              newBudgetItem.status = "overspent";
            }
          }

          budgetItems.push({ ...newBudgetItem });
        }

        for (const budgetItem of budgetItems) {
          budgetTotal += budgetItem.ytdBudgetAmount;
          expenseTotal += budgetItem.ytdExpenseAmount;
        }
      }

      let newBudget = { ...budget };

      newBudget.status = "ok";
      if (budgetTotal > 0 && expenseTotal > 0) {
        let percent = expenseTotal / budgetTotal;

        if (percent > 0.9 && percent <= 1) {
          newBudget.status = "warning";
        } else if (percent > 1) {
          newBudget.status = "overspent";
        }
      }

      budgetTotals.push({
        ...newBudget,
        budgetItems: [...budgetItems],
        ytdBudgetAmount: budgetTotal,
        ytdExpenseAmount: expenseTotal,
      });
    }

    setYTDBudgets(budgetTotals);
  };

  const YTDBudgetItem = (startDate, budgetItem) => {
    if (budgetItem.budgetFrequency === "onetime") {
      return {
        ...budgetItem,
        budgetAmount: budgetItem.budgetAmount,
        ytdBudgetAmount: budgetItem.budgetAmount,
        budgetStart: startDate,
        budgetEnd: startOfToday(),
        expenses: 0,
      };
    }

    if (budgetItem.budgetFrequency === "weekly") {
      const startWeek = startOfWeek(startDate);
      const currentWeek = startOfWeek(startOfToday());
      let ytdTotal = 0;
      for (
        let week = startWeek;
        week <= currentWeek;
        week = addWeeks(week, 1)
      ) {
        ytdTotal += budgetItem.budgetAmount;
      }

      return {
        ...budgetItem,
        budgetAmount: budgetItem.budgetAmount,
        ytdBudgetAmount: ytdTotal,
        budgetStart: startWeek,
        budgetEnd: endOfWeek(currentWeek),
        expenses: 0,
      };
    }

    if (budgetItem.budgetFrequency === "monthly") {
      const currentMonth = startOfMonth(startOfToday());
      let ytdTotal = 0;
      for (
        let month = startDate;
        month <= currentMonth;
        month = addMonths(month, 1)
      ) {
        ytdTotal += budgetItem.budgetAmount;
      }

      return {
        ...budgetItem,
        budgetAmount: budgetItem.budgetAmount,
        ytdBudgetAmount: ytdTotal,
        budgetStart: startDate,
        budgetEnd: endOfMonth(subMonths(currentMonth, 1)),
        expenses: 0,
      };
    }

    if (budgetItem.budgetFrequency === "specified") {
      const currentMonth = startOfMonth(startOfToday());
      let ytdTotal = 0;
      for (
        let month = startDate;
        month <= currentMonth;
        month = addMonths(month, 1)
      ) {
        if (budgetItem.months[month]) {
          ytdTotal += budgetItem.budgetAmount;
        }
      }

      return {
        ...budgetItem,
        budgetAmount: budgetItem.budgetAmount,
        ytdBudgetAmount: ytdTotal,
        budgetStart: startDate,
        budgetEnd: endOfMonth(subMonths(currentMonth, 1)),
        expenses: 0,
      };
    }
  };

  const YTDExpenseTotal = (budgetItem, expenses) => {
    if (!Array.isArray(expenses) || expenses.length === 0) return 0;

    const budgetExpenses = expenses.filter(
      (expense) => expense.budgetItem.budgetItemId === budgetItem.id
    );
    if (!budgetExpenses || budgetExpenses.length === 0) {
      return 0;
    }

    let expenseTotal = 0;
    for (const expense of budgetExpenses) {
      expenseTotal += expense.expenseAmount;
    }

    return expenseTotal;
  };

  const getBudgetItem = async (budgetItem) => {
    if (!budgetItem || !budgetItem.budgetId || !budgetItem.budgetItemId)
      return null;

    const data = await getBudget(budgetItem.budgetId);
    if (!data) return null;

    const item = data.budgetItems.find((i) => i.id === budgetItem.budgetItemId);
    if (!item) return null;

    return item;
  };

  return {
    budgets,
    getBudgets,
    calcYTDBudgets,
    YTDBudgets,
    getBudget,
    budget,
    getBudgetItem,
    getBudgetCalendar,
    budgetCalendar,
    monthlyBudgetTotals,
  };
};

export default useBudget;
