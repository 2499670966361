import { useEffect, useState } from "react";

import {
  startOfToday,
  startOfDay,
  isDate,
  format,
  parseISO,
  parse,
  isBefore,
  isEqual,
} from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useWindow from "../../hooks/useWindow";

import { Box } from "@mui/material";

import OFDFormControl from "./OFDFormControl";
import OFDDate from "./OFDDate";
import OFDTime from "./OFDTime";
import OFDSwitch from "./OFDSwitch";

const OFDDateTimeRange = ({
  id,
  label,
  value,
  onChange,
  required,
  message,
  error,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { isMobile } = useWindow();
  const { getLabel } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  const [initialState, setInitialState] = useState(true);

  useEffect(() => {
    setInitialState(true);
  }, []);

  const handleFieldChange = (fieldName, newValue) => {
    setInitialState(false);

    let startDate = isDate(value[0]) ? value[0] : getDate();
    let endDate = isDate(value[1]) ? value[1] : startDate;

    let yyyymmdd = format(startDate, "yyyy-MM-dd");
    if (fieldName === "date") {
      yyyymmdd = format(newValue, "yyyy-MM-dd");
    }

    let fromTime = format(startDate, "hh:mm a");
    if (fieldName === "fromTime") {
      fromTime = format(newValue, "hh:mm a");
    }

    let toTime = format(endDate, "hh:mm a");
    if (fieldName === "toTime") {
      toTime = format(newValue, "hh:mm a");
    }

    let allDay = value[2] || false;
    if (fieldName === "allDay") {
      allDay = newValue;
      fromTime = "12:00 pm";
      toTime = "12:00 pm";
    }

    let newDates = [
      parse(`${yyyymmdd} ${fromTime}`, "yyyy-MM-dd hh:mm a", new Date()),
      parse(`${yyyymmdd} ${toTime}`, "yyyy-MM-dd hh:mm a", new Date()),
      allDay,
    ];

    onChange(newDates);
  };

  const getDate = () => {
    let date = startOfToday();
    if (Array.isArray(value) && value.length > 0) {
      if (isDate(value[0])) date = value[0];
    }

    return date;
  };

  const getAllDay = () => {
    if (Array.isArray(value) && value.length > 2) {
      return value[2];
    } else {
      return false;
    }
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  const getFromTime = () => {
    let time = null;
    if (!value || value.length === 0) return time;
    if (initialState && !value[1]) return time;

    return value[0];
  };

  const getToTime = () => {
    let time = null;
    if (!value || value.length < 1) return time;
    if (isEqual(value[0], value[1])) return time;

    return value[1];
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <OFDFormControl
      id={id}
      label={getLabel(label)}
      required={required || false}
      message={message}
      error={error}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          paddingTop: ".75rem",
        }}
        gap={1}
      >
        <Box sx={{ width: isMobile ? "100%" : "40%" }}>
          <OFDDate
            id="date"
            label="date"
            value={getDate()}
            onChange={(newValue) => handleFieldChange("date", newValue)}
            disabled={isDisabled()}
          />
        </Box>

        {Array.isArray(value) && !value[2] ? (
          <Box
            sx={{ width: isMobile ? "100%" : "60%", display: "flex" }}
            gap={1}
          >
            <Box sx={{ width: "50%" }}>
              <OFDTime
                id="startTime"
                label="from"
                value={getFromTime()}
                onChange={(newValue) => handleFieldChange("fromTime", newValue)}
                disabled={isDisabled()}
              />
            </Box>

            <Box sx={{ width: "50%" }}>
              <OFDTime
                id="endTime"
                label="to"
                value={getToTime()}
                onChange={(newValue) => handleFieldChange("toTime", newValue)}
                disabled={isDisabled()}
              />
            </Box>
          </Box>
        ) : null}
      </Box>

      <OFDSwitch
        id="allDay"
        value={getAllDay()}
        label="allDay"
        onChange={(newValue) => handleFieldChange("allDay", newValue)}
        disabled={isDisabled()}
      />
    </OFDFormControl>
  );
};

export default OFDDateTimeRange;
