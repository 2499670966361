import axios from "axios";
import { isDate, parseISO, parse } from "date-fns";
import { useDispatch } from "react-redux";

import { authenticationActions } from "../store/authentication";
import { memberActions } from "../store/member";
import { familyActions } from "../store/family";

import useSystem from "./useSystem";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosExternal = axios.create();

const useApi = () => {
  const { setCurrentVersion } = useSystem();
  const dispatch = useDispatch();

  const requestHeader = (args, defaultAction) => {
    const token = localStorage.getItem("token");

    let headers = {
      action: args.action || defaultAction,
      entityName: args.entityName,
      options: args.options ? JSON.stringify(args.options) : null,
    };

    if (token) {
      headers.authorization = `Bearer ${token}`;
    }

    if (!!args.filter) {
      headers.filter = JSON.stringify(args.filter);
    }

    if (args.file) {
      headers["Content-Type"] = "multipart/form-data";
    }

    return headers;
  };

  const requestPath = (args) => {
    let url = args.url || "";

    if (args.file) {
      url += "/upload";
    }

    let path = !!args.id ? `${url}/${args.id}` : url;

    return path;
  };

  const getData = async (args) => {
    let results = null;
    try {
      const response = await axiosInstance.get(requestPath(args), {
        headers: requestHeader(args, "get"),
      });

      results = await serverResponse(response);
    } catch (err) {}

    return results;
  };

  const postData = async (args) => {
    let results = null;

    try {
      let defaultAction = args.data && args.data.id ? "update" : "add";

      const response = await axiosInstance.post(
        requestPath(args),
        { data: JSON.stringify(args.data) },
        { headers: requestHeader(args, defaultAction) }
      );

      results = await serverResponse(response);
    } catch (err) {}

    return results;
  };

  const deleteData = async (args) => {
    let results = null;

    try {
      let defaultAction = "delete";

      const response = await axiosInstance.post(
        requestPath(args),
        { data: JSON.stringify(args.data) },
        {
          headers: requestHeader(args, defaultAction),
        }
      );

      results = await serverResponse(response);
    } catch (err) {}

    return results;
  };

  const upload = async (args) => {
    let results = null;

    try {
      let defaultAction = "upload";

      const data = {
        ...args.data,
        type: args.file.type,
      };

      let formData = new FormData();
      formData.append("file", args.file);
      formData.append("data", JSON.stringify(data));

      const response = await axiosInstance.post(requestPath(args), formData, {
        headers: requestHeader(args, defaultAction),
      });

      results = await serverResponse(response);
    } catch (err) {}

    return results;
  };

  const uploadFile = async (args) => {
    const presignedResults = await requestPresignedPutURL(args);

    const file = args.file;

    let results = null;

    try {
      let headers = {
        "Content-Type": file.type,
      };

      const response = await axios.put(
        presignedResults.data.presignedURL,
        file,
        {
          headers,
        }
      );

      results = response;
    } catch (err) {}

    return results;
  };

  const externalData = async (url) => {
    const results = await axiosExternal.get(url);

    return results;
  };

  const postExternalData = async (args) => {
    let formData = new FormData();
    const keys = Object.keys(args.data);
    for (const key of keys) {
      formData.append(key, args.data[key]);
    }

    const results = await axiosExternal.post(args.url, { data: formData });
    return results;
  };

  const requestPresignedPutURL = async (args) => {
    const { file } = args;

    let data = {
      filename: args.key,
      type: file.type,
    };

    let results = null;

    try {
      const response = await axiosInstance.post(
        requestPath(args),
        { data: JSON.stringify(data) },
        {
          headers: requestHeader({ ...args, action: "requestPresignedPutURL" }),
        }
      );

      results = await serverResponse(response);
    } catch (err) {}

    return results;
  };

  const getFileUrl = async (args) => {
    let results = null;

    try {
      const response = await axiosInstance.post(
        requestPath(args),
        { data: JSON.stringify(args.data) },
        { headers: requestHeader(args) }
      );

      results = await serverResponse(response);
    } catch (err) {}

    return results;
  };

  // PROCESS INCOMING RESPONSE DATA
  // - convert date strings to date objects
  // =======================================
  const serverResponse = async (response) => {
    if (response.data && !response.data.isActiveSubscription) {
      await logout();
    }

    if (response.data && response.data.appVersion) {
      setCurrentVersion(response.data.appVersion);
    }

    const data = processResponse(response.data);

    return data;
  };

  const processResponse = (obj) => {
    let newResponse;
    if (Array.isArray(obj)) {
      newResponse = [];

      for (const entry of obj) {
        newResponse.push(processResponseItem(entry));
      }
    } else {
      newResponse = processResponseItem(obj);
    }

    return newResponse;
  };

  const processResponseItem = (obj) => {
    if (obj === null || obj === undefined || typeof obj !== "object") {
      return obj;
    }

    let newObj = {};

    let fields = Object.keys(obj);

    for (const field of fields) {
      let value = null;

      if (typeof obj[field] === "object") {
        value = processResponse(obj[field]);
        newObj[field] = value;
      } else if (isISODateString(obj[field])) {
        if (["date", "toDate", "fromDate"].includes(field)) {
          if ("fromTime" in obj && isISODateString(obj["fromTime"])) {
            let newDate = obj[field].substring(0, 10);
            newDate += obj["fromTime"].substring(10);

            newObj[field] = parseISO(newDate);
          } else {
            newObj[field] = parse(
              obj[field].substring(0, 10),
              "yyyy-MM-dd",
              new Date()
            );
          }
        } else {
          newObj[field] = parseISO(obj[field]);
        }
      } else if (isDate(obj[field])) {
        newObj[field] = new Date(obj[field]);
      } else {
        newObj[field] = obj[field];
      }
    }

    return newObj;
  };

  const isISODateString = (value) => {
    const isoDateFormat =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

    return value && typeof value === "string" && isoDateFormat.test(value);
  };

  const logout = async () => {
    await postData({
      entityName: "Member",
      action: "logout",
      data: {},
    });

    dispatch(memberActions.setMemberState({ data: null }));
    dispatch(familyActions.setFamilyState({ data: null }));
    dispatch(authenticationActions.logout());
  };

  return {
    getData,
    postData,
    deleteData,
    externalData,
    uploadFile,
    getFileUrl,
    postExternalData,
    upload,
  };
};

export default useApi;
