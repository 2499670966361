import { useEffect, useState } from "react";
import { startOfToday, startOfYear, endOfYear } from "date-fns";

import { Box, Stack, Typography } from "@mui/material";

import useForm from "../../hooks/useForm";
import useEntity from "../../hooks/useEntity";
import useLocalization from "../../hooks/useLocalization";
import useExpense from "../../hooks/useExpense";

import OFDModal from "../layout/OFDModal";
import OFDMessage from "../ui/OFDMessage";
import OFDToolbar from "../layout/OFDToolbar";
import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDCurrency from "../ui/OFDCurrency";
import OFDSelect from "../ui/OFDSelect";
import OFDDisplayCategory from "../ui/OFDDisplayCategory";
import OFDDate from "../ui/OFDDate";
import OFDDialog from "../layout/OFDDialog";
import OFDBudgetItem from "../ui/OFDBudgetItem";
import OFDDisplayGallery from "../ui/OFDDisplayGallery";
import FieldEditorModal from "./FieldEditorModal";

import OFDDateRange from "../ui/OFDDateRange";
import OFDWeeklySchedule from "../ui/OFDWeeklySchedule";
import OFDBiWeeklySchedule from "../ui/OFDBiWeeklySchedule";
import OFDMonthlySchedule from "../ui/OFDMonthlySchedule";
import OFDMultiDatePicker from "../ui/OFDMultiDatePicker";

const ExpenseEditor = ({
  expense,
  expenseId,
  source,
  field,
  open,
  onClose,
  onSave,
  expenseCalendar,
  expenseCalendarId,
  initialValues,
  isIncome = false,
}) => {
  const { defaultValues } = useEntity(
    expenseCalendar || expenseCalendarId ? "ExpenseCalendar" : "Expense"
  );
  const { validateExpense, transformExpense, getEntityDates } = useExpense();
  const { getLabel } = useLocalization();

  const {
    data,
    setData,
    getDataById,

    setFieldValue,
    getFieldValue,
    getFieldMessage,
    getFieldError,

    saveData,
    deleteDocument,
    formMessage,
    resetFormMessage,
    resetFieldMessages,
    setFormMessage,

    validate,
  } = useForm(
    expenseCalendar || expenseCalendarId ? "ExpenseCalendar" : "Expense"
  );

  const [categorySelector, setCategorySelector] = useState(false);
  const [receiptsEditor, setReceiptsEditor] = useState(false);

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [updateFrom, setUpdateFrom] = useState(null);

  useEffect(() => {
    if (open) {
      resetFormMessage();
      resetFieldMessages();
    }

    if (expense) {
      setData(expense);
    } else if (expenseId) {
      getDataById(expenseId);
    } else if (expenseCalendarId) {
      getDataById(expenseCalendarId);
    } else {
      if (initialValues) {
        setData({ ...initialValues });
      } else {
        setData({
          ...defaultValues,
        });
      }
    }
  }, [expense, open]);

  useEffect(() => {
    if (data && data.id) {
      setUpdateFrom(data.expenseDate);
    }
    if (data) {
      if (!data.frequency) {
        setFieldValue("frequency", "onetime");
      }
    }
  }, [data]);

  useEffect(() => {
    if (!data || !data.frequency) return;

    if (data.frequency !== "onetime") {
      setFieldValue("startDate", startOfYear(startOfToday()));
      setFieldValue("endDate", endOfYear(startOfToday()));
    }
  }, [getFieldValue("frequency")]);

  const handleToolbarClick = async (name) => {
    switch (name) {
      case "save":
        await saveExpense();
        break;

      case "delete":
        setOpenDeleteDialog(true);
        break;
    }
  };

  const saveExpense = async () => {
    await saveConfirmation("yes");
  };

  const saveConfirmation = async (response) => {
    setOpenSaveDialog(false);

    if (response === "no") {
      return;
    }

    resetFormMessage();
    resetFieldMessages();

    let newData = data;
    newData = transformExpense(data);

    const validationResults = validate(validateExpense, newData);

    if (!validationResults.isSuccessful) {
      setFormMessage(validationResults);
      return;
    }

    if (source) {
      newData.source = source;
    }

    if (field) {
      newData.field = field;
    }

    let action = data.id ? `update` : `add`;
    console.log({ action });

    let options = {};

    let entityDates = getEntityDates(newData);

    if (!entityDates) {
      return;
    }
    options = {
      entityDates,
    };

    if (data && data.id) {
      options.updateFrom = updateFrom;
    }

    const results = await saveData({
      action,
      data: newData,
      options,
    });

    console.log({ results });

    if (results.isSuccessful) {
      onSave(results.data);
    }
  };

  const deleteConfirmation = async (response) => {
    setOpenDeleteDialog(false);

    if (response === "no") {
      return;
    }

    resetFormMessage();
    resetFieldMessages();

    const isSuccessful = await deleteDocument();

    if (isSuccessful) {
      if (onSave) {
        onSave();
      }
    }
  };

  const handleCategorySelector = () => {
    setCategorySelector(true);
  };

  const handleCancelCategory = () => {
    setCategorySelector(false);
  };

  const handleChangeCategory = (newValue) => {
    setCategorySelector(false);
    setFieldValue("category", newValue);
  };

  const handleReceiptsEditor = () => {
    setReceiptsEditor(true);
  };

  const handleCancelReceipts = () => {
    setReceiptsEditor(false);
  };

  const handleChangeReceipts = (newValue) => {
    setReceiptsEditor(false);
    setFieldValue("receipts", newValue);
  };

  const handleFrequencyChange = (newFrequency) => {
    setFieldValue("startDate", null);
    setFieldValue("endDate", null);
    setFieldValue("dow", null);
    setFieldValue("dates", null);
    setFieldValue("days", null);

    setFieldValue("frequency", newFrequency);
  };

  const getDailyFields = () => {
    return [getFieldValue("startDate"), getFieldValue("endDate")];
  };

  const getWeeklyFields = () => {
    return {
      startDate: getFieldValue("startDate"),
      endDate: getFieldValue("endDate"),
      dow: getFieldValue("dow") || [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    };
  };

  const getBiWeeklyFields = () => {
    return {
      startDate: getFieldValue("startDate"),
      endDate: getFieldValue("endDate"),
    };
  };

  const handleDailyChange = (newValue) => {
    setFieldValue("startDate", newValue[0]);
    setFieldValue("endDate", newValue[1]);
  };

  const handleWeeklyChange = (newValue) => {
    setFieldValue("startDate", newValue.startDate);
    setFieldValue("endDate", newValue.endDate);
    setFieldValue("dow", newValue.dow);
  };

  const handleBiWeeklyChange = (newValue) => {
    setFieldValue("startDate", newValue.startDate);
    setFieldValue("endDate", newValue.endDate);
  };

  const handleCompanyChange = (newValue) => {
    let company = null;

    if (newValue && newValue.length > 0) {
      let words = newValue.split(" ");
      for (let x = 0; x < words.length; x++) {
        if (!words[x]) continue;

        words[x] = words[x][0].toUpperCase() + words[x].substr(1);
      }
      company = words.join(" ");
    }

    setFieldValue("company", company);
  };

  const getMonthlyFields = () => {
    return {
      startDate: getFieldValue("startDate"),
      endDate: getFieldValue("endDate"),
      months: getFieldValue("months") || [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      monthlyType: getFieldValue("monthlyType"),
      dates: getFieldValue("dates"),
      days: getFieldValue("days"),
    };
  };

  const handleMonthlyChange = (newValue) => {
    setFieldValue("startDate", newValue.startDate);
    setFieldValue("endDate", newValue.endDate);
    setFieldValue("monthlyType", newValue.monthlyType);
    setFieldValue("dates", newValue.dates);
    setFieldValue("days", newValue.days);
  };

  const getExpenseDate = () => {
    if (!data) return null;
    if (data.expenseDate) return data.expenseDate;
    if (data.startDate) return data.startDate;

    return null;
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
      >
        <Box sx={{ height: "100%" }}>
          <OFDToolbar
            sticky={true}
            menuItems={[
              { name: "save", label: "save", icon: "save" },
              { name: "delete", label: "delete", icon: "delete" },
            ]}
            onClick={handleToolbarClick}
          />

          <Box sx={{ padding: "1rem" }}>
            <Stack spacing={2}>
              <Typography
                variant="h5"
                align="center"
              >
                {isIncome ? getLabel("income") : getLabel("expense")}
              </Typography>

              {!expenseCalendar && !expenseCalendarId && !source ? (
                <OFDSelect
                  id="frequency"
                  label="frequency"
                  value={getFieldValue("frequency")}
                  onChange={(newValue) => handleFrequencyChange(newValue)}
                  listName="expenseFrequency"
                />
              ) : null}

              {getFieldValue("frequency") === "onetime" ? (
                <OFDDate
                  id="expenseDate"
                  label="date"
                  value={getExpenseDate()}
                  onChange={(newValue) =>
                    setFieldValue("expenseDate", newValue)
                  }
                  required={true}
                  message={getFieldMessage("expenseDate")}
                  error={getFieldError("expenseDate")}
                />
              ) : null}

              {!expenseCalendar && getFieldValue("frequency") === "daily" ? (
                <OFDDateRange
                  id="dailyFrequency"
                  value={getDailyFields()}
                  onChange={handleDailyChange}
                />
              ) : null}
              {!expenseCalendar && getFieldValue("frequency") === "weekly" ? (
                <OFDWeeklySchedule
                  id="weeklyFrequency"
                  value={getWeeklyFields()}
                  onChange={handleWeeklyChange}
                />
              ) : null}
              {!expenseCalendar && getFieldValue("frequency") === "biweekly" ? (
                <OFDBiWeeklySchedule
                  id="biweeklyFrequency"
                  value={getBiWeeklyFields()}
                  onChange={handleBiWeeklyChange}
                />
              ) : null}
              {!expenseCalendar && getFieldValue("frequency") === "monthly" ? (
                <OFDMonthlySchedule
                  id="monthlyFrequency"
                  value={getMonthlyFields()}
                  onChange={handleMonthlyChange}
                />
              ) : null}
              {!expenseCalendar && getFieldValue("frequency") === "schedule" ? (
                <OFDMultiDatePicker
                  id="multidate"
                  value={getFieldValue("schedule")}
                  onChange={(newValue) => setFieldValue("schedule", newValue)}
                />
              ) : null}
              {expense && getFieldValue("frequency") !== "onetime" ? (
                <OFDDate
                  id="fromDate"
                  label="updateFrom"
                  value={updateFrom}
                  onChange={(newValue) => setUpdateFrom(newValue)}
                />
              ) : null}
              <OFDTextField
                id="name"
                label="name"
                value={getFieldValue("name")}
                onChange={(newValue) => setFieldValue("name", newValue)}
                required={true}
                message={getFieldMessage("name")}
                error={getFieldError("name")}
              />
              <OFDTextNote
                id="description"
                label="description"
                value={getFieldValue("description")}
                onChange={(newValue) => setFieldValue("description", newValue)}
              />
              <OFDTextField
                id="company"
                label="company"
                value={getFieldValue("company")}
                onChange={(newValue) => handleCompanyChange(newValue)}
                message={getFieldMessage("company")}
                error={getFieldError("company")}
              />
              <OFDCurrency
                id="expenseAmount"
                label="amount"
                value={getFieldValue("expenseAmount")}
                onChange={(newValue) =>
                  setFieldValue("expenseAmount", newValue)
                }
                message={getFieldMessage("expenseAmount")}
                error={getFieldError("expenseAmount")}
                required={true}
              />
              <OFDBudgetItem
                id="budgetItem"
                label="budgetItem"
                value={getFieldValue("budgetItem")}
                onChange={(newValue) => setFieldValue("budgetItem", newValue)}
                message={getFieldMessage("budgetItem")}
                error={getFieldError("budgetItem")}
                required={true}
                variant="outlined"
                isIncome={isIncome}
              />
              <OFDDisplayCategory
                label="category"
                value={getFieldValue("category")}
                onClick={handleCategorySelector}
              />
              <OFDTextField
                id="tags"
                label="tags"
                value={getFieldValue("tags")}
                onChange={(newValue) => setFieldValue("tags", newValue)}
                message={getFieldMessage("tags")}
                error={getFieldError("tags")}
              />
              <OFDDisplayGallery
                id="receipts"
                label="receipts"
                value={getFieldValue("receipts")}
                onClick={handleReceiptsEditor}
              />
            </Stack>
          </Box>
        </Box>
      </OFDModal>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <FieldEditorModal
        entityName="Expense"
        entityData={data}
        field={{
          id: "category",
          label: "category",
          icon: "category",
          fieldType: "category",
        }}
        open={categorySelector}
        onCancel={handleCancelCategory}
        onChange={handleChangeCategory}
        returnValue
      />

      <FieldEditorModal
        entityName="Expense"
        entityData={data}
        field={{
          id: "receipts",
          label: "receipts",
          icon: "receipts",
          fieldType: "gallery",
        }}
        open={receiptsEditor}
        onCancel={handleCancelReceipts}
        onChange={handleChangeReceipts}
        returnValue
      />

      <OFDDialog
        open={openSaveDialog}
        title="confirmSaveExpense"
        description="confirmSaveExpense"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={saveConfirmation}
      />

      <OFDDialog
        open={openDeleteDialog}
        title="confirmDeleteExpense"
        description="confirmDelete_expense"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default ExpenseEditor;
