import React from "react";

import useMember from "../../hooks/useMember";
import useFamily from "../../hooks/useFamily";

import { Box, Typography } from "@mui/material";

import OFDSelect from "./OFDSelect";
import OFDAvatar from "./OFDAvatar";

const OFDMember = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  fullWidth,
  security,
  entryAddedBy,
  memberList,
}) => {
  const { member } = useMember();
  const { familyMembers } = useFamily();

  const listItems = () => {
    let options = [];

    let list = memberList ? memberList : familyMembers;

    for (const member of list) {
      options.push({
        value: member.id,
        label: (
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            gap={1}
          >
            <OFDAvatar
              avatar={member.avatar}
              name={member.name}
            />
            <Typography>{member.name}</Typography>
          </Box>
        ),
      });
    }

    return options;
  };

  return (
    <OFDSelect
      id={id}
      label={label}
      value={value || member.id}
      onChange={onChange}
      message={message}
      error={error}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      security={security}
      entryAddedBy={entryAddedBy}
      options={listItems()}
    />
  );
};

export default OFDMember;
