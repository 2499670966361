import { useEffect, useState } from "react";

import useWindow from "../../../../hooks/useWindow";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const DashboardWidget = ({ title, height, children }) => {
  const { windowWidth } = useWindow();
  const { getTitle } = useLocalization();

  const theme = useTheme();

  const [widgetHeight, setWidgetHeight] = useState();
  const [widgetWidth, setWidgetWidth] = useState();

  useEffect(() => {
    setWidgetHeight((height - 48) / 2);
  }, [height]);

  useEffect(() => {
    setWidgetWidth((windowWidth - 120) / 3);
  }, [windowWidth]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: `${widgetHeight}px`,
          width: `${widgetWidth}px`,
        }}
        gap={1}
      >
        <Typography
          align="center"
          variant="h5"
        >
          {getTitle(title)}
        </Typography>

        <Box
          sx={{
            backgroundColor: "#f0f0f0",
            borderRadius: "16px",
            padding: "1rem",
            height: "100%",
            overflow: "auto",
            border: "1px solid #000",
            boxShadow: theme.shadows[3],
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default DashboardWidget;
