import React, { useEffect, useState, Suspense } from "react";
import {
  getMonth,
  getYear,
  startOfToday,
  getDaysInMonth,
  endOfMonth,
  getDay,
  startOfWeek,
  startOfDay,
  endOfWeek,
  format,
  subDays,
  addDays,
  isEqual,
  isAfter,
  isBefore,
  parseISO,
} from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useCalendar from "../../hooks/useCalendar";
import useReminder from "../../hooks/useReminder";
import useMember from "../../hooks/useMember";
import useFamily from "../../hooks/useFamily";
import useWindow from "../../hooks/useWindow";
import useOccasion from "../../hooks/useOccasion";

import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDYearMonth from "../ui/OFDYearMonth";
import OFDIcon from "../ui/OFDIcon";
import OFDMessage from "../ui/OFDMessage";
import AppointmentDisplay from "./AppointmentDisplay";
import ReminderItems from "./ReminderItems";
import ToDoItems from "./ToDoItems";
import OFDDialog from "../layout/OFDDialog";
import Loading from "../layout/Loading";
import OFDListAndDetails from "../layout/OFDListAndDetails";
import OccasionsDisplay from "./OccasionsDisplay";
import SeriesDelete from "./SeriesDelete";
import LoadingModal from "../layout/LoadingModal";
import GroceryShop from "../editors/GroceryShop";

const OFDMemberFilter = React.lazy(() => import("../ui/OFDMemberFilter"));
const OFDCategoryFilter = React.lazy(() => import("../ui/OFDCategoryFilter"));

const CalendarDisplay = ({
  refresh,
  onEditAppointment,
  onEditReminder,
  onEditToDo,
  onSelectedDate,
}) => {
  const {
    data,
    calendarData,
    getCalendarData,
    clearData,
    getForDateRange,
    filterCalendarData,
    clearFilter,
    dateRangeFilter,
    yearMonthFilter,
    getDailyItems,
    dailyItems,
    deleteCalendar,
    deleteSeries,
    message,
    setMessage,
    getCurrentMembers,
    getCurrentCategories,
    dayHasReminders,
    dayHasToDos,
    getDailyReminders,
    dailyReminders,
    getDailyToDos,
    dailyToDos,
    storeSelectedDate,
    completeToDo,
    deleteEntity,
  } = useCalendar();

  const { calendarLists } = useLocalization();
  const { member, memberColor, getMemberColor } = useMember();
  const { familyMembers } = useFamily();
  const { isDesktop, isMobile } = useWindow();
  const { getOccasionCalendar } = useOccasion();

  const theme = useTheme();

  const [occasions, setOccasions] = useState(null);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendar, setCalendar] = useState(null);
  const [selectedGroceryId, setSelectedGroceryId] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dayOccasions, setDayOccasions] = useState(null);
  const [openSeriesDelete, setOpenSeriesDelete] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [openShop, setOpenShop] = useState(false);

  const [filter, setFilter] = useState();

  const dayWidth = 45;
  const dayHeight = 30;

  useEffect(() => {
    setSelectedYear(getYear(startOfToday()));
    setSelectedMonth(getMonth(startOfToday()));
    setSelectedDate(startOfToday());
    setSelectedOption(null);
    setSelectedItem(null);
    setOccasions(null);
    setDayOccasions(null);
  }, []);

  useEffect(() => {
    getData();
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    if (!member) return;

    getData();
  }, [refresh, member]);

  const getData = async () => {
    if (selectedYear === null || selectedMonth === null) return;

    setOpenLoading(true);

    setOccasions(null);
    setDayOccasions(null);
    clearData();

    let filter = {
      ...yearMonthFilter(selectedYear, selectedMonth),
    };

    const occasionResults = await getOccasionCalendar(filter);
    if (occasionResults.isSuccessful) {
      setOccasions(occasionResults.data);
    } else {
      setOccasions(null);
    }

    // filter.$and = [{ $or: [{ todoComplete: false }, { todoComplete: null }] }];

    await getCalendarData({ filter });

    setOpenLoading(false);
  };

  useEffect(() => {
    resetFilter();
  }, [data]);

  useEffect(() => {
    if (!calendarData) return;

    buildCalendar();
    getDailyItems(selectedDate);
    getDailyReminders(selectedDate);
    getDailyToDos(selectedDate);
  }, [calendarData]);

  useEffect(() => {
    filterCalendarData(filter);
  }, [filter]);

  useEffect(() => {
    if (!selectedDate) return;

    onSelectedDate(selectedDate);
    storeSelectedDate(selectedDate);

    handleDateSelection();
  }, [selectedDate]);

  const handleDateSelection = () => {
    getDailyItems(selectedDate);
    getDailyReminders(selectedDate);
    getDailyToDos(selectedDate);

    buildCalendar();
  };

  const hasOccasions = (currentDate) => {
    if (!Array.isArray(occasions) || occasions.length === 0) return false;

    let occasionFound = false;

    let date = format(currentDate, "yyyy-MM-dd");

    for (const occasion of occasions) {
      let occasionDate = format(occasion.startDate, "yyyy-MM-dd");
      if (date === occasionDate) {
        occasionFound = true;
        break;
      }
    }

    return occasionFound;
  };

  const buildCalendar = () => {
    setCalendar(null);
    setSelectedItem(null);
    setSelectedOption(null);

    const monthStart = new Date(selectedYear, selectedMonth, 1);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const calendarWeeks = [];
    let daysArray = [];

    let prevWeekStart = null;
    let maxDots = null;

    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate = addDays(currentDate, 1)
    ) {
      let weekStart = startOfWeek(currentDate);
      let weekEnd = endOfWeek(currentDate);

      if (!isEqual(weekStart, prevWeekStart)) {
        maxDots = calcMaxDots(weekStart, weekEnd);
      }

      daysArray.push(buildDay(currentDate, maxDots));

      if (format(currentDate, "yyyy-MM-dd") === format(weekEnd, "yyyy-MM-dd")) {
        calendarWeeks.push(
          <Box
            key={`weekOF_${format(weekStart, "yyyy-MM-dd")}`}
            sx={{
              display: "flex",
              alignItems: "center",
              ...memberColor({ color: "grey", shade: 50 }),
            }}
          >
            {daysArray}
          </Box>
        );
        daysArray = [];
      }

      prevWeekStart = weekStart;
    }

    const calendarBody = <Stack key="calendarBody">{calendarWeeks}</Stack>;

    const calendarHeader = buildCalendarHeader();
    setCalendar(
      <Box>
        {calendarHeader}
        {calendarBody}
      </Box>
    );
  };

  const buildCalendarHeader = () => {
    const calendarHdrColumns = [];

    for (const dow of calendarLists.weekdays) {
      calendarHdrColumns.push(
        <Box
          key={`hdrCol_${dow.short}`}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: `${dayWidth}px`,
            paddingTop: ".5rem",
            paddingBottom: ".5rem",
          }}
        >
          {dow.letter}
        </Box>
      );
    }

    const calendarHeader = (
      <Box
        key="calendarHeader"
        sx={{
          display: "flex",
          ...memberColor({ color: "grey", shade: 50 }),
        }}
      >
        {calendarHdrColumns}
      </Box>
    );

    return calendarHeader;
  };

  const buildDay = (date, maxDots) => {
    let dayElement = null;
    const key = format(date, "yyyy-MM-dd");

    const dayHasOccasions = hasOccasions(date);

    const dayStart = startOfDay(date);

    let dots = buildMemberDots(dayStart);

    let dotsHeight = Math.ceil(maxDots / 4) * 12;
    let totalHeight = dayHeight + dotsHeight;

    let dayColor = "inherit";
    let occasionStyle = {};
    if (getMonth(dayStart) !== selectedMonth) {
      dayColor = "#ddd";
    } else if (dayHasOccasions) {
      occasionStyle = {
        borderTop: `5px solid ${memberColor({ shade: 900 }).backgroundColor}`,
      };
    }

    dayElement = (
      <Box
        key={key}
        sx={{
          width: `${dayWidth}px`,
          height: `${totalHeight}px`,
          minHeight: `${dayWidth}px`,
          border: daySelected(date)
            ? `1px solid ${memberColor({ shade: 900 }).backgroundColor}`
            : `1px solid #eee`,

          color: dayColor,
          ...occasionStyle,
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: `${dayWidth}px`,
            height: `30px`,
          }}
          onClick={() => handleDaySelect(dayStart)}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            {dayHasToDos(dayStart) ? (
              <OFDIcon
                name="checkcircle"
                size="xxsmall"
                color="grey"
                shade={900}
              />
            ) : null}
            <Typography>{format(date, "dd")}</Typography>
            {dayHasReminders(dayStart) ? (
              <OFDIcon
                name="reminder"
                size="xxsmall"
                color="red"
              />
            ) : null}
          </Box>
        </Box>
        <Box
          id={key}
          onClick={() => handleDaySelect(dayStart)}
          sx={{
            display: "flex",
            justifyContent: "center",
            height: `${dotsHeight}px`,
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid container>{dots}</Grid>
        </Box>
      </Box>
    );

    return dayElement;
  };

  const buildMemberDots = (date, calculatingMax) => {
    if (!date) return [];

    if (!calendarData || calendarData.length === 0) {
      return;
    }
    const scheduledMembers = calendarData.filter((item) =>
      isEqual(startOfDay(date), startOfDay(item.startDate))
    );

    if (!scheduledMembers || scheduledMembers.length === 0) {
      return;
    }

    const members = new Set();
    for (const schedule of scheduledMembers) {
      if (schedule.calendarType !== "appointment") {
        continue;
      }
      if (Array.isArray(schedule.attendees) && schedule.attendees.length > 0) {
        members.add(schedule.memberId);
      } else {
        members.add("noAttendees");
      }
    }

    const dots = [];

    for (const memberId of members) {
      dots.push(
        <Grid
          key={memberId}
          sx={{
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            ...memberColor({ color: getMemberColor(memberId) }),
            margin: "1px",
          }}
        ></Grid>
      );
    }

    return dots;
  };

  const calcMaxDots = (weekStart, weekEnd) => {
    let maxDots = 0;

    for (
      let currentDate = weekStart;
      currentDate <= weekEnd;
      currentDate = addDays(currentDate, 1)
    ) {
      let currentDots = buildMemberDots(currentDate, true);
      if (!Array.isArray(currentDots)) continue;

      if (currentDots.length > maxDots) {
        maxDots = currentDots.length;
      }
    }

    return maxDots;
  };

  const handleDaySelect = (date) => {
    setSelectedDate(date);

    setDayOccasions(() => {
      let inDate = format(date, "yyyy-MM-dd");

      const occasionArray = [];
      if (occasions && occasions.length > 0) {
        for (const occasion of occasions) {
          let occasionDate = format(occasion.startDate, "yyyy-MM-dd");
          if (occasionDate === inDate) {
            occasionArray.push(occasion);
          }
        }
      }

      return occasionArray;
    });
  };

  const daySelected = (date) => {
    if (!selectedDate) return false;

    return isEqual(date, selectedDate);
  };

  const handleYearMonthChange = (newValue) => {
    setSelectedYear(newValue.selectedYear);
    setSelectedMonth(newValue.selectedMonth);
  };

  const handleGotoToday = () => {
    setSelectedYear(getYear(startOfToday()));
    setSelectedMonth(getMonth(startOfToday()));
    setSelectedDate(startOfToday());
  };

  const handleDelete = async (item, option) => {
    setSelectedItem(item);
    setSelectedOption(option);

    if (option === "series") {
      setOpenSeriesDelete(true);
    } else {
      setOpenDelete(true);
    }
  };

  const handleDeleteSeries = async (seriesFilter) => {
    setOpenLoading(true);
    // await deleteSeries(selectedItem, seriesFilter);
    await deleteEntity(selectedItem, seriesFilter);
    await getData();
    setOpenLoading(false);

    setSelectedItem(null);
    setSelectedOption(null);
    setOpenSeriesDelete(false);
  };

  const handleConfirmDelete = async (response) => {
    if (response === "yes") {
      setOpenLoading(true);
      if (selectedItem.frequency === "onetime") {
        await deleteEntity(selectedItem);
      } else {
        await deleteCalendar(selectedItem, selectedOption);
      }
      await getData();
      setOpenLoading(false);
      getDailyItems(selectedDate);
      getDailyReminders(selectedDate);
      getDailyToDos(selectedDate);
    }
    setSelectedItem(null);
    setSelectedOption(null);
    setOpenDelete(false);
  };

  const handleCompleteToDo = async (item, option) => {
    await completeToDo(item, !item.todoComplete);
    await getData();
  };

  const resetFilter = () => {
    setFilter(() => {
      let newFilter = {
        members: getCurrentMembers(),
        categories: getCurrentCategories(),
      };

      return newFilter;
    });
  };

  const handleMemberFilter = (newValue) => {
    setFilter((current) => {
      return {
        ...current,
        members: [...newValue],
      };
    });
  };

  const handleCategoryFilter = (newValue) => {
    setFilter((current) => {
      return {
        ...current,
        categories: [...newValue],
      };
    });
  };

  const handleShop = (calendar) => {
    setSelectedGroceryId(calendar.source.groceryId);
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setSelectedGroceryId(null);
    setOpenShop(false);
  };

  if (!calendar) return <LoadingModal />;

  return (
    <>
      {isDesktop ? (
        <Box>
          <OFDListAndDetails
            list={
              <Box>
                <Box
                  sx={{
                    ...memberColor({ color: "grey", shade: 50 }),
                    borderRadius: "8px",
                    padding: ".5rem",
                    width: "100%",
                    marginBottom: "8px",
                    boxShadow: theme.shadows[1],
                  }}
                >
                  <Suspense fallback={<div></div>}>
                    <OFDMemberFilter
                      value={filter ? filter.members : []}
                      onChange={handleMemberFilter}
                      available={getCurrentMembers()}
                    />
                  </Suspense>
                </Box>

                <Box
                  sx={{
                    ...memberColor({ color: "grey", shade: 50 }),
                    borderRadius: "8px",
                    padding: ".5rem",
                    width: "100%",
                    boxShadow: theme.shadows[1],
                  }}
                >
                  <Suspense fallback={<div></div>}>
                    <OFDCategoryFilter
                      value={filter ? filter.categories : []}
                      onChange={handleCategoryFilter}
                      available={getCurrentCategories()}
                    />
                  </Suspense>
                </Box>

                <Box
                  sx={{
                    ...memberColor({ color: "grey", shade: 50 }),
                    borderRadius: "8px",
                    padding: ".5rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: ".5rem",
                    boxShadow: theme.shadows[1],
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "1rem",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                    gap={2}
                  >
                    <Box sx={{ marginTop: "-1rem" }}>
                      <OFDIcon
                        name="today"
                        size="medium"
                        color="grey"
                        shade={700}
                        onClick={handleGotoToday}
                      />
                    </Box>
                    <OFDYearMonth
                      selectedYear={selectedYear}
                      selectedMonth={selectedMonth}
                      onChange={handleYearMonthChange}
                    />
                  </Box>

                  <Box>{calendar ? calendar : <Loading />}</Box>
                </Box>
              </Box>
            }
            details={
              <Box>
                {dayOccasions && dayOccasions.length > 0 ? (
                  <Box sx={{ width: "100%", marginTop: "1rem" }}>
                    <OccasionsDisplay occasions={dayOccasions} />
                  </Box>
                ) : null}

                {dailyReminders && dailyReminders.length > 0 ? (
                  <Box sx={{ width: "100%", marginTop: "1rem" }}>
                    <ReminderItems
                      reminders={dailyReminders}
                      onEdit={onEditReminder}
                      onDelete={(item, option) => handleDelete(item, option)}
                    />
                  </Box>
                ) : null}

                {dailyToDos && dailyToDos.length > 0 ? (
                  <Box sx={{ width: "100%", marginTop: "1rem" }}>
                    <ToDoItems
                      todos={dailyToDos}
                      onEdit={onEditToDo}
                      onDelete={(item, option) => handleDelete(item, option)}
                      onComplete={handleCompleteToDo}
                    />
                  </Box>
                ) : null}

                <Stack
                  spacing={2}
                  sx={{ width: "100%", marginTop: "1rem" }}
                >
                  {dailyItems?.map((item) => (
                    <AppointmentDisplay
                      key={item.id}
                      calendar={item}
                      onDelete={(option) => handleDelete(item, option)}
                      onEdit={(option) => onEditAppointment(item, option)}
                    />
                  ))}
                </Stack>
              </Box>
            }
            detailStyles={{
              backgroundColor: "#ebebeb",
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            marginBottom: "3rem",
          }}
        >
          <Box
            sx={{
              ...memberColor({ color: "grey", shade: 50 }),
              borderRadius: "8px",
              padding: ".5rem",
              width: "100%",
              marginBottom: "8px",
              boxShadow: theme.shadows[1],
            }}
          >
            <Suspense fallback={<div></div>}>
              <OFDMemberFilter
                value={filter ? filter.members : []}
                onChange={handleMemberFilter}
                available={getCurrentMembers()}
              />
            </Suspense>
          </Box>

          <Box
            sx={{
              ...memberColor({ color: "grey", shade: 50 }),
              borderRadius: "8px",
              padding: ".5rem",
              width: "100%",
              boxShadow: theme.shadows[1],
            }}
          >
            <Suspense fallback={<div></div>}>
              <OFDCategoryFilter
                value={filter ? filter.categories : []}
                onChange={handleCategoryFilter}
                available={getCurrentCategories()}
              />
            </Suspense>
          </Box>

          <Box
            sx={{
              ...memberColor({ color: "grey", shade: 50 }),
              borderRadius: "8px",
              padding: ".5rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: ".5rem",
              boxShadow: theme.shadows[1],
            }}
          >
            <Box
              sx={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
                display: "flex",
                alignItems: "center",
              }}
              gap={2}
            >
              <Box sx={{ marginTop: "-1rem" }}>
                <OFDIcon
                  name="today"
                  size="medium"
                  color="grey"
                  shade={700}
                  onClick={handleGotoToday}
                />
              </Box>
              <OFDYearMonth
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                onChange={handleYearMonthChange}
                variant={isMobile ? "short" : "full"}
              />
            </Box>

            <Box>{calendar}</Box>
          </Box>

          {dayOccasions && dayOccasions.length > 0 ? (
            <Box sx={{ width: "100%", marginTop: "1rem" }}>
              <OccasionsDisplay
                occasions={dayOccasions}
                onUpdate={getData}
              />
            </Box>
          ) : null}

          {dailyReminders && dailyReminders.length > 0 ? (
            <Box sx={{ width: "100%", marginTop: "1rem" }}>
              <ReminderItems
                reminders={dailyReminders}
                onEdit={onEditReminder}
                onDelete={(item, option) => handleDelete(item, option)}
              />
            </Box>
          ) : null}

          {dailyToDos && dailyToDos.length > 0 ? (
            <Box sx={{ width: "100%", marginTop: "1rem" }}>
              <ToDoItems
                todos={dailyToDos}
                onEdit={onEditToDo}
                onDelete={(item, option) => handleDelete(item, option)}
                onComplete={handleCompleteToDo}
                onShop={handleShop}
              />
            </Box>
          ) : null}

          <Stack
            spacing={2}
            sx={{ width: "100%", marginTop: "1rem" }}
          >
            {dailyItems?.map((item) => (
              <AppointmentDisplay
                key={item.id}
                calendar={item}
                onDelete={(option) => handleDelete(item, option)}
                onEdit={(option) => onEditAppointment(item, option)}
              />
            ))}
          </Stack>
        </Box>
      )}

      <OFDMessage
        message={message}
        onClose={() => setMessage(null)}
      />

      {openSeriesDelete ? (
        <SeriesDelete
          data={selectedItem}
          open={openSeriesDelete}
          onClose={() => {
            setOpenSeriesDelete(false);
          }}
          onDelete={handleDeleteSeries}
        />
      ) : null}

      {openShop ? (
        <GroceryShop
          groceryId={selectedGroceryId}
          open={openShop}
          onClose={handleCloseShop}
        />
      ) : null}

      <OFDDialog
        open={openDelete}
        title="confirmDelete"
        description={`confirmDelete_${selectedItem?.calendarType}_${
          selectedOption ? selectedOption : "occurrence"
        }`}
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <LoadingModal open={openLoading} />
    </>
  );
};

export default CalendarDisplay;
