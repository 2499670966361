import { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import useMember from "../../hooks/useMember";
import useNotifications from "../../hooks/useNotifications";
import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDTitle from "../layout/OFDTitle";
import OFDContainer from "../ui/OFDContainer";
import OFDSwitch from "../ui/OFDSwitch";
import OFDPhone from "../ui/OFDPhone";
import OFDEmail from "../ui/OFDEmail";
import OFDMessage from "../ui/OFDMessage";
import OFDPasswordField from "../ui/OFDPasswordField";
import OFDButton from "../ui/OFDButton";
import OFDFieldGroup from "../ui/OFDFieldGroup";

const Notifications = ({ onChange }) => {
  const { getData } = useApi();
  const { getMessage, getLabel } = useLocalization();

  const {
    notificationsAvailable,
    askForNotificationPermission,
    updateMemberSubscription,
  } = useNotifications();

  const {
    member,
    memberPreferences,
    verifyPassword,
    validatePassword,
    updatePassword,
    getMemberState,
    defaultMemberPreferences,
  } = useMember();

  const [preferences, setPreferences] = useState(null);
  const [status, setStatus] = useState();

  useEffect(() => {
    setStatus("initial");
    getMemberPreferences();
  }, [member]);

  const getMemberPreferences = async () => {
    const results = await getData({
      entityName: "Member",
      action: "get",
      id: member.id,
    });

    if (results.isSuccessful && results.data) {
      if (results.data.preferences) {
        setPreferences(results.data.preferences);
      } else {
        setPreferences(defaultMemberPreferences);
      }
    }

    setStatus("unchanged");
  };

  useEffect(() => {
    if (!preferences || status === "unchanged") return;

    onChange(preferences);
  }, [preferences, status]);

  const getNotificationEnabled = (field) => {
    if (preferences.notifications.hasOwnProperty(field)) {
      return preferences.notifications[field].enabled;
    } else {
      return false;
    }
  };

  const setNotificationEnabled = async (field, newValue) => {
    if (field === "push") {
      if (newValue) {
        await askForNotificationPermission();
      } else {
        await updateMemberSubscription(null);
      }
    }

    setPreferences((current) => {
      let newNotifications;

      if (current && current.notifications) {
        newNotifications = {
          ...current.notifications,
          [field]: {
            enabled: newValue,
            value: current.notifications.hasOwnProperty(field)
              ? current.notifications[field].value
              : null,
          },
        };
      } else {
        newNotifications = {
          ...defaultMemberPreferences.notifications,
          [field]: {
            enabled: newValue,
            value: defaultMemberPreferences.notifications[field].value,
          },
        };
      }

      let preferences = current ? current : defaultMemberPreferences;

      return { ...preferences, notifications: { ...newNotifications } };
    });
  };

  const getNotificationValue = (field) => {
    return preferences.notifications[field].value;
  };

  const setNotificationValue = (field, newValue) => {
    setPreferences((current) => {
      let newNotifications = {
        ...current.notifications,
        [field]: {
          enabled: current.notifications[field].enabled,
          value: newValue,
        },
      };

      return { ...current, notifications: { ...newNotifications } };
    });

    setStatus("changed");
  };

  if (!preferences) return null;

  return (
    <>
      <OFDContainer>
        <Stack spacing={1}>
          <Box>
            <OFDSwitch
              id="app"
              value={getNotificationEnabled("app")}
              label="app"
              onChange={(newValue) => setNotificationEnabled("app", newValue)}
            />

            <Box></Box>
          </Box>

          {notificationsAvailable ? (
            <Box>
              <OFDSwitch
                id="push"
                value={getNotificationEnabled("push")}
                label="push"
                onChange={(newValue) =>
                  setNotificationEnabled("push", newValue)
                }
              />

              <Box></Box>
            </Box>
          ) : null}

          <Box>
            <OFDSwitch
              id="sms"
              value={getNotificationEnabled("sms")}
              label="sms"
              onChange={(newValue) => setNotificationEnabled("sms", newValue)}
            />

            {getNotificationEnabled("sms") ? (
              <Box sx={{ marginLeft: "3rem" }}>
                <OFDPhone
                  id="sms"
                  label="cell"
                  value={getNotificationValue("sms")}
                  onChange={(newValue) => setNotificationValue("sms", newValue)}
                />
              </Box>
            ) : null}
          </Box>

          <Box>
            <OFDSwitch
              id="email"
              value={getNotificationEnabled("email")}
              label="email"
              onChange={(newValue) => setNotificationEnabled("email", newValue)}
            />

            {getNotificationEnabled("email") ? (
              <Box sx={{ marginLeft: "3rem" }}>
                <OFDEmail
                  id="email"
                  label="email"
                  value={getNotificationValue("email")}
                  onChange={(newValue) =>
                    setNotificationValue("email", newValue)
                  }
                  fullWidth
                />
              </Box>
            ) : null}
          </Box>
        </Stack>
      </OFDContainer>
    </>
  );
};

export default Notifications;
