import React from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

const OFDMealTypes = ({ id, value, onChange }) => {
  const { getList, getLabel } = useLocalization();

  const isSelected = (name) => {
    if (Array.isArray(value) && value.includes(name)) {
      return true;
    }

    return false;
  };

  const handleSelect = (name) => {
    let selection = Array.isArray(value) ? [...value] : [];

    const index = selection.findIndex((item) => item === name);
    if (index > -1) {
      selection.splice(index, 1);
    } else {
      selection.push(name);
    }

    onChange(selection);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          padding: "8px",
          paddingTop: "12px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          position: "relative",
        }}
        gap={1}
      >
        <Box
          sx={{
            position: "absolute",
            top: "-12px",
            left: "10px",
            backgroundColor: "#fff",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Typography variant="caption">{getLabel("mealType")}</Typography>
        </Box>
        {getList("mealTypes").map((mealType) => (
          <Box
            key={mealType.value}
            sx={{
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: isSelected(mealType.value) ? "#000" : "#fff",
              color: isSelected(mealType.value) ? "#fff" : "#000",
            }}
            onClick={() => handleSelect(mealType.value)}
          >
            <Typography>{mealType.label}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default OFDMealTypes;
