import { useEffect, useState } from "react";

import useColor from "../../hooks/useColor";
import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

const OFDDisplayGroceryItem = ({ value, onClick }) => {
  const { calendarLists, getList } = useLocalization();
  const { getColor } = useColor();
  const [displayValue, setDisplayValue] = useState("");
  const [expandImage, setExpandImage] = useState(false);

  const thumbnailStyle = {
    width: "50px",
  };

  const expandedStyle = {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    width: "100%",
    zIndex: 1000,
    backgroundColor: "#fff",
    padding: "1rem",
  };

  useEffect(() => {
    buildDisplayValue();
  }, [value]);

  const buildDisplayValue = () => {
    if (!value) return;

    let display = "";

    if (value.department) {
      display += value.department + " | ";
    }
    display += value.item;

    if (value.quantity) {
      display += ` (${value.quantity})`;
    }

    setDisplayValue(display);
  };

  const handleClick = () => {
    if (onClick) onClick(value);
  };

  const handleExpandImage = () => {
    setExpandImage((current) => !current);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: ".5rem",
        width: "100%",
        ...getColor({ color: "grey", shade: 200 }),
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <Box onClick={handleClick}>
        <Typography>{displayValue}</Typography>

        {value.note ? (
          <Typography
            variant="caption"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {value.note}
          </Typography>
        ) : null}
      </Box>

      {value.image ? (
        <Box
          sx={expandImage ? expandedStyle : thumbnailStyle}
          onClick={handleExpandImage}
        >
          <img
            src={value.image}
            width="100%"
            loading="lazy"
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDDisplayGroceryItem;
