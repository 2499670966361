import { useState } from "react";

import useApi from "./useApi";
import useDebug from "./useDebug";

const initialState = {
  country: "CA",
  language: "en",
  localeCode: process.env.REACT_APP_LOCALE,
  email: "",
  confirmationCode: "",
  codeVerified: false,
  password: "",
  familyName: "",
  memberName: "",
  termsAccepted: null,
  termsVersion: "",
  privacyAccepted: null,
  privacyVersion: "",
  couponCode: "",
  subscriptionPlanId: null,
  priceId: null,
};

const stages = [
  { step: 0, name: "email" },
  { step: 1, name: "confirmCode" },
  { step: 2, name: "familyInfo" },
  { step: 3, name: "termsOfUse" },
  { step: 4, name: "subscriptionPlan" },
  { step: 5, name: "coupon" },
  { step: 6, name: "advisorAgreement" },
  { step: 7, name: "payment" },
  { step: 8, name: "thankyou" },
  { step: 9, name: "notAvailable" },
];

const useSignup = () => {
  const [signup, setSignup] = useState({ ...initialState });
  const { consoleLog } = useDebug(false);
  const { postData } = useApi();

  const [stage, setStage] = useState({ step: 0, name: "email" });

  const updateSignup = async (fields) => {
    consoleLog("useSignup", updateSignup, { fields });
    const results = await postData({
      entityName: "Signup",
      action: "validateSignup",
      data: {
        stage: stage.name,
        ...signup,
        ...fields,
      },
    });

    if (results.isSuccessful) {
      setSignup((current) => {
        return { ...current, ...results.data };
      });
    } else {
      if (stage.name === "thankyou") {
        setStage(stages[5]);
      }
    }

    return results;
  };

  const completeSignup = async (args) => {
    const data = {
      stage: stage.name,
      ...args,
    };

    const results = await postData({
      entityName: "Signup",
      action: "completeSignup",
      data,
    });

    return results;
  };

  const nextStage = () => {
    if (stage.step === stages.length - 1) return;

    switch (stage.name) {
      case "coupon":
        let advisorAgreement = stages.find(
          (item) => item.name === "advisorAgreement"
        );
        if (signup.isAdvisor) {
          setStage(stages[advisorAgreement.step]);
        } else {
          setStage(stages[advisorAgreement.step + 1]);
        }
        break;

      case "advisorAgreement":
        let payment = stages.find((item) => item.name === "payment");
        setStage(stages[payment.step]);
        break;

      default:
        setStage((current) => stages[current.step + 1]);
    }
  };

  const prevStage = () => {
    if (stage.step === 0) return;

    setStage((current) => stages[current.step - 1]);
  };

  return {
    signup,
    stage,
    setStage,
    updateSignup,
    nextStage,
    prevStage,
    completeSignup,
  };
};

export default useSignup;
