import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";
import useData from "../../hooks/useData";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDFieldSecurity from "../ui/OFDFieldSecurity";
import OFDToolbar from "../layout/OFDToolbar";
import OFDMessage from "../ui/OFDMessage";

const DocumentsFolder = ({ open, onClose, onSave }) => {
  const { getTitle } = useLocalization();
  const { newId } = useData();

  const [name, setName] = useState(null);
  const [nameMessage, setNameMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [description, setDescription] = useState(null);
  const [security, setSecurity] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    setName("");
    setDescription("");
    setSecurity({
      view: { securityLevel: "family" },
      update: { securityLevel: "addedBy" },
      delete: { securityLevel: "addedBy" },
    });
  }, []);

  const saveFolder = async () => {
    if (!isValid()) return;

    let folder = {
      name,
      description,
      security,
    };

    onSave(folder);
  };

  const isValid = () => {
    if (name && name.trim().length > 0) {
      setNameError(false);
      setNameMessage("");
      return true;
    } else {
      setNameError(true);
      setNameMessage("isEmpty");
      return false;
    }
  };

  const handleMenuClick = async (action) => {
    if (action === "save") {
      await saveFolder();
    }
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={getTitle("documentFolder")}
      >
        <OFDToolbar
          menuItems={[{ name: "save", label: "save", icon: "save" }]}
          onClick={handleMenuClick}
        />
        <Box sx={{ padding: "1rem" }}>
          <Stack spacing={2}>
            <OFDTextField
              id="name"
              label="name"
              value={name}
              onChange={(newValue) => setName(newValue)}
              fullWidth
              required
              error={nameError}
              message={nameMessage}
            />

            <OFDTextNote
              id="description"
              label="description"
              value={description}
              onChange={(newValue) => setDescription(newValue)}
              fullWidth
            />

            <OFDFieldSecurity
              id="security"
              label="security"
              value={security}
              onChange={(newValue) => setSecurity(newValue)}
            />
          </Stack>
        </Box>
      </OFDModal>

      <OFDMessage
        message={message}
        onClose={() => setMessage(null)}
      />
    </>
  );
};

export default DocumentsFolder;
