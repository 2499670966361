import { useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDCollapsable from "../layout/OFDCollapsable";
import ToDoItem from "./ToDoItem";
import OFDIcon from "../ui/OFDIcon";

const ToDoItems = ({
  todos,
  onEdit,
  onDelete,
  onDelay,
  onComplete,
  onShop,
}) => {
  const { getTitle } = useLocalization();

  return (
    <Box sx={{ width: "100%" }}>
      <OFDCollapsable
        header={
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <OFDIcon name="todo" />
            <Typography>{getTitle("todos")}</Typography>
          </Box>
        }
      >
        <Stack>
          {todos?.map((calendar) => (
            <ToDoItem
              key={calendar.id}
              calendar={calendar}
              onEdit={onEdit}
              onDelay={onDelay}
              onDelete={onDelete}
              onComplete={onComplete}
              onShop={onShop}
            />
          ))}
        </Stack>
      </OFDCollapsable>
    </Box>
  );
};

export default ToDoItems;
