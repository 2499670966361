import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useLocalization from "../../../../hooks/useLocalization";
import useSecurity from "../../../../hooks/useSecurity";
import useMessage from "../../../../hooks/useMessage";
import usePage from "../../../../hooks/usePage";
import useApple from "../../../../hooks/useApple";

import { Box, Button, Stack, Typography } from "@mui/material";

import FamilyContentSlider from "../../../layout/FamilySliderLayout";
import OFDTextField from "../../../ui/OFDTextField";
import OFDPasswordField from "../../../ui/OFDPasswordField";
import OFDMessage from "../../../ui/OFDMessage";

const Login = () => {
  const { titles, labels, text, getLabel } = useLocalization(
    process.env.REACT_APP_LOCALE
  );
  const { destructureResults } = useMessage();
  const { login } = useSecurity();
  const navigate = useNavigate();
  const { setPage } = usePage();
  const { isApple, showPayWall, resubscribe } = useApple();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    setPage("login");
  }, []);

  const handleLogin = async () => {
    setMessage(null);
    const loginResults = await login(username, password);
    if (loginResults.message === "notActive" && isApple) {
      resubscribe(loginResults.data.signupId);
    } else {
      setMessage(destructureResults(loginResults));
    }
  };

  const handleSignup = () => {
    if (isApple) {
      showPayWall();
    } else {
      navigate("/signup/register");
    }
  };

  const handleForgotPassword = () => {
    navigate("/passwordreset");
  };

  return (
    <>
      <FamilyContentSlider>
        <Box>
          <Typography
            variant="h4"
            align="center"
          >
            {titles.login}
          </Typography>

          <Stack
            marginTop="1rem"
            width="100%"
            spacing={2}
          >
            <OFDTextField
              id="username"
              label={labels.username}
              value={username}
              onChange={(newValue) => setUsername(newValue)}
            />

            <OFDPasswordField
              id="password"
              label={labels.password}
              value={password}
              onChange={(newValue) => setPassword(newValue)}
            />

            <Button
              variant="contained"
              onClick={handleLogin}
            >
              {labels.login}
            </Button>

            <Typography align="center">{text.noAccount}</Typography>

            <Button
              variant="contained"
              onClick={handleSignup}
            >
              {labels.signup}
            </Button>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={handleForgotPassword}>
                {getLabel("forgotPassword")}
              </Button>
            </Box>
          </Stack>
        </Box>
      </FamilyContentSlider>

      <OFDMessage
        message={message}
        onClose={() => setMessage(null)}
      />
    </>
  );
};

export default Login;
