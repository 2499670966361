import { useEffect, useState } from "react";

import usePage from "../../../../hooks/usePage";
import useRecipe from "../../../../hooks/useRecipe";
import useWindow from "../../../../hooks/useWindow";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDMealTypes from "../../../ui/OFDMealTypes";
import OFDTextField from "../../../ui/OFDTextField";
import OFDButton from "../../../ui/OFDButton";
import RecipeListItem from "./RecipeListItem";
import RecipeDetails from "./RecipeDetails";
import OFDIcon from "../../../ui/OFDIcon";

const RecipeDashboard = ({ height }) => {
  const { refresh } = usePage();
  const { windowWidth } = useWindow();
  const { recipes, getRecipes, filterRecipes, filteredRecipes, clearFilter } =
    useRecipe();
  const { getLabel, getMessage } = useLocalization();

  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [searchFilter, setSearchFilter] = useState({
    mealTypes: null,
    searchValue: null,
  });

  useEffect(() => {
    getData();
  }, [refresh]);

  const getData = async () => {
    await getRecipes();
  };

  const handleMealTypeSelection = (newSelection) => {
    setSearchFilter((current) => {
      return { ...current, mealTypes: [...newSelection] };
    });
  };

  const handleKeyword = (keywords) => {
    setSearchFilter((current) => {
      return { ...current, searchValue: keywords };
    });
  };

  const handleClear = () => {
    setSearchFilter({
      mealTypes: null,
      searchValue: null,
    });
    clearFilter();
  };

  const handleSelect = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const handleSearch = () => {
    filterRecipes(searchFilter);
  };

  if (filteredRecipes === undefined) return;

  return (
    <>
      <Box sx={{ height: height }}>
        <Box
          sx={{
            display: "flex",
            padding: "1rem",
            alignItems: "center",
            height: "80px",
          }}
          gap={1}
        >
          <OFDMealTypes
            value={searchFilter.mealTypes}
            onChange={handleMealTypeSelection}
          />

          <OFDTextField
            label="keywords"
            value={searchFilter.searchValue}
            fullWidth
            onChange={handleKeyword}
            color="orange"
          />

          <OFDIcon
            name="search"
            color="grey"
            size="large"
            onClick={handleSearch}
          />

          {/* <OFDButton
            label="search"
            variant="contained"
            color="orange"
            onClick={handleSearch}
          /> */}

          <OFDButton
            label="clear"
            variant="outlined"
            color="orange"
            onClick={handleClear}
          />
        </Box>

        <Box
          sx={{ height: `${height - 80}px`, padding: "16px", display: "flex" }}
          gap={3}
        >
          <Box
            sx={{
              height: `${height - 80 - 32}px`,
              width: "400px",
              backgroundColor: "#efefef",
              borderRadius: "16px",
              overflow: "auto",
              padding: "10px",
            }}
          >
            {Array.isArray(filteredRecipes) && filteredRecipes.length > 0 ? (
              <Stack spacing={1}>
                {filteredRecipes.map((recipe) => (
                  <RecipeListItem
                    key={recipe.id}
                    recipe={recipe}
                    onSelect={handleSelect}
                    selected={recipe.id === selectedRecipe?.id}
                  />
                ))}
              </Stack>
            ) : (
              <Typography>{getMessage("noRecipesFound")}</Typography>
            )}
          </Box>

          <RecipeDetails
            recipe={selectedRecipe}
            width={windowWidth - 32 - 400}
            height={height - 80 - 32}
          />
        </Box>
      </Box>
    </>
  );
};

export default RecipeDashboard;
