import {
  getYear,
  isBefore,
  isAfter,
  isDate,
  isEqual,
  startOfDay,
  parseISO,
  startOfToday,
} from "date-fns";

import useMember from "./useMember";
import useDate from "./useDate";
import useRecurrence from "./useRecurrence";

const useToDo = () => {
  const { member } = useMember();
  const { toUTCDate } = useDate();
  const { transformRecurrence, validateRecurrence } = useRecurrence();

  const transformToDo = (passedToDo, option) => {
    let newToDo = { ...passedToDo };

    if (
      passedToDo.frequency !== "onetime" &&
      passedToDo.frequency !== "schedule"
    ) {
      newToDo.forever = Boolean(passedToDo.recurrence.forever);
    }

    if (option === "series") {
      newToDo.overridden = false;
    }

    if (option === "occurrence") {
      newToDo.time = newToDo.startDate;
    }

    if (!Array.isArray(newToDo.members) || newToDo.members.length === 0) {
      newToDo.members = [member.id];
    }

    newToDo.calendarType = "reminder";

    newToDo.recurrence = transformRecurrence(passedToDo.recurrence);

    newToDo.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const today = new Date();
    const utcDate = toUTCDate(today);
    const offset = (utcDate.getTimezoneOffset() / 60) * -1;

    newToDo.tzOffset = offset;

    return newToDo;
  };

  const validateToDo = (passedToDo, option) => {
    let results = {
      isSuccessful: true,
      message: "ok",
      displayToUser: false,
      fieldErrors: [],
      severity: "info",
    };

    results = validateRecurrence(passedToDo.recurrence);

    // name cannot be empty
    if (!passedToDo.name || passedToDo.name.trim().length === 0) {
      results.isSuccessful = false;
      results.fieldErrors.push({
        name: "name",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    // must have at least one member
    if (!Array.isArray(passedToDo.members) || passedToDo.members.length === 0) {
      results.isSuccessful = false;
      results.fieldErrors.push({
        name: "members",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    if (!results.isSuccessful) {
      results.message = "fieldErrors";
      results.displayToUser = true;
      results.severity = "error";
    }

    return results;
  };

  return {
    transformToDo,
    validateToDo,
  };
};

export default useToDo;
