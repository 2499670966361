import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useWindow from "../../hooks/useWindow";

import { Box, Stack, Typography, Typogrphy } from "@mui/material";

import OFDMember from "../ui/OFDMember";
import OFDNumber from "../ui/OFDNumber";
import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDUrl from "../ui/OFDUrl";
import OFDButton from "../ui/OFDButton";
import OFDMessage from "../ui/OFDMessage";
import OFDPicture from "../ui/OFDPicture";

import OFDDialog from "../layout/OFDDialog";
import { openDB } from "idb";

const TokenBanks = ({ fullSize = false, onSave }) => {
  const { getLabel, getText } = useLocalization();
  const { getMembersWithChores, updateMember, memberMessage } = useMember();
  const { isDesktop, windowHeight } = useWindow();

  const tokenDataTemplate = {
    tokenBank: 0,
    tokenObjective: {
      name: "",
      note: "",
      image: null,
      tokens: "",
    },
  };

  const [members, setMembers] = useState(undefined);
  const [selectedMember, setSelectedMember] = useState("");
  const [tokenData, setTokenData] = useState({ ...tokenDataTemplate });
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const currentSelection = selectedMember;

    setSelectedMember("");
    setMembers(undefined);
    const data = await getMembersWithChores();
    if (Array.isArray(data) && data.length > 0) {
      if (currentSelection) {
        setSelectedMember(currentSelection);
      } else {
        setSelectedMember(data[0].id);
      }
    }
    setMembers(data);
  };

  useEffect(() => {
    if (!selectedMember) {
      setTokenData({ ...tokenDataTemplate });
      return;
    }

    let member = members.find((m) => m.id === selectedMember);

    setTokenData({
      tokenBank: member.tokenBank || tokenDataTemplate.tokenBank,
      tokenObjective: member.tokenObjective || tokenDataTemplate.tokenObjective,
    });
  }, [selectedMember]);

  const getTokenValue = (field) => {
    if (field === "tokenBank") return tokenData.tokenBank;
    if (field === "tokenObjective.name") return tokenData.tokenObjective.name;
    if (field === "tokenObjective.note") return tokenData.tokenObjective.note;
    if (field === "tokenObjective.image") return tokenData.tokenObjective.image;
    if (field === "tokenObjective.tokens")
      return tokenData.tokenObjective.tokens;
    return "";
  };

  const setTokenValue = (field, newValue) => {
    if (field === "tokenBank") {
      setTokenData((current) => {
        return { ...current, tokenBank: newValue };
      });
    }

    if (field === "tokenObjective.name") {
      setTokenData((current) => {
        return {
          ...current,
          tokenObjective: { ...current.tokenObjective, name: newValue },
        };
      });
    }

    if (field === "tokenObjective.note") {
      setTokenData((current) => {
        return {
          ...current,
          tokenObjective: { ...current.tokenObjective, note: newValue },
        };
      });
    }

    if (field === "tokenObjective.image") {
      setTokenData((current) => {
        return {
          ...current,
          tokenObjective: { ...current.tokenObjective, image: newValue },
        };
      });
    }

    if (field === "tokenObjective.tokens") {
      setTokenData((current) => {
        return {
          ...current,
          tokenObjective: { ...current.tokenObjective, tokens: newValue },
        };
      });
    }
  };

  const saveLabel = () => {
    if (!selectedMember) return;

    let member = members.find((m) => m.id === selectedMember);

    let label = getLabel("saveTokensFor");

    let name = member.name.split(" ");
    label = label.replace("{name}", name[0]);
    return label;
  };

  const handleSave = async () => {
    const results = await updateMember(selectedMember, tokenData);
    await getData();
  };

  const confirmClear = () => {
    setShowDialog(true);
  };

  const clearConfirmation = async (response) => {
    setShowDialog(false);
    if (response === "yes") {
      await handleClear();
    }
  };

  const handleClear = async () => {
    const results = await updateMember(selectedMember, {
      ...tokenDataTemplate,
    });

    await getData();
  };

  const handleMemberSelect = (memberId) => {
    setSelectedMember(memberId);
  };

  if (members === undefined) return null;

  return (
    <>
      <Box sx={{ paddingTop: ".5rem" }}>
        {Array.isArray(members) && members.length > 0 ? (
          <Box>
            <Box sx={{ marginBottom: ".5rem" }}>
              <OFDMember
                memberList={members}
                value={selectedMember}
                onChange={(newValue) => {
                  handleMemberSelect(newValue);
                }}
                fullWidth
              />
            </Box>

            <Box
              sx={{
                height: `${
                  fullSize ? windowHeight - 225 : windowHeight - 600
                }px`,
                overflow: "auto",
                paddingTop: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <Stack spacing={2}>
                <OFDNumber
                  id="tokenBank"
                  label="tokens"
                  value={getTokenValue("tokenBank") || 0}
                  onChange={(newValue) => setTokenValue("tokenBank", newValue)}
                />

                <OFDTextField
                  id="tokenObjective.name"
                  label="tokenObjectiveName"
                  value={getTokenValue("tokenObjective.name") || 0}
                  onChange={(newValue) =>
                    setTokenValue("tokenObjective.name", newValue)
                  }
                />

                <OFDTextNote
                  id="tokenObjective.note"
                  label="note"
                  value={getTokenValue("tokenObjective.note") || 0}
                  onChange={(newValue) =>
                    setTokenValue("tokenObjective.note", newValue)
                  }
                />

                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: ".5rem",
                  }}
                >
                  <OFDPicture
                    id="tokenObjective.image"
                    value={getTokenValue("tokenObjective.image") || null}
                    onChange={(newValue) =>
                      setTokenValue("tokenObjective.image", newValue)
                    }
                  />
                </Box>

                <OFDNumber
                  id="tokens"
                  label="tokensNeeded"
                  value={getTokenValue("tokenObjective.tokens") || 0}
                  onChange={(newValue) =>
                    setTokenValue("tokenObjective.tokens", newValue)
                  }
                />
              </Stack>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
              gap={1}
            >
              <OFDButton
                label="clear"
                variant="outlined"
                onClick={confirmClear}
              />

              <OFDButton
                label={saveLabel()}
                onClick={handleSave}
                variant="contained"
              />
            </Box>
          </Box>
        ) : (
          <Typography>{getText("tokenBank_noMembers")}</Typography>
        )}
      </Box>

      <OFDMessage message={memberMessage} />

      <OFDDialog
        open={showDialog}
        title="confirmClearTokens"
        description="confirmClearTokens"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={clearConfirmation}
      />
    </>
  );
};

export default TokenBanks;
