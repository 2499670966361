import { useEffect, useState } from "react";
import { getDay, format, startOfDay } from "date-fns";

import useLocalization from "../../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import MemberWidget from "./MemberWidget";
import ToDos from "./ToDos";

const MemberToDo = ({ memberToDo, height, onUpdated }) => {
  const { calendarLists } = useLocalization();

  const [todos, setToDos] = useState(undefined);

  useEffect(() => {
    sortToDos();
  }, [memberToDo]);

  const sortToDos = () => {
    if (!memberToDo || !Array.isArray(memberToDo.items)) {
      return;
    }

    let list = [
      { dow: 0, date: null, items: [] },
      { dow: 1, date: null, items: [] },
      { dow: 2, date: null, items: [] },
      { dow: 3, date: null, items: [] },
      { dow: 4, date: null, items: [] },
      { dow: 5, date: null, items: [] },
      { dow: 6, date: null, items: [] },
    ];

    for (const item of memberToDo.items) {
      let dowIndex = list.findIndex(
        (dowItem) => dowItem.dow == getDay(item.startDate)
      );

      let dow = list[dowIndex];
      if (dow.date === null) {
        dow.date = startOfDay(item.startDate);
      }
      dow.items.push({ ...item });
      list[dowIndex] = dow;
    }

    setToDos(list);
  };

  if (todos === undefined) return;

  return (
    <MemberWidget
      memberId={memberToDo.memberId}
      height={height}
    >
      <Box sx={{ padding: ".5rem" }}>
        {todos.map((todo) => {
          if (todo.items.length === 0) return;
          return (
            <Box
              key={todo.id}
              sx={{ marginBottom: ".5rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="overline"
                  sx={{ fontWeight: 900 }}
                >
                  {calendarLists.weekdays[todo.dow].label}
                </Typography>

                <Typography
                  variant="overline"
                  sx={{ fontWeight: 900 }}
                >
                  {format(todo.date, "MMM dd")}
                </Typography>
              </Box>

              <ToDos
                todos={todo.items}
                onUpdated={onUpdated}
              />
            </Box>
          );
        })}
      </Box>
    </MemberWidget>
  );
};

export default MemberToDo;
