import { useEffect, useState } from "react";
import { format, parse } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { systemActions } from "../store/system";
import { addDays, addHours, isBefore } from "date-fns";

const useSystem = () => {
  const dispatch = useDispatch();

  const localVersion = useSelector((state) => state.system.localVersion);
  const currentVersion = useSelector((state) => state.system.currentVersion);

  const setCurrentVersion = (value) => {
    if (localVersion === value) return;

    dispatch(systemActions.setCurrentVersion({ currentVersion: value }));
    localStorage.setItem("currentVersion", value);
  };

  const resetCurrentVersion = () => {
    dispatch(systemActions.setCurrentVersion({ currentVersion: localVersion }));
  };

  const setLocalVersion = (value) => {
    dispatch(systemActions.setLocalVersion({ localVersion: value }));
    localStorage.setItem("localVersion", value);
  };

  const updateApp = () => {
    if (!localVersion) {
      setLocalVersion(currentVersion);
      return;
    }

    if (localVersion === currentVersion) return;

    setLocalVersion(currentVersion);
    window.location.reload();
  };

  const showUpdateModal = () => {
    if (!localVersion) {
      setLocalVersion(currentVersion);
      return false;
    }

    if (localVersion === currentVersion) return false;

    return true;
  };

  return {
    localVersion,
    currentVersion,
    setCurrentVersion,
    setLocalVersion,
    resetCurrentVersion,
    updateApp,
    showUpdateModal,
  };
};

export default useSystem;
