import React from "react";

import useWindow from "../../../hooks/useWindow";

import { Box } from "@mui/material";

const IntroVideo = () => {
  const { isMobile, windowHeight } = useWindow();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <video
        style={{
          maxWidth: "100%",
          height: `${isMobile ? windowHeight - 120 : 500}px`,
        }}
        autoPlay
        controls
      >
        <source
          src="https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/GetStarted.mp4"
          type="video/mp4"
        />
      </video>
    </Box>
  );
};

export default IntroVideo;
