import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

const OFDMonthSelector = ({
  id,
  label,
  value,
  onChange,
  disabled,
  error,
  message,
}) => {
  const { calendarLists, getMessage } = useLocalization();
  const { getColor } = useColor();

  const handleMonthSelection = (month) => {
    if (disabled) return;

    let newValue = value
      ? [...value]
      : [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ];

    newValue[month] = !newValue[month];

    onChange(newValue);
  };

  const isSelected = (month) => {
    if (!value) return false;

    return value[month];
  };

  return (
    <Box id={id}>
      <Grid container>
        {calendarLists.months.map((month) => (
          <Grid
            key={`month-${month.value}`}
            mobile={3}
          >
            <Box
              sx={{
                padding: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...getColor({
                  color: "grey",
                  shade: isSelected(month.value) ? 900 : 50,
                }),
              }}
              onClick={() => handleMonthSelection(month.value)}
            >
              <Typography>{month.short}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {message ? (
        <Box sx={{ paddingLeft: "1rem" }}>
          <Typography
            sx={{ fontSize: "10px", color: error ? "#ff0000" : "#000" }}
          >
            {getMessage(message)}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDMonthSelector;
