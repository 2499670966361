import React, { useEffect, useState, useRef } from "react";
import ReactCrop, { PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useImage from "../../hooks/useImage";

// Material UI
import { Box, Button, Typography } from "@mui/material";

// Assets
import OFDAvatar from "./OFDAvatar";

import OFDModal from "../layout/OFDModal";
import OFDToolbar from "../layout/OFDToolbar";
import OFDFormControl from "./OFDFormControl";
import LoadingModal from "../layout/LoadingModal";

const fileTypes = {
  images: {
    "image/png": [".png"],
    "image/jpeg": [".jpeg", ".jpg"],
  },
};

const TO_RADIANS = Math.PI / 180;

const OFDAvatarUpload = ({ id, name, label, value, onChange }) => {
  const { labels, titles, getText } = useLocalization();
  const { memberMode } = useMember();
  const { compressImage, compressedImage } = useImage();

  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 200,
    height: 200,
  });
  const [completedCrop, setCompletedCrop] = useState(false);
  const [image, setImage] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [source, setSource] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [base64, setBase64] = useState(null);

  const cameraRef = useRef(null);
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const galleryRef = useRef(null);

  useEffect(() => {
    if (!value) {
      setAvatar(null);
      return;
    }

    setAvatar(value);
  }, [value]);

  useEffect(() => {
    if (!open) return;

    setImage(null);
    setSource(null);
  }, [open]);

  const capturePicture = () => {
    setSource("capture");
    let camera = cameraRef.current;

    setImage(window.URL.createObjectURL(camera.files[0]));
  };

  const selectGallery = async () => {
    setOpenLoading(true);
    setSource("gallery");
    let gallery = galleryRef.current;

    await compressImage(gallery.files[0]);

    // setImage(window.URL.createObjectURL(gallery.files[0]));
  };

  useEffect(() => {
    if (!compressedImage) return;

    setImage(window.URL.createObjectURL(compressedImage));

    setOpenLoading(false);
  }, [compressedImage]);

  const handleCrop = async () => {
    setAvatar(file);
    onChange(file);

    setOpen(false);
  };

  const handleCropComplete = async () => {
    const canvas = canvasRef.current;
    const img = imageRef.current;
    const ctx = canvas.getContext("2d");

    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;

    canvas.width = crop.width;
    canvas.height = crop.height;

    // const pixelRatio = window.devicePixelRatio;

    // canvas.width = crop.width * pixelRatio;
    // canvas.height = crop.height * pixelRatio;

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // canvas.toBlob(async (blob) => {
    //   await compressImage(blob);
    // });
    setFileToBase64();
  };

  // useEffect(() => {
  //   if (!compressedImage) return;

  //   setFileToBase64();
  // }, [compressedImage]);

  const setFileToBase64 = async () => {
    let camera = cameraRef.current;
    let gallery = galleryRef.current;
    let sourceFile = null;

    if (source === "capture") {
      if (!camera || !camera.files || camera.files.length === 0) {
        return;
      }
      sourceFile = camera.files[0];
    } else if (source === "gallery") {
      if (!gallery || !gallery.files || gallery.files.length === 0) {
        return;
      }
      sourceFile = gallery.files[0];
    }

    if (!sourceFile) return;

    setBase64(canvasRef.current.toDataURL());

    // let newFile = new File([canvasRef.current], sourceFile.name, {
    //   type: sourceFile.type,
    // });

    // await convertToBase64(newFile);
  };

  useEffect(() => {
    setFile(base64);

    setCompletedCrop(true);
  }, [base64]);

  const handleTakePicture = () => {
    cameraRef.current.click();
  };

  const handleImageGallery = () => {
    galleryRef.current.click();
  };

  const handleMenuClick = (action) => {
    switch (action) {
      case "camera":
        handleTakePicture();
        break;

      case "gallery":
        handleImageGallery();
        break;

      case "crop":
        handleCrop();
        break;
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setImage(null);
    setSource(null);
    // setCompletedCrop(false);
  };

  return (
    <>
      <OFDFormControl
        id={id}
        label={label}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Box sx={{ marginRight: "1rem" }}>
            <OFDAvatar
              avatar={avatar}
              name={name ? name : ""}
            />
          </Box>
          <Button
            sx={{ color: "#666" }}
            onClick={() => setOpen(true)}
          >
            {labels.uploadAvatar}
          </Button>
        </Box>
      </OFDFormControl>

      <OFDModal
        title={titles.uploadAvatar}
        open={open}
        onClose={handleCloseModal}
      >
        <OFDToolbar
          id="toolbar"
          menuItems={[
            // { name: "camera", label: "camera", icon: "camera" },
            { name: "gallery", label: "Picture", icon: "camera" },
            {
              name: "crop",
              label: "save",
              icon: "crop",
              hide: !image,
            },
          ]}
          onClick={handleMenuClick}
        />

        <Box sx={{ padding: "1rem" }}>
          <Box sx={{ marginBottom: "1rem" }}>
            <input
              style={{ display: "none" }}
              ref={cameraRef}
              type="file"
              name="image"
              accept="image/*"
              capture="user"
              onChange={capturePicture}
            />

            <input
              style={{ display: "none" }}
              ref={galleryRef}
              type="file"
              name="gallery"
              accept="image/*"
              onChange={selectGallery}
            />
          </Box>

          <ReactCrop
            aspect={1}
            height={200}
            width={200}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={handleCropComplete}
          >
            <img
              ref={imageRef}
              src={image}
              width="100%"
              height="auto"
              loading="lazy"
            />
          </ReactCrop>

          {image ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {getText("avatarInstructions")}
            </Typography>
          ) : null}

          <canvas
            ref={canvasRef}
            style={{ display: "none" }}
          ></canvas>
        </Box>
      </OFDModal>

      <LoadingModal open={openLoading} />
    </>
  );
};

export default OFDAvatarUpload;
