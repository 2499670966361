import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";
import PictureDisplay from "../display/PictureDisplay";

const OFDDisplayPicture = ({
  label,
  value,
  styleoverride,
  onClick,
  imageDimensions,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const [viewPicture, setViewPicture] = useState(false);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box onClick={handleClick}>
              <Typography variant="overline">{getLabel(label)}</Typography>
            </Box>
            <OFDIcon
              name="openPage"
              onClick={() => setViewPicture(true)}
            />
          </Box>

          {value ? (
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              onClick={handleClick}
            >
              <img
                alt="picture"
                src={value}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "auto",
                  ...imageDimensions,
                }}
              />
            </Box>
          ) : (
            <Typography>-</Typography>
          )}
        </Box>
      </Box>

      <PictureDisplay
        src={value}
        open={viewPicture}
        onClose={() => setViewPicture(false)}
      />
    </>
  );
};

export default OFDDisplayPicture;
