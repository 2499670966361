import { useEffect, useState } from "react";

import useLocalization from "../../../hooks/useLocalization";
import useBudget from "../../../hooks/useBudget";

import { Box, Stack, Typography } from "@mui/material";

import OFDYear from "../../ui/OFDYear";
import OFDSelect from "../../ui/OFDSelect";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const BudgetCopy = ({ year, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();
  const { getBudgets, budgets } = useBudget();

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedBudget, setSelectedBudget] = useState("none");
  const [selectedTemplate, setSelectedTemplate] = useState("none");

  useEffect(() => {
    setSelectedYear(year - 1);
  }, []);

  useEffect(() => {
    if (!selectedYear) return;

    getData();
  }, [selectedYear]);

  const getData = async () => {
    await getBudgets(selectedYear);
  };

  const budgetOptions = () => {
    let options = [{ value: "none", label: getLabel("skip") }];

    if (Array.isArray(budgets) && budgets.length > 0) {
      for (const budget of budgets) {
        options.push({ value: budget.id, label: budget.name });
      }
    }

    return options;
  };

  const templateOptions = () => {
    let options = [
      { value: "none", label: getLabel("skip") },
      { value: "simpleBudget", label: getLabel("simpleBudget") },
      { value: "detailedBudget", label: getLabel("detailedBudget") },
    ];

    return options;
  };

  useEffect(() => {
    if (selectedBudget === "none" && selectedTemplate === "none") {
      onChange("scratch", null);
    } else if (selectedBudget !== "none") {
      onChange("copy", selectedBudget);
    } else if (selectedTemplate !== "none") {
      onChange("template", selectedTemplate);
    }
  }, [selectedBudget, selectedTemplate]);

  return (
    <Box>
      <Typography variant="h5">{getWizard("budget").copy.title}</Typography>

      <Stack spacing={2}>
        {getWizard("budget").copy.instructions.map((instruction, index) => (
          <Typography key={`instruction-${index}`}>{instruction}</Typography>
        ))}
      </Stack>

      <OFDFieldContainer sx={{ marginTop: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <OFDYear
            id="year"
            label="year"
            value={selectedYear}
            onChange={(newValue) => setSelectedYear(newValue)}
          />
        </Box>

        <Box>
          <OFDSelect
            id="budgets"
            label="budgets"
            options={budgetOptions()}
            value={selectedBudget}
            onChange={(newValue) => setSelectedBudget(newValue)}
            fullWidth
          />
        </Box>
      </OFDFieldContainer>

      <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Typography
          align="center"
          variant="h5"
        >
          {getLabel("or")}
        </Typography>
      </Box>

      <Typography variant="h5">{getWizard("budget").template.title}</Typography>

      <Stack spacing={2}>
        {getWizard("budget").template.instructions.map((instruction, index) => (
          <Typography key={`instruction-${index}`}>{instruction}</Typography>
        ))}
      </Stack>

      <OFDFieldContainer sx={{ marginTop: "1rem" }}>
        <OFDSelect
          id="templates"
          label="templates"
          options={templateOptions()}
          value={selectedTemplate}
          onChange={(newValue) => setSelectedTemplate(newValue)}
          fullWidth
        />
      </OFDFieldContainer>
    </Box>
  );
};

export default BudgetCopy;
