import { useEffect, useState, useRef } from "react";
import { v4 as uuidV4 } from "uuid";

import useEntity from "../../../hooks/useEntity";
import useLocalization from "../../../hooks/useLocalization";
import useColor from "../../../hooks/useColor";
import useWindow from "../../../hooks/useWindow";

import {
  Box,
  Typography,
  Collapse,
  Dialog,
  DialogTitle,
  Stack,
  Divider,
  Button,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import FieldIcon from "./FieldIcon";
import OFDIcon from "../../ui/OFDIcon";
import OFDTextField from "../../ui/OFDTextField";
import OFDFieldGroup from "../../ui/OFDFieldGroup";
import OFDSortableList from "../../ui/OFDSortableList";
import OFDDialog from "../../layout/OFDDialog";
import OFDFieldSecurity from "../../ui/OFDFieldSecurity";

const defaultFieldSecurity = {
  view: { securityLevel: "family" },
  update: { securityLevel: "addedBy" },
  delete: { securityLevel: "addedBy" },
};

const AvailableFields = ({
  entityName,
  open,
  isNewFolder,
  onChange,
  fields,
}) => {
  const { availableFields } = useEntity(entityName);
  const { getTitle, getLabel, getWizard } = useLocalization();
  const { getColor } = useColor();
  const { windowHeight } = useWindow();

  const [showFields, setShowFields] = useState(open);
  const [workFields, setWorkFields] = useState(fields ? fields : []);

  const [showFieldDialog, setShowFieldDialog] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [fieldLabel, setFieldLabel] = useState("");
  const [fieldSecurity, setFieldSecurity] = useState({
    ...defaultFieldSecurity,
  });
  const [deleteFieldId, setDeleteFieldId] = useState(null);

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [fieldMode, setFieldMode] = useState("add");

  const [fieldsHeight, setFieldsHeight] = useState("auto");
  const [availableFieldsHeight, setAvailableFieldsHeight] = useState(284);
  const [containerHeight, setContainerHeight] = useState(0);

  const parentContainer = useRef();
  const availableFieldsRef = useRef();

  useEffect(() => {
    if (!parentContainer.current) return;
    setAvailableFieldsHeight(availableFieldsRef.current.offsetHeight);
    setContainerHeight(parentContainer.current.clientHeight - 150);
  }, [parentContainer?.current?.clientHeight]);

  useEffect(() => {
    onChange(workFields);
  }, [workFields]);

  useEffect(() => {
    if (containerHeight === 0) return;

    let height = containerHeight;
    if (showFields) {
      height -= availableFieldsHeight;
    }

    setFieldsHeight(`${height}px`);
  }, [showFields, containerHeight]);

  const handleFieldSelection = (fieldType) => {
    // setShowFields(false);
    let availableField = availableFields.find((f) => f.fieldType === fieldType);
    setSelectedField(availableField);
    setFieldLabel(getLabel(availableField.label));
    setFieldMode("add");
    setShowFieldDialog(true);
  };

  const addField = (field) => {
    let newField = { ...field };
    newField.id = uuidV4();
    newField.label = getFieldLabel(field);
    newField.security = { ...fieldSecurity };

    setWorkFields((current) => {
      return [...current, { ...newField }];
    });

    setFieldLabel("");
    setFieldSecurity({ ...defaultFieldSecurity });
  };

  const updateField = (field) => {
    setWorkFields((current) => {
      let newFields = [];

      for (const currentField of current) {
        if (currentField.id !== field.id) {
          newFields.push({ ...currentField });
          continue;
        }

        newFields.push({
          ...field,
          label: fieldLabel,
          security: fieldSecurity,
        });
      }

      return [...newFields];
    });
  };

  const getFieldLabel = (field) => {
    let label;
    if (field.editLabel && fieldLabel) {
      label = fieldLabel;
    } else {
      label = getLabel(field.label);
    }
    return label;
  };

  const fieldDisabled = (fieldType) => {
    let availableField = availableFields.find((f) => f.fieldType === fieldType);
    if (!availableField) return true;
    if (availableField.multiple) return false;

    let field = workFields.find((f) => f.fieldType === fieldType);
    if (field) return true;
  };

  const handleResetField = () => {
    setFieldLabel("");
    setFieldSecurity({ ...defaultFieldSecurity });
  };

  const handleSaveField = () => {
    handleResetField();
    setShowFieldDialog(false);
    if (fieldMode === "add") {
      addField(selectedField);
    } else {
      updateField(selectedField);
    }
  };

  const handleCancelField = () => {
    setShowFieldDialog(false);
    setFieldLabel("");
    setFieldSecurity({ ...defaultFieldSecurity });
  };

  const handleSortedFields = (sortedFields) => {
    setWorkFields(sortedFields);
  };

  const handleRemoveField = (fieldId) => {
    if (isNewFolder) {
      removeField(fieldId);
    } else {
      setDeleteFieldId(fieldId);
      setOpenRemoveDialog(true);
    }
  };

  const handleRemoveConfirmation = (response) => {
    if (response === "yes") {
      removeField(deleteFieldId);
    } else {
      setDeleteFieldId(null);
    }

    setOpenRemoveDialog(false);
  };

  const removeField = (fieldId) => {
    const fieldIndex = workFields.findIndex((field) => field.id === fieldId);
    setWorkFields((current) => {
      let currentFields = [...current];
      currentFields.splice(fieldIndex, 1);

      return currentFields;
    });

    setDeleteFieldId(null);
  };

  const handleEditField = (fieldId) => {
    const editField = fields.find((field) => field.id === fieldId);
    if (!editField) return;

    setFieldMode("update");
    setSelectedField(editField);
    setFieldLabel(editField.label);
    setFieldSecurity(editField.security);
    setShowFieldDialog(true);
  };

  return (
    <>
      <Box
        ref={parentContainer}
        sx={{ height: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "1rem",
            marginRight: "1rem",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Typography variant="h5">{getTitle("availableFields")}</Typography>
          <Box>
            <OFDIcon
              name={showFields ? "arrowUp" : "arrowDown"}
              size="medium"
              color="grey"
              shade={800}
              onClick={() => setShowFields((current) => !current)}
            />
          </Box>
        </Box>

        <Collapse
          ref={availableFieldsRef}
          in={showFields}
          timeout={500}
        >
          <Box
            sx={{
              marginTop: "1rem",
              flexGrow: 1,
            }}
          >
            <Grid
              container
              rowSpacing={2}
            >
              {availableFields?.map((field) => (
                <Grid
                  key={field.id}
                  mobile={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Box>
                    <FieldIcon
                      {...field}
                      onClick={handleFieldSelection}
                      disabled={fieldDisabled(field.fieldType)}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Collapse>

        <Divider sx={{ marginTop: "1rem", marginBotton: "1rem" }}>
          {getLabel("fieldsInFolder")}
        </Divider>

        <Box
          sx={{
            height: fieldsHeight,
            overflow: "auto",
          }}
        >
          <OFDSortableList
            list={workFields}
            onChange={handleSortedFields}
            onRemove={handleRemoveField}
            onEdit={handleEditField}
          />
        </Box>
      </Box>

      {selectedField ? (
        <Dialog
          open={showFieldDialog}
          onClose={() => setShowFieldDialog(false)}
        >
          <DialogTitle>{getLabel(selectedField.label)}</DialogTitle>
          <Box
            sx={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Typography variant="subtitle2">
                  {getWizard("folder").availableFields.fieldLabel}
                </Typography>

                <OFDTextField
                  id="label"
                  label="label"
                  value={fieldLabel}
                  onChange={(newValue) => setFieldLabel(newValue)}
                />

                <Divider />

                <Typography variant="subtitle2">
                  {getWizard("folder").availableFields.fieldSecurity}
                </Typography>

                <OFDFieldSecurity
                  id="fieldSecurity"
                  label="fieldSecurity"
                  value={fieldSecurity}
                  onChange={(newValue) => setFieldSecurity(newValue)}
                />
              </Stack>
            </Stack>

            <Box sx={{ marginTop: "1rem" }}>
              <OFDFieldGroup justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={handleCancelField}
                >
                  {getLabel("cancel")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleResetField}
                >
                  {getLabel("reset")}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSaveField}
                >
                  {getLabel("save")}
                </Button>
              </OFDFieldGroup>
            </Box>
          </Box>
        </Dialog>
      ) : null}

      <OFDDialog
        open={openRemoveDialog}
        title="removeField"
        description="removeField"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleRemoveConfirmation}
      />
    </>
  );
};

export default AvailableFields;
