import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useLocalization from "../../../../hooks/useLocalization";
import useSecurity from "../../../../hooks/useSecurity";
import useMessage from "../../../../hooks/useMessage";
import usePage from "../../../../hooks/usePage";

import { Box, Button, Stack, Typography } from "@mui/material";

import FamilyContentSlider from "../../../layout/FamilySliderLayout";
import OFDTextField from "../../../ui/OFDTextField";
import OFDPasswordField from "../../../ui/OFDPasswordField";
import OFDMessage from "../../../ui/OFDMessage";

const ResetPassword = () => {
  const { titles, labels, text, getText, getLabel, getMessage } =
    useLocalization(process.env.REACT_APP_LOCALE);
  const { destructureResults } = useMessage();
  const { forgotPassword, verifyCode, changePassword } = useSecurity();
  const navigate = useNavigate();
  const { setPage } = usePage();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [message, setMessage] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [codeConfirmed, setCodeConfimed] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    setPage("forgotPassword");
  }, []);

  const handleForgotPassword = async () => {
    setMessage(null);
    const results = await forgotPassword(username);
    setMessage(destructureResults(results));

    if (results.isSuccessful) {
      setCodeSent(true);
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleVerify = async () => {
    const results = await verifyCode(username, confirmationCode);
    setMessage(destructureResults(results));

    if (results.isSuccessful) {
      setCodeConfimed(true);
    }
  };

  useEffect(() => {
    setPasswordError(false);
    setPasswordMessage("");
  }, [password]);

  const handleChangePassword = async () => {
    if (!password || password.length < 8) {
      setPasswordError(true);
      setPasswordMessage(getMessage("passwordLength"));
      return;
    }

    const results = await changePassword(username, confirmationCode, password);
    setMessage(destructureResults(results));
    if (results.isSuccessful) {
      setPasswordReset(true);
    }
  };

  return (
    <>
      <FamilyContentSlider>
        <Box>
          <Typography
            variant="h4"
            align="center"
          >
            {titles.forgotPassword}
          </Typography>

          <Stack
            marginTop="1rem"
            width="100%"
            spacing={2}
          >
            {!passwordReset ? (
              <Stack spacing={2}>
                <Typography>{getText("forgotPassword")}</Typography>

                <OFDTextField
                  id="username"
                  label={labels.username}
                  value={username}
                  onChange={(newValue) => setUsername(newValue)}
                  fullWidth
                />

                <Button
                  variant="contained"
                  onClick={handleForgotPassword}
                >
                  {getLabel("sendCode")}
                </Button>

                {codeSent ? (
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={1}
                  >
                    <Box>
                      <OFDTextField
                        id="confirmationCode"
                        label="confirmationCode"
                        value={confirmationCode}
                        onChange={(newValue) => setConfirmationCode(newValue)}
                      />
                    </Box>
                    <Box>
                      {!codeConfirmed ? (
                        <Button
                          variant="contained"
                          onClick={handleVerify}
                        >
                          {getLabel("verify")}
                        </Button>
                      ) : (
                        <Typography>{getLabel("verified")}</Typography>
                      )}
                    </Box>
                  </Box>
                ) : null}

                {codeConfirmed ? (
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={1}
                  >
                    <OFDPasswordField
                      id="password"
                      label={getLabel("newPassword")}
                      value={password}
                      onChange={(newValue) => setPassword(newValue)}
                    />

                    <Button
                      variant="contained"
                      onClick={handleChangePassword}
                    >
                      {getLabel("reset")}
                    </Button>
                  </Box>
                ) : null}
              </Stack>
            ) : (
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                gap={2}
              >
                <Typography align="center">
                  {getMessage("passwordReset")}
                </Typography>
              </Box>
            )}

            <Button
              variant="contained"
              onClick={handleLogin}
            >
              {getLabel("login")}
            </Button>
          </Stack>
        </Box>
      </FamilyContentSlider>

      <OFDMessage
        message={message}
        onClose={() => setMessage(null)}
      />
    </>
  );
};

export default ResetPassword;
