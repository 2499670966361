import { format, isDate } from "date-fns";

import { Box } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import OFDFormControl from "../ui/OFDFormControl";

const OFDDateEditor = ({ id, label, value, onChange }) => {
  return (
    <>
      <Box>
        <OFDFormControl
          id={id}
          label={label}
        >
          {isDate(value) ? format(value, "EEE, MMM d, yyyy") : ""}
        </OFDFormControl>

        <Box>
          <DateCalendar
            value={value}
            onChange={onChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default OFDDateEditor;
