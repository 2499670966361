import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useEntity from "../../hooks/useEntity";

import { Box, Typography } from "@mui/material";

import OFDCollapsable from "../layout/OFDCollapsable";
import OFDDisplayText from "../ui/OFDDisplayText";
import OFDDisplayNote from "../ui/OFDDisplayNote";
import OFDDisplayBudgetItem from "../ui/OFDDisplayBudgetItem";
import OFDDisplayReceipts from "../ui/OFDDisplayReceipts";

import ExpenseEditor from "../editors/ExpenseEditor";

const ExpenseDisplay = ({ expense, onChange }) => {
  const { displayLocalCurrency } = useLocalization();
  const { sourceEntity, getSourceEntity } = useEntity();

  const [openEditor, setOpenEditor] = useState(false);

  useEffect(() => {
    getEntity();
  }, [expense]);

  const getEntity = async () => {
    if (!expense.source) return;

    await getSourceEntity(expense.source);
  };

  const handleMenuItemClick = (action) => {
    if (action === "edit") {
      setOpenEditor(true);
    }
  };

  const handleSave = () => {
    setOpenEditor(false);
    onChange();
  };

  return (
    <>
      <OFDCollapsable
        header={
          <Box>
            <Typography sx={{ fontSize: "1.1rem" }}>{`${
              expense.name
            } : ${displayLocalCurrency(expense.expenseAmount)}`}</Typography>

            <Typography variant="caption">{`${format(
              expense.expenseDate,
              "MMM dd, yyyy"
            )}`}</Typography>
          </Box>
        }
        menuItems={[{ value: "edit", label: "edit", icon: "edit" }]}
        onMenuClick={handleMenuItemClick}
        onSave={onChange}
      >
        <Box sx={{ padding: ".5rem" }}>
          {sourceEntity ? (
            <Box
              sx={{
                borderBottom: "1px solid #ccc",
                marginBottom: ".5rem",
                paddingBottom: ".5rem",
              }}
            >
              <Typography
                align="center"
                variant="h6"
              >
                {expense.source.model}
              </Typography>
              <Typography
                align="center"
                sx={{ fontWeight: 500 }}
              >
                {sourceEntity.name}
              </Typography>
            </Box>
          ) : null}

          <OFDDisplayBudgetItem
            id="budgetItem"
            label="budgetItem"
            value={expense.budgetItem}
          />

          {expense.company ? (
            <OFDDisplayText
              id="company"
              label="company"
              value={expense.company}
            />
          ) : null}

          {expense.description ? (
            <OFDDisplayNote
              id="description"
              label="description"
              value={expense.description}
            />
          ) : null}

          {expense.receipts ? (
            <OFDDisplayReceipts
              id="receipts"
              label="receipts"
              value={expense.receipts}
            />
          ) : null}
        </Box>
      </OFDCollapsable>

      {openEditor ? (
        <ExpenseEditor
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          expense={expense}
          onSave={handleSave}
          expenseCalendar
          isIncome={expense.budgetItem.budgetType === "income"}
        />
      ) : null}
    </>
  );
};

export default ExpenseDisplay;
