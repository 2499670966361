import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDSecurity from "../ui/OFDSecurity";
import OFDSwitch from "../ui/OFDSwitch";
import OFDIcon from "../ui/OFDIcon";
import OFDTitle from "../layout/OFDTitle";
import OFDToolbar from "../layout/OFDToolbar";
import OFDFieldContainer from "../ui/OFDFieldContainer";

import Pages from "../../configuration/Pages";

const ModulePreferences = ({ module, open, onClose, onChange }) => {
  const { getLabel, getText } = useLocalization();

  const [updatedModule, setUpdatedModule] = useState();
  const [pageConfig, setPageConfig] = useState(null);

  useEffect(() => {
    if (!module) return;

    let page = Pages.find((entry) => entry.name === module.name);
    if (page) {
      setPageConfig(page);
    } else {
      setPageConfig({
        name: module.name,
        defaultSecurity: {
          view: { securityLevel: "family", members: [] },
          add: { securityLevel: "admin", members: [] },
          update: { securityLevel: "admin", members: [] },
          delete: { securityLevel: "admin", members: [] },
        },
      });
    }

    setUpdatedModule(module);
  }, [module]);

  const handleToolbarClick = (action) => {
    if (action === "save") {
      onChange(updatedModule);
    }
  };

  const getValue = (field) => {
    if (field === "defaultSecurity" && !updatedModule?.defaultSecurity) {
      if (pageConfig) {
        return pageConfig.defaultSecurity;
      } else {
        return {
          view: { securityLevel: "family", members: [] },
          add: { securityLevel: "admin", members: [] },
          update: { securityLevel: "admin", members: [] },
          delete: { securityLevel: "admin", members: [] },
        };
      }
    }

    if (!updatedModule) return null;

    return updatedModule[field];
  };

  const setValue = (field, value) => {
    setUpdatedModule((current) => {
      if (!current) return { [field]: value };

      return {
        ...current,
        [field]: value,
      };
    });
  };

  if (!module) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
      >
        <OFDToolbar
          menuItems={[{ name: "save", icon: "save", label: "save" }]}
          onClick={handleToolbarClick}
        />

        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
        >
          <OFDIcon
            name={module.name}
            size="xlarge"
          />
        </Box>

        <OFDTitle title={getLabel(module.name)} />

        <Stack
          spacing={2}
          sx={{ margin: "1rem" }}
        >
          <Box>
            <OFDSwitch
              id="enabled"
              label="enabled"
              value={Boolean(getValue("enabled"))}
              onChange={(newValue) => setValue("enabled", newValue)}
            />
          </Box>

          <OFDFieldContainer>
            <Typography
              sx={{ marginBottom: "1rem" }}
              align="center"
              variant="h6"
            >
              {getText("pageSecurity")}
            </Typography>
            <OFDSecurity
              id="security"
              value={getValue("security")}
              onChange={(newValue) => setValue("security", newValue)}
            />
          </OFDFieldContainer>

          <OFDFieldContainer>
            <Typography
              sx={{ marginBottom: "1rem" }}
              align="center"
              variant="h6"
            >
              {getText("defaultSecurity")}
            </Typography>
            <OFDSecurity
              id="defaultSecurity"
              value={getValue("defaultSecurity")}
              onChange={(newValue) => setValue("defaultSecurity", newValue)}
              show={{
                view: true,
                add: false,
                update: true,
                delete: true,
              }}
            />
          </OFDFieldContainer>
        </Stack>
      </OFDModal>
    </>
  );
};

export default ModulePreferences;
