import { useEffect, useState } from "react";

import { Box, Stack, Button, Typography } from "@mui/material";

import useEntity from "../../hooks/useEntity";
import useForm from "../../hooks/useForm";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useSecurity from "../../hooks/useSecurity";

import OFDModal from "../layout/OFDModal";
import OFDIcon from "../ui/OFDIcon";
import OFDField from "../ui/OFDField";
import OFDFieldGroup from "../ui/OFDFieldGroup";
import OFDMessage from "../ui/OFDMessage";
import FieldEditorModal from "./FieldEditorModal";
import OFDModalHeader from "../layout/OFDModalHeader";
import OFDToolbar from "../layout/OFDToolbar";
import OFDFieldContainer from "../ui/OFDFieldContainer";

const EntityEditor = ({ entityName, entityData, open, onClose }) => {
  const { entityConfig } = useEntity(entityName);
  const { labels, titles } = useLocalization();
  const { getMemberState } = useMember();
  const { pageDefaultSecurity } = useSecurity();
  const {
    data,
    setData,
    setFieldValue,
    getFieldValue,
    getFieldMessage,
    getFieldLabel,
    getFieldError,
    getDataById,

    saveData,
    formMessage,
    formStatus,
    resetFormMessage,
  } = useForm(entityName);

  const [selectedField, setSelectedField] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);

  const mode = "field";

  useEffect(() => {
    if (entityData) {
      setData(entityData);
    } else {
      setData({
        ...entityConfig.defaultValues,
        security: pageDefaultSecurity(entityName),
      });
    }
  }, [entityData, open]);

  const handleMenuItemClick = (option) => {
    if (option === "save") {
      handleSave();
    }
  };

  const handleSave = async () => {
    const results = await saveData();

    if (results && results.isSuccessful) {
      await getMemberState();
      onClose();
      resetFormMessage(null);
    }
  };

  const handleCancel = () => {
    setData(entityConfig.defaultValues);
    onClose();
  };

  const handleFieldClick = (field) => {
    setSelectedField(field);
    setOpenEditor(true);
  };

  const handleCancelEditor = () => {
    setSelectedField(null);
    // setData(entityConfig.defaultValues);

    setOpenEditor(false);
  };

  const handleEditorChange = (newValue) => {
    setFieldValue(selectedField.id, newValue);
    setSelectedField(null);
    // setData(entityConfig.defaultValues);

    setOpenEditor(false);
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={entityConfig.title}
      >
        <Box sx={{ position: "sticky", left: "0px", top: "0px", zIndex: 500 }}>
          <OFDToolbar
            menuItems={[{ name: "save", label: "save", icon: "save" }]}
            onClick={handleMenuItemClick}
          />
        </Box>

        <OFDFieldContainer sx={{ margin: "1rem" }}>
          <Stack spacing={2}>
            {entityConfig.fields?.map((field) =>
              !field.mode || field.mode !== "hide" ? (
                <OFDField
                  key={field.id}
                  fieldType={field.fieldType}
                  mode={field.mode ? field.mode : mode}
                  fieldProps={{
                    ...field,
                    value: getFieldValue(field.id),
                    onChange: (newValue) => setFieldValue(field.id, newValue),
                    message: getFieldMessage(field.id),
                    error: getFieldError(field.id) || false,
                    memberData: entityName === "Member" ? data : null,
                    entityName,
                  }}
                  onEdit={() => handleFieldClick(field)}
                />
              ) : null
            )}
          </Stack>
        </OFDFieldContainer>
      </OFDModal>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      {selectedField ? (
        <FieldEditorModal
          entityName={entityName}
          entityData={data}
          field={selectedField}
          open={openEditor}
          onCancel={handleCancelEditor}
          onChange={handleEditorChange}
          returnValue={true}
        />
      ) : null}
    </>
  );
};

export default EntityEditor;
