import { useEffect, useState } from "react";
import { startOfToday, getYear } from "date-fns";

import OFDIconButton from "./OFDIconButton";
import { Box, Typography } from "@mui/material";

const OFDYear = ({ id, value, label, onChange }) => {
  const handlePrevYear = () => {
    onChange(value - 1);
  };

  const handleNextYear = () => {
    onChange(value + 1);
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      gap={2}
    >
      <OFDIconButton
        name="arrowLeft"
        onClick={handlePrevYear}
      />
      <Typography variant="h6">{value}</Typography>
      <OFDIconButton
        name="arrowRight"
        onClick={handleNextYear}
      />
    </Box>
  );
};

export default OFDYear;
