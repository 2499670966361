import { useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDDisplayEmail = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}>
      <Box
        onClick={onClick}
        sx={{ width: "100%" }}
      >
        <Box
          sx={{ display: "flex", alignItem: "center" }}
          gap={1}
        >
          <Typography variant="overline">{getLabel(label)}</Typography>
          {/* {userCanUpdate(security, entryAddedBy) ? (
            <OFDIcon
              name="edit"
              size="xsmall"
              color="grey"
            />
          ) : null} */}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography noWrap>{value ? value : "-"}</Typography>
        </Box>
      </Box>

      {value ? (
        <Box>
          <a href={`mailto:${value}`}>
            <OFDIcon name="email" />
          </a>
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDDisplayEmail;
