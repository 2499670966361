import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import OFDButton from "../ui/OFDButton";
import OFDTextField from "../ui/OFDTextField";
import OFDField from "../ui/OFDField";

const OFDDialog = ({
  id,
  title,
  description,
  fields,
  actions,
  open,
  onClose,
}) => {
  const { titles, text, labels } = useLocalization();

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setFormData(null);
  }, [open]);

  const handleClose = (response) => {
    onClose(response, formData ? formData : null);
  };

  const getFieldValue = (fieldName) => {
    if (!formData) return null;
    if (!fieldName in formData) return null;
    return formData[fieldName];
  };

  const setFieldValue = (fieldName, newValue) => {
    setFormData((currentData) => {
      if (!currentData) return { [fieldName]: newValue };
      return { ...currentData, [fieldName]: newValue };
    });
  };

  return (
    <Dialog
      id={id}
      open={open}
    >
      <DialogTitle>{titles[title]}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
          {text[description]}
        </DialogContentText>
        {fields?.map((field) => {
          return (
            <OFDField
              key={field.id}
              fieldType={field.fieldType}
              mode="edit"
              fieldProps={{
                ...field.fieldProps,
                value: getFieldValue(field.id),
                onChange: (newValue) => setFieldValue(field.id, newValue),
              }}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        {actions?.map((action) => (
          <OFDButton
            key={action.id}
            iconName={action.iconName}
            id={action.id}
            label={action.label}
            onClick={() => handleClose(action.id)}
            variant="contained"
          />
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default OFDDialog;
