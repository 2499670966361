import { useEffect, useState } from "react";

import useLocalization from "../../../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../../ui/OFDIcon";

const DashboardMenuItem = ({ id, isSelected, onSelect }) => {
  const { familyDashboard } = useLocalization();
  const theme = useTheme();

  const [menuItem, setMenuItem] = useState(null);

  useEffect(() => {
    getMenuItem();
  }, [id]);

  const getMenuItem = () => {
    const item = familyDashboard.menuItems.find((i) => i.id === id);
    setMenuItem(item);
  };

  const handleSelect = () => {
    onSelect(id);
  };

  if (!menuItem) return;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={handleSelect}
    >
      <Box
        id={id}
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: isSelected ? theme.palette.primary.main : "#fff",
          padding: "4px",
          borderRadius: "8px",
          cursor: "pointer",
        }}
      >
        <OFDIcon
          name={menuItem.icon}
          color="grey"
          shade={isSelected ? 50 : 900}
          size="medium"
          onClick={handleSelect}
        />
      </Box>

      <Typography variant="caption">{menuItem.label}</Typography>
    </Box>
  );
};

export default DashboardMenuItem;
