import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography } from "@mui/material";

const OFDDisplayUserAccount = ({
  label,
  value,
  styleoverride,
  onClick,
  memberData,
  security,
  entryAddedBy,
}) => {
  const { getLabel, getListItemLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={handleClick}
    >
      <Box>
        <Typography variant="overline">{getLabel(label)}</Typography>
      </Box>
      {memberData ? (
        <Box>
          {memberData.isUser ? (
            <Stack spacing={1}>
              <Typography>{`${getLabel("username")} : ${
                memberData.username
              }`}</Typography>
              <Typography>{`${getLabel("securityLevel")} : ${getListItemLabel(
                "securityLevels",
                memberData.securityLevel
              )}`}</Typography>
            </Stack>
          ) : (
            <Box></Box>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDDisplayUserAccount;
