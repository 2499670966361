import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { authenticationActions } from "../store/authentication";
import { memberActions } from "../store/member";
import { familyActions } from "../store/family";

import useApi from "./useApi";
import useMember from "./useMember";
import useDebug from "./useDebug";
import useLocalization from "./useLocalization";
import useFamily from "./useFamily";

import Pages from "../configuration/Pages";

const useSecurity = () => {
  const dispatch = useDispatch();
  const { securityLevels } = useLocalization();

  const token = localStorage.getItem("token");
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const { postData } = useApi();
  const { member, getMemberState } = useMember();
  const { family } = useFamily();
  const { consoleLog } = useDebug(false);

  const [isReady, setIsReady] = useState(false);

  const [defaultSecurity, setDefaultSecurity] = useState({
    view: { securityLevel: "family", members: [] },
    add: { securityLevel: "admin", members: [] },
    update: { securityLevel: "admin", members: [] },
    delete: { securityLevel: "admin", members: [] },
  });

  useEffect(() => {
    if (member.hasOwnProperty("id")) {
      setIsReady(true);
    }
  }, [member]);

  const login = async (username, password) => {
    const results = await postData({
      entityName: "Member",
      action: "login",
      data: {
        username,
        password,
      },
    });

    if (results.isSuccessful) {
      dispatch(authenticationActions.login({ token: results.data.token }));
      dispatch(memberActions.setMemberState({ data: results.data.member }));
      dispatch(familyActions.setFamilyState({ data: results.data.family }));
    } else {
      dispatch(authenticationActions.logout());
    }

    return results;
  };

  const logout = async () => {
    const results = await postData({
      entityName: "Member",
      action: "logout",
      data: {},
    });

    dispatch(memberActions.setMemberState({ data: null }));
    dispatch(familyActions.setFamilyState({ data: null }));
    dispatch(authenticationActions.logout());
  };

  const userIsCreator = (addedBy) => {
    if (!Boolean(addedBy)) return true;
    return addedBy === member.id;
  };

  const isAvailable = (securityLevel, addedBy) => {
    const memberSecurity = securityLevels[member.securityLevel];
    const itemSecurity = securityLevels[securityLevel];

    if (!memberSecurity || !itemSecurity) return false;

    if (securityLevel === "addedBy") {
      if (!addedBy) return true;

      if (member.id === addedBy) {
        return true;
      } else {
        return false;
      }
    }

    if (memberSecurity.level >= itemSecurity.level) return true;

    return false;
  };

  const userCanView = (security, addedBy) => {
    if (!security || !security.view) return true;
    if (typeof security.view === "string") return true;

    if (member.id === addedBy) {
      return true;
    }

    consoleLog("useSecurity", "userCanView", { security, member });
    const memberSecurity = securityLevels[member.securityLevel];
    const itemSecurity = securityLevels[security.view.securityLevel];

    if (!memberSecurity || !itemSecurity) return false;

    if (security.view.securityLevel === "members") {
      if (security.view.members.includes(member.id)) {
        return true;
      } else {
        return false;
      }
    }

    if (security.view.securityLevel === "addedBy") {
      if (!addedBy) return true;

      if (member.id === addedBy) {
        return true;
      } else {
        return false;
      }
    }

    if (memberSecurity.level >= itemSecurity.level) return true;

    return false;
  };

  const userCanUpdate = (security, addedBy) => {
    if (!security || !security.update) return true;
    if (typeof security.update === "string") return true;

    if (member.id === addedBy) {
      return true;
    }

    const memberSecurity = securityLevels[member.securityLevel];
    const itemSecurity = securityLevels[security.update.securityLevel];

    if (!memberSecurity || !itemSecurity) return false;

    if (security.update.securityLevel === "members") {
      if (security.update.members.includes(member.id)) {
        return true;
      } else {
        return false;
      }
    }

    if (security.update.securityLevel === "addedBy") {
      if (!addedBy) return true;

      if (member.id === addedBy) {
        return true;
      } else {
        return false;
      }
    }

    if (memberSecurity.level >= itemSecurity.level) return true;

    return false;
  };

  const userCanDelete = (security, addedBy) => {
    if (!security || !security.delete) return true;
    if (typeof security.delete === "string") return true;

    if (member.id === addedBy) {
      return true;
    }

    const memberSecurity = securityLevels[member.securityLevel];
    const itemSecurity = securityLevels[security.delete.securityLevel];

    if (!memberSecurity || !itemSecurity) return false;

    if (security.delete.securityLevel === "members") {
      if (security.delete.members.includes(member.id)) {
        return true;
      } else {
        return false;
      }
    }

    if (security.delete.securityLevel === "addedBy") {
      if (!addedBy) return true;

      if (member.id === addedBy) {
        return true;
      } else {
        return false;
      }
    }

    if (memberSecurity.level >= itemSecurity.level) return true;

    return false;
  };

  const userCanAdd = (security, addedBy) => {
    if (!security.add) return false;

    const memberSecurity = securityLevels[member.securityLevel];
    const itemSecurity = securityLevels[security.add.securityLevel];

    if (!memberSecurity || !itemSecurity) return false;

    if (security.add.securityLevel === "members") {
      if (security.add.members.includes(member.id)) {
        return true;
      } else {
        return false;
      }
    }

    if (security.add.securityLevel === "addedBy") {
      if (!addedBy) return true;

      if (member.id === addedBy) {
        return true;
      } else {
        return false;
      }
    }

    if (memberSecurity.level >= itemSecurity.level) return true;

    return false;
  };

  const userCan = (action, security, addedBy) => {
    if (action === "view") return userCanView(security, addedBy);
    if (action === "add") return userCanAdd(security, addedBy);
    if (action === "update") return userCanUpdate(security, addedBy);
    if (action === "delete") return userCanDelete(security, addedBy);
  };

  const securityLevelOptions = () => {
    let options = [];

    const levels = Object.keys(securityLevels);
    for (const level of levels) {
      if (securityLevels[level].showUser) {
        options.push({
          value: level,
          label: securityLevels[level].label,
        });
      }
    }

    return options;
  };

  const forgotPassword = async (username) => {
    const results = await postData({
      entityName: "Member",
      action: "forgotPassword",
      data: {
        username,
      },
    });

    return results;
  };

  const verifyCode = async (username, confirmationCode) => {
    const results = await postData({
      entityName: "Member",
      action: "confirmPasswordCode",
      data: {
        username,
        confirmationCode,
      },
    });

    return results;
  };

  const changePassword = async (username, confirmationCode, newPassword) => {
    const results = await postData({
      entityName: "Member",
      action: "changePassword",
      data: {
        username,
        confirmationCode,
        newPassword,
      },
    });

    return results;
  };

  const pageDefaultSecurity = (entityName) => {
    if (!entityName) {
      return defaultSecurity;
    }

    let security = defaultSecurity;

    let pageConfig = Pages.find((entry) => entry.entityName === entityName);
    if (pageConfig) {
      security = pageConfig.defaultSecurity;
    }

    if (
      family &&
      family.preferences &&
      Array.isArray(family.preferences.pagePreferences)
    ) {
      const page = family.preferences.pagePreferences.find(
        (entry) => entry.name === pageConfig.name
      );

      if (page.hasOwnProperty("defaultSecurity")) {
        security = page.defaultSecurity;
      }
    }

    return security;
  };

  return {
    token,
    isAuthenticated,
    login,
    logout,
    securityLevels,
    securityLevelOptions,
    userCanAdd,
    userCanView,
    userCanUpdate,
    userCanDelete,
    userIsCreator,
    isAvailable,
    isReady,
    forgotPassword,
    verifyCode,
    changePassword,
    pageDefaultSecurity,
    defaultSecurity,
    userCan,
  };
};

export default useSecurity;
