import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";
import useSecurity from "../../hooks/useSecurity";

import { Box, Divider, Stack, Typography } from "@mui/material";

import OFDCollapsable from "../layout/OFDCollapsable";
import OFDIcon from "../ui/OFDIcon";
import OFDDisplayField from "../ui/OFDDisplayField";
import RecurrenceDisplay from "./RecurrenceDisplay";
import OFDDisplayAttendees from "../ui/OFDDisplayAttendees";

const AppointmentDisplay = ({ calendar, onDelete, onEdit }) => {
  const { getLabel, calendarLists, getMessage } = useLocalization();
  const { userCanUpdate, userCanDelete } = useSecurity();
  const { getData } = useApi();

  const [menuItems, setMenuItems] = useState(null);
  const [appointment, setAppointment] = useState(null);

  useEffect(() => {
    let items = [];

    if (calendar.frequency === "import") {
      if (userCanDelete(calendar.security, calendar.addedBy)) {
        items.push({ value: "deleteSeries", label: "deleteImport" });
      }
      if (!calendar.iCalLink) {
        if (userCanUpdate(calendar.security, calendar.addedBy)) {
          items.push({ value: "editOccurrence", label: "editOccurrence" });
        }
        if (userCanDelete(calendar.security, calendar.addedBy)) {
          items.push({ value: "deleteOccurrence", label: "deleteOccurrence" });
        }
      }
    } else if (calendar.frequency !== "onetime") {
      if (userCanUpdate(calendar.security, calendar.addedBy)) {
        items.push({ value: "editSeries", label: "editSeries" });
        items.push({ value: "editOccurrence", label: "editOccurrence" });
      }
      if (userCanDelete(calendar.security, calendar.addedBy)) {
        items.push({ value: "deleteSeries", label: "deleteSeries" });
        items.push({ value: "deleteOccurrence", label: "deleteOccurrence" });
      }
    } else {
      if (userCanUpdate(calendar.security, calendar.addedBy)) {
        items.push({ value: "editOccurrence", label: "editAppointment" });
      }
      if (userCanDelete(calendar.security, calendar.addedBy)) {
        items.push({ value: "deleteOccurrence", label: "deleteAppointment" });
      }
    }

    setMenuItems(items);

    getAppointment();
  }, [calendar]);

  const getAppointment = async () => {
    const results = await getData({
      entityName: "Appointment",
      action: "get",
      id: calendar.source.id,
    });

    if (results.isSuccessful) {
      setAppointment(results.data);
    } else {
      setAppointment(null);
    }
  };

  const handleMenuClick = (option) => {
    if (option === "editSeries") onEdit("series");
    if (option === "editOccurrence") onEdit("occurrence");
    if (option === "deleteSeries") onDelete("series");
    if (option === "deleteOccurrence") onDelete("occurrence");
  };

  const weekdays = (dow) => {
    let list = [];

    for (let x = 0; x < 7; x++) {
      if (dow[x]) {
        let weekday = calendarLists.weekdays.find((wd) => wd.value === x);
        list.push(weekday.short);
      }
    }

    return list.join(", ");
  };

  const dates = () => {
    return appointment.dates.join(", ");
  };

  const days = () => {
    let list = [];

    if (Array.isArray(appointment.days)) {
      for (let x = 0; x < appointment.days.length; x++) {
        let item = appointment.days[x];
        list.push(
          <Typography variant="caption">{`${getLabel(item.week)} : ${weekdays(
            item.dow
          )} ${x < appointment.days.length - 1 ? "| " : ""}`}</Typography>
        );
      }
    }

    return list;
  };

  const displayTime = () => {
    let time = "";

    if (calendar.allDay) {
      time = getLabel("allDay");
    } else if (calendar.fromTime) {
      if (calendar.fromTime) {
        time = format(calendar.fromTime, "h:mm a");
      }
      if (calendar.toTime) {
        time += ` ${getLabel("to")} ${format(calendar.toTime, "h:mm a")}`;
      }
    } else {
      time = `${format(calendar.startDate, "h:mm a")} ${getLabel(
        "to"
      )} ${format(calendar.endDate, "h:mm a")}`;
    }

    return time;
  };

  if (!calendar || !appointment) return;

  return (
    <>
      <Box
        id={calendar.id}
        sx={{ width: "100%" }}
      >
        <OFDCollapsable
          header={
            <Box
              sx={{ display: "flex" }}
              gap={1}
            >
              <Box>
                <OFDIcon name={calendar.category || "calendar"} />
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  {displayTime()}
                </Typography>
                <Typography>{calendar.name}</Typography>
                <OFDDisplayAttendees
                  value={calendar.attendees}
                  direction="row"
                />
              </Box>
            </Box>
          }
          contentStyle={{
            backgroundColor: "transparent",
          }}
          menuItems={menuItems}
          onMenuClick={handleMenuClick}
        >
          <Box sx={{ backgroundColor: "#f8f8f8", borderRadius: "10px" }}>
            <Stack
              sx={{ padding: "1rem" }}
              spacing={1}
            >
              <RecurrenceDisplay entityData={appointment} />

              <Divider />

              <OFDDisplayField
                id="startDate"
                fieldType="datetimeRange"
                fieldProps={{
                  label: "datetime",
                  value: [calendar.startDate, calendar.endDate],
                }}
              />

              <OFDDisplayField
                id="description"
                fieldType="textNote"
                fieldProps={{
                  label: "description",
                  value: calendar.description,
                }}
              />

              <OFDDisplayField
                id="attendees"
                fieldType="attendees"
                fieldProps={{
                  label: "attendees",
                  value: calendar.attendees,
                }}
              />

              <OFDDisplayField
                id="address"
                fieldType="address"
                fieldProps={{
                  label: "address",
                  value: calendar.address,
                }}
              />
            </Stack>
          </Box>
        </OFDCollapsable>
      </Box>
    </>
  );
};

export default AppointmentDisplay;
