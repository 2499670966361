import { Box, Typography } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";

const OFDFormControl = ({
  label,
  required,
  children,
  message,
  error,
  backgroundColor,
  sx,
}) => {
  const { getLabel, getMessage } = useLocalization();

  return (
    <Box>
      <Box
        sx={{
          border: `1px solid ${error ? "#ff0000" : "#ccc"}`,
          padding: ".5rem",
          borderRadius: "4px",
          position: "relative",
          ...sx,
        }}
      >
        {label ? (
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              left: "0px",
              color: `${error ? "#ff0000" : "rgba(0, 0, 0, 0.6)"}`,
              fontSize: "1rem",
              transform: "translate(-10px, -11px) scale(0.75)",
              paddingLeft: "6px",
              paddingRight: "6px",
              backgroundColor: backgroundColor ? backgroundColor : "#fff",
            }}
          >
            <span>{`${label ? getLabel(label) : ""}${
              required ? " *" : ""
            }`}</span>
          </Box>
        ) : null}

        {children}
      </Box>
      <Typography
        variant="caption"
        sx={{ color: `${error ? "#ff0000" : "inherit"}`, marginLeft: "1rem" }}
      >
        {message ? getMessage(message) : ""}
      </Typography>
    </Box>
  );
};

export default OFDFormControl;
