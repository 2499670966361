import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import OFDMultiSelect from "./OFDMultiSelect";

const OFDByMonthDay = ({ value, onChange, disabled, error, message }) => {
  const getSelectedValues = () => {
    let list = [];

    if (Array.isArray(value)) {
      for (const byMonthDay of value) {
        list.push(byMonthDay);
      }
    }

    return list;
  };

  const setSelectedValues = (newValue) => {
    let returnValue = [];

    if (Array.isArray(newValue)) {
      for (const item of newValue) {
        returnValue.push(parseInt(item));
      }
    }

    onChange(returnValue);
  };

  return (
    <Box>
      <OFDMultiSelect
        id="byDay"
        label="monthDates"
        value={getSelectedValues()}
        onChange={setSelectedValues}
        listName="monthDates"
        fullWidth
        disabled={disabled}
        error={error}
        message={message}
      />
    </Box>
  );
};

export default OFDByMonthDay;
