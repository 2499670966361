import { useEffect, useState } from "react";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const useWindow = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("desktop"));

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [orientation, setOrientation] = useState(null);
  const [isVertical, setIsVertical] = useState(undefined);
  const [direction, setDirection] = useState(null);
  const [touch, setTouch] = useState(true);
  const [localeCode, setLocaleCode] = useState(process.env.REACT_APP_LOCALE);
  const [countryCode, setCountryCode] = useState(process.env.REACT_APP_COUNTRY);
  const [languageCode, setLanguageCode] = useState("en");

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    const orientation =
      window.innerWidth > window.innerHeight ? "landscape" : "portrait";

    setOrientation(orientation);

    setIsVertical(orientation === "portrait" ? true : false);
    setDirection(orientation === "portrait" ? "column" : "row");
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, [window.windowHeight, window.windowWidth]);

  useEffect(() => {
    setTouch(matchMedia("(hover:none)").matches);

    handleResize();

    window.addEventListener("resize", handleResize);

    if (window.location.hostname !== "localhost") {
      let urlParts = window.location.hostname.split(".");
      if (urlParts.length === 3) {
        if (urlParts[0] !== "test") {
          setCountryCode(urlParts[0].toUpperCase());
        }
      }
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const iOS = () => {
    return (
      typeof navigator !== "undefined" &&
      /iPad|iPhone|iPod/.test(navigator.userAgent)
    );
  };

  const installSupported = () => {
    if ("serviceWorker" in navigator && "BeforeInstallPromptEvent" in window) {
      return true;
    }

    return false;
  };

  const isRunningApp = () => {
    return window.matchMedia("(display-mode: standalone").matches;
  };

  const deviceType = () => {
    // Use a regular expression to test the userAgent string for common mobile device identifiers.
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? "Mobile" // If any of the identifiers are found, return 'Mobile'.
      : "Desktop"; // Otherwise, return 'Desktop'.
  };

  return {
    windowWidth,
    windowHeight,
    orientation,
    isVertical,
    direction,
    isMobile,
    isDesktop,
    iOS,
    touch,
    localeCode,
    countryCode,
    languageCode,
    installSupported,
    isRunningApp,
    deviceType,
  };
};

export default useWindow;
