import OFDAddress from "./OFDAddress";
import OFDAudioRecorder from "./OFDAudioRecorder";
import OFDAllergies from "./OFDAllergies";
import OFDAttendees from "./OFDAttendees";
import OFDAvatarUpload from "./OFDAvatarUpload";
import OFDBloodPressure from "./OFDBloodPressure";
import OFDBudgetItem from "./OFDBudgetItem";
import OFDBudgetItems from "./OFDBudgetItems";
import OFDButtonSelector from "./OFDButtonSelector";
import OFDCategory from "./OFDCategory";
import OFDCheckBox from "./OFDCheckBox";
import OFDColor from "./OFDColor";
import OFDCurrency from "./OFDCurrency";
import OFDDate from "./OFDDate";
import OFDDateEditor from "../editors/OFDDateEditor";
import OFDDateRange from "./OFDDateRange";
import OFDDocuments from "./OFDDocuments";
import OFDEmail from "./OFDEmail";
import OFDFieldSecurity from "./OFDFieldSecurity";
import OFDFieldSelector from "./OFDFieldSelector";
import OFDGallery from "./OFDGallery";
import OFDGasLog from "./OFDGasLog";
import OFDGroceryItems from "./OFDGroceryItems";
import OFDIngredients from "./OFDIngredients";
import OFDItinerary from "./OFDItinerary";
import OFDInstructions from "./OFDInstructions";
import OFDList from "./OFDList";
import OFDMember from "./OFDMember";
import OFDMembers from "./OFDMembers";
import OFDMemberPicker from "./OFDMemberPicker";
import OFDMealItems from "./OFDMealItems";
import OFDMileageLog from "./OFDMileageLog";
import OFDMultiSelect from "./OFDMultiSelect";
import OFDNoteEditor from "./OFDNoteEditor";
import OFDNumber from "./OFDNumber";
import OFDPasswordField from "./OFDPasswordField";
import OFDPhone from "./OFDPhone";
import OFDPicture from "./OFDPicture";
import OFDReceipts from "./OFDReceipts";
import OFDRecordSecurity from "./OFDRecordSecurity";
import OFDReminders from "./OFDReminders";
import OFDSecurity from "./OFDSecurity";
import OFDSelect from "./OFDSelect";
import OFDServiceLog from "./OFDServiceLog";
import OFDSwitch from "./OFDSwitch";
import OFDTextField from "./OFDTextField";
import OFDTextNote from "./OFDTextNote";
import OFDUrl from "./OFDUrl";
import OFDUserAccount from "./OFDUserAccount";
import OFDVehicleFinancing from "./OFDVehicleFinancing";

import OFDDisplayField from "./OFDDisplayField";

// modes:
// display :  displays value of field in custom format
// editor :   opens modal editor custom for field
// field :    open entry field for forms

const OFDField = ({
  entityName,
  fieldType,
  fieldProps,
  mode = "display",
  onEdit,
}) => {
  if (fieldProps.hidden) return null;

  if (mode === "display") {
    return (
      <OFDDisplayField
        fieldType={fieldType}
        fieldProps={fieldProps}
        onClick={onEdit}
      />
    );
  }

  if (fieldType === "address") {
    return <OFDAddress {...fieldProps} />;
  }

  if (fieldType === "audio") {
    return <OFDAudioRecorder {...fieldProps} />;
  }

  if (fieldType === "allergies") {
    return <OFDAllergies {...fieldProps} />;
  }

  if (fieldType === "avatar") {
    return <OFDAvatarUpload {...fieldProps} />;
  }

  if (fieldType === "attendees") {
    return <OFDAttendees {...fieldProps} />;
  }

  if (fieldType === "boolean" || fieldType === "switch") {
    return <OFDSwitch {...fieldProps} />;
  }

  if (fieldType === "budgetItem") {
    return <OFDBudgetItem {...fieldProps} />;
  }

  if (fieldType === "category") {
    return <OFDCategory {...fieldProps} />;
  }

  if (fieldType === "currency") {
    return <OFDCurrency {...fieldProps} />;
  }

  if (fieldType === "bloodPressure") {
    return <OFDBloodPressure {...fieldProps} />;
  }

  if (fieldType === "budgetItems") {
    return <OFDBudgetItems {...fieldProps} />;
  }

  if (fieldType === "buttonSelector")
    return <OFDButtonSelector {...fieldProps} />;

  if (fieldType === "checkbox") return <OFDCheckBox {...fieldProps} />;

  if (fieldType === "color") {
    return <OFDColor {...fieldProps} />;
  }

  if (fieldType === "date") {
    if (mode === "editor") {
      return <OFDDateEditor {...fieldProps} />;
    } else if (mode === "field") {
      return <OFDDate {...fieldProps} />;
    }
  }

  if (fieldType === "dateRange") {
    return <OFDDateRange {...fieldProps} />;
  }

  if (fieldType === "documents") {
    return <OFDDocuments {...fieldProps} />;
  }

  if (fieldType === "email") {
    return <OFDEmail {...fieldProps} />;
  }

  if (fieldType === "fieldSecurity") {
    return <OFDFieldSecurity {...fieldProps} />;
  }

  if (fieldType === "fieldSelector") {
    return (
      <OFDFieldSelector
        entityName={entityName}
        {...fieldProps}
      />
    );
  }
  if (fieldType === "gallery") {
    return <OFDGallery {...fieldProps} />;
  }

  if (fieldType === "gasLog") {
    return <OFDGasLog {...fieldProps} />;
  }

  if (fieldType === "groceryItems") {
    return <OFDGroceryItems {...fieldProps} />;
  }

  if (fieldType === "ingredients") {
    return <OFDIngredients {...fieldProps} />;
  }

  if (fieldType === "instructions") {
    return <OFDInstructions {...fieldProps} />;
  }

  if (fieldType === "itinerary") {
    return <OFDItinerary {...fieldProps} />;
  }

  if (fieldType === "list") {
    return <OFDList {...fieldProps} />;
  }

  if (fieldType === "member") {
    return <OFDMember {...fieldProps} />;
  }

  if (fieldType === "members") {
    return <OFDMembers {...fieldProps} />;
  }

  if (fieldType === "memberPicker") {
    return <OFDMemberPicker {...fieldProps} />;
  }

  if (fieldType === "mileageLog") {
    return <OFDMileageLog {...fieldProps} />;
  }

  if (fieldType === "multiSelect") {
    return <OFDMultiSelect {...fieldProps} />;
  }

  if (fieldType === "noteEditor") {
    return <OFDNoteEditor {...fieldProps} />;
  }

  if (fieldType === "number") {
    return <OFDNumber {...fieldProps} />;
  }

  if (fieldType === "password") {
    return <OFDPasswordField {...fieldProps} />;
  }

  if (fieldType === "phone") {
    return <OFDPhone {...fieldProps} />;
  }

  if (fieldType === "picture") {
    return <OFDPicture {...fieldProps} />;
  }

  if (fieldType === "mealItems") {
    return <OFDMealItems {...fieldProps} />;
  }

  if (fieldType === "receipts") {
    return <OFDReceipts {...fieldProps} />;
  }

  if (fieldType === "recordSecurity") {
    return <OFDRecordSecurity {...fieldProps} />;
  }

  if (fieldType === "reminders") {
    return <OFDReminders {...fieldProps} />;
  }

  if (fieldType === "security") {
    return <OFDSecurity {...fieldProps} />;
  }

  if (fieldType === "select") {
    return <OFDSelect {...fieldProps} />;
  }

  if (fieldType === "serviceLog") {
    return <OFDServiceLog {...fieldProps} />;
  }

  if (fieldType === "textField") {
    return <OFDTextField {...fieldProps} />;
  }

  if (fieldType === "textNote") {
    return <OFDTextNote {...fieldProps} />;
  }

  if (fieldType === "url") {
    return <OFDUrl {...fieldProps} />;
  }

  if (fieldType === "userAccount") {
    return <OFDUserAccount {...fieldProps} />;
  }

  if (fieldType === "vehicleFinancing") {
    return <OFDVehicleFinancing {...fieldProps} />;
  }
};

export default OFDField;
