import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Divider, Typography } from "@mui/material";

import CalendarFrequencyDisplay from "./CalendarFrequencyDisplay";
import ExpenseEditor from "../editors/ExpenseEditor";

const RecurringExpense = ({ expense, onChange }) => {
  const { displayLocalCurrency } = useLocalization();

  const [openEditor, setOpenEditor] = useState(false);

  const handleOpenEditor = () => {
    setOpenEditor(true);
  };

  const handleSave = () => {
    setOpenEditor(false);
    onChange();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: ".5rem",
        }}
        onClick={handleOpenEditor}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6">{expense.name}</Typography>
            <CalendarFrequencyDisplay entityData={expense} />
          </Box>
          <Box>
            <Typography variant="h6">
              {displayLocalCurrency(expense.expenseAmount)}
            </Typography>
          </Box>
        </Box>

        {expense.description ? (
          <Box>
            <Divider />
            <Typography variant="caption">{expense.description}</Typography>
          </Box>
        ) : null}
      </Box>

      <ExpenseEditor
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        expense={expense}
        onSave={handleSave}
        isIncome={expense.budgetItem.budgetType === "income"}
      />
    </>
  );
};

export default RecurringExpense;
