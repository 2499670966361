import { useState } from "react";

import { Box, Typography } from "@mui/material";

import OFDCheckBox from "../../../ui/OFDCheckBox";

const IngredientItem = ({ ingredient }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Box sx={{ borderBottom: "1px solid #ccc" }}>
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        gap={1}
      >
        <OFDCheckBox
          value={checked}
          onChange={(newValue) => setChecked(newValue)}
          color="orange"
          shade={900}
          size="large"
        />

        {ingredient.quantity ? (
          <Typography
            sx={{ fontSize: "20px", color: checked ? "#ccc" : "#000" }}
          >
            {ingredient.quantity}
          </Typography>
        ) : null}

        <Typography sx={{ fontSize: "20px", color: checked ? "#ccc" : "#000" }}>
          {ingredient.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default IngredientItem;
