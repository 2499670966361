import React from "react";

import OFDModal from "../layout/OFDModal";
import RecipeDisplay from "./RecipeDisplay";

const RecipeDisplayModal = ({ recipeId, recipe, open, onClose }) => {
  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="recipe"
      >
        <RecipeDisplay
          recipeId={recipeId}
          recipe={recipe}
        />
      </OFDModal>
    </>
  );
};

export default RecipeDisplayModal;
