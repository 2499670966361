import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { memberActions } from "../store/member";
import { familyActions } from "../store/family";
import { authenticationActions } from "../store/authentication";

import Utils from "../store/Utils";

import useColor from "./useColor";
import useApi from "./useApi";
import useDebug from "./useDebug";
import useMessage from "./useMessage";

import defaultMemberPreferences from "../store/defaultMemberPreferences";

const useMember = () => {
  const { getData, postData } = useApi();
  const { getColor } = useColor();
  const { consoleLog } = useDebug(false);
  const dispatch = useDispatch();
  const { getState } = Utils();
  const { destructureResults } = useMessage();

  const members = useSelector((state) => state.family.members);
  const member = useSelector((state) => state.member);
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const family = useSelector((state) => state.family);

  const [memberMessage, setMemberMessage] = useState(null);

  const memberColor = (args) => {
    let colorArgs = {
      color: args?.color ? args.color : member.color ? member.color : "default",
      shade: args?.shade ? args.shade : 500,
    };

    if (args && args.id) {
      const selectedMember = members.find((m) => m.id === args.id);
      if (selectedMember && selectedMember.color) {
        colorArgs.color = selectedMember.color;
      }
    } else if (!args) {
      if (member.color) {
        colorArgs.color = member.color;
      }
    }

    let returnColor = getColor(colorArgs);

    return returnColor;
  };

  const getMemberState = async () => {
    if (!isAuthenticated) return;

    const results = await getData({
      entityName: "Member",
      action: "getMemberState",
    });

    if (results.isSuccessful) {
      dispatch(memberActions.setMemberState({ data: results.data.member }));

      dispatch(familyActions.setFamilyState({ data: results.data.family }));
    }
  };

  const refreshMemberState = async () => {
    consoleLog("useMember", "refreshMemberState");

    const memberData = await getState({ name: "member" });

    if (!memberData) {
      dispatch(authenticationActions.logout());
      return;
    }

    dispatch(memberActions.refreshMemberState({ data: memberData }));

    const familyData = await getState({ name: "family" });
    dispatch(familyActions.refreshFamilyState({ data: familyData }));
  };

  const colorAssignments = () => {
    let assignments = {};

    if (!members) return;

    for (const familyMember of members) {
      if (familyMember.color) {
        assignments[familyMember.color] = {
          memberId: familyMember.id,
          name: familyMember.name,
          avatar: familyMember.avatar || null,
          backgroundColor: familyMember.color,
          borderColor: familyMember.color,
        };
      }
    }

    return assignments;
  };

  const getMember = (memberId) => {
    consoleLog("useMember", "getMember", { memberId });

    const familyMember = members?.find((membr) => membr.id === memberId);

    consoleLog("useMember", "getMember", { familyMember });

    return familyMember;
  };

  const getMemberColor = (memberId) => {
    let member = getMember(memberId);

    if (member?.color) return member.color;

    return "grey";
  };

  const getMemberAvatar = (memberId) => {
    let member = getMember(memberId);

    return {
      avatar: member?.avatar || null,
      name: member?.name || null,
      borderColor: member?.color || "grey",
    };
  };

  const getMemberPreferences = () => {
    return { ...defaultMemberPreferences, ...member.preferences };
  };

  const updateMemberPreferences = async (preferences) => {
    const results = await postData({
      entityName: "Member",
      action: "update",
      data: {
        id: member.id,
        preferences,
      },
    });

    if (results.isSuccessful) {
      await getMemberState();
    }
  };

  const validateAccount = (account) => {
    consoleLog("useMember", "validateAccount", { account });

    let validationResults = {
      isSuccessful: true,
      message: "ok",
      displayToUser: false,
      fieldErrors: [],
      severity: "info",
    };

    if (!account.isUser) {
      return validationResults;
    }

    if (!account.username || account.username.trim().length === 0) {
      validationResults.isSuccessful = false;
      validationResults.message = "fieldErrors";
      validationResults.displayToUser = true;
      validationResults.severity = "error";
      validationResults.fieldErrors.push({
        name: "username",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    } else if (account.username.indexOf(" ") > -1) {
      validationResults.isSuccessful = false;
      validationResults.message = "fieldErrors";
      validationResults.displayToUser = true;
      validationResults.severity = "error";
      validationResults.fieldErrors.push({
        name: "username",
        message: "cannotContainSpaces",
        severity: "error",
        error: true,
      });
    }

    consoleLog("useMember", "validateAccount", { validationResults });

    return validationResults;
  };

  const setMemberAvatar = async (memberId, avatar) => {
    const results = await postData({
      entityName: "Member",
      action: "update",
      data: {
        id: memberId,
        avatar,
      },
    });

    if (results.isSuccessful) {
      await getMemberState();
      // let memberState = { ...member, avatar };
      // dispatch(memberActions.setMemberState({ data: memberState }));
    }
  };

  const setMemberColor = async (memberId, color) => {
    const results = await postData({
      entityName: "Member",
      action: "update",
      data: {
        id: memberId,
        color,
      },
    });

    if (results.isSuccessful) {
      await getMemberState();

      // let memberState = { ...member, color };
      // dispatch(memberActions.setMemberState({ data: memberState }));
    }
  };

  const verifyPassword = async (password) => {
    const results = await postData({
      entityName: "Member",
      action: "verifyPassword",
      data: {
        id: member.id,
        password,
      },
    });

    if (results.isSuccessful) {
      return results.data.verified;
    }

    return false;
  };

  const validatePassword = async (password) => {
    const results = await postData({
      entityName: "Member",
      action: "validatePassword",
      data: {
        id: member.id,
        password,
      },
    });

    if (results.isSuccessful) {
      return results.data.valid;
    }

    return false;
  };

  const updatePassword = async (newPassword) => {
    const results = await postData({
      entityName: "Member",
      action: "changePassword",
      data: {
        id: member.id,
        newPassword,
      },
    });

    return results;
  };

  const updateMemberFirstLogin = async () => {
    if (!member.isFirstLogin) return;

    const results = await postData({
      entityName: "Member",
      action: "update",
      data: {
        id: member.id,
        isFirstLogin: false,
      },
    });

    if (results.isSuccessful) {
      await getMemberState();
    }
  };

  const updateMember = async (memberId, data) => {
    const results = await postData({
      entityName: "Member",
      action: "update",
      data: {
        id: memberId,
        ...data,
      },
    });

    setMemberMessage(destructureResults(results));

    return results;
  };

  const getMembersWithChores = async () => {
    const results = await getData({
      entityName: "Member",
      action: "withChores",
      filter: {
        familyId: family.id,
      },
    });

    if (results.isSuccessful) {
      return results.data;
    } else {
      return null;
    }
  };

  const deleteMember = async (member) => {};

  return {
    member,
    memberColor,
    getMember,
    getMemberColor,
    getMemberAvatar,
    getMemberState,
    refreshMemberState,
    memberMode: member?.preferences?.mode || "novice",
    colorAssignments,
    getMemberPreferences,
    updateMemberPreferences,
    validateAccount,
    setMemberAvatar,
    setMemberColor,
    verifyPassword,
    validatePassword,
    updatePassword,
    memberPreferences: { ...defaultMemberPreferences, ...member.preferences },
    defaultMemberPreferences,
    updateMemberFirstLogin,
    getMembersWithChores,
    updateMember,
    memberMessage,
  };
};

export default useMember;
