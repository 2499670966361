import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "./useLocalization";
import useData from "./useData";

const useSearch = () => {
  const { getLabel } = useLocalization();
  const { sort } = useData();

  const [data, setData] = useState();
  const [searchCriteria, setSearchCriteria] = useState(null);
  const [searchResults, setSearchResults] = useState(undefined);

  const searchData = () => {
    setSearchResults(undefined);
    setSearchResults(() => {
      let results = [];

      if (!Array.isArray(data)) return [];

      for (const item of data) {
        if (returnItem(item)) {
          results.push({ ...item });
        }
      }

      return results;
    });
  };

  const returnItem = (item) => {
    let returnThis = true;

    if (!searchCriteria) return true;

    for (const searchField of searchCriteria) {
      switch (searchField.fieldType) {
        case "textField":
          returnThis = searchText(item[searchField.id], searchField.value);
          break;

        case "textNote":
          returnThis = searchText(item[searchField.id], searchField.value);
          break;

        case "address":
          returnThis = searchAddress(item[searchField.id], searchField.value);
          break;
      }
    }

    return returnThis;
  };

  const searchText = (value, searchValue) => {
    if (!searchValue) return true;
    if (!value) return false;

    let startsWithWildCard =
      searchValue.toLowerCase().substring(0, 1) === "*" ? true : false;
    let endsWithWildCard =
      searchValue.toLowerCase().substring(searchValue.length - 1) === "*"
        ? true
        : false;

    if (!startsWithWildCard && !endsWithWildCard) {
      let searchIndex = value.toLowerCase().indexOf(searchValue.toLowerCase());
      if (searchIndex === -1) {
        return false;
      } else {
        return true;
      }
    }

    let minusAstrisk = searchValue.replace("*", "");
    let length = minusAstrisk.length;

    if (startsWithWildCard) {
      if (
        value.toLowerCase().substring(value.length - length) ===
        minusAstrisk.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (endsWithWildCard) {
      if (
        value.toLowerCase().substring(0, length) === minusAstrisk.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const searchAddress = (value, searchValue) => {
    if (!searchValue) return true;
    if (!value) return false;

    let results = true;

    if (searchValue.name && !searchText(value.name, searchValue.name)) {
      results = false;
    }

    if (
      searchValue.streetAddress &&
      !searchText(value.streetAddress, searchValue.streetAddress)
    ) {
      results = false;
    }

    if (
      searchValue.otherAddress &&
      !searchText(value.otherAddress, searchValue.otherAddress)
    ) {
      results = false;
    }

    if (searchValue.city && !searchText(value.city, searchValue.city)) {
      results = false;
    }

    if (
      searchValue.province &&
      !searchText(value.province, searchValue.province)
    ) {
      results = false;
    }

    if (
      searchValue.country &&
      !searchText(value.country, searchValue.country)
    ) {
      results = false;
    }

    if (searchValue.postal && !searchText(value.postal, searchValue.postal)) {
      results = false;
    }

    return results;
  };

  const displaySearchCriteria = (searchCriteria) => {
    if (!searchCriteria) return null;

    let criteria = [];

    for (const field of searchCriteria) {
      if (field.fieldType === "address") {
        criteria = [...criteria, [...getAddressFilter(field.value)]];
      } else if (field.fieldType === "date") {
        let dateRange = [];
        if (Array.isArray(field.value)) {
          if (field.value[0]) {
            dateRange.push(format(field.value[0], "MMM dd"));
          }
          if (field.value[1]) {
            dateRange.push(format(field.value[1], "MMM dd"));
          }
        }
        criteria.push(`${getLabel(field.label)} : ${dateRange.join(" to ")}`);
      } else {
        criteria.push(`${getLabel(field.label)} : ${field.value}`);
      }
    }

    return criteria.join("\n");
  };

  const getAddressFilter = (field) => {
    let address = [];
    if (field.name) address.push(`${getLabel("name")} : ${field.name}`);
    if (field.streetAddress)
      address.push(`${getLabel("streetAddress")} : ${field.streetAddress}`);
    if (field.otherAddress)
      address.push(`${getLabel("otherAddress")} : ${field.otherAddress}`);
    if (field.city) address.push(`${getLabel("city")} : ${field.city}`);
    if (field.province)
      address.push(`${getLabel("province")} : ${field.province}`);
    if (field.country)
      address.push(`${getLabel("country")} : ${field.country}`);
    if (field.postal) address.push(`${getLabel("postal")} : ${field.postal}`);

    return address;
  };

  const clearSearch = () => {
    setSearchResults(null);
    setSearchCriteria(null);
  };

  return {
    setData,
    data,
    setSearchCriteria,
    searchCriteria,
    searchData,
    searchResults,
    displaySearchCriteria,
    clearSearch,
  };
};

export default useSearch;
