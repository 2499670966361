import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography } from "@mui/material";

import OFDSelect from "./OFDSelect";
import OFDMemberPicker from "./OFDMemberPicker";

const OFDSecurity = ({
  id,
  label,
  value,
  onChange,
  required,
  disabled,
  show = { view: true, add: true, update: true, delete: true },
  entityName,
}) => {
  const { labels } = useLocalization();
  const { pageDefaultSecurity, defaultSecurity } = useSecurity();

  const [security, setSecurity] = useState(defaultSecurity);

  useEffect(() => {
    if (entityName) {
      setSecurity(pageDefaultSecurity(entityName));
    }
  }, [entityName]);

  const handleChange = (action, field, newValue) => {
    let newSecurity = {};
    if (value) newSecurity = value;
    if (!value) newSecurity = { ...security };

    onChange({
      ...newSecurity,
      [action]: { ...newSecurity[action], [field]: newValue },
    });
  };

  const getValue = (action, field) => {
    if (!value || !value.hasOwnProperty(action)) {
      if (field === "securityLevel") {
        return security[action].securityLevel;
      } else if (field === "members") {
        return [];
      }
    }

    if (value[action].hasOwnProperty(field)) {
      return value[action][field];
    } else {
      if (field === "securityLevel") {
        return security[action].securityLevel;
      } else if (field === "members") {
        return [];
      }
    }

    return null;
  };

  return (
    <>
      <Stack
        id={id}
        spacing={2}
        sx={{ width: "100%" }}
      >
        {/* VIEW */}
        {show.view && getValue("view", "securityLevel") !== "na" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography>{labels.view}</Typography>
            </Box>

            <Box sx={{ width: "80%" }}>
              <OFDSelect
                id="view-security"
                label="fieldSecurity"
                value={getValue("view", "securityLevel")}
                onChange={(newValue) =>
                  handleChange("view", "securityLevel", newValue)
                }
                listName="fieldSecurityLevels"
                fullWidth
              />

              {getValue("view", "securityLevel") === "members" ? (
                <OFDMemberPicker
                  value={getValue("view", "members")}
                  onChange={(newValue) =>
                    handleChange("view", "members", newValue)
                  }
                />
              ) : null}
            </Box>
          </Box>
        ) : null}
        {/* ADD */}
        {show.add && getValue("add", "securityLevel") !== "na" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography>{labels.add}</Typography>
            </Box>

            <Box sx={{ width: "80%" }}>
              <OFDSelect
                id="add-security"
                label="fieldSecurity"
                value={getValue("add", "securityLevel")}
                onChange={(newValue) =>
                  handleChange("add", "securityLevel", newValue)
                }
                listName="fieldSecurityLevels"
                fullWidth
              />

              {getValue("add", "securityLevel") === "members" ? (
                <OFDMemberPicker
                  value={getValue("add", "members")}
                  onChange={(newValue) =>
                    handleChange("add", "members", newValue)
                  }
                />
              ) : null}
            </Box>
          </Box>
        ) : null}

        {/* UPDATE */}
        {show.update && getValue("update", "securityLevel") !== "na" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography>{labels.update}</Typography>
            </Box>

            <Box sx={{ width: "80%" }}>
              <OFDSelect
                id="update-security"
                label="fieldSecurity"
                value={getValue("update", "securityLevel")}
                onChange={(newValue) =>
                  handleChange("update", "securityLevel", newValue)
                }
                listName="fieldSecurityLevels"
                fullWidth
              />

              {getValue("update", "securityLevel") === "members" ? (
                <OFDMemberPicker
                  value={getValue("update", "members")}
                  onChange={(newValue) =>
                    handleChange("update", "members", newValue)
                  }
                />
              ) : null}
            </Box>
          </Box>
        ) : null}

        {/* DELETE */}
        {show.delete && getValue("delete", "securityLevel") !== "na" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography>{labels.delete}</Typography>
            </Box>

            <Box sx={{ width: "80%" }}>
              <OFDSelect
                id="delete-security"
                label="fieldSecurity"
                value={getValue("delete", "securityLevel")}
                onChange={(newValue) =>
                  handleChange("delete", "securityLevel", newValue)
                }
                listName="fieldSecurityLevels"
                fullWidth
              />

              {getValue("delete", "securityLevel") === "members" ? (
                <OFDMemberPicker
                  value={getValue("delete", "members")}
                  onChange={(newValue) =>
                    handleChange("delete", "members", newValue)
                  }
                />
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

export default OFDSecurity;
