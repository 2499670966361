import { useEffect, useState } from "react";

import useMember from "../../../../hooks/useMember";

import { Box } from "@mui/material";

const AttendeeColors = ({ attendees }) => {
  const { memberColor } = useMember();

  const [colors, setColors] = useState(null);

  useEffect(() => {
    if (!Array.isArray(attendees) || attendees.length === 0) return;

    getColors();
  }, [attendees]);

  const getColors = () => {
    let memberColors = [];

    for (const attendee of attendees) {
      if (attendee.entityName !== "Member") continue;

      memberColors.push(memberColor({ id: attendee.id }));
    }

    setColors(memberColors);
  };

  if (!Array.isArray(attendees) || attendees.length === 0) return;
  if (!colors) return;

  return (
    <Box
      sx={{ display: "flex" }}
      gap={1}
    >
      {colors.map((color) => (
        <Box
          key={color.id}
          sx={{
            ...color,
            height: "10px",
            width: "10px",
            borderRadius: "50%",
          }}
        ></Box>
      ))}
    </Box>
  );
};

export default AttendeeColors;
