import { useEffect, useState } from "react";

import useWindow from "../../hooks/useWindow";

import { Box, Modal } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";

const PictureDisplay = ({ src, open, onClose }) => {
  const { windowHeight, windowWidth } = useWindow();

  const [pictureWidth, setPictureWidth] = useState(undefined);
  const [pictureHeight, setPictureHeight] = useState();

  useEffect(() => {
    if (windowWidth > windowHeight) {
      setPictureHeight("100%");
      setPictureWidth("auto");
    } else {
      setPictureWidth("100%");
      setPictureHeight("auto");
    }
  }, [windowWidth, windowHeight]);

  if (pictureWidth === undefined) return;

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        sx={{ zIndex: 5000 }}
      >
        <Box
          sx={{
            height: `${windowHeight}px`,
            width: `${windowWidth}px`,
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              height: "64px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ccc",
                padding: "4px",
                borderRadius: "50%",
              }}
            >
              <OFDIcon
                name="close"
                color="grey"
                onClick={onClose}
                size="medium"
              />
            </Box>
          </Box>

          <Box
            sx={{
              // height: `${windowHeight - 64 - 10}px`,
              // width: `${windowWidth}px`,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <img
              alt="picture"
              src={src}
              loading="lazy"
              style={{
                objectFit: "contain",
                height: pictureHeight,
                width: pictureWidth,
                maxHeight: "calc(100% - 80px)",
              }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PictureDisplay;
