import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";
import ReminderItem from "./ReminderItem";

const ReminderList = ({ reminders, onEdit, onDelete }) => {
  const { getText } = useLocalization();

  return (
    <>
      <Stack spacing={1}>
        {Array.isArray(reminders) ? (
          reminders.map((reminder) => (
            <Box
              key={reminder.id}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            >
              <ReminderItem
                calendar={reminder}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Box>
          ))
        ) : (
          <Typography>{getText("noEntries")}</Typography>
        )}
      </Stack>
    </>
  );
};

export default ReminderList;
