import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useExpense from "../../hooks/useExpense";
import usePage from "../../hooks/usePage";
import useSearch from "../../hooks/useSearch";
import useData from "../../hooks/useData";

import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import ExpensesForBudget from "./ExpensesForBudget";
import Loading from "../layout/Loading";

const ExpensesByBudget = ({ year, filter, ytd }) => {
  const { displayLocalCurrency } = useLocalization();
  const { refresh } = usePage();
  const { sort, sortByFields } = useData();

  const {
    getExpenseCalendar,
    expenseCalendar,
    calculateBudgetTotals,
    calculateYearlyTotal,
  } = useExpense();

  const {
    data,
    setData,
    searchData,
    searchResults,
    searchCriteria,
    setSearchCriteria,
  } = useSearch();

  const [budgetTotals, setBudgetTotals] = useState(undefined);

  useEffect(() => {
    getData();
    setSearchCriteria(filter);
  }, [year, refresh, filter, ytd]);

  const getData = async () => {
    if (!year) return;

    await getExpenseCalendar(year, ytd);
  };

  useEffect(() => {
    setData(expenseCalendar);
  }, [expenseCalendar]);

  useEffect(() => {
    searchData();
  }, [data]);

  useEffect(() => {
    getBudgetTotals();
  }, [searchResults]);

  const getBudgetTotals = async () => {
    setBudgetTotals(undefined);
    const totals = await calculateBudgetTotals(searchResults, ytd);
    console.log({ totals });
    if (Array.isArray(totals)) {
      setBudgetTotals(sortByFields(totals, ["budgetName"]));
    } else {
      setBudgetTotals(totals);
    }
  };

  if (budgetTotals === undefined) return <Loading />;

  return (
    <>
      <Box sx={{ paddingBottom: "2rem" }}>
        <Stack spacing={2}>
          {budgetTotals?.map((budgetTotal) => (
            <ExpensesForBudget
              key={budgetTotal.budgetId}
              year={year}
              budget={budgetTotal}
              expenses={expenseCalendar}
              onChange={getData}
            />
          ))}
        </Stack>
      </Box>
    </>
  );
};

export default ExpensesByBudget;
