import { useEffect, useState } from "react";

import useLocalization from "../../../hooks/useLocalization";
import useMember from "../../../hooks/useMember";

import { Box, Stack, Typography } from "@mui/material";

import OFDFieldContainer from "../../ui/OFDFieldContainer";
import OFDAvatarUpload from "../../ui/OFDAvatarUpload";
import OFDColor from "../../ui/OFDColor";

const AvatarAndColor = () => {
  const { getWizard } = useLocalization();
  const { member, setMemberAvatar, setMemberColor } = useMember();

  const [avatar, setAvatar] = useState(null);
  const [color, setColor] = useState(null);

  useEffect(() => {
    setAvatar(member.avatar);
    setColor(member.color);
  }, [member]);

  const handleUploadAvatar = async (newAvatar) => {
    setAvatar(newAvatar);
    await setMemberAvatar(member.id, newAvatar);
  };

  const handleChangeColor = async (newColor) => {
    setColor(newColor);
    await setMemberColor(member.id, newColor);
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <Stack spacing={2}>
        <Typography variant="h5">
          {getWizard("setup").avatarAndColor.title}
        </Typography>
        {getWizard("setup").avatarAndColor.text.map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}

        <OFDFieldContainer>
          <Stack spacing={2}>
            <OFDAvatarUpload
              id="avatarUpload"
              value={avatar}
              label="avatar"
              onChange={(newValue) => handleUploadAvatar(newValue)}
            />

            <OFDColor
              id="color"
              label="color"
              value={color}
              onChange={(newValue) => handleChangeColor(newValue)}
              memberData={member}
            />
          </Stack>
        </OFDFieldContainer>
      </Stack>
    </Box>
  );
};

export default AvatarAndColor;
