import React, { useState, useEffect } from "react";
import { format } from "date-fns";

import useMember from "../../hooks/useMember";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const OFDClock = ({ variant }) => {
  const { member, memberColor } = useMember();

  const theme = useTheme();

  const [currentTime, setCurrentTime] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    const updateTime = () => {
      let now = new Date();

      setCurrentTime(format(now, "h:mm aa"));
      setCurrentDate(format(now, "EEE, MMM dd, yyyy"));
    };

    const timer = setInterval(updateTime, 1000);

    updateTime();

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!member) return;

    if (variant) {
      setColor(variant);
    } else if (member.color) {
      setColor(memberColor().color);
    }
  }, [member]);

  return (
    <Box
      sx={{
        color: color,
      }}
    >
      <Typography
        variant="h5"
        align="center"
      >
        {currentTime}
      </Typography>
      <Typography
        align="center"
        sx={{ fontSize: "14px" }}
      >
        {currentDate}
      </Typography>
    </Box>
  );
};

export default OFDClock;
