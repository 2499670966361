import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../../hooks/useLocalization";
import useColor from "../../../hooks/useColor";
import useMember from "../../../hooks/useMember";
import usePage from "../../../hooks/usePage";

import { Box, Stack, Typography } from "@mui/material";

import OFDCollapsable from "../../layout/OFDCollapsable";
import OFDModal from "../../layout/OFDModal";
import OFDDisplayMealItems from "../../ui/OFDDisplayMealItems";
import OFDMealItems from "../../ui/OFDMealItems";
import OFDFieldGroup from "../../ui/OFDFieldGroup";
import OFDButton from "../../ui/OFDButton";
import Loading from "../../layout/Loading";
import OFDToolbar from "../../layout/OFDToolbar";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const DailyPlans = ({ dailyPlans, onChange, weekOf }) => {
  const { calendarLists, getList, getListItemLabel, getLabel } =
    useLocalization();
  const { getColor } = useColor();
  const { member, memberColor } = useMember();
  const {
    isMealPlanDayOpen,
    openMealPlanDay,
    closeMealPlanDay,
    isMealPlanMealsOpen,
    openMealPlanMeals,
    closeMealPlanMeals,
  } = usePage();

  const [weekDays, setWeekDays] = useState(undefined);
  const [selectedWeekDay, setSelectedWeekDay] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [mealItems, setMealItems] = useState([]);

  useEffect(() => {
    if (!dailyPlans || dailyPlans.length === 0) {
      initializeWeekDays();
    } else {
      setWeekDays(dailyPlans);
    }
  }, [dailyPlans]);

  const initializeWeekDays = () => {
    let days = [];
    for (const weekday of calendarLists.weekdays) {
      days.push({
        meals: [...getList("mealTypes").map((type) => ({ name: type.value }))],
      });
    }

    setWeekDays(days);
    onChange(days);
  };

  const handleMenuItems = (weekday, meal) => {
    setSelectedWeekDay(weekday);
    setSelectedMeal(meal);
    setMealItems(meal.items);

    setOpenEditor(true);
  };

  const handleCancel = () => {
    setOpenEditor(false);
    setSelectedWeekDay(null);
    setSelectedMeal(null);
  };

  const handleEditorClose = () => {
    setOpenEditor(false);
  };

  const handleSave = () => {
    let newPlans = [];
    let mealTypes = getList("mealTypes");

    for (let day = 0; day < weekDays.length; day++) {
      let weekday = weekDays[day];
      if (day !== selectedWeekDay) {
        newPlans.push({ ...weekday });
        continue;
      }

      let meals = [];
      let mealIndex = 0;
      for (const weekdayMeal of weekday.meals) {
        let meal = { ...weekdayMeal };

        if (!meal.name) {
          meal.name = mealTypes[mealIndex].value;
        }

        if (meal.name !== selectedMeal.name) {
          meals.push({ ...meal });
          mealIndex++;
          continue;
        }

        meals.push({ ...meal, items: mealItems });

        mealIndex++;
      }

      newPlans.push({ ...weekday, meals: meals });
    }

    onChange(newPlans);

    setOpenEditor(false);
    setSelectedWeekDay(null);
    setSelectedMeal(null);
  };

  const handleToolbarClick = (action) => {
    if (action === "save") {
      handleSave();
    }
  };

  const numberOfMealsForDay = (weekday) => {
    let number = 0;
    for (const meal of weekday.meals) {
      if (Array.isArray(meal.items)) {
        number += meal.items.length;
      }
    }
    return ` (${number})`;
  };

  if (weekDays === undefined) return <Loading />;

  return (
    <>
      <Box>
        <Stack spacing={2}>
          {weekDays.map((weekday, index) => (
            <Box key={`weekday-${index}`}>
              <OFDCollapsable
                id={`${format(weekOf, "yyyyMMdd")}-${index}`}
                header={
                  <Typography>
                    {calendarLists.weekdays[index].label}
                    {numberOfMealsForDay(weekday)}
                  </Typography>
                }
                // styleOverride={{ ...getColor({ color: "grey", shade: 500 }) }}
                styleOverride={{ ...memberColor() }}
                arrowColor={member.color}
                arrowShade={50}
                onOpen={openMealPlanDay}
                onClose={closeMealPlanDay}
                startOpen={isMealPlanDayOpen(
                  `${format(weekOf, "yyyyMMdd")}-${index}`
                )}
              >
                <Stack
                  spacing={1}
                  sx={{ padding: ".5rem" }}
                >
                  {weekday.meals.map((meal) => (
                    <Box key={meal.name}>
                      <OFDCollapsable
                        id={`${format(weekOf, "yyyyMMdd")}-${index}-${
                          meal.name
                        }`}
                        header={
                          <Typography>
                            {`${getListItemLabel("mealTypes", meal.name)} ${
                              meal.items && meal.items.length > 0
                                ? `(${meal.items.length})`
                                : "(0)"
                            }`}
                          </Typography>
                        }
                        styleOverride={{
                          ...memberColor({ shade: 200 }),
                        }}
                        arrowColor={member.color}
                        arrowShade={900}
                        onOpen={openMealPlanMeals}
                        onClose={closeMealPlanMeals}
                        startOpen={isMealPlanMealsOpen(
                          `${format(weekOf, "yyyyMMdd")}-${index}-${meal.name}`
                        )}
                      >
                        <OFDDisplayMealItems
                          label="mealItems"
                          value={meal.items || null}
                          onClick={() => handleMenuItems(index, meal)}
                        />
                      </OFDCollapsable>
                    </Box>
                  ))}
                </Stack>
              </OFDCollapsable>
            </Box>
          ))}
        </Stack>
      </Box>

      {openEditor ? (
        <OFDModal
          open={openEditor}
          onClose={handleEditorClose}
          title={`${calendarLists.weekdays[selectedWeekDay].label} ${selectedMeal.name}`}
        >
          <OFDToolbar
            menuItems={[{ name: "save", label: "save", icon: "save" }]}
            onClick={handleToolbarClick}
          />
          <OFDFieldContainer sx={{ padding: "1rem" }}>
            <OFDMealItems
              id="mealItems"
              value={mealItems}
              onChange={(newValue) => setMealItems(newValue)}
            />
          </OFDFieldContainer>
        </OFDModal>
      ) : null}
    </>
  );
};

export default DailyPlans;
