import { useEffect, useState } from "react";

import usePage from "../../../../hooks/usePage";
import useWindow from "../../../../hooks/useWindow";
import useMealPlan from "../../../../hooks/useMealPlan";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Modal, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../../ui/OFDIcon";
import MealItem from "./MealItem";
import OFDToolbar from "../../OFDToolbar";
import OFDMealItems from "../../../ui/OFDMealItems";

const Meal = ({ meal, dow, mealPlanId, onUpdated }) => {
  const { mealTypes, calendarLists, getListItemLabel } = useLocalization();
  const { windowWidth, windowHeight } = useWindow();
  const { updateMeal } = useMealPlan();
  const { dashboardMode } = usePage();

  const theme = useTheme();

  const [openEditor, setOpenEditor] = useState(false);
  const [open, setOpen] = useState(false);

  const [mealItems, setMealItems] = useState();

  useEffect(() => {
    if (!meal) return;

    setMealItems(meal.items);
  }, [meal, dow]);

  const getMealType = (name) => {
    const mealType = mealTypes.find((item) => item.value === name);
    if (!mealType) {
      return "??";
    }

    return mealType.label;
  };

  const editMeal = () => {
    setOpenEditor(true);
  };

  const handleCloseEditor = () => {
    setOpenEditor(false);
  };

  const handleChange = (newItems) => {
    setMealItems(newItems);
  };

  const handleMenuItem = async (action) => {
    if (action === "save") {
      await handleSave();
    }
  };

  const handleSave = async () => {
    setOpenEditor(false);

    let data = {
      mealPlanId,
      dow,
      mealType: meal.name,
      items: mealItems,
    };

    const results = await updateMeal(data);

    onUpdated();
  };

  if (!meal) return;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "4px",
          borderRadius: "4px",
          paddingLeft: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            {dashboardMode === "edit" ? (
              <OFDIcon
                name="edit"
                color="grey"
                onClick={editMeal}
              />
            ) : null}

            <Typography sx={{ fontWeight: 900 }}>
              {`${getMealType(meal.name)} (${
                Array.isArray(meal.items) ? meal.items.length : 0
              })`}
            </Typography>
          </Box>

          {Array.isArray(meal.items) && meal.items.length > 0 ? (
            <OFDIcon
              name={open ? "arrowUp" : "arrowDown"}
              color="grey"
              onClick={() => setOpen(!open)}
            />
          ) : null}
        </Box>

        {open && Array.isArray(meal.items) && meal.items.length > 0 ? (
          <Box sx={{ marginTop: "10px" }}>
            <Stack spacing={1}>
              {meal.items.map((mealItem) => (
                <MealItem
                  key={mealItem.id}
                  mealItem={mealItem}
                />
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      {openEditor ? (
        <Modal
          open={openEditor}
          onClose={handleCloseEditor}
        >
          <Box
            sx={{
              width: windowWidth,
              height: windowHeight,
              display: "flex",
              justifyContent: "center",
              paddingTop: "50px",
            }}
          >
            <Box
              sx={{
                width: "400px",
                height: `${windowHeight - 100}px`,
                backgroundColor: "#ffffff",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "60px",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  gap={1}
                >
                  <OFDIcon
                    name="mealPlan"
                    color="grey"
                    shade={50}
                  />

                  <Typography variant="h5">
                    {calendarLists.weekdays[dow].label}
                  </Typography>

                  <Typography variant="h5">
                    {getListItemLabel("mealTypes", meal.name)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    border: "1px solid #fff",
                    padding: "4px",
                    borderRadius: "50%",
                  }}
                >
                  <OFDIcon
                    name="close"
                    color="grey"
                    shade={50}
                    onClick={handleCloseEditor}
                  />
                </Box>
              </Box>

              <OFDToolbar
                menuItems={[{ name: "save", label: "save", icon: "save" }]}
                onClick={handleMenuItem}
              />

              <Box
                sx={{
                  height: `${windowHeight - 225}px`,
                  overflow: "auto",
                  padding: "1rem",
                  backgroundColor: "#efefef",
                }}
              >
                <OFDMealItems
                  value={mealItems}
                  onChange={handleChange}
                />
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default Meal;
