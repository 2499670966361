import React from "react";

import { Box } from "@mui/material";

const OFDFieldGroup = ({
  direction = "row",
  justifyContent = "space-between",
  gap = 1,
  children,
  sx,
}) => {
  return (
    <Box
      display="flex"
      flexDirection={direction}
      justifyContent={direction === "row" ? justifyContent : "center"}
      alignItems={direction === "row" ? "center" : justifyContent}
      gap={gap}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export default OFDFieldGroup;
