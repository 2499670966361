import { format, isDate } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

const OFDDisplayDate = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const contentStyle = {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={onClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>

      <Box sx={contentStyle}>
        <Typography>
          {isDate(value) ? format(value, "EEE, MMM d, yyyy") : "-"}
        </Typography>
      </Box>
    </Box>
  );
};

export default OFDDisplayDate;
