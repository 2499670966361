import React, { useState, useEffect } from "react";

import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";

import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "./OFDIcon";

const OFDButton = ({
  id,
  iconName,
  label,
  variant = "contained",
  onClick,
  color,
  width,
  shade,
  size,
  disabled,
}) => {
  const { getColor } = useColor();
  const { getLabel } = useLocalization();
  const { memberColor, member } = useMember();

  const theme = useTheme();

  const [buttonStyle, setButtonStyle] = useState(null);
  const [iconColor, setIconColor] = useState("grey");
  const [iconShade, setIconShade] = useState(50);
  const [labelColor, setLabelColor] = useState("#000");

  useEffect(() => {
    let buttonColor = color ? color : member.color ? member.color : "default";
    let buttonShade = shade ? shade : 500;

    setIconColor(buttonColor);

    if (variant === "contained") {
      setButtonStyle({
        "&.MuiButton-root": {
          ...memberColor({ color: buttonColor, shade: buttonShade }),
        },
      });

      if (buttonShade > 500) {
        setLabelColor(theme.palette[buttonColor].darkContrast);
      } else {
        setLabelColor(theme.palette[buttonColor].lightContrast);
      }
    } else {
      setButtonStyle({
        "&.MuiButton-root": {
          border: `1px solid ${
            memberColor({
              color: buttonColor,
              shade: buttonShade,
            }).backgroundColor
          }`,
          color: memberColor({ color: buttonColor, shade: 500 })
            .backgroundColor,
          "&.Mui-disabled": {
            border: `1px solid ${
              memberColor({
                color: buttonColor,
                shade: 500,
              }).backgroundColor
            }`,
            color: memberColor({ color: buttonColor, shade: 500 })
              .backgroundColor,
          },
        },
      });
    }
  }, [color, shade, variant]);

  return (
    <Button
      id={id}
      variant={variant ? variant : "contained"}
      onClick={onClick}
      disabled={disabled || false}
      sx={{
        ...buttonStyle,
        width: width ? width : "auto",
      }}
      size={size ? size : "small"}
    >
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        gap={1}
      >
        {iconName ? (
          <Box>
            <OFDIcon
              name={iconName}
              color={iconColor}
              shade={iconShade}
            />
          </Box>
        ) : null}
        <Typography
          sx={{ fontSize: "14px" }}
          noWrap={true}
          color={labelColor}
        >
          {getLabel(label)}
        </Typography>
      </Box>
    </Button>
  );
};

export default OFDButton;
