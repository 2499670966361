import { useEffect, useState } from "react";

import useFamily from "./useFamily";
import useApi from "./useApi";
import useMessage from "./useMessage";
import useData from "./useData";

import Configuration from "../configuration/Configuration";

const useDocument = () => {
  const { family } = useFamily();
  const { getData, postData, getFileUrl, deleteData } = useApi();
  const { destructureResults } = useMessage();
  const { sort } = useData();

  const config = Configuration.entity.Document;

  const [searchCriteria, setSearchCriteria] = useState();
  const [searchResults, setSearchResults] = useState();
  const [documents, setDocuments] = useState(undefined);
  const [folders, setFolders] = useState(undefined);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [documentMessage, setDocumentMessage] = useState(null);
  const [currentPath, setCurrentPath] = useState([{ id: null, name: "root" }]);
  const [currentDocument, setCurrentDocument] = useState(null);

  useEffect(() => {
    buildCurrentPath();
  }, [currentFolderId]);

  const getDocuments = async () => {
    setDocuments(undefined);

    const results = await getData({
      entityName: "Document",
      action: "get",
      filter: {
        familyId: family.id,
        source: null,
      },
    });

    if (results.isSuccessful) {
      setDocuments(results.data);
      await getFolders();
    } else {
      setDocuments([]);
    }
  };

  const getFolders = async () => {
    setFolders(undefined);

    const results = await getData({
      entityName: "Folder",
      action: "get",
      filter: {
        familyId: family.id,
      },
    });

    if (results.isSuccessful) {
      setFolders(results.data);
    } else {
      setFolders([]);
    }
  };

  const getCurrentFolderContents = () => {
    if (!documents) return [];

    let folder = { id: null, name: "root" };
    if (currentFolderId && Array.isArray(folders)) {
      folder = folders.find((f) => f.id === currentFolderId);
    }

    let files = documents.filter((doc) => doc.folderId === currentFolderId);
    files = sort(files, "name");

    let subFolders = [];
    if (Array.isArray(folders) && folders.length > 0) {
      subFolders = folders.filter((f) => f.parentFolderId === currentFolderId);
    }

    subFolders = sort(subFolders, "name");

    return {
      ...folder,
      documents: [...files],
      folders: [...subFolders],
    };
  };

  const getSearchFields = () => {
    let searchFields = config.fields.filter((field) => field.search);

    return searchFields;
  };

  const applySearchCriteria = () => {
    if (!documents) {
      setSearchResults(null);
      return;
    }

    setSearchResults(() => {
      let items = [];

      for (const item of documents) {
        if (returnItem(item)) {
          items.push({ ...item });
        }
      }

      return items;
    });
  };

  const returnItem = (item) => {
    let returnThis = true;

    for (const searchField of searchCriteria) {
      switch (searchField.fieldType) {
        case "textField":
          returnThis = searchText(item[searchField.id], searchField.value);
          break;

        case "textNote":
          returnThis = searchText(item[searchField.id], searchField.value);
          break;
      }
    }

    return returnThis;
  };

  const searchText = (value, searchValue) => {
    if (!searchValue) return true;
    if (!value) return false;

    let startsWithWildCard =
      searchValue.toLowerCase().substring(0, 1) === "*" ? true : false;
    let endsWithWildCard =
      searchValue.toLowerCase().substring(searchValue.length - 1) === "*"
        ? true
        : false;

    if (!startsWithWildCard && !endsWithWildCard) {
      let searchIndex = value.toLowerCase().indexOf(searchValue.toLowerCase());
      if (searchIndex === -1) {
        return false;
      } else {
        return true;
      }
    }

    let minusAstrisk = searchValue.replace("*", "");
    let length = minusAstrisk.length;

    if (startsWithWildCard) {
      if (
        value.toLowerCase().substring(value.length - length) ===
        minusAstrisk.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (endsWithWildCard) {
      if (
        value.toLowerCase().substring(0, length) === minusAstrisk.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const deleteDocument = async (document) => {
    const results = await deleteData({
      entityName: "Document",
      action: "delete",
      id: document.id,
    });

    setDocumentMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getDocuments();
    }
  };

  const deleteFolder = async (folder) => {
    const deleteDocuments = documents.filter(
      (doc) => doc.folderId === folder.id
    );

    if (deleteDocuments && deleteDocuments.length > 0) {
      for (const document of deleteDocuments) {
        await deleteData({
          entityName: "Document",
          action: "delete",
          id: document.id,
        });
      }
    }

    const results = await deleteData({
      entityName: "Folder",
      action: "delete",
      id: folder.id,
    });

    setDocumentMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getDocuments();
    }
  };

  const addFolder = async (newFolder) => {
    let data = {
      familyId: family.id,
      parentFolderId: currentFolderId,
      ...newFolder,
    };

    const results = await postData({
      entityName: "Folder",
      action: "add",
      data,
    });

    setDocumentMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getDocuments();
    }

    return results;
  };

  const buildCurrentPath = () => {
    const rootPath = { id: null, name: "root" };

    let folderItems = [];
    let folder = null;

    if (
      Array.isArray(folders) &&
      folders.length > 0 &&
      currentFolderId !== null
    ) {
      folder = folders.find((f) => f.id === currentFolderId);

      while (folder !== null) {
        folderItems.unshift({
          id: folder.id,
          name: folder.name,
        });

        if (folder.parentFolderId) {
          folder = folders.find((f) => f.id === folder.parentFolderId);
        } else {
          folder = null;
        }
      }
    }

    folderItems.unshift(rootPath);

    setCurrentPath(folderItems);
  };

  const getDocumentUrl = async (documentId) => {
    let results = await getData({
      entityName: "Document",
      action: "get",
      id: documentId,
    });

    console.log({ results });

    if (results.isSuccessful) {
      let apiUrl = process.env.REACT_APP_API_URL;

      if (apiUrl.indexOf("localhost") > -1) {
        apiUrl = "https://test.ourfamilydashboard.com/api";
      }

      return `${apiUrl}/download/${encodeURIComponent(results.data.key)}.${
        results.data.fileExtension
      }`;
    }

    // let results = await getFileUrl({
    //   entityName: "Document",
    //   action: "requestPresignedGetURL",
    //   data: {
    //     documentId,
    //   },
    // });

    // let documentUrl = null;

    // if (results.isSuccessful) {
    //   documentUrl = results.data.presignedURL;
    // }

    // return documentUrl;
  };

  const getFolderStructure = () => {
    let folderStructure = [{ value: "root", label: "root", level: 0 }];
    if (!folders || folders.length === 0) {
      return folderStructure;
    }

    const rootFolders = folders.filter(
      (folder) => folder.parentFolderId === null
    );

    for (const rootFolder of rootFolders) {
      folderStructure.push({
        value: rootFolder.id,
        label: `root / ${rootFolder.name}`,
        level: 1,
      });

      let subFilters = getSubFolders(
        rootFolder.id,
        `root / ${rootFolder.name}`,
        1
      );
      if (subFilters) {
        folderStructure = [...folderStructure, ...subFilters];
      }
    }

    folderStructure = sort(folderStructure, "label");

    return folderStructure;
  };

  const getSubFolders = (parentFolderId, parentFolderName, parentLevel) => {
    let subFolders = folders.filter(
      (folder) => folder.parentFolderId === parentFolderId
    );
    if (!subFolders || subFolders.length === 0) {
      return null;
    }

    let folderList = [];
    let currentLevel = parentLevel + 1;

    for (const subFolder of subFolders) {
      let name = `${parentFolderName} / ${subFolder.name}`;

      folderList.push({
        value: subFolder.id,
        label: name,
        level: currentLevel,
      });

      let list = getSubFolders(subFolder.id, name, currentLevel);

      if (list) {
        folderList = [...folderList, ...list];
      }
    }

    return folderList;
  };

  const moveDocument = async (document, newFolderId) => {
    let data = {
      ...document,
      folderId: newFolderId === "root" ? null : newFolderId,
    };

    const results = await postData({
      entityName: "Document",
      action: "update",
      data,
    });

    setDocumentMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getDocuments();
    }

    return results;
  };

  const moveFolder = async (folder, newFolderId) => {
    let data = {
      ...folder,
      parentFolderId: newFolderId === "root" ? null : newFolderId,
    };

    const results = await postData({
      entityName: "Folder",
      action: "update",
      data,
    });

    setDocumentMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getDocuments();
    }

    return results;
  };

  const renameDocument = async (document, newName) => {
    let data = {
      ...document,
      name: newName,
    };

    const results = await postData({
      entityName: "Document",
      action: "update",
      data,
    });

    setDocumentMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getDocuments();
    }

    return results;
  };

  const renameFolder = async (folder, newName) => {
    let data = {
      ...folder,
      name: newName,
    };

    const results = await postData({
      entityName: "Folder",
      action: "update",
      data,
    });

    setDocumentMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getDocuments();
    }

    return results;
  };

  const updateSecurity = async (entityName, document, newSecurity) => {
    const results = await postData({
      entityName: entityName,
      action: "update",
      data: {
        ...document,
        security: { ...newSecurity },
      },
    });

    setDocumentMessage(destructureResults(results));

    if (results.isSuccessful) {
      await getDocuments();
    }

    return results;
  };

  const getBySource = async (source) => {
    setDocuments(undefined);

    const results = await getData({
      entityName: "Document",
      action: "get",
      filter: {
        familyId: family.id,
        source,
      },
    });

    if (results.isSuccessful) {
      setDocuments(results.data);
    } else {
      setDocuments([]);
    }
  };

  return {
    documents,
    getDocuments,
    searchCriteria,
    setSearchCriteria,
    getSearchFields,
    applySearchCriteria,
    deleteDocument,
    deleteFolder,
    getCurrentFolderContents,
    currentFolderId,
    setCurrentFolderId,
    documentMessage,
    setDocumentMessage,
    addFolder,
    currentPath,
    getDocumentUrl,
    currentDocument,
    setCurrentDocument,
    getFolderStructure,
    moveDocument,
    moveFolder,
    renameDocument,
    renameFolder,
    updateSecurity,
    getBySource,
  };
};

export default useDocument;
