import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography, Popover } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../ui/OFDIcon";
import OFDDisplayMembers from "../ui/OFDDisplayMembers";
import OFDCheckBox from "../ui/OFDCheckBox";

const ToDoItem = ({ calendar, onEdit, onDelete, onComplete, onShop }) => {
  const { userCanUpdate, userCanDelete } = useSecurity();
  const { getLabel } = useLocalization();
  const theme = useTheme();

  const [showMenu, setShowMenu] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);
  const [menuItems, setMenuItems] = useState(null);

  useEffect(() => {
    let items = [];

    if (calendar.frequency !== "onetime") {
      if (userCanUpdate(calendar.security, calendar.addedBy)) {
        items.push({ value: "editSeries", label: "editSeries", icon: "edit" });
        items.push({
          value: "editOccurrence",
          label: "editOccurrence",
          icon: "edit",
        });
        items.push({
          value: "complete",
          label: "complete",
          icon: "checkCircle",
        });
      }
      if (userCanDelete(calendar.security, calendar.addedBy)) {
        items.push({
          value: "deleteSeries",
          label: "deleteSeries",
          icon: "delete",
        });
        items.push({
          value: "deleteOccurrence",
          label: "deleteOccurrence",
          icon: "delete",
        });
      }
    } else {
      if (userCanUpdate(calendar.security, calendar.addedBy)) {
        if (calendar.source?.groceryId) {
          items.push({
            value: "shop",
            label: "shop",
            icon: "grocery",
          });
        }
        items.push({
          value: "editOccurrence",
          label: "editToDo",
          icon: "edit",
        });
        items.push({
          value: "complete",
          label: "complete",
          icon: "checkCircle",
        });
      }
      if (userCanDelete(calendar.security, calendar.addedBy)) {
        items.push({
          value: "deleteOccurrence",
          label: "deleteToDo",
          icon: "delete",
        });
      }
    }

    setMenuItems(items);
  }, [calendar]);

  const handleMenuClick = (option) => {
    if (option === "editSeries") onEdit(calendar, "series");
    if (option === "editOccurrence") onEdit(calendar, "occurrence");
    if (option === "deleteSeries") onDelete(calendar, "series");
    if (option === "deleteOccurrence") onDelete(calendar, "occurrence");
    if (option === "complete") onComplete(calendar, "occurrence");
    if (option === "shop") onShop(calendar);
    handleCloseMenu();
  };

  const handleOpenMenu = (e) => {
    setAnchorE1(e.target);
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorE1(null);
    setShowMenu(false);
  };

  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid #ccc",
          borderBottom: "1px solid #ccc",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            gap={1}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
              gap={0.5}
            >
              <OFDIcon name={calendar.category} />
            </Box>
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>
                {calendar.name}
              </Typography>
            </Box>
          </Box>

          {Array.isArray(menuItems) && menuItems.length > 0 ? (
            <Box onClick={handleOpenMenu}>
              <OFDIcon
                name="verticalEllipse"
                color="grey"
              />
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <Box>
            <OFDCheckBox
              value={calendar.todoComplete}
              onChange={() => onComplete(calendar, "occurrence")}
            />
          </Box>

          <Box>
            {calendar.description ? (
              <Box sx={{ marginRight: "5px" }}>
                <Typography
                  variant="caption"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {calendar.description}
                </Typography>
              </Box>
            ) : null}

            <Box sx={{ marginRight: "5px" }}>
              <OFDDisplayMembers
                value={calendar.members}
                styleoverride={{ borderBottom: "none" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Popover
        open={showMenu}
        onClose={handleCloseMenu}
        anchorEl={anchorE1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack
          spacing={3}
          sx={{
            backgroundColor: "#ffffff",
            padding: "1rem",
            borderRadius: "8px",
            boxShadow: theme.shadows[8],
          }}
        >
          {menuItems?.map((menuItem) => (
            <Box
              key={menuItem.value}
              onClick={() => handleMenuClick(menuItem.value)}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <OFDIcon name={menuItem.icon} />
              {getLabel(menuItem.label)}
            </Box>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default ToDoItem;
