import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import useSubscription from "../../../../hooks/useSubscription";
import useApi from "../../../../hooks/useApi";

import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import Offering from "./Offering";

import OFDTextField from "../../../ui/OFDTextField";

import FeaturesModal from "./FeaturesModal";

const Paywall = () => {
  const { offerings, packages, appUserId, purchaseCancelled } =
    useSubscription();
  const { postData } = useApi();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [packageSelected, setPackageSelected] = useState(null);

  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [couponMessage, setCouponMessage] = useState();
  const [validCoupon, setValidCoupon] = useState(false);

  const [openFeatures, setOpenFeatures] = useState(false);

  useEffect(() => {
    const cancelled = searchParams.get("cancelled");
    if (cancelled === "1") {
      const signupId = searchParams.get("signupid");
      const email = searchParams.get("email");

      purchaseCancelled(signupId, email);
    }
  }, []);

  const handleSelect = async (identifier) => {
    setPackageSelected(identifier);
  };

  const handlePurchase = async () => {
    const results = await postData({
      entityName: "Signup",
      action: "createSessionCheckout",
      data: {
        lookupKey: packageSelected,
        couponCode: coupon,
        email: appUserId,
      },
    });

    if (results.isSuccessful) {
      window.location.href = results.data.url;
    }
  };

  const handleCouponEntry = (newValue) => {
    setCoupon(newValue);
    setCouponMessage(null);
    setValidCoupon(false);
  };

  const handleOpenCouponModal = () => {
    setCouponMessage(null);
    setValidCoupon(false);
    setCoupon(null);
    setOpenCouponModal(true);
  };

  const verifyCoupon = async () => {
    setCouponMessage(null);
    setValidCoupon(false);

    const results = await postData({
      entityName: "Coupon",
      action: "verifyCoupon",
      data: {
        couponCode: coupon,
      },
    });

    if (results.isSuccessful) {
      setCouponMessage(results.data.description);
      setValidCoupon(true);
    } else {
      setValidCoupon(false);
      setCouponMessage("Coupon not valid");
    }
  };

  if (!Array.isArray(packages)) return;

  return (
    <>
      <Box>
        <Typography
          align="center"
          variant="h5"
        >
          Subscription Plans
        </Typography>

        <Stack
          spacing={2}
          sx={{ marginTop: "1rem" }}
        >
          {packages ? (
            packages.map((offer) => (
              <Offering
                key={offer.productId}
                id={offer.productId}
                offer={offer}
                onSelect={handleSelect}
                packageSelected={packageSelected}
              />
            ))
          ) : (
            <Typography>No Plans available</Typography>
          )}

          {packageSelected && (!coupon || !validCoupon) ? (
            <Button onClick={handleOpenCouponModal}>I have a coupon</Button>
          ) : (
            <Typography
              align="center"
              sx={{ fontWeight: 900 }}
            >
              {couponMessage}
            </Typography>
          )}

          {packages && packageSelected ? (
            <Button
              variant="contained"
              onClick={handlePurchase}
            >
              Purchase
            </Button>
          ) : null}

          <Typography
            align="center"
            sx={{ fontWeight: 900 }}
          >
            30-Day FREE trial with all plans!
          </Typography>

          <Typography
            align="center"
            sx={{ fontWeight: 900 }}
          >
            Includes 10 Family Members with Logins!
          </Typography>

          <Button
            variant="contained"
            onClick={() => setOpenFeatures(true)}
          >
            What do I get?
          </Button>
        </Stack>
      </Box>

      <Modal
        id="couponModal"
        open={openCouponModal}
        onClose={() => setOpenCouponModal(false)}
      >
        <Box
          sx={{
            marginTop: "3rem",
            marginLeft: "2rem",
            marginRight: "2rem",
            backgroundColor: "#fff",
            borderRadius: "1rem",
            padding: "1rem",
          }}
        >
          <Stack spacing={2}>
            <Typography
              align="center"
              variant="h5"
            >
              Enter Your Coupon
            </Typography>

            <OFDTextField
              id="coupon"
              label="Coupon"
              value={coupon}
              onChange={handleCouponEntry}
            />

            <Typography align="center">{couponMessage}</Typography>

            {!validCoupon ? (
              <Button
                variant="contained"
                onClick={verifyCoupon}
              >
                Verify Coupon
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => setOpenCouponModal(false)}
              >
                Apply Coupon
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>

      <FeaturesModal
        open={openFeatures}
        onClose={() => setOpenFeatures(false)}
      />
    </>
  );
};

export default Paywall;
