import { useEffect, useState } from "react";
import { startOfToday, endOfToday, startOfDay, format } from "date-fns";

import useApi from "../../hooks/useApi";
import useData from "../../hooks/useData";
import usePage from "../../hooks/usePage";
import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";
import ChildToDo from "./ChildToDo";

const ChildToDos = ({ onChange }) => {
  const { sort } = useData();
  const { getData } = useApi();
  const { refresh } = usePage();
  const { member, memberColor } = useMember();
  const { getText } = useLocalization();

  const [todos, setToDos] = useState(undefined);

  useEffect(() => {
    getToDos();
  }, [refresh]);

  const getToDos = async () => {
    setToDos(undefined);

    const results = await getData({
      entityName: "Calendar",
      action: "get",
      filter: {
        memberId: member.id,
        calendarType: "todo",
        startDate: startOfToday(),
      },
    });

    if (results.isSuccessful) {
      setToDos(results.data);
    } else {
      setToDos(null);
    }
  };

  if (!todos || todos.length === 0) return null;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
      }}
      gap={1}
    >
      <Box
        sx={{
          display: "flex",
          ...memberColor(),
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          padding: "8px",
        }}
        gap={1}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <OFDIcon
            name="todo"
            size="large"
            color="grey"
            shade={50}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6">{getText("thingsToDo")}</Typography>
          </Box>
        </Box>
      </Box>

      <Stack
        sx={{ padding: ".5rem" }}
        spacing={1}
      >
        {todos.map((todo) => (
          <ChildToDo
            key={todo.id}
            id={todo.id}
            todo={todo}
            onChange={onChange}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default ChildToDos;
