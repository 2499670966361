import { useEffect, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import useEntity from "../../hooks/useEntity";
import useForm from "../../hooks/useForm";
import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useColor from "../../hooks/useColor";
import useDocument from "../../hooks/useDocument";

import OFDField from "../ui/OFDField";
import FieldEditorModal from "../editors/FieldEditorModal";
import OFDCollapsable from "../layout/OFDCollapsable";
import DocumentViewer from "./DocumentViewer";
import DocumentDisplay from "./DocumentDisplay";
import Loading from "../layout/Loading";

const EntityDisplay = ({
  entityName,
  id,
  entityData,
  onChange,
  onFolderSelected,
  onFolderEdit,
}) => {
  const { entityConfig } = useEntity(entityName);
  const {
    getDataById,
    data,
    setData,
    getFieldValue,
    getFieldLabel,
    getFieldValueFromFolder,
    setFieldValue,
    createFolder,
    updateFolder,
    deleteFolder,
    saveFolder,
    saveData,
    formStatus,
    formMessage,
    resetFormMessage,
  } = useForm(entityName);

  const { memberColor } = useMember();
  const { getLabel, getMessage } = useLocalization();
  const { userCanUpdate, userCanView, userCanDelete, userIsCreator } =
    useSecurity();
  const { getColor } = useColor();
  const { documents, getBySource } = useDocument();

  const [openEditor, setOpenEditor] = useState(false);
  const [selectedField, setSelectedField] = useState(null);

  const [currentFolder, setCurrentFolder] = useState(null);
  const [displayDocument, setDisplayDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const mode = "display";

  useEffect(() => {
    if (!id || !entityData) return;

    getEntityData();
  }, [id, entityData]);

  useEffect(() => {
    onFolderSelected(currentFolder);
  }, [currentFolder]);

  const getEntityData = async () => {
    setData(undefined);
    if (entityData) {
      setData(entityData);
    } else {
      await getDataById(id);
    }
  };

  useEffect(() => {
    if (!data) return;
    if (!data.hasDocuments) return;

    getBySource({
      model: entityName,
      id: id,
    });
  }, [data]);

  const folderMenuItems = (folder) => {
    if (!userCanUpdate(folder.security, folder.addedBy)) return;

    let menuItems = [
      {
        value: "edit",
        label: "editFolder",
        icon: "edit",
      },
    ];

    if (userIsCreator(folder.addedBy)) {
      menuItems.push({
        value: "security",
        label: "security",
        icon: "security",
      });
    }

    if (userCanDelete(folder.security, folder.addedBy)) {
      menuItems.push({
        value: "delete",
        label: "deleteFolder",
        icon: "delete",
      });
    }

    return menuItems;
  };

  const handleEdit = (field, folder) => {
    if (field.security) {
      if (!userCanUpdate(field.security, field.addedBy || data.addedBy)) {
        return;
      }
    }

    if (!userCanUpdate(data.security, data.addedBy)) {
      return;
    }

    if (folder) {
      setCurrentFolder(folder);
    } else {
      setCurrentFolder(null);
    }
    setSelectedField(field);
    setOpenEditor(true);
  };

  const handleChange = () => {
    setOpenEditor(false);
    setData(undefined);

    getDataById(id);
    onChange();
  };

  const handleCancel = () => {
    setOpenEditor(false);
  };

  const handleMenuClick = (option, folder) => {
    onFolderEdit(option, folder);
  };

  const handleDocumentSelect = (document) => {
    setSelectedDocument(document);
    setDisplayDocument(true);
  };

  const handleCloseDocument = () => {
    setSelectedDocument(null);
    setDisplayDocument(false);
  };

  const documentsAvailable = () => {
    if (!Array.isArray(documents) || documents.length === 0) return false;

    let available = false;

    for (const document of documents) {
      if (userCanView(document.security, document.addedBy)) {
        available = true;
        break;
      }
    }

    return available;
  };

  if (data === undefined || !entityConfig) return null;

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingBottom: "1rem",
        }}
      >
        {entityConfig.fields?.map((field) => (
          <OFDField
            key={field.id}
            fieldType={field.fieldType}
            mode={mode}
            fieldProps={{
              ...field,
              value: getFieldValue(field.id),
              memberData: entityName === "Member" ? data : null,
              entityData: data,
            }}
            onEdit={() => handleEdit(field)}
          />
        ))}

        {data?.folders?.length > 0
          ? data.folders.map((folder) => {
              if (
                folder.security &&
                !userCanView(folder.security, folder.addedBy)
              ) {
                return;
              }
              return (
                <Box key={folder.id}>
                  <OFDCollapsable
                    header={<Typography variant="h6">{folder.name}</Typography>}
                    headerStyle={{
                      ...getColor({ color: "grey", shade: "200" }),
                    }}
                    menuItems={folderMenuItems(folder)}
                    onMenuClick={(option) => handleMenuClick(option, folder)}
                  >
                    <Stack
                      spacing={2}
                      sx={{
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        paddingBottom: "1rem",
                      }}
                    >
                      {Array.isArray(folder.fields) &&
                      folder.fields.length > 0 ? (
                        folder.fields.map((field) => {
                          if (
                            field.security &&
                            !userCanView(field.security, field.addedBy)
                          ) {
                            return;
                          }

                          return (
                            <OFDField
                              key={field.id}
                              fieldType={field.fieldType}
                              fieldProps={{
                                ...field,
                                fullWidth: true,
                                value: getFieldValueFromFolder(
                                  folder.id,
                                  field
                                ),
                                entryAddedBy: data.addedBy,
                                source: {
                                  model: entityName,
                                  id: id,
                                },
                                onChange: handleChange,
                              }}
                              mode={mode}
                              onEdit={() => handleEdit(field, folder)}
                            />
                          );
                        })
                      ) : (
                        <Typography>{getMessage("folderEmpty")}</Typography>
                      )}
                    </Stack>
                  </OFDCollapsable>
                </Box>
              );
            })
          : null}

        {documentsAvailable() ? (
          <OFDCollapsable
            header={
              <Typography variant="h6">{getLabel("documents")}</Typography>
            }
            headerStyle={{
              ...getColor({ color: "grey", shade: "200" }),
            }}
          >
            <Stack spacing={1.5}>
              {documents?.map((document) => {
                return (
                  <DocumentDisplay
                    key={document.id}
                    document={document}
                    onClick={handleDocumentSelect}
                    onDelete={onChange}
                    onChange={onChange}
                  />
                );
              })}
            </Stack>
          </OFDCollapsable>
        ) : null}
      </Stack>

      {openEditor ? (
        <FieldEditorModal
          entityName={entityName}
          entityData={data}
          open={openEditor}
          field={selectedField}
          folder={currentFolder}
          onChange={handleChange}
          onCancel={handleCancel}
          source={{ model: entityName, id: id }}
        />
      ) : null}

      {displayDocument ? (
        <DocumentViewer
          document={selectedDocument}
          open={displayDocument}
          onClose={handleCloseDocument}
        />
      ) : null}
    </>
  );
};

export default EntityDisplay;
