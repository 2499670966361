import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useApi from "../../hooks/useApi";
import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";
import useData from "../../hooks/useData";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";
import OFDTextNote from "./OFDTextNote";
import OFDSelect from "./OFDSelect";
import OFDButton from "./OFDButton";

import OFDDialog from "../layout/OFDDialog";
import OFDDisplayAttendee from "./OFDDisplayAttendee";
import OFDFieldGroup from "./OFDFieldGroup";

const fieldsTemplate = {
  entityName: "Member",
  id: null,
  notes: null,
};

const OFDAttendees = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { getColor } = useColor();
  const { getData } = useApi();
  const { userCanView, userCanUpdate } = useSecurity();
  const { memberColor } = useMember();
  const { sort } = useData();

  const [fields, setFields] = useState({ ...fieldsTemplate });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");
  const [entityList, setEntityList] = useState([]);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    getModelList();
  }, [value, fields.entityName]);

  const getModelList = async () => {
    const results = await getData({
      entityName: fields.entityName || "Member",
      action: "get",
      filter: {},
    });

    if (results.isSuccessful) {
      setEntityList(results.data);
    } else {
      setEntityList([]);
    }
  };

  const handleCancel = () => {
    setEditMode("add");
    setFields({ ...fieldsTemplate });
    setSelectedItem(null);
  };

  const handleAddItem = () => {
    setEditMode("add");
    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields }];
    } else {
      list = [{ ...fields }];
    }

    handleChange(list);
    setSelectedItem(null);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
  };

  const handleUpdateItem = () => {
    let list = [];

    for (const attendee of value) {
      if (attendee.id !== selectedItem.id) {
        list.push({ ...attendee });
      } else {
        list.push({ ...fields });
      }
    }

    handleChange(list);
    setSelectedItem(null);
  };

  const handleDeleteItem = () => {
    if (!selectedItem) return;

    setEditMode("delete");

    let list = [];

    for (const attendee of value) {
      if (attendee.id !== selectedItem.id) {
        list.push({ ...attendee });
        continue;
      }
    }
    setSelectedItem(null);

    handleChange(list);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const attendee of value) {
        if (attendee.id !== selectedItem.id) {
          list.push({ ...attendee });
          continue;
        }
      }

      handleChange(list);
      setSelectedItem(null);
    }
  };

  const handleChange = (list) => {
    setEditMode("add");
    onChange(list);
    setFields({ ...fieldsTemplate, entityName: fields.entityName });
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  const sortedList = () => {
    if (!Array.isArray(entityList) || entityList.length === 0) {
      return [];
    }

    let sorted = [];

    let list = entityList.map((entity) => {
      return { value: entity.id, label: entity.name };
    });

    sorted = sort(list, "label");

    return sorted;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box
        id={id}
        sx={{ padding: "1rem" }}
      >
        <Box>
          <Typography
            variant="h5"
            align="center"
          >
            {getLabel(label)}
          </Typography>
        </Box>

        <Stack
          sx={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          gap={1}
        >
          <OFDSelect
            id="entity"
            label="attendeeType"
            value={getFieldValue("entityName")}
            onChange={(newValue) => updateFieldValue("entityName", newValue)}
            listName="attendeeModels"
            variant="outlined"
            disabled={isDisabled()}
            fullWidth
          />

          <OFDSelect
            id="entityList"
            label={fields.entityName || "Member"}
            value={getFieldValue("id")}
            onChange={(newValue) => updateFieldValue("id", newValue)}
            variant="outlined"
            options={sortedList()}
            disabled={isDisabled()}
            fullWidth
          />

          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <OFDTextNote
              id="notes"
              label="notes"
              value={getFieldValue("notes")}
              onChange={(newValue) => updateFieldValue("notes", newValue)}
              disabled={isDisabled()}
              fullWidth
            />
          </Box>
        </Stack>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <OFDButton
              variant="outlined"
              size="small"
              onClick={handleCancel}
              label={getLabel("cancel")}
            ></OFDButton>
            <OFDButton
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
              label={getLabel("delete")}
            ></OFDButton>
            <OFDButton
              variant="contained"
              size="small"
              onClick={selectedItem ? handleUpdateItem : handleAddItem}
              label={selectedItem ? getLabel("update") : getLabel("add")}
            ></OFDButton>
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem", marginBottom: ".5rem" }} />

        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          <Stack spacing={1}>
            {value?.map((attendee) => (
              <Box
                key={attendee.id}
                sx={{ display: "flex" }}
                gap={1}
              >
                <Box sx={{ width: "100%" }}>
                  <OFDDisplayAttendee
                    attendee={attendee}
                    onClick={handleEditItem}
                  />
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default OFDAttendees;
