import React from "react";

import { Box, Typography } from "@mui/material";

import Budgets from "../../../../assets/tools/Budgets.png";
import Calendar from "../../../../assets/tools/Calendar.png";
import Chores from "../../../../assets/tools/Chores.png";
import Contacts from "../../../../assets/tools/Contacts.png";
import Documents from "../../../../assets/tools/Documents.png";
import Expenses from "../../../../assets/tools/Expenses.png";
import Family from "../../../../assets/tools/Family.png";
import Groceries from "../../../../assets/tools/Groceries.png";
import Homework from "../../../../assets/tools/Homework.png";
import MealPlans from "../../../../assets/tools/MealPlans.png";
import Notes from "../../../../assets/tools/Notes.png";
import Passwords from "../../../../assets/tools/Passwords.png";
import Recipes from "../../../../assets/tools/Recipes.png";
import Reminders from "../../../../assets/tools/Reminders.png";
import Subscriptions from "../../../../assets/tools/Subscriptions.png";
import ToDo from "../../../../assets/tools/ToDo.png";
import Trips from "../../../../assets/tools/Trips.png";
import Vehicles from "../../../../assets/tools/Vehicles.png";

const ToolView = ({ toolInfo }) => {
  const getImage = () => {
    switch (toolInfo.image) {
      case "Budgets":
        return Budgets;

      case "Calendar":
        return Calendar;

      case "Chores":
        return Chores;

      case "Contacts":
        return Contacts;

      case "Documents":
        return Documents;

      case "Expenses":
        return Expenses;

      case "Family":
        return Family;

      case "Groceries":
        return Groceries;

      case "Homework":
        return Homework;

      case "MealPlans":
        return MealPlans;

      case "Notes":
        return Notes;

      case "Passwords":
        return Passwords;

      case "Recipes":
        return Recipes;

      case "Reminders":
        return Reminders;

      case "Subscriptions":
        return Subscriptions;

      case "ToDo":
        return ToDo;

      case "Trips":
        return Trips;

      case "Vehicles":
        return Vehicles;
    }
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between" }}
      gap={1}
    >
      <Box sx={{ width: "50px" }}>
        <img
          alt={toolInfo.label}
          src={getImage()}
          style={{
            width: "50px",
            height: "auto",
          }}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 900 }}>
          {toolInfo.label}
        </Typography>
        <Typography sx={{ fontSize: "11px" }}>{toolInfo.summary}</Typography>
      </Box>
    </Box>
  );
};

export default ToolView;
