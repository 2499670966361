import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

const FolderIntro = ({ entityName }) => {
  const { getWizard } = useLocalization();

  return (
    <Box>
      <Stack spacing={2}>
        {getWizard("folder").folderIntro.map((text, index) => (
          <Typography key={index}>
            {text.replace("{entityName}", entityName)}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default FolderIntro;
