import React from "react";

import useColor from "../../hooks/useColor";

import { IconButton } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDIconButton = ({ id, name, onClick, color, shade, size }) => {
  const { getColor } = useColor();

  return (
    <IconButton
      id={id}
      onClick={onClick}
      sx={{
        border: `1px solid ${
          getColor({
            color: color ? color : "grey",
            shade: shade ? shade : 500,
          }).backgroundColor
        }`,
        cursor: "pointer",
      }}
    >
      <OFDIcon
        name={name || "question"}
        color={color || "grey"}
        shade={shade || 500}
        size={size || null}
        // onClick={onClick}
      />
    </IconButton>
  );
};

export default OFDIconButton;
