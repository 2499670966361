import React from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDFileUpload from "../ui/OFDFileUpload";

const DocumentUpload = ({
  open,
  onClose,
  folderId,
  source,
  onUpload,
  entityName,
}) => {
  const { getTitle } = useLocalization();

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={getTitle("documentUpload")}
      >
        <Box sx={{ padding: "1rem" }}>
          <OFDFileUpload
            folderId={folderId || null}
            source={source || null}
            onUpload={onUpload}
            entityName={entityName}
          />
        </Box>
      </OFDModal>
    </>
  );
};

export default DocumentUpload;
