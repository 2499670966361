import { useEffect } from "react";
import { format } from "date-fns";

import { Box, Typography } from "@mui/material";

import OFDDisplayHtml from "../ui/OFDDisplayHtml";

const ChildAppointment = ({ appointment }) => {
  return (
    <Box
      sx={{
        padding: ".5rem",
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid #ccc",
        borderTop: "1px solid #ccc",
      }}
    >
      <Typography variant="h6">
        {`${format(appointment.startDate, "h:mm a")} - ${format(
          appointment.endDate,
          "h:mm a"
        )}`}
      </Typography>

      <Typography sx={{ fontWeight: 700 }}>{appointment.name}</Typography>

      {appointment.description ? (
        <OFDDisplayHtml value={appointment.description} />
      ) : null}
    </Box>
  );
};

export default ChildAppointment;
