import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useApi from "../../hooks/useApi";
import useFamily from "../../hooks/useFamily";
import useSecurity from "../../hooks/useSecurity";
import useData from "../../hooks/useData";

import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const OFDBudgetItem = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  fullWidth,
  variant = "standard",
  security,
  entryAddedBy,
  isIncome,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();
  const { family } = useFamily();
  const { getData } = useApi();
  const { sortByFields } = useData();

  const [budgets, setBudgets] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    getBudgets();
  }, []);

  useEffect(() => {
    buildOptions();
  }, [value]);

  const getBudgets = async () => {
    const results = await getData({
      entityName: "Budget",
      action: "get",
      filter: {
        familyId: family.id,
      },
    });

    if (results.isSuccessful) {
      setBudgets(results.data);
    } else {
      setBudgets(null);
    }
  };

  useEffect(() => {
    buildOptions();
  }, [budgets]);

  const buildOptions = () => {
    if (!budgets) return;

    let budgetOptions = [
      <option
        key="none"
        value=""
      ></option>,
    ];

    for (const budget of budgets) {
      let sortedItems = sortByFields(budget.budgetItems, ["type", "name"]);
      let budgetItems = [];
      for (const budgetItem of sortedItems) {
        if (
          isIncome &&
          budgetItem.budgetType &&
          budgetItem.budgetType.trim() !== "income"
        ) {
          continue;
        }
        if (
          !isIncome &&
          budgetItem.budgetType &&
          budgetItem.budgetType.trim() === "income"
        ) {
          continue;
        }
        budgetItems.push(
          <option
            key={budgetItem.id}
            value={`${budget.id}_${budgetItem.id}`}
          >
            {`${budgetItem.type ? `${budgetItem.type}/` : ""}${
              budgetItem.name
            }`}
          </option>
        );
      }
      if (budgetItems.length > 0) {
        budgetOptions.push(
          <optgroup
            key={budget.id}
            label={budget.name}
          >
            {budgetItems}
          </optgroup>
        );
      }
    }

    setOptions(budgetOptions);
  };

  const getValue = () => {
    if (!value) return "";

    // value is an object
    // {budgetId, budgetItemId}
    return `${value.budgetId}_${value.budgetItemId}`;
  };

  const handleChange = (newValue) => {
    if (newValue.indexOf("_") === -1) {
      onChange(null);
      return;
    }

    const valueArray = newValue.split("_");
    onChange({
      budgetId: valueArray[0],
      budgetItemId: valueArray[1],
      budgetType: getBudgetItemType(valueArray[0], valueArray[1]),
    });
  };

  const getBudgetItemType = (budgetId, budgetItemId) => {
    let type = "expense";

    const budget = budgets.find((b) => b.id === budgetId);

    if (budget) {
      const budgetItem = budget.budgetItems.find(
        (bi) => bi.id === budgetItemId
      );

      if (budgetItem && budgetItem.budgetType) {
        type = budgetItem.budgetType;
      }
    }

    return type;
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;
  if (!options) return;

  return (
    <>
      <FormControl
        id={id}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled}
        required={required}
      >
        <InputLabel id={`${id}-label`}>{getLabel(label)}</InputLabel>

        <Select
          native
          size="small"
          label={getLabel(label)}
          value={getValue()}
          onChange={(e) => handleChange(e.target.value)}
          error={error || false}
          disabled={isDisabled()}
        >
          {options}
        </Select>

        <FormHelperText error={error || false}>
          {message ? getMessage(message) : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default OFDBudgetItem;
