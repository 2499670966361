import { useEffect, useState } from "react";
import { format } from "date-fns";

import useApi from "../../../../hooks/useApi";
import useColor from "../../../../hooks/useColor";
import useWindow from "../../../../hooks/useWindow";
import useMember from "../../../../hooks/useMember";
import useHomework from "../../../../hooks/useHomework";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../../ui/OFDIcon";
import OFDModal from "../../OFDModal";
import OFDDisplayHtml from "../../../ui/OFDDisplayHtml";
import OFDNoteEditor from "../../../ui/OFDNoteEditor";
import OFDCollapsable from "../../OFDCollapsable";
import OFDDisplayField from "../../../ui/OFDDisplayField";
import DocumentDisplay from "../../../display/DocumentDisplay";
import DocumentViewer from "../../../display/DocumentViewer";

const Homework = ({ homework, onChange }) => {
  const { getLabel, getListItemLabel } = useLocalization();
  const { updateHomework, apiResult } = useHomework();
  const { getMemberColor } = useMember();
  const { windowHeight } = useWindow();
  const { getColor } = useColor();
  const { getData } = useApi();

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [notes, setNotes] = useState(homework.notes || null);
  const [openFolders, setOpenFolders] = useState([]);

  const [documents, setDocuments] = useState([]);
  const [displayDocument, setDisplayDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    getDocuments();
  }, [homework]);

  const getDocuments = async () => {
    const filter = {
      source: {
        model: "Homework",
        id: homework.id,
      },
    };

    const results = await getData({
      entityName: "Document",
      action: "get",
      filter,
    });

    if (results.isSuccessful) {
      setDocuments(results.data);
    }
  };

  const saveHomework = async () => {
    let data = {
      notes,
    };

    const results = await updateHomework(homework.id, data);

    if (results.isSuccessful) {
      setOpenEditor(false);
      if (onChange) onChange();
    }
  };

  const handleDocumentSelect = (document) => {
    setSelectedDocument(document);
    setDisplayDocument(true);
  };

  const handleCloseDocument = () => {
    setSelectedDocument(null);
    setDisplayDocument(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "4px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
            gap={1}
          >
            <OFDIcon
              name="homework"
              color={getMemberColor(homework.memberId)}
            />
            <Box sx={{ maxWidth: "200px" }}>
              <Typography sx={{ fontWeight: 900, whiteSpace: "wrap" }}>
                {homework.name}
              </Typography>
              <Typography>{format(homework.dueDate, "EEE, MMM dd")}</Typography>
            </Box>
          </Box>

          <OFDIcon
            name="openPage"
            color="grey"
            onClick={() => setOpen(!open)}
          />
        </Box>
      </Box>

      {open ? (
        <OFDModal
          open={open}
          onClose={() => setOpen(false)}
          color={getMemberColor(homework.memberId)}
          title={homework.name}
        >
          <Box
            sx={{
              backgroundColor: "#e0e0e0",
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            <Stack
              spacing={1}
              sx={{ padding: "1rem" }}
            >
              {homework.course ? (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h4">{`${getLabel(
                      "course"
                    )} :`}</Typography>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h4">{`${homework.course}`}</Typography>
                  </Box>
                </Box>
              ) : null}

              {homework.teacher ? (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h5">{`${getLabel(
                      "teacher"
                    )} : `}</Typography>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h5">{` ${homework.teacher}`}</Typography>
                  </Box>
                </Box>
              ) : null}

              {homework.dueDate ? (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h5">
                      {`${getLabel("dueDate")} :`}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h5">
                      {` ${format(homework.dueDate, "EEE, MMM dd")}`}
                    </Typography>
                  </Box>
                </Box>
              ) : null}

              {homework.homeworkType ? (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h5">{`${getLabel(
                      "homeworkType"
                    )} : `}</Typography>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h5">{` ${getListItemLabel(
                      "homeworkType",
                      homework.homeworkType
                    )}`}</Typography>
                  </Box>
                </Box>
              ) : null}

              {homework.percentOfFinal ? (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h5">{`${getLabel(
                      "percentOfFinal"
                    )} : `}</Typography>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography variant="h5">{` ${homework.percentOfFinal}`}</Typography>
                  </Box>
                </Box>
              ) : null}

              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  padding: "1rem",
                  borderRadius: "1rem",
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                >
                  {getLabel("description")}
                </Typography>
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                  {homework.description}
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  padding: "1rem",
                  borderRadius: "1rem",
                  position: "relative",
                }}
              >
                <Box sx={{ position: "absolute", top: "20px", right: "20px" }}>
                  <OFDIcon
                    name="openPage"
                    color={getMemberColor(homework.memberId)}
                    onClick={() => setOpenEditor(true)}
                  />
                </Box>
                <Typography
                  variant="h5"
                  align="center"
                >
                  {getLabel("notes")}
                </Typography>
                <OFDDisplayHtml value={homework.notes} />
              </Box>

              {homework.folders && homework.folders.length > 0 ? (
                <Stack>
                  {homework.folders.map((folder) => (
                    <OFDCollapsable header={folder.name}>
                      <Stack>
                        {folder.fields.map((field) => (
                          <OFDDisplayField
                            fieldType={field.fieldType}
                            fieldProps={{
                              id: field.id,
                              label: field.label,
                              value: field.value,
                              security: field.security,
                              entity: folder,
                            }}
                          />
                        ))}
                      </Stack>
                    </OFDCollapsable>
                  ))}
                </Stack>
              ) : null}

              {Array.isArray(documents) && documents.length > 0 ? (
                <OFDCollapsable header={getLabel("documents")}>
                  <Stack>
                    {documents.map((document) => (
                      <DocumentDisplay
                        key={document.id}
                        document={document}
                        onClick={handleDocumentSelect}
                      />
                    ))}
                  </Stack>
                </OFDCollapsable>
              ) : null}
            </Stack>
          </Box>
        </OFDModal>
      ) : null}

      {openEditor ? (
        <Modal
          open={openEditor}
          onClose={() => setOpenEditor(false)}
        >
          <Box
            sx={{
              backgroundColor: "#f0f0f0",
              width: "100%",
              height: `${windowHeight}px`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "4rem",
                padding: "1rem",
              }}
            >
              <Box
                sx={{
                  padding: "4px",
                  backgroundColor:
                    theme.palette[getMemberColor(homework.memberId)].main,
                  borderRadius: "50%",
                  width: "32px",
                  height: "32px",
                }}
              >
                <OFDIcon
                  name="close"
                  onClick={() => setOpenEditor(false)}
                  color="grey"
                  shade={50}
                />
              </Box>
            </Box>

            <Box
              sx={{
                padding: "1rem",
                backgroundColor: "#ffffff",
                height: `${windowHeight - 128}px`,
                borderRadius: "1rem",
                marginLeft: "1rem",
                marginRight: "1rem",
                overflow: "auto",
              }}
            >
              <OFDNoteEditor
                value={notes}
                onChange={(newValue) => {
                  setNotes(newValue);
                }}
              />
            </Box>

            <Box
              sx={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                padding: "1rem",
              }}
            >
              <Button
                color={getMemberColor(homework.memberId)}
                variant="contained"
                onClick={saveHomework}
              >
                {getLabel("save")}
              </Button>
            </Box>
          </Box>
        </Modal>
      ) : null}

      {displayDocument ? (
        <DocumentViewer
          document={selectedDocument}
          open={displayDocument}
          onClose={handleCloseDocument}
        />
      ) : null}
    </>
  );
};

export default Homework;
