import { useEffect, useState } from "react";

import useWindow from "../../../../hooks/useWindow";
import useGrocery from "../../../../hooks/useGrocery";

import { Box, Modal, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../../ui/OFDIcon";
import OFDDate from "../../../ui/OFDDate";
import OFDGroceryItems from "../../../ui/OFDGroceryItems";
import OFDToolbar from "../../OFDToolbar";
import OFDDialog from "../../OFDDialog";

const GroceryEditor = ({ groceryList, open, onClose, onUpdated }) => {
  const { updateGrocery } = useGrocery();
  const { windowWidth, windowHeight } = useWindow();
  const theme = useTheme();

  const [shoppingDate, setShoppingDate] = useState();
  const [groceryItems, setGroceryItems] = useState();
  const [changesMade, setChangesMade] = useState(false);
  const [openCancelChanges, setOpenCancelChanges] = useState(false);

  useEffect(() => {
    if (!groceryList) return;

    initializeFields();
  }, [groceryList]);

  useEffect(() => {
    if (!shoppingDate && !groceryItems) {
      return;
    }

    setChangesMade(true);
  }, [shoppingDate, groceryItems]);

  const initializeFields = () => {
    if (groceryList.shoppingDate) {
      setShoppingDate(groceryList.shoppingDate);
    } else {
      setShoppingDate(null);
    }

    if (Array.isArray(groceryList.groceryItems)) {
      setGroceryItems(groceryList.groceryItems);
    } else {
      setGroceryItems([]);
    }

    setChangesMade(false);
  };

  const handleChange = (newValue) => {
    setGroceryItems(newValue);
  };

  const handleClose = () => {
    if (changesMade) {
      setOpenCancelChanges(true);
    } else {
      onClose();
    }
  };

  const handleMenuItemClick = async (name) => {
    if (name === "save") {
      await save();
    }
  };

  const closeConfirmation = (response) => {
    setOpenCancelChanges(false);

    if (response === "yes") {
      initializeFields();
      onClose();
    }
  };

  const save = async () => {
    const results = await updateGrocery({
      id: groceryList.id,
      shoppingDate,
      groceryItems,
    });

    if (results.isSuccessful) {
      onUpdated();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            width: windowWidth,
            height: windowHeight,
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
          }}
        >
          <Box
            sx={{
              width: "400px",
              height: `${windowHeight - 100}px`,
              backgroundColor: "#ffffff",
              borderRadius: "1rem",
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderTopLeftRadius: "1rem",
                borderTopRightRadius: "1rem",
                height: "60px",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ display: "flex" }}
                gap={1}
              >
                <OFDIcon
                  name="grocery"
                  color="grey"
                  shade={50}
                />
                <Typography variant="h5">{groceryList.name}</Typography>
              </Box>

              <Box
                sx={{
                  border: "1px solid #fff",
                  padding: "4px",
                  borderRadius: "50%",
                }}
                onClick={handleClose}
              >
                <OFDIcon
                  name="close"
                  color="grey"
                  shade={50}
                  onClick={handleClose}
                />
              </Box>
            </Box>

            <OFDToolbar
              menuItems={[{ name: "save", label: "save", icon: "save" }]}
              onClick={handleMenuItemClick}
            />

            <Box
              sx={{
                height: `${windowHeight - 230}px`,
                overflow: "auto",
                padding: ".5rem",
                paddingTop: "1rem",
              }}
            >
              <OFDDate
                label="shoppingDate"
                value={shoppingDate}
                onChange={(newValue) => setShoppingDate(newValue)}
                color="orange"
              />

              <OFDGroceryItems
                label="groceryItems"
                value={groceryItems}
                onChange={handleChange}
                color="orange"
              />
            </Box>
          </Box>
        </Box>
      </Modal>

      <OFDDialog
        open={openCancelChanges}
        title="cancelChanges"
        description="cancelChanges"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={closeConfirmation}
      />
    </>
  );
};

export default GroceryEditor;
