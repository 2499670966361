import { formatPhoneNumber } from "react-phone-number-input";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDDisplayPhone = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}>
      <Box
        onClick={onClick}
        sx={{ width: "100%" }}
      >
        <Typography variant="overline">{getLabel(label)}</Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography noWrap>
            {value ? formatPhoneNumber(value) : "-"}
          </Typography>
        </Box>
      </Box>

      {value ? (
        <Box>
          <a href={`tel:${value}`}>
            <OFDIcon name="phone" />
          </a>
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDDisplayPhone;
