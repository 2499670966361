import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Chip } from "@mui/material";

const OFDDisplayMultiSelect = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    onClick();
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={handleClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {value?.map((item) => (
          <Chip
            key={item}
            label={item}
          />
        ))}
      </Box>
    </Box>
  );
};

export default OFDDisplayMultiSelect;
