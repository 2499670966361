import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDIcon from "../ui/OFDIcon";
import OFDTitle from "../layout/OFDTitle";
import OFDToolbar from "../layout/OFDToolbar";
import OFDMemberPicker from "../ui/OFDMemberPicker";
import OFDFieldContainer from "../ui/OFDFieldContainer";

const ChorePreferences = ({ preferences, open, onClose, onChange }) => {
  const { getLabel } = useLocalization();

  const [updatedPreferences, setUpdatedPreferences] = useState();

  useEffect(() => {
    setUpdatedPreferences(preferences);
  }, [preferences]);

  const handleToolbarClick = (action) => {
    if (action === "save") {
      onChange(updatedPreferences);
    }
  };

  const getValue = (field) => {
    if (!updatedPreferences) return null;

    return updatedPreferences[field];
  };

  const setValue = (field, value) => {
    setUpdatedPreferences((current) => {
      if (!current) return { [field]: value };

      return {
        ...current,
        [field]: value,
      };
    });
  };

  if (!module) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={getLabel("chores")}
      >
        <OFDToolbar
          menuItems={[{ name: "save", icon: "save", label: "save" }]}
          onClick={handleToolbarClick}
        />

        <OFDFieldContainer sx={{ margin: "1rem" }}>
          <Stack
            spacing={2}
            sx={{ margin: "1rem" }}
          >
            <OFDMemberPicker
              id="approvers"
              label="approvers"
              value={getValue("approvers")}
              onChange={(newValue) => setValue("approvers", newValue)}
            />
          </Stack>
        </OFDFieldContainer>
      </OFDModal>
    </>
  );
};

export default ChorePreferences;
