import { useEffect, useState } from "react";

import useColor from "../../hooks/useColor";
import useBudget from "../../hooks/useBudget";
import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

const OFDDisplayBudgetItem = ({ value, styleoverride, onClick, noLabel }) => {
  const { calendarLists, getList, getLabel, displayLocalCurrency } =
    useLocalization();
  const { getBudgetItem } = useBudget();
  const { getColor } = useColor();

  const [frequency, setFrequency] = useState("");
  const [months, setMonths] = useState(null);
  const [budgetItem, setBudgetItem] = useState();

  useEffect(() => {
    getBudgetItemData();
  }, [value]);

  const getBudgetItemData = async () => {
    if (!value) {
      setBudgetItem(null);
      return;
    }

    if ("budgetId" in value) {
      const results = await getBudgetItem(value);
      setBudgetItem(results);
    } else {
      setBudgetItem(value);
    }
  };

  useEffect(() => {
    if (!budgetItem) return;

    const budgetFrequencies = getList("budgetFrequency");
    const budgetFrequency = budgetFrequencies.find(
      (freq) => freq.value === budgetItem.budgetFrequency
    );
    if (!budgetFrequency) return;
    setFrequency(budgetFrequency.alt);

    if (budgetItem.budgetFrequency === "specified") {
      let monthNames = [];

      for (let x = 0; x < budgetItem.months.length; x++) {
        if (budgetItem.months[x]) {
          let month = calendarLists.months.find((mth) => mth.value === x);
          monthNames.push(month.short);
        }
      }

      setMonths(monthNames.join(", "));
    }
  }, [budgetItem]);

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Box
      sx={{
        paddingLeft: ".5rem",
        paddingBottom: noLabel ? "4px" : ".5rem",
        width: "100%",
        borderBottom: noLabel ? "none" : "1px solid grey",
        ...styleoverride,
      }}
      onClick={handleClick}
    >
      {!noLabel ? (
        <Typography variant="overline">{getLabel("budgetItem")}</Typography>
      ) : null}

      {budgetItem ? (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography>{`${budgetItem.type ? budgetItem.type + "/" : ""}${
              budgetItem.name
            } `}</Typography>
          </Box>

          <Box>
            <Typography>{`${displayLocalCurrency(
              budgetItem.budgetAmount,
              0
            )}${frequency}${months ? months : ""}`}</Typography>
          </Box>
        </Box>
      ) : (
        <Typography>-</Typography>
      )}
    </Box>
  );
};

export default OFDDisplayBudgetItem;
