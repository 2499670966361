const useMessage = () => {
  const destructureResults = (results) => {
    if (!results) return null;

    return {
      isSuccessful: results.isSuccessful,
      displayToUser: results.displayToUser,
      message: results.message,
      severity: results.severity,
    };
  };

  const newError = (message) => {
    return {
      isSuccessful: false,
      displayToUser: true,
      message: message,
      severity: "error",
    };
  };

  const newSuccess = (message, displayToUser = false) => {
    return {
      isSuccessful: true,
      displayToUser: displayToUser,
      message: message ? message : "ok",
      severity: "info",
    };
  };

  return {
    destructureResults,
    newError,
    newSuccess,
  };
};

export default useMessage;
