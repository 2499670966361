import { useEffect, useState } from "react";
import { format, subMinutes } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";
import useMember from "../../hooks/useMember";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import BudgetItemExpenses from "./BudgetItemExpenses";
import ExpenseDetails from "./ExpenseDetails";
import Loading from "../layout/Loading";

const ExpensesForBudget = ({ year, budget, onChange, expenses }) => {
  const { displayLocalCurrency, getLabel } = useLocalization();
  const { memberColor } = useMember();
  const { sortByFields } = useData();

  const [openDetails, setOpenDetails] = useState(false);
  const [selectedBudgetItem, setSelectedBudgetItem] = useState();
  const [budgetItemExpenses, setBudgetItemExpenses] = useState(null);
  const [sortedItems, setSortedItems] = useState([]);
  const [incomeItems, setIncomeItems] = useState([]);
  const [expenseItems, setExpenseItems] = useState([]);

  useEffect(() => {
    if (!budget || !Array.isArray(budget.budgetItems)) return;

    console.log({ budget });

    setIncomeItems(() => {
      let items = budget.budgetItems.filter(
        (item) => item.budgetType === "income"
      );

      items = sortByFields(items, ["budgetTotal"]).reverse();

      return items;
    });

    setExpenseItems(() => {
      let items = budget.budgetItems.filter(
        (item) => item.budgetType === "expense"
      );

      items = sortByFields(items, ["budgetTotal"]).reverse();

      return items;
    });

    setSortedItems(
      sortByFields(budget.budgetItems, ["budgetItemType", "budgetItemName"])
    );
  }, [budget]);

  const handleClick = (budgetItem) => {
    setSelectedBudgetItem(budgetItem);

    let budgetExpenses = [];
    if (Array.isArray(expenses)) {
      budgetExpenses = expenses.filter(
        (expense) => expense.budgetItem.budgetItemId === budgetItem.budgetItemId
      );
    }
    setBudgetItemExpenses(budgetExpenses);
    setOpenDetails(true);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: ".5rem",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            ...memberColor({ shade: 700 }),
          }}
        >
          <Box>
            <Typography variant="h5">{`${budget.budgetName}`}</Typography>
            {budget.toDate ? (
              <Typography variant="caption">{`${getLabel("to")} ${format(
                budget.toDate,
                "MMM dd, yyyy"
              )}`}</Typography>
            ) : null}
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            display: "flex",
            padding: ".5rem",
            justifyContent: "space-between",
          }}
          gap={1}
        >
          <Box
            sx={{
              width: "33%",
            }}
          >
            <Box
              sx={{
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                ...memberColor({ shade: 700 }),
                border: `1px solid ${
                  memberColor({ shade: 700 }).backgroundColor
                }`,
              }}
            >
              <Typography align="center">{getLabel("income")}</Typography>
            </Box>
            <Box
              sx={{
                padding: ".5rem",
                border: `1px solid ${
                  memberColor({ shade: 700 }).backgroundColor
                }`,
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <Typography align="center">{`${displayLocalCurrency(
                budget.incomeTotal,
                0
              )}`}</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "33%",
            }}
          >
            <Box
              sx={{
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                ...memberColor({ shade: 700 }),
                border: `1px solid ${
                  memberColor({ shade: 700 }).backgroundColor
                }`,
              }}
            >
              <Typography align="center">{getLabel("expenses")}</Typography>
            </Box>
            <Box
              sx={{
                padding: ".5rem",
                border: `1px solid ${
                  memberColor({ shade: 700 }).backgroundColor
                }`,
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <Typography align="center">{`${displayLocalCurrency(
                budget.budgetTotal,
                0
              )}`}</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "33%",
            }}
          >
            <Box
              sx={{
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                ...memberColor({ shade: 700 }),
                border: `1px solid ${
                  memberColor({ shade: 700 }).backgroundColor
                }`,
              }}
            >
              <Typography align="center">{getLabel("spend")}</Typography>
            </Box>
            <Box
              sx={{
                padding: ".5rem",
                border: `1px solid ${
                  memberColor({ shade: 700 }).backgroundColor
                }`,
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <Typography align="center">{`${displayLocalCurrency(
                budget.expenseTotal,
                0
              )}`}</Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <Typography
            variant="h6"
            align="center"
          >
            Income
          </Typography>
          <Grid
            container
            spacing={2}
          >
            {incomeItems.map((budgetItem) => (
              <Grid
                key={budgetItem.budgetItemId}
                mobile={4}
              >
                <BudgetItemExpenses
                  budgetItem={budgetItem}
                  onClick={handleClick}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <Typography
            variant="h6"
            align="center"
          >
            Expenses
          </Typography>
          <Grid
            container
            spacing={2}
          >
            {expenseItems?.map((budgetItem) => (
              <Grid
                key={budgetItem.budgetItemId}
                mobile={4}
              >
                <BudgetItemExpenses
                  budgetItem={budgetItem}
                  onClick={handleClick}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {openDetails ? (
        <ExpenseDetails
          year={year}
          expenses={budgetItemExpenses}
          open={openDetails}
          onClose={() => setOpenDetails(false)}
          onChange={onChange}
          title={`${selectedBudgetItem.budgetItemType} / ${selectedBudgetItem.budgetItemName}`}
        />
      ) : null}
    </>
  );
};

export default ExpensesForBudget;
