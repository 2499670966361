import { Box, Stack, Typography } from "@mui/material";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useBloodPressure from "../../hooks/useBloodPressure";
import useColor from "../../hooks/useColor";
import useSecurity from "../../hooks/useSecurity";

import OFDDisplayNote from "./OFDDisplayNote";

import OFDCollapsable from "../layout/OFDCollapsable";
import OFDDisplayBPItem from "./OFDDisplayBPItem";

const OFDDisplayBloodPressure = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { bpColor } = useBloodPressure();
  const { getColor } = useColor();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const contentStyle = {
    maxHeight: "200px",
    overflow: "auto",
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={onClick}
    >
      <Typography
        variant="overline"
        onClick={onClick}
      >
        {getLabel(label)}
      </Typography>

      <Box sx={contentStyle}>
        <Stack spacing={0.5}>
          {value?.map((bp) => (
            <OFDCollapsable
              key={bp.id}
              compressed
              header={
                <OFDDisplayBPItem
                  onClick={onClick}
                  bloodPressure={bp}
                />
              }
              styleOverride={{ ...getColor(bpColor(bp)) }}
              arrowColor="grey"
              arrowShade={50}
            >
              {bp.note ? (
                <OFDDisplayNote
                  value={bp.note}
                  textOnly={true}
                  styleoverride={{
                    fontSize: "12px",
                    color: "#535353",
                  }}
                />
              ) : null}
            </OFDCollapsable>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayBloodPressure;
