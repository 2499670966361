const CryptoJS = require("crypto-js");

const useCryptography = () => {
  const encrypt = (value) => {
    if (!value instanceof String) {
      return value;
    }

    if (!value || value.length === 0) {
      return value;
    }

    let hashedValue;

    try {
      hashedValue = CryptoJS.AES.encrypt(
        value,
        process.env.REACT_APP_LOCAL_KEY
      ).toString();

      return hashedValue;
    } catch (err) {
      return value;
    }
  };

  const decrypt = (hashedValue) => {
    let bytes;
    try {
      if (!hashedValue instanceof String) {
        return hashedValue;
      }

      if (hashedValue.length === 0) {
        return hashedValue;
      }

      bytes = CryptoJS.AES.decrypt(
        hashedValue,
        process.env.REACT_APP_LOCAL_KEY
      );

      let value = bytes.toString(CryptoJS.enc.Utf8);

      return value;
    } catch (err) {
      return hashedValue;
    }
  };

  return {
    encrypt,
    decrypt,
  };
};

export default useCryptography;
