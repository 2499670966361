import { useEffect, useState } from "react";

import useLocalization from "../../../hooks/useLocalization";
import useSetup from "../../../hooks/useSetup";
import useFamily from "../../../hooks/useFamily";
import useMember from "../../../hooks/useMember";
import useWindow from "../../../hooks/useWindow";

import { Box, Button, Typography } from "@mui/material";

import OFDModal from "../../layout/OFDModal";
import OFDButton from "../../ui/OFDButton";

import SetupIntro from "./SetupIntro";
import IntroVideo from "./IntroVideo";
import AvatarAndColor from "./AvatarAndColor";
import FamilyMembers from "./FamilyMembers";
import MemberAccounts from "./MemberAccounts";
import NotificationPreferences from "./NotificationPreferences";
import Final from "./Final";

const SetupWizard = ({ open, onClose }) => {
  const { getLabel } = useLocalization();
  const { updateFamilyFirstLogin } = useFamily();
  const { windowHeight, isMobile } = useWindow();
  const { member, updateMemberFirstLogin, updateMemberPreferences } =
    useMember();

  const {
    setupSteps,
    currentStep,
    setCurrentStep,
    nextStep,
    prevStep,
    isFirstStep,
    isLastStep,
  } = useSetup();

  const [preferences, setPreferences] = useState(null);

  useEffect(() => {
    if (!open) return;

    updateFirstLogin();
  }, [open]);

  useEffect(() => {
    setCurrentStep(setupSteps[0]);
  }, [open]);

  const updateFirstLogin = async () => {
    await updateFamilyFirstLogin();
    await updateMemberFirstLogin();
  };

  const handleChangeNotifications = async (updatedPreferences) => {
    setPreferences(updatedPreferences);
  };

  const handleNext = async () => {
    if (isLastStep()) {
      onClose();
      return;
    }
    if (currentStep.name === "notifications" && preferences) {
      await updateMemberPreferences(preferences);
    }
    nextStep();
  };

  const handlePrev = async () => {
    if (isFirstStep()) return;

    if (currentStep.name === "notifications" && preferences) {
      await updateMemberPreferences(preferences);
    }
    prevStep();
  };

  if (currentStep === undefined) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="setupWizard"
      >
        <Box sx={{ height: `${windowHeight - 64}px` }}>
          <Box
            sx={{
              padding: "1rem",
              height: isMobile ? "calc(100% - 3rem)" : "calc(100% - 7rem)",
              overflow: "auto",
            }}
          >
            <Box>{currentStep?.name === "intro" ? <SetupIntro /> : null}</Box>

            <Box>{currentStep?.name === "video" ? <IntroVideo /> : null}</Box>

            <Box>
              {currentStep?.name === "avatarAndColor" ? (
                <AvatarAndColor />
              ) : null}
            </Box>

            <Box>
              {currentStep?.name === "notifications" ? (
                <NotificationPreferences onChange={handleChangeNotifications} />
              ) : null}
            </Box>

            {member.securityLevel === "accountOwner" ? (
              <Box>
                <Box>
                  {currentStep?.name === "family" ? <FamilyMembers /> : null}
                </Box>

                <Box>
                  {currentStep?.name === "memberAccounts" ? (
                    <MemberAccounts />
                  ) : null}
                </Box>
              </Box>
            ) : null}

            <Box>{currentStep?.name === "final" ? <Final /> : null}</Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              left: "1rem",
              right: "1rem",
              height: "4rem",
              borderTop: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "1rem",
              paddingTop: ".5rem",
            }}
            gap={2}
          >
            <OFDButton
              id="back"
              variant="outlined"
              onClick={handlePrev}
              label={getLabel("back")}
              width="auto"
              color="grey"
              shade={900}
            />

            <OFDButton
              id="next"
              variant="contained"
              onClick={handleNext}
              color="grey"
              shade={900}
              label={getLabel("next")}
              width="auto"
            />
          </Box>
        </Box>
      </OFDModal>
    </>
  );
};

export default SetupWizard;
