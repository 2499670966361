import { useEffect, useState } from "react";

import { Box, FormControl } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import OFDSwitch from "./OFDSwitch";

const OFDTimeRange = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  const [allDay, setAllDay] = useState(false);

  useEffect(() => {
    setAllDay(value[2]);
  }, [value]);

  const getValue = (index) => {
    if (!Array.isArray(value) || value.length - 1 < index) return null;

    return value[index];
  };

  const handleChange = (index, newValue) => {
    let newTimeArray = Array.isArray(value) ? [...value] : [null, null, false];
    newTimeArray[index] = newValue;

    if (index === 2 && newValue === true) {
      // all day set to true
      newTimeArray[0] = null;
      newTimeArray[1] = null;
    }

    onChange(newTimeArray);
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box id={id}>
      {!allDay ? (
        <Box
          sx={{ display: "flex", justifyContent: "space-between" }}
          gap={2}
        >
          <FormControl
            fullWidth
            size="small"
          >
            <TimePicker
              size="small"
              label={getLabel("from")}
              value={getValue(0)}
              onChange={(newValue) => handleChange(0, newValue)}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "10px",
                },
                width: "100%",
              }}
              slotProps={{
                toolbar: {
                  sx: {
                    "& .MuiPickersToolbar-content": {
                      "& .MuiTimePickerToolbar-ampmSelection": {
                        "& .MuiButtonBase-root": {
                          padding: "2px",
                          "& .MuiTypography-root.MuiPickersToolbarText-root.Mui-selected":
                            {
                              backgroundColor: "#000000",
                              color: "#ffffff",
                              padding: "4px",
                              borderRadius: "50%",
                            },
                        },
                      },
                    },
                  },
                },
              }}
              disabled={isDisabled()}
            />
          </FormControl>

          <FormControl
            fullWidth
            size="small"
          >
            <TimePicker
              size="small"
              label={getLabel("to")}
              value={getValue(1)}
              onChange={(newValue) => handleChange(1, newValue)}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "10px",
                },
                width: "100%",
              }}
              slotProps={{
                toolbar: {
                  sx: {
                    "& .MuiPickersToolbar-content": {
                      "& .MuiTimePickerToolbar-ampmSelection": {
                        "& .MuiButtonBase-root": {
                          padding: "2px",
                          "& .MuiTypography-root.MuiPickersToolbarText-root.Mui-selected":
                            {
                              backgroundColor: "#000000",
                              color: "#ffffff",
                              padding: "4px",
                              borderRadius: "50%",
                            },
                        },
                      },
                    },
                  },
                },
              }}
              disabled={isDisabled()}
            />
          </FormControl>
        </Box>
      ) : null}

      <OFDSwitch
        id="allDay"
        value={allDay}
        label="allDay"
        onChange={(newValue) => handleChange(2, newValue)}
        disabled={isDisabled()}
      />
    </Box>
  );
};

export default OFDTimeRange;
