import { useEffect } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

const CalendarFrequencyDisplay = ({ entityData }) => {
  const { getLabel, calendarLists } = useLocalization();

  const weekdays = (dow) => {
    let list = [];

    for (let x = 0; x < 7; x++) {
      if (dow[x]) {
        let weekday = calendarLists.weekdays.find((wd) => wd.value === x);
        list.push(weekday.short);
      }
    }

    return list.join(", ");
  };

  const dates = () => {
    return entityData.dates.join(", ");
  };

  const days = () => {
    let list = [];

    if (Array.isArray(entityData.days)) {
      for (let x = 0; x < entityData.days.length; x++) {
        let item = entityData.days[x];
        list.push(
          <Typography
            key={item.week}
            variant="caption"
          >{`${getLabel(item.week)} : ${weekdays(item.dow)} ${
            x < entityData.days.length - 1 ? "| " : ""
          }`}</Typography>
        );
      }
    }

    return list;
  };

  return (
    <Box>
      {entityData.frequency === "onetime" ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">{`${getLabel("once")} : ${format(
            entityData.startDate,
            "MMM d, yyyy"
          )}`}</Typography>

          {entityData.fromTime || entityData.toTime ? (
            <Typography variant="caption">
              {getLabel("time")} :
              {entityData.fromTime
                ? format(entityData.fromTime, " h:mm a")
                : null}
              {entityData.toTime
                ? ` to ${format(entityData.toTime, "h:mm a")}`
                : null}
            </Typography>
          ) : null}
        </Box>
      ) : null}

      {entityData.frequency === "daily" ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">{`${getLabel("daily")} : ${format(
            entityData.startDate,
            "MMM d, yyyy"
          )} - ${
            entityData.endDate
              ? format(entityData.endDate, "MMM d, yyyy")
              : getLabel("noEnd")
          }`}</Typography>

          {entityData.fromTime || entityData.toTime ? (
            <Typography variant="caption">
              {getLabel("time")} :
              {entityData.fromTime
                ? format(entityData.fromTime, " h:mm a")
                : null}
              {entityData.toTime
                ? ` to ${format(entityData.toTime, "h:mm a")}`
                : null}
            </Typography>
          ) : null}
        </Box>
      ) : null}

      {entityData.frequency === "weekly" ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">{`${getLabel("weekly")} : ${format(
            entityData.startDate,
            "MMM d, yyyy"
          )} - ${
            entityData.endDate
              ? format(entityData.endDate, "MMM d, yyyy")
              : getLabel("noEnd")
          }`}</Typography>

          <Typography variant="caption">{`${getLabel("every")} : ${weekdays(
            entityData.dow
          )}`}</Typography>
        </Box>
      ) : null}

      {entityData.frequency === "monthly" ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">{`${getLabel("monthly")} : ${format(
            entityData.startDate,
            "MMM d, yyyy"
          )} - ${
            entityData.endDate
              ? format(entityData.endDate, "MMM d, yyyy")
              : getLabel("noEnd")
          }`}</Typography>

          {entityData.monthlyType === "specific" ? (
            <Typography variant="caption">{`${getLabel(
              "dates"
            )} : ${dates()}`}</Typography>
          ) : (
            <Box>{days()}</Box>
          )}
        </Box>
      ) : null}

      {entityData.frequency === "schedule" ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">{`${getLabel("schedule")} : ${format(
            entityData.startDate,
            "MMM d, yyyy"
          )} - ${format(entityData.endDate, "MMM d, yyyy")}`}</Typography>

          <Typography variant="caption">{`${
            entityData.schedule?.length
          } ${getLabel("daysSelected")}`}</Typography>
        </Box>
      ) : null}

      {entityData.frequency === "yearly" ? (
        <Box>
          <Typography variant="caption">{`${getLabel("yearly")} on ${format(
            entityData.startDate,
            "MMMM"
          )} ${format(entityData.startDate, "d")}`}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default CalendarFrequencyDisplay;
