import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import OFDAvatar from "../../ui/OFDAvatar";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const FamilyMember = ({ familyMember }) => {
  return (
    <>
      <OFDFieldContainer>
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          gap={2}
        >
          <OFDAvatar
            avatar={familyMember.avatar}
            name={familyMember.name}
            borderColor={familyMember.color}
          />
          <Typography variant="h6">{familyMember.name}</Typography>
        </Box>
      </OFDFieldContainer>
    </>
  );
};

export default FamilyMember;
