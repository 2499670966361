import React from "react";

import useLocalization from "../../../../hooks/useLocalization";

import { Box } from "@mui/material";

import DashboardMenuItem from "./DashboardMenuItem";

const DashboardMenuItems = ({ selectedMenuItem, onChange }) => {
  const { familyDashboard } = useLocalization();

  return (
    <Box
      sx={{
        display: "flex",
        align: "center",
      }}
      gap={4}
    >
      {familyDashboard.menuItems.map((menuItem) => (
        <DashboardMenuItem
          key={menuItem.id}
          id={menuItem.id}
          isSelected={menuItem.id === selectedMenuItem}
          onSelect={onChange}
        />
      ))}
    </Box>
  );
};

export default DashboardMenuItems;
