import { useEffect, useState } from "react";
import { format, parse, isEqual } from "date-fns";

import useLocalization from "../../../../hooks/useLocalization";
import useChore from "../../../../hooks/useChore";
import useMember from "../../../../hooks/useMember";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../../../ui/OFDIcon";

const MemberChore = ({ memberChore, choreList, onChange, forDate }) => {
  const { updateChoreActivity } = useChore();
  const { getMember } = useMember();

  const handleChoreStatusChange = async () => {
    let status = memberChore.activity.status;

    if (status === "approved") {
      status = "outstanding";
    } else {
      status = "approved";
    }

    let activity = {
      memberId: memberChore.memberId,
      choreId: memberChore.chore.id,
      activityId: memberChore.activity.activityId,
      status,
    };

    const results = await updateChoreActivity(activity);

    if (results.isSuccessful) {
      if (onChange) onChange(status);
    }
  };

  if (!memberChore) return;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "6px",
        }}
      >
        <Typography>{memberChore.chore.name}</Typography>
        <OFDIcon
          name={`chores-${memberChore.activity.status}`}
          onClick={handleChoreStatusChange}
          color={getMember(memberChore.memberId).color}
          size="medium"
        />
      </Box>
    </>
  );
};

export default MemberChore;
