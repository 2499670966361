import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signupActions } from "../store/signup";

import useApi from "./useApi";

const useSubscription = () => {
  const { postData } = useApi();
  const dispatch = useDispatch();

  const signupId = useSelector((state) => state.signup.signupId);
  const appUserId = useSelector((state) => state.signup.appUserId);

  const [offerings, setOfferings] = useState(null);
  const [packages, setPackages] = useState(null);

  useEffect(() => {
    if (!appUserId) return;

    getOfferings();
  }, [appUserId]);

  useEffect(() => {
    if (!offerings) return;
    setPackages(null);

    const currentOffering = offerings.offerings.find(
      (offer) => offer.identifier === offerings.current_offering_id
    );

    if (currentOffering) {
      setPackages(currentOffering.metadata.products);
    }
  }, [offerings]);

  const getOfferings = async () => {
    let url = `${process.env.REACT_APP_RCURL}/subscribers/${appUserId}/offerings`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_RCKEY}`,
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      setOfferings(data);
    }
  };

  const registerEmail = async (data) => {
    const results = await postData({
      entityName: "Signup",
      action: "registerEmail",
      data,
    });

    if (results.isSuccessful) {
      const signup = {
        signupId: results.data.signupId,
        email: data.email,
      };
      dispatch(signupActions.initializeSignup(signup));
    }

    return results;

    //  results.data = {
    //   hasName: false,
    //   isValidEmail: false,
    //   isValidPassword: false,
    //   doPasswordsMatch: false,
    //   alreadyRegistered: false,
    // };
  };

  const purchaseCancelled = (signupId, email) => {
    const signup = {
      signupId: signupId,
      email: email,
    };
    dispatch(signupActions.initializeSignup(signup));
  };

  const resendEmailVerification = async () => {
    const results = await postData({
      entityName: "Signup",
      action: "resendVerification",
      data: {
        signupId,
      },
    });

    return results;
  };

  const isEmailVerified = async () => {
    const results = await postData({
      entityName: "Signup",
      action: "isEmailVerified",
      data: {
        signupId,
      },
    });

    return results;
  };

  return {
    registerEmail,
    resendEmailVerification,
    isEmailVerified,
    signupId,
    appUserId,
    offerings,
    packages,
    purchaseCancelled,
  };
};

export default useSubscription;
