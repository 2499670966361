import { Box, Stack } from "@mui/material";

import useWindow from "../../hooks/useWindow";

import OFDDate from "./OFDDate";
import OFDTime from "./OFDTime";
import OFDDayOfWeek from "./OFDDayOfWeek";
import OFDFormControl from "./OFDFormControl";

const OFDWeeklySchedule = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  disabled,
}) => {
  const { isMobile } = useWindow();

  const handleChange = (field, newValue) => {
    onChange({
      ...value,
      [field]: newValue,
    });
  };

  return (
    <OFDFormControl
      label={label}
      message={message}
      error={error}
    >
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
          gap={1}
        >
          <Box>
            <OFDDate
              id="startDate"
              label="from"
              value={value.startDate || null}
              onChange={(newValue) => handleChange("startDate", newValue)}
            />
          </Box>
          <Box>
            <OFDDate
              id="endDate"
              label="to"
              value={value.endDate || null}
              onChange={(newValue) => handleChange("endDate", newValue)}
            />
          </Box>
        </Box>

        <Box>
          <OFDDayOfWeek
            label="weekDays"
            value={value.dow}
            onChange={(newValue) => handleChange("dow", newValue)}
            disabled={disabled}
          />
        </Box>
      </Stack>
    </OFDFormControl>
  );
};

export default OFDWeeklySchedule;
