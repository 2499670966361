import React from "react";

import useLocalization from "../../../hooks/useLocalization";
import useMember from "../../../hooks/useMember";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../ui/OFDIcon";

const MenuItem = ({ page, onSelect }) => {
  const { getLabel } = useLocalization();
  const { member } = useMember();

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => onSelect(page)}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: ".5rem",
          borderRadius: "8px",
          width: "2.5rem",
        }}
      >
        <OFDIcon
          name={page.icon}
          shade={page.subscribed ? null : 100}
          // color="#555555"
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: theme.palette[member?.color || "default"].mainContrast,
          }}
          align="center"
          noWrap
        >
          {getLabel(page.name)}
        </Typography>
      </Box>
    </Box>
  );
};

export default MenuItem;
