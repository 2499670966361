import { useEffect, useState, useRef } from "react";
import {
  differenceInCalendarDays,
  startOfToday,
  startOfDay,
  format,
} from "date-fns";

import useApi from "../../hooks/useApi";
import useFamily from "../../hooks/useFamily";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import usePage from "../../hooks/usePage";

import { Box, Divider, Stack, Typography } from "@mui/material";

import OFDButton from "../ui/OFDButton";
import Loading from "../layout/Loading";
import OFDDialog from "../layout/OFDDialog";

const ManageSubscription = () => {
  const { getLabel, getText, getSubscription, displayLocalCurrency } =
    useLocalization();
  const { memberColor, member } = useMember();
  const { family, refreshFamilyPreferences, deleteAllData } = useFamily();
  const { getData, postData } = useApi();
  const { refresh } = usePage();

  const [subscriptionData, setSubscriptionData] = useState(undefined);
  const [memberPricing, setMemberPricing] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const subscriptionLink = useRef();

  useEffect(() => {
    if (!family || !family.id) {
      refreshFamilyPreferences();
    }
  }, []);

  useEffect(() => {
    getSubscriptionData();
  }, [refresh, family]);

  const getSubscriptionData = async () => {
    if (!family || !family.id) return;

    const results = await getData({
      entityName: "Family",
      action: "getSubscriptionData",
      id: family.id,
    });

    if (results.isSuccessful) {
      setSubscriptionData(results.data);
      setMemberPricing(results.data.subscriptionFee);
      // getMemberPricing(results.data);
    } else {
      setSubscriptionData(null);
    }
  };

  const getMemberPricing = (data) => {
    const prices = Object.keys(data.pricing);

    for (const priceId of prices) {
      let price = data.pricing[priceId];

      if (price.name !== "additionalUsers") continue;

      if (price.frequency !== data.subscriptionFrequency) continue;

      setMemberPricing(price);
      break;
    }
  };

  const getStatus = () => {
    let status = "";

    if (subscriptionData.cancelledAt) {
      status = getLabel("cancelled");
    } else if (subscriptionData.status === "trial") {
      if (subscriptionData.trialEnd) {
        let daysRemaining = differenceInCalendarDays(
          startOfToday(),
          startOfDay(subscriptionData.trialEnd)
        );

        if (daysRemaining > 0) {
          status = getLabel("daysRemainingInTrial");
          status = status.replace("{days}", daysRemaining);
        } else if (daysRemaining === 0) {
          status = getLabel("lastDayOfTrial");
        } else {
          status = getLabel("active");
        }
      }
    } else {
      status = getLabel(subscriptionData.status);
    }

    return status;
  };

  const getTrialDates = () => {
    if (subscriptionData.status !== "trial") {
      return null;
    }

    let trialDates = `${getLabel("trialEnds")} ${format(
      subscriptionData.trialEnd,
      "EEE MMM dd, yyyy"
    )}`;

    return trialDates;
  };

  const handleSubscription = () => {
    subscriptionLink.current.click();
  };

  const handlePurchaseMembers = async () => {
    if (isNaN(quantity) || quantity < 1) {
      return;
    }
    // const lookupKey = `${selectedPlan.name}-${subscriptionFrequency}`;
    const results = await postData({
      entityName: "Family",
      action: "purchaseAdditionalMembers",
      data: {
        lookupKey: memberPricing.lookupKey,
        quantity,
      },
    });
    if (results.isSuccessful) {
      window.location.href = results.data.url;
    }
  };

  const getMemberFee = () => {
    let fee = displayLocalCurrency(memberPricing.fee / 100);
    let frequency = getLabel(memberPricing.frequency);
    let text = getLabel("additionalMembersCanBePurchased");
    let feeText = `${fee} ${frequency}`;

    text = text.replace("{price}", feeText);

    return text;
  };

  const handleDeleteResponse = async (response) => {
    setOpenDeleteDialog(false);
    if (response === "no") {
      return;
    } else if (response === "yes") {
      await deleteAllData();
    }
  };

  if (subscriptionData === undefined) return <Loading />;

  return (
    <>
      <Box>
        <Stack spacing={1}>
          <Typography
            variant="h5"
            align="center"
          >
            {subscriptionData.plan}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                padding: "8px",
                ...memberColor(),
                borderRadius: "8px",
              }}
            >
              <Typography>{getLabel(subscriptionData.status)}</Typography>
            </Box>
          </Box>

          {subscriptionData.status === "trial" ? (
            <Typography align="center">{getTrialDates()}</Typography>
          ) : null}

          <Typography
            align="center"
            variant="h6"
          >
            {`${displayLocalCurrency(
              subscriptionData.subscriptionFee
            )} ${getLabel(subscriptionData.subscriptionFrequency)}`}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ width: "45%" }}>
              <Box
                sx={{
                  padding: "4px",
                }}
              >
                <Typography
                  sx={{ fontWeight: 800 }}
                  align="center"
                >
                  {getLabel("periodStart")}
                </Typography>
              </Box>

              <Typography align="center">
                {format(subscriptionData.currentPeriodStart, "MMM dd, yyy")}
              </Typography>
            </Box>

            <Box sx={{ width: "45%" }}>
              <Box
                sx={{
                  padding: "4px",
                }}
              >
                <Typography
                  sx={{ fontWeight: 800 }}
                  align="center"
                >
                  {getLabel("periodEnd")}
                </Typography>
              </Box>

              <Typography align="center">
                {format(subscriptionData.currentPeriodEnd, "MMM dd, yyy")}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
            <Divider />
          </Box>

          {subscriptionData.store === "stripe" ? (
            <OFDButton
              label={getLabel(`manageSubscription`)}
              variant="contained"
              onClick={handleSubscription}
            />
          ) : null}

          <Box>
            <Typography
              variant="caption"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {getText(`${subscriptionData.store}Link`)}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <a
        ref={subscriptionLink}
        style={{ display: "none" }}
        href={process.env.REACT_APP_CUSTOMER_PORTAL}
        target="_blank"
        rel="noopener"
      >
        Manage Subscription
      </a>

      {member.securityLevel === "accountOwner" ? (
        <Box sx={{ marginTop: "1rem" }}>
          <OFDButton
            label={getLabel("deleteAllData")}
            variant="contained"
            onClick={() => setOpenDeleteDialog(true)}
          />
        </Box>
      ) : null}

      {openDeleteDialog ? (
        <OFDDialog
          open={openDeleteDialog}
          onClose={handleDeleteResponse}
          title="deleteFamilyData"
          description="confirmDeleteFamilyData"
          actions={[
            {
              id: "yes",
              iconName: "",
              label: "yes",
            },
            {
              id: "no",
              iconName: "",
              label: "no",
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default ManageSubscription;
