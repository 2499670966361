import { useEffect, useState } from "react";

import useFamily from "../../hooks/useFamily";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography, Popover } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDAvatar from "./OFDAvatar";
import OFDHorizontalScroll from "./OFDHorizontalScroll";
import OFDIcon from "./OFDIcon";
import OFDFormControl from "./OFDFormControl";

const OFDMemberPicker = ({
  value,
  label,
  onChange,
  disabled,
  security,
  entryAddedBy,
  required,
  error,
  message,
}) => {
  const { getList } = useLocalization();
  const { familyMembers } = useFamily();
  const { memberColor, getMemberColor, member } = useMember();
  const { userCanView, userCanUpdate } = useSecurity();

  const theme = useTheme();

  const [showMenu, setShowMenu] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);

  const handleOpenMenu = (e) => {
    setAnchorE1(e.target);
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorE1(null);
    setShowMenu(false);
  };

  const handleMemberClick = (member) => {
    if (isDisabled()) return;

    let newValue;

    if (!Array.isArray(value)) {
      newValue = [member.id];
    } else {
      if (value.includes(member.id)) {
        newValue = value.filter((mbr) => mbr !== member.id);
      } else {
        newValue = [...value, member.id];
      }
    }

    onChange(newValue);
  };

  const handleMenuClick = (option) => {
    if (isDisabled()) return;

    let newValue = [];

    if (option === "all") {
      newValue = familyMembers.map((member) => member.id);
    } else if (option === "clear") {
      newValue = [];
    } else if (option === "me") {
      newValue = [member.id];
    }

    onChange(newValue);

    handleCloseMenu();
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <OFDFormControl
      label={label}
      required={required}
      error={error}
      message={message}
    >
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        gap={1}
      >
        <OFDHorizontalScroll>
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            {familyMembers?.map((member) => (
              <Box
                key={member.id}
                sx={{
                  paddingBottom: "4px",
                  borderBottom: value?.includes(member.id)
                    ? `4px solid ${
                        memberColor({ color: getMemberColor(member.id) })
                          .backgroundColor
                      }`
                    : "none",
                  cursor: "pointer",
                }}
                onClick={() => handleMemberClick(member)}
              >
                <OFDAvatar
                  {...member}
                  size="xsmall"
                />
              </Box>
            ))}
          </Box>
        </OFDHorizontalScroll>

        {!isDisabled() ? (
          <Box sx={{ position: "relative" }}>
            <Box onClick={handleOpenMenu}>
              <OFDIcon
                name="verticalEllipse"
                color="grey"
              />
            </Box>

            <Popover
              open={showMenu}
              onClose={handleCloseMenu}
              anchorEl={anchorE1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Stack
                spacing={3}
                sx={{
                  backgroundColor: "#ffffff",
                  padding: "1rem",
                  borderRadius: "8px",
                  boxShadow: theme.shadows[8],
                }}
              >
                {getList("memberFilterOptions").map((option) => (
                  <Box
                    key={option.value}
                    onClick={() => handleMenuClick(option.value)}
                  >
                    {option.label}
                  </Box>
                ))}
              </Stack>
            </Popover>
          </Box>
        ) : null}
      </Box>
    </OFDFormControl>
  );
};

export default OFDMemberPicker;
