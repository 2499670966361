import { Navigate, Outlet } from "react-router-dom";

import useSecurity from "../hooks/useSecurity";

const ProtectedRoutes = () => {
  const { isAuthenticated } = useSecurity();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
