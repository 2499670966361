import { useState } from "react";

import useWindow from "../../../../hooks/useWindow";
import useMealPlan from "../../../../hooks/useMealPlan";

import { Box, Modal, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Meal from "./Meal";

import OFDIcon from "../../../ui/OFDIcon";

const DailyPlan = ({ dailyPlan, dow, mealPlanId, onUpdated }) => {
  if (!Array.isArray(dailyPlan.meals) || dailyPlan.meals.length === 0) return;

  return (
    <Box>
      <Stack spacing={1}>
        {dailyPlan.meals.map((meal) => (
          <Meal
            key={meal.id}
            meal={meal}
            dow={dow}
            mealPlanId={mealPlanId}
            onUpdated={onUpdated}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default DailyPlan;
