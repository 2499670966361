import { useEffect, useState } from "react";

import useApi from "./useApi";
import useMessage from "./useMessage";
import useLocalization from "./useLocalization";
import useData from "./useData";
import useDebug from "./useDebug";
import useFamily from "./useFamily";
import useMember from "./useMember";

const useForm = (entityName) => {
  const { destructureResults } = useMessage();
  const { getData, postData, deleteData } = useApi();
  const { labels, getMessage } = useLocalization();
  const { newId } = useData();
  const { consoleLog } = useDebug(false);
  const { family } = useFamily();
  const { member } = useMember();

  const [data, setData] = useState(null);
  const [formMessage, setFormMessage] = useState(null);
  const [fieldMessages, setFieldMessages] = useState(null);
  const [originalData, setOriginalData] = useState(null);

  const [formStatus, setFormStatus] = useState("unchanged");

  const getDataById = async (id) => {
    setFormMessage(null);
    setFieldMessages(null);
    setFormStatus("retrieving");

    const results = await getData({
      entityName,
      action: "get",
      id: id,
    });

    if (results.isSuccessful) {
      setData(results.data);
      setOriginalData(results.data);
    }

    setFormMessage(destructureResults(results));
    setFormStatus("unchanged");
  };

  const getDataByFilter = async (filter) => {
    setFormMessage(null);
    setFieldMessages(null);
    setFormStatus("retrieving");

    const results = await getData({
      entityName,
      action: "get",
      filter,
    });

    if (results.isSuccessful) {
      setData(results.data);
      setOriginalData(results.data);
    }

    setFormMessage(destructureResults(results));
    setFormStatus("unchanged");
  };

  const setFieldValue = (fieldName, value) => {
    let newFieldData = {};

    newFieldData = { [fieldName]: value };

    if (!data) {
      setData(newFieldData);
      return;
    }

    setData((currentData) => {
      return { ...currentData, ...newFieldData };
    });

    setFormStatus("changed");
  };

  const getFieldValue = (fieldName) => {
    if (!data) return null;

    return data[fieldName] || null;
  };

  const getFieldMessage = (fieldName) => {
    if (!Array.isArray(fieldMessages)) return "";

    let fieldMessage = null;

    if (fieldName === "userAccount") {
      let username = fieldMessages.find((field) => field.name === "username");
      let password = fieldMessages.find((field) => field.name === "password");
      let securityLevel = fieldMessages.find(
        (field) => field.name === "securityLevel"
      );
      if (username || password || securityLevel) {
        fieldMessage = {
          message: {
            username,
            password,
            securityLevel,
          },
        };
      }
    } else {
      fieldMessage = fieldMessages.find((field) => field.name === fieldName);
    }

    return fieldMessage?.message || "";
  };

  const getFieldLabel = (fieldName) => {
    if (!labels || !labels[fieldName]) return "";

    return labels[fieldName];
  };

  const getFieldError = (fieldName) => {
    if (!Array.isArray(fieldMessages)) return "";

    const fieldMessage = fieldMessages.find(
      (field) => field.name === fieldName
    );

    return fieldMessage?.error ? true : false;
  };

  const createFolder = (newFolder) => {
    consoleLog("useForm", "createFolder", { newFolder });
    let folder = {
      ...newFolder,
      id: newFolder.id ? newFolder.id : newId(),
    };

    setData((currentData) => {
      let newFolders = [];
      if (Array.isArray(currentData.folders)) {
        newFolders = [...currentData.folders, folder];
      } else {
        newFolders = [folder];
      }

      return {
        ...currentData,
        folders: [...newFolders],
      };
    });

    setFormStatus("changed");
  };

  const updateFolder = (changedFolder) => {
    setData((currentData) => {
      let folders = [];

      for (const folder of currentData.folders) {
        if (folder.id !== changedFolder.id) {
          folders.push({ ...folder });
          continue;
        }

        folders.push({ ...changedFolder });
      }

      return {
        ...currentData,
        folders: [...folders],
      };
    });
    setFormStatus("changed");
  };

  const saveFolder = async (changedFolder) => {
    let folders = [];

    for (const folder of data.folders) {
      if (folder.id !== changedFolder.id) {
        folders.push({ ...folder });
        continue;
      }

      folders.push({ ...changedFolder });
    }

    let newData = { ...data, folders: [...folders] };

    let results = await postData({
      entityName,
      action: "update",
      data: {
        ...newData,
      },
    });

    setFormMessage(destructureResults(results));

    if (results.isSuccessful) {
      setData(results.data);
      setFormStatus("saved");
    } else {
      setFieldMessages(results.fieldErrors);
      setFormStatus("error");
    }
  };

  const deleteFolder = (deletedFolder) => {
    setData((currentData) => {
      let folders = [];

      for (const folder of currentData.folders) {
        if (folder.id !== deletedFolder.id) {
          folders.push({ ...folder });
          continue;
        }
      }

      return {
        ...currentData,
        folders: [...folders],
      };
    });
    setFormStatus("changed");
  };

  const addFieldToFolder = (folderId, newField) => {
    setData((currentData) => {
      let folders = [];

      for (const folder of currentData.folders) {
        if (folder.id !== folderId) {
          folders.push({ ...folder });
          continue;
        }

        let fields = [];
        if (Array.isArray(folder.fields)) {
          fields = [...folder.fields, { ...newField, id: newId() }];
        } else {
          fields.push({ ...newField });
        }

        folders.push({ ...folder, fields: [...fields] });
      }

      return { ...currentData, folders: [...folders] };
    });
    setFormStatus("changed");
  };

  const getFieldValueFromFolder = (folderId, field) => {
    consoleLog("useForm", "getFieldValueFromFolder", { folderId, field, data });
    if (!data.folders || data.folders.length === 0) return null;

    const folder = data.folders.find((item) => item.id === folderId);
    consoleLog("useForm", "getFieldValueFromFolder", { folder });

    if (!folder) return null;

    const currentField = folder.fields.find((item) => item.id === field.id);
    consoleLog("useForm", "getFieldValueFromFolder", { currentField });

    if (!currentField || !currentField.value) return null;

    return currentField.value;
  };

  const updateFieldInFolder = (folderId, updatedField, newValue) => {
    consoleLog("useForm", "updateFieldInFolder", {
      folderId,
      updatedField,
      newValue,
    });

    setData((currentData) => {
      let folders = [];

      for (const folder of currentData.folders) {
        if (folder.id !== folderId) {
          folders.push({ ...folder });
          continue;
        }

        let fields = [];
        for (const field of folder.fields) {
          if (field.id !== updatedField.id) {
            fields.push({ ...field });
            continue;
          }

          fields.push({ ...updatedField, value: newValue });
        }

        folders.push({ ...folder, fields: [...fields] });
      }

      return { ...currentData, folders: [...folders] };
    });
    setFormStatus("changed");
  };

  const deleteFieldFromFolder = (folderId, deletedField) => {
    setData((currentData) => {
      let folders = [];

      for (const folder of currentData.folders) {
        if (folder.id !== folderId) {
          folders.push({ ...folder });
          continue;
        }

        let fields = [];
        for (const field of folder.fields) {
          if (field.id !== deletedField.id) {
            fields.push({ ...field });
            continue;
          }
        }

        folders.push({ ...folder, fields: [...fields] });
      }

      return { ...currentData, folders: [...folders] };
    });
    setFormStatus("changed");
  };

  const saveData = async (args) => {
    // if (formStatus === "error") return false;

    let newData = args && args.data ? args.data : data;

    if (!newData.familyId) {
      newData.familyId = family.id;
    }

    if (!newData.memberId) {
      newData.memberId = member.id;
    }

    if (newData.userAccount) {
      const validationResults = validateUserAccount(newData.userAccount);

      if (validationResults.isSuccessful) {
        newData = { ...newData, ...validationResults.data, userAccount: null };
      } else {
        setFormMessage({
          isSuccessful: validationResults.isSuccessful,
          displayToUser: true,
          message: "fieldErrors",
          severity: "error",
        });
        setFieldMessages(validationResults.fieldMessages);
        setFormStatus("error");
        return null;
      }
    }

    let results = await postData({
      entityName,
      action: args && args.action ? args.action : newData.id ? "update" : "add",
      data: {
        ...newData,
        options: args && args.options ? args.options : null,
      },
    });

    setFormMessage(destructureResults(results));

    if (results.isSuccessful) {
      setData(results.data);
      setFormStatus("saved");
    } else {
      setFieldMessages(results.fieldErrors);
      setFormStatus("error");
    }

    // refreshPage();

    return results;
  };

  const validateUserAccount = (userAccount) => {
    let userAccountData = {};
    let isSuccessful = true;
    let fieldMessages = [];

    if (userAccount.isUser) {
      if (!userAccount.username) {
        isSuccessful = false;
        fieldMessages.push({
          name: "username",
          message: "isEmpty",
          severity: "error",
          error: true,
        });
      }
      if (!userAccount.password) {
        isSuccessful = false;
        fieldMessages.push({
          name: "password",
          message: "isEmpty",
          severity: "error",
          error: true,
        });
      }
      if (!userAccount.securityLevel) {
        isSuccessful = false;
        fieldMessages.push({
          name: "securityLevel",
          message: "isEmpty",
          severity: "error",
          error: true,
        });
      }

      userAccountData = { ...userAccount };
    } else {
      userAccountData = {
        isUser: false,
        username: null,
        password: null,
        securityLevel: null,
      };
    }

    return {
      data: userAccountData,
      isSuccessful,
      fieldMessages,
    };
  };

  const deleteDocument = async (args) => {
    let newData = args && args.data ? args.data : data;

    consoleLog("useForm", "deleteDocument", { data: newData });

    const results = await deleteData({
      entityName,
      action: "delete",
      id: newData.id,
    });

    setFormMessage(destructureResults(results));

    if (results.isSuccessful) {
      setData(null);
      setFormStatus("deleted");
    }

    return results.isSuccessful;
  };

  const resetFormMessage = () => {
    setFormMessage(null);
  };

  const resetFieldMessages = () => {
    setFieldMessages(null);
  };

  // validation routines return the following:
  // isSuccessful: Boolean
  // message: String
  // displayToUser: Boolean
  // fieldErrors: Array of field errors
  // severity: error | warning | info

  const validate = (validationRoutine, newData, option) => {
    setFormMessage(null);
    setFieldMessages(null);

    const results = validationRoutine(newData || data, option);

    setFormMessage(destructureResults(results));
    setFieldMessages(results.fieldErrors);
    if (results.isSuccessful) {
      setFormStatus("changed");
    } else {
      setFormStatus("error");
    }

    return results;
  };

  const getDailyFields = () => {
    return [getFieldValue("startDate"), getFieldValue("endDate")];
  };

  const handleFrequencyChange = (newFrequency) => {
    setFieldValue("startDate", null);
    setFieldValue("endDate", null);
    setFieldValue("dow", null);
    setFieldValue("time", null);
    setFieldValue("dates", null);
    setFieldValue("days", null);

    setFieldValue("frequency", newFrequency);
  };

  const handleDailyChange = (newValue) => {
    setFieldValue("startDate", newValue[0]);
    setFieldValue("endDate", newValue[1]);
  };

  const getWeeklyFields = () => {
    return {
      startDate: getFieldValue("startDate"),
      endDate: getFieldValue("endDate"),
      dow: getFieldValue("dow") || [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    };
  };

  const handleWeeklyChange = (newValue) => {
    setFieldValue("startDate", newValue.startDate);
    setFieldValue("endDate", newValue.endDate);
    setFieldValue("dow", newValue.dow);
  };

  const getMonthlyFields = () => {
    return {
      startDate: getFieldValue("startDate"),
      endDate: getFieldValue("endDate"),
      months: getFieldValue("months") || [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      monthlyType: getFieldValue("monthlyType"),
      dates: getFieldValue("dates"),
      days: getFieldValue("days"),
    };
  };

  const handleMonthlyChange = (newValue) => {
    setFieldValue("startDate", newValue.startDate);
    setFieldValue("endDate", newValue.endDate);
    setFieldValue("monthlyType", newValue.monthlyType);
    setFieldValue("dates", newValue.dates);
    setFieldValue("days", newValue.days);
  };

  const handleDateChange = (newValue) => {
    setFieldValue("startDate", newValue);
    setFieldValue("endDate", newValue);
  };

  return {
    getDataById,
    getDataByFilter,
    data,
    setData,
    deleteDocument,

    setOriginalData,
    originalData,

    setFieldValue,
    getFieldValue,
    getFieldLabel,
    getFieldMessage,
    getFieldError,

    getDailyFields,
    handleFrequencyChange,
    handleDailyChange,
    getWeeklyFields,
    handleWeeklyChange,
    getMonthlyFields,
    handleMonthlyChange,
    handleDateChange,

    createFolder,
    updateFolder,
    deleteFolder,
    saveFolder,

    getFieldValueFromFolder,
    addFieldToFolder,
    deleteFieldFromFolder,
    updateFieldInFolder,

    saveData,
    formMessage,
    setFormMessage,
    setFieldMessages,
    formStatus,
    resetFormMessage,
    resetFieldMessages,

    validate,
  };
};

export default useForm;
