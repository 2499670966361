const BudgetTemplates = {
  simpleBudget: [
    {
      type: "Income",
      name: "Primary",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "income",
    },
    {
      type: "Income",
      name: "Secondary",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "income",
    },
    {
      type: "Income",
      name: "Other",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "income",
    },

    {
      type: "Fixed",
      name: "Mortgage/Rent",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Fixed",
      name: "Utilities",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Fixed",
      name: "Car Payment",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Fixed",
      name: "Insurance",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Fixed",
      name: "Childcare/School",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Fixed",
      name: "Loan Repayments",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Variable",
      name: "Groceries",
      budgetFrequency: "weekly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Variable",
      name: "Eating Out",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Variable",
      name: "Entertainment",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Variable",
      name: "Clothing",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Variable",
      name: "Transportation",
      budgetFrequency: "weekly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Variable",
      name: "Personal Care",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Variable",
      name: "Medical",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Savings",
      name: "Emergency Fund",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Savings",
      name: "Retirement Savings",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Savings",
      name: "Education Savings",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Savings",
      name: "Other Savings",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
  ],
  detailedBudget: [
    {
      type: "Income",
      name: "Primary",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "income",
    },
    {
      type: "Income",
      name: "Secondary",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "income",
    },
    {
      type: "Income",
      name: "Investments",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "income",
    },
    {
      type: "Income",
      name: "Side Job",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "income",
    },
    {
      type: "Income",
      name: "Other",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "income",
    },

    {
      type: "Housing",
      name: "Mortgage/Rent",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Utilities",
      name: "Electric",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Utilities",
      name: "Water",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Utilities",
      name: "Gas",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Utilities",
      name: "Internet",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Utilities",
      name: "Cable/Streaming",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Utilities",
      name: "Cellular",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Vehicle",
      name: "Car Payment",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Insurance",
      name: "Home",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Insurance",
      name: "Car",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Insurance",
      name: "Health",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Insurance",
      name: "Life",
      budgetFrequency: "yearly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Education",
      name: "Childcare/School",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Repayments",
      name: "Student Loan",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Repayments",
      name: "Personal Loan",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Repayments",
      name: "Credit Card",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Food",
      name: "Groceries",
      budgetFrequency: "weekly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Food",
      name: "Restaurants",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Food",
      name: "Fast Food",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Food",
      name: "Coffee Shops",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Entertainment",
      name: "Movies",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Entertainment",
      name: "Theater",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Entertainment",
      name: "Concerts",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Entertainment",
      name: "Hobbies",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Clothing",
      name: "Adults",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Clothing",
      name: "Children",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Transportation",
      name: "Gas",
      budgetFrequency: "weekly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Transportation",
      name: "Public",
      budgetFrequency: "weekly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Transportation",
      name: "Maintenance",
      budgetFrequency: "weekly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Personal Care",
      name: "Gym",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Personal Care",
      name: "Hair/Salon",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Personal Care",
      name: "Cosmetics/Personal",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Medical",
      name: "Uncovered",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Medical",
      name: "Medications",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },

    {
      type: "Savings",
      name: "Emergency Fund",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Savings",
      name: "Retirement",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Savings",
      name: "Education",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Savings",
      name: "Vacation",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Savings",
      name: "New Car",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
    {
      type: "Savings",
      name: "Home Reno",
      budgetFrequency: "monthly",
      budgetAmount: 0,
      budgetType: "expense",
    },
  ],
};

export default BudgetTemplates;
