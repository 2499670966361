import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDPasswordField from "./OFDPasswordField";
import OFDSwitch from "./OFDSwitch";
import OFDSecurity from "./OFDSecurity";
import OFDSelect from "./OFDSelect";

const OFDUserAccount = ({
  id,
  label,
  value,
  onChange,
  source,
  memberData,
  error,
  message,
}) => {
  const { getLabel } = useLocalization();

  const [userAccount, setUserAccount] = useState(undefined);
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    if (!memberData) return;

    if (!value) {
      setUserAccount(() => {
        let data = {
          isUser: memberData.isUser,
          username: memberData.username ? memberData.username : null,
          securityLevel: memberData.securityLevel
            ? memberData.securityLevel
            : null,
          password: null,
        };

        return data;
      });
    } else {
      setUserAccount(() => {
        let data = {
          isUser: value.isUser,
          username: value.username ? value.username : null,
          securityLevel: value.securityLevel ? value.securityLevel : null,
          password: value.password ? value.password : null,
        };

        return data;
      });
    }
  }, [value, memberData]);

  const handleChange = (fieldName, newValue) => {
    onChange({ ...userAccount, [fieldName]: newValue });
  };

  const handleChangePasswordType = () => {
    setPasswordType((current) => {
      if (current === "password") return "text";
      return "password";
    });
  };

  if (userAccount === undefined) return null;

  return (
    <Box>
      <Stack spacing={1}>
        <OFDSwitch
          id="isUser"
          label="isUser"
          value={userAccount.isUser}
          onChange={(newValue) => handleChange("isUser", newValue)}
        />
        {userAccount.isUser ? (
          <OFDSelect
            id="securityLevel"
            label="securityLevel"
            listName="securityLevels"
            value={userAccount.securityLevel}
            onChange={(newValue) => handleChange("securityLevel", newValue)}
            error={message?.securityLevel?.error}
            message={message?.securityLevel?.message}
          />
        ) : null}
        {userAccount.isUser ? (
          <OFDTextField
            id="username"
            label="username"
            value={userAccount.username}
            onChange={(newValue) => handleChange("username", newValue)}
            error={message?.username?.error}
            message={message?.username?.message}
          />
        ) : null}
        {userAccount.isUser ? (
          <Box>
            <OFDTextField
              id="newpassword"
              label="newPassword"
              type={passwordType}
              value={userAccount.password}
              onChange={(newValue) => handleChange("password", newValue)}
              fullWidth
              error={message?.password?.error}
              message={message?.password?.message}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={handleChangePasswordType}
            >
              <Typography
                variant="caption"
                sx={{ textDecoration: "underline" }}
              >
                {getLabel("showPassword")}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

export default OFDUserAccount;
