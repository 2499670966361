import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDWeekOf from "../../ui/OFDWeekOf";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const MealPlanWeekOf = ({ value, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">{getWizard("mealPlan").weekOf.title}</Typography>

      <Stack spacing={2}>
        {getWizard("mealPlan").weekOf.instructions.map((instruction, index) => (
          <Typography key={`instruction-${index}`}>{instruction}</Typography>
        ))}

        <OFDFieldContainer>
          <OFDWeekOf
            id="weekOf"
            value={value}
            onChange={(newValue) => onChange(newValue)}
          />
        </OFDFieldContainer>
      </Stack>
    </Box>
  );
};

export default MealPlanWeekOf;
