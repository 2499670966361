import { useEffect, useState } from "react";
import { getMonth } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useExpense from "../../hooks/useExpense";
import useBudget from "../../hooks/useBudget";
import useSearch from "../../hooks/useSearch";
import usePage from "../../hooks/usePage";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import ExpensesForMonth from "./ExpensesForMonth";
import Loading from "../layout/Loading";

const ExpensesByMonth = ({ year, filter, ytd }) => {
  const { displayLocalCurrency, getLabel } = useLocalization();
  const { refresh } = usePage();
  const { monthlyBudgetTotals } = useBudget();

  const {
    getExpenseCalendar,
    expenseCalendar,
    calculateMonthlyTotals,
    calculateYearlyTotal,
    setCurrentYear,
  } = useExpense();

  const { data, setData, searchData, searchResults, setSearchCriteria } =
    useSearch();

  const [monthlyTotals, setMonthlyTotals] = useState(undefined);

  useEffect(() => {
    getData();
    setSearchCriteria(filter);
    setCurrentYear(year);
  }, [year, refresh, filter, ytd]);

  const getData = async () => {
    if (!year) return;

    await getExpenseCalendar(year);
  };

  useEffect(() => {
    setData(expenseCalendar);
  }, [expenseCalendar]);

  useEffect(() => {
    searchData();
  }, [data]);

  useEffect(() => {
    getTotals();
  }, [searchResults]);

  const getTotals = async () => {
    setMonthlyTotals(undefined);
    const totals = await calculateMonthlyTotals(searchResults, ytd);

    const monthlyBudgets = await monthlyBudgetTotals(year, ytd);

    if (monthlyBudgets.isSuccessful) {
      for (const monthlyBudget of monthlyBudgets.data) {
        totals[monthlyBudget.month].incomeBudget = monthlyBudget.incomeBudget;
        totals[monthlyBudget.month].expenseBudget = monthlyBudget.expenseBudget;
      }
    }

    setMonthlyTotals(totals);
  };

  if (monthlyTotals === undefined) return <Loading />;

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="caption">{getLabel("income")}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{getLabel("budget")}</Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{ fontWeight: 900 }}
              >
                {displayLocalCurrency(
                  calculateYearlyTotal(monthlyTotals, ytd, "incomeBudget"),
                  0
                )}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="caption">{getLabel("income")}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{getLabel("actual")}</Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{ fontWeight: 900 }}
              >
                {displayLocalCurrency(
                  calculateYearlyTotal(monthlyTotals, ytd, "incomeAmount"),
                  0
                )}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="caption">{getLabel("expense")}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{getLabel("actual")}</Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{ fontWeight: 900 }}
              >
                {displayLocalCurrency(
                  calculateYearlyTotal(monthlyTotals, ytd, "expenseAmount"),
                  0
                )}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="caption">{getLabel("expense")}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{getLabel("budget")}</Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{ fontWeight: 900 }}
              >
                {displayLocalCurrency(
                  calculateYearlyTotal(monthlyTotals, ytd, "expenseBudget"),
                  0
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "1rem" }}>
          <Grid
            container
            spacing={2}
          >
            {monthlyTotals?.map((monthlyTotal) => {
              if (ytd && monthlyTotal.month > getMonth(new Date())) return;

              return (
                <Grid
                  key={`month_${monthlyTotal.month}`}
                  mobile={12}
                >
                  <ExpensesForMonth
                    year={year}
                    {...monthlyTotal}
                    onChange={getData}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ExpensesByMonth;
