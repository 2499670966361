import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import useLocalization from "../../../../hooks/useLocalization";
import useMessage from "../../../../hooks/useMessage";
import useSignup from "../../../../hooks/useSignup";
import usePage from "../../../../hooks/usePage";

import RegisterEmail from "./RegisterEmail";
import EmailVerification from "./EmailVerification";
import Paywall from "./Paywall";
import Success from "./Success";
import Cancel from "./Cancel";

import FamilyContentSlider from "../../../layout/FamilySliderLayout";
import OFDMessage from "../../../ui/OFDMessage";
// import SignupForm from "./_archive/SignupForm";
import useAnalytics from "../../../../hooks/useAnalytics";

const Signup = () => {
  const { titles, labels, text } = useLocalization(
    process.env.REACT_APP_LOCALE
  );
  const { signup, stage, setStage, nextStage, prevStage, updateSignup } =
    useSignup();
  const { destructureResults } = useMessage();
  const { setPage } = usePage();

  const [message, setMessage] = useState(null);
  const [fieldErrors, setFieldErrors] = useState(null);

  useAnalytics();

  return (
    <>
      <FamilyContentSlider title="Signup">
        <Routes>
          <Route
            path="register"
            element={<RegisterEmail />}
          />
          <Route
            path="emailverification"
            element={<EmailVerification />}
          />
          <Route
            path="paywall"
            element={<Paywall />}
          />
          <Route
            path="success"
            element={<Success />}
          />
          <Route
            path="cancel"
            element={<Cancel />}
          />
          <Route
            path="*"
            element={<RegisterEmail />}
          />
        </Routes>
      </FamilyContentSlider>

      <OFDMessage
        message={message}
        onClose={() => setMessage(null)}
      />
    </>
  );
};

export default Signup;
