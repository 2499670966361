import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDTextField from "../../ui/OFDTextField";
import OFDNumber from "../../ui/OFDNumber";
import OFDMultiSelect from "../../ui/OFDMultiSelect";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const RecipePreparation = ({ preparation, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  const getValue = (field) => {
    if (!preparation) return null;
    if (!preparation[field]) return null;

    return preparation[field];
  };

  const handleChange = (field, newValue) => {
    if (!preparation) {
      onChange({ [field]: newValue });
      return;
    }

    onChange({ ...preparation, [field]: newValue });
  };

  return (
    <Box>
      <Typography variant="h5">
        {getWizard("recipe").preparation.title}
      </Typography>

      <OFDFieldContainer sx={{ marginTop: "1rem" }}>
        <Stack spacing={2}>
          <OFDTextField
            id="preparationTime"
            label="preparationTime"
            value={getValue("preparationTime")}
            onChange={(newValue) => handleChange("preparationTime", newValue)}
            error={error}
            message={message}
          />

          <OFDTextField
            id="servings"
            label="servings"
            value={getValue("servings") || ""}
            onChange={(newValue) => handleChange("servings", newValue)}
            error={error}
            message={message}
          />

          <OFDMultiSelect
            id="mealType"
            label="mealType"
            value={getValue("mealType") || []}
            onChange={(newValue) => handleChange("mealType", newValue)}
            error={error}
            message={message}
            listName="mealTypes"
          />
        </Stack>
      </OFDFieldContainer>
    </Box>
  );
};

export default RecipePreparation;
