import React from "react";

import useMember from "../../hooks/useMember";

import { Box, Typography } from "@mui/material";

const OFDMonthlyDates = ({ id, label, value, onChange, disabled }) => {
  const { memberColor, member } = useMember();

  const weekStyle = {
    display: "flex",
  };

  const dayStyle = (date) => {
    return {
      border: `1px solid ${memberColor({ shade: 200 }).backgroundColor}`,
      width: "calc(100% / 7)",
      aspectRatio: "1/1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...memberColor({
        color: isDateSelected(date) ? member.color : "inherit",
        shade: 200,
      }),
      fontSize: "14px",
      color: disabled ? "#ccc" : "inherit",
    };
  };

  const isDateSelected = (date) => {
    if (!Array.isArray(value)) return false;
    return value.includes(date);
  };

  const handleDateSelected = (date) => {
    if (disabled) return;

    let newDates = Array.isArray(value) ? [...value] : [];

    let keyIndex = newDates.findIndex((item) => item === date);
    if (keyIndex > -1) {
      newDates.splice(keyIndex, 1);
    } else {
      newDates.push(date);
    }

    onChange(newDates);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={weekStyle}>
        <Box
          sx={dayStyle("1")}
          onClick={() => handleDateSelected("1")}
        >
          1
        </Box>
        <Box
          sx={dayStyle("2")}
          onClick={() => handleDateSelected("2")}
        >
          2
        </Box>
        <Box
          sx={dayStyle("3")}
          onClick={() => handleDateSelected("3")}
        >
          3
        </Box>
        <Box
          sx={dayStyle("4")}
          onClick={() => handleDateSelected("4")}
        >
          4
        </Box>
        <Box
          sx={dayStyle("5")}
          onClick={() => handleDateSelected("5")}
        >
          5
        </Box>
        <Box
          sx={dayStyle("6")}
          onClick={() => handleDateSelected("6")}
        >
          6
        </Box>
        <Box
          sx={dayStyle("7")}
          onClick={() => handleDateSelected("7")}
        >
          7
        </Box>
      </Box>

      <Box sx={weekStyle}>
        <Box
          sx={dayStyle("8")}
          onClick={() => handleDateSelected("8")}
        >
          8
        </Box>
        <Box
          sx={dayStyle("9")}
          onClick={() => handleDateSelected("9")}
        >
          9
        </Box>
        <Box
          sx={dayStyle("10")}
          onClick={() => handleDateSelected("10")}
        >
          10
        </Box>
        <Box
          sx={dayStyle("11")}
          onClick={() => handleDateSelected("11")}
        >
          11
        </Box>
        <Box
          sx={dayStyle("12")}
          onClick={() => handleDateSelected("12")}
        >
          12
        </Box>
        <Box
          sx={dayStyle("13")}
          onClick={() => handleDateSelected("13")}
        >
          13
        </Box>
        <Box
          sx={dayStyle("14")}
          onClick={() => handleDateSelected("14")}
        >
          14
        </Box>
      </Box>

      <Box sx={weekStyle}>
        <Box
          sx={dayStyle("15")}
          onClick={() => handleDateSelected("15")}
        >
          15
        </Box>
        <Box
          sx={dayStyle("16")}
          onClick={() => handleDateSelected("16")}
        >
          16
        </Box>
        <Box
          sx={dayStyle("17")}
          onClick={() => handleDateSelected("17")}
        >
          17
        </Box>
        <Box
          sx={dayStyle("18")}
          onClick={() => handleDateSelected("18")}
        >
          18
        </Box>
        <Box
          sx={dayStyle("19")}
          onClick={() => handleDateSelected("19")}
        >
          19
        </Box>
        <Box
          sx={dayStyle("20")}
          onClick={() => handleDateSelected("20")}
        >
          20
        </Box>
        <Box
          sx={dayStyle("21")}
          onClick={() => handleDateSelected("21")}
        >
          21
        </Box>
      </Box>

      <Box sx={weekStyle}>
        <Box
          sx={dayStyle("22")}
          onClick={() => handleDateSelected("22")}
        >
          22
        </Box>
        <Box
          sx={dayStyle("23")}
          onClick={() => handleDateSelected("23")}
        >
          23
        </Box>
        <Box
          sx={dayStyle("24")}
          onClick={() => handleDateSelected("24")}
        >
          24
        </Box>
        <Box
          sx={dayStyle("25")}
          onClick={() => handleDateSelected("25")}
        >
          25
        </Box>
        <Box
          sx={dayStyle("26")}
          onClick={() => handleDateSelected("26")}
        >
          26
        </Box>
        <Box
          sx={dayStyle("27")}
          onClick={() => handleDateSelected("27")}
        >
          27
        </Box>
        <Box
          sx={dayStyle("28")}
          onClick={() => handleDateSelected("28")}
        >
          28
        </Box>
      </Box>

      <Box>
        <Box
          sx={dayStyle("last")}
          onClick={() => handleDateSelected("last")}
        >
          Last
        </Box>
      </Box>
    </Box>
  );
};

export default OFDMonthlyDates;
