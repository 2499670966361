import { useEffect, useState } from "react";

import OFDRecurrence from "../ui/OFDRecurrence";

const initialRecurrence = {
  rrule: null,
  occurrenceStart: null,
  frequency: "onetime",
  interval: null,
  count: null,
  until: null,
  forever: false,
  byDay: null,
  bySetPosition: null,
  byMonthDay: null,
  byMonth: null,
  monthlyType: "relative",
  scheduleDates: null,
};

const Recurrence = () => {
  const [recurrence, setRecurrence] = useState({ ...initialRecurrence });

  const handleChange = (newValue) => {
    if (newValue.frequency === "onetime") {
      setRecurrence({ ...initialRecurrence });
    } else {
      setRecurrence(newValue);
    }
  };

  return (
    <OFDRecurrence
      value={recurrence}
      onChange={handleChange}
    />
  );
};

export default Recurrence;
