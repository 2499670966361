import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useFamily from "../../hooks/useFamily";

import { Box, Stack } from "@mui/material";

import OFDSelect from "../ui/OFDSelect";
import OFDMemberFilter from "../ui/OFDMemberFilter";
import OFDFormControl from "../ui/OFDFormControl";

const SecurityAction = ({ action, value, onChange }) => {
  const { securityLevels, securityLevelOptions } = useSecurity();
  const { familyMembers } = useFamily();

  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (!familyMembers) return;

    setMembers(() => {
      return familyMembers.map((member) => member.id);
    });
  }, [familyMembers]);

  const handleChange = (newValue) => {
    let security = { ...value, securityLevel: newValue };

    if (newValue !== "members") {
      security.members = null;
    }

    onChange(action, security);
  };

  const handleMemberSelection = (selectedMembers) => {
    let security = { ...value, members: selectedMembers };
    onChange(action, security);
  };

  return (
    <Box>
      <OFDFormControl label={action}>
        <OFDSelect
          id="securityLevel"
          label="securityLevel"
          value={value?.securityLevel || ""}
          onChange={(newValue) => handleChange(newValue)}
          options={securityLevelOptions()}
          fullWidth
        />

        {value?.securityLevel === "members" ? (
          <Box sx={{ marginTop: "1rem" }}>
            <OFDMemberFilter
              value={value?.members || []}
              available={members}
              onChange={handleMemberSelection}
            />
          </Box>
        ) : null}
      </OFDFormControl>
    </Box>
  );
};

export default SecurityAction;
