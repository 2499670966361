import { useEffect, useState } from "react";
import { startOfWeek, startOfToday } from "date-fns";

import useLocalization from "../../../hooks/useLocalization";
import useWindow from "../../../hooks/useWindow";

import { Box, Button } from "@mui/material";

import OFDModal from "../../layout/OFDModal";
import MealPlanIntro from "./MealPlanIntro";
import MealPlanWeekOf from "./MealPlanWeekOf";
import DailyPlans from "./DailyPlans";

import OFDButton from "../../ui/OFDButton";

const steps = [
  { step: 0, label: "intro" },
  { step: 1, label: "weekOf" },
  { step: 2, label: "plans" },
];

const MealPlanWizard = ({
  mealPlan,
  open,
  onClose,
  onCreateMealPlan,
  onUpdateMealPlan,
  selectedWeek,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { isMobile, windowHeight } = useWindow();

  const [currentStep, setCurrentStep] = useState(0);
  const [firstStep, setFirstStep] = useState(0);

  const [weekOf, setWeekOf] = useState(null);
  const [dailyPlans, setDailyPlans] = useState(null);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (open) {
      setData();
    }
  }, [open]);

  useEffect(() => {
    setData();
  }, [mealPlan]);

  const setData = () => {
    if (mealPlan) {
      setWeekOf(mealPlan.weekOf);

      setCurrentStep(1);
      setFirstStep(1);
    } else {
      setWeekOf(selectedWeek ? selectedWeek : startOfWeek(startOfToday()));
      setCurrentStep(0);
      setFirstStep(0);
    }
  };

  const handleBack = () => {
    setCurrentStep((current) => {
      if (current === firstStep) return current;

      return current - 1;
    });
  };

  const handleNext = () => {
    if (!stepValidated()) return;

    if (currentStep === steps.length - 1) {
      handleSave();
    } else {
      setCurrentStep((current) => {
        if (current >= steps.length - 1) return current;

        return current + 1;
      });
    }
  };

  const stepValidated = () => {
    setError(false);
    setMessage(null);

    if (currentStep === 0) return true;

    if (currentStep === 1) {
      if (!weekOf) {
        setError(true);
        setMessage(getMessage("isEmpty"));
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    let newMealPlan;

    if (!mealPlan) {
      newMealPlan = {
        weekOf,
        dailyPlans,
      };

      onCreateMealPlan(newMealPlan);
    } else {
      newMealPlan = {
        ...mealPlan,
        weekOf,
        dailyPlans,
      };
      onUpdateMealPlan(newMealPlan);
    }
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="mealPlanWizard"
        color="grey"
        shade="900"
      >
        <Box
          sx={{
            padding: "1rem",
            height: isMobile ? "calc(100% - 4rem)" : "calc(100% - 7rem)",
            overflow: "auto",
          }}
        >
          {currentStep === 0 && !mealPlan ? <MealPlanIntro /> : null}

          {currentStep === 1 ? (
            <MealPlanWeekOf
              value={weekOf}
              onChange={(newValue) => setWeekOf(newValue)}
              error={error}
              message={message}
            />
          ) : null}

          {currentStep === 2 ? (
            <DailyPlans
              dailyPlans={dailyPlans}
              onChange={(newValue) => setDailyPlans(newValue)}
              error={error}
              message={message}
              weekOf={weekOf}
            />
          ) : null}
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "1rem",
            right: "1rem",
            height: "4rem",
            borderTop: "1px solid #ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "1rem",
            paddingTop: ".5rem",
          }}
          gap={2}
        >
          <OFDButton
            id="back"
            variant="outlined"
            disabled={currentStep === firstStep}
            onClick={handleBack}
            label={getLabel("back")}
            width="auto"
            color="grey"
            shade={900}
          />

          <OFDButton
            id="next"
            variant="contained"
            onClick={currentStep < steps.length - 1 ? handleNext : handleSave}
            color="grey"
            shade={900}
            label={getLabel(
              currentStep < steps.length - 1
                ? "next"
                : mealPlan
                ? "updateMealPlan"
                : "createMealPlan"
            )}
            width="auto"
          />
        </Box>
      </OFDModal>
    </>
  );
};

export default MealPlanWizard;
