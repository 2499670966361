import { TextField } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";
import useColor from "../../hooks/useColor";

const OFDTextNote = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  fullWidth,
  disabled,
  security,
  entryAddedBy,
  color,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();
  const { memberColor } = useMember();
  const { getColor } = useColor();

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <TextField
      id={id}
      label={getLabel(label)}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      helperText={message ? getMessage(message) : ""}
      error={Boolean(error)}
      required={required}
      disabled={isDisabled()}
      multiline={true}
      fullWidth={fullWidth}
      size="small"
      sx={{
        "& label.Mui-focused": {
          color: color
            ? getColor({ color }).backgroundColor
            : memberColor().backgroundColor,
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: color
              ? getColor({ color }).backgroundColor
              : memberColor().backgroundColor,
          },
        },
      }}
    />
  );
};

export default OFDTextNote;
