import React from "react";

import { Box } from "@mui/material";

const OFDFieldContainer = ({ sx, children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "1rem",
        borderRadius: "12px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default OFDFieldContainer;
