import { Box, Stack, Typography } from "@mui/material";

import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

const OFDButtonSelector = ({ id, label, options, value, onChange }) => {
  const { labels } = useLocalization();
  const { memberColor } = useMember();

  return (
    <Stack
      id={id}
      spacing={1}
    >
      <Typography>{labels[label]}</Typography>

      {options?.map((option) => (
        <Box
          id={option.value}
          key={option.value}
          sx={{
            width: "100%",
            padding: "1rem",
            border: `1px solid ${memberColor().backgroundColor}`,
            backgroundColor:
              option.value === value
                ? memberColor().backgroundColor
                : "inherit",
            color: option.value === value ? memberColor().color : "inherit",
            borderRadius: "8px",
          }}
          onClick={() => onChange(option.value)}
        >
          <Typography>{labels[option.label]}</Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default OFDButtonSelector;
