import React, { useEffect, useState, useRef } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

// Material UI
import { Box, Button } from "@mui/material";

// Assets
import OFDAvatar from "./OFDAvatar";

import OFDModal from "../layout/OFDModal";
import OFDToolbar from "../layout/OFDToolbar";
import OFDFormControl from "./OFDFormControl";

const fileTypes = {
  images: {
    "image/png": [".png"],
    "image/jpeg": [".jpeg", ".jpg"],
  },
};

const OFDPicture = ({ id, name, label, value, onChange, height, width }) => {
  const { labels, titles } = useLocalization();
  const { memberMode } = useMember();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [base64, setBase64] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const cameraRef = useRef(null);
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const galleryRef = useRef(null);

  useEffect(() => {
    setImage(null);
    setFile(null);
    setBase64(null);
    setIsLoaded(false);

    if (!value) {
      setBase64(null);
    } else {
      setBase64(value);
    }

    setIsLoaded(true);
  }, [value]);

  const capturePicture = () => {
    let camera = cameraRef.current;

    setImage(camera.files[0]);
  };

  const selectGallery = () => {
    let gallery = galleryRef.current;

    setImage(gallery.files[0]);
  };

  const handleTakePicture = () => {
    cameraRef.current.click();
  };

  const handleImageGallery = () => {
    galleryRef.current.click();
  };

  const handleToolbarClick = (name) => {
    if (name === "takePicture") handleTakePicture();
    if (name === "imageGallery") handleImageGallery();
  };

  const convertFileToBase64 = () => {
    let canvas = canvasRef.current;
    let img = imageRef.current;

    let width = img.width;
    let height = img.height;
    let ratio = width / height;

    let newWidth = 800;
    let newHeight = 800 / ratio;

    canvas.width = newWidth;
    canvas.height = newHeight;
    let context = canvas.getContext("2d");
    context.drawImage(img, 0, 0, newWidth, newHeight);

    let dataUrl = canvas.toDataURL(image.type);

    setBase64(dataUrl);
  };

  useEffect(() => {
    if (!base64 || !isLoaded) return;

    onChange(base64);
  }, [base64]);

  return (
    <>
      <Box>
        <Box>
          <OFDToolbar
            menuItems={[
              {
                name: "imageGallery",
                label: "camera",
                icon: "camera",
              },
            ]}
            onClick={handleToolbarClick}
            containerSx={{ backgroundColor: "#fff" }}
            itemSx={{ backgroundColor: "#ccc" }}
          />
        </Box>
        <Box sx={{ marginBottom: "1rem" }}>
          <input
            style={{ display: "none" }}
            ref={cameraRef}
            type="file"
            name="image"
            accept="image/*"
            onChange={capturePicture}
          />

          <input
            style={{ display: "none" }}
            ref={galleryRef}
            type="file"
            name="gallery"
            accept="image/*"
            onChange={selectGallery}
          />
        </Box>

        <canvas
          ref={canvasRef}
          style={{ display: "none" }}
        ></canvas>

        {base64 ? (
          <img
            src={base64}
            loading="lazy"
            style={{
              width: width ? width : "100%",
              height: height ? height : "auto",
            }}
          />
        ) : null}

        <Box sx={{ display: "none" }}>
          <img
            ref={imageRef}
            src={image ? window.URL.createObjectURL(image) : null}
            onLoad={convertFileToBase64}
          />
        </Box>
      </Box>
    </>
  );
};

export default OFDPicture;
