import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayNote from "./OFDDisplayNote";
import OFDCollapsable from "../layout/OFDCollapsable";
import RecipeDisplay from "../display/RecipeDisplay";
import RecipeDisplayModal from "../display/RecipeDisplayModal";
import OFDIcon from "./OFDIcon";

const OFDDisplayMenuItems = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();
  const { memberColor, member } = useMember();

  const [openRecipe, setOpenRecipe] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    onClick();
  };

  const handleOpenRecipe = (recipeId) => {
    setSelectedRecipe(recipeId);
    setOpenRecipe(true);
  };

  const handleCloseRecipe = () => {
    setOpenRecipe(false);
    setSelectedRecipe(null);
  };

  const getContent = (item) => {
    let items = [];
    if (item.recipeId) {
      items.push(
        <Box key={item.recipeId}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <OFDIcon
              name="openPage"
              onClick={() => handleOpenRecipe(item.recipeId)}
            />
          </Box>
          <RecipeDisplay recipeId={item.recipeId} />
        </Box>
      );
    }

    if (item.note) {
      items.push(
        <OFDDisplayNote
          key={`note-${item.recipeId}`}
          value={item.note}
          textOnly={true}
          styleoverride={{ fontSize: "12px", color: "#535353" }}
        />
      );
    }

    if (items.length > 0) {
      return (
        <Stack spacing={1}>{items.map((contentItem) => contentItem)}</Stack>
      );
    } else {
      return null;
    }
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={displayStyle}>
        <Typography
          variant="overline"
          onClick={handleClick}
        >
          {getLabel(label)}
        </Typography>

        <Box>
          <Stack spacing={0.5}>
            {Array.isArray(value) && value.length > 0 ? (
              value.map((item) => (
                <OFDCollapsable
                  key={item.id}
                  header={
                    <Typography onClick={onClick}>{item.name}</Typography>
                  }
                  styleOverride={{
                    ...memberColor({ shade: 100 }),
                    minHeight: "10px",
                    maxHeight: "5rem",
                  }}
                  arrowColor={member.color}
                  arrowShade={900}
                >
                  {getContent(item)}
                </OFDCollapsable>
              ))
            ) : (
              <Typography>-</Typography>
            )}
          </Stack>
        </Box>
      </Box>

      <RecipeDisplayModal
        open={openRecipe}
        onClose={handleCloseRecipe}
        recipeId={selectedRecipe}
      />
    </>
  );
};

export default OFDDisplayMenuItems;
