import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayNote from "../ui/OFDDisplayNote";
import OFDCheckBox from "../ui/OFDCheckBox";

const RecipeIngredients = ({
  id,
  label,
  value,
  styleoverride,
  onClick,
  onSelect,
  selectedItems,
}) => {
  const { getLabel, getMessage } = useLocalization();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick(id);
  };

  const isSelected = (item) => {
    if (!selectedItems) return false;

    return selectedItems.includes(item.id);
  };

  return (
    <Box
      id={id}
      sx={displayStyle}
      onClick={handleClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>
      <Typography sx={{ fontSize: "12px" }}>
        {getMessage("checkoffIngredient")}
      </Typography>
      <Box>
        <Stack spacing={1}>
          {value?.map((item) => (
            <Box key={item.id}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <OFDCheckBox
                    value={isSelected(item)}
                    onChange={(newValue) => onSelect(item, newValue)}
                  />
                  <Box>
                    <Typography>{item.name}</Typography>
                    {item.note ? (
                      <OFDDisplayNote
                        value={item.note}
                        textOnly={true}
                        styleoverride={{ fontSize: "12px", color: "#535353" }}
                      />
                    ) : null}
                  </Box>
                </Box>
                {item.quantity ? (
                  <Typography>{item.quantity}</Typography>
                ) : null}
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default RecipeIngredients;
