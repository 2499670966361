// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { WifiProtectedSetupSharp } from "@mui/icons-material";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.AUTHDOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FB_APPID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const requestForToken = (updatePushToken) => {
  try {
    Notification.requestPermission()
      .then(async (permission) => {
        if (permission === "granted") {
          return getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID,
          })
            .then((currentToken) => {
              if (currentToken) {
                updatePushToken(currentToken);
              } else {
                updatePushToken(null);
              }
            })
            .catch((err) => {
              updatePushToken(null);
            });
        } else {
          updatePushToken(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }
};

onMessage(messaging, (payload) => {
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: "../assets/logo.png",
    badge: "../assets/logo.png",
  };

  if (!("serviceWorker" in navigator)) return;
  navigator.serviceWorker.ready.then((swreg) => {
    swreg.showNotification(notificationTitle, notificationOptions);
  });
});
