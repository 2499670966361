import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDDate from "../../ui/OFDDate";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const GroceryShoppingDate = ({ shoppingDate, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">
        {getWizard("grocery").shoppingDate.title}
      </Typography>

      <Stack spacing={2}>
        {getWizard("grocery").shoppingDate.instructions.map(
          (instruction, index) => (
            <Typography key={`instruction-${index}`}>{instruction}</Typography>
          )
        )}

        <OFDFieldContainer>
          <OFDDate
            id="shoppingDate"
            label="shoppingDate"
            value={shoppingDate}
            onChange={(newValue) => onChange(newValue)}
            error={error}
            message={message}
          />
        </OFDFieldContainer>
      </Stack>
    </Box>
  );
};

export default GroceryShoppingDate;
