import { useEffect, useState, useRef } from "react";
import { startOfToday } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";
import useSecurity from "../../hooks/useSecurity";
import useWindow from "../../hooks/useWindow";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDCurrency from "./OFDCurrency";
import OFDSelect from "./OFDSelect";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDMonthSelector from "./OFDMonthSelector";
import OFDAutoComplete from "./OFDAutoComplete";
import OFDDate from "./OFDDate";
import OFDFieldContainer from "./OFDFieldContainer";

import OFDDialog from "../layout/OFDDialog";
import OFDDisplayBudgetItem from "./OFDDisplayBudgetItem";

const fieldsTemplate = {
  id: null,
  type: "",
  name: "",
  budgetFrequency: "monthly",
  budgetAmount: null,
  months: null,
  asOf: null,
};

const OFDBudgetItems = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
  wizard,
}) => {
  const { getLabel, getText } = useLocalization();
  const { sortByFields, newId } = useData();
  const { userCanView, userCanUpdate } = useSecurity();
  const { isDesktop, windowHeight } = useWindow();

  const [fields, setFields] = useState({ ...fieldsTemplate, id: newId() });
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [fieldMessages, setFieldMessages] = useState({});

  const [sortedList, setSortedList] = useState([]);

  const fieldsRef = useRef();

  useEffect(() => {
    if (!value || !Array.isArray(value)) return;

    setSortedList(sortByFields(value, ["type", "name"]));
  }, [value]);

  const handleCancel = () => {
    setEditMode("add");
    setFields({ ...fieldsTemplate, id: newId() });
    setSelectedItem(null);
    setFieldMessages({});
  };

  const handleAddItem = () => {
    setEditMode("add");

    if (!isItemValid()) return;

    let list = [];
    let fieldValues = {
      ...fields,
      id: newId(),
      budgetType: fields.budgetType ? fields.budgetType : "expense",
    };
    if (Array.isArray(value)) {
      list = [...value, fieldValues];
    } else {
      list = [fieldValues];
    }

    handleChange(list);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
    setFieldMessages({});
  };

  const handleUpdateItem = () => {
    if (!isItemValid()) return;

    let list = [];

    for (const item of value) {
      if (item.id !== selectedItem.id) {
        list.push({ ...item });
      } else {
        list.push({ ...fields });
      }
    }

    handleChange(list);
  };

  const isItemValid = () => {
    setFieldMessages({});

    let valid = true;

    if (!fields.name || fields.name.trim().length === 0) {
      addFieldMessage({ field: "name", message: "isEmpty" });
      valid = false;
    }

    // if (!fields.budgetAmount) {
    //   addFieldMessage({ field: "budgetAmount", message: "isEmpty" });
    //   valid = false;
    // }

    // if (fields.budgetAmount <= 0) {
    //   addFieldMessage({
    //     field: "budgetAmount",
    //     message: "mustBeGreaterThanZero",
    //   });
    //   valid = false;
    // }

    if (fields.budgetFrequency === "specified") {
      if (!fields.months || fields.months.length === 0) {
        addFieldMessage({ field: "months", message: "selectMonths" });
        valid = false;
      }
    }

    if (editMode === "add") {
      if (Array.isArray(value)) {
        let index = -1;
        if (fields.type) {
          index = value.findIndex(
            (item) =>
              item.type === fields.type &&
              item.name.toLowerCase() === fields.name.toLowerCase()
          );
        } else {
          index = value.findIndex(
            (item) => item.name.toLowerCase() === fields.name.toLowerCase()
          );
        }

        if (index > -1) {
          addFieldMessage({ field: "name", message: "alreadyExists" });
          valid = false;
        }
      }
    }

    return valid;
  };

  const addFieldMessage = (message) => {
    setFieldMessages((current) => {
      return { ...current, [message.field]: message.message };
    });
  };

  const handleDeleteItem = () => {
    if (!selectedItem) return;

    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const budgetItem of value) {
        if (budgetItem.id !== selectedItem.id) {
          list.push({ ...budgetItem });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const handleChange = (list) => {
    onChange(list);
    setFields({ ...fieldsTemplate, id: newId() });
    setEditMode("add");
    setSelectedItem(null);
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      let months = current.months;
      if (field === "months") months = newValue;
      if (field === "budgetFrequency" && newValue !== "specified")
        months = null;

      return {
        ...current,
        [field]: newValue,
        months,
      };
    });
  };

  const getTypes = () => {
    let options = [];

    if (Array.isArray(value)) {
      for (const item of value) {
        if (item.type && !options.includes(item.type)) {
          options.push(item.type);
        }
      }
    }

    return options;
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <OFDFieldContainer
        id={id}
        sx={{ height: `${windowHeight - 200}px` }}
      >
        <Box ref={fieldsRef}>
          <Box
            ref={fieldsRef}
            sx={{ marginBottom: "1rem" }}
          >
            <Typography
              variant="h5"
              align="center"
            >
              {getLabel(label)}
            </Typography>
          </Box>

          <Stack
            sx={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
            spacing={1}
          >
            <Box>
              <OFDSelect
                id="budgetType"
                label="budgetType"
                value={getFieldValue("budgetType") || "expense"}
                onChange={(newValue) =>
                  updateFieldValue("budgetType", newValue)
                }
                listName="budgetType"
                variant="outlined"
                disabled={isDisabled()}
                fullWidth
              />
            </Box>
            <Box
              sx={{ display: "flex" }}
              gap={1}
            >
              <Box sx={{ width: "45%" }}>
                <OFDAutoComplete
                  id="type"
                  label="type"
                  value={getFieldValue("type") || ""}
                  onChange={(newValue) => updateFieldValue("type", newValue)}
                  options={getTypes()}
                  disabled={isDisabled()}
                  fullWidth
                />
              </Box>
              <Box sx={{ width: "65%" }}>
                <OFDTextField
                  id="name"
                  label="name"
                  value={getFieldValue("name") || ""}
                  onChange={(newValue) => updateFieldValue("name", newValue)}
                  fullWidth
                  disabled={isDisabled()}
                  required
                  error={fieldMessages.name ? true : false}
                  message={fieldMessages.name}
                />
              </Box>
            </Box>
            <Box
              sx={{ display: "flex" }}
              gap={1}
            >
              <OFDCurrency
                id="budgetAmount"
                label="budgetAmount"
                value={getFieldValue("budgetAmount") || ""}
                onChange={(newValue) =>
                  updateFieldValue("budgetAmount", Number(newValue))
                }
                disabled={isDisabled()}
                required
                error={fieldMessages.budgetAmount ? true : false}
                message={fieldMessages.budgetAmount}
              />
              <OFDSelect
                id="budgetFrequency"
                label="budgetFrequency"
                value={getFieldValue("budgetFrequency") || ""}
                onChange={(newValue) =>
                  updateFieldValue("budgetFrequency", newValue)
                }
                listName="budgetFrequency"
                variant="outlined"
                disabled={isDisabled()}
                fullWidth
              />
            </Box>

            {getFieldValue("budgetFrequency") === "specified" ? (
              <OFDMonthSelector
                id="months"
                value={getFieldValue("months") || ""}
                onChange={(newValue) => updateFieldValue("months", newValue)}
                disabled={isDisabled()}
                error={fieldMessages.months ? true : false}
                message={fieldMessages.months}
              />
            ) : null}

            <OFDDate
              id="asOf"
              label="changeAsOf"
              value={getFieldValue("asOf")}
              onChange={(newValue) => updateFieldValue("asOf", newValue)}
              message={getText("emptyAsOf")}
            />
          </Stack>

          <Box sx={{ marginTop: "1rem" }}>
            <OFDFieldGroup justifyContent="flex-end">
              <Button
                variant="outlined"
                size="small"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleDeleteItem}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={selectedItem ? handleUpdateItem : handleAddItem}
              >
                {selectedItem ? "Update" : "Add"}
              </Button>
            </OFDFieldGroup>
          </Box>

          <Divider sx={{ marginTop: ".5rem" }}>
            <Typography variant="overline">{getLabel("entries")}</Typography>
          </Divider>
        </Box>

        <Box
          sx={{
            height: `calc(100% - ${fieldsRef.current?.clientHeight}px - 1rem)`,
            overflow: "auto",
            width: "100%",
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            spacing={1}
          >
            {sortedList?.map((budgetItem) => (
              <OFDDisplayBudgetItem
                key={budgetItem.id}
                onClick={() => handleEditItem(budgetItem)}
                value={budgetItem}
                noLabel
              />
            ))}
          </Stack>
        </Box>
      </OFDFieldContainer>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default OFDBudgetItems;
