import { useEffect, useState } from "react";
import { startOfWeek, startOfToday, getDay } from "date-fns";

import useLocalization from "../../../../hooks/useLocalization";
import useMealPlan from "../../../../hooks/useMealPlan";
import usePage from "../../../../hooks/usePage";

import { Box, Modal, Stack, Typography } from "@mui/material";

import DailyPlan from "./DailyPlan";

const MealPlanWidget = ({ forDate }) => {
  const { getMessage, calendarLists, getListItemLabel } = useLocalization();
  const { getMealPlanForWeek, updateMeal } = useMealPlan();
  const { refresh } = usePage();

  const [mealPlan, setMealPlan] = useState(undefined);
  const [weekOf, setWeekOf] = useState();
  const [dow, setDow] = useState(undefined);

  useEffect(() => {
    if (!forDate) return;
    setDow(getDay(forDate));

    setWeekOf(startOfWeek(forDate));
  }, [forDate]);

  useEffect(() => {
    if (!weekOf) return;

    getData();
  }, [weekOf, refresh]);

  const getData = async () => {
    const results = await getMealPlanForWeek(weekOf);

    if (results.isSuccessful) {
      setMealPlan(results.data[0]);
    } else {
      setMealPlan(null);
    }
  };

  if (mealPlan === undefined || dow === undefined) return;

  return (
    <>
      <Box>
        {Array.isArray(mealPlan.dailyPlans) &&
        mealPlan.dailyPlans.length > 0 ? (
          <DailyPlan
            dailyPlan={mealPlan.dailyPlans[getDay(forDate)]}
            dow={dow}
            mealPlanId={mealPlan.id}
            onUpdated={getData}
          />
        ) : (
          <Typography>{getMessage("noMealPlan")}</Typography>
        )}
      </Box>
    </>
  );
};

export default MealPlanWidget;
