import { Box, Stack } from "@mui/material";

import useSecurity from "../../hooks/useSecurity";
import useWindow from "../../hooks/useWindow";

import OFDDate from "./OFDDate";
import OFDTime from "./OFDTime";
import OFDSelect from "./OFDSelect";

import OFDSpecificDates from "./OFDSpecificDates";
import OFDRelativeDays from "./OFDRelativeDays";
import OFDFormControl from "./OFDFormControl";

const OFDMonthlySchedule = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { userCanView, userCanUpdate } = useSecurity();
  const { isMobile } = useWindow();

  const handleChange = (field, newValue) => {
    onChange({
      ...value,
      [field]: newValue,
      monthlyType: value.monthlyType ? value.monthlyType : "specific",
    });
  };

  const handleMonthlyTypeChange = (newMonthlyType) => {
    onChange({
      ...value,
      monthlyType: newMonthlyType,
      dates: null,
      days: null,
    });
  };

  const handleDatesChange = (newDates) => {
    onChange({
      ...value,
      dates: newDates,
      days: null,
      monthlyType: value.monthlyType ? value.monthlyType : "specific",
    });
  };

  const handleDaysChange = (newDays) => {
    onChange({
      ...value,
      dates: null,
      days: newDays,
    });
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <OFDFormControl
      label={label}
      message={message}
      error={error}
    >
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
          gap={1}
        >
          <Box>
            <OFDDate
              id="startDate"
              label="from"
              value={value.startDate || null}
              onChange={(newValue) => handleChange("startDate", newValue)}
              // disabled={isDisabled()}
            />
          </Box>
          <Box>
            <OFDDate
              id="endDate"
              label="to"
              value={value.endDate || null}
              onChange={(newValue) => handleChange("endDate", newValue)}
              // disabled={isDisabled()}
            />
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <OFDSelect
            id="monthlyType"
            label="monthlyType"
            value={value.monthlyType || "specific"}
            onChange={(newValue) => {
              handleMonthlyTypeChange(newValue);
            }}
            listName="monthlyTypes"
            fullWidth
            disabled={isDisabled()}
          />
        </Box>

        {value.monthlyType === "specific" || !value.monthlyType ? (
          <OFDSpecificDates
            value={value.dates}
            onChange={handleDatesChange}
            disabled={isDisabled()}
          />
        ) : (
          <OFDRelativeDays
            value={value.days}
            onChange={handleDaysChange}
            disabled={isDisabled()}
          />
        )}
      </Stack>
    </OFDFormControl>
  );
};

export default OFDMonthlySchedule;
