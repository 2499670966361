import { useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import RecipePlaceholder from "../../../../assets/images/RecipePlaceholder.jpeg";

import OFDDisplayUrl from "../../../ui/OFDDisplayUrl";
import OFDFieldGroup from "../../../ui/OFDFieldGroup";
import OFDDisplayText from "../../../ui/OFDDisplayText";
import OFDDisplayIngredients from "../../../ui/OFDDisplayIngredients";
import OFDDisplayInstructions from "../../../ui/OFDDisplayInstructions";
import OFDIcon from "../../../ui/OFDIcon";

import RecipeFullView from "./RecipeFullView";

const RecipeDetails = ({ recipe, height, width }) => {
  const [openModal, setOpenModal] = useState(false);

  if (!recipe) return;

  return (
    <>
      <Box sx={{ width: width }}>
        <Box
          sx={{ display: "flex" }}
          gap={3}
        >
          <Box sx={{ height: height, overflow: "auto" }}>
            <Stack spacing={2}>
              <Box
                sx={{ display: "flex" }}
                gap={2}
              >
                <Box>
                  <img
                    src={recipe.image ? recipe.image : RecipePlaceholder}
                    style={{
                      borderRadius: ".5rem",
                      width: "auto",
                      height: "80px",
                    }}
                  />
                </Box>

                <Box>
                  <Box
                    sx={{ display: "flex" }}
                    gap={3}
                  >
                    <Typography variant="h5">{recipe.name}</Typography>
                    <OFDIcon
                      name="openPage"
                      color="orange"
                      shade={900}
                      onClick={() => setOpenModal(true)}
                    />
                  </Box>

                  {recipe.externalUrl ? (
                    <OFDDisplayUrl value={recipe.externalUrl} />
                  ) : null}
                </Box>
              </Box>

              {recipe.description ? (
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                  {recipe.description}
                </Typography>
              ) : null}

              {recipe.preparationTime || recipe.cookTime || recipe.servings ? (
                <OFDFieldGroup>
                  <OFDDisplayText
                    id="preparationTime"
                    label="preparationTime"
                    value={recipe.preparationTime}
                  />
                  <OFDDisplayText
                    id="cookTime"
                    label="cookTime"
                    value={recipe.cookTime}
                  />
                  <OFDDisplayText
                    id="servings"
                    label="servings"
                    value={recipe.servings}
                  />
                </OFDFieldGroup>
              ) : null}

              {Array.isArray(recipe.ingredients) &&
              recipe.ingredients.length > 0 ? (
                <OFDDisplayIngredients
                  label="ingredients"
                  value={recipe.ingredients}
                />
              ) : null}

              {Array.isArray(recipe.instructions) &&
              recipe.instructions.length > 0 ? (
                <OFDDisplayInstructions
                  label="instructions"
                  value={recipe.instructions}
                />
              ) : null}
            </Stack>
          </Box>
        </Box>
      </Box>

      {openModal ? (
        <RecipeFullView
          recipe={recipe}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      ) : null}
    </>
  );
};

export default RecipeDetails;
