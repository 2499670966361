import { useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDCategoryIcon from "./OFDCategoryIcon";

const OFDDisplayCategory = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={onClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>

      {value ? (
        <OFDCategoryIcon
          id={value}
          selected
          size="medium"
        />
      ) : (
        <Typography>-</Typography>
      )}
    </Box>
  );
};

export default OFDDisplayCategory;
