import useApi from "./useApi";

const useQueue = () => {
  const { postData } = useApi();

  const addToQueue = async (transactionType, payload) => {
    const results = await postData({
      entityName: "Queue",
      action: "add",
      data: {
        transactionType,
        payload,
      },
    });

    return results;
  };

  return {
    addToQueue,
  };
};

export default useQueue;
