import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDRecordSecurity from "../../ui/OFDRecordSecurity";

const BudgetSecurity = ({ security, onChange }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">{getLabel("security")}</Typography>

      <Stack spacing={2}>
        {getWizard("budget").budgetSecurity.instructions.map(
          (instruction, index) => (
            <Typography key={`instruction-${index}`}>{instruction}</Typography>
          )
        )}

        <OFDRecordSecurity
          id="security"
          label="security"
          value={security}
          onChange={(newValue) => onChange(newValue)}
        />
      </Stack>
    </Box>
  );
};

export default BudgetSecurity;
