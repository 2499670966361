import useLocalization from "../../hooks/useLocalization";

import { Typography } from "@mui/material";

const OFDTitle = ({ title }) => {
  const { getTitle } = useLocalization();

  return (
    <Typography
      align="center"
      variant="h5"
    >
      {getTitle(title)}
    </Typography>
  );
};

export default OFDTitle;
