import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDFolderSecurity from "../../ui/OFDFolderSecurity";

const FolderSecurity = ({ folderSecurity, onChange }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  return (
    <Box>
      <Typography variant="h5">{getTitle("folderSecurity")}</Typography>

      <Stack spacing={2}>
        {getWizard("folder").folderSecurity.instructions.map(
          (instruction, index) => (
            <Typography key={`instruction-${index}`}>{instruction}</Typography>
          )
        )}

        <OFDFolderSecurity
          id="folderSecurity"
          label="folderSecurity"
          value={folderSecurity}
          onChange={(newValue) => onChange(newValue)}
        />
      </Stack>
    </Box>
  );
};

export default FolderSecurity;
