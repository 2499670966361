import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography, Popover, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../ui/OFDIcon";

const ReminderItem = ({ calendar, onEdit, onDelete, onDelay }) => {
  const { userCanUpdate, userCanDelete } = useSecurity();
  const { getLabel } = useLocalization();
  const theme = useTheme();

  const [showMenu, setShowMenu] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);
  const [menuItems, setMenuItems] = useState(null);

  useEffect(() => {
    let items = [];

    if (calendar.frequency !== "onetime") {
      if (userCanUpdate(calendar.security, calendar.addedBy)) {
        items.push({ value: "editSeries", label: "editSeries" });
        items.push({ value: "editOccurrence", label: "editOccurrence" });
      }
      if (userCanDelete(calendar.security, calendar.addedBy)) {
        items.push({ value: "deleteSeries", label: "deleteSeries" });
        items.push({ value: "deleteOccurrence", label: "deleteOccurrence" });
      }
    } else {
      if (userCanUpdate(calendar.security, calendar.addedBy)) {
        items.push({ value: "editOccurrence", label: "editReminder" });
      }
      if (userCanDelete(calendar.security, calendar.addedBy)) {
        items.push({ value: "deleteOccurrence", label: "deleteReminder" });
      }
    }

    setMenuItems(items);
  }, [calendar]);

  const handleMenuClick = (option) => {
    if (option === "editSeries") onEdit(calendar, "series");
    if (option === "editOccurrence") onEdit(calendar, "occurrence");
    if (option === "deleteSeries") onDelete(calendar, "series");
    if (option === "deleteOccurrence") onDelete(calendar, "occurrence");
  };

  const handleOpenMenu = (e) => {
    setAnchorE1(e.target);
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorE1(null);
    setShowMenu(false);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #e0e0e0",
          paddingBottom: "8px",
          paddingTop: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            gap={1}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px",
                alignItems: "flex-start",
              }}
              gap={0.5}
            >
              <OFDIcon
                name={calendar.sent ? "checkcircle" : calendar.category}
              />
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  {calendar.name}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <OFDIcon
              name="time"
              size="xsmall"
              color="grey"
            />
            <Typography noWrap>
              {format(calendar.startDate, "h:mm a")}
            </Typography>

            <Box onClick={handleOpenMenu}>
              <OFDIcon
                name="verticalEllipse"
                color="grey"
              />
            </Box>
          </Box>
        </Box>

        {calendar.description ? (
          <Box sx={{ marginLeft: "32px", marginRight: "5px" }}>
            <Typography
              variant="caption"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {calendar.description}
            </Typography>
          </Box>
        ) : null}
      </Box>

      <Popover
        open={showMenu}
        onClose={handleCloseMenu}
        anchorEl={anchorE1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack
          spacing={3}
          sx={{
            backgroundColor: "#ffffff",
            padding: "1rem",
            borderRadius: "8px",
            boxShadow: theme.shadows[8],
          }}
        >
          {menuItems?.map((menuItem) => (
            <Box
              key={menuItem.value}
              onClick={() => handleMenuClick(menuItem.value)}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <OFDIcon name={menuItem.icon} />
              {getLabel(menuItem.label)}
            </Box>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default ReminderItem;
