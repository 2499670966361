import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

const GroceryIntro = () => {
  const { getWizard } = useLocalization();

  return (
    <Box>
      <Stack spacing={2}>
        {getWizard("grocery").groceryIntro.map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default GroceryIntro;
