import { useEffect, useState } from "react";
import { isBefore, isAfter, isEqual, startOfToday, endOfToday } from "date-fns";

import useApi from "./useApi";
import useFamily from "./useFamily";
import useDebug from "./useDebug";
import useData from "./useData";
import useMessage from "./useMessage";
import useMember from "./useMember";
import useSecurity from "./useSecurity";
import usePage from "./usePage";

import Configuration from "../configuration/Configuration";
import Pages from "../configuration/Pages";

const useEntity = (entityName) => {
  const { consoleLog } = useDebug(false);
  const { family, familyModules, familyPreferences } = useFamily();
  const { sort } = useData();
  const { destructureResults } = useMessage();
  const { member } = useMember();
  const { userCan } = useSecurity();
  const { getPageSecurityByEntityName } = usePage();

  const [entityList, setEntityList] = useState(undefined);
  const [sourceEntity, setSourceEntity] = useState(undefined);
  const [searchResults, setSearchResults] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState(null);
  const [entityMessage, setEntityMessage] = useState(null);
  const [toolbar, setToolbar] = useState(null);

  const { getData, deleteData, postData } = useApi();

  useEffect(() => {
    if (!entityName) return;
    getToolbar();
  }, [entityName, Configuration, member]);

  const getEntityList = async (args) => {
    setEntityList(undefined);
    setSearchResults(null);
    let filter = args && args.filter ? args.filter : null;
    let sortFields = args && args.sortFields ? args.sortFields : null;

    const results = await getData({
      entityName,
      action: "get",
      options: { level: "basic" },
      filter: {
        familyId: family.id,
        ...filter,
      },
    });

    consoleLog("useEntity", "getEntityList", { results });

    if (results.isSuccessful) {
      if (sortFields) {
        setEntityList(
          sort(results.data, sortFields.field, sortFields.order || "asc")
        );
      } else {
        setEntityList(results.data);
      }
    } else {
      setEntityList(null);
    }

    setSearchCriteria(null);
    setSearchResults(null);

    return results;
  };

  const getSourceEntity = async (source) => {
    const results = await getData({
      entityName: source.model,
      action: "get",
      id: source.id,
    });

    if (results && results.isSuccessful) {
      setSourceEntity(results.data);
    } else {
      setSourceEntity(null);
    }
  };

  useEffect(() => {
    setSearchResults(entityList);
  }, [entityList]);

  useEffect(() => {
    if (!searchCriteria) {
      setSearchResults(entityList);
    } else {
      applySearchCriteria();
    }
  }, [searchCriteria]);

  const getSearchFields = () => {
    if (
      !Configuration ||
      !Configuration.entity[entityName] ||
      !Configuration.entity[entityName].fields
    ) {
      return [];
    }

    let searchFields = Configuration.entity[entityName].fields.filter(
      (field) => field.search
    );

    return searchFields;
  };

  const applySearchCriteria = () => {
    setSearchResults(() => {
      let items = [];

      for (const item of entityList) {
        if (returnItem(item)) {
          items.push({ ...item });
        }
      }

      return items;
    });
  };

  const returnItem = (item) => {
    let returnThis = true;

    for (const searchField of searchCriteria) {
      switch (searchField.fieldType) {
        case "multiSelect":
          returnThis = searchMultiSelect(
            item[searchField.id],
            searchField.value
          );
          break;

        case "select":
          returnThis = searchSelect(item[searchField.id], searchField.value);
          break;

        case "textField":
          returnThis = searchText(item[searchField.id], searchField.value);
          break;

        case "textNote":
          returnThis = searchText(item[searchField.id], searchField.value);
          break;

        case "noteEditor":
          returnThis = searchText(item[searchField.id], searchField.value);
          break;

        case "address":
          returnThis = searchAddress(item[searchField.id], searchField.value);
          break;

        case "ingredients":
          returnThis = searchIngredients(
            item[searchField.id],
            searchField.value
          );
          break;

        case "date":
          returnThis = searchDateRange(item[searchField.id], searchField.value);
          break;

        case "switch":
          returnThis = searchBoolean(item[searchField.id], searchField.value);
          break;
      }
    }

    return returnThis;
  };

  const searchText = (value, searchValue) => {
    if (!searchValue) return true;
    if (!value) return false;

    let startsWithWildCard =
      searchValue.toLowerCase().substring(0, 1) === "*" ? true : false;
    let endsWithWildCard =
      searchValue.toLowerCase().substring(searchValue.length - 1) === "*"
        ? true
        : false;

    if (!startsWithWildCard && !endsWithWildCard) {
      let searchIndex = value.toLowerCase().indexOf(searchValue.toLowerCase());
      if (searchIndex === -1) {
        return false;
      } else {
        return true;
      }
    }

    let minusAstrisk = searchValue.replace("*", "");
    let length = minusAstrisk.length;

    if (startsWithWildCard) {
      if (
        value.toLowerCase().substring(value.length - length) ===
        minusAstrisk.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (endsWithWildCard) {
      if (
        value.toLowerCase().substring(0, length) === minusAstrisk.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const searchAddress = (value, searchValue) => {
    if (!searchValue) return true;
    if (!value) return false;

    let results = true;

    if (searchValue.name && !searchText(value.name, searchValue.name)) {
      results = false;
    }

    if (
      searchValue.streetAddress &&
      !searchText(value.streetAddress, searchValue.streetAddress)
    ) {
      results = false;
    }

    if (
      searchValue.otherAddress &&
      !searchText(value.otherAddress, searchValue.otherAddress)
    ) {
      results = false;
    }

    if (searchValue.city && !searchText(value.city, searchValue.city)) {
      results = false;
    }

    if (
      searchValue.province &&
      !searchText(value.province, searchValue.province)
    ) {
      results = false;
    }

    if (
      searchValue.country &&
      !searchText(value.country, searchValue.country)
    ) {
      results = false;
    }

    if (searchValue.postal && !searchText(value.postal, searchValue.postal)) {
      results = false;
    }

    return results;
  };

  const searchIngredients = (value, searchValue) => {
    if (!searchValue) return true;
    if (!value) return false;
    if (!Array.isArray(value)) return true;

    let found = false;

    for (const ingredient of value) {
      if (ingredient.name) {
        let searchIndex = ingredient.name
          .toLowerCase()
          .indexOf(searchValue.toLowerCase());
        if (searchIndex > -1) {
          found = true;
          break;
        }
      }
      if (ingredient.note) {
        let searchIndex = ingredient.note
          .toLowerCase()
          .indexOf(searchValue.toLowerCase());
        if (searchIndex > -1) {
          found = true;
          break;
        }
      }
    }

    return found;
  };

  const searchSelect = (value, searchValue) => {
    if (!searchValue) return true;
    if (!value) return false;

    if (searchValue === value) return true;

    return false;
  };

  const searchMultiSelect = (value, searchValue) => {
    if (!searchValue) return true;
    if (!value) return false;

    let found = false;

    for (const item of searchValue) {
      if (value.includes(item)) {
        found = true;
        break;
      }
    }

    return found;
  };

  const searchDateRange = (value, searchValue) => {
    if (searchValue[0] && isEqual(value, searchValue[0])) {
      return true;
    }
    if (searchValue[1] && isEqual(value, searchValue[1])) {
      return true;
    }

    if (searchValue[0] && searchValue[1]) {
      if (isAfter(value, searchValue[0]) && isBefore(value, searchValue[1])) {
        return true;
      }
    }

    return false;
  };

  const searchBoolean = (value, searchValue) => {
    if (value === false) return true; //switch not set
    if (searchValue === true) return true;
    return false;
  };

  const searchBudgetItem = (value, searchValue) => {};

  const hasItemMenu = () => {
    if (
      Array.isArray(Configuration.entity[entityName]?.itemMenu) &&
      Configuration.entity[entityName].itemMenu.length > 0
    ) {
      return true;
    }

    return false;
  };

  const itemMenu = () => {
    if (
      Array.isArray(Configuration.entity[entityName]?.itemMenu) &&
      Configuration.entity[entityName].itemMenu.length > 0
    ) {
      return Configuration.entity[entityName].itemMenu;
    }

    return [];
  };

  const menuClick = async (action, entity) => {
    let results = null;

    switch (action) {
      case "delete":
        results = await deleteEntity(entity.id);
        break;
    }

    setEntityMessage(destructureResults(results));

    return results;
  };

  const updateEntityMessage = (results) => {
    setEntityMessage(destructureResults(results));
  };

  const deleteEntity = async (id) => {
    const results = await deleteData({
      entityName,
      action: "delete",
      id,
    });

    return results;
  };

  const updateEntity = async (data) => {
    const results = await postData({
      entityName,
      action: "update",
      data,
    });

    return results;
  };

  const resetEntityMessage = () => {
    setEntityMessage(null);
  };

  const getToolbar = () => {
    setToolbar([]);
    if (!Configuration.entity[entityName]?.hasOwnProperty("toolbar")) {
      return;
    }

    let items = [];

    for (const toolbarItem of Configuration.entity[entityName].toolbar) {
      const pageSecurity = getPageSecurityByEntityName(entityName);

      if (!userCan(toolbarItem.action, pageSecurity)) continue;

      items.push({ ...toolbarItem });
    }

    setToolbar(items);
  };

  return {
    entityList,
    getEntityList,
    updateEntity,
    entityConfig: Configuration.entity[entityName],
    toolbar: toolbar,
    defaultValues: Configuration.entity[entityName]?.defaultValues,
    availableFields: Configuration.entity[entityName]?.availableFields,
    getSearchFields,
    setSearchCriteria,
    searchCriteria,
    searchResults,
    allowFolders: Configuration.entity[entityName]?.allowFolders,
    hasItemMenu,
    itemMenu,
    menuClick,
    entityMessage,
    resetEntityMessage,
    updateEntityMessage,
    setEntityList,
    getToolbar,
    toolbar,
    getSourceEntity,
    sourceEntity,
  };
};

export default useEntity;
