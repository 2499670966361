import { useEffect, useState, useRef } from "react";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";

import { Box, Popover, Stack, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";
import OFDDialog from "../layout/OFDDialog";
import SecurityEditor from "../editors/SecurityEditor";
import OFDContainer from "../ui/OFDContainer";

const FolderDisplay = ({
  folder,
  path,
  onFolderSelect,
  onDocumentSelect,
  onDocumentDelete,
  onDocumentMove,
  onDocumentRename,
  onFolderDelete,
  onFolderMove,
  onFolderRename,
  getFolderStructure,
  onSecurityChange,
}) => {
  const { getLabel } = useLocalization();
  const { getColor } = useColor();
  const { userCanView, userCanUpdate, userCanDelete, userIsCreator } =
    useSecurity();
  const { member, memberColor } = useMember();

  const [breadcrumbs, setBreadcrumbs] = useState("");

  const [openDocumentMenu, setOpenDocumentMenu] = useState(false);
  const [documentAnchor, setDocumentAnchor] = useState(null);
  const [openDeleteDocument, setOpenDeleteDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openDocumentMove, setOpenDocumentMove] = useState(false);
  const [openRenameDocument, setOpenRenameDocument] = useState(false);

  const [openFolderMenu, setOpenFolderMenu] = useState(false);
  const [folderAnchor, setFolderAnchor] = useState(null);
  const [openDeleteFolder, setOpenDeleteFolder] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openFolderMove, setOpenFolderMove] = useState(false);
  const [openRenameFolder, setOpenRenameFolder] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [openSecurity, setOpenSecurity] = useState(false);

  useEffect(() => {
    buildBreadcrumbs();
  }, [folder]);

  const buildBreadcrumbs = () => {
    let crumbs = [];

    for (const item of path) {
      crumbs.push(
        <Box
          key={item.id}
          sx={{ display: "flex" }}
          gap={1}
        >
          <Typography
            sx={{
              ...memberColor({
                shade: folder.id === item.id ? 500 : 100,
              }),
              padding: "2px",
              paddingLeft: "6px",
              paddingRight: "6px",
              borderRadius: "4px",
            }}
            onClick={() => onFolderSelect(item)}
            noWrap
          >
            {item.name}
          </Typography>
          <Typography>/</Typography>
        </Box>
      );
    }

    setBreadcrumbs(crumbs);
  };

  const handleOpenDocumentMenu = (target, document) => {
    setSelectedFolder(null);
    setSelectedDocument(document);
    setSelectedItem(document);
    setDocumentAnchor(target);
    setOpenDocumentMenu(true);
  };

  const handleCloseDocumentMenu = () => {
    setDocumentAnchor(null);
    setOpenDocumentMenu(false);
  };

  const handleDocumentMenuClick = (action) => {
    setDocumentAnchor(null);
    setOpenDocumentMenu(false);

    if (action === "delete") {
      setOpenDeleteDocument(true);
    } else if (action === "move") {
      setOpenDocumentMove(true);
    } else if (action === "rename") {
      setOpenRenameDocument(true);
    } else if (action === "security") {
      setOpenSecurity(true);
    }
  };

  const handleConfirmDeleteDocument = (response) => {
    setOpenDeleteDocument(false);
    if (response === "yes") {
      onDocumentDelete(selectedDocument);
    }
  };

  const handleConfirmMoveDocument = (response, formData) => {
    if (response === "cancel") {
      setOpenDocumentMove(false);
      return;
    }
    if (!formData || !formData.folder) return;

    setOpenDocumentMove(false);
    onDocumentMove(selectedDocument, formData.folder);
  };

  const handleOpenFolderMenu = (target, document) => {
    setSelectedDocument(null);
    setSelectedFolder(document);
    setSelectedItem(document);
    setFolderAnchor(target);
    setOpenFolderMenu(true);
  };

  const handleCloseFolderMenu = () => {
    setFolderAnchor(null);
    setOpenFolderMenu(false);
  };

  const handleFolderMenuClick = (action) => {
    setFolderAnchor(null);
    setOpenFolderMenu(false);

    if (action === "delete") {
      setOpenDeleteFolder(true);
    } else if (action === "move") {
      setOpenFolderMove(true);
    } else if (action === "rename") {
      setOpenRenameFolder(true);
    } else if (action === "security") {
      setOpenSecurity(true);
    }
  };

  const handleConfirmDeleteFolder = (response) => {
    setOpenDeleteFolder(false);
    if (response === "yes") {
      onFolderDelete(selectedFolder);
    }
  };

  const handleConfirmMoveFolder = (response, formData) => {
    if (response === "cancel") {
      setOpenFolderMove(false);
      return;
    }
    if (!formData || !formData.folder) return;

    setOpenFolderMove(false);
    onFolderMove(selectedFolder, formData.folder);
  };

  const handleConfirmRenameDocument = (response, formData) => {
    if (response === "cancel") {
      setOpenRenameDocument(false);
      return;
    }
    if (!formData || !formData.name) return;

    setOpenRenameDocument(false);
    onDocumentRename(selectedDocument, formData.name);
  };

  const handleConfirmRenameFolder = (response, formData) => {
    if (response === "cancel") {
      setOpenRenameFolder(false);
      return;
    }
    if (!formData || !formData.name) return;

    setOpenRenameFolder(false);
    onFolderRename(selectedFolder, formData.name);
  };

  const handleMenuItemClick = (type, action) => {
    if (type === "folder") {
      handleFolderMenuClick(action);
    } else {
      handleDocumentMenuClick(action);
    }
  };

  const menuItems = (type, record) => {
    if (!record.security) return null;
    if (!userCanUpdate(record.security, record.addedBy)) return null;

    let items = [];

    items.push(
      <Box
        key="move"
        onClick={() => handleMenuItemClick(type, "move")}
        sx={{ display: "flex" }}
        gap={1}
      >
        <OFDIcon
          name="move"
          color="grey"
        />
        <Typography noWrap>{getLabel("move")}</Typography>
      </Box>
    );

    items.push(
      <Box
        key="rename"
        onClick={() => handleMenuItemClick(type, "rename")}
        sx={{ display: "flex" }}
        gap={1}
      >
        <OFDIcon
          name="rename"
          color="grey"
        />
        <Typography noWrap>{getLabel("rename")}</Typography>
      </Box>
    );

    if (userCanDelete(record.security, record.addedBy)) {
      items.push(
        <Box
          key="delete"
          onClick={() => handleMenuItemClick(type, "delete")}
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDIcon
            name="delete"
            color="grey"
          />
          <Typography noWrap>{getLabel("delete")}</Typography>
        </Box>
      );
    }

    if (userIsCreator(record.addedBy)) {
      items.push(
        <Box
          key="security"
          onClick={() => handleMenuItemClick(type, "security")}
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDIcon
            name="security"
            color="grey"
          />
          <Typography noWrap>{getLabel("security")}</Typography>
        </Box>
      );
    }

    return items;
  };

  const handleChangeSecurity = async (newSecurity) => {
    let entityName = selectedFolder ? "Folder" : "Document";

    await onSecurityChange(entityName, selectedItem, newSecurity);
  };

  if (!userCanView(folder.security, folder.addedBy)) return;

  return (
    <>
      <Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: ".25rem",
            borderRadius: "8px",
            marginTop: ".5rem",
            marginBottom: "1rem",
          }}
        >
          <Box
            sx={{ padding: "4px", display: "flex", flexWrap: "wrap" }}
            gap={1}
          >
            {breadcrumbs}
          </Box>
        </Box>

        {Array.isArray(folder.folders) && folder.folders.length > 0 ? (
          <OFDContainer label={getLabel("folders")}>
            <Stack
              spacing={1.5}
              sx={{ marginBottom: ".5rem" }}
            >
              {folder.folders.map((subFolder) => {
                if (!userCanView(subFolder.security, subFolder.addedBy)) return;

                return (
                  <Box
                    key={subFolder.id}
                    sx={{
                      display: "flex",
                      backgroundColor: "#fff",
                      padding: "4px",
                      borderRadius: "4px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "flex-start" }}
                      gap={0.5}
                      onClick={() => {
                        onFolderSelect(subFolder);
                      }}
                    >
                      <OFDIcon
                        name="folder"
                        color={member.color}
                        shade={500}
                      />
                      <Box>
                        <Typography>{subFolder.name}</Typography>
                        {subFolder.description ? (
                          <Typography variant="caption">
                            {subFolder.description}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>

                    {menuItems("folder", subFolder) ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                        onClick={(e) =>
                          handleOpenFolderMenu(e.target, subFolder)
                        }
                      >
                        <OFDIcon
                          name="verticalEllipse"
                          color="grey"
                        />
                      </Box>
                    ) : null}

                    <Popover
                      open={openFolderMenu}
                      onClose={handleCloseFolderMenu}
                      anchorEl={folderAnchor}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Stack
                        sx={{ minWidth: "150px", padding: "4px" }}
                        spacing={1}
                      >
                        {menuItems("folder", subFolder)}
                      </Stack>
                    </Popover>
                  </Box>
                );
              })}
            </Stack>
          </OFDContainer>
        ) : null}

        {Array.isArray(folder.documents) && folder.documents.length > 0 ? (
          <OFDContainer
            sx={{ marginTop: ".5rem" }}
            label={getLabel("documents")}
          >
            <Stack spacing={1.5}>
              {folder.documents.map((document) => {
                if (!userCanView(document.security, document.addedBy)) return;

                return (
                  <Box
                    key={document.id}
                    sx={{
                      display: "flex",
                      backgroundColor: "#fff",
                      padding: "4px",
                      borderRadius: "4px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      gap={0.5}
                      onClick={() => onDocumentSelect(document)}
                    >
                      <OFDIcon
                        name="document"
                        color={member.color}
                        shade={500}
                      />
                      <Typography>{document.name}</Typography>
                    </Box>

                    {menuItems("document", document) ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                        onClick={(e) =>
                          handleOpenDocumentMenu(e.target, document)
                        }
                      >
                        <OFDIcon
                          name="verticalEllipse"
                          color="grey"
                        />
                      </Box>
                    ) : null}

                    <Popover
                      open={openDocumentMenu}
                      onClose={handleCloseDocumentMenu}
                      anchorEl={documentAnchor}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Stack
                        sx={{ minWidth: "150px", padding: "4px" }}
                        spacing={1}
                      >
                        {menuItems("document", document)}
                      </Stack>
                    </Popover>
                  </Box>
                );
              })}
            </Stack>
          </OFDContainer>
        ) : null}
      </Box>

      <OFDDialog
        open={openDeleteDocument}
        title="confirmDelete"
        description="deleteDocument"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDeleteDocument}
      />

      <OFDDialog
        open={openDocumentMove}
        title="moveDocument"
        description="moveDocument"
        actions={[
          {
            id: "move",
            iconName: "",
            label: "move",
          },
          {
            id: "cancel",
            iconName: "",
            label: "cancel",
          },
        ]}
        onClose={handleConfirmMoveDocument}
        fields={[
          {
            id: "folder",
            fieldType: "select",
            fieldProps: {
              id: "folder",
              label: "folders",
              options: getFolderStructure(),
              fullWidth: true,
            },
            mode: "edit",
          },
        ]}
      />

      <OFDDialog
        open={openRenameDocument}
        title="renameDocument"
        description="renameDocument"
        actions={[
          {
            id: "rename",
            iconName: "",
            label: "rename",
          },
          {
            id: "cancel",
            iconName: "",
            label: "cancel",
          },
        ]}
        onClose={handleConfirmRenameDocument}
        fields={[
          {
            id: "name",
            fieldType: "textField",
            fieldProps: {
              id: "name",
              label: "name",
              fullWidth: true,
            },
            mode: "edit",
          },
        ]}
      />

      <OFDDialog
        open={openDeleteFolder}
        title="confirmDelete"
        description="deleteDocumentFolder"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDeleteFolder}
      />

      <OFDDialog
        open={openFolderMove}
        title="moveFolder"
        description="moveFolder"
        actions={[
          {
            id: "move",
            iconName: "",
            label: "move",
          },
          {
            id: "cancel",
            iconName: "",
            label: "cancel",
          },
        ]}
        onClose={handleConfirmMoveFolder}
        fields={[
          {
            id: "folder",
            fieldType: "select",
            fieldProps: {
              id: "folder",
              label: "folders",
              options: getFolderStructure(),
              fullWidth: true,
            },
            mode: "edit",
          },
        ]}
      />

      <OFDDialog
        open={openRenameFolder}
        title="renameFolder"
        description="renameFolder"
        actions={[
          {
            id: "rename",
            iconName: "",
            label: "rename",
          },
          {
            id: "cancel",
            iconName: "",
            label: "cancel",
          },
        ]}
        onClose={handleConfirmRenameFolder}
        fields={[
          {
            id: "name",
            fieldType: "textField",
            fieldProps: {
              id: "name",
              label: "name",
              fullWidth: true,
            },
            mode: "edit",
          },
        ]}
      />

      <SecurityEditor
        open={openSecurity}
        onClose={() => setOpenSecurity(false)}
        document={selectedItem}
        onChange={handleChangeSecurity}
        entityName="Document"
      />
    </>
  );
};

export default FolderDisplay;
