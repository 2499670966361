import { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { startOfToday, startOfDay, isBefore, parse } from "date-fns";

import useApi from "../../../../hooks/useApi";
import useSignup from "../../../../hooks/useSignup";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Button, Typography } from "@mui/material";

import ThirtyDay from "../../../emailSubscriptions/ThirtyDay";

const Success = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTitle, getLabel, text } = useLocalization();
  const { completeSignup } = useSignup();
  const { externalData, getData, postData } = useApi();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [preLaunch, setPreLaunch] = useState(false);

  useEffect(() => {
    complete();
  }, []);

  const complete = async () => {
    const sessionId = searchParams.get("session_id");
    const signupId = searchParams.get("id");

    const results = await completeSignup({ sessionId, signupId });

    if (results.isSuccessful) {
      await getSignup(signupId);
    }
  };

  const getSignup = async (signupId) => {
    const signup = await postData({
      entityName: "Signup",
      action: "getSignup",
      data: {
        id: signupId,
      },
    });

    if (signup.isSuccessful && signup.data) {
      try {
        setEmail(signup.data.email);
        setName(signup.data.memberName);
      } catch (err) {}
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const getInstructions = () => {
    let instructions = text.signup.thankyouInstructions;
    instructions = instructions.replace("{email}", "");
    return instructions;
  };

  return (
    <>
      <Box>
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {getTitle("thanksForSigningUp")}
          </Typography>
        </Box>

        <Typography sx={{ whiteSpace: "pre-wrap" }}>
          {getInstructions()}
        </Typography>

        <Box
          sx={{ display: "flex", justifyContent: "center", paddingTop: "1rem" }}
        >
          <a href="mailto:queries@ourfamilydashboard.com">
            queries@ourfamilydashboard.com
          </a>
        </Box>

        {!preLaunch ? (
          <Box sx={{ marginTop: "1rem" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ paddingTop: "1rem" }}
            >
              <Button
                variant="contained"
                onClick={handleLogin}
              >
                {getLabel("login")}
              </Button>
            </Box>
            <Typography
              align="center"
              sx={{ marginTop: "1rem" }}
            >
              Use your email and password provided previously.
            </Typography>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default Success;
