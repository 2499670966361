import { useEffect } from "react";
import { format, isDate } from "date-fns";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import RecurrenceLabel from "./RecurrenceLabel";
import RecurrenceTimeFrame from "./RecurrenceTimeFrame";
import RecurrenceDays from "./RecurrenceDays";

const RecurrenceDisplay = ({ entityData }) => {
  const { getLabel, calendarLists } = useLocalization();

  if (!entityData || !isDate(entityData.startDate)) return null;

  return (
    <Box>
      {entityData.frequency === "onetime" ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">{`${getLabel("once")} : ${format(
            entityData.startDate,
            "MMM d, yyyy"
          )}`}</Typography>

          {entityData.fromTime || entityData.toTime ? (
            <Typography variant="caption">
              {getLabel("time")} :
              {entityData.fromTime
                ? format(entityData.fromTime, " h:mm a")
                : null}
              {entityData.toTime
                ? ` to ${format(entityData.toTime, "h:mm a")}`
                : null}
            </Typography>
          ) : null}
        </Box>
      ) : (
        <Box>
          <Stack>
            <RecurrenceLabel recurrence={entityData.recurrence} />
            <RecurrenceTimeFrame recurrence={entityData.recurrence} />
            <RecurrenceDays recurrence={entityData.recurrence} />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default RecurrenceDisplay;
