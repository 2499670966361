import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayNote from "./OFDDisplayNote";
import OFDCollapsable from "../layout/OFDCollapsable";

const OFDDisplayList = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={displayStyle}>
      <Typography
        variant="overline"
        onClick={handleClick}
      >
        {getLabel(label)}
      </Typography>

      <Box>
        <Stack spacing={0.5}>
          {value?.map((item) => (
            <OFDCollapsable
              key={item.id}
              compressed
              header={
                <Typography onClick={handleClick}>{item.name}</Typography>
              }
              styleOverride={{ backgroundColor: "#e0e0e0" }}
            >
              {item.note ? (
                <Box sx={{ marginTop: "4px", paddingLeft: "4px" }}>
                  <OFDDisplayNote
                    value={item.note}
                    textOnly={true}
                    styleoverride={{ fontSize: "12px", color: "#535353" }}
                  />
                </Box>
              ) : null}
            </OFDCollapsable>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayList;
