import { useEffect, useState } from "react";

import useMember from "../../../hooks/useMember";
import usePage from "../../../hooks/usePage";
import useEntity from "../../../hooks/useEntity";
import useWindow from "../../../hooks/useWindow";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import SystemMenu from "./SystemMenu";
import OFDAvatar from "../../ui/OFDAvatar";
import OFDToolbar from "../OFDToolbar";
import OFDClock from "../../ui/OFDClock";
import OFDIcon from "../../ui/OFDIcon";

import Help from "../../help/Help";

const PageHeader = () => {
  const { isMobile } = useWindow();
  const { memberColor, member } = useMember();
  const { entityName, setToolbarAction, refreshPage, refresh } = usePage();
  const { entityConfig, toolbar, getToolbar } = useEntity(entityName);

  const theme = useTheme();

  const [headerHeight, setHeaderHeight] = useState(null);

  const [openHelp, setOpenHelp] = useState(false);

  useEffect(() => {
    setHeaderHeight(null);
    if (toolbar && toolbar.length > 0) {
      setHeaderHeight("125px");
    } else {
      setHeaderHeight("80px");
    }
  }, [toolbar, refresh]);

  const handleMenuItemClick = (menuItemName) => {
    setToolbarAction(menuItemName);
  };

  return (
    <>
      <Box
        id="pageHeader"
        sx={{
          position: "fixed",
          top: 0,
          left: isMobile ? 0 : 100,
          right: 0,
          height: headerHeight || "125px",
          zIndex: 1000,
        }}
      >
        <Box
          id="pageHeader"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: "80px",
            ...memberColor(),
            padding: 2,
          }}
        >
          <Box sx={{ position: "relative" }}>
            <OFDAvatar
              {...member}
              size="large"
              backgroundColor="grey"
            />

            {/* <Box sx={{ position: "absolute", top: "10px", right: "-32px" }}>
              <OFDIcon
                name="mic"
                color="grey"
                shade={50}
                size="medium"
                onClick={handleVoice}
              />
            </Box> */}
          </Box>

          <OFDClock />

          <Box sx={{ display: "flex", position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: "5px",
                left: "-36px",
              }}
            >
              <Box>
                <OFDIcon
                  name="refresh"
                  onClick={() => {
                    refreshPage();
                  }}
                  theme="contrast"
                  size="medium"
                />
              </Box>
            </Box>

            <SystemMenu />
          </Box>
        </Box>

        {Array.isArray(toolbar) && toolbar.length > 0 ? (
          <OFDToolbar
            menuItems={toolbar}
            onClick={handleMenuItemClick}
          />
        ) : null}
      </Box>

      <Help
        open={openHelp}
        onClose={() => setOpenHelp(false)}
      />
    </>
  );
};

export default PageHeader;
