import { useEffect, useState } from "react";
import { format } from "date-fns";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useMember from "../../hooks/useMember";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import EntityEditor from "../editors/EntityEditor";
import Search from "../forms/Search";
import SearchCriteria from "../forms/SearchCriteria";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import RecurrenceDisplay from "../display/RecurrenceDisplay";
import ToDoEditor from "../editors/ToDoEditor";

import Loading from "../layout/Loading";
import { Box, Typography } from "@mui/material";

const ToDos = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const {
    entityList,
    getEntityList,
    getSearchFields,
    searchResults,
    searchCriteria,
    setSearchCriteria,
    menuClick,
    entityMessage,
    resetEntityMessage,
    setEntityList,
    updateEntity,
  } = useEntity("ToDo");
  const { member } = useMember();

  const [openForm, setOpenForm] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    setOpenForm(false);
    setEntityName("ToDo");
    getData();
  }, [refresh]);

  const getData = async () => {
    setEntityList(undefined);
    await getEntityList({
      filter: { isComplete: false, memberId: member.id },
      sortFields: { field: "startDate", order: "asc" },
    });
  };

  useEffect(() => {
    if (toolbarAction === "addToDo") {
      setOpenForm(true);
    }
    if (toolbarAction === "search") {
      setOpenSearch(true);
    }
    resetToolbarAction();
  }, [toolbarAction]);

  const handleEditEntity = async () => {
    getData();
  };

  const handleFormClose = async () => {
    setOpenForm(false);
    resetToolbarAction();
    getData();
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleClearSearch = () => {
    setSearchCriteria(null);
    setOpenSearch(false);
  };

  const handleSearch = (newSearch) => {
    setSearchCriteria(newSearch);
    setOpenSearch(false);
  };

  const handleMenuClick = async (action, entity) => {
    setSelectedEntity(entity);

    if (action === "edit") {
      setOpenForm(true);
    } else if (action === "delete") {
      setOpenDeleteConfirmation(true);
    } else if (action === "complete") {
      let data = { ...entity, isComplete: true };
      await updateEntity(data);
      await getData();
    }
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      const results = await menuClick("delete", selectedEntity);

      if (results.isSuccessful) {
        await getData();
      }
    }
    setOpenDeleteConfirmation(false);
  };

  const customHeader = (todo) => {
    let header = (
      <Box>
        <Typography>{todo.name}</Typography>
        <RecurrenceDisplay entityData={todo} />
      </Box>
    );

    return header;
  };

  const handleSave = () => {
    handleFormClose();
    // refreshPage();
  };

  return (
    <>
      <OFDTitle title="todo" />

      <SearchCriteria
        searchCriteria={searchCriteria}
        onClear={handleClearSearch}
      />

      {entityList !== undefined ? (
        <OFDEntityList
          entityName="ToDo"
          entityList={searchResults}
          onEdit={handleEditEntity}
          onMenuItemClick={handleMenuClick}
          customHeader={customHeader}
        />
      ) : (
        <Loading />
      )}

      {openForm ? (
        <ToDoEditor
          open={openForm}
          onClose={handleFormClose}
          onSave={handleSave}
          entityData={selectedEntity}
          option="series"
        />
      ) : null}

      {openSearch ? (
        <Search
          open={openSearch}
          searchCriteria={searchCriteria}
          onClose={handleSearchClose}
          searchFields={getSearchFields()}
          onSearch={handleSearch}
        />
      ) : null}

      <OFDMessage
        message={entityMessage}
        onClose={resetEntityMessage}
      />

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_todo"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default ToDos;
