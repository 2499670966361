import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useData from "../../hooks/useData";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDTextField from "./OFDTextField";
import OFDDate from "./OFDDate";
import OFDTextNote from "./OFDTextNote";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDDisplayNote from "./OFDDisplayNote";
import OFDNumber from "./OFDNumber";
import OFDCurrency from "./OFDCurrency";
import OFDButton from "./OFDButton";

import OFDDialog from "../layout/OFDDialog";
import ExpenseEditor from "../editors/ExpenseEditor";

const fieldsTemplate = {
  id: null,
  name: "",
  date: null,
  cost: null,
  expenseId: null,
  odometer: null,
  note: "",
};

const OFDServiceLog = ({ id, label, value, onChange, source }) => {
  const { getLabel } = useLocalization();
  const { newId, sort } = useData();

  const [fields, setFields] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [openExpense, setOpenExpense] = useState(false);
  const [fieldMessages, setFieldMessages] = useState({});

  useEffect(() => {
    initializeFields();
  }, []);

  const validFields = () => {
    let isValid = true;
    let messages = {};

    if (
      !fields ||
      fields.name === undefined ||
      fields.name.trim().length === 0
    ) {
      messages.name = { error: true, message: "isEmpty" };
      isValid = false;
    }

    if (!fields || !fields.cost) {
      messages.cost = { error: true, message: "isEmpty" };
      isValid = false;
    }

    if (!fields || !fields.date) {
      messages.date = { error: true, message: "isEmpty" };
      isValid = false;
    }

    setFieldMessages(messages);
    return isValid;
  };

  const handleCancel = () => {
    setEditMode("add");
    initializeFields();
    setSelectedItem(null);
    setFieldMessages({});
  };

  const initializeFields = () => {
    setFields({ ...fieldsTemplate, id: newId() });
  };

  const handleAddItem = () => {
    setEditMode("add");

    if (!validFields()) return;

    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields }];
    } else {
      list = [{ ...fields }];
    }

    const sortedList = sort(list, "date", "desc");

    handleChange(sortedList);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
    setFieldMessages({});
  };

  const handleUpdateItem = (newFields = fields) => {
    if (!validFields()) return;

    let list = [];

    for (const bp of value) {
      if (bp.id !== selectedItem.id) {
        list.push({ ...bp });
      } else {
        list.push({ ...newFields });
      }
    }

    const sortedList = sort(list, "date", "desc");

    handleChange(sortedList);
  };

  const handleDeleteItem = () => {
    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const bp of value) {
        if (bp.id !== selectedItem.id) {
          list.push({ ...bp });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const handleChange = (list) => {
    let sortedList = sort(list, "date", "desc");
    onChange(sortedList);
    setEditMode("add");
    setSelectedItem(null);
    initializeFields();
  };

  const getFieldMessage = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return null;

    return fieldMessages[field].message;
  };

  const getFieldError = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return false;

    return fieldMessages[field].error;
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  const handleExpenseSaved = (expense) => {
    setOpenExpense(false);
    let newFields = { ...fields, expenseId: expense.id };
    setFields(newFields);
    if (selectedItem) {
      handleUpdateItem(newFields);
    }
  };

  const handleOpenExpense = () => {
    setOpenExpense(true);
  };

  if (fields === null) return null;

  return (
    <>
      <Box id={id}>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {label}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          gap={1}
        >
          <Box sx={{ width: "100%" }}>
            <OFDTextField
              id="name"
              label="name"
              value={getFieldValue("name") || ""}
              onChange={(newValue) => updateFieldValue("name", newValue)}
              fullWidth
              required
              error={getFieldError("name")}
              message={getFieldMessage("name")}
            />
          </Box>
        </Box>

        <Box>
          <OFDDate
            id="date"
            label="date"
            value={getFieldValue("date") || ""}
            onChange={(newValue) => updateFieldValue("date", newValue)}
            required
            error={getFieldError("date")}
            message={getFieldMessage("date")}
          />
        </Box>

        <Box
          sx={{ display: "flex", marginTop: ".5rem" }}
          gap={1}
        >
          <Box sx={{ width: "50%" }}>
            <OFDNumber
              id="odometer"
              label="odometer"
              value={getFieldValue("odometer") || ""}
              onChange={(newValue) => updateFieldValue("odometer", newValue)}
              fullWidth
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <OFDCurrency
              id="cost"
              label="cost"
              value={getFieldValue("cost") || ""}
              onChange={(newValue) => updateFieldValue("cost", newValue)}
              fullWidth
              required
              error={getFieldError("cost")}
              message={getFieldMessage("cost")}
            />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDTextNote
            id="note"
            label="note"
            value={getFieldValue("note") || ""}
            onChange={(newValue) => updateFieldValue("note", newValue)}
            fullWidth
          />
        </Box>

        <OFDFieldGroup
          justifyContent="flex-end"
          sx={{ marginTop: ".5rem" }}
        >
          <OFDButton
            variant="contained"
            color="grey"
            name="expense"
            label="expense"
            iconName="expense"
            onClick={handleOpenExpense}
          />
        </OFDFieldGroup>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                selectedItem ? handleUpdateItem() : handleAddItem()
              }
            >
              {selectedItem ? "Update" : "Add"}
            </Button>
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          <Stack spacing={1}>
            {Array.isArray(value)
              ? value.map((item) => (
                  <Box key={item.id}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#ccc",
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      gap={1}
                      onClick={() => handleEditItem(item)}
                    >
                      <Typography>{item.name}</Typography>
                      <Typography>
                        {item.date ? format(item.date, "MMM dd") : null}
                      </Typography>
                    </Box>

                    {item.note ? (
                      <Box
                        sx={{
                          backgroundColor: "#ebebeb",
                          borderRadius: "4px",
                          marginTop: "4px",
                          paddingTop: ".25rem",
                          paddingBottom: ".25rem",
                          paddingLeft: ".5rem",
                          maxHeight: "100px",
                          overflow: "auto",
                          fontSize: "12px",
                          color: "#535353",
                        }}
                      >
                        <OFDDisplayNote
                          value={item.note}
                          textOnly={true}
                          styleoverride={{
                            fontSize: "12px",
                            color: "#535353",
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ))
              : null}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <ExpenseEditor
        open={openExpense}
        onClose={() => setOpenExpense(false)}
        expenseId={selectedItem ? selectedItem.expenseId : null}
        onSave={handleExpenseSaved}
        initialValues={{
          name: getFieldValue("name"),
          expenseDate: getFieldValue("date"),
          expenseAmount: getFieldValue("cost"),
          frequency: "onetime",
        }}
        source={source}
        field={{
          field: "serviceLog",
          itemId: selectedItem ? selectedItem.id : fields.id,
        }}
      />
    </>
  );
};

export default OFDServiceLog;
