import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Material UI
import {
  IconButton,
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Custom UI
import OFDAvatar from "../../ui/OFDAvatar";
import OFDIcon from "../../ui/OFDIcon";

// Hooks
import useApi from "../../../hooks/useApi";
import useWindow from "../../../hooks/useWindow";
import useMember from "../../../hooks/useMember";
import useSecurity from "../../../hooks/useSecurity";
import useDebug from "../../../hooks/useDebug";
import usePage from "../../../hooks/usePage";
import useSystem from "../../../hooks/useSystem";
import useLocalization from "../../../hooks/useLocalization";
import useFamily from "../../../hooks/useFamily";

import Help from "../../help/Help";

import SetupWizard from "../../wizards/setup/SetupWizard";

import OFDButton from "../../ui/OFDButton";

import UserPin from "../UserPin";

const SystemMenu = () => {
  const navigate = useNavigate();
  const { consoleLog } = useDebug(false);
  const { localVersion } = useSystem();

  // const { profile } = useUser();

  const { windowWidth, isDesktop, isMobile } = useWindow();
  const theme = useTheme();
  const { memberColor, member } = useMember();
  const { logout, securityLevels } = useSecurity();
  const { reload, setPageMode } = usePage();
  const { getLabel } = useLocalization();
  const { family } = useFamily();
  const { postData } = useApi();

  const [open, setOpen] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openSetup, setOpenSetup] = useState(false);
  const [openPinDialog, setOpenPinDialog] = useState(false);
  const [showIDs, setShowIDs] = useState(false);

  const subscriptionLink = useRef();

  useEffect(() => {
    if (family.isFirstLogin || member.isFirstLogin) {
      setOpenSetup(true);
    }
  }, [family, member]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (page) => {
    handleDrawerClose();

    navigate(page);
  };

  const handleSubscription = () => {
    handleDrawerClose();

    subscriptionLink.current.click();
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleWindowReload = async () => {
    await reload();
  };

  const handleOpenHelp = () => {
    setOpen(false);

    setOpenHelp(true);
  };

  const handleOpenSetup = () => {
    setOpen(false);
    setOpenSetup(true);
  };

  const handleCopy = (what) => {
    navigator.clipboard.writeText(what);
  };

  const handleSwitchToDashboard = async () => {
    setOpenPinDialog(false);
    setPageMode("dashboard");
  };

  const canAccessFamilyDashboard = () => {
    if (!member || !securityLevels || !member.securityLevel) return false;
    const memberLevel = securityLevels[member.securityLevel].level;
    const dashboardSecurityLevel = securityLevels.admin.level;

    return memberLevel >= dashboardSecurityLevel;
  };

  const handleShowIDs = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      return;
    }
    setShowIDs(!showIDs);
  };

  if (!member) return <></>;

  return (
    <>
      <OFDIcon
        name="menu"
        theme="contrast"
        size="large"
        onClick={handleDrawerOpen}
      />

      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        transitionDuration={1000}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "1rem",
            borderBottomLeftRadius: "1rem",
            backgroundColor: "#f0f0f0",
            zindex: "2000",
          },
        }}
      >
        <Box
          sx={{
            ...memberColor(),
            borderTopLeftRadius: "1rem",
          }}
          paddingX="1rem"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={isMobile ? `calc(${windowWidth}px - 2rem)` : "400px"}
          height="5rem"
        >
          <OFDAvatar
            config={{ size: "large" }}
            avatar={member.avatar || null}
            name={member.name}
            onClick={handleShowIDs}
          ></OFDAvatar>
          <Typography variant="h5">System Menu</Typography>
          <IconButton onClick={handleDrawerClose}>
            <OFDIcon
              name="chevronRight"
              theme="light"
              size="large"
              // style={{ color: memberColor({ shade: 900 }).color }}
            />
          </IconButton>
        </Box>

        <Typography
          align="center"
          variant="caption"
        >{`${getLabel("version")} : ${localVersion}`}</Typography>

        <Box
          p="1rem"
          display="flex"
          flexDirection="column"
          width={isMobile ? `calc(${windowWidth}px - 2rem)` : "400px"}
        >
          <List>
            <ListItem
              key="setup"
              disablePadding
              dense={true}
            >
              <ListItemButton
                onClick={handleOpenSetup}
                sx={{ backgroundColor: "#ffffff" }}
              >
                <ListItemIcon>
                  <OFDIcon name="settings" />
                </ListItemIcon>
                <ListItemText primary="Setup"></ListItemText>
              </ListItemButton>
            </ListItem>

            <Divider
              variant="inset"
              component="div"
            />

            <ListItem
              key="help"
              disablePadding
              dense={true}
            >
              <ListItemButton
                onClick={handleOpenHelp}
                sx={{ backgroundColor: "#ffffff" }}
              >
                <ListItemIcon>
                  <OFDIcon name="help" />
                </ListItemIcon>
                <ListItemText primary="Help"></ListItemText>
              </ListItemButton>
            </ListItem>

            <Divider
              variant="inset"
              component="div"
            />

            <ListItem
              key="UserPreferences"
              disablePadding
              dense={true}
            >
              <ListItemButton
                onClick={() => handleNavigation("myaccount")}
                sx={{ backgroundColor: "#ffffff" }}
              >
                <ListItemIcon>
                  <OFDIcon name="MyPreferences" />
                </ListItemIcon>
                <ListItemText primary="My Account"></ListItemText>
              </ListItemButton>
            </ListItem>

            <Divider
              variant="inset"
              component="div"
            />

            <ListItem
              key="reload"
              disablePadding
              dense={true}
            >
              <ListItemButton
                onClick={handleWindowReload}
                sx={{ backgroundColor: "#ffffff" }}
              >
                <ListItemIcon>
                  <OFDIcon name="updateApp" />
                </ListItemIcon>
                <ListItemText primary="Update App"></ListItemText>
              </ListItemButton>
            </ListItem>

            {member &&
            member.securityLevel &&
            securityLevels[member.securityLevel].level >= 4 ? (
              <>
                <Divider
                  variant="inset"
                  component="div"
                />

                <ListItem
                  key="FamilyPreferences"
                  disablePadding
                  dense={true}
                >
                  <ListItemButton
                    onClick={() => handleNavigation("familypreferences")}
                    sx={{ backgroundColor: "#ffffff" }}
                  >
                    <ListItemIcon>
                      <OFDIcon name="familyPreferences" />
                    </ListItemIcon>
                    <ListItemText primary="Family Preferences"></ListItemText>
                  </ListItemButton>
                </ListItem>

                <Divider
                  variant="inset"
                  component="div"
                />

                <ListItem
                  key="loginAccounts"
                  disablePadding
                  dense={true}
                >
                  <ListItemButton
                    onClick={() => handleNavigation("accounts")}
                    sx={{ backgroundColor: "#ffffff" }}
                  >
                    <ListItemIcon>
                      <OFDIcon name="accounts" />
                    </ListItemIcon>
                    <ListItemText primary="User Accounts"></ListItemText>
                  </ListItemButton>
                </ListItem>

                <Divider
                  variant="inset"
                  component="div"
                />

                <ListItem
                  key="subscriptionPlan"
                  disablePadding
                  dense={true}
                >
                  <ListItemButton
                    onClick={() => handleNavigation("managesubscription")}
                    sx={{ backgroundColor: "#ffffff" }}
                  >
                    <ListItemIcon>
                      <OFDIcon name="subscriptionplan" />
                    </ListItemIcon>
                    <ListItemText primary="Manage Subscription"></ListItemText>
                  </ListItemButton>
                </ListItem>

                {/* <Divider
                  variant="inset"
                  component="div"
                />

                <ListItem
                  key="thirtyday"
                  disablePadding
                  dense={true}
                >
                  <ListItemButton
                    onClick={() => handleNavigation("thirtyday")}
                    sx={{ backgroundColor: "#ffffff" }}
                  >
                    <ListItemIcon>
                      <OFDIcon name="email" />
                    </ListItemIcon>
                    <ListItemText primary="Thirty Day Subscription"></ListItemText>
                  </ListItemButton>
                </ListItem> */}
              </>
            ) : null}

            <Divider
              variant="inset"
              component="div"
            />

            <ListItem
              key="Logout"
              disablePadding
              dense={true}
            >
              <ListItemButton
                onClick={handleLogout}
                sx={{ backgroundColor: "#ffffff" }}
              >
                <ListItemIcon>
                  <OFDIcon name="logout" />
                </ListItemIcon>
                <ListItemText primary="Logout"></ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>

        {canAccessFamilyDashboard() && windowWidth > 900 ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <OFDButton
              label="switchToFamilyDashboard"
              variant="contained"
              onClick={() => setOpenPinDialog(true)}
            />
          </Box>
        ) : null}

        {showIDs ? (
          <Stack
            spacing={2}
            sx={{ padding: "1rem" }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{`memberId: ${member.id}`}</Typography>
              <OFDIcon
                name="copy"
                onClick={() => handleCopy(member.id)}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{`familyId: ${family.id}`}</Typography>
              <OFDIcon
                name="copy"
                onClick={() => handleCopy(family.id)}
              />
            </Box>
          </Stack>
        ) : null}
      </Drawer>

      <a
        ref={subscriptionLink}
        style={{ display: "none" }}
        href={process.env.REACT_APP_CUSTOMER_PORTAL}
        target="_blank"
        rel="noopener"
      >
        Manage Subscription
      </a>

      <Help
        open={openHelp}
        onClose={() => setOpenHelp(false)}
      />

      <SetupWizard
        open={openSetup}
        onClose={() => setOpenSetup(false)}
      />

      <UserPin
        open={openPinDialog}
        onClose={() => setOpenPinDialog(false)}
        onSuccess={handleSwitchToDashboard}
      />
    </>
  );
};

export default SystemMenu;
