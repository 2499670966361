import { useEffect } from "react";

import { Box, IconButton, Typography } from "@mui/material";

import usePage from "../../hooks/usePage";

import OFDHorizontalScroll from "../ui/OFDHorizontalScroll";
import OFDToolbarItem from "./OFDToolbarItem";

const OFDToolbar = ({ menuItems, sticky, onClick, containerSx, itemSx }) => {
  const { refresh } = usePage();

  useEffect(() => {}, [refresh]);

  return (
    <Box
      id="toolbar"
      sx={{
        backgroundColor: "#ccc",
        width: "100%",
        paddingLeft: ".5rem",
        paddingRight: ".5rem",
        position: sticky ? "sticky" : "static",
        top: 0,
        left: 0,
        zIndex: 5500,
        ...containerSx,
      }}
    >
      <OFDHorizontalScroll>
        <Box
          sx={{
            width: "95%",
            height: "65px",
            display: "flex",
            alignItems: "center",
          }}
          gap={2}
        >
          {menuItems?.map((menuItem) => (
            <OFDToolbarItem
              key={menuItem.name}
              {...menuItem}
              onClick={onClick}
              sx={{ ...itemSx }}
            />
          ))}
        </Box>
      </OFDHorizontalScroll>
    </Box>
  );
};

export default OFDToolbar;
