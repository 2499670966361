const fr_CA = {
  code: "fr-CA",
  language: {
    code: "fr",
    name: "French",
  },
  country: {
    code: "ca",
    name: "Canada",
  },
  currency: "cad",

  labels: {
    name: "Nom",
  },

  text: {},

  messages: {
    saved: "Saved",
  },

  lists: {},
};

export default fr_CA;
