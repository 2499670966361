import React from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const RecipeListItem = ({ recipe, onSelect, selected }) => {
  const theme = useTheme();

  const handleSelect = () => {
    onSelect(recipe);
  };

  return (
    <Box
      sx={{
        borderRadius: "10px",
        backgroundColor: selected ? theme.palette.primary.main : "#fff",
        color: selected ? "#fff" : "#000",
        minHeight: "50px",
        padding: ".5rem",
        display: "flex",
        justifyContent: "space-between",
      }}
      onClick={handleSelect}
    >
      <Typography variant="h6">{recipe.name}</Typography>
    </Box>
  );
};

export default RecipeListItem;
