import { useEffect, useState } from "react";
import { startOfToday, endOfToday, startOfDay, format } from "date-fns";

import useApi from "../../hooks/useApi";
import useData from "../../hooks/useData";
import usePage from "../../hooks/usePage";
import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";
import ChildReminder from "./ChildReminder";

const ChildReminders = () => {
  const { sort } = useData();
  const { getData } = useApi();
  const { refresh } = usePage();
  const { member, memberColor } = useMember();
  const { getText } = useLocalization();

  const [reminders, setReminders] = useState(undefined);

  useEffect(() => {
    getReminders();
  }, [refresh]);

  const getReminders = async () => {
    setReminders(undefined);

    const results = await getData({
      entityName: "Calendar",
      action: "get",
      filter: {
        memberId: member.id,
        calendarType: "reminder",
        sent: false,
        $or: [
          {
            startDate: {
              $gte: startOfToday(),
              $lte: endOfToday(),
            },
          },
          {
            startDate: {
              $lte: startOfToday(),
            },
            endDate: {
              $gte: endOfToday(),
            },
          },
        ],
      },
    });

    if (results.isSuccessful) {
      sortItems(results.data);
    } else {
      setReminders(null);
    }
  };

  const sortItems = (data) => {
    let sortedItems = [];

    for (const item of data) {
      if (item.memberId !== member.id) {
        continue;
      }

      let sort = item.startDate;
      if (item.allDay) {
        sort = startOfDay(item.startDate);
      }
      sortedItems.push({ ...item, sort });
    }

    sortedItems = sort(sortedItems, "sort", "asc");

    setReminders(sortedItems);
  };

  if (!reminders || reminders.length === 0) return null;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
      }}
      gap={1}
    >
      <Box
        sx={{
          display: "flex",
          ...memberColor(),
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          padding: "8px",
        }}
        gap={1}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <OFDIcon
            name="reminder"
            size="large"
            color="grey"
            shade={50}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6">{getText("dontForget")}</Typography>
          </Box>
        </Box>
      </Box>

      <Stack
        sx={{ padding: ".5rem" }}
        spacing={1}
      >
        {reminders.map((reminder) => (
          <ChildReminder
            key={reminder.id}
            reminder={reminder}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default ChildReminders;
