import { useEffect, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";
import useSecurity from "../../hooks/useSecurity";
import useDocument from "../../hooks/useDocument";

import DocumentDisplay from "../display/DocumentDisplay";
import DocumentViewer from "../display/DocumentViewer";
import OFDButton from "./OFDButton";

const OFDDisplayDocuments = ({
  id,
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
  source,
  onChange,
}) => {
  const { getLabel } = useLocalization();
  const { getColor } = useColor();
  const { userCanView, userCanUpdate } = useSecurity();
  const { documents, getBySource } = useDocument();

  const [viewDocument, setViewDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const contentStyle = {
    maxHeight: "200px",
    overflow: "auto",
  };

  useEffect(() => {
    getDocuments();
  }, [source, id]);

  const getDocuments = async () => {
    await getBySource({ ...source, fieldId: id });
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleViewDocument = (document) => {
    setSelectedDocument(document);
    setViewDocument(true);
  };

  const handleCloseDocument = () => {
    setSelectedDocument(null);
    setViewDocument(false);
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={displayStyle}>
        <Typography variant="overline">{getLabel(label)}</Typography>

        <Box sx={contentStyle}>
          <Stack spacing={1}>
            {Array.isArray(documents)
              ? documents?.map((document) => (
                  <DocumentDisplay
                    key={document.id}
                    document={document}
                    onClick={() => handleViewDocument(document)}
                    onChange={onChange}
                  />
                ))
              : null}

            {userCanUpdate(security, entryAddedBy) ? (
              <OFDButton
                label="upload"
                icon="upload"
                variant="outlined"
                onClick={handleClick}
              />
            ) : null}
          </Stack>
        </Box>
      </Box>

      <DocumentViewer
        open={viewDocument}
        onClose={handleCloseDocument}
        document={selectedDocument}
      />
    </>
  );
};

export default OFDDisplayDocuments;
