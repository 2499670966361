import { useEffect, useState } from "react";

import { Box, Stack } from "@mui/material";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useMember from "../../hooks/useMember";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import EntityEditor from "../editors/EntityEditor";
import Search from "../forms/Search";
import SearchCriteria from "../forms/SearchCriteria";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import useWindow from "../../hooks/useWindow";
import useData from "../../hooks/useData";

import Loading from "../layout/Loading";
import OFDListAndDetails from "../layout/OFDListAndDetails";
import EntityDisplay from "../display/EntityDisplay";
import OFDCollapsable from "../layout/OFDCollapsable";

const Notes = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const {
    entityList,
    getEntityList,
    getSearchFields,
    searchResults,
    searchCriteria,
    setSearchCriteria,
    menuClick,
    entityMessage,
    resetEntityMessage,
    setEntityList,
  } = useEntity("Note");
  const { isDesktop } = useWindow();
  const { memberColor } = useMember();
  const { sort } = useData();

  const [openForm, setOpenForm] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [groups, setGroups] = useState(undefined);
  const [notes, setNotes] = useState(undefined);

  useEffect(() => {
    setOpenForm(false);
    setEntityName("Note");
    getData();
  }, [refresh]);

  const getData = async () => {
    setEntityList(undefined);
    await getEntityList({ sortFields: { field: "group" } });
  };

  useEffect(() => {
    if (toolbarAction === "addNote") {
      setOpenForm(true);
    }
    if (toolbarAction === "search") {
      setOpenSearch(true);
    }
    resetToolbarAction();
  }, [toolbarAction]);

  useEffect(() => {
    if (!searchResults) {
      setGroups(null);
      setNotes(null);
      return;
    }

    let currentGroups = [];
    let currentNotes = [];

    for (const note of searchResults) {
      if (note.group) {
        if (currentGroups.findIndex((g) => g === note.group) === -1) {
          currentGroups.push(note.group);
        }
      }
      currentNotes.push({ ...note, group: note.group ? note.group : null });
    }

    currentGroups = sort(currentGroups);
    currentNotes = sort(currentNotes, "name");

    setGroups(currentGroups);
    setNotes(currentNotes);
  }, [searchResults]);

  const handleEditEntity = async () => {
    getData();
  };

  const handleFormClose = async () => {
    setOpenForm(false);
    resetToolbarAction();
    getData();
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleClearSearch = () => {
    setSearchCriteria(null);
    setOpenSearch(false);
  };

  const handleSearch = (newSearch) => {
    setSearchCriteria(newSearch);
    setOpenSearch(false);
  };

  const handleMenuClick = (action, entity) => {
    setSelectedEntity(entity);

    if (action === "delete") {
      setOpenDeleteConfirmation(true);
    }
  };

  const deleteConfirmation = async (response) => {
    if (response === "yes") {
      const results = await menuClick("delete", selectedEntity);

      if (results.isSuccessful) {
        await getData();
      }
    }
    setOpenDeleteConfirmation(false);
  };

  const handleOpenRight = (entityName, id, entity) => {
    setSelectedEntity(entity);
  };

  const getNotes = (group) => {
    if (!notes || notes.length === 0) {
      return [];
    }

    let returnNotes = notes.filter((note) => note.group === group);

    return returnNotes;
  };

  const handleEdit = () => {};

  const handleSelectedFolder = () => {};

  const handleOpenFolderEditor = () => {};

  return (
    <>
      <OFDTitle title="notes" />

      <SearchCriteria
        searchCriteria={searchCriteria}
        onClear={handleClearSearch}
      />

      {Array.isArray(notes) && Array.isArray(groups) ? (
        isDesktop ? (
          <OFDListAndDetails
            list={
              <OFDEntityList
                entityName="Note"
                entityList={searchResults}
                onEdit={handleEditEntity}
                options={{
                  showAvatar: true,
                }}
                onMenuItemClick={handleMenuClick}
                onOpenRight={handleOpenRight}
              />
            }
            details={
              selectedEntity ? (
                <EntityDisplay
                  entityName="Note"
                  id={selectedEntity.id}
                  entityData={selectedEntity}
                  onChange={handleEdit}
                  onFolderSelected={handleSelectedFolder}
                  onFolderEdit={handleOpenFolderEditor}
                />
              ) : null
            }
          />
        ) : (
          <Stack>
            <Stack>
              <OFDEntityList
                entityName="Note"
                entityList={getNotes(null)}
                onEdit={handleEditEntity}
                options={{
                  showAvatar: true,
                }}
                onMenuItemClick={handleMenuClick}
              />
            </Stack>
            <Stack spacing={1}>
              {groups.map((group) => (
                <OFDCollapsable
                  key={group}
                  id={group}
                  header={group}
                  headerStyle={{ ...memberColor() }}
                >
                  <OFDEntityList
                    key={`list_${group}`}
                    entityName="Note"
                    entityList={getNotes(group)}
                    onEdit={handleEditEntity}
                    options={{
                      showAvatar: true,
                    }}
                    onMenuItemClick={handleMenuClick}
                  />
                </OFDCollapsable>
              ))}
            </Stack>
          </Stack>
        )
      ) : (
        <Loading />
      )}

      {openForm ? (
        <EntityEditor
          entityName="Note"
          open={openForm}
          onClose={handleFormClose}
        />
      ) : null}

      {openSearch ? (
        <Search
          open={openSearch}
          searchCriteria={searchCriteria}
          onClose={handleSearchClose}
          searchFields={getSearchFields()}
          onSearch={handleSearch}
        />
      ) : null}

      <OFDMessage
        message={entityMessage}
        onClose={resetEntityMessage}
      />

      <OFDDialog
        open={openDeleteConfirmation}
        title="confirmDelete"
        description="confirmDelete_note"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={deleteConfirmation}
      />
    </>
  );
};

export default Notes;
