import { useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useColor from "../../hooks/useColor";

import {
  Box,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Chip,
} from "@mui/material";

const OFDMultiSelect = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  fullWidth,
  listName,
  options,
  variant = "standard",
  color,
}) => {
  const { getLabel, lists, getListItemLabel, getMessage } = useLocalization();
  const { memberColor } = useMember();
  const { getColor } = useColor();

  const getSelectedLabel = (value) => {
    if (listName) return getListItemLabel(listName, value);
    if (options) {
      for (const option of options) {
        if (option.value === value) {
          return options.label;
        }
      }
    }
  };

  return (
    <>
      <FormControl
        id={id}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled}
        required={required}
        error={error}
        sx={{
          "& label.Mui-focused": {
            color: color
              ? getColor({ color }).backgroundColor
              : memberColor().backgroundColor,
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: color
                ? getColor({ color }).backgroundColor
                : memberColor().backgroundColor,
            },
          },
        }}
      >
        <InputLabel
          sx={{ marginTop: "-8px", marginLeft: "12px" }}
          htmlFor={id}
        >
          {getLabel(label)}
        </InputLabel>

        <Select
          size="small"
          label={getLabel(label)}
          value={value !== null ? value : ""}
          onChange={(e) => onChange(e.target.value)}
          multiple
          input={
            <OutlinedInput
              size="small"
              label={getLabel(label)}
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={getSelectedLabel(value)}
                />
              ))}
            </Box>
          )}
        >
          {lists[listName]?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
          {options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText error={error}>{getMessage(message)}</FormHelperText>
      </FormControl>
    </>
  );
};

export default OFDMultiSelect;
