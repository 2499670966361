import { useEffect, useState } from "react";

import usePage from "../../hooks/usePage";
import useFamily from "../../hooks/useFamily";
import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";
import useColor from "../../hooks/useColor";

import { Box, Typography, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import OFDTitle from "../layout/OFDTitle";
import OFDContainer from "../ui/OFDContainer";
import OFDIcon from "../ui/OFDIcon";
import OFDDisplayMembers from "../ui/OFDDisplayMembers";

import OFDMessage from "../ui/OFDMessage";

import ModulePreferences from "../editors/ModulePreferences";
import ChorePreferences from "../editors/ChorePreferences";

const FamilyPreferences = () => {
  const { memberColor } = useMember();
  const { getLabel } = useLocalization();
  const { getColor } = useColor();
  const {
    familyMembers,
    familyPreferences,
    familyMessages,
    setFamilyMessages,
    updateFamilyPreferences,
    refreshFamilyPreferences,
  } = useFamily();
  const { getMainPages, defaultPageSecurity, refresh } = usePage();

  const [openModulePreferences, setOpenModulePreferences] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  const [openChorePreferences, setOpenChorePreferences] = useState(false);

  useEffect(() => {
    refreshFamilyPreferences();
  }, [refresh]);

  const isEnabled = (name) => {
    if (!familyPreferences) return false;

    const page = familyPreferences?.pagePreferences.find(
      (page) => page.name === name
    );

    if (!page) return false;

    return page.enabled;
  };

  const selectModule = (name) => {
    let page = familyPreferences.pagePreferences.find(
      (page) => page.name === name
    );

    if (!page) {
      page = {
        name,
        enabled: false,
        security: defaultPageSecurity(),
      };
    }

    setSelectedModule(page);
    setOpenModulePreferences(true);
  };

  const handleCloseModule = () => {
    setOpenModulePreferences(false);
    setSelectedModule(null);
  };

  const handleChangeModule = async (updatedModule) => {
    let pages = [];
    let pageFound = false;

    for (const page of familyPreferences.pagePreferences) {
      if (page.name !== updatedModule.name) {
        pages.push({ ...page });
        continue;
      }

      pages.push({ ...updatedModule });
      pageFound = true;
    }

    if (!pageFound) {
      pages.push({ ...updatedModule });
    }

    let newPreferences = {
      pagePreferences: [...pages],
      chores: familyPreferences.chores,
    };

    await updateFamilyPreferences(newPreferences);

    handleCloseModule();
  };

  const handleOpenChorePreferences = () => {
    setOpenChorePreferences(true);
  };

  const handleChangeChores = async (newChores) => {
    let newPreferences = {
      ...familyPreferences,
      chores: { ...newChores },
    };

    await updateFamilyPreferences(newPreferences);

    handleCloseChores();
  };

  const handleCloseChores = () => {
    setOpenChorePreferences(false);
  };

  const getChorePreferences = () => {
    if (familyPreferences?.hasOwnProperty("chores")) {
      return familyPreferences.chores;
    }

    let approvers = [];
    const admins = familyMembers?.filter(
      (member) =>
        member.securityLevel === "admin" ||
        member.securityLevel === "accountOwner"
    );

    if (Array.isArray(admins)) {
      for (const admin of admins) {
        approvers.push(admin.id);
      }
    }

    return {
      approvers,
    };
  };

  return (
    <>
      <Box>
        <OFDTitle title="familyPreferences" />

        <Stack spacing={2}>
          <Grid
            container
            spacing={2}
          >
            {getMainPages()?.map((page) => (
              <Grid
                key={page.name}
                mobile={3}
              >
                <Box
                  sx={{
                    backgroundColor: getColor({
                      color: "grey",
                      shade: 300,
                    }).backgroundColor,
                    borderRadius: "8px",
                    width: "100%",
                    aspectRatio: 1,
                    padding: "3px",
                  }}
                  onClick={() => selectModule(page.name)}
                >
                  <OFDIcon
                    name={page.icon}
                    shade={isEnabled(page.name) ? 900 : 500}
                    size="large"
                    color="grey"
                  />
                  <Typography
                    sx={{
                      fontSize: "10px",
                      color: getColor({
                        color: "grey",
                        shade: isEnabled(page.name) ? 900 : 500,
                      }).backgroundColor,
                    }}
                    align="center"
                    noWrap
                  >
                    {getLabel(page.name)}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <OFDContainer label="chores">
            <Box onClick={handleOpenChorePreferences}>
              <OFDDisplayMembers
                id="approvers"
                label="approvers"
                value={getChorePreferences().approvers}
              />
            </Box>
          </OFDContainer>
        </Stack>
      </Box>

      <ModulePreferences
        open={openModulePreferences}
        onClose={handleCloseModule}
        module={selectedModule}
        onChange={handleChangeModule}
      />

      <ChorePreferences
        open={openChorePreferences}
        onClose={handleCloseChores}
        preferences={getChorePreferences()}
        onChange={handleChangeChores}
      />

      <OFDMessage
        message={familyMessages}
        onClose={() => setFamilyMessages(null)}
      />
    </>
  );
};

export default FamilyPreferences;
