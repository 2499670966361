import React from "react";

import { Box } from "@mui/material";

import OFDSelect from "./OFDSelect";

const OFDDailyOptions = ({ interval, onChange, disabled, error, message }) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center" }}
      gap={1}
    >
      <OFDSelect
        id="interval"
        label="every"
        value={interval}
        onChange={onChange}
        listName="dailyIntervals"
        fullWidth
        disabled={disabled}
        error={error}
        message={message}
      />
    </Box>
  );
};

export default OFDDailyOptions;
