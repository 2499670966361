import { useEffect, useState } from "react";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  format,
  getDay,
  getMonth,
  isAfter,
  isDate,
  isEqual,
  parse,
  setDate,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subWeeks,
  parseISO,
  startOfToday,
  endOfToday,
  getYear,
  startOfYear,
  endOfYear,
  subMonths,
} from "date-fns";

import useFamily from "./useFamily";
import useData from "./useData";
import useApi from "./useApi";

const useExpense = () => {
  const { family } = useFamily();
  const { getData } = useApi();
  const { sort } = useData();

  const [expenses, setExpenses] = useState(undefined);
  const [recurring, setRecurring] = useState(undefined);
  const [expenseCalendar, setExpenseCalendar] = useState(undefined);
  const [currentYear, setCurrentYear] = useState(null);

  const defaultMonthlyTotals = [
    {
      month: 0,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 1,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 2,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 3,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 4,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 5,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 6,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 7,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 8,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 9,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 10,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
    {
      month: 11,
      expenseBudget: 0,
      incomeBudget: 0,
      incomeAmount: 0,
      income: [],
      expenseAmount: 0,
      expenses: [],
    },
  ];

  const defaultMonthyBudgets = [
    { month: 0, incomeBudget: 0, expenseBudget: 0 },
    { month: 1, incomeBudget: 0, expenseBudget: 0 },
    { month: 2, incomeBudget: 0, expenseBudget: 0 },
    { month: 3, incomeBudget: 0, expenseBudget: 0 },
    { month: 4, incomeBudget: 0, expenseBudget: 0 },
    { month: 5, incomeBudget: 0, expenseBudget: 0 },
    { month: 6, incomeBudget: 0, expenseBudget: 0 },
    { month: 7, incomeBudget: 0, expenseBudget: 0 },
    { month: 8, incomeBudget: 0, expenseBudget: 0 },
    { month: 9, incomeBudget: 0, expenseBudget: 0 },
    { month: 10, incomeBudget: 0, expenseBudget: 0 },
    { month: 11, incomeBudget: 0, expenseBudget: 0 },
  ];

  const budgetTemplate = {
    budgetId: null,
    budgetName: null,
    budgetTotal: 0,
    expenseTotal: 0,
    budgetItems: [],
  };

  const budgetItemTemplate = {
    budgetItemId: null,
    budgetItemName: null,
    budgetTotal: 0,
    expenseTotal: 0,
  };

  const getExpenses = async (year) => {
    setExpenses(undefined);
    setCurrentYear(year);

    const results = await getData({
      entityName: "Expense",
      action: "getExpenses",
      filter: {
        family: family.id,
        year,
      },
    });

    if (results.isSuccessful) {
      setExpenses(results.data);
    } else {
      setExpenses(null);
    }
  };

  const getExpenseCalendar = async (year, ytd, expenseId) => {
    setExpenseCalendar(undefined);
    setCurrentYear(year);

    let filter = null;

    if (expenseId) {
      filter = {
        expenseId,
      };
    } else {
      filter = {
        family: family.id,
        year,
      };

      if (ytd) {
        filter.expenseDate = { $lte: endOfMonth(subMonths(new Date(), 1)) };
      }
    }

    const results = await getData({
      entityName: "ExpenseCalendar",
      action: "get",
      filter: filter,
    });

    if (results.isSuccessful) {
      setExpenseCalendar(results.data);
    } else {
      setExpenseCalendar(null);
    }

    if (expenseId) {
      return results.data;
    }
  };

  const getSourceExpenses = async (source) => {
    const results = await getData({
      entityName: "ExpenseCalendar",
      action: "get",
      filter: {
        familyId: family.id,
        source,
      },
    });

    if (results.isSuccessful) {
      setExpenses(results.data);
    } else {
      setExpenses(null);
    }
  };

  const getRecurring = async (year) => {
    setRecurring(undefined);

    const results = await getData({
      entityName: "Expense",
      action: "getRecurring",
      filter: {
        family: family.id,
        year,
      },
    });

    if (results.isSuccessful) {
      setRecurring(sort(results.data, "startDate"));
    } else {
      setRecurring(null);
    }
  };

  const calculateMonthlyTotals = async (data, ytd) => {
    let totals = [...defaultMonthlyTotals];

    const dayEnd = endOfDay(startOfToday());

    if (Array.isArray(data)) {
      for (const expense of data) {
        if (ytd && isAfter(expense.expenseDate, dayEnd)) {
          continue;
        }
        if (!expense.expenseAmount) continue;

        if (
          expense.budgetItem &&
          expense.budgetItem.budgetType &&
          expense.budgetItem.budgetType === "income"
        ) {
          totals[expense.month].incomeAmount += expense.expenseAmount;
          totals[expense.month].income.push({ ...expense });
        } else {
          totals[expense.month].expenseAmount += expense.expenseAmount;
          totals[expense.month].expenses.push({ ...expense });
        }
      }
    }

    return totals;
  };

  const calculateYearlyTotal = (data, ytd, type) => {
    let total = 0;

    const currentMonth = getMonth(startOfToday());

    for (let month = 0; month < data.length; month++) {
      if (ytd && month > currentMonth) break;

      total += data[month][type];
    }

    // if (Array.isArray(data)) {
    //   for (const expense of data) {
    //     if (ytd && isAfter(expense.expenseDate, endOfDay(startOfToday()))) {
    //       continue;
    //     }

    //     if (
    //       expense.budgetItem &&
    //       expense.budgetItem.budgetType &&
    //       expense.budgetItem.budgetType === "income"
    //     ) {
    //       continue;
    //     }

    //     total += expense.expenseAmount;
    //   }
    // }

    return total;
  };

  const calculateBudgetTotals = async (data, ytd) => {
    const results = await getData({
      entityName: "Budget",
      action: "getBudgetTotals",
      filter: {
        family: family.id,
        year: currentYear,
        ytd,
      },
    });

    if (
      !results.isSuccessful ||
      results.data.length === 0 ||
      results.data === null
    ) {
      return null;
    }

    let budgetTotals = [...results.data];

    let unknownBudgets = [];

    if (Array.isArray(data)) {
      for (const expense of data) {
        if (
          ytd &&
          isAfter(expense.expenseDate, endOfMonth(subMonths(new Date(), 1)))
        )
          continue;

        if (!expense.budgetItem) continue;

        let budgetIndex = budgetTotals.findIndex(
          (item) => item.budgetId === expense.budgetItem.budgetId
        );

        if (budgetIndex === -1) {
          unknownBudgets.push({ ...expense });
          continue;
        }

        let budget = budgetTotals[budgetIndex];

        let budgetItemIndex = budget.budgetItems.findIndex(
          (item) => item.budgetItemId === expense.budgetItem.budgetItemId
        );

        if (budgetItemIndex === -1) {
          continue;
        }

        if (
          !expense.budgetItem.budgetType ||
          expense.budgetItem.budgetType === "expense"
        ) {
          budget.expenseTotal += expense.expenseAmount;
        }

        let budgetItem = budget.budgetItems[budgetItemIndex];
        budgetItem.expenseTotal += expense.expenseAmount;

        budget.budgetItems[budgetItem] = { ...budgetItem };

        budgetTotals[budgetIndex] = { ...budget };
      }
    }

    return budgetTotals;
  };

  const calculateCompanyTotals = (data, ytd) => {
    let companyTotals = undefined;

    if (Array.isArray(data)) {
      companyTotals = [];

      for (const expense of data) {
        if (!expense.company) continue;
        if (ytd && isAfter(expense.expenseDate, endOfToday())) continue;

        const companyKey = calcCompanyKey(expense.company);

        let companyIndex = companyTotals.findIndex(
          (company) => company.key === companyKey
        );

        let companyTotal = null;
        if (companyIndex === -1) {
          companyTotal = {
            key: companyKey,
            name: expense.company,
            expenseAmount: expense.expenseAmount,
            expenses: [{ ...expense }],
          };
          companyTotals.push({ ...companyTotal });
        } else {
          companyTotal = { ...companyTotals[companyIndex] };
          companyTotal.expenseAmount += expense.expenseAmount;
          companyTotal.expenses.push({ ...expense });

          companyTotals[companyIndex] = { ...companyTotal };
        }
      }
    }

    return companyTotals;
  };

  const calcCompanyKey = (company) => {
    let key = company.replace(/\s+/g, "");
    key = key.toLowerCase();
    return key;
  };

  const transformExpense = (expense) => {
    console.log({ expense });
    let newExpense = { ...expense };

    if (expense.frequency === "schedule") {
      let startDate = null;
      let endDate = null;

      if (Array.isArray(newExpense.schedule)) {
        for (const date of newExpense.schedule) {
          let start = typeof date === "string" ? parseISO(date) : date;

          if (startDate === null || start < startDate) {
            startDate = start;
          }
          if (endDate === null || start > endDate) {
            endDate = start;
          }
        }
      }

      newExpense.startDate = startDate;
      newExpense.endDate = endDate;
    } else if (expense.frequency === "onetime") {
      newExpense.startDate = newExpense.expenseDate;
      newExpense.endDate = newExpense.expenseDate;
    }

    newExpense.year = getYear(newExpense.startDate);
    newExpense.month = getMonth(newExpense.startDate);

    console.log({ newExpense });

    return newExpense;
  };

  const validateExpense = (expense) => {
    let validationResults = {
      isSuccessful: true,
      message: "ok",
      displayToUser: false,
      fieldErrors: [],
      severity: "info",
    };

    if (expense.expenseId) {
      if (!expense.expenseDate) {
        validationResults.isSuccessful = false;
        validationResults.message = "fieldErrors";
        validationResults.severity = "error";
        validationResults.displayToUser = true;

        validationResults.fieldErrors.push({
          name: "expenseDate",
          message: "isEmpty",
          severity: "error",
          error: true,
        });
      }
    }

    if (!expense.name || expense.name.trim().length === 0) {
      validationResults.isSuccessful = false;
      validationResults.message = "fieldErrors";
      validationResults.severity = "error";
      validationResults.displayToUser = true;

      validationResults.fieldErrors.push({
        name: "name",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    if (!expense.expenseAmount) {
      validationResults.isSuccessful = false;
      validationResults.message = "fieldErrors";
      validationResults.severity = "error";
      validationResults.displayToUser = true;

      validationResults.fieldErrors.push({
        name: "expenseAmount",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    if (!expense.budgetItem || !expense.budgetItem.budgetItemId) {
      validationResults.isSuccessful = false;
      validationResults.message = "fieldErrors";
      validationResults.severity = "error";
      validationResults.displayToUser = true;

      validationResults.fieldErrors.push({
        name: "budgetItem",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    return validationResults;
  };

  const getEntityDates = (entityData) => {
    if (entityData.frequency === "onetime") {
      return oneTimeDates(entityData);
    } else if (entityData.frequency === "daily") {
      return dailyDates(entityData);
    } else if (entityData.frequency === "weekly") {
      return weeklyDates(entityData);
    } else if (entityData.frequency === "biweekly") {
      return biweeklyDates(entityData);
    } else if (entityData.frequency === "monthly") {
      return monthlyDates(entityData);
    } else if (entityData.frequency === "yearly") {
      return yearlyDates(entityData);
    } else if (entityData.frequency === "schedule") {
      return scheduleDates(entityData);
    }
  };

  const oneTimeDates = (entityData) => {
    let entityDates = [];

    let entityDate;
    entityDate = {
      startDate: entityData.startDate,
      endDate: entityData.endDate,
      year: getYear(entityData.startDate),
      month: getMonth(entityData.startDate),
    };

    if (entityDate) {
      entityDates.push({ ...entityDate });
    }

    return entityDates;
  };

  const dailyDates = (entityData) => {
    let entityDates = [];

    for (
      let date = startOfDay(entityData.startDate);
      date <= startOfDay(entityData.endDate);
      date = addDays(date, 1)
    ) {
      let entityDate = getEntityDate(entityData, date);

      if (entityDate) {
        entityDates.push(entityDate);
      }
    }

    return entityDates;
  };

  const weeklyDates = (entityData) => {
    let entityDates = [];

    for (
      let date = startOfWeek(entityData.startDate);
      date <= startOfWeek(entityData.endDate);
      date = addWeeks(date, 1)
    ) {
      for (let dow = 0; dow < entityData.dow.length; dow++) {
        if (entityData.dow[dow] === false) continue;

        let day = addDays(date, dow);
        let entityDate = getEntityDate(entityData, day);

        if (entityDate) {
          entityDates.push(entityDate);
        }
      }
    }

    return entityDates;
  };

  const biweeklyDates = (entityData) => {
    let entityDates = [];
    let endDate = entityData.endDate
      ? entityData.endDate
      : endOfYear(entityData.startDate);

    for (
      let date = entityData.startDate;
      date <= endDate;
      date = addWeeks(date, 2)
    ) {
      let entityDate = getEntityDate(entityData, date);
      entityDates.push(entityDate);
    }

    return entityDates;
  };

  const monthlyDates = (entityData) => {
    let entityDates = [];

    for (
      let date = startOfMonth(entityData.startDate);
      date <= endOfMonth(entityData.endDate);
      date = addMonths(date, 1)
    ) {
      const daysOfMonth = getDaysOfMonth(date, entityData);
      for (const day of daysOfMonth) {
        let entityDate = getEntityDate(entityData, day);

        if (entityDate) {
          entityDates.push(entityDate);
        }
      }
    }

    return entityDates;
  };

  const yearlyDates = (entityData) => {
    let entityDates = [];
    entityDates = getEntityDate(startOfYear(entityData.startDate));
    return entityDates;
  };

  const scheduleDates = (entityData) => {
    let entityDates = [];

    for (const date of entityData.schedule) {
      let startDate = typeof date === "string" ? parseISO(date) : date;
      let endDate = startDate;

      entityDates.push({
        startDate,
        endDate,
        year: getYear(startDate),
        month: getMonth(startDate),
      });
    }

    return entityDates;
  };

  const getEntityDate = (entityData, date) => {
    let startDate = date;
    let endDate = date;

    if (
      startDate < startOfDay(entityData.startDate) ||
      endDate > endOfDay(entityData.endDate)
    ) {
      return null;
    }

    return {
      startDate,
      endDate,
      year: getYear(startDate),
      month: getMonth(startDate),
    };
  };

  const getDaysOfMonth = (monthStart, entityData) => {
    let days = [];

    if (entityData.monthlyType === "specific") {
      for (const dom of entityData.dates) {
        if (dom === "last") {
          days.push(endOfMonth(monthStart));
        } else {
          days.push(setDate(monthStart, parseInt(dom)));
        }
      }
    } else if (entityData.monthlyType === "relative") {
      for (const relativeDays of entityData.days) {
        let week = startOfWeek(monthStart);

        let monthStartsDOW = getDay(monthStart);

        for (let dow = 0; dow < relativeDays.dow.length; dow++) {
          if (relativeDays.dow[dow] === false) continue;

          if (monthStartsDOW > dow) {
            week = addWeeks(week, 1);
          }

          switch (relativeDays.week) {
            case "second":
              week = addWeeks(week, 1);
              break;

            case "third":
              week = addWeeks(week, 2);
              break;

            case "fourth":
              week = addWeeks(week, 3);
              break;

            case "last":
              week = startOfWeek(endOfMonth(monthStart));
              break;
          }

          let date = addDays(week, dow);
          let yyyymm_date = format(date, "yyyyMM");
          let yyyymm_week = format(week, "yyyyMM");

          if (relativeDays.week === "last" && yyyymm_date > yyyymm_week) {
            date = subWeeks(date, 1);
          }
          if (
            relativeDays.week === "first" &&
            getMonth(date) < getMonth(addDays(week, 7))
          ) {
            date = addWeeks(date, 1);
          }

          if (date > entityData.endDate || date < entityData.startDate)
            continue;

          let exists = false;
          for (const existingDate of days) {
            if (isEqual(existingDate, date)) {
              exists = true;
            }
          }
          if (!exists) {
            days.push(date);
          }
        }
      }
    }

    return days;
  };

  return {
    expenses,
    getExpenses,
    getExpenseCalendar,
    expenseCalendar,
    calculateMonthlyTotals,
    calculateBudgetTotals,
    calculateCompanyTotals,
    calculateYearlyTotal,
    setExpenses,
    validateExpense,
    transformExpense,
    getEntityDates,
    getRecurring,
    recurring,
    getSourceExpenses,
    setCurrentYear,
  };
};

export default useExpense;
