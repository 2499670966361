import { useState } from "react";
import { useSelector } from "react-redux";

import useApi from "./useApi";

const useChore = () => {
  const { getData, postData } = useApi();

  const family = useSelector((state) => state.family);

  const [familyChores, setFamilyChores] = useState(undefined);
  const [numberOfChores, setNumberOfChores] = useState(0);

  const getChoreApprovers = () => {
    if (
      family.preferences.hasOwnProperty("chores") &&
      family.preferences.chores.hasOwnProperty("approvers")
    ) {
      return family.preferences.chores.approvers;
    }

    let approvers = [];
    const admins = family.members.filter(
      (member) =>
        member.securityLevel === "admin" ||
        member.securityLevel === "accountOwner"
    );

    for (const admin of admins) {
      approvers.push(admin.id);
    }

    return approvers;
  };

  const getFamilyChores = async () => {
    setFamilyChores(undefined);
    const results = await getData({
      entityName: "Chore",
      action: "familyChores",
    });

    if (results.isSuccessful) {
      setFamilyChores(results.data);

      setNumberOfChores(() => {
        let choreCount = 0;

        for (const member of results.data.activity) {
          choreCount += Object.keys(member.chores).length;
        }

        return choreCount;
      });
    } else {
      setFamilyChores(null);
    }
  };

  const updateChoreActivity = async (activity) => {
    // memberId, choreId, activityId, tokensGiven, status

    const results = await postData({
      entityName: "Member",
      action: "updateChoreActivity",
      data: { ...activity },
    });

    if (results.isSuccessful) {
      if (familyChores) {
        await getFamilyChores();
      } else {
        return results;
      }
    }
  };

  const getChore = (choreId) => {
    if (!familyChores || !Array.isArray(familyChores.choreList)) return null;

    const chore = familyChores.choreList.find((c) => c.id === choreId);

    return chore;
  };

  const clearChores = () => {
    setFamilyChores(null);
  };

  return {
    getChoreApprovers,
    familyChores,
    getFamilyChores,
    numberOfChores,
    updateChoreActivity,
    getChore,
    clearChores,
  };
};

export default useChore;
