import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

import { Switch, FormControl, FormControlLabel } from "@mui/material";

const OFDSwitch = ({
  id,
  label,
  value,
  onChange,
  disabled,
  checkedColor,
  uncheckedColor,
  fontColor,
}) => {
  const { memberColor } = useMember();
  const { getLabel } = useLocalization();

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Switch
            id={id}
            checked={value || false}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: checkedColor
                  ? checkedColor
                  : memberColor().backgroundColor,
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: memberColor({ shade: 200 }).backgroundColor,
              },
            }}
            disabled={disabled}
          />
        }
        sx={{ color: fontColor ? fontColor : "#000000" }}
        label={getLabel(label)}
      />
    </FormControl>
  );
};

export default OFDSwitch;
