import React from "react";

import { Box, Typography } from "@mui/material";

const GroceryItem = ({ groceryItem }) => {
  return (
    <Box
      sx={{
        paddingLeft: ".5rem",
        paddingRight: ".5rem",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{groceryItem.item}</Typography>
        <Typography>{groceryItem.quantity}</Typography>
      </Box>

      {groceryItem.note ? (
        <Box>
          <Typography variant="caption">{groceryItem.note}</Typography>
        </Box>
      ) : null}

      {groceryItem.image ? (
        <Box>
          <img
            src={groceryItem.image}
            style={{ height: "50px", width: "auto" }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default GroceryItem;
