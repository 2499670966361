const StyleConstants = {
  pageHeader: {
    height: {
      mobile: "145px",
      tablet: "145px",
      laptop: "100px",
      dektop: "100px",
    },
  },
  pageFooter: {
    height: {
      mobile: "80px",
      tablet: "80px",
      laptop: "100px",
      dektop: "100px",
    },
  },
};

export default StyleConstants;
