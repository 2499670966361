import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/index";

import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "./styles/lightTheme";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";

import * as Ably from "ably";
import { AblyProvider } from "ably/react";
import Utils from "./store/Utils";

import "./index.css";
import App from "./App";

LicenseInfo.setLicenseKey(
  "bb08819b478924979fd99599566455d3Tz04NzA2MCxFPTE3NDI4MzU4MjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const { getMemberId } = Utils();
const memberId = await getMemberId();

const requestUrl = `${process.env.REACT_APP_API_URL}/tokenRequest?id=${memberId}`;

const client = new Ably.Realtime.Promise({
  authUrl: requestUrl,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AblyProvider client={client}>
          <App />
        </AblyProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </Provider>
);
