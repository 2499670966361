import { useState } from "react";

import usePage from "../../../../hooks/usePage";
import useLocalization from "../../../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDLogo from "../../../../assets/images/OFD_hrzntl_logo_All_White.png";

import OFDClock from "../../../ui/OFDClock";
import OFDIcon from "../../../ui/OFDIcon";
import OFDSwitch from "../../../ui/OFDSwitch";
import UserPin from "../../UserPin";

const DashboardHeader = () => {
  const { setDashboardMode, dashboardMode, refreshPage } = usePage();
  const { getLabel } = useLocalization();

  const theme = useTheme();

  const [openUserPin, setOpenUserPin] = useState(false);

  const switchDashboardMode = () => {
    if (dashboardMode === "display") {
      setOpenUserPin(true);
    } else {
      setDashboardMode("display");
    }
  };

  const setToEditMode = () => {
    setOpenUserPin(false);
    setDashboardMode("edit");
  };

  return (
    <>
      <Box
        sx={{
          height: "5rem",
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }}
      >
        <Box>
          <img
            src={OFDLogo}
            style={{
              height: "4rem",
              width: "auto",
            }}
          />
        </Box>

        <OFDClock variant="#fff" />

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "175px" }}
        >
          <OFDIcon
            name="refresh"
            color="grey"
            shade={50}
            size="xlarge"
            onClick={() => refreshPage()}
          />
        </Box>
      </Box>

      <UserPin
        open={openUserPin}
        onClose={() => setOpenUserPin(false)}
        onSuccess={setToEditMode}
      />
    </>
  );
};

export default DashboardHeader;
