import { useEffect, useState, useRef } from "react";
import { startOfToday, endOfToday } from "date-fns";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useForm from "../../hooks/useForm";
import useWindow from "../../hooks/useWindow";

import OFDTitle from "../layout/OFDTitle";
import OFDEntityList from "../layout/OFDEntityList";
import RecipeWizard from "../wizards/recipe/RecipeWizard";
import OFDMessage from "../ui/OFDMessage";
import OFDDialog from "../layout/OFDDialog";
import SecurityEditor from "../editors/SecurityEditor";
import RecipeDisplayModal from "../display/RecipeDisplayModal";
import OFDListAndDetails from "../layout/OFDListAndDetails";
import EntityDisplay from "../display/EntityDisplay";
import Search from "../forms/Search";
import SearchCriteria from "../forms/SearchCriteria";
import Loading from "../layout/Loading";

const Recipes = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const {
    entityList,
    getEntityList,
    setEntityList,
    getSearchFields,
    searchResults,
    searchCriteria,
    setSearchCriteria,
  } = useEntity("Recipe");
  const {
    data,
    setData,
    saveData,
    setFieldValue,
    getFieldValue,
    formMessage,
    formStatus,
    resetFormMessage,
    deleteDocument,
  } = useForm("Recipe");
  const { isDesktop } = useWindow();

  const [openWizard, setOpenWizard] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [openRecipe, setOpenRecipe] = useState(false);
  const [openSecurityEditor, setOpenSecurityEditor] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const urlRef = useRef();

  useEffect(() => {
    setOpenWizard(false);
    setEntityName("Recipe");
    getRecipes();
  }, [refresh]);

  useEffect(() => {
    if (toolbarAction === "addRecipe") {
      setData(null);
      setOpenWizard(true);
      setSelectedRecipe(null);
    }
    if (toolbarAction === "search") {
      setOpenSearch(true);
    }

    resetToolbarAction();
  }, [toolbarAction]);

  const getRecipes = async () => {
    setEntityList(undefined);

    await getEntityList({
      sortFields: { field: "name" },
    });
  };

  const handleWizardClose = async () => {
    setOpenWizard(false);
    resetToolbarAction();
    await getEntityList();
    setData(null);
  };

  const handleCreateRecipe = async (newRecipe) => {
    await saveRecipe(newRecipe);
  };

  const handleUpdateRecipe = async (updatedRecipe) => {
    await saveRecipe(updatedRecipe);
  };

  const saveRecipe = async (groceryData) => {
    const results = await saveData({ data: groceryData });

    if (results.isSuccessful) {
      await getRecipes();
      setOpenWizard(false);
    }
  };

  const handleDelete = async (item) => {
    setSelectedRecipe(item);
    setOpenDelete(true);
  };

  const handleConfirmDelete = async (response) => {
    if (response === "yes") {
      await deleteDocument({ data: selectedRecipe });
      await getRecipes();
    }
    setSelectedRecipe(null);
    setOpenDelete(false);
  };

  const handleMenuItemClick = (name, entity) => {
    setSelectedRecipe(entity);

    switch (name) {
      case "delete":
        handleDelete(entity);
        break;

      case "security":
        handleSecutity(entity);
        break;

      case "open":
        handleShowRecipe(entity);
        break;
    }
  };

  const handleEditEntity = async (entity) => {
    await getEntityList();
  };

  const handleSecutity = (item) => {
    setSelectedRecipe(item);
    setOpenSecurityEditor(true);
  };

  const handleCloseSecurity = () => {
    setOpenSecurityEditor(false);
  };

  const handleChangeSecurity = async (newSecurity) => {
    handleCloseSecurity();

    let newRecipe = { ...selectedRecipe, security: { ...newSecurity } };

    await saveRecipe(newRecipe);
  };

  const handleShowRecipe = (entity) => {
    setSelectedRecipe(entity);

    setOpenRecipe(true);

    // if (entity.externalUrl && !entity.ingredients && !entity.instructions) {
    //   urlRef.current.click();
    // } else {
    //   setOpenRecipe(true);
    // }
  };

  const handleOpenRight = (entityName, id, entity) => {
    setSelectedRecipe(entity);
  };

  const handleSearch = (newSearch) => {
    setSearchCriteria(newSearch);
    setOpenSearch(false);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleClearSearch = () => {
    setSearchCriteria(null);
    setOpenSearch(false);
  };

  const handleEdit = () => {};

  const handleSelectedFolder = () => {};

  const handleOpenFolderEditor = () => {};

  return (
    <>
      <OFDTitle title="recipes" />

      <SearchCriteria
        searchCriteria={searchCriteria}
        onClear={handleClearSearch}
      />

      {entityList !== undefined ? (
        isDesktop ? (
          <OFDListAndDetails
            list={
              <OFDEntityList
                entityName="Recipe"
                entityList={searchResults}
                onEdit={handleEditEntity}
                options={{
                  showAvatar: true,
                }}
                onMenuItemClick={handleMenuItemClick}
                onOpenRight={handleOpenRight}
              />
            }
            details={
              selectedRecipe ? (
                <EntityDisplay
                  entityName="Recipe"
                  id={selectedRecipe.id}
                  entityData={selectedRecipe}
                  onChange={handleEdit}
                  onFolderSelected={handleSelectedFolder}
                  onFolderEdit={handleOpenFolderEditor}
                />
              ) : null
            }
          />
        ) : (
          <OFDEntityList
            entityName="Recipe"
            entityList={searchResults}
            onMenuItemClick={handleMenuItemClick}
            headerFields={[{ name: "name", type: "string" }]}
            options={{
              showAvatar: false,
            }}
          />
        )
      ) : (
        <Loading />
      )}

      <a
        ref={urlRef}
        style={{ display: "none" }}
        href={selectedRecipe?.externalUrl}
        target="_blank"
        rel="noopener"
      />

      {openWizard ? (
        <RecipeWizard
          grocery={data}
          open={openWizard}
          onClose={handleWizardClose}
          onCreateRecipe={handleCreateRecipe}
          onUpdateRecipe={handleUpdateRecipe}
        />
      ) : null}

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <OFDDialog
        open={openDelete}
        title="confirmDelete"
        description={`confirmDelete_recipe`}
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      {openSecurityEditor ? (
        <SecurityEditor
          open={openSecurityEditor}
          onClose={handleCloseSecurity}
          document={selectedRecipe}
          onChange={handleChangeSecurity}
        />
      ) : null}

      {openRecipe ? (
        <RecipeDisplayModal
          open={openRecipe}
          onClose={() => setOpenRecipe(false)}
          recipe={selectedRecipe}
        />
      ) : null}

      {openSearch ? (
        <Search
          open={openSearch}
          searchCriteria={searchCriteria}
          onClose={handleSearchClose}
          searchFields={getSearchFields()}
          onSearch={handleSearch}
        />
      ) : null}
    </>
  );
};

export default Recipes;
