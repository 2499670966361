import React from "react";

const VideoViewer = ({ url }) => {
  if (!url) return null;

  return (
    <div>
      <video
        src={url}
        width="100%"
        controls
      />
    </div>
  );
};

export default VideoViewer;
