import { useEffect, useState } from "react";
import { format } from "date-fns";

import useMember from "../../../../hooks/useMember";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../../../ui/OFDIcon";
import AttendeeColors from "./AttendeeColors";
import OFDDisplayAddress from "../../../ui/OFDDisplayAddress";

const Appointment = ({ appointment, onSelect }) => {
  const { getMember } = useMember();

  const [open, setOpen] = useState(false);

  const getColor = () => {
    let color = "grey";

    for (const attendee of appointment.attendees) {
      if (attendee.entityName === "Member") {
        color = getMember(attendee.id).color;
        break;
      }
    }

    return color;
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "8px",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            gap={1}
          >
            <OFDIcon
              name={appointment.category}
              color={getColor()}
            />

            <Typography sx={{ fontWeight: 900 }}>
              {appointment.allDay
                ? "All Day"
                : format(appointment.startDate, "hh:mm a")}
            </Typography>

            <AttendeeColors attendees={appointment.attendees} />
          </Box>

          <Typography
            sx={{
              marginLeft: "32px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "180px",
              overflow: "hidden",
            }}
          >
            {appointment.name}
          </Typography>
        </Box>

        <OFDIcon
          name={open ? "arrowUp" : "arrowDown"}
          onClick={() => {
            setOpen(!open);
          }}
          color="grey"
        />
      </Box>

      {open ? (
        <Box
          sx={{
            marginTop: "10px",
            paddingLeft: "32px",
          }}
        >
          <Stack spacing={0.5}>
            <Typography variant="caption">
              {appointment.allDay
                ? "All Day"
                : `${format(appointment.startDate, "hh:mm a")} to ${format(
                    appointment.endDate,
                    "hh:mm a"
                  )}`}
            </Typography>

            {appointment.description ? (
              <Typography
                variant="caption"
                sx={{ whiteSpace: "pre-wrap", maxWidth: "200px" }}
              >
                {appointment.description}
              </Typography>
            ) : null}

            {appointment.address ? (
              <OFDDisplayAddress
                styleoverride={{ paddingLeft: "0px" }}
                value={appointment.address}
              />
            ) : null}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Appointment;
