import React from "react";

import { Box, Stack } from "@mui/material";

import OccasionDisplay from "./OccasionDisplay";

const OccasionsDisplay = ({ occasions, onUpdate }) => {
  return (
    <Box
      sx={{ backgroundColor: "#fff", borderRadius: "10px", padding: "1rem" }}
    >
      <Stack spacing={1}>
        {occasions?.map((calendar) => (
          <OccasionDisplay
            key={calendar.id}
            calendar={calendar}
            onUpdate={onUpdate}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default OccasionsDisplay;
