import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useMember from "../../../hooks/useMember";
import usePage from "../../../hooks/usePage";
import useLocalization from "../../../hooks/useLocalization";
import useFamily from "../../../hooks/useFamily";
import useWindow from "../../../hooks/useWindow";

import { Box, Collapse, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDIcon from "../../ui/OFDIcon";
import MenuItem from "./MenuItem";

const SideMenu = () => {
  const { memberColor, member } = useMember();
  const { navMenu, refresh } = usePage();
  const { getLabel } = useLocalization();
  const navigate = useNavigate();
  const { familyPreferences } = useFamily();
  const { windowHeight, windowWidth } = useWindow();

  const menuItemContainer = useRef();

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const [menu, setMenu] = useState(undefined);

  useEffect(() => {
    getMenu();
  }, [navMenu, familyPreferences]);

  const getMenu = async () => {
    setMenu(navMenu);
  };

  const handleOpen = () => {
    setOpen((current) => {
      return !current;
    });
  };

  const handlePageSelection = (page) => {
    setOpen(false);

    if (!page.subscribed) return;

    navigate(page.url);
  };

  const calcItemsPerColumn = () => {
    let menuItemHeight = 80 + 20;
    let items = parseInt((windowHeight - 125) / menuItemHeight);
    return items;
  };

  const calcNumberOfColumns = () => {
    if (!menu) return 1;
    let columns = menu.length / calcItemsPerColumn();
    return columns;
  };

  const columns = () => {
    let columnArray = [];
    for (let x = 0; x < calcNumberOfColumns(); x++) {
      columnArray.push(x + 1);
    }

    return columnArray;
  };

  if (menu === undefined) return null;

  return (
    <Box
      id="sideMenu"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        width: open ? `${columns().length * 100}px` : "100px",
        maxWidth: "100%",
        ...memberColor({ shade: 400 }),
        zIndex: 1500,
        overflow: "hidden",
        overflowX: "auto",
      }}
    >
      <Box
        id="sideMenuIcon"
        sx={{
          height: "125px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleOpen}
      >
        {columns().length > 1 ? (
          <OFDIcon
            name={open ? "arrowLeft" : "arrowRight"}
            color="grey"
            shade={50}
            size="xxlarge"
          />
        ) : null}
      </Box>

      <Box
        id="menuItemContainer"
        ref={menuItemContainer}
        sx={{
          height: `${windowHeight - 125}px`,
          width: open ? `${columns().length * 100}px` : "100px",
          display: "flex",
          overflow: "hidden",
        }}
      >
        {columns().map((column) => {
          return (
            <Box
              key={`column-${column}`}
              sx={{ width: "100px" }}
            >
              {menu?.map((page, index) => {
                const start = calcItemsPerColumn() * (column - 1);
                const end = calcItemsPerColumn() * column;

                if (index >= start && index < end) {
                  return (
                    <Box
                      key={`menuitem-${column}-${index}`}
                      sx={{ height: "100px", width: "100px" }}
                    >
                      <MenuItem
                        page={page}
                        onSelect={handlePageSelection}
                      />
                    </Box>
                  );
                }
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SideMenu;
