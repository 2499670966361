import { openDB } from "idb";

const dbPromise = openDB("OFD", 1, {
  upgrade(db) {
    db.createObjectStore("session");
    db.createObjectStore("member");
    db.createObjectStore("family");
    db.createObjectStore("data");
    db.createObjectStore("reminders");
  },
});

// Version 1 - used for member and family state
export async function getLocalState(state, key) {
  return (await dbPromise).get(state, key);
}

export async function setLocalState(state, key, value) {
  return (await dbPromise).put(state, value, key);
}

export async function deleteLocalState(state, key) {
  return (await dbPromise).delete(state, key);
}

export async function clearLocalState(state) {
  return (await dbPromise).clear(state);
}

// Version 2 - used for newer components like reminders
export async function getRecord(storeName, key) {
  return (await dbPromise).get(storeName, key);
}

export async function getAllRecords(storeName) {
  return (await dbPromise).getAll(storeName);
}

export async function putRecord(storeName, key, value) {
  return (await dbPromise).put(storeName, value, key);
}

export async function deleteRecord(storeName, key) {
  return (await dbPromise).delete(storeName, key);
}

export async function clearStore(storeName) {
  return (await dbPromise).clear(storeName);
}

export async function getKeys(storeName) {
  return (await dbPromise).getAllKeys(storeName);
}
