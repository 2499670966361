import React from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

const OFDContainer = ({ label, sx, children }) => {
  const { getLabel } = useLocalization();

  return (
    <Box sx={sx}>
      <Typography
        variant="h6"
        align="center"
      >
        {getLabel(label)}
      </Typography>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "1rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default OFDContainer;
