import React from "react";

import useLocalization from "../../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import ToDo from "./ToDo";

const ToDos = ({ todos, onSelect, onUpdated }) => {
  const { getMessage } = useLocalization();

  if (todos === undefined) return;

  return (
    <>
      <Box>
        {Array.isArray(todos) && todos.length > 0 ? (
          <Stack space={1}>
            {todos.map((todo) => (
              <ToDo
                key={todo.id}
                todo={todo}
                onUpdated={onUpdated}
              />
            ))}
          </Stack>
        ) : (
          <Typography>{getMessage("no_todos_ForToday")}</Typography>
        )}
      </Box>
    </>
  );
};

export default ToDos;
