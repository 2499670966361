import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import useColor from "../../hooks/useColor";

import { Box, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";

const OFDSortableItem = ({ item, isDragging }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const { getColor } = useColor();

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <Box
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      sx={{
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: ".5rem",
        marginTop: ".5rem",
        ...getColor({ color: "grey", shade: isDragging ? 600 : 300 }),
        ...style,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        gap={1}
      >
        <OFDIcon
          name="drag"
          color="grey"
          shade={900}
        />
        <Typography sx={{ flexGrow: 1, whiteSpace: "pre-wrap" }}>
          {item.label}
        </Typography>
      </Box>
    </Box>
  );
};

export default OFDSortableItem;
