import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography } from "@mui/material";

import OFDDisplayAttendee from "./OFDDisplayAttendee";

const attendeeTemplate = {
  entityName: "Member",
  id: null,
  note: null,
};

const OFDDisplayAttendees = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
  direction = "column",
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: direction === "column" ? "1px solid #e0e0e0" : "none",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}>
      <Box
        onClick={onClick}
        sx={{ width: "100%" }}
      >
        <Typography variant="overline">{getLabel(label)}</Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: direction,
          }}
          gap={1}
        >
          {value && value.length > 0
            ? value.map((attendee, index) => (
                <Box
                  key={attendee.id}
                  sx={{
                    marginLeft:
                      index > 0 && direction === "row" ? "-14px" : "0px",
                  }}
                >
                  <OFDDisplayAttendee
                    key={attendee.id}
                    attendee={attendee}
                    showName={direction === "column" ? true : false}
                  />
                </Box>
              ))
            : null}
        </Box>
      </Box>
    </Box>
  );
};

export default OFDDisplayAttendees;
