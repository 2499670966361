import BloodPressure from "../configuration/BloodPressure";

const useBloodPressure = () => {
  const getLevel = ({ systolic, diastolic }) => {
    let level = null;

    for (const bpLevel of BloodPressure) {
      if (bpLevel.label === "normal" || bpLevel.label === "elevated") {
        if (
          parseInt(systolic) <= bpLevel.systolicHigh &&
          parseInt(diastolic) <= bpLevel.diastolicHigh
        ) {
          return bpLevel;
        }
      } else {
        if (
          parseInt(systolic) <= bpLevel.systolicHigh ||
          parseInt(diastolic) <= bpLevel.diastolicHigh
        ) {
          return bpLevel;
        }
      }
    }

    return level;
  };

  const bpColor = (args) => {
    const level = getLevel(args);

    let color = {
      color: level.color,
      shade: level.shade,
    };

    return color;
  };

  return {
    getLevel,
    bpColor,
  };
};

export default useBloodPressure;
