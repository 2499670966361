import { useEffect } from "react";

import useLocalization from "../../../hooks/useLocalization";
import usePage from "../../../hooks/usePage";

import { Box, Button, Typography } from "@mui/material";

import Logo from "../../../assets/images/OFD_hrzntl_logo_Black.svg";

const PageError = ({ error, resetErrorBoundary }) => {
  const { getMessage } = useLocalization();
  const { reload } = usePage();

  // useEffect(() => {
  //   resetErrorBoundary();
  // }, []);

  const handleReload = async () => {
    await reload();
  };

  return (
    <Box
      sx={{
        paddingTop: "160px",
        height: "100%",
        overflowY: "auto",
        backgroundColor: "#ebebeb",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <img
          alt="logo"
          src={Logo}
          style={{ height: "8vh" }}
        />
      </Box>

      <Box sx={{ marginTop: "1rem" }}>
        <Typography
          variant="h6"
          align="center"
        >
          {getMessage("errorEncountered")}
        </Typography>
      </Box>

      <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Typography>{error ? error.message : "ERROR"}</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={resetErrorBoundary}
        >
          Retry
        </Button>

        <Button
          variant="contained"
          onClick={handleReload}
        >
          Reload
        </Button>
      </Box>
    </Box>
  );
};

export default PageError;
