import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

import { Box, Typography } from "@mui/material";

const OFDRelativeDays = ({ id, label, value, onChange, disabled }) => {
  const { getLabel, calendarLists } = useLocalization();
  const { memberColor, member } = useMember();

  const rowStyle = {
    display: "flex",
    alignItems: "center",
  };

  const labelStyle = {
    width: "50px",
  };

  const dowStyle = (isSelected) => {
    return {
      width: "calc((100% - 50px)/7)",
      aspectRatio: "1/1",
      padding: "8px",
      border: `1px solid ${memberColor({ shade: 200 }).backgroundColor}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px",
      ...memberColor({
        color: isSelected ? member.color : "inherit",
        shade: 200,
      }),
      color: disabled ? "#ccc" : "inherit",
    };
  };

  const isSelected = (week, dow) => {
    if (!value) return false;

    let selectedWeek = value.find((w) => w.week === week);
    if (!selectedWeek) return false;

    return selectedWeek.dow[dow];
  };

  const handleSelect = (week, dow) => {
    if (disabled) return;

    let newValue = value ? [...value] : [];

    let weekIndex = newValue.findIndex((w) => w.week === week);
    if (weekIndex === -1) {
      let newWeek = {
        week,
        dow: [false, false, false, false, false, false, false],
      };
      newWeek.dow[dow] = true;

      newValue.push(newWeek);
    } else {
      newValue[weekIndex].dow[dow] = !newValue[weekIndex].dow[dow];
    }

    onChange(newValue);
  };

  return (
    <Box>
      <Box sx={rowStyle}>
        <Box sx={labelStyle}>
          <Typography>{getLabel("first")}</Typography>
        </Box>
        {calendarLists.weekdays.map((dow) => (
          <Box
            key={dow.value}
            sx={dowStyle(isSelected("first", dow.value))}
            onClick={() => handleSelect("first", dow.value)}
          >
            {dow.short}
          </Box>
        ))}
      </Box>

      <Box sx={rowStyle}>
        <Box sx={labelStyle}>
          <Typography>{getLabel("second")}</Typography>
        </Box>
        {calendarLists.weekdays.map((dow) => (
          <Box
            key={dow.value}
            sx={dowStyle(isSelected("second", dow.value))}
            onClick={() => handleSelect("second", dow.value)}
          >
            {dow.short}
          </Box>
        ))}
      </Box>

      <Box sx={rowStyle}>
        <Box sx={labelStyle}>
          <Typography>{getLabel("third")}</Typography>
        </Box>
        {calendarLists.weekdays.map((dow) => (
          <Box
            key={dow.value}
            sx={dowStyle(isSelected("third", dow.value))}
            onClick={() => handleSelect("third", dow.value)}
          >
            {dow.short}
          </Box>
        ))}
      </Box>

      <Box sx={rowStyle}>
        <Box sx={labelStyle}>
          <Typography>{getLabel("fourth")}</Typography>
        </Box>
        {calendarLists.weekdays.map((dow) => (
          <Box
            key={dow.value}
            sx={dowStyle(isSelected("fourth", dow.value))}
            onClick={() => handleSelect("fourth", dow.value)}
          >
            {dow.short}
          </Box>
        ))}
      </Box>

      <Box sx={rowStyle}>
        <Box sx={labelStyle}>
          <Typography>{getLabel("last")}</Typography>
        </Box>
        {calendarLists.weekdays.map((dow) => (
          <Box
            key={dow.value}
            sx={dowStyle(isSelected("last", dow.value))}
            onClick={() => handleSelect("last", dow.value)}
          >
            {dow.short}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OFDRelativeDays;
