import { useState } from "react";
import useApi from "./useApi";

const useHomework = () => {
  const { getData, postData } = useApi();

  const [homework, setHomework] = useState(undefined);
  const [apiResult, setApiResult] = useState({
    isSuccessful: undefined,
    message: undefined,
    displayToUser: undefined,
  });

  const getOutstandingHomework = async () => {
    setHomework(null);

    const results = await getData({
      entityName: "Homework",
      action: "get",
      filter: {
        idComplete: false,
      },
    });

    if (results.isSuccessful) {
      setHomework(results.data);
    } else {
      setHomework(null);
    }

    setApiResult({
      isSuccessful: results.isSuccessful,
      message: results.message,
      displayToUser: results.displayToUser,
    });
  };

  const updateHomework = async (homeworkId, data) => {
    const results = await postData({
      entityName: "Homework",
      action: "update",
      data: {
        id: homeworkId,
        ...data,
      },
    });

    return results;
  };

  const clearHomework = () => {
    setHomework(null);
  };

  return {
    homework,
    getOutstandingHomework,
    updateHomework,
    apiResult,
    clearHomework,
  };
};

export default useHomework;
