import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";

import {
  Box,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";

const OFDSelect = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  fullWidth,
  listName,
  options,
  variant = "outlined",
  security,
  entryAddedBy,
}) => {
  const { getLabel, getList, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();
  const { memberColor } = useMember();

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (listName) {
      let list = getList(listName)?.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ));

      setMenuItems(list);
    } else if (options) {
      let list = options?.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ));

      setMenuItems(list);
    }
  }, [listName, options]);

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;
  if (menuItems.length === 0) return null;

  return (
    <>
      <FormControl
        id={id}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled}
        required={required}
        error={error}
        sx={{
          "& label.Mui-focused": {
            color: memberColor().backgroundColor,
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: memberColor().backgroundColor,
            },
          },
        }}
      >
        <InputLabel id={`${id}-label`}>{getLabel(label)}</InputLabel>

        <Select
          label={getLabel(label)}
          size="small"
          value={value === null ? "" : value}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          disabled={isDisabled()}
        >
          {menuItems}
        </Select>

        <FormHelperText error={error}>{getMessage(message)}</FormHelperText>
      </FormControl>
    </>
  );
};

export default OFDSelect;
