import { useEffect, useState } from "react";

import { Box, Typography, Divider } from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";
import useSecurity from "../../hooks/useSecurity";
import useWindow from "../../hooks/useWindow";

import OFDIcon from "./OFDIcon";
import OFDDisplayGallery from "./OFDDisplayGallery";
import OFDPicture from "./OFDPicture";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDButton from "./OFDButton";

const OFDGallery = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { newId } = useData();
  const { userCanView, userCanUpdate } = useSecurity();
  const { isMobile, windowHeight } = useWindow();

  const [selectedPicture, setSelectedPicture] = useState(null);
  const [gallery, setGallery] = useState(value);

  useEffect(() => {
    if (onChange) onChange(gallery);
  }, [gallery]);

  const handleChangePicture = (newImage) => {
    setSelectedPicture((current) => {
      if (!current) return { id: newId(), src: newImage };
      return current;
    });
  };

  const handleDelete = () => {
    setGallery((current) => {
      let newGallery = current.filter(
        (image) => image.id !== selectedPicture.id
      );

      return newGallery;
    });

    setSelectedPicture(null);
  };

  const handleCancel = () => {
    setSelectedPicture(null);
  };

  const handleAddPicture = () => {
    setGallery((current) => {
      let newGallery = Array.isArray(current) ? [...current] : [];

      newGallery = [...newGallery, { ...selectedPicture }];

      setSelectedPicture(null);

      return newGallery;
    });
  };

  const handleImageSelect = (image) => {
    setSelectedPicture(image);
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box
        sx={{
          height: isMobile ? `${windowHeight - 200}px` : "100%",
          overflow: "auto",
          paddingBottom: "3rem",
        }}
      >
        <Typography
          variant="h6"
          align="center"
        >
          {getLabel(label)}
        </Typography>

        <Box sx={{ paddingBottom: "1rem" }}>
          <OFDPicture
            value={selectedPicture ? selectedPicture.src : null}
            onChange={(newImage) => handleChangePicture(newImage)}
            disabled={isDisabled()}
            width="auto"
            height="200px"
          />
        </Box>

        <OFDFieldGroup justifyContent="flex-end">
          <OFDButton
            variant="outlined"
            onClick={handleDelete}
            label={getLabel("delete")}
            color="grey"
            shade={900}
            width="auto"
          />

          <OFDButton
            variant="outlined"
            onClick={handleCancel}
            label={getLabel("cancel")}
            color="grey"
            shade={900}
            width="auto"
          />

          <OFDButton
            variant="contained"
            onClick={handleAddPicture}
            label={getLabel("add")}
            color="grey"
            shade={900}
            width="auto"
          />
        </OFDFieldGroup>

        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          Images
        </Divider>

        <OFDDisplayGallery
          value={gallery}
          onSelect={handleImageSelect}
        />
      </Box>
    </>
  );
};

export default OFDGallery;
