import Family_1 from "./ArabCouple_Child_Rooftop-min.jpg";
import Family_2 from "./BlackCouple_OnCouch_Smartphones-min.jpg";
import Family_3 from "./Family_4_Couch_BeigeWall-min.jpg";
import Family_4 from "./Family_4_Couch_Tablet_Orange Wall-min.jpg";
import Family_5 from "./Family-of-4_OnCouch_YellowWall_Tablet-min.jpg";
import Family_6 from "./Family-of-4_OnFloor_Rooftop-min.jpg";
import Family_7 from "./Individual_Woman-min.jpg";
import Family_8 from "./LrgFamily_Black_Couch-min.jpg";
import Family_9 from "./MedFamily_Couch_Laptop-min.jpg";
import Family_10 from "./SameSex_Female_Couch-min.jpg";
import Family_11 from "./SingleMom_BabySling_Smartphone-min.jpg";
import Family_12 from "./Woman-with-smartphone_ThumbsUp-min.jpg";

const FamilyImage = () => {
  const family_Int = Math.floor(Math.random() * 12);

  switch (family_Int) {
    case 1:
      return Family_1;

    case 2:
      return Family_2;

    case 3:
      return Family_3;

    case 4:
      return Family_4;

    case 5:
      return Family_5;

    case 6:
      return Family_6;

    case 7:
      return Family_7;

    case 8:
      return Family_8;

    case 9:
      return Family_9;

    case 10:
      return Family_10;

    case 11:
      return Family_11;

    case 12:
      return Family_12;

    default:
      return Family_9;
  }
};

export default FamilyImage;
