import React from "react";

import useLocalization from "../../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDInstructions from "../../ui/OFDInstructions";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const RecipeInstructions = ({ instructions, onChange }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  const handleChange = (newItems) => {
    onChange(newItems);
  };

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <Typography
          variant="h5"
          sx={{ marginBottom: "1rem" }}
        >
          {getWizard("recipe").instructions.title}
        </Typography>

        <OFDFieldContainer>
          <OFDInstructions
            value={instructions}
            onChange={handleChange}
            wizard
          />
        </OFDFieldContainer>
      </Box>
    </>
  );
};

export default RecipeInstructions;
