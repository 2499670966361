import { useEffect, useState } from "react";

import useWindow from "../../../../hooks/useWindow";
import useGrocery from "../../../../hooks/useGrocery";
import useLocalization from "../../../../hooks/useLocalization";
import useData from "../../../../hooks/useData";

import { Box, Modal, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import RecipePlaceholder from "../../../../assets/images/RecipePlaceholder.jpeg";

import OFDIcon from "../../../ui/OFDIcon";
import OFDDisplayUrl from "../../../ui/OFDDisplayUrl";
import OFDDisplayText from "../../../ui/OFDDisplayText";
import OFDFieldGroup from "../../../ui/OFDFieldGroup";
import IngredientItem from "./IngredientItem";
import RecipeInstruction from "./RecipeInstruction";
import OFDCheckBox from "../../../ui/OFDCheckBox";
import OFDMessage from "../../../ui/OFDMessage";
import OFDButton from "../../../ui/OFDButton";

const RecipeFullView = ({ recipe, open, onClose }) => {
  const { windowHeight, windowWidth } = useWindow();
  const { getLabel, getMessage } = useLocalization();
  const {
    getGroceryLists,
    groceryLists,
    formMessage,
    resetFormMessage,
    addIngredientsToList,
  } = useGrocery();
  const { newId } = useData();
  const theme = useTheme();

  const [openInstructions, setOpenInstructions] = useState(false);
  const [openShopping, setOpenShopping] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientsPurchased, setIngredientsPurchased] = useState([]);
  const [selectedGroceryList, setSelectedGroceryList] = useState(null);

  useEffect(() => {
    getGroceryLists({ completedAt: null });
  }, [recipe]);

  const selectIngredient = (ingredient) => {
    setIngredients((current) => {
      let list = [...current];
      let index = list.findIndex((i) => i.id === ingredient.id);
      if (index > -1) {
        list.splice(index, 1);
      } else {
        list.push({ ...ingredient });
      }

      return list;
    });
  };

  const isIngredientSelected = (ingredient) => {
    let index = ingredients.findIndex((i) => i.id === ingredient.id);
    if (index === -1) return false;

    return true;
  };

  const isIngredientPurchased = (ingredient) => {
    let index = ingredientsPurchased.findIndex((i) => i.id === ingredient.id);
    if (index === -1) return false;

    return true;
  };

  const selectGroceryList = (groceryList) => {
    if (selectedGroceryList && selectedGroceryList.id === groceryList.id) {
      setSelectedGroceryList(null);
      return;
    }

    setSelectedGroceryList(groceryList);
  };

  const isGroceryListSelected = (groceryList) => {
    if (!selectedGroceryList) return false;

    if (selectedGroceryList.id === groceryList.id) return true;

    return false;
  };

  const purchaseIngredients = () => {
    if (!ingredients.length === 0) return;
    if (!selectedGroceryList) return;

    setIngredientsPurchased((current) => {
      let list = [...current];

      for (const ingredient of ingredients) {
        list.push({
          ...ingredient,
          groceryListId: selectedGroceryList.id,
          selected: false,
        });
      }

      return list;
    });

    setIngredients([]);
    setSelectedGroceryList(null);
  };

  const selectPurchasedIngredient = (ingredient) => {
    setIngredientsPurchased((current) => {
      let newlist = [...current];
      const index = newlist.findIndex((i) => i.id === ingredient.id);

      newlist[index].selected = !newlist[index].selected;

      return newlist;
    });
  };

  const unPurchaseIngredients = () => {
    setIngredientsPurchased((current) => {
      let list = [...current];

      for (const ingredient of ingredientsPurchased) {
        if (!ingredient.selected) continue;

        let index = list.findIndex((i) => i.id === ingredient.id);
        list.splice(index, 1);
      }

      return list;
    });

    setIngredients([]);
  };

  const saveToGroceryLists = async () => {
    let saveItems = [];
    for (const item of ingredientsPurchased) {
      let index = saveItems.findIndex(
        (i) => i.groceryListId === item.groceryListId
      );
      let groceryItem = {
        id: newId(),
        department: `${getLabel("recipe")} : ${recipe.name}`,
        item: item.name,
        quantity: item.quantity,
      };

      if (index === -1) {
        saveItems.push({
          groceryListId: item.groceryListId,
          items: [{ ...groceryItem }],
        });
      } else {
        saveItems[index].items.push({ ...groceryItem });
      }
    }

    for (const groceryList of saveItems) {
      let results = await addIngredientsToList(
        groceryList.groceryListId,
        groceryList.items
      );
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Box
          sx={{
            height: windowHeight,
            width: windowWidth,
            backgroundColor: "#fff",
            padding: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "175px",
            }}
          >
            <Box
              sx={{ display: "flex" }}
              gap={2}
            >
              <Box>
                <img
                  src={recipe.image ? recipe.image : RecipePlaceholder}
                  style={{
                    borderRadius: ".5rem",
                    width: "auto",
                    height: "175px",
                  }}
                />
              </Box>

              <Box>
                <Box
                  sx={{ display: "flex" }}
                  gap={3}
                >
                  <Typography variant="h3">{recipe.name}</Typography>
                </Box>

                {recipe.externalUrl ? (
                  <OFDDisplayUrl value={recipe.externalUrl} />
                ) : null}

                {recipe.preparationTime ||
                recipe.cookTime ||
                recipe.servings ? (
                  <OFDFieldGroup>
                    <OFDDisplayText
                      id="preparationTime"
                      label="preparationTime"
                      value={recipe.preparationTime}
                    />
                    <OFDDisplayText
                      id="cookTime"
                      label="cookTime"
                      value={recipe.cookTime}
                    />
                    <OFDDisplayText
                      id="servings"
                      label="servings"
                      value={recipe.servings}
                    />
                  </OFDFieldGroup>
                ) : null}
              </Box>
            </Box>

            <Box
              sx={{
                border: "1px solid #ccc",
                height: "48px",
                width: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <OFDIcon
                name="close"
                color="grey"
                size="large"
                onClick={onClose}
              />
            </Box>
          </Box>

          <Box
            sx={{
              height: `${windowHeight - 175 - 32}px`,
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "50%",
                height: `${windowHeight - 175 - 32}px`,
                padding: "16px",
              }}
            >
              <Box
                sx={{ height: "32px", display: "flex", alignItems: "center" }}
                gap={2}
              >
                <Typography
                  variant="h4"
                  sx={{ textDecoration: "underline" }}
                >
                  {getLabel("ingredients")}
                </Typography>

                <OFDIcon
                  name="grocery"
                  size="medium"
                  color="orange"
                  shade={900}
                  onClick={() => setOpenShopping(true)}
                />
              </Box>

              <Box
                sx={{
                  height: `${windowHeight - 175 - 32 - 64}px`,
                  width: "100%",
                  overflow: "auto",
                  paddingTop: "1rem",
                }}
              >
                {Array.isArray(recipe.ingredients) &&
                recipe.ingredients.length > 0 ? (
                  <Stack space={1}>
                    {recipe.ingredients.map((ingredient) => (
                      <IngredientItem
                        key={ingredient.id}
                        ingredient={ingredient}
                      />
                    ))}
                  </Stack>
                ) : (
                  <Typography>{getMessage("noIngredients")}</Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: "50%",
                height: `${windowHeight - 175 - 32}px`,
                padding: "16px",
              }}
            >
              <Box
                sx={{ height: "32px", display: "flex", alignItems: "center" }}
                gap={2}
              >
                <Typography
                  variant="h4"
                  sx={{ textDecoration: "underline" }}
                >
                  {getLabel("instructions")}
                </Typography>

                <OFDIcon
                  name="openPage"
                  size="medium"
                  color="orange"
                  shade={900}
                  onClick={() => setOpenInstructions(true)}
                />
              </Box>

              <Box
                sx={{
                  height: `${windowHeight - 175 - 32 - 64}px`,
                  width: "100%",
                  overflow: "auto",
                  paddingTop: "1rem",
                }}
              >
                {Array.isArray(recipe.instructions) &&
                recipe.instructions.length > 0 ? (
                  <Stack spacing={1}>
                    {recipe.instructions.map((instruction) => (
                      <RecipeInstruction
                        key={instruction.id}
                        instruction={instruction}
                      />
                    ))}
                  </Stack>
                ) : (
                  <Typography>{getMessage("noInstructions")}</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openInstructions}
        onClose={() => setOpenInstructions(false)}
      >
        <Box
          sx={{
            height: windowHeight,
            width: windowWidth,
            backgroundColor: "#fff",
            padding: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "50px",
            }}
          >
            <Typography
              variant="h4"
              sx={{ textDecoration: "underline" }}
            >
              {getLabel("instructions")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "50%",
              }}
            >
              <OFDIcon
                name="close"
                color="grey"
                size="large"
                onClick={() => setOpenInstructions(false)}
              />
            </Box>
          </Box>

          <Box
            sx={{
              height: `${windowHeight - 80 - 20}px`,
              overflow: "auto",
              paddingTop: "20px",
            }}
          >
            {Array.isArray(recipe.instructions) &&
            recipe.instructions.length > 0 ? (
              <Stack spacing={2}>
                {recipe.instructions.map((instruction) => (
                  <RecipeInstruction
                    key={instruction.id}
                    instruction={instruction}
                    fontSize="32px"
                  />
                ))}
              </Stack>
            ) : (
              <Typography>{getMessage("noInstructions")}</Typography>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openShopping}
        onClose={() => setOpenShopping(false)}
      >
        <Box
          sx={{
            height: windowHeight,
            width: windowWidth,
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "80px",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <Typography variant="h4">{getLabel("addToGrocery")}</Typography>
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              gap={3}
            >
              {ingredientsPurchased.length > 0 ? (
                <Box>
                  <OFDButton
                    id="save"
                    label="saveToGroceryLists"
                    iconName="save"
                    variant="outlined"
                    color="grey"
                    shade={50}
                    onClick={saveToGroceryLists}
                  />
                </Box>
              ) : null}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #fff",
                  borderRadius: "50%",
                  width: "38px",
                  height: "38px",
                }}
              >
                <OFDIcon
                  name="close"
                  size="medium"
                  color="grey"
                  shade={50}
                  onClick={() => setOpenShopping(false)}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              padding: "1rem",
              height: `${windowHeight - 80 - 32}px`,
              display: "flex",
            }}
            gap={5}
          >
            <Box
              sx={{
                height: `${windowHeight - 80 - 32}`,
                width: "50%",
                overflow: "auto",
              }}
            >
              <Typography
                variant="h5"
                sx={{ textDecoration: "underline" }}
              >
                {getLabel("ingredients")}
              </Typography>
              {Array.isArray(recipe.ingredients) &&
              recipe.ingredients.length > 0 ? (
                <Stack
                  spacing={1}
                  mt="1rem"
                >
                  {recipe.ingredients.map((ingredient) => {
                    if (isIngredientPurchased(ingredient)) return;

                    return (
                      <Box
                        key={ingredient.id}
                        sx={{ display: "flex", alignItems: "center" }}
                        gap={1}
                      >
                        <OFDCheckBox
                          color="orange"
                          shade={900}
                          onChange={() => selectIngredient(ingredient)}
                          value={isIngredientSelected(ingredient)}
                          size="large"
                        />
                        <Typography sx={{ fontSize: "20px" }}>
                          {ingredient.name}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>
              ) : (
                <Typography>{getMessage("noIngredients")}</Typography>
              )}
            </Box>

            <Box
              sx={{
                height: `${windowHeight - 80 - 32}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "1rem",
                paddingTop: "4rem",
              }}
              gap={3}
            >
              <Box
                sx={{
                  width: "48px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #000",
                  borderRadius: "50%",
                }}
              >
                <OFDIcon
                  name="arrowRight"
                  size="large"
                  color="grey"
                  shade={900}
                  onClick={purchaseIngredients}
                />
              </Box>

              <Box
                sx={{
                  width: "48px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #000",
                  borderRadius: "50%",
                }}
              >
                <OFDIcon
                  name="arrowLeft"
                  size="large"
                  color="grey"
                  shade={900}
                  onClick={unPurchaseIngredients}
                />
              </Box>
            </Box>

            <Box
              sx={{
                height: `${windowHeight - 80 - 32}`,
                width: "50%",
                overflow: "auto",
              }}
            >
              <Typography
                variant="h5"
                sx={{ textDecoration: "underline" }}
              >
                {getLabel("groceryLists")}
              </Typography>

              {Array.isArray(groceryLists) && groceryLists.length > 0 ? (
                <Stack
                  spacing={1}
                  mt="1rem"
                >
                  {groceryLists.map((groceryList) => (
                    <Box key={groceryList.id}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <OFDCheckBox
                          size="large"
                          color="orange"
                          shade={900}
                          value={isGroceryListSelected(groceryList)}
                          onChange={() => selectGroceryList(groceryList)}
                        />
                        <Typography variant="h6">{groceryList.name}</Typography>
                      </Box>

                      {ingredientsPurchased.length > 0 ? (
                        <Box sx={{ paddingLeft: "48px" }}>
                          <Stack spacing={1}>
                            {ingredientsPurchased.map((ingredient) => {
                              if (ingredient.groceryListId === groceryList.id) {
                                return (
                                  <Box
                                    key={ingredient.id}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <OFDCheckBox
                                      color="orange"
                                      shade={900}
                                      onChange={() =>
                                        selectPurchasedIngredient(ingredient)
                                      }
                                      value={ingredient.selected}
                                    />
                                    <Typography>{ingredient.name}</Typography>
                                  </Box>
                                );
                              }
                            })}
                          </Stack>
                        </Box>
                      ) : null}
                    </Box>
                  ))}
                </Stack>
              ) : (
                <Typography>{getMessage("noGrocery")}</Typography>
              )}
            </Box>
          </Box>

          <OFDMessage
            message={formMessage}
            onClose={resetFormMessage}
          />
        </Box>
      </Modal>
    </>
  );
};

export default RecipeFullView;
