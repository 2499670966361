import { useEffect, useState } from "react";

import useEntity from "../../hooks/useEntity";
import usePage from "../../hooks/usePage";
import useLocalization from "../../hooks/useLocalization";

import { Box, Typography, Stack } from "@mui/material";

import AccountEditor from "../editors/AccountEditor";
import AccountItem from "../display/AccountItem";

import OFDTitle from "../layout/OFDTitle";
import Loading from "../layout/Loading";

const Accounts = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refresh } =
    usePage();
  const { entityList, getEntityList } = useEntity("Member");
  const { getLabel } = useLocalization();

  const [openAccount, setOpenAccount] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setEntityName("Account");
    getMembers();
  }, [refresh]);

  const getMembers = async () => {
    await getEntityList();
  };

  useEffect(() => {
    if (toolbarAction === "addAccount") {
      setSelectedMember(null);
      setOpenAccount(true);
    }
    resetToolbarAction();
  }, [toolbarAction]);

  const handleEditMember = (member) => {
    setSelectedMember(member);
    setOpenAccount(true);
  };

  const handleCloseEditor = () => {
    setOpenAccount(false);
    setSelectedMember(null);
  };

  const handleRefresh = async () => {
    setOpenAccount(false);
    setSelectedMember(null);
    await getMembers();
  };

  return (
    <>
      <OFDTitle title="accounts" />

      {Array.isArray(entityList) && entityList.length > 0 ? (
        <Stack
          spacing={2}
          sx={{ marginTop: "1rem" }}
        >
          {entityList.map((member) => (
            <AccountItem
              key={member.id}
              member={member}
              onEdit={handleEditMember}
            />
          ))}
        </Stack>
      ) : (
        <Loading />
      )}

      <AccountEditor
        open={openAccount}
        onClose={handleCloseEditor}
        onSave={handleRefresh}
        account={selectedMember}
        option={selectedOption}
      />
    </>
  );
};

export default Accounts;
