import {
  TextField,
  FormControl,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  Typography,
} from "@mui/material";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";
import useMember from "../../hooks/useMember";
import useColor from "../../hooks/useColor";

import OFDIcon from "./OFDIcon";

const OFDTextField = ({
  id,
  label,
  value,
  onChange,
  message,
  error,
  required,
  disabled,
  multiline,
  fullWidth,
  security,
  entryAddedBy,
  type,
  color,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();
  const { memberColor } = useMember();
  const { getColor } = useColor();

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <FormControl
      id={id}
      fullWidth={fullWidth}
      size="small"
      required={required}
      sx={{
        "& label.Mui-focused": {
          color: color
            ? getColor({ color }).backgroundColor
            : memberColor().backgroundColor,
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: color
              ? getColor({ color }).backgroundColor
              : memberColor().backgroundColor,
          },
        },
      }}
    >
      <InputLabel htmlFor={id}>{getLabel(label)}</InputLabel>

      <OutlinedInput
        id={id}
        label={getLabel(label)}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        error={error || false}
        disabled={isDisabled()}
        multiline={multiline}
        type={type || "text"}
        size="small"
        endAdornment={
          <InputAdornment position="end">
            <OFDIcon
              name="clearentry"
              color="grey"
              onClick={() => onChange("")}
            />
          </InputAdornment>
        }
        autoComplete="new-password"
        inputProps={{
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
        }}
      />

      <FormHelperText error={error || false}>
        {message ? getMessage(message) : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default OFDTextField;
