import useLocalization from "../../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDTextField from "../../ui/OFDTextField";
import OFDTextNote from "../../ui/OFDTextNote";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const RecipeName = ({ value, onChange, error, message }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();

  const getValue = (field) => {
    if (!value) return null;
    if (!value[field]) return null;

    return value[field];
  };

  const handleChange = (field, newValue) => {
    if (!value) {
      onChange({ [field]: newValue });
      return;
    }

    onChange({ ...value, [field]: newValue });
  };

  return (
    <Box>
      <Typography variant="h5">{getWizard("recipe").name.title}</Typography>

      <Stack spacing={2}>
        {getWizard("recipe").name.instructions.map((instruction, index) => (
          <Typography key={`instruction-${index}`}>{instruction}</Typography>
        ))}
      </Stack>

      <OFDFieldContainer sx={{ marginTop: "1rem" }}>
        <Stack spacing={1}>
          <OFDTextField
            id="name"
            label="name"
            value={getValue("name") || ""}
            onChange={(newValue) => handleChange("name", newValue)}
            error={error}
            message={message}
            fullWidth
          />

          <OFDTextNote
            id="description"
            label="description"
            value={getValue("description") || ""}
            onChange={(newValue) => handleChange("description", newValue)}
            fullWidth
          />
        </Stack>
      </OFDFieldContainer>
    </Box>
  );
};

export default RecipeName;
