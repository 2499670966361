import { useEffect, useState, useRef } from "react";
import { startOfToday, format, isBefore, getMonth, getDate } from "date-fns";

import { Box, Stack, Typography, Tab, Tabs } from "@mui/material";

import useForm from "../../hooks/useForm";
import useEntity from "../../hooks/useEntity";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";
import useCalendar from "../../hooks/useCalendar";
import useApi from "../../hooks/useApi";
import useWindow from "../../hooks/useWindow";
import useICal from "../../hooks/useICal";
import usePage from "../../hooks/usePage";
import useSecurity from "../../hooks/useSecurity";

import OFDModal from "../layout/OFDModal";
import OFDMessage from "../ui/OFDMessage";
import OFDDateTimeRange from "../ui/OFDDateTimeRange";
import OFDToolbar from "../layout/OFDToolbar";
import OFDTextField from "../ui/OFDTextField";
import OFDTextNote from "../ui/OFDTextNote";
import OFDDisplayAttendees from "../ui/OFDDisplayAttendees";
import OFDDisplayCategory from "../ui/OFDDisplayCategory";
import OFDDisplayAddress from "../ui/OFDDisplayAddress";
import OFDSelect from "../ui/OFDSelect";
import OFDTimeRange from "../ui/OFDTimeRange";
import OFDDateRange from "../ui/OFDDateRange";
import OFDDate from "../ui/OFDDate";
import OFDDialog from "../layout/OFDDialog";
import OFDFieldContainer from "../ui/OFDFieldContainer";
import OFDSecurity from "../ui/OFDSecurity";
import OFDTabPanel from "../layout/OFDTabPanel";
import OFDButton from "../ui/OFDButton";
import OFDDisplayReminders from "../ui/OFDDisplayReminders";
import SeriesFilter from "./SeriesFilter";
import LoadingModal from "../layout/LoadingModal";
import OFDMonthDay from "../ui/OFDMonthDay";

import FieldEditorModal from "./FieldEditorModal";

import OFDWeekySchedule from "../ui/OFDWeeklySchedule";
import OFDMonthlySchedule from "../ui/OFDMonthlySchedule";
import OFDMultiDatePicker from "../ui/OFDMultiDatePicker";
import OFDFileUploader from "../ui/OFDFileUploader";

import OFDRecurrence from "../ui/OFDRecurrence";

const defaultSecurity = {
  view: { securityLevel: "family" },
  update: { securityLevel: "addedBy" },
  delete: { securityLevel: "addedBy" },
};

const initialRecurrence = {
  rrule: null,
  occurrenceStart: null,
  frequency: "onetime",
  interval: null,
  count: null,
  until: null,
  forever: false,
  byDay: null,
  bySetPosition: null,
  byMonthDay: null,
  byMonth: null,
  monthlyType: "relative",
  scheduleDates: null,
};

const AppointmentEditor = ({
  calendar,
  open,
  onClose,
  onSave,
  option,
  selectedDate,
}) => {
  const { entityConfig, defaultValues } = useEntity("Appointment");
  const {
    validateCalendar,
    transformCalendar,
    getEntityDates,
    getSelectedDate,
  } = useCalendar();
  const { getLabel, getText, getMessage } = useLocalization();
  const { member, memberColor } = useMember();
  const { getData } = useApi();
  const { isMobile, windowHeight } = useWindow();
  const { testICalLink } = useICal();
  const { getPageSecurity } = usePage();
  const { pageDefaultSecurity } = useSecurity();

  const {
    data,
    setData,

    setFieldValue,
    getFieldValue,
    getFieldMessage,
    getFieldLabel,
    getFieldError,
    getDataById,

    saveData,
    formMessage,
    formStatus,
    resetFormMessage,
    resetFieldMessages,
    setFieldMessages,
    setFormMessage,

    validate,
  } = useForm("Appointment");

  const [appointment, setAppointment] = useState(null);
  const [attendeeEditor, setAttendeeEditor] = useState(false);
  const [categorySelector, setCategorySelector] = useState(false);
  const [addressEditor, setAddressEditor] = useState(false);
  const [remindersEditor, setRemindersEditor] = useState();
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [fieldsHeight, setFieldsHeight] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [iCalLinkVerified, setICalLinkVerified] = useState(false);
  const [iCalError, setICalError] = useState(false);
  const [iCalMessage, setICalMessage] = useState(null);

  const [importFilename, setImportFilename] = useState("");
  const [importFileError, setImportFileError] = useState(false);
  const [importFileUploaded, setImportFileUploaded] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const [seriesFilter, setSeriesFilter] = useState(null);

  const [recurrence, setRecurrence] = useState({
    ...initialRecurrence,
    occurrenceStart: selectedDate ? selectedDate : startOfToday(),
  });
  const [timeRange, setTimeRange] = useState(null);

  const containerRef = useRef();
  const toolbarRef = useRef();
  const seriesFilterRef = useRef();

  const [containerHeight, setContainerHeight] = useState("100%");
  const [fieldErrors, setFieldErrors] = useState(null);

  const tabStyle = {
    "& .MuiTabs-indicator": {
      backgroundColor: memberColor().backgroundColor,
    },
    "& .MuiTab-root.Mui-selected": {
      color: memberColor().backgroundColor,
    },
  };

  useEffect(() => {
    if (!containerRef.current) {
      // setFieldsHeight(windowHeight - 200);
      return;
    }

    setFieldsHeight(containerRef.current.clientHeight - 100);
  }, [containerRef]);

  useEffect(() => {
    if (!toolbarRef || !toolbarRef.current) {
      setContainerHeight("calc(100% - 4rem");
    } else {
      setContainerHeight(() => {
        let height = windowHeight - toolbarRef.current.clientHeight - 100;

        return `${height}px`;
      });
    }
  }, [toolbarRef.current]);

  useEffect(() => {
    if (open) {
      resetFormMessage();
      resetFieldMessages();

      setICalLinkVerified(false);
      setICalError(false);
      setICalMessage(null);

      setImportFileError(false);
      setImportFileUploaded(false);
      setImportFilename(null);

      setFieldErrors(null);

      setData(null);
    }

    if (calendar) {
      getAppointment();
    } else {
      let selectedDate = getSelectedDate();
      const newData = {
        ...defaultValues,
        startDate: selectedDate || startOfToday(),
        attendees: [{ entityName: "Member", id: member.id, notes: null }],
        category: "appointment",
        frequency: "onetime",
        security: { ...pageDefaultSecurity("Calendar") },
      };

      setData(newData);

      setTimeRange([null, null, true]);
    }
  }, [calendar, open, option]);

  const getAppointment = async () => {
    const results = await getData({
      entityName: "Appointment",
      action: "get",
      id: calendar.source.id,
    });

    if (results.isSuccessful) {
      setAppointment(results.data);

      if (option === "occurrence") {
        setData({
          ...results.data,
          name: calendar.name,
          isImport: results.data.frequency === "import",
          frequency:
            results.data.frequency === "import"
              ? "onetime"
              : results.data.frequency,
          originalStartDate: calendar.startDate,
          startDate: calendar.startDate,
          endDate: calendar.endDate,
          fromTime: calendar.fromTime,
          toTime: calendar.toTime,
          allDay: calendar.allDay,
          category: calendar.category,
          description: calendar.description,
          attendees: calendar.attendees,
          address: calendar.address,
          security: calendar.security,
          reminders: calendar.reminders,
        });
      } else {
        setData({ ...results.data });
        if (option === "series") {
          let filterStartDate = startOfToday();
          if (isBefore(filterStartDate, results.data.startDate)) {
            filterStartDate = results.data.startDate;
          }
          setSeriesFilter(null);
        }
      }

      setRecurrence(results.data.recurrence);
      setTimeRange(() => {
        let range = [
          results.data.fromTime ? results.data.fromTime : null,
          results.data.toTime ? results.data.toTime : null,
          results.data.allDay,
        ];
        return range;
      });
    } else {
      setData(null);
    }
  };

  const handleToolbarClick = async (name) => {
    switch (name) {
      case "save":
        await saveAppointment();
        break;
    }
  };

  const handleAttendeeEditor = () => {
    setAttendeeEditor(true);
  };

  const handleCancelAttendees = () => {
    setAttendeeEditor(false);
  };

  const handleChangeAttendees = (newValue) => {
    setAttendeeEditor(false);
    setFieldValue("attendees", newValue);
  };

  const saveAppointment = async () => {
    resetFormMessage();
    resetFieldMessages();

    let newData = transformCalendar(data, option);

    const validationResults = validate(validateCalendar, newData, option);

    if (!validationResults.isSuccessful) {
      setFormMessage(validationResults);
      setFieldMessages(validationResults.fieldErrors);
      setFieldErrors(validationResults.fieldErrors);
      return;
    }

    let action = data.id ? `updateAppointment` : `addAppointment`;

    if (newData.isImport) {
      newData.frequency = "import";
    }

    let options = null;
    options = {
      option: option ? option : null,
      calendarId: calendar ? calendar.id : null,
      seriesFilter,
    };

    setOpenLoading(true);

    const results = await saveData({
      action,
      data: newData,
      options,
    });

    setOpenLoading(false);

    if (results.isSuccessful) {
      onSave();
    }
  };

  const handleCategorySelector = () => {
    setCategorySelector(true);
  };

  const handleCancelCategory = () => {
    setCategorySelector(false);
  };

  const handleChangeCategory = (newValue) => {
    setCategorySelector(false);
    setFieldValue("category", newValue);
  };

  const handleAddressEditor = () => {
    setAddressEditor(true);
  };

  const handleCancelAddress = () => {
    setAddressEditor(false);
  };

  const handleChangeAddress = (newValue) => {
    setAddressEditor(false);
    setFieldValue("address", newValue);
  };

  const handleRemindersEditor = () => {
    setRemindersEditor(true);
  };

  const handleCancelReminders = () => {
    setRemindersEditor(false);
  };

  const handleChangeReminders = (newValue) => {
    setRemindersEditor(false);
    setFieldValue("reminders", newValue);
  };

  const handleTabChange = (e, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleTestICalLink = async () => {
    if (!getFieldValue("iCalLink")) return;

    const results = await testICalLink(getFieldValue("iCalLink"));

    if (results.isSuccessful) {
      setICalLinkVerified(true);
      setICalError(false);
      setFieldValue("iCalName", results.data.name);
      setFieldValue("iCalDates", results.data.dates);
      setFieldValue("iCalEventCount", results.data.eventCount);
    } else {
      setICalLinkVerified(false);
      setICalError(true);
    }
  };

  const handleFileUpload = (file) => {
    if (file.type === "text/calendar") {
      setImportFileUploaded(true);
      setImportFileError(false);
      setImportFilename(file.name);
      convertFileToText(file);
    } else {
      setImportFileError(true);
      setImportFileUploaded(false);
      setImportFilename(getMessage("invalidCalendarFile"));
    }
  };

  const convertFileToText = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setFieldValue("iCalData", reader.result);
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const handleDescriptionChange = (newValue) => {
    if (!data) return;
    setFieldValue("description", newValue);
  };

  const handleRecurrenceChange = (newValue) => {
    setRecurrence(newValue);
    setFieldValue("recurrence", newValue);
    setFieldValue("frequency", newValue.frequency);

    if (!data.id && newValue.frequency !== "onetime") {
      setFieldValue("fromTime", null);
      setFieldValue("toTime", null);
      setFieldValue("allDay", false);

      setTimeRange([null, null, false]);

      if (newValue.frequency !== "schedule") {
        setFieldValue("startDate", newValue.occurrenceStart);
      } else {
        if (
          Array.isArray(newValue.scheduleDates) &&
          newValue.scheduleDates.length > 0
        ) {
          setFieldValue("startDate", newValue.scheduleDates[0]);
        }
      }
    }
  };

  const handleDateTimeRange = (dates) => {
    setFieldValue("startDate", dates[0]);
    setFieldValue("endDate", dates[1]);
    setFieldValue("allDay", dates[2]);

    setFieldValue("fromTime", dates[0]);
    setFieldValue("toTime", dates[1]);
  };

  const handleTimeRangeChange = (newValue) => {
    setFieldValue("fromTime", newValue[0]);
    setFieldValue("toTime", newValue[1]);
    setFieldValue("allDay", newValue[2]);

    setTimeRange(newValue);
  };

  if (!data) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="appointment"
      >
        <Box
          ref={toolbarRef}
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#ccc",
            zIndex: 1000,
          }}
        >
          <OFDToolbar
            menuItems={[
              {
                name: "save",
                label: "save",
                icon: "save",
              },
            ]}
            onClick={handleToolbarClick}
          />
        </Box>

        <Box sx={{ height: "100%" }}>
          <Box
            ref={containerRef}
            sx={{
              height: { containerHeight },
              overflow: "auto",
            }}
          >
            {calendar &&
            calendar.startDate &&
            calendar.frequency !== "onetime" &&
            option === "occurrence" ? (
              <Box sx={{ marginTop: "1rem" }}>
                <Typography
                  variant="h6"
                  align="center"
                >
                  {format(calendar.startDate, "EEEE, MMMM dd, yyyy")}
                </Typography>
              </Box>
            ) : null}

            {option === "series" && data ? (
              <Box>
                <OFDFieldContainer
                  sx={{
                    padding: "1rem",
                    margin: "1rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <SeriesFilter
                    id="seriesFilter"
                    startDate={appointment.startDate}
                    endDate={
                      appointment.forever ? null : appointment.lastScheduledDate
                    }
                    filter={seriesFilter}
                    onChange={(newValue) => setSeriesFilter(newValue)}
                  />
                </OFDFieldContainer>
              </Box>
            ) : null}

            <OFDFieldContainer
              sx={{
                // height: fieldsHeight ? `${fieldsHeight}px` : "85%",
                // overflow: "auto",
                padding: "1rem",
                margin: "1rem",
                paddingBottom: "2rem",
              }}
            >
              <Stack spacing={2}>
                {getFieldValue("frequency") !== "import" ? (
                  <Stack spacing={1}>
                    {!calendar || option === "series" ? (
                      <OFDRecurrence
                        value={recurrence}
                        onChange={handleRecurrenceChange}
                        disabled={calendar ? true : false}
                        selectedDate={selectedDate}
                        fieldErrors={fieldErrors}
                      />
                    ) : null}

                    {getFieldValue("frequency") === "onetime" ||
                    option === "occurrence" ? (
                      <OFDDateTimeRange
                        id="startDate"
                        label="startDate"
                        value={[
                          getFieldValue("startDate"),
                          getFieldValue("endDate"),
                          getFieldValue("allDay"),
                        ]}
                        onChange={handleDateTimeRange}
                        required={true}
                        message={getFieldMessage("startDate")}
                        error={getFieldError("startDate")}
                      />
                    ) : (
                      <OFDTimeRange
                        id="timeRange"
                        value={timeRange}
                        onChange={handleTimeRangeChange}
                      />
                    )}

                    <OFDTextNote
                      id="name"
                      label="subject"
                      value={getFieldValue("name")}
                      onChange={(newValue) => setFieldValue("name", newValue)}
                      required={true}
                      message={getFieldMessage("name")}
                      error={getFieldError("name")}
                      fullWidth
                    />
                    <OFDTextNote
                      id="description"
                      label="description"
                      value={getFieldValue("description")}
                      onChange={handleDescriptionChange}
                    />
                    <OFDDisplayReminders
                      id="reminders"
                      label="reminders"
                      value={getFieldValue("reminders")}
                      onClick={handleRemindersEditor}
                    />
                    <OFDDisplayCategory
                      label="category"
                      value={getFieldValue("category")}
                      onClick={handleCategorySelector}
                    />
                    <OFDDisplayAttendees
                      label="attendees"
                      value={
                        getFieldValue("attendees") || [
                          { entityName: "Member", id: member.id },
                        ]
                      }
                      onClick={handleAttendeeEditor}
                    />
                    <OFDDisplayAddress
                      label="address"
                      value={getFieldValue("address")}
                      onClick={handleAddressEditor}
                    />
                  </Stack>
                ) : (
                  <Box>
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {getText("iCalImport")}
                    </Typography>

                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Tabs
                          value={tabIndex}
                          onChange={handleTabChange}
                          sx={tabStyle}
                        >
                          <Tab label={getLabel("uploadFile")} />
                          <Tab label={getLabel("externalUrl")} />
                        </Tabs>
                      </Box>

                      <Box sx={{ padding: ".5rem" }}>
                        <OFDTabPanel
                          value={0}
                          index={tabIndex}
                        >
                          <Stack spacing={1}>
                            <OFDFileUploader onUpload={handleFileUpload} />

                            <Typography>{importFilename}</Typography>

                            <OFDDisplayCategory
                              label="category"
                              value={getFieldValue("category")}
                              onClick={handleCategorySelector}
                            />

                            <OFDDisplayAttendees
                              label="attendees"
                              value={getFieldValue("attendees")}
                              onClick={handleAttendeeEditor}
                            />
                          </Stack>
                        </OFDTabPanel>

                        <OFDTabPanel
                          value={1}
                          index={tabIndex}
                        >
                          <Stack spacing={1}>
                            <OFDTextField
                              id="iCalLink"
                              label="externalUrl"
                              value={getFieldValue("iCalLink")}
                              onChange={(newValue) =>
                                setFieldValue("iCalLink", newValue)
                              }
                              message={getFieldMessage("iCalLink")}
                              error={getFieldError("iCalLink")}
                            />

                            {!iCalLinkVerified ? (
                              <OFDButton
                                id="textiCalLink"
                                label="testLink"
                                variant="contained"
                                onClick={handleTestICalLink}
                              />
                            ) : (
                              <Stack spacing={2}>
                                <Box
                                  sx={{
                                    backgroundColor: "#f0f0f0",
                                    borderRadius: "10px",
                                    padding: "1rem",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontWeight: "bold" }}
                                    align="center"
                                  >
                                    {getFieldValue("iCalName")}
                                  </Typography>

                                  <Typography align="center">
                                    {`${
                                      getFieldValue("iCalDates")?.startDate
                                        ? format(
                                            getFieldValue("iCalDates")
                                              .startDate,
                                            "MMM dd, yyyy"
                                          )
                                        : ""
                                    } to ${
                                      getFieldValue("iCalDates")?.endDate
                                        ? format(
                                            getFieldValue("iCalDates").endDate,
                                            "MMM dd, yyyy"
                                          )
                                        : ""
                                    }`}
                                  </Typography>

                                  <Typography align="center">{`${getFieldValue(
                                    "iCalEventCount"
                                  )} events`}</Typography>
                                </Box>

                                <OFDDisplayCategory
                                  label="category"
                                  value={getFieldValue("category")}
                                  onClick={handleCategorySelector}
                                />

                                <OFDDisplayAttendees
                                  label="attendees"
                                  value={getFieldValue("attendees")}
                                  onClick={handleAttendeeEditor}
                                />
                              </Stack>
                            )}

                            {iCalError ? (
                              <Typography>{getMessage(iCalMessage)}</Typography>
                            ) : null}
                          </Stack>
                        </OFDTabPanel>
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    paddingLeft: ".5rem",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: ".5rem",
                  }}
                >
                  <Box sx={{ paddingBottom: ".5rem" }}>
                    <Typography variant="overline">
                      {getLabel("security")}
                    </Typography>
                  </Box>

                  <OFDSecurity
                    value={
                      getFieldValue("security") || {
                        ...pageDefaultSecurity("Calendar"),
                      }
                    }
                    onChange={(newValue) => setFieldValue("security", newValue)}
                    show={{
                      view: true,
                      add: false,
                      update: true,
                      delete: true,
                    }}
                  />
                </Box>
              </Stack>
            </OFDFieldContainer>
          </Box>
        </Box>
      </OFDModal>

      <OFDMessage
        message={formMessage}
        onClose={resetFormMessage}
      />

      <FieldEditorModal
        entityName="Appointment"
        entityData={data}
        field={{
          id: "attendees",
          label: "attendees",
          icon: "members",
          fieldType: "attendees",
        }}
        open={attendeeEditor}
        onCancel={handleCancelAttendees}
        onChange={handleChangeAttendees}
        returnValue
      />

      <FieldEditorModal
        entityName="Appointment"
        entityData={data}
        field={{
          id: "category",
          label: "category",
          icon: "category",
          fieldType: "category",
        }}
        open={categorySelector}
        onCancel={handleCancelCategory}
        onChange={handleChangeCategory}
        returnValue
      />

      <FieldEditorModal
        entityName="Appointment"
        entityData={data}
        field={{
          id: "address",
          label: "address",
          icon: "address",
          fieldType: "address",
        }}
        open={addressEditor}
        onCancel={handleCancelAddress}
        onChange={handleChangeAddress}
        returnValue
      />

      <FieldEditorModal
        entityName="Appointment"
        entityData={data}
        field={{
          id: "reminders",
          label: "reminders",
          icon: "reminders",
          fieldType: "reminders",
        }}
        open={remindersEditor}
        onCancel={handleCancelReminders}
        onChange={handleChangeReminders}
        returnValue
      />

      <OFDDialog
        open={openSaveDialog}
        title="confirmSaveSeries"
        description="confirmSaveSeries"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={saveAppointment}
      />

      <LoadingModal open={openLoading} />
    </>
  );
};

export default AppointmentEditor;
