import { useEffect } from "react";
import { format } from "date-fns";

import useCalendar from "../../hooks/useCalendar";
import usePage from "../../hooks/usePage";

import { Box, Typography } from "@mui/material";

import OFDButton from "../ui/OFDButton";
import OFDIcon from "../ui/OFDIcon";

const ChildToDo = ({ todo }) => {
  const { completeToDo } = useCalendar();
  const { refreshPage } = usePage();

  const handleDone = async () => {
    await completeToDo(todo, true);
    refreshPage();
  };

  return (
    <Box
      sx={{
        padding: ".5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ccc",
        borderTop: "1px solid #ccc",
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: 700 }}>{todo.name}</Typography>

        {todo.description ? (
          <Typography>
            <Typography
              ml=".5rem"
              variant="caption"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {todo.description}
            </Typography>
          </Typography>
        ) : null}
      </Box>

      <Box>
        {todo.todoComplete ? (
          <Box>
            <OFDIcon
              name="checkCircle"
              size="medium"
            />
          </Box>
        ) : (
          <OFDButton
            id="done"
            label="done"
            onClick={handleDone}
            variant="outlined"
          />
        )}
      </Box>
    </Box>
  );
};

export default ChildToDo;
