import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

import OFDDisplayNote from "./OFDDisplayNote";

const OFDDisplayIngredients = ({
  id,
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick(id);
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      id={id}
      sx={displayStyle}
      onClick={handleClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>

      <Box>
        <Stack spacing={1}>
          {value?.map((item) => (
            <Box key={item.id}>
              <Box
                sx={{ display: "flex", borderBottom: "1px solid #ccc" }}
                gap={1}
              >
                <Box>
                  <Typography>{`${item.name}`}</Typography>
                </Box>
                <Box>
                  {item.quantity ? (
                    <Typography noWrap>{item.quantity}</Typography>
                  ) : null}
                </Box>
              </Box>
              {item.note ? (
                <OFDDisplayNote
                  value={item.note}
                  textOnly={true}
                  styleoverride={{ fontSize: "12px", color: "#535353" }}
                />
              ) : null}
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayIngredients;
