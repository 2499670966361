import { TrendingUp } from "@mui/icons-material";
import { v4 as uuidV4 } from "uuid";

const Configuration = {
  entity: {
    Account: {
      title: "accounts",
      icon: "account",
      allowFolders: false,
      fields: [],
      toolbar: [],
      itemMenu: false,
      defaultValues: {},
      defaultSecurity: {
        view: { securityLevel: "admin" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "admin" },
        delete: { securityLevel: "admin" },
      },
    },

    Appointment: {
      title: "appointment",
      icon: "appointment",
      fields: [],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "admin" },
        delete: { securityLevel: "admin" },
      },
      availableFields: [],
      allowFolders: true,
      toolbar: [],
      itemMenu: [
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
      ],
      defaultValues: {
        name: "",
        frequency: "onetime",
        startDate: null,
        endDate: null,
        monthlyType: "specific",
        allDay: false,
        description: "",
        address: null,
        attendees: [],
        category: "appointment",
        reminders: [],
        folders: [],
      },
    },

    Budget: {
      title: "budgets",
      icon: "budget",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "category",
          label: "category",
          fieldType: "category",
        },
        {
          id: "budgetItems",
          label: "budgetItems",
          fieldType: "budgetItems",
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "admin" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "admin" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: false,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      toolbar: [
        {
          name: "addBudget",
          label: "budget",
          icon: "addBudget",
          action: "add",
          page: "budgets",
        },
      ],
      itemMenu: [
        {
          name: "reminder",
          label: "reminder",
          icon: "reminder",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        category: "",
        budgetItems: [],
        folders: [],
      },
    },

    Calendar: {
      title: "calendar",
      icon: "calendar",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
        },
        {
          id: "startDate",
          label: "date",
          fieldType: "date",
        },
        {
          id: "startDate",
          label: "from",
          fieldType: "time",
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "admin" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [],
      toolbar: [
        {
          name: "monthly",
          label: "monthly",
          icon: "calendarMonth",
          action: "view",
          page: "calendar",
        },
        {
          name: "weekly",
          label: "weekly",
          icon: "calendarWeek",
          action: "view",
          page: "calendar",
        },
        {
          name: "addCalendar",
          label: "calendar",
          icon: "addCalendar",
          action: "add",
          page: "calendar",
        },
        {
          name: "addReminder",
          label: "reminder",
          icon: "addreminder",
          action: "add",
          page: "reminders",
        },
        {
          name: "addToDo",
          label: "todo",
          icon: "addtodo",
          action: "add",
          page: "todos",
        },
        {
          name: "addOccasion",
          label: "occasion",
          icon: "addoccasion",
          action: "add",
        },
        {
          name: "importCalendar",
          label: "import",
          icon: "upload",
          action: "add",
          page: "calendar",
        },
      ],
      itemMenu: [
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
      ],
      defaultValues: {
        familyId: null,
        name: "",
        startDate: null,
        endDate: null,
        description: "",
        address: null,
        attendees: [],
        category: "appointment",
        reminders: [],
        folders: [],
      },
    },

    Chore: {
      title: "chores",
      icon: "chores",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
        },
        {
          id: "description",
          label: "description",
          fieldType: "textNote",
          fullWidth: true,
        },
        {
          id: "frequency",
          label: "frequency",
          fieldType: "select",
          listName: "choreFrequency",
          required: true,
        },
        {
          id: "tokenValue",
          label: "tokenValue",
          fieldType: "number",
        },
        {
          id: "responsible",
          label: "responsible",
          fieldType: "memberPicker",
          required: true,
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "admin" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: false,
          editLabel: true,
          enterValue: false,
        },
      ],
      toolbar: [
        {
          name: "addChore",
          label: "chore",
          icon: "addChore",
          action: "add",
          page: "chores",
        },
        {
          name: "tokens",
          label: "tokens",
          icon: "savings",
          action: "update",
          page: "chores",
        },
      ],
      itemMenu: [
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        note: "",
        avatar: null,
        color: "",
        folders: [],
      },
    },

    Contact: {
      title: "contact",
      icon: "contact",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "avatar",
          label: "avatar",
          fieldType: "avatar",
        },
        {
          id: "email",
          label: "email",
          fieldType: "email",
          fullWidth: true,
        },
        {
          id: "phone",
          label: "phone",
          fieldType: "phone",
        },
        {
          id: "address",
          label: "address",
          fieldType: "address",
          search: true,
        },
        {
          id: "group",
          label: "group",
          fieldType: "textField",
          fullWidth: true,
          search: true,
        },
        {
          id: "note",
          label: "note",
          fieldType: "noteEditor",
          fullWidth: true,
          search: true,
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "family" },
        update: { securityLevel: "family" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "address",
          fieldType: "address",
          icon: "address",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "phone",
          fieldType: "phone",
          icon: "phone",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "email",
          fieldType: "email",
          icon: "email",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "url",
          fieldType: "url",
          icon: "url",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "currency",
          fieldType: "currency",
          icon: "currency",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "number",
          fieldType: "number",
          icon: "number",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "date",
          fieldType: "date",
          icon: "date",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "audio",
          fieldType: "audio",
          icon: "audio",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      toolbar: [
        {
          name: "addContact",
          label: "contact",
          icon: "addContact",
          action: "add",
          page: "contacts",
        },
        {
          name: "search",
          label: "search",
          icon: "search",
          action: "view",
          page: "contacts",
        },
        {
          name: "import",
          label: "import",
          icon: "upload",
          action: "add",
          page: "contacts",
        },
      ],
      itemMenu: [
        {
          name: "reminder",
          label: "reminder",
          icon: "reminder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "upload",
          label: "document",
          icon: "upload",
        },
        {
          name: "occasions",
          label: "occasions",
          icon: "celebration",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        note: "",
        avatar: null,
        color: "",
        folders: [],
      },
    },

    Document: {
      title: "document",
      icon: "document",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "description",
          label: "description",
          fieldType: "textNote",
          fullWidth: true,
          search: true,
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "adult" },
      },
      allowFolders: false,
      availableFields: [],
      toolbar: [
        {
          name: "uploadDocument",
          label: "document",
          icon: "upload",
          action: "add",
          page: "documents",
        },
        {
          name: "addFolder",
          label: "folder",
          icon: "newFolder",
          action: "update",
          page: "documents",
        },
      ],
      itemMenu: false,
      defaultValues: {
        name: "",
        description: null,
        folderId: null,
        source: null,
        filename: "",
        mimeType: null,
        fileExtension: null,
        key: null,
        fileSize: 0,
      },
    },

    Expense: {
      title: "expenses",
      icon: "expense",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "description",
          label: "description",
          fieldType: "textNote",
          search: true,
        },
        {
          id: "company",
          label: "company",
          fieldType: "textNote",
          search: true,
        },
        {
          id: "expenseAmount",
          label: "expenseAmount",
          fieldType: "number",
          search: true,
        },
        {
          id: "expenseDate",
          label: "expenseDate",
          fieldType: "date",
          search: true,
        },
        {
          id: "budgetItem",
          label: "budgetItem",
          fieldType: "budgetItem",
          search: true,
        },
        {
          id: "category",
          label: "category",
          fieldType: "category",
        },
        {
          id: "tags",
          label: "tags",
          fieldType: "textField",
          search: true,
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "admin" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "admin" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [],
      toolbar: [
        {
          name: "addExpense",
          label: "expense",
          icon: "addExpense",
          action: "add",
          page: "expenses",
        },
        {
          name: "addIncome",
          label: "income",
          icon: "income",
          action: "add",
          page: "expenses",
        },
        {
          name: "recurring",
          label: "recurring",
          icon: "recurringExpense",
          action: "add",
          page: "expenses",
        },
        {
          name: "byBudget",
          label: "byBudget",
          icon: "budget",
          action: "view",
          page: "expenses",
        },
        {
          name: "byMonth",
          label: "byMonth",
          icon: "calendar",
          action: "view",
          page: "expenses",
        },
        {
          name: "byCompany",
          label: "byCompany",
          icon: "retailer",
          action: "view",
          page: "expenses",
        },
        {
          name: "search",
          label: "search",
          icon: "search",
          action: "view",
          page: "expenses",
        },
      ],
      itemMenu: [
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
      ],
      defaultValues: {
        id: null,
        frequency: "onetime",
        name: "",
        description: "",
        company: "",
        category: "expense",
        tags: "",
        expenseAmount: "",
        budgetItem: null,
        folders: [],
      },
    },

    Family: {
      title: "family",
      icon: "family",
      fields: [],
      defaultSecurity: {
        view: { securityLevel: "admin" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "member" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: false,
      availableFields: [],
      toolbar: [],
      itemMenu: [],
      defaultValues: {
        id: null,
        name: "",
        avatar: null,
        color: "",
        folders: [],
      },
    },

    Grocery: {
      title: "grocery",
      icon: "grocery",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "category",
          label: "category",
          fieldType: "category",
        },
        {
          id: "budgetItem",
          label: "budgetItem",
          fieldType: "budgetItem",
        },
        {
          id: "shoppingDate",
          label: "shoppingDate",
          fieldType: "date",
          search: true,
        },
        {
          id: "groceryItems",
          label: "groceryItems",
          fieldType: "groceryItems",
          search: true,
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "adult" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [],
      toolbar: [
        {
          name: "add",
          label: "grocery",
          icon: "addGrocery",
          action: "add",
          page: "groceries",
        },
        {
          name: "search",
          label: "search",
          icon: "search",
          action: "view",
          page: "groceries",
        },
      ],
      itemMenu: [
        {
          name: "shop",
          label: "shop",
          icon: "grocery",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        category: "",
        groceryItems: [],
      },
    },

    Home: {
      title: "home",
      icon: "home",
      fields: [],
      toolbar: [
        {
          name: "addCalendar",
          label: "calendar",
          icon: "addCalendar",
          action: "add",
          page: "calendar",
        },
        {
          name: "addReminder",
          label: "reminder",
          icon: "addreminder",
          action: "add",
          page: "reminders",
        },
        {
          name: "addToDo",
          label: "todo",
          icon: "addtodo",
          action: "add",
          page: "todos",
        },
      ],
      itemMenu: false,
      defaultValues: {},
      allowFolders: false,
    },

    Homework: {
      title: "homework",
      icon: "homework",
      fields: [
        {
          id: "memberId",
          label: "familyMember",
          fieldType: "member",
          required: true,
        },
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "description",
          label: "description",
          fieldType: "textNote",
          fullWidth: true,
        },
        {
          id: "homeworkType",
          label: "homeworkType",
          fieldType: "select",
          listName: "homeworkType",
          fullWidth: true,
          required: true,
        },
        {
          id: "dueDate",
          label: "dueDate",
          fieldType: "date",
          fullWidth: true,
          required: true,
        },
        {
          id: "notes",
          label: "notes",
          fieldType: "noteEditor",
          fullWidth: true,
        },
        {
          id: "submittedOn",
          label: "submittedOn",
          fieldType: "date",
          fullWidth: true,
        },
        {
          id: "course",
          label: "course",
          fieldType: "textField",
          fullWidth: true,
        },
        {
          id: "teacher",
          label: "teacher",
          fieldType: "textField",
          fullWidth: true,
        },
        {
          id: "percentOfFinal",
          label: "percentOfFinal",
          fieldType: "number",
          fullWidth: true,
        },
        {
          id: "markReceived",
          label: "markReceived",
          fieldType: "textField",
          fullWidth: true,
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "family" },
        update: { securityLevel: "family" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "address",
          fieldType: "address",
          icon: "address",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "phone",
          fieldType: "phone",
          icon: "phone",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "email",
          fieldType: "email",
          icon: "email",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "url",
          fieldType: "url",
          icon: "url",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "currency",
          fieldType: "currency",
          icon: "currency",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "number",
          fieldType: "number",
          icon: "number",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "date",
          fieldType: "date",
          icon: "date",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "audio",
          fieldType: "audio",
          icon: "audio",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      toolbar: [
        {
          name: "addHomework",
          label: "homework",
          icon: "addHomework",
          action: "add",
          page: "homework",
        },
        {
          name: "search",
          label: "search",
          icon: "search",
          action: "view",
          page: "homework",
        },
      ],
      itemMenu: [
        {
          name: "reminder",
          label: "reminder",
          icon: "reminder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "upload",
          label: "document",
          icon: "upload",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        note: "",
        avatar: null,
        color: "",
        folders: [],
      },
    },

    MealPlan: {
      title: "mealPlan",
      icon: "mealPlan",
      fields: [
        {
          id: "weekOf",
          label: "weekOf",
          fieldType: "weekOf",
          required: true,
        },
        {
          id: "dailyPlans",
          label: "dailyPlans",
          fieldType: "dailyPlans",
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [],
      toolbar: [
        {
          name: "addMealPlan",
          label: "mealPlan",
          icon: "addMealPlan",
          action: "add",
          page: "mealplans",
        },
      ],
      itemMenu: [
        {
          name: "open",
          label: "open",
          icon: "recipe",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
      ],
      defaultValues: {
        id: null,
        weekOf: null,
        dailyPlans: null,
      },
    },

    Member: {
      title: "member",
      icon: "family",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
        },
        {
          id: "avatar",
          label: "avatar",
          fieldType: "avatar",
        },
        {
          id: "color",
          label: "color",
          fieldType: "color",
        },
        {
          id: "email",
          label: "email",
          fieldType: "email",
          fullWidth: true,
        },
        {
          id: "phone",
          label: "phone",
          fieldType: "phone",
        },
        {
          id: "userAccount",
          label: "userAccount",
          fieldType: "userAccount",
          security: {
            view: { securityLevel: "admin" },
            update: { securityLevel: "admin" },
          },
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "member" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "address",
          fieldType: "address",
          icon: "address",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "phone",
          fieldType: "phone",
          icon: "phone",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "email",
          fieldType: "email",
          icon: "email",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "url",
          fieldType: "url",
          icon: "url",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "currency",
          fieldType: "currency",
          icon: "currency",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "number",
          fieldType: "number",
          icon: "number",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "date",
          fieldType: "date",
          icon: "date",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "audio",
          fieldType: "audio",
          icon: "audio",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "bloodPressure",
          fieldType: "bloodPressure",
          icon: "bloodpressure",
          multiple: false,
          editLabel: false,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "allergies",
          fieldType: "allergies",
          icon: "allergies",
          multiple: false,
          editLabel: false,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      toolbar: [
        {
          name: "addFamily",
          label: "familyMember",
          icon: "addfamily",
          action: "add",
          page: "members",
        },
      ],
      itemMenu: [
        {
          name: "reminder",
          label: "reminder",
          icon: "reminder",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "upload",
          label: "document",
          icon: "upload",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
          securityLevel: "accountOwner",
        },
        {
          name: "occasions",
          label: "occasions",
          icon: "celebration",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        avatar: null,
        color: "",
        folders: [],
      },
    },

    MyAccount: {
      title: "myAccount",
      icon: "settings",
      fields: [],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "member" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: false,
      availableFields: [],
      toolbar: [
        {
          name: "save",
          label: "save",
          icon: "save",
        },
      ],
      itemMenu: false,
      defaultValues: {},
    },

    MyPreferences: {
      title: "myPreferences",
      icon: "settings",
      fields: [],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "admin" },
        update: { securityLevel: "member" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: false,
      availableFields: [],
      toolbar: [
        {
          name: "save",
          label: "save",
          icon: "save",
        },
      ],
      itemMenu: false,
      defaultValues: {},
    },

    Note: {
      title: "note",
      icon: "note",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "group",
          label: "group",
          fieldType: "textField",
          fullWidth: true,
          search: true,
        },
        {
          id: "note",
          label: "notes",
          fieldType: "noteEditor",
          search: true,
        },
        {
          id: "category",
          label: "category",
          fieldType: "category",
          mode: "display",
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "family" },
        update: { securityLevel: "family" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "address",
          fieldType: "address",
          icon: "address",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "phone",
          fieldType: "phone",
          icon: "phone",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "email",
          fieldType: "email",
          icon: "email",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "url",
          fieldType: "url",
          icon: "url",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "currency",
          fieldType: "currency",
          icon: "currency",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "number",
          fieldType: "number",
          icon: "number",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "date",
          fieldType: "date",
          icon: "date",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "audio",
          fieldType: "audio",
          icon: "audio",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      toolbar: [
        {
          name: "addNote",
          label: "note",
          icon: "addNote",
          action: "add",
          page: "notes",
        },
        {
          name: "search",
          label: "search",
          icon: "search",
          action: "view",
          page: "notes",
        },
      ],
      itemMenu: [
        {
          name: "reminder",
          label: "reminder",
          icon: "reminder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        note: "",
        avatar: null,
        color: "",
        folders: [],
      },
    },

    Password: {
      title: "password",
      icon: "password",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
        },
        {
          id: "avatar",
          label: "avatar",
          fieldType: "avatar",
        },
        {
          id: "url",
          label: "url",
          fieldType: "url",
        },
        {
          id: "username",
          label: "username",
          fieldType: "textField",
          copyToClipboard: true,
        },
        {
          id: "password",
          label: "password",
          fieldType: "password",
          copyToClipboard: true,
          allowShow: true,
        },
        {
          id: "note",
          label: "note",
          fieldType: "textNote",
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "family" },
        update: { securityLevel: "family" },
        delete: { securityLevel: "admin" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: false,
          editLabel: true,
          enterValue: false,
        },
      ],
      toolbar: [
        {
          name: "addPassword",
          label: "password",
          icon: "addPassword",
          action: "add",
          page: "passwords",
        },
      ],
      itemMenu: [
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        url: "",
        username: "",
        password: "",
        folders: [],
      },
    },

    Recipe: {
      title: "recipe",
      icon: "recipe",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "image",
          label: "image",
          fieldType: "picture",
        },
        {
          id: "mealType",
          label: "mealType",
          fieldType: "multiSelect",
          listName: "mealTypes",
          search: true,
        },
        {
          id: "description",
          label: "description",
          fieldType: "textNote",
          fullWidth: true,
          search: true,
        },
        {
          id: "externalUrl",
          label: "externalUrl",
          fieldType: "url",
        },
        {
          id: "servings",
          label: "servings",
          fieldType: "textField",
        },
        {
          id: "preparationTime",
          label: "preparationTime",
          fieldType: "textField",
        },
        {
          id: "cookTime",
          label: "cookTime",
          fieldType: "textField",
        },
        {
          id: "category",
          label: "category",
          fieldType: "category",
        },
        {
          id: "ingredients",
          label: "ingredients",
          fieldType: "ingredients",
          search: true,
        },
        {
          id: "instructions",
          label: "instructions",
          fieldType: "instructions",
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "adult" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "adult" },
      },
      allowFolders: true,
      availableFields: [],
      toolbar: [
        {
          name: "addRecipe",
          label: "recipe",
          icon: "addRecipe",
          action: "add",
          page: "recipes",
        },
        {
          name: "search",
          label: "search",
          icon: "search",
          action: "view",
          page: "recipes",
        },
      ],
      itemMenu: [
        {
          name: "open",
          label: "open",
          icon: "recipe",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        category: "",
        groceryItems: [],
      },
    },

    Reminder: {
      title: "reminder",
      icon: "reminder",
      fields: [],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "adult" },
      },
      allowFolders: false,
      availableFields: [],
      toolbar: [
        {
          name: "addReminder",
          label: "reminder",
          icon: "addreminder",
          action: "add",
          page: "reminders",
        },
      ],
      defaultValues: {
        familyId: null,
        name: "",
        startDate: null,
        endDate: null,
        description: "",
        address: null,
        attendees: [],
        category: "appointment",
        reminders: [],
        folders: [],
      },
    },

    Subscription: {
      title: "subscription",
      icon: "subscription",
      expense: true,
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
        },
        {
          id: "avatar",
          label: "avatar",
          fieldType: "avatar",
        },
        {
          id: "url",
          label: "url",
          fieldType: "url",
        },
        {
          id: "frequency",
          label: "frequency",
          fieldType: "select",
          listName: "subscriptionFrequency",
        },
        {
          id: "startDate",
          label: "from",
          fieldType: "date",
        },
        {
          id: "cost",
          label: "cost",
          fieldType: "currency",
        },
        {
          id: "budgetItem",
          label: "budgetItem",
          fieldType: "budgetItem",
        },
        {
          id: "cardType",
          label: "cardType",
          fieldType: "textField",
        },
        {
          id: "cardDescription",
          label: "cardDescription",
          fieldType: "textField",
        },
        {
          id: "note",
          label: "note",
          fieldType: "noteEditor",
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "adult" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "address",
          fieldType: "address",
          icon: "address",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "phone",
          fieldType: "phone",
          icon: "phone",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "email",
          fieldType: "email",
          icon: "email",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "url",
          fieldType: "url",
          icon: "url",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "currency",
          fieldType: "currency",
          icon: "currency",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "number",
          fieldType: "number",
          icon: "number",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "date",
          fieldType: "date",
          icon: "date",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "audio",
          fieldType: "audio",
          icon: "audio",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      toolbar: [
        {
          name: "addSubscription",
          label: "subscription",
          icon: "addSubscription",
          action: "add",
          page: "subscriptions",
        },
      ],
      itemMenu: [
        {
          name: "reminder",
          label: "reminder",
          icon: "reminder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
        {
          name: "expenses",
          label: "expenses",
          icon: "expense",
        },
        {
          name: "cancel",
          label: "cancel",
          icon: "cancel",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        avatar: "",
        url: "",
        startDate: null,
        endDate: null,
        cost: "",
        frequency: "",
        cardType: "",
        cardDescription: "",
        cancelled: false,
        cancelledAt: null,
        note: "",
        folders: [],
      },
    },

    ToDo: {
      title: "todos",
      icon: "todo",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
          search: true,
        },
        {
          id: "startDate",
          label: "dueDate",
          fieldType: "date",
          required: true,
          search: true,
        },
        {
          id: "isComplete",
          label: "complete",
          fieldType: "switch",
          search: true,
        },
        {
          id: "description",
          label: "description",
          fieldType: "textNote",
          fullWidth: true,
          search: true,
        },
        {
          id: "category",
          label: "category",
          fieldType: "category",
          mode: "display",
          search: true,
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "adult" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "address",
          fieldType: "address",
          icon: "address",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "phone",
          fieldType: "phone",
          icon: "phone",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "email",
          fieldType: "email",
          icon: "email",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "url",
          fieldType: "url",
          icon: "url",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "currency",
          fieldType: "currency",
          icon: "currency",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "number",
          fieldType: "number",
          icon: "number",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "date",
          fieldType: "date",
          icon: "date",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "audio",
          fieldType: "audio",
          icon: "audio",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      itemMenu: [
        {
          name: "edit",
          label: "edit",
          icon: "edit",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "upload",
          label: "document",
          icon: "upload",
        },
        {
          name: "expenses",
          label: "expenses",
          icon: "expenses",
        },
      ],
      toolbar: [
        {
          name: "addToDo",
          label: "todo",
          icon: "addtodo",
          action: "add",
          page: "todos",
        },
        {
          name: "search",
          label: "search",
          icon: "search",
          action: "view",
          page: "contacts",
        },
      ],
      defaultValues: {
        category: "todo",
      },
    },

    Trip: {
      title: "trip",
      icon: "trip",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
        },
        {
          id: "destination",
          label: "destination",
          fieldType: "textField",
          fullWidth: true,
        },
        {
          id: "fromDate",
          label: "from",
          fieldType: "date",
        },
        {
          id: "toDate",
          label: "to",
          fieldType: "date",
        },
        {
          id: "attendees",
          label: "familyMembers",
          fieldType: "memberPicker",
        },
        {
          id: "avatar",
          label: "avatar",
          fieldType: "avatar",
        },
        {
          id: "itinerary",
          label: "destinations",
          fieldType: "itinerary",
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "adult" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "address",
          fieldType: "address",
          icon: "address",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "phone",
          fieldType: "phone",
          icon: "phone",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "email",
          fieldType: "email",
          icon: "email",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "url",
          fieldType: "url",
          icon: "url",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "currency",
          fieldType: "currency",
          icon: "currency",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "number",
          fieldType: "number",
          icon: "number",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "date",
          fieldType: "date",
          icon: "date",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "audio",
          fieldType: "audio",
          icon: "audio",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      toolbar: [
        {
          name: "addTrip",
          label: "trip",
          icon: "addTrip",
          action: "add",
          page: "trips",
        },
      ],
      itemMenu: [
        {
          name: "reminder",
          label: "reminder",
          icon: "reminder",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "upload",
          label: "document",
          icon: "upload",
        },
        {
          name: "expenses",
          label: "expenses",
          icon: "expenses",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        note: "",
        avatar: null,
        color: "",
        folders: [],
      },
    },

    Vehicle: {
      title: "vehicle",
      icon: "vehicle",
      fields: [
        {
          id: "name",
          label: "name",
          fieldType: "textField",
          required: true,
          fullWidth: true,
        },
        {
          id: "plateNumber",
          label: "plateNumber",
          fieldType: "textField",
        },
        {
          id: "vinNumber",
          label: "vinNumber",
          fieldType: "textField",
        },
        {
          id: "avatar",
          label: "avatar",
          fieldType: "avatar",
        },
        {
          id: "serviceLog",
          label: "serviceLog",
          fieldType: "serviceLog",
          mode: "hide",
        },
        {
          id: "mileageLog",
          label: "mileageLog",
          fieldType: "mileageLog",
          mode: "hide",
        },
        {
          id: "gasLog",
          label: "gasLog",
          fieldType: "gasLog",
          mode: "hide",
        },
        {
          id: "note",
          label: "note",
          fieldType: "noteEditor",
          fullWidth: true,
        },
        {
          id: "security",
          label: "security",
          fieldType: "recordSecurity",
        },
      ],
      defaultSecurity: {
        view: { securityLevel: "family" },
        add: { securityLevel: "adult" },
        update: { securityLevel: "adult" },
        delete: { securityLevel: "adult" },
      },
      allowFolders: true,
      availableFields: [
        {
          id: uuidV4(),
          label: "note",
          fieldType: "textNote",
          icon: "note",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "list",
          fieldType: "list",
          icon: "list",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "picture",
          fieldType: "picture",
          icon: "image",
          multiple: true,
          editLabel: true,
          enterValue: false,
        },
        {
          id: uuidV4(),
          label: "address",
          fieldType: "address",
          icon: "address",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "phone",
          fieldType: "phone",
          icon: "phone",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "email",
          fieldType: "email",
          icon: "email",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "url",
          fieldType: "url",
          icon: "url",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "currency",
          fieldType: "currency",
          icon: "currency",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "number",
          fieldType: "number",
          icon: "number",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "date",
          fieldType: "date",
          icon: "date",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "documents",
          fieldType: "documents",
          icon: "documents",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
        {
          id: uuidV4(),
          label: "audio",
          fieldType: "audio",
          icon: "audio",
          multiple: true,
          editLabel: true,
          enterValue: true,
        },
      ],
      allowDocuments: true,
      toolbar: [
        {
          name: "addVehicle",
          label: "vehicle",
          icon: "addVehicle",
          action: "add",
          page: "vehicles",
        },
      ],
      itemMenu: [
        {
          name: "reminder",
          label: "reminder",
          icon: "reminder",
        },
        {
          name: "addFolder",
          label: "addFolder",
          icon: "newFolder",
        },
        {
          name: "upload",
          label: "document",
          icon: "upload",
        },
        {
          name: "security",
          label: "security",
          icon: "security",
        },
        {
          name: "delete",
          label: "delete",
          icon: "delete",
        },
        {
          name: "expenses",
          label: "expenses",
          icon: "expenses",
        },
      ],
      defaultValues: {
        id: null,
        name: "",
        note: "",
        avatar: null,
        color: "",

        folders: [],
      },
    },
  },
};

export default Configuration;
