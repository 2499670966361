import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDFieldGroup from "./OFDFieldGroup";
import OFDNumber from "./OFDNumber";
import OFDSelect from "./OFDSelect";
import OFDTextNote from "./OFDTextNote";

import OFDDialog from "../layout/OFDDialog";

const fieldsTemplate = {
  id: null,
  duration: "minutes",
  value: "",
  minutes: 0,
  note: null,
};

const OFDReminders = ({ id, label, value, onChange, source }) => {
  const { getLabel } = useLocalization();
  const { newId, sort } = useData();

  const [fields, setFields] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [fieldMessages, setFieldMessages] = useState({});

  useEffect(() => {
    initializeFields();
  }, []);

  const validFields = () => {
    let isValid = true;
    let messages = {};

    if (!fields || !fields.value) {
      messages.value = { error: true, message: "isEmpty" };
      isValid = false;
    }

    setFieldMessages(messages);
    return isValid;
  };

  const handleCancel = () => {
    setEditMode("add");
    initializeFields();
    setSelectedItem(null);
    setFieldMessages({});
  };

  const initializeFields = () => {
    setFields({ ...fieldsTemplate, id: newId() });
  };

  const handleAddItem = () => {
    setEditMode("add");

    if (!validFields()) return;

    let multiplier = 1;
    if (fields.duration === "hours") multiplier = 60;
    if (fields.duration === "days") multiplier = 1440;

    let minutes = fields.value ? fields.value * multiplier : 0;
    fields.minutes = minutes;

    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields }];
    } else {
      list = [{ ...fields }];
    }

    const sortedList = sort(list, "minutes", "desc");

    handleChange(sortedList);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
    setFieldMessages({});
  };

  const handleUpdateItem = (newFields = fields) => {
    if (!validFields()) return;

    let list = [];

    for (const item of value) {
      if (item.id !== selectedItem.id) {
        list.push({ ...item });
      } else {
        let multiplier = 1;
        if (newFields.duration === "hours") multiplier = 60;
        if (newFields.duration === "days") multiplier = 1440;

        let minutes = newFields.value ? newFields.value * multiplier : 0;
        newFields.minutes = minutes;

        list.push({ ...newFields });
      }
    }

    const sortedList = sort(list, "minutes", "desc");

    handleChange(sortedList);
  };

  const handleDeleteItem = () => {
    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const item of value) {
        if (item.id !== selectedItem.id) {
          list.push({ ...item });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const handleChange = (list) => {
    let sortedList = sort(list, "minutes", "desc");
    onChange(sortedList);
    setEditMode("add");
    setSelectedItem(null);
    initializeFields();
  };

  const getFieldMessage = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return null;

    return fieldMessages[field].message;
  };

  const getFieldError = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return false;

    return fieldMessages[field].error;
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  if (fields === null) return null;

  return (
    <>
      <Box id={id}>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {label}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          gap={1}
        >
          <Box>
            <OFDNumber
              id="value"
              label={`# ${getLabel(getFieldValue("duration"))}`}
              value={getFieldValue("value") || ""}
              onChange={(newValue) => updateFieldValue("value", newValue)}
              fullWidth
            />
          </Box>

          <Box sx={{ width: "100%" }}>
            <OFDSelect
              id="duration"
              label="duration"
              value={getFieldValue("duration") || "minutes"}
              onChange={(newValue) => updateFieldValue("duration", newValue)}
              fullWidth
              required
              error={getFieldError("duration")}
              message={getFieldMessage("duration")}
              listName="reminderDuration"
            />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDTextNote
            id="note"
            label="note"
            value={getFieldValue("note") || ""}
            onChange={(newValue) => updateFieldValue("note", newValue)}
            fullWidth
          />
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                selectedItem ? handleUpdateItem() : handleAddItem()
              }
            >
              {selectedItem ? "Update" : "Add"}
            </Button>
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          <Stack spacing={1}>
            {Array.isArray(value)
              ? value.map((item) => (
                  <Box key={item.id}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#ccc",
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      gap={1}
                      onClick={() => handleEditItem(item)}
                    >
                      <Typography>{`${item.value} ${item.duration}`}</Typography>
                    </Box>
                  </Box>
                ))
              : null}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />
    </>
  );
};

export default OFDReminders;
