import { useEffect, useState } from "react";
import { startOfToday } from "date-fns";

import useChore from "../../../../hooks/useChore";
import usePage from "../../../../hooks/usePage";

import { Box } from "@mui/material";

import OFDDayOf from "../../../ui/OFDDayOf";
import Confetti from "../../../display/Confetti";
import MemberChores from "./MemberChores";

const ChoresDashboard = ({ height }) => {
  const { getFamilyChores, familyChores, clearChores } = useChore();
  const { refresh } = usePage();

  const [showConfetti, setShowConfetti] = useState(false);
  const [dayOf, setDayOf] = useState(undefined);

  useEffect(() => {
    setDayOf(startOfToday());
    setShowConfetti(false);
    getData();
  }, []);

  const getData = async () => {
    clearChores();
    await getFamilyChores();
  };

  const handleChange = async (status) => {
    if (status === "approved") {
      setShowConfetti(true);
    }
    await getData();
  };

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [showConfetti]);

  if (dayOf === undefined) return;
  if (familyChores === undefined) return;

  return (
    <>
      <Box sx={{ height: height }}>
        <OFDDayOf
          value={dayOf}
          onChange={(newValue) => setDayOf(newValue)}
        />

        {familyChores &&
        Array.isArray(familyChores.activity) &&
        familyChores.activity.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              paddingTop: "1rem",
              overflowX: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            gap={2}
          >
            {familyChores.activity.map((activity) => (
              <MemberChores
                key={activity.id}
                activity={activity}
                choreList={familyChores.choreList}
                onChange={handleChange}
                height={height - 50}
                dayOf={dayOf}
              />
            ))}
          </Box>
        ) : null}
      </Box>

      {showConfetti ? <Confetti /> : null}
    </>
  );
};

export default ChoresDashboard;
