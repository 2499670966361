import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import OFDIcon from "./OFDIcon";

const OFDContextHelp = ({ text }) => {
  const { getText } = useLocalization();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Box
        aria-describedby={id}
        onClick={handleClick}
      >
        <OFDIcon name="question" />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2, whiteSpace: "pre-wrap" }}>
          {getText(text)}
        </Typography>
      </Popover>
    </div>
  );
};

export default OFDContextHelp;
