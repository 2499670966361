import { useEffect, useState } from "react";
import { format, parse } from "date-fns";

import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

import {
  Box,
  Paper,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import OFDAvatar from "../ui/OFDAvatar";
import OFDIcon from "../ui/OFDIcon";
import OFDFieldGroup from "../ui/OFDFieldGroup";
import OFDContainer from "../ui/OFDContainer";
import OFDTabPanel from "../layout/OFDTabPanel";
import TokenBanks from "./TokenBanks";

const ChoreMatrix = ({ familyChores, onChange }) => {
  const { getLabel, getText } = useLocalization();
  const { getMemberAvatar, memberColor } = useMember();

  const [choreMatrix, setChoreMatrix] = useState(null);
  const [activityIndex, setActivityIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const tabStyle = {
    "& .MuiTabs-indicator": {
      backgroundColor: memberColor().backgroundColor,
    },
    "& .MuiTab-root.Mui-selected": {
      color: memberColor().backgroundColor,
    },
  };

  useEffect(() => {
    buildChoreMatrix();
  }, [familyChores, activityIndex]);

  const buildChoreMatrix = () => {
    if (!familyChores) {
      setChoreMatrix(null);
      return;
    }

    let matrix = [];

    for (const chore of familyChores.choreList) {
      let activity = [];
      for (const member of familyChores.activity) {
        if (member.chores.hasOwnProperty(chore.id)) {
          activity.push({
            memberId: member.memberId,
            choreId: chore.id,
            ...member.chores[chore.id][activityIndex],
          });
        } else {
          activity.push({ memberId: member.memberId, choreId: chore.id });
        }
      }

      matrix.push({
        ...chore,
        activity: [...activity],
      });
    }

    setChoreMatrix(matrix);
  };

  const handleChoreStatus = (activity) => {
    if (activity.status === "outstanding" || activity.status === "completed") {
      onChange({ ...activity, status: "approved" });
    } else {
      onChange({ ...activity, status: "outstanding" });
    }
  };

  const handleNextActivity = () => {
    setActivityIndex((current) => {
      if (current === 0) return 0;

      return current - 1;
    });
  };

  const handlePrevActivity = () => {
    setActivityIndex((current) => {
      if (current === familyChores.activity.length - 1) return current;

      return current + 1;
    });
  };

  const handleTabChange = (e, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  if (!choreMatrix) return null;

  return (
    <>
      <OFDContainer>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={tabStyle}
          >
            <Tab label={getLabel("chores")} />
            <Tab label={getLabel("tokens")} />
          </Tabs>
        </Box>

        <OFDTabPanel
          value={0}
          index={tabIndex}
        >
          {Array.isArray(familyChores.choreList) &&
          familyChores.choreList.length > 0 ? (
            <Box>
              <OFDFieldGroup>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "50%",
                    padding: ".25rem",
                  }}
                >
                  <OFDIcon
                    name="arrowLeft"
                    label="prev"
                    color="grey"
                    onClick={handlePrevActivity}
                  />
                </Box>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "50%",
                    padding: ".25rem",
                  }}
                >
                  <OFDIcon
                    name="arrowRight"
                    label="next"
                    color="grey"
                    onClick={handleNextActivity}
                  />
                </Box>
              </OFDFieldGroup>

              <Box sx={{ marginTop: "3px" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "125px",
                            minWidth: "125px",
                            position: "sticky",
                            left: "0px",
                            backgroundColor: "#fff",
                          }}
                        >
                          {getLabel("chore")}
                        </TableCell>
                        {familyChores.activity.map((member) => (
                          <TableCell
                            key={member.memberId}
                            align="center"
                          >
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <OFDAvatar
                                id={member}
                                {...getMemberAvatar(member.memberId)}
                                size="medium"
                              />
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {choreMatrix.map((chore) => (
                        <TableRow key={chore.id}>
                          <TableCell
                            sx={{
                              width: "125px",
                              minWidth: "125px",
                              position: "sticky",
                              left: "0px",
                              backgroundColor: "#fff",
                              zIndex: 500,
                            }}
                          >
                            {chore.name}
                          </TableCell>
                          {chore.activity.map((activity) => (
                            <TableCell
                              key={`${chore.id}-${activity.memberId}`}
                              align="center"
                              onClick={(e) => handleChoreStatus(activity)}
                            >
                              {activity.status ? (
                                <OFDIcon name={`chores-${activity.status}`} />
                              ) : null}
                              {activity.date ? (
                                <Typography variant="caption">
                                  {format(
                                    parse(
                                      activity.date,
                                      "yyyyMMdd",
                                      new Date()
                                    ),
                                    "MMM dd"
                                  )}
                                </Typography>
                              ) : null}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          ) : (
            <Typography>{getText("tokenBank_noMembers")}</Typography>
          )}
        </OFDTabPanel>

        <OFDTabPanel
          value={1}
          index={tabIndex}
        >
          <TokenBanks onSave={onChange} />
        </OFDTabPanel>
      </OFDContainer>
    </>
  );
};

export default ChoreMatrix;
