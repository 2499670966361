import React from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDDisplayAddress from "./OFDDisplayAddress";
import OFDDisplayAllergies from "./OFDDisplayAllergies";
import OFDDisplayAttendees from "./OFDDisplayAttendees";
import OFDDisplayAudio from "./OFDDisplayAudio";
import OFDDisplayAvatar from "./OFDDisplayAvatar";
import OFDDisplayBloodPressure from "./OFDDisplayBloodPressure";
import OFDDisplayBudgetItem from "./OFDDisplayBudgetItem";
import OFDDisplayBudgetItems from "./OFDDisplayBudgetItems";
import OFDDisplayCategory from "./OFDDisplayCategory";
import OFDDisplayColor from "./OFDDisplayColor";
import OFDDisplayCurrency from "./OFDDisplayCurrency";
import OFDDisplayDate from "./OFDDisplayDate";
import OFDDisplayDateTimeRange from "./OFDDisplayDateTimeRange";
import OFDDisplayDateRange from "./OFDDisplayDateRange";
import OFDDisplayDocuments from "./OFDDisplayDocuments";
import OFDDisplayEmail from "./OFDDisplayEmail";
import OFDDisplayGallery from "./OFDDisplayGallery";
import OFDDisplayGasLog from "./OFDDisplayGasLog";
import OFDDisplayGroceryItems from "./OFDDisplayGroceryItems";
import OFDDisplayIngredients from "./OFDDisplayIngredients";
import OFDDisplayInstructions from "./OFDDisplayInstructions";
import OFDDisplayItinerary from "./OFDDisplayItinerary";
import OFDDisplayList from "./OFDDisplayList";
import OFDDisplayMember from "./OFDDisplayMember";
import OFDDisplayMembers from "./OFDDisplayMembers";
import OFDDisplayMileageLog from "./OFDDisplayMileageLog";
import OFDDisplayMultiSelect from "./OFDDisplayMultiSelect";
import OFDDisplayMealItems from "./OFDDisplayMealItems";
import OFDDisplayNoteEditor from "./OFDDisplayNoteEditor";
import OFDDisplayNumber from "./OFDDisplayNumber";
import OFDDisplayPassword from "./OFDDisplayPassword";
import OFDDisplayPhone from "./OFDDisplayPhone";
import OFDDisplayPicture from "./OFDDisplayPicture";
import OFDDisplayReminders from "./OFDDisplayReminders";
import OFDDisplaySelect from "./OFDDisplaySelect";
import OFDDisplayServiceLog from "./OFDDisplayServiceLog";
import OFDDisplaySwitch from "./OFDDisplaySwitch";
import OFDDisplayText from "./OFDDisplayText";
import OFDDisplayNote from "./OFDDisplayNote";
import OFDDisplayUrl from "./OFDDisplayUrl";
import OFDDisplayUserAccount from "./OFDDisplayUserAccount";

const OFDDisplayField = ({ fieldType, fieldProps, onClick }) => {
  const { getLabel } = useLocalization();

  const displayStyle = {
    width: "100%",
  };

  return (
    <>
      <Box sx={displayStyle}>
        {fieldType === "address" ? (
          <OFDDisplayAddress
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "allergies" ? (
          <OFDDisplayAllergies
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "attendees" ? (
          <OFDDisplayAttendees
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "audio" ? (
          <OFDDisplayAudio
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "avatar" ? (
          <OFDDisplayAvatar
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "bloodPressure" ? (
          <OFDDisplayBloodPressure
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "boolean" ? (
          <OFDDisplaySwitch
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "budgetItem" ? (
          <OFDDisplayBudgetItem
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "budgetItems" ? (
          <OFDDisplayBudgetItems
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "category" ? (
          <OFDDisplayCategory
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "color" ? (
          <OFDDisplayColor
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "currency" ? (
          <OFDDisplayCurrency
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "date" ? (
          <OFDDisplayDate
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "datetimeRange" ? (
          <OFDDisplayDateTimeRange
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "dateRange" ? (
          <OFDDisplayDateRange
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "documents" ? (
          <OFDDisplayDocuments
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "email" ? (
          <OFDDisplayEmail
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "gallery" ? (
          <OFDDisplayGallery
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "gasLog" ? (
          <OFDDisplayGasLog
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "groceryItems" ? (
          <OFDDisplayGroceryItems
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "ingredients" ? (
          <OFDDisplayIngredients
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "instructions" ? (
          <OFDDisplayInstructions
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "itinerary" ? (
          <OFDDisplayItinerary
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "list" ? (
          <OFDDisplayList
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "member" ? (
          <OFDDisplayMember
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "members" || fieldType === "memberPicker" ? (
          <OFDDisplayMembers
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "mileageLog" ? (
          <OFDDisplayMileageLog
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "multiSelect" ? (
          <OFDDisplayMultiSelect
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "mealItems" ? (
          <OFDDisplayMealItems
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "noteEditor" ? (
          <OFDDisplayNoteEditor
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "number" ? (
          <OFDDisplayNumber
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "password" ? (
          <OFDDisplayPassword
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "phone" ? (
          <OFDDisplayPhone
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "picture" ? (
          <OFDDisplayPicture
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "reminders" ? (
          <OFDDisplayReminders
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "select" ? (
          <OFDDisplaySelect
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "serviceLog" ? (
          <OFDDisplayServiceLog
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "switch" ? (
          <OFDDisplaySwitch
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "textField" ? (
          <OFDDisplayText
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "textNote" ? (
          <OFDDisplayNote
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "url" ? (
          <OFDDisplayUrl
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}

        {fieldType === "userAccount" ? (
          <OFDDisplayUserAccount
            onClick={onClick}
            {...fieldProps}
          />
        ) : null}
      </Box>
    </>
  );
};

export default OFDDisplayField;
