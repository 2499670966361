import { useEffect, useState } from "react";
import { startOfWeek, startOfToday } from "date-fns";

import useCalendar from "../../../../hooks/useCalendar";
import useWindow from "../../../../hooks/useWindow";
import usePage from "../../../../hooks/usePage";

import { Box } from "@mui/material";

import OFDWeekOf from "../../../ui/OFDWeekOf";
import MemberCalendar from "./MemberCalendar";

const CalendarDashboard = ({ height }) => {
  const { getCalendarForDashboard } = useCalendar();
  const { windowWidth } = useWindow();
  const { refresh } = usePage();

  const [calendarData, setCalendarData] = useState(undefined);
  const [weekOf, setWeekOf] = useState(undefined);
  const [memberCalendar, setMemberCalendar] = useState(undefined);

  useEffect(() => {
    setWeekOf(startOfWeek(startOfToday()));
  }, []);

  useEffect(() => {
    if (!weekOf) return;

    getData();
  }, [weekOf, refresh]);

  const getData = async () => {
    setCalendarData(null);
    const results = await getCalendarForDashboard(weekOf, "appointment");

    if (results.isSuccessful) {
      setCalendarData(results.data);
    } else {
      setCalendarData(null);
    }
  };

  useEffect(() => {
    if (calendarData === undefined) return;

    splitCalendarData();
  }, [calendarData]);

  const splitCalendarData = () => {
    if (calendarData === null) {
      setMemberCalendar([]);
      return;
    }

    let byMember = [];

    for (const calendarItem of calendarData) {
      let memberEntry;
      let memberIndex = byMember.findIndex(
        (item) => item.memberId === calendarItem.memberId
      );

      if (memberIndex > -1) {
        memberEntry = byMember[memberIndex];
      } else {
        memberEntry = {
          memberId: calendarItem.memberId,
          items: [],
        };
      }

      memberEntry.items.push(calendarItem);

      if (memberIndex > -1) {
        byMember[memberIndex] = memberEntry;
      } else {
        byMember.push(memberEntry);
      }
    }

    setMemberCalendar(byMember);
  };

  if (weekOf === undefined) return;
  if (memberCalendar === undefined) return;

  return (
    <Box sx={{ height: { height } }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <OFDWeekOf
          value={weekOf}
          onChange={(newValue) => setWeekOf(newValue)}
        />
      </Box>

      {Array.isArray(memberCalendar) && memberCalendar.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            paddingTop: "1rem",
            overflowX: "auto",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
          gap={2}
        >
          {memberCalendar.map((member) => (
            <MemberCalendar
              key={member.id}
              memberCalendar={member}
              height={height - 50}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default CalendarDashboard;
