import React from "react";
import { format, parseISO } from "date-fns";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

const RecurrenceTimeFrame = ({ recurrence }) => {
  const { getLabel } = useLocalization();

  const firstScheduleDate = () => {
    if (recurrence.frequency !== "schedule") return "";

    let scheduledates = [...recurrence.scheduleDates].sort();
    return format(parseISO(scheduledates[0]), "MMM dd, yyyy");
  };

  const lastScheduleDate = () => {
    if (recurrence.frequency !== "schedule") return "";

    let scheduledates = [...recurrence.scheduleDates].sort();
    return format(
      parseISO(scheduledates[scheduledates.length - 1]),
      "MMM dd, yyyy"
    );
  };

  if (!recurrence) return null;

  return (
    <Box
      sx={{ display: "flex" }}
      gap={1}
    >
      <Typography variant="caption">{getLabel("from")}</Typography>

      {recurrence.frequency !== "schedule" ? (
        <Typography variant="caption">
          {format(recurrence.occurrenceStart, "MMM dd, yyyy")}
        </Typography>
      ) : null}

      {recurrence.forever && recurrence.frequency !== "schedule" ? (
        <Typography variant="caption">{getLabel("forever")}</Typography>
      ) : null}

      {!recurrence.forever && recurrence.until ? (
        <Typography variant="caption">{`to ${format(
          recurrence.until,
          "MMM dd, yyyy"
        )}`}</Typography>
      ) : null}

      {!recurrence.forever && recurrence.count ? (
        <Typography
          variant="caption"
          sx={{ textTransform: "lowercase" }}
        >{`${getLabel("for")} ${recurrence.count} ${getLabel(
          "occurrences"
        )}`}</Typography>
      ) : null}

      {recurrence.frequency === "schedule" &&
      Array.isArray(recurrence.scheduleDates) ? (
        <Typography variant="caption">{`${firstScheduleDate()} to ${lastScheduleDate()}`}</Typography>
      ) : null}
    </Box>
  );
};

export default RecurrenceTimeFrame;
