import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import OFDFormControl from "./OFDFormControl";

import "../../styles/phoneNumber.css";

const OFDPhone = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView, userCanUpdate } = useSecurity();

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <OFDFormControl
      id={id}
      label={getLabel(label)}
    >
      <PhoneInput
        value={value ? value : null}
        onChange={onChange}
        style={{ fontSize: "24px" }}
        disabled={isDisabled()}
      />
    </OFDFormControl>
  );
};

export default OFDPhone;
