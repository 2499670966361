import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Stack, Typography } from "@mui/material";

import OFDSelect from "./OFDSelect";
import OFDMemberPicker from "./OFDMemberPicker";
import OFDSwitch from "./OFDSwitch";

const defaultLevels = {
  view: { securityLevel: "family" },
  update: { securityLevel: "admin" },
  delete: { securityLevel: "admin" },
};

const OFDRecordSecurity = ({
  id,
  label,
  value,
  onChange,
  required,
  disabled,
  entityName,
}) => {
  const { labels } = useLocalization();
  const { pageDefaultSecurity } = useSecurity();

  const [defaultSecurityLevel, setDefaultSecurityLevel] = useState(undefined);

  useEffect(() => {
    setDefaultSecurityLevel(pageDefaultSecurity(entityName));
  }, [entityName]);

  useEffect(() => {}, [defaultSecurityLevel]);

  const handleChange = (action, field, newValue) => {
    let newSecurity = {};
    if (value) newSecurity = value;
    if (!value) newSecurity = { ...defaultSecurityLevel };

    onChange({
      ...newSecurity,
      [action]: { ...newSecurity[action], [field]: newValue },
    });
  };

  const getValue = (action, field) => {
    if (!value || !value.hasOwnProperty(action)) {
      if (field === "securityLevel") {
        return defaultSecurityLevel[action].securityLevel;
      } else if (field === "members") {
        return [];
      }
    }

    if (value[action].hasOwnProperty(field)) {
      return value[action][field];
    } else {
      if (field === "securityLevel") {
        return defaultSecurityLevel[action].securityLevel;
      } else if (field === "members") {
        return [];
      }
    }

    return null;
  };

  const isPrivate = () => {
    if (!value || !value.view) return false;
    if (value.view.securityLevel === "addedBy") return true;
    return false;
  };

  const setPrivate = (newValue) => {
    let newSecurity = {};
    if (value) newSecurity = { ...value };
    if (!value) newSecurity = { ...defaultSecurityLevel };

    if (newValue) {
      newSecurity.view.securityLevel = "addedBy";
      newSecurity.update.securityLevel = "addedBy";
      newSecurity.delete.securityLevel = "addedBy";
    } else {
      newSecurity.view.securityLevel = "family";
    }

    onChange(newSecurity);
  };

  if (defaultSecurityLevel == undefined) return;

  return (
    <>
      <Stack
        id={id}
        spacing={2}
        sx={{ width: "100%" }}
      >
        <OFDSwitch
          id="isPrivate"
          label="isPrivate"
          value={isPrivate()}
          onChange={setPrivate}
        />

        {/* VIEW */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>{labels.view}</Typography>
          </Box>

          <Box sx={{ width: "80%" }}>
            <OFDSelect
              id="view-security"
              label="viewSecurity"
              value={getValue("view", "securityLevel")}
              onChange={(newValue) =>
                handleChange("view", "securityLevel", newValue)
              }
              listName="fieldSecurityLevels"
              fullWidth
            />

            {getValue("view", "securityLevel") === "members" ? (
              <OFDMemberPicker
                value={getValue("view", "members")}
                onChange={(newValue) =>
                  handleChange("view", "members", newValue)
                }
              />
            ) : null}
          </Box>
        </Box>

        {/* UPDATE */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>{labels.update}</Typography>
          </Box>

          <Box sx={{ width: "80%" }}>
            <OFDSelect
              id="update-security"
              label="updateSecurity"
              value={getValue("update", "securityLevel")}
              onChange={(newValue) =>
                handleChange("update", "securityLevel", newValue)
              }
              listName="fieldSecurityLevels"
              fullWidth
            />

            {getValue("update", "securityLevel") === "members" ? (
              <OFDMemberPicker
                value={getValue("update", "members")}
                onChange={(newValue) =>
                  handleChange("update", "members", newValue)
                }
              />
            ) : null}
          </Box>
        </Box>

        {/* DELETE */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>{labels.delete}</Typography>
          </Box>

          <Box sx={{ width: "80%" }}>
            <OFDSelect
              id="delete-security"
              label="deleteSecurity"
              value={getValue("delete", "securityLevel")}
              onChange={(newValue) =>
                handleChange("delete", "securityLevel", newValue)
              }
              listName="fieldSecurityLevels"
              fullWidth
            />

            {getValue("delete", "securityLevel") === "members" ? (
              <OFDMemberPicker
                value={getValue("delete", "members")}
                onChange={(newValue) =>
                  handleChange("delete", "members", newValue)
                }
              />
            ) : null}
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default OFDRecordSecurity;
