import { configureStore } from "@reduxjs/toolkit";

import authenticationReducer from "./authentication";
import memberReducer from "./member";
import pageReducer from "./page";
import familyReducer from "./family";
import systemReducer from "./system";
import messagingReducer from "./messaging";
import signupReducer from "./signup";

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    member: memberReducer,
    page: pageReducer,
    family: familyReducer,
    system: systemReducer,
    messaging: messagingReducer,
    signup: signupReducer,
  },
});

export default store;
