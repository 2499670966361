import { useEffect, useState } from "react";
import { startOfToday, format, addDays } from "date-fns";

import { Box, Button, Typography } from "@mui/material";

import DashboardWidget from "./DashboardWidget";

import CalendarWidget from "./CalendarWidget";
import ToDoWidget from "./ToDoWidget";
import ChoresWidget from "./ChoresWidget";
import HomeworkWidget from "./HomeworkWidget";
import GroceryWidget from "./GroceryWidget";
import MealPlanWidget from "./MealPlanWidget";

import OFDIcon from "../../../ui/OFDIcon";

const HomeDashboard = ({ height }) => {
  const [forDate, setForDate] = useState(startOfToday());

  const changeDate = (delta) => {
    setForDate((current) => {
      return addDays(current, delta);
    });
  };

  const gotoToday = () => {
    setForDate(startOfToday());
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: ".5rem",
          }}
        >
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "50%",
            }}
            onClick={() => changeDate(-1)}
          >
            <OFDIcon
              name="arrowLeft"
              color="grey"
              shade={500}
              size="large"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            gap={1}
          >
            <Box>
              <Button
                variant="text"
                onClick={gotoToday}
              >
                Go to today
              </Button>
            </Box>

            <Box>
              <Box
                sx={{ display: "flex" }}
                gap={1}
              >
                <Typography sx={{ fontWeight: 900 }}>
                  {format(forDate, "EEE, MMM dd, yyyy")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "50%",
            }}
            onClick={() => changeDate(1)}
          >
            <OFDIcon
              name="arrowRight"
              color="grey"
              shade={500}
              size="large"
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexFlow: "wrap",
          }}
          gap={2}
        >
          <DashboardWidget
            height={height}
            title="calendar"
          >
            <CalendarWidget forDate={forDate} />
          </DashboardWidget>

          <DashboardWidget
            height={height}
            title="todo"
          >
            <ToDoWidget forDate={forDate} />
          </DashboardWidget>

          <DashboardWidget
            height={height}
            title="chores"
          >
            <ChoresWidget forDate={forDate} />
          </DashboardWidget>

          <DashboardWidget
            height={height}
            title="homework"
          >
            <HomeworkWidget />
          </DashboardWidget>

          <DashboardWidget
            height={height}
            title="grocery"
          >
            <GroceryWidget forDate={forDate} />
          </DashboardWidget>

          <DashboardWidget
            height={height}
            title="mealPlan"
          >
            <MealPlanWidget forDate={forDate} />
          </DashboardWidget>
        </Box>
      </Box>
    </>
  );
};

export default HomeDashboard;
