import { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import useMember from "../../hooks/useMember";

import { Box, Typography } from "@mui/material";

import OFDAvatar from "./OFDAvatar";

const OFDDisplayAttendee = ({ attendee, onClick, showName = true }) => {
  const { getData } = useApi();
  const { getMember } = useMember();

  const [data, setData] = useState(null);

  useEffect(() => {
    if (!attendee) return;

    getAttendee();
  }, [attendee]);

  const getAttendee = async () => {
    let results;

    if (attendee.entityName === "Member") {
      results = {
        isSuccessful: true,
        data: getMember(attendee.id),
      };
    } else {
      results = await getData({
        entityName: attendee.entityName,
        action: "get",
        id: attendee.id,
      });
    }

    if (results.isSuccessful) {
      setData(results.data);
    } else {
      setData(null);
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick(attendee);
    }
  };

  if (!data) return null;

  return (
    <Box
      sx={{ display: "flex", alignItems: "center" }}
      gap={1}
      onClick={handleClick}
    >
      <OFDAvatar
        id={`avatar-${data.id}`}
        avatar={data.avatar || null}
        name={data.name}
        borderColor={data.color || "grey"}
      />
      {showName ? (
        <Box>
          <Typography>{data.name}</Typography>
          {attendee.notes ? (
            <Typography sx={{ fontSize: "12px", color: "#888" }}>
              {attendee.notes}
            </Typography>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default OFDDisplayAttendee;
