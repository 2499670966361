"use client";

import React, { useEffect, useState, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

import useSystem from "../../../hooks/useSystem";
import usePage from "../../../hooks/usePage";
import useWindow from "../../../hooks/useWindow";

import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

import PageHeader from "./PageHeader";
import PageError from "./PageError";
import PageBody from "./PageBody";
import SideMenu from "./SideMenu";

import LoadingModal from "../LoadingModal";

const PageFooter = React.lazy(() => import("./PageFooter"));

const Page = () => {
  const theme = useTheme();
  const { isMobile } = useWindow();
  const { updateApp, currentVersion } = useSystem();

  const { isSaving, refreshPage } = usePage();

  useEffect(() => {
    window.addEventListener("load", refreshPage);

    return () => {
      window.removeEventListener("load", refreshPage);
    };
  }, []);

  useEffect(() => {
    updateApp();
  }, [currentVersion]);

  const fallbackRender = ({ error, resetErrorBoundary }) => {
    return (
      <PageError
        error={error}
        resetErrorBoundary={resetErrorBoundary}
      />
    );
  };

  return (
    <>
      <Box
        width="100%"
        height="100%"
        sx={{
          ...theme.page,
        }}
      >
        {!isMobile ? <SideMenu /> : null}

        <PageHeader />
        <ErrorBoundary fallbackRender={fallbackRender}>
          <PageBody />
        </ErrorBoundary>
        {isMobile ? (
          <Suspense fallback={<div></div>}>
            <PageFooter />
          </Suspense>
        ) : null}
      </Box>

      <LoadingModal open={isSaving} />
    </>
  );
};

export default Page;
