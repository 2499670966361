import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";

import { Box, Stack, Typography } from "@mui/material";

import OFDCollapsable from "../layout/OFDCollapsable";
import ReminderItem from "./ReminderItem";
import OFDIcon from "../ui/OFDIcon";

const ReminderItems = ({ reminders, onEdit, onDelete, onDelay }) => {
  const { getTitle } = useLocalization();
  const { sortByFields } = useData();

  return (
    <Box sx={{ width: "100%" }}>
      <OFDCollapsable
        header={
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <OFDIcon
              name="reminder"
              color="red"
            />
            <Typography>{getTitle("reminders")}</Typography>
          </Box>
        }
      >
        <Stack>
          {reminders?.map((calendar) => (
            <ReminderItem
              key={calendar.id}
              calendar={calendar}
              onEdit={onEdit}
              onDelay={onDelay}
              onDelete={onDelete}
            />
          ))}
        </Stack>
      </OFDCollapsable>
    </Box>
  );
};

export default ReminderItems;
