const Allergies = [
  {
    label: "mild",
    color: "green",
    shade: 200,
    level: 1,
  },
  {
    label: "moderate",
    color: "orange",
    shade: 300,
    level: 2,
  },
  {
    label: "severe",
    color: "red",
    shade: 900,
    level: 3,
  },
];

export default Allergies;
