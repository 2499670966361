import { useEffect } from "react";
import { startOfToday, isEqual, isBefore } from "date-fns";

import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDSwitch from "../ui/OFDSwitch";
import OFDDate from "../ui/OFDDate";
import OFDContextHelp from "../ui/OFDContextHelp";

const SeriesFilter = ({ startDate, endDate, filter, onChange, deleteMode }) => {
  const { getTitle } = useLocalization();

  useEffect(() => {
    if (filter) return;

    const before = isBefore(startOfToday(), startDate);
    onChange({
      fromDate: before ? startDate : startOfToday(),
      toDate: endDate,
      entireSeries: before ? true : false,
      fromToday: before ? false : true,
      ignoreChanged: deleteMode ? false : true,
    });
  }, [filter, startDate, endDate]);

  const setFilterValue = (name, value) => {
    let newFilter = { ...filter };

    if (name === "entireSeries" && value === true) {
      newFilter.fromDate = startDate;
      newFilter.toDate = endDate;
      newFilter.fromToday = false;
    }
    if (name === "fromToday" && value === true) {
      newFilter.fromDate = startOfToday();
      newFilter.entireSeries = false;
    }
    if (name === "fromDate") {
      if (isEqual(value, startOfToday())) {
        newFilter.fromToday = true;
        newFilter.entireSeries = false;
      } else {
        newFilter.fromToday = false;
        newFilter.entireSeries = false;
      }
    }

    newFilter[name] = value;

    onChange(newFilter);
  };

  const defaultFromDate = () => {};

  if (!filter) return;

  return (
    <Box>
      <Typography
        align="center"
        variant="h6"
      >
        {getTitle("seriesFilter")}
      </Typography>
      <Stack
        spacing={2}
        sx={{ marginTop: "1rem" }}
      >
        <OFDSwitch
          id="entireSeries"
          label="entireSeries"
          value={
            filter.hasOwnProperty("entireSeries") ? filter.entireSeries : false
          }
          onChange={(newValue) => setFilterValue("entireSeries", newValue)}
        />
        {filter?.entireSeries === false ? (
          <OFDSwitch
            id="fromToday"
            label="fromToday"
            value={filter.hasOwnProperty("fromToday") ? filter.fromToday : true}
            onChange={(newValue) => setFilterValue("fromToday", newValue)}
          />
        ) : null}
        {filter?.entireSeries === false ? (
          <OFDDate
            id="fromDate"
            label="fromDate"
            value={filter.fromDate ? filter.fromDate : startOfToday()}
            onChange={(newValue) => setFilterValue("fromDate", newValue)}
            minDate={startDate}
            maxDate={endDate}
          />
        ) : null}
        {filter?.entireSeries === false ? (
          <OFDDate
            id="toDate"
            label="toDate"
            value={filter.toDate ? filter.toDate : endDate}
            onChange={(newValue) => setFilterValue("toDate", newValue)}
            minDate={startDate}
            maxDate={endDate}
          />
        ) : null}

        <Box
          sx={{ display: "flex", alignItems: "center" }}
          gap={1}
        >
          <OFDSwitch
            id="ignoreChanged"
            label="ignoreChanged"
            value={
              filter.hasOwnProperty("ignoreChanged")
                ? filter.ignoreChanged
                : true
            }
            onChange={(newValue) => setFilterValue("ignoreChanged", newValue)}
          />
          <OFDContextHelp
            id="todoPopover"
            text="ignoreChangedOccurrences"
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default SeriesFilter;
