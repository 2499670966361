import { useEffect, useState } from "react";

import useChore from "../../../../hooks/useChore";
import useLocalization from "../../../../hooks/useLocalization";
import usePage from "../../../../hooks/usePage";

import { Box, Stack, Typography } from "@mui/material";

import OFDIcon from "../../../ui/OFDIcon";
import ChoresByMember from "./ChoresByMember";
import Confetti from "../../../display/Confetti";

const ChoresWidget = ({ forDate }) => {
  const { getFamilyChores, familyChores } = useChore();
  const { getMessage } = useLocalization();
  const { refresh } = usePage();

  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    setShowConfetti(false);
    getFamilyChores();
  }, [refresh]);

  const handleChange = async (status) => {
    if (status === "approved") {
      setShowConfetti(true);
    }
    await getFamilyChores();
  };

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [showConfetti]);

  if (familyChores === undefined) return;

  return (
    <>
      <Box>
        {Array.isArray(familyChores.activity) &&
        familyChores.activity.length > 0 ? (
          <Stack spacing={1}>
            {familyChores.activity.map((activity, index) => (
              <ChoresByMember
                key={`chore_${index}`}
                memberChores={activity}
                choreList={familyChores.choreList}
                onChange={handleChange}
                forDate={forDate}
              />
            ))}
          </Stack>
        ) : (
          <Typography>{getMessage("noFamilyChores")}</Typography>
        )}
      </Box>

      {showConfetti ? <Confetti /> : null}
    </>
  );
};

export default ChoresWidget;
