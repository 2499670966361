import React from "react";

import { Box } from "@mui/material";

import OFDSelect from "./OFDSelect";

const OFDYearlyOptions = ({ interval, onChange, disabled }) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center" }}
      gap={1}
    >
      <OFDSelect
        id="interval"
        label="every"
        value={interval}
        onChange={onChange}
        listName="yearlyIntervals"
        fullWidth
        disabled={disabled}
      />
    </Box>
  );
};

export default OFDYearlyOptions;
