import { useEffect, useState } from "react";

import usePage from "../../hooks/usePage";
import useMember from "../../hooks/useMember";

import { Box } from "@mui/material";

import AppointmentEditor from "../editors/AppointmentEditor";
import ReminderEditor from "../editors/ReminderEditor";
import ToDoEditor from "../editors/ToDoEditor";

import CurrentActivities from "../dashboards/CurrentActivities";
import ChildDashboard from "../dashboards/ChildDashboard";

const Home = () => {
  const { setEntityName, toolbarAction, resetToolbarAction, refreshPage } =
    usePage();
  const { member } = useMember();

  const [dashboard, setDashboard] = useState("currentActivities");

  const [openAppointment, setOpenAppointment] = useState(false);
  const [openReminder, setOpenReminder] = useState(false);
  const [openToDo, setOpenToDo] = useState(false);

  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [selectedOption, setSelectedOption] = useState("occurrence");

  useEffect(() => {
    setEntityName("Home");
    if (member.securityLevel === "child" || member.securityLevel === "teen") {
      setDashboard("childDashboard");
    } else {
      setDashboard("currentActivities");
    }
  }, [member.securityLevel]);

  useEffect(() => {
    if (toolbarAction === "addCalendar") {
      setSelectedCalendar(null);
      setSelectedOption(null);
      setOpenAppointment(true);
    }
    if (toolbarAction === "addReminder") {
      setSelectedCalendar(null);
      setSelectedOption(null);
      setOpenReminder(true);
    }
    if (toolbarAction === "addToDo") {
      setSelectedCalendar(null);
      setSelectedOption(null);
      setOpenToDo(true);
    }
  }, [toolbarAction]);

  const handleCloseEditor = () => {
    resetToolbarAction();
    setOpenAppointment(false);
    setOpenReminder(false);
    setOpenToDo(false);
  };

  const handleSave = () => {
    handleCloseEditor();
    refreshPage();
  };

  const handleSelect = (calendar) => {
    setSelectedCalendar(calendar);
    if (calendar.calendarType === "appointment") {
      setOpenAppointment(true);
    } else if (calendar.calendarType === "reminder") {
      setOpenReminder(true);
    } else if (calendar.calendarType === "todo") {
      setOpenToDo(true);
    }
  };

  return (
    <>
      <Box>
        {dashboard === "currentActivities" ? (
          <CurrentActivities onSelect={handleSelect} />
        ) : null}
        {dashboard === "childDashboard" ? <ChildDashboard /> : null}
      </Box>

      {openAppointment ? (
        <AppointmentEditor
          open={openAppointment}
          onClose={handleCloseEditor}
          onSave={handleSave}
          calendar={selectedCalendar}
          option={selectedOption}
        />
      ) : null}

      {openReminder ? (
        <ReminderEditor
          open={openReminder}
          onClose={handleCloseEditor}
          onSave={handleSave}
          calendar={selectedCalendar}
          option={selectedOption}
        />
      ) : null}

      {openToDo ? (
        <ToDoEditor
          open={openToDo}
          onClose={handleCloseEditor}
          onSave={handleSave}
          calendar={selectedCalendar}
          option={selectedOption}
        />
      ) : null}
    </>
  );
};

export default Home;
