import React from "react";

import { Box, Typography } from "@mui/material";

import useAllergies from "../../hooks/useAllergies";
import useColor from "../../hooks/useColor";

const OFDDisplayAllergyItem = ({ allergy, onClick }) => {
  const { allergyColor, severityLabel } = useAllergies();
  const { getColor } = useColor();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...getColor(allergyColor(allergy)),

        width: "100%",
      }}
      gap={1}
      onClick={onClick}
    >
      <Typography>{allergy.name}</Typography>
      <Typography>{severityLabel(allergy)}</Typography>
    </Box>
  );
};

export default OFDDisplayAllergyItem;
