import { useEffect, useState, useRef } from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography } from "@mui/material";

import OFDButton from "./OFDButton";

import OFDIconButton from "./OFDIconButton";

const OFDAudioRecorder = ({ id, label, value, onChange }) => {
  const { getLabel, getMessage } = useLocalization();

  const [audioURL, setAudioURL] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [base64, setBase64] = useState(null);

  const playbackRef = useRef();
  const recorderRef = useRef();
  const mediaRecorder = useRef();

  const constraints = {
    audio: true,
    video: false,
  };

  useEffect(() => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
      navigator.mediaDevices.getUserMedia = (constraints) => {
        let getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        if (!getUserMedia) {
          return Promise.reject(new Error("Audio recording not available"));
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((audioStream) => {
        let audio = recorderRef.current;
        audio.src = audioStream;

        mediaRecorder.current = new MediaRecorder(audioStream);
        let chunks = [];

        mediaRecorder.current.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        mediaRecorder.current.onstop = (e) => {
          let blob = new Blob(chunks, { type: "audio/mp3" });
          chunks = [];
          setAudioURL(window.URL.createObjectURL(blob));

          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            setBase64(reader.result);
          };
        };
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    onChange(base64);
  }, [base64]);

  const handleStartRecording = () => {
    setIsRecording(true);
    setAudioURL(null);
    mediaRecorder.current.start();
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    mediaRecorder.current.stop();
  };

  return (
    <>
      <Box>
        <audio
          ref={recorderRef}
          style={{ display: "none" }}
        ></audio>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <OFDIconButton
            name="mic"
            size="xlarge"
            onClick={isRecording ? handleStopRecording : handleStartRecording}
            color={isRecording ? "red" : "grey"}
          />
        </Box>

        <Typography
          sx={{ marginTop: "1rem" }}
          align="center"
        >
          {getMessage(isRecording ? "recording" : "tapMicToRecord")}
        </Typography>

        {audioURL ? (
          <Box
            sx={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <audio
              ref={playbackRef}
              controls
              src={audioURL}
            ></audio>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default OFDAudioRecorder;
