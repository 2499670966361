import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import usePage from "../../../hooks/usePage";
import useEntity from "../../../hooks/useEntity";
import useFamily from "../../../hooks/useFamily";
import useAnalytics from "../../../hooks/useAnalytics";
import useWindow from "../../../hooks/useWindow";

import Accounts from "../../pages/Accounts";
import Budgets from "../../pages/Budgets";
import Calendar from "../../pages/Calendar";
import Chores from "../../pages/Chores";
import Contacts from "../../pages/Contacts";
import Debug from "../../pages/Debug";
import Documents from "../../pages/Documents";
import Expenses from "../../pages/Expenses";
import Family from "../../pages/Family";
import FamilyPreferences from "../../pages/FamilyPreferences";
import Google from "../../pages/Google";
import Groceries from "../../pages/Groceries";
import Home from "../../pages/Home";
import Homework from "../../pages/Homework";
import ManageSubscription from "../../pages/ManageSubscription";
import MealPlans from "../../pages/MealPlans";
import Members from "../../pages/Members";
import MyAccount from "../../pages/MyAccount";
import Notes from "../../pages/Notes";
import PasswordManager from "../../pages/PasswordManager";
import Recipes from "../../pages/Recipes";
import Recurrence from "../../pages/Recurrence";
import Reminders from "../../pages/Reminders";
import Subscriptions from "../../pages/Subscriptions";
import SubscriptionPlan from "../../pages/SubscriptionPlan";
import ToDos from "../../pages/ToDos";
import Trips from "../../pages/Trips";
import Vehicles from "../../pages/Vehicles";
import VideoThumbnail from "../../pages/VideoThumbnail";

import ThirtyDay from "../../emailSubscriptions/ThirtyDay";

import PageError from "./PageError";

import { Box } from "@mui/material";

import StyleConstants from "../../../styles/StyleConstants";

import Configuration from "../../../configuration/Configuration";

const PageBody = () => {
  const { setPage, entityName, refreshMenu } = usePage();
  const { toolbar } = useEntity(entityName);
  const { refreshFamilyPreferences } = useFamily();
  const { isMobile } = useWindow();

  const [headerHeight, setHeaderHeight] = useState("125px");
  const [hasToolbar, setHasToolbar] = useState(true);

  useAnalytics();

  useEffect(() => {
    updateFamilyPreferences();
    refreshMenu();
  }, [entityName]);

  useEffect(() => {
    if (toolbar && toolbar.length > 0) {
      setHeaderHeight("145px");
      setHasToolbar(true);
    } else {
      setHeaderHeight("80px");
      setHasToolbar(false);
    }
  }, [toolbar]);

  useEffect(() => {
    pageVisit();
  }, [window.location.pathname]);

  const updateFamilyPreferences = async () => {
    await refreshFamilyPreferences();
  };

  const pageVisit = async () => {
    await setPage({ page: window.location.pathname });
  };

  return (
    <>
      <Box
        sx={{
          paddingTop: headerHeight,
          paddingBottom: StyleConstants.pageFooter.height["mobile"],
          height: "100%",
          overflowY: "auto",
          backgroundColor: "#ebebeb",
          marginLeft: isMobile ? 0 : "100px",
        }}
      >
        <Box
          sx={{
            paddingTop: "1rem",
            paddingLeft: isMobile ? "1rem" : "8%",
            paddingRight: isMobile ? "1rem" : "8%",
          }}
        >
          <Routes>
            <Route
              path="accounts"
              element={<Accounts />}
            />
            <Route
              path="budgets"
              element={<Budgets />}
            />
            <Route
              path="calendar"
              element={<Calendar />}
            />
            <Route
              path="chores"
              element={<Chores />}
            />
            <Route
              path="contacts"
              element={<Contacts />}
            />
            <Route
              path="documents"
              element={<Documents />}
            />
            <Route
              path="debug"
              element={<Debug />}
            />
            <Route
              path="expenses"
              element={<Expenses />}
            />
            <Route
              path="family"
              element={<Family />}
            />
            <Route
              path="familypreferences"
              element={<FamilyPreferences />}
            />
            <Route
              path="google"
              element={<Google />}
            />
            <Route
              path="groceries"
              element={<Groceries />}
            />
            <Route
              path="home"
              element={<Home />}
            />
            <Route
              path="homework"
              element={<Homework />}
            />
            <Route
              path="managesubscription"
              element={<ManageSubscription />}
            />
            <Route
              path="mealplans"
              element={<MealPlans />}
            />
            <Route
              path="members"
              element={<Members />}
            />
            <Route
              path="myaccount"
              element={<MyAccount />}
            />
            <Route
              path="notes"
              element={<Notes />}
            />
            <Route
              path="error"
              element={<PageError />}
            />

            <Route
              path="passwords"
              element={<PasswordManager />}
            />
            <Route
              path="recipes"
              element={<Recipes />}
            />
            <Route
              path="recurrence"
              element={<Recurrence />}
            />
            <Route
              path="reminders"
              element={<Reminders />}
            />
            <Route
              path="subscriptionplan"
              element={<SubscriptionPlan />}
            />
            <Route
              path="subscriptions"
              element={<Subscriptions />}
            />
            <Route
              path="thirtyday"
              element={<ThirtyDay />}
            />
            <Route
              path="todos"
              element={<ToDos />}
            />
            <Route
              path="trips"
              element={<Trips />}
            />
            <Route
              path="vehicles"
              element={<Vehicles />}
            />
            <Route
              path="videothumbnail"
              element={<VideoThumbnail />}
            />

            <Route
              path="*"
              element={<Home />}
            />
          </Routes>
        </Box>
      </Box>
    </>
  );
};

export default PageBody;
