import { useEffect, useState } from "react";

import useFamily from "../../hooks/useFamily";
import useLocalization from "../../hooks/useLocalization";
import useMember from "../../hooks/useMember";

import { Box, Stack, Typography, Popover } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OFDAvatar from "./OFDAvatar";
import OFDHorizontalScroll from "./OFDHorizontalScroll";
import OFDSelect from "./OFDSelect";
import OFDIcon from "./OFDIcon";

const OFDMemberFilter = ({ value, onChange, available }) => {
  const { getList } = useLocalization();
  const { familyMembers } = useFamily();
  const { memberColor, getMemberColor, member } = useMember();

  const theme = useTheme();

  const [showMenu, setShowMenu] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);

  const handleOpenMenu = (e) => {
    setAnchorE1(e.target);
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorE1(null);
    setShowMenu(false);
  };

  const handleMemberClick = (member) => {
    if (!available?.includes(member.id)) return;

    let newValue;

    if (value?.includes(member.id)) {
      newValue = value.filter((mbr) => mbr !== member.id);
    } else {
      newValue = [...value, member.id];
    }

    onChange(newValue);
  };

  const handleMenuClick = (option) => {
    let newValue = [];

    if (option === "all") {
      newValue = available.map((member) => member);
    } else if (option === "clear") {
      newValue = [];
    } else if (option === "me") {
      newValue = [member.id];
    }

    onChange(newValue);

    handleCloseMenu();
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center" }}
      gap={1}
    >
      <OFDHorizontalScroll>
        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          {familyMembers?.map((member) => (
            <Box
              key={member.id}
              sx={{
                paddingBottom: "4px",
                borderBottom: value?.includes(member.id)
                  ? `4px solid ${
                      memberColor({ color: getMemberColor(member.id) })
                        .backgroundColor
                    }`
                  : "none",
                opacity: available?.includes(member.id) ? 1 : 0.5,
                cursor: available?.includes(member.id) ? "pointer" : "auto",
              }}
              onClick={() => handleMemberClick(member)}
            >
              <OFDAvatar
                {...member}
                size="xsmall"
              />
            </Box>
          ))}
        </Box>
      </OFDHorizontalScroll>

      <Box sx={{ position: "relative" }}>
        <Box onClick={handleOpenMenu}>
          <OFDIcon
            name="verticalEllipse"
            color="grey"
          />
        </Box>

        <Popover
          open={showMenu}
          onClose={handleCloseMenu}
          anchorEl={anchorE1}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Stack
            spacing={3}
            sx={{
              backgroundColor: "#ffffff",
              padding: "1rem",
              borderRadius: "8px",
              boxShadow: theme.shadows[8],
            }}
          >
            {getList("memberFilterOptions").map((option) => (
              <Box
                key={option.value}
                onClick={() => handleMenuClick(option.value)}
              >
                {option.label}
              </Box>
            ))}
          </Stack>
        </Popover>
      </Box>
    </Box>
  );
};

export default OFDMemberFilter;
