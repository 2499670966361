import useLocalization from "./useLocalization";

import Allergies from "../configuration/Allergies";

const useAllergies = () => {
  const { getList } = useLocalization();

  const allergyColor = (allergy) => {
    const allergyLevel = Allergies.find((a) => a.label === allergy.severity);

    return {
      color: allergyLevel.color,
      shade: allergyLevel.shade,
    };
  };

  const severityLabel = (allergy) => {
    const list = getList("allergySeverity");
    const level = list.find((a) => a.value === allergy.severity);
    return level.label;
  };

  return {
    allergySeverity: getList("allergySeverity"),
    allergyColor,
    severityLabel,
  };
};

export default useAllergies;
