import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import useMember from "../../hooks/useMember";

import OFDIconElement from "./OFDIconElement";

const sizes = {
  xxsmall: {
    width: "12px",
    height: "12px",
  },
  xsmall: {
    width: "16px",
    height: "16px",
  },
  small: {
    width: "24px",
    height: "24px",
  },
  medium: {
    width: "32px",
    height: "32px",
  },
  large: {
    width: "40px",
    height: "40px",
  },
  xlarge: {
    width: "48px",
    height: "48px",
  },
  xxlarge: {
    width: "56px",
    height: "56px",
  },
  xxxlarge: {
    width: "128px",
    height: "128px",
  },
};

const OFDIcon = ({
  name,
  size = "small",
  theme = "member",
  mode,
  style,
  color,
  backgroundColor,
  shade,
  onClick,
  disabled,
}) => {
  const { memberColor, member } = useMember();
  const styleTheme = useTheme();

  const [iconStyle, setIconStyle] = useState();

  let containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: onClick ? "pointer" : "default",
  };

  useEffect(() => {
    if (!member) return;

    if (theme === "editor") {
      containerStyle.backgroundColor = memberColor({
        color: backgroundColor ? backgroundColor : "grey",
        shade: shade ? shade : 500,
      }).backgroundColor;
      containerStyle.padding = "8px";
      containerStyle.borderRadius = "50%";
    }

    let tempStyle = { ...style };

    if (disabled) {
      tempStyle.color = "#bbb";
    } else if (color) {
      tempStyle.color = memberColor({
        color,
        shade: shade || 500,
      }).backgroundColor;
    } else if (shade) {
      tempStyle.color = memberColor({
        shade: shade,
      }).backgroundColor;
    } else if (theme === "light") {
      tempStyle.color = "#f0f0f0";
    } else if (theme === "member") {
      tempStyle.color = memberColor().backgroundColor;
    } else if (theme === "editor") {
      tempStyle.color = "#fff";
    } else if (theme === "contrast") {
      let memberColor = "default";
      if (member.color) {
        memberColor = member.color;
      }
      if (shade === 900) {
        tempStyle.color = styleTheme.palette[memberColor].darkContrast;
      } else {
        tempStyle.color = styleTheme.palette[memberColor].mainContrast;
      }
    }

    if (size) {
      tempStyle = { ...tempStyle, ...sizes[size] };
    }

    setIconStyle(tempStyle);
  }, [member, color, shade]);

  const handleClick = () => {
    if (disabled) return;

    if (onClick) {
      onClick();
    }
  };

  if (!iconStyle) return;

  return (
    <Box sx={{ ...containerStyle }}>
      <OFDIconElement
        name={name}
        style={iconStyle}
        onClick={handleClick}
      />
    </Box>
  );
};

export default OFDIcon;
