const en_US = {
  code: "en-US",
  language: {
    code: "en",
    name: "English",
  },
  country: {
    code: "us",
    name: "United States",
  },
  currency: "usd",

  titles: {
    account: "Account",
    accounts: "Accounts",
    appointment: "Appointment",
    availableFields: "Available Fields",
    budget: "Budget",
    budgetWizard: "Budget Wizard",
    budgets: "Budgets",
    bytes: "Bytes",
    calendar: "Calendar",
    calendarImport: "Calendar Import",
    cancelChanges: "Cancel Changes?",
    chores: "Chores",
    createBudget: "Create Budget",
    createFolder: "Create Folder",
    confirmClearTokens: "Confirm Token Removal?",
    confirmDelete: "Confirm Delete",
    confirmSaveSeries: "Save all occurrences in series?",
    confirmSaveExpense: "Save Expense?",
    confirmDeleteExpense: "Delete Expense?",
    contact: "Contact",
    contacts: "Contacts",
    currentActivities: "Current Activities",
    dashboard: "Dashboard",
    deleteFamilyData: "Delete All Family Data?",
    deleteSeries: "Delete Series",
    documents: "Documents",
    documentFolder: "Document Folder",
    documentUpload: "Document Upload",
    documentViewer: "Document Viewer",
    editField: "Edit Field",
    expenses: "Expenses",
    expensesByMonth: "Monthly Summary",
    expensesByBudget: "Budget Summary",
    expensesByCompany: "Company Summary",
    family: "Family",
    familyPreferences: "Family Preferences",
    fieldType: "Field Types",
    folderEditor: "Folder Editor",
    folderName: "Folder Name",
    folderSecurity: "Folder Security",
    forgotPassword: "Forgot Password",
    importContacts: "Import Contacts",
    installation: "Installation",
    installationInstructions: "Installation Instructions",
    itinerary: "Destinations",
    introduction: "Introduction",
    getStarted: "Getting Started",
    groceries: "Groceries",
    grocery: "Grocery",
    groceryItems: "Grocery Items",
    groceryShopping: "Grocery Shopping",
    groceryWizard: "Grocery Wizard",
    group: "Group",
    help: "Help",
    homework: "Homework",
    instructions: "Instructions",
    login: "Login",
    mealItems: "Meal Items",
    mealPlan: "Meal Plan",
    mealPlans: "Meal Plans",
    mealplans: "Meal Plans",
    mealPlanWizard: "Meal Plan Wizard",
    member: "Family Member",
    memberForm: "Member Profile",
    moveDocument: "Move Document",
    myAccount: "My Account",
    myPreferences: "My Preferences",
    newAppVersion: "A new version is available",
    noteEditor: "Note Editor",
    note: "Note",
    notes: "Notes",
    occasion: "Occasion",
    occasions: "Occasions",
    open: "Open",
    password: "Password",
    passwordManager: "Password Manager",
    payment: "Payment",
    recipe: "Recipe",
    recipeFinder: "Recipe Finder",
    recipes: "Recipes",
    recipeWizard: "Recipe Wizard",
    recurringExpenses: "Recurring Transactions",
    reminder: "Reminder",
    reminders: "Reminders",
    removeField: "Remove field from folder",
    renameDocument: "Rename Document",
    renameFolder: "Rename Folder",
    savingContacts: "Saving Contacts",
    search: "Search",
    securityEditor: "Security Editor",
    seriesFilter: "Series Filter",
    setupWizard: "Setup Wizard",
    specialOccasions: "Special Occasions",
    submitSupportTicket: "Submit Support Ticket",
    subscriptionPlans: "Subscription Plans",
    subscription: "Subscription",
    subscriptions: "Subscriptions",
    thankyou: " Thank You!",
    thanksForSigningUp: "Thanks for signing up!",
    todo: "To Do",
    todos: "To Dos",
    trip: "Trip",
    trips: "Trips",
    updateBudget: "Update Budget",
    uploadAvatar: "Upload Avatar",
    userAccount: "User Account",
    vehicles: "Vehicles",
    vehicle: "Vehicle",
  },

  securityLevels: {
    child: { level: 1, label: "Child", showUser: false },
    teen: { level: 1, label: "Teen", showUser: false },
    family: { level: 1, label: "All Family", showUser: true },
    adult: { level: 2, label: "Adults", showUser: true },
    admin: { level: 3, label: "Adminstrators", showUser: true },
    accountOwner: { level: 4, label: "Account Owner", showUser: true },
    members: { level: 5, label: "Selected Members", showUser: true },
    addedBy: { level: 6, label: "User Who Added Entry", showUser: true },
    system: { level: 7, label: "System", showUser: false },
  },

  userSecurityLevels: {
    child: { level: 1, label: "Child", showUser: true },
    teen: { level: 1, label: "Teen", showUser: true },
    adult: { level: 2, label: "Adult", showUser: true },
    admin: { level: 3, label: "Adminstrator", showUser: true },
    accountOwner: { level: 4, label: "Account Owner", showUser: true },
  },

  labels: {
    accept: "I accept",
    acceptTerms: "I accept these terms",
    accommodations: "Accommodations",
    actual: "Actual",
    add: "Add",
    addDestination: "Add Destination",
    addFolder: "New Folder",
    addField: "+Field",
    additionalInfo: "Additional Info",
    additionalMembersCanBePurchased:
      "Additional Members can be purchased for {price}",
    addMenuItem: "Add Menu Item",
    addMoreMembers: "Add More Members",
    address: "Address",
    addToExpenses: "Add to Expenses",
    addToGrocery: "Add to grocery list",
    allDay: "All Day",
    allergies: "Allergies",
    allergy: "Allergy",
    allowedMembers: "Allowed Members",
    amount: "Amount",
    app: "Application",
    apply: "Apply",
    appointment: "Appointment",
    appointments: "Appointments",
    approve: "Approve",
    approver: "Approver",
    approvers: "Approvers",
    approvals: "Approvals",
    arrival: "Arrival",
    asof: "as of",
    attendees: "Attendees",
    attendeeType: "Attendee Type",
    attractions: "Attractions",
    audio: "Audio",
    availableMembers: "Available Members",
    avatar: "Avatar",
    back: "Back",
    birthdate: "Birthday",
    bloodPressure: "Blood Pressure",
    budget: "Budget",
    budgetFrequency: "Budget Frequency",
    budgets: "Budgets",
    budgetAmount: "Budget $",
    budgetItem: "Budget Item",
    budgetItems: "Budget Items",
    budgetType: "Income or Expense",
    business: "Business",
    byBudget: "By Budget",
    byCalendar: "By Calendar",
    byCompany: "By Company",
    byMonth: "By Month",
    bytes: "bytes",
    calendar: "Calendar",
    camera: "Camera",
    cancel: "Cancel",
    cancelled: "Cancelled",
    cancelledAt: "Cancelled on",
    cancelSubscription: "Cancel Subscription",
    cardDescription: "Card Description",
    cardType: "Card Type",
    carRental: "Car Rental",
    carrier: "Carrier",
    category: "Category",
    cell: "Cell Number",
    changeAsOf: "Set budget amount as of this date",
    changePassword: "Change Password",
    check: "Check",
    chore: "Chore",
    chores: "Chores",
    city: "City",
    company: "Company",
    complete: "Complete",
    completeChore: "Complete your chores to earn tokens",
    cookTime: "Cook Time",
    copy: "Copy",
    color: "Color Assignment",
    confirmationCode: "Confirmation Code",
    contact: "Contact",
    contacts: "Contacts",
    contactsToImport: "contacts to import",
    cost: "Cost",
    couponCode: "Coupon Code",
    country: "Country",
    course: "Course",
    createBudget: "Create Budget",
    createGrocery: "Save Grocery List",
    createFolder: "Create Folder",
    createMealPlan: "Create Meal Plan",
    createRecipe: "Save Recipe",
    crop: "Crop",
    currency: "Currency",
    currentPassword: "Current Password",
    currentMembers: "Current Members",
    daily: "Daily",
    date: "Date",
    dates: "Dates",
    datetime: "Date and time",
    dayOf: "Day Of",
    days: "Days",
    daysRemainingInTrial: "{days} days remaining in Trial",
    dateRange: "Date Range",
    daysSelected: "Days Selected",
    definition: "Definition",
    delay15: "Delay 15 minutes",
    delay30: "Delay 30 minutes",
    delay60: "Delay 1 hour",
    delayUpdate: "Delay Update",
    delete: "Delete",
    deleteAll: "Delete All",
    deleteAllData: "Delete all family data",
    deleteAppointment: "Delete Appointment",
    deleteEvent: "Delete Event",
    deleteField: "Delete Field",
    deleteFolder: "Delete Folder",
    deleteImport: "Delete Import",
    deleteOccurrence: "Delete Occurrence",
    deleteReminder: "Delete Reminder",
    deleteSeries: "Delete Series",
    deleteToDo: "Delete To Do",
    department: "Department",
    departure: "Departure",
    depot: "Depot",
    description: "Description",
    destination: "Destination",
    destinations: "Destinations",
    details: "Details",
    detailedBudget: "Detailed Budget",
    diastolic: "Diastolic",
    difference: "Difference",
    displayMode: "Display Mode",
    document: "Document",
    documents: "Documents",
    download: "Download",
    dueDate: "Due Date",
    duration: "Duration",
    active: "Active",
    edit: "Edit",
    editAppointment: "Edit Appointment",
    editFolder: "Edit Folder",
    editMode: "Edit Mode",
    editOccurrence: "Edit Occurrence",
    editReminder: "Edit Reminder",
    editSeries: "Edit Series",
    editToDo: "Edit To Do",
    email: "Email",
    enabled: "Enabled",
    enterPassword: "Enter Password",
    enterPin: "Enter your PIN",
    entireSeries: "Entire Series",
    entries: "Entries",
    event: "Multi-Day Event",
    eventsToImport: "events to import",
    every: "Every",
    examples: "Examples",
    exitDashboard: "Exit Dashboard",
    expense: "Expense",
    expenseDate: "Expense Date",
    expenses: "Expenses",
    expired: "Expired",
    externalUrl: "External link",
    false: "False",
    family: "Family",
    familyMember: "Family Member",
    familyMembers: "Family Members",
    familyName: "Family Name",
    faqs: "FAQs",
    fieldLabel: "Field Label",
    fieldName: "Field Name",
    fieldSecurity: "Security",
    fieldsInFolder: "Fields in your Folder",
    fieldType: "Field Types",
    first: "1st",
    folderName: "Folder Name",
    folder: "Folder",
    folders: "Folders",
    forever: "forever",
    forgotPassword: "Forgot Password",
    fourth: "4th",
    frequency: "Frequency",
    from: "From",
    fromDate: "From Date",
    fromToday: "From Today",
    gallery: "Gallery",
    gasLog: "Gas Log",
    gate: "Gate",
    general: "General Info",
    glucose: "Glucose Log",
    gotoToday: "Go to today",
    grocery: "Grocery",
    groceries: "Groceries",
    groceryItems: "Grocery Items",
    groceryLists: "Grocery Lists",
    groceryList: "Grocery List",
    group: "Group",
    heartRate: "Heart Rate",
    help: "Help",
    hidePicked: "Hide picked",
    homework: "Homework",
    homeworkType: "Homework Type",
    home: "Home",
    hours: "Hours",
    household: "Household",
    howLong: "For how long?",
    ignoreChangedOccurrences: "Ignore Changed Occurrences",
    imageAdd: "Add Image",
    imageRemove: "Remove image",
    import: "Import",
    income: "Income",
    info: "Info",
    ingredient: "Ingredient",
    ingredients: "Ingredients",
    installApp: "Install Now!",
    instruction: "Instruction",
    instructions: "Instructions",
    inYourBank: "tokens in your bank!",
    isUser: "Is User",
    itinerary: "Itinerary",
    item: "Item",
    label: "Label",
    last: "Last",
    lastDayOfTrial: "Last day of Trial",
    letsLogin: "Let's Login!",
    list: "List",
    loadEvents: "Load Events",
    location: "Location/City",
    login: "login",
    manageSubscription: "Manage Subscription",
    markReceived: "Mark Received",
    mealItems: "Meal Items",
    mealPlan: "Meal Plan",
    mealPlans: "Meal Plans",
    mealplans: "Meal Plans",
    mealType: "Meal Type",
    members: "Members",
    membersToAdd: "Members to add",
    mileageLog: "Mileage Log",
    minutes: "Minutes",
    mode: "Mode",
    month: "Month",
    monthDates: "Month Dates",
    monthly: "Monthly",
    monthlyType: "Monthly Type",
    move: "Move",
    myDevice: "My Device",
    name: "Name",
    newItem: "New List Item",
    newPassword: "New Password",
    next: "Next",
    no: "No",
    noCategories: "Category Filter (empty)",
    noEnd: "No End Date",
    noneScheduled: "None scheduled",
    notAUser: "Not a User",
    notdone: "Not Done",
    note: "Note",
    notes: "Notes",
    notifications: "Notifications",
    notScheduled: "Not scheduled",
    number: "Number",
    occasion: "Occasion",
    occasions: "Occasions",
    occurrences: "Occurrences",
    odometer: "Odometer",
    once: "Once",
    open: "Open",
    openFolder: "Open",
    or: "Or",
    otherAddress: "Other Address",
    outstanding: "Outstanding",
    pages: "Pages",
    password: "Password",
    passwords: "Passwords",
    periodEnd: "Period End",
    periodStart: "Period Start",
    percentOfFinal: "% of Final",
    personal: "Personal",
    phone: "Phone",
    picture: "Picture",
    pin: "PIN",
    pinReset: "PIN Reset",
    plan: "Plan",
    plateNumber: "Plate Number",
    postal: "ZIP",
    preparationTime: "Prep time",
    province: "State",
    purchaseMembers: "Purchase Members",
    push: "Push Notifications",
    quantity: "Quantity",
    range: "Range",
    rate: "Rate",
    receipt: "Receipt",
    receipts: "Receipts",
    recipe: "Recipe",
    recipes: "Recipes",
    recurrenceSettings: "Recurrence Settings",
    recurring: "Recurring",
    remind: "Remind",
    reminder: "Reminder",
    reminders: "Reminders",
    rename: "Rename",
    reset: "Reset",
    responsible: "Responsible",
    routeNumber: "Route #",
    save: "Save",
    saveToGroceryLists: "Save to Grocery lists",
    saveTokensFor: "Update {name}'s tokens",
    schedule: "Schedule",
    search: "Search",
    second: "2nd",
    security: "Security",
    securityLevel: "Security Level",
    selectAll: "Select All",
    selectFile: "Select File",
    sendCode: "Email Confirmation Code",
    serviceLog: "Service Log",
    servings: "Servings",
    severity: "Severity",
    shop: "Shop",
    shoppingDate: "Shopping Date",
    showAll: "Show All",
    showCancelled: "Show Cancelled",
    showOlder: "Show older trips",
    showPassword: "Show Password",
    signup: "Signup!",
    simpleBudget: "Simple Budget",
    skip: "Skip this step",
    sms: "SMS(text message)",
    spend: "Spend",
    startDate: "Appointment Date & Time",
    startsOn: "Starts on",
    startFromScratch: "Start from scratch",
    streetAddress: "Street Address",
    subject: "Subject",
    submitAnother: "Submit Another Ticket",
    submittedOn: "Submitted On",
    subscription: "Subscription",
    subscriptionEnd: "Subscription End",
    subscriptions: "Subscriptions",
    support: "Support",
    switchToFamilyDashboard: "Switch to Family Dashboard",
    sync: "Sync",
    systolic: "Systolic",
    tags: "Tags",
    teacher: "Teacher/Professor",
    templates: "Templates",
    testLink: "Test Link",
    terminal: "Terminal",
    textNote: "Text Note",
    third: "3rd",
    thisMonth: "This Month",
    thisWeek: "This Week",
    time: "Time",
    to: "To",
    toDate: "To Date",
    today: "Today",
    todo: "To Do",
    todos: "To Dos",
    tokens: "Tokens",
    tokensEarned: "Tokens Earned",
    tokensNeeded: "Tokens Needed",
    tokenObjectiveName: "Working Towards",
    tokenObjectiveUrl: "URL",
    tokenValue: "Token Value",
    tomorrow: "Tomorrow",
    total: "Total",
    totalMileage: "Total Mileage",
    trialEnds: "Trial ends on",
    trial: "In Trial",
    trialing: "In Trial",
    trip: "Trip",
    trips: "Trips",
    true: "True",
    type: "Type",
    until: "Until",
    update: "Update",
    updateAll: "Update All",
    updateFolder: "Update Folder",
    updateFrom: "Update From",
    updateFromStart: "Update From Start",
    updateGrocery: "Update Grocery List",
    updateMealPlan: "Update Plan",
    updateNow: "Update Now",
    updateRecipe: "Update Recipe",
    updateSecurity: "Who can update this field",
    updateToDosFrom: "Update to dos starting from",
    upload: "Upload",
    uploadAvatar: "Take / Upload Picture",
    uploadFile: "Upload File",
    url: "URL",
    userAccount: "User Account",
    userMode: "User Mode",
    username: "Username",
    whatIsAFolder: "What is a folder?",
    view: "View",
    webLogin: "Web Login",
    week: "Week",
    weekDays: "Week Days",
    weekOf: "Week Of",
    weekly: "Weekly",
    year: "Year",
    yearly: "Yearly",
    yes: "Yes",
    yourName: "Your Name",
    ytd: "YTD",
    vehicle: "Vehicle",
    vehicles: "Vehicles",
    version: "Version",
    videos: "Videos",
    viewSecurity: "Who can see this field",
    vinNumber: "VIN",
    volume: "Volume",
    volumeType: "Volume Type",
  },

  text: {
    appleCalendarImport: `To import events from your Apple device tap the "Continue" button below.\n\nYou may be prompted by your device to allow Our Family Dashboard to access your events.\n\nTap allow in order to proceed.`,
    appleContactImport: `To import contacts from your Apple device tap the "Continue" button below.\n\nYou may be prompted by your device to allow Our Family Dashboard to access your contacts.\n\nTap allow in order to proceed.`,
    appleLink: `To manage your subscription, please use Apple Subscriptions on your device`,
    appVersion: `A new version of the app is available.\n\n This update will only take a few seconds and may contain bug fixes and/or new features.\n\n`,
    avatarInstructions: `Move the box around the area you want to use for your avatar.\n\nDrag the corners of the box to resize it.\n\nOnce you have the area selected, tap "save".`,
    noAccount: "Don't have an account?",
    calendarLink: `Provide a link to an external calendar feed and have it automatically updated every four hours, informing you when an event changes.`,
    calendarUpload: `Export items from an external calendars and import them here.\n\nThe file must be a calendar file (.ics).`,
    completeImport: `To complete the import, please tap the "save" menu item.\n\nYour import will be processed in the background and you will be be notified when it is complete.\n\nThis may take a few minutes.`,
    cancelChanges: `You are leaving the page without saving your changes. Do you wish to cancel your changes?`,
    cannotBeChanged: "Cannot be changed after being created",
    confirmClearTokens:
      "This resets the token fields for the selected child.\n\nDo you wish to continue?",
    confirmDelete_appointment_occurrence: `Are you sure you want to delete this appointment?`,
    confirmDelete_appointment_series: `Are you sure you want to delete this appointment from the series?`,
    confirmDelete_budget: `Are you sure you want to delete this budget?`,
    confirmDelete_contact: `Are you sure you want to delete this contact?`,
    confirmDelete_document: `Are you sure you want to delete this document?`,
    confirmDelete_event_occurrence: `Are you sure you want to delete this one day in this mult-day event?`,
    confirmDelete_event_series: `Are you sure you want to delete all days in this multi-day event?`,
    confirmDelete_expense: "Are you sure you want to delete this expense?",
    confirmDelete_groceryList: `Are you sure you want to delete this grocery list?`,
    confirmDelete_homework: `Are you sure you want to delete this homework?`,
    confirmDelete_member: `Are you sure you want to delete this family member? This will permanently remove all data associated with them. THIS CANNOT BE UNDONE!`,
    confirmDelete_note: `Are you sure you want to delete this note?`,
    confirmDelete_password: `Are you sure you want to delete this password?`,
    confirmDelete_recipe: `Are you sure you want to delete this recipe?`,
    confirmDelete_reminder_occurrence: `Are you sure you want to delete this one reminder?`,
    confirmDelete_reminder_series: `Are you sure you want to delete all reminders this series?`,
    confirmDelete_subscription: `Are you sure you want to delete this subscription?`,
    confirmDelete_todo_occurrence: `Are you sure you want to delete this one todo?`,
    confirmDelete_todo_series: `Are you sure you want to delete all todos this series?`,
    confirmDelete_todo: `Are you sure you want to delete this to do?`,
    confirmDelete_trip: `Are you sure you want to delete this trip and all it's data?`,
    confirmDelete_vehicle: `Are you sure you want to delete this vehicle?`,
    confirmDeleteFamilyData: `Proceeding will DELETE ALL YOUR DATA!\n\nThis cannot be undone.\n\nIf you cancel your subscription, your data will be retained for 30 days, after which, it will be automatically deleted.\n\nIf you continue, you will be logged out immediately and your data deleted within the next few minutes.\n\nIt cannot be canceled.\n\nDo you wish to continue deleteing all your data?`,
    confirmDeleteField: `Deleting a field from a folder permanently removes it. You will not be able to recover it. Are you sure you want to delete?`,
    confirmDeleteFolder: `Deleting a folder will delete all of it's fields. You will not be able to recover it. Are you sure you want to delete?`,
    confirmDeleteItem: `After deleting an item you will not be able to recover it. Are you sure you want to delete?`,
    confirmSaveSeries: `Changes have been made to individual appointments in this series. These changes will be lost if you continue. Do you wish to reset the entire series?`,
    contactImportIntro: `Export contacts from your existing app into a .vcf (vCard) format. Transfer the file to this device and then click the 'Select File' button below to import.`,
    createFolder: "Provide a name for your new folder.",
    defaultSecurity: "Default security in page",
    deleteFolder:
      "Deleting a folder will delete all fields and data contained inside. This cannot be recovered. Do you wish to delete?",
    deleteSeries: `You can delete the entire series or just a subset.\n\nUse the following filter to identify what you want to delete.`,
    dontForget: "Don't forget...",
    emptyAsOf: "Leave blank to set for entire year",
    fieldsNone:
      "There are no fields currently assigned to this folder. Use 'Update Folder' in the dropdown menu to add.",
    forgotPassword: `Please provide your username. If you just signed up, use the email address you provided.`,
    googleCalendarImport: `To import events from your Google accounts, tap the "Continue" button below.\n\nYou will be prompted to select an account. Tap the account you wish to import.\n\n`,
    googleContactImport: `To import contacts from your Google account, tap the "Continue" button below.\n\nYou will be prompted to select an account. Tap the account you wish to import.\n\n`,
    iCalImport: `You can import appointments from either a file you exported from another calendar or from a link provided by another subscription like a sports league.\n\nFiles must be in iCal (.ics) format only.`,
    ignoreChangedOccurrences: `If you changed any individual occurences in this series, set this on to ignore those occurrences so as to not override those changes.\n\nTurn this off to refresh all occurrences, overriding any individual changes you may have made.`,
    moveDocument: "Choose which folder you want to move this document to.",
    noFields: "No fields available",
    noEntries: "No entries available",
    noRecurringExpenses: "No recurring expenses have been entered",
    pageSecurity: "Who can access this page?",
    removeField:
      "Removing a field will delete all information within it. This cannot be recovered. Do you wish to remove this field?",
    renameDocument: "Please provide a new name for your document",
    renameFolder: "Please provide a new name for your folder",
    selectAvailableField: "Select a field from the following list...",
    signup: {
      signupForm: "Signup Form",
      checkEmailForCode:
        "Please check your email for your confirmation code.\n\nIf you don't see it in your inbox within the next minute or so, check your spam/junk mail folder.\n\nYou can resend the code if needed.",
      emailRequest: `Please provide a valid email address and tap "Confirm email".\n\nA confirmation code will be sent to this email.\n\nA field will be provided for you to enter this code.\n\nThis process ensures you are a real person and not a bot. We don't serve bots here!`,
      confirmation: `Please enter the confirmation code that was emailed to your email address.`,
      coupon:
        "If you have a coupon code, provide it here to validate and apply,or simply tap Next to continue",
      email: `Please provide your email and password. A confirmation code will be sent to your email. Enter it in the next step.`,
      familyInfo: `Please provide a name for your family and yourself`,
      installation: [
        `Our Family Dashboard can be installed directly from your browser.`,
        `It is not available in app stores at this time.`,
        `Here are two videos (Android and iOS) that explain how to install.`,
        `Please use Safari on iOS.`,
        `It's very easy and after installation, the app will be available on your Home screen.`,
      ],
      payment: `We do not store your payment information on our servers.\n\nClicking on the button below will redirect you to our payment processing partner "Stripe".\n\nOnce you complete your payment transaction, you will be redirected back to our site.`,
      thankyou: "Thank you!",
      thankyouInstructions: `Your 30-day free trial starts now!\n\nWe will be sending you emails over the trial period to help you through all the features found in Our Family Dasboard.\n\nFor any questions please reach out to: {email}`,
      chooseOptions:
        "You can now either install the app on your device or login through the web interface.",
      notAvailable:
        "Sorry, Our Family Dashboard is not available to the public just yet",
      couponCannotBeEmpty:
        "Please provide a coupon code or tap 'Next' to continue.",
    },
    stripeLink: `Manage Subscription will take you to your Our Family Dashboard Stripe portal.\nThere you can cancel your subscription, change your payment details or change your plan`,
    subscriptions_cancel: `Cancelling a subscription will keep all expenses incurred and future expenses until the end date of your subscription.\n\nMake sure the end date is set accurately to ensure that expenses stop at the appropriate time.`,
    subscriptions_hasExpenses: `You have expenses for this subscription.\n\nYou should cancel instead of delete in order to maintain your previous spend on this subscription.\n\nIf you wish to continue, all your expenses for this subscription will be deleted as well.\n\nIf you wish wish to delete this subscription and all of it's related expenses, tap "DELETE".`,
    thingsToDo: "Things to do today...",
    tokenBank_noMembers: "No family members are assigned chores",
    yourAppointments: "Your appointments today...",
  },

  instructions: {
    fieldName: "Provide a custom name for this field...",
    fieldLabel: "Provide a custom label for this field...",
    fieldSecurity:
      "You can customize who can view, update and delete this field...",
    selectFieldType: `Select the type of field you wish to add...`,
  },

  messages: {
    alreadyExists: "Already Exists",
    appointmentDailyMissing: "Date range is missing",
    budgetItemsHaveExpenses:
      "You tried to delete budget items that have expenses assigned to them.",
    budgetHasExpenses:
      "This budget has expenses assign to it. You cannot delete unless you reassign those expenses",
    cancelled: "Cancelled",
    cannotBeEmpty: "Cannot be empty",
    codeResent: "Code resent. Please check your email",
    codeSent: "Confirmation Code Sent",
    calendarItemModified:
      "This calendar item has been modified from the series",
    cannotContainSpaces: "Cannot contain spaces",
    checkoffIngredient:
      "Check off the ingredients you need and add them to a grocery list",
    checkoffInstruction:
      "Check off the steps as you complete them to make sure you haven't missed a step.",
    codeValid: "Code valid!",
    copiedToClipboard: "Copied to clipboard!",
    deleted: "Deleted",
    emailExists: "Email already used",
    errorEncountered: "OOPS! Something went wrong.",
    errorSendingConfirmationCode: "Error sending confirmation code",
    fieldErrors: "Check below for field errors",
    fileUploaded: "File Uploaded",
    folderEmpty:
      "Folder is currently empty. Use 'Update Folder' in dropdown menu to add fields.",
    groceryRequired:
      "Please select a grocery list. If you do not currently have an active list, you will need to create one first",
    isEmpty: "Cannot be empty",
    incorrectPassword: "Password is not correct",
    invalidConfirmationCode: "Confirmation code is invalid",
    invalidCoupon: "Coupon Code is invalid or expired",
    invalidCredentials: "Invalid Credentials",
    invalidData: "Invalid data",
    invalidEmail: "Email is invalid",
    invalidPassword: "Password is invalid",
    invalidPin: "Invalid PIN",
    invalidUsername: "Username is invalid",
    missingData: "There is some missing data. Please fill in all areas",
    missingPin: "You do not have a pin. Please set in My Account",
    mustBeGreaterThanZero: "Must be > 0",
    noActivitiesForToday: "You do not have any activities scheduled for today",
    noAppointmentsThisWeek: "There are no appointments for this week",
    no_appointments_ForToday:
      "You do not have any appointments scheduled for today",
    no_reminders_ForToday: "You do not have any reminders scheduled for today",
    no_todos_ForToday: "You do not have any to do's scheduled for today",
    noFamilyChores: "There are currently no chores assigned to Family Members",
    noChoreApprovals: "There are currently no chores needing approval",
    noChores:
      "No chores have been added yet. Please use the Add Chore icon in the toolbar to start.",
    noChoresForDate: "No chores assigned for date",
    noExpenses: "There are no expenses",
    noEmailRegisteredForUser:
      "Username does not have an email registered. Please check with you account owner.",
    noGroceryLists: "No outstanding grocery lists",
    noGroceryItems: "No grocery items added",
    noHomework: "There is currently no outstanding homework",
    noImages: "No Images",
    noIngredients: "No ingredients provided",
    noInstructions: "No instructions provided",
    noMealPlan: "No meal plan set for this week.",
    noDailyPlan: "No meal plan for today",
    noResults: "No entries found",
    noReceipts: "No Receipts uploaded",
    noRecipesFound: "No Recipes Found",
    notActive: "Your account is not currently active.",
    notificationsNotSupported:
      "Notifications are not supported in your browser version",
    passwordWeak:
      "Please provide a strong password. It must contain at least 8 characters including 1 uppercase, 1 lowercase, 1 number and 1 special character",
    passwordLength: `Password must be at least 8 characters`,
    passwordNeededToChangePin: "Enter your password to save your new PIN",
    passwordReset: "Password has been reset",
    pinReset: "Reset PIN",
    pinResetSuccessful: "PIN has been reset successfuly",
    recording: "Recording...tap mic to stop",
    saved: "Saved",
    selectMonths: "Please select one or more months",
    supportTicketSubmitted:
      "Your ticket has been submitted. We will get back to you as soon as possible. Thank You.",
    tapMicToRecord: "Tap Mic to record",
    toDateCannotBeBeforeFrom: "To Date cannot be before From date",
    usernameExists: "Username already used. Please choose another",
  },

  pages: {
    home: "Home",
    calendar: "Calendar",
    reminders: "Reminders",
    todos: "To Dos",
    members: "Members",
    family: "Family",
    contacts: "Contacts",
    groceries: "Groceries",
    chores: "Chores",
    recipes: "Recipes",
    mealplans: "Meals",
    vehicles: "Vehicles",
    trips: "Trips",
    budgets: "Budgets",
    expenses: "Expenses",
    passwords: "Passwords",
    subscriptions: "Subscriptions",
  },

  fields: {
    allergies: "Allergies",
    bloodPressureLog: "Blood Pressure Log",
    glucoseLog: "Glucose Log",
    textNote: "Text Note",
  },

  lists: {
    allergySeverity: [
      { value: "mild", label: "Mild" },
      { value: "moderate", label: "Moderate" },
      { value: "severe", label: "Severe" },
    ],
    appointmentFrequency: [
      { value: "onetime", label: "One Time" },
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "yearly", label: "Yearly" },
      { value: "schedule", label: "Custom" },
    ],
    attendeeModels: [
      { value: "Member", label: "Member" },
      { value: "Contact", label: "Contact" },
    ],
    budgetFrequency: [
      { value: "yearly", label: "Yearly", alt: "/year" },
      { value: "weekly", label: "Weekly", alt: "/week" },
      { value: "monthly", label: "Monthly", alt: "/month" },
      { value: "specified", label: "Specific Months", alt: " in " },
    ],
    budgetType: [
      { value: "expense", label: "Expense" },
      { value: "income", label: "Income" },
    ],
    calendarFrequency: [
      { value: "onetime", label: "One Time" },
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "yearly", label: "Yearly" },
      { value: "schedule", label: "Custom" },
    ],
    categoryFilterOptions: [
      { value: "all", label: "All" },
      { value: "clear", label: "Clear" },
    ],
    choreFrequency: [
      { value: "onetime", label: "One Time" },
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
    ],
    dailyIntervals: [
      { value: 1, label: "every day" },
      { value: 2, label: "every other day" },
      { value: 3, label: "every 3rd day" },
      { value: 4, label: "every 4th day" },
      { value: 5, label: "every 5th day" },
      { value: 6, label: "every 6th day" },
      { value: 7, label: "every 7th day" },
      { value: 8, label: "every 8th day" },
      { value: 9, label: "every 9th day" },
      { value: 10, label: "every 10th day" },
      { value: 11, label: "every 11th day" },
      { value: 12, label: "every 12th day" },
      { value: 13, label: "every 13th day" },
      { value: 14, label: "every 14th day" },
      { value: 15, label: "every 15th day" },
      { value: 16, label: "every 16th day" },
      { value: 17, label: "every 17th day" },
      { value: 18, label: "every 18th day" },
      { value: 19, label: "every 19th day" },
      { value: 20, label: "every 20th day" },
      { value: 21, label: "every 21st day" },
      { value: 22, label: "every 22nd day" },
      { value: 23, label: "every 23rd day" },
      { value: 24, label: "every 24th day" },
      { value: 25, label: "every 25th day" },
      { value: 26, label: "every 26th day" },
      { value: 27, label: "every 27th day" },
      { value: 28, label: "every 28th day" },
      { value: 29, label: "every 29th day" },
      { value: 30, label: "every 30th day" },
    ],
    dayPositions: [
      { value: "dow_0_1", label: "1st Sunday" },
      { value: "dow_1_1", label: "1st Monday" },
      { value: "dow_2_1", label: "1st Tuesday" },
      { value: "dow_3_1", label: "1st Wednesday" },
      { value: "dow_4_1", label: "1st Thursday" },
      { value: "dow_5_1", label: "1st Friday" },
      { value: "dow_6_1", label: "1st Saturday" },
      { value: "dow_0_2", label: "2nd Sunday" },
      { value: "dow_1_2", label: "2nd Monday" },
      { value: "dow_2_2", label: "2nd Tuesday" },
      { value: "dow_3_2", label: "2nd Wednesday" },
      { value: "dow_4_2", label: "2nd Thursday" },
      { value: "dow_5_2", label: "2nd Friday" },
      { value: "dow_6_2", label: "2nd Saturday" },
      { value: "dow_0_3", label: "3rd Sunday" },
      { value: "dow_1_3", label: "3rd Monday" },
      { value: "dow_2_3", label: "3rd Tuesday" },
      { value: "dow_3_3", label: "3rd Wednesday" },
      { value: "dow_4_3", label: "3rd Thursday" },
      { value: "dow_5_3", label: "3rd Friday" },
      { value: "dow_6_3", label: "3rd Saturday" },
      { value: "dow_0_4", label: "4th Sunday" },
      { value: "dow_1_4", label: "4th Monday" },
      { value: "dow_2_4", label: "4th Tuesday" },
      { value: "dow_3_4", label: "4th Wednesday" },
      { value: "dow_4_4", label: "4th Thursday" },
      { value: "dow_5_4", label: "4th Friday" },
      { value: "dow_6_4", label: "4th Saturday" },
      { value: "dow_0_-1", label: "Last Sunday" },
      { value: "dow_1_-1", label: "Last Monday" },
      { value: "dow_2_-1", label: "Last Tuesday" },
      { value: "dow_3_-1", label: "Last Wednesday" },
      { value: "dow_4_-1", label: "Last Thursday" },
      { value: "dow_5_-1", label: "Last Friday" },
      { value: "dow_6_-1", label: "Last Saturday" },
    ],
    days: [
      { value: "dow_0", label: "Sunday", short: "SU" },
      { value: "dow_1", label: "Monday", short: "MO" },
      { value: "dow_2", label: "Tuesday", short: "TU" },
      { value: "dow_3", label: "Wednesday", short: "WE" },
      { value: "dow_4", label: "Thursday", short: "TH" },
      { value: "dow_5", label: "Friday", short: "FR" },
      { value: "dow_6", label: "Saturday", short: "SA" },
    ],
    expenseFrequency: [
      { value: "onetime", label: "One Time" },
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "biweekly", label: "Bi-Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "schedule", label: "Custom" },
    ],
    fieldSecurityLevels: [
      { value: "family", label: "Family" },
      { value: "adult", label: "Adult" },
      { value: "admin", label: "Administrator" },
      { value: "accountOwner", label: "Account Owner" },
      { value: "members", label: "Members" },
      { value: "addedBy", label: "User Who Added Entry" },
    ],
    homeworkType: [
      { value: "assignment", label: "Assignment" },
      { value: "test", label: "Test" },
      { value: "exam", label: "Exam" },
      { value: "final", label: "Final" },
    ],
    mealTypes: [
      { value: "breakfast", label: "Breakfast" },
      { value: "lunch", label: "Lunch" },
      { value: "appetizer", label: "Appetizer" },
      { value: "dinner", label: "Dinner" },
      { value: "dessert", label: "Dessert" },
      { value: "snack", label: "Snack" },
    ],
    memberFilterOptions: [
      { value: "all", label: "All" },
      { value: "clear", label: "Clear" },
      { value: "me", label: "Just Me" },
    ],
    monthDates: [
      { value: 1, label: "1st" },
      { value: 2, label: "2nd" },
      { value: 3, label: "3rd" },
      { value: 4, label: "4th" },
      { value: 5, label: "5th" },
      { value: 6, label: "6th" },
      { value: 7, label: "7th" },
      { value: 8, label: "8th" },
      { value: 9, label: "9th" },
      { value: 10, label: "10th" },
      { value: 11, label: "11th" },
      { value: 12, label: "12th" },
      { value: 13, label: "13th" },
      { value: 14, label: "14th" },
      { value: 15, label: "15th" },
      { value: 16, label: "16th" },
      { value: 17, label: "17th" },
      { value: 18, label: "18th" },
      { value: 19, label: "19th" },
      { value: 20, label: "20th" },
      { value: 21, label: "21st" },
      { value: 22, label: "22nd" },
      { value: 23, label: "23rd" },
      { value: 24, label: "24th" },
      { value: 25, label: "25th" },
      { value: 26, label: "26th" },
      { value: 27, label: "27th" },
      { value: 28, label: "28th" },
      { value: 29, label: "29th" },
      { value: 30, label: "30th" },
      { value: 31, label: "31st" },
    ],
    monthlyIntervals: [
      { value: 1, label: "every month" },
      { value: 2, label: "every other month" },
      { value: 3, label: "every 3rd month" },
      { value: 4, label: "every 4th month" },
      { value: 5, label: "every 5th month" },
      { value: 6, label: "every 6th month" },
      { value: 7, label: "every 7th month" },
      { value: 8, label: "every 8th month" },
      { value: 9, label: "every 9th month" },
      { value: 10, label: "every 10th month" },
      { value: 11, label: "every 11th month" },
      { value: 12, label: "every 12th month" },
      { value: 18, label: "every 18th month" },
      { value: 24, label: "every 24th month" },
      { value: 36, label: "every 36th month" },
      { value: 48, label: "every 48th month" },
    ],
    monthlyTypes: [
      { value: "specific", label: "Specific Dates" },
      { value: "relative", label: "Relative Days" },
    ],
    months: [
      { value: 0, label: "January", short: "Jan" },
      { value: 1, label: "February", short: "Feb" },
      { value: 2, label: "March", short: "Mar" },
      { value: 3, label: "April", short: "Apr" },
      { value: 4, label: "May", short: "May" },
      { value: 5, label: "June", short: "Jun" },
      { value: 6, label: "July", short: "Jul" },
      { value: 7, label: "August", short: "Aug" },
      { value: 8, label: "September", short: "Sep" },
      { value: 9, label: "October", short: "Oct" },
      { value: 10, label: "November", short: "Nov" },
      { value: 11, label: "December", short: "Dec" },
    ],
    occasionTypes: [
      { value: "anniversary", label: "Anniversary" },
      { value: "birthday", label: "Birthday" },
    ],
    recurrenceCount: [
      { value: "forever", label: "Forever" },
      { value: "until", label: "Until" },
      { value: "count", label: "Occurrences" },
    ],
    reminderFrequency: [
      { value: "onetime", label: "One Time" },
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
    ],
    reminderDuration: [
      { value: "days", label: "Days" },
      { value: "hours", label: "Hours" },
      { value: "minutes", label: "Minutes" },
    ],
    securityLevels: [
      { value: "child", label: "Child" },
      { value: "teen", label: "Teen" },
      { value: "adult", label: "Adult" },
      { value: "admin", label: "Administrator" },
      { value: "accountOwner", label: "Account Owner" },
    ],
    subscriptionFrequency: [
      { value: "monthly", label: "Monthly" },
      { value: "yearly", label: "Yearly" },
    ],
    ticketStatus: [
      { value: "open", label: "Open" },
      { value: "closed", label: "Closed" },
    ],
    transportationModes: [
      { value: "airplane", label: "Airplane" },
      { value: "train", label: "Train" },
      { value: "bus", label: "Bus" },
      { value: "ship", label: "Ship" },
      { value: "car", label: "Car" },
      { value: "other", label: "Other" },
    ],
    volumeTypes: [
      { value: "litres", label: "Liters" },
      { value: "gallons", label: "Gallons" },
    ],
    weeklyIntervals: [
      { value: 1, label: "every week" },
      { value: 2, label: "every other week" },
      { value: 3, label: "every 3rd week" },
      { value: 4, label: "every 4th week" },
      { value: 5, label: "every 5th week" },
      { value: 6, label: "every 6th week" },
      { value: 7, label: "every 7th week" },
      { value: 8, label: "every 8th week" },
      { value: 9, label: "every 9th week" },
      { value: 10, label: "every 10th week" },
      { value: 11, label: "every 11th week" },
      { value: 12, label: "every 12th week" },
      { value: 13, label: "every 13th week" },
      { value: 14, label: "every 14th week" },
      { value: 15, label: "every 15th week" },
      { value: 16, label: "every 16th week" },
      { value: 17, label: "every 17th week" },
      { value: 18, label: "every 18th week" },
      { value: 19, label: "every 19th week" },
      { value: 20, label: "every 20th week" },
      { value: 21, label: "every 21st week" },
      { value: 22, label: "every 22nd week" },
      { value: 23, label: "every 23rd week" },
      { value: 24, label: "every 24th week" },
      { value: 25, label: "every 25th week" },
      { value: 26, label: "every 26th week" },
    ],
    yearlyIntervals: [
      { value: 1, label: "every year" },
      { value: 2, label: "every other year" },
      { value: 3, label: "every 3rd year" },
      { value: 4, label: "every 4th year" },
      { value: 5, label: "every 5th year" },
      { value: 6, label: "every 6th year" },
      { value: 7, label: "every 7th year" },
      { value: 8, label: "every 8th year" },
      { value: 9, label: "every 9th year" },
      { value: 10, label: "every 10th year" },
    ],
  },

  dashboards: {
    title: "Dashboards",
    options: [
      { value: "currentActivities", label: "Current Activities" },
      { value: "childDashboard", label: "Child Dashboard" },
    ],
  },

  calendarLists: {
    weekdays: [
      { value: 0, label: "Sunday", short: "Sun", letter: "S" },
      { value: 1, label: "Monday", short: "Mon", letter: "M" },
      { value: 2, label: "Tuesday", short: "Tue", letter: "T" },
      { value: 3, label: "Wednesday", short: "Wed", letter: "W" },
      { value: 4, label: "Thursday", short: "Thu", letter: "T" },
      { value: 5, label: "Friday", short: "Fri", letter: "F" },
      { value: 6, label: "Saturday", short: "Sat", letter: "S" },
    ],
    months: [
      { value: 0, label: "January", short: "Jan" },
      { value: 1, label: "February", short: "Feb" },
      { value: 2, label: "March", short: "Mar" },
      { value: 3, label: "April", short: "Apr" },
      { value: 4, label: "May", short: "May" },
      { value: 5, label: "June", short: "Jun" },
      { value: 6, label: "July", short: "Jul" },
      { value: 7, label: "August", short: "Aug" },
      { value: 8, label: "September", short: "Sep" },
      { value: 9, label: "October", short: "Oct" },
      { value: 10, label: "November", short: "Nov" },
      { value: 11, label: "December", short: "Dec" },
    ],
  },

  help: {
    whatIsAFolder: {
      title: "What is a Folder?",
      definition: [
        "A folder is a place where you store related pieces of information.",
        "Folders are fully customizable, so create them to suite your unique needs.",
        "Pick the fields you want and customize their labels to describe what they contain.",
        "Add as many fields as you need.",
      ],
      examples: [
        "Create a Medical folder to store all your medical information such as medications, allergies, blood pressure log and more",
        "Create a Soccer folder to store all the practices, games and tournaments.",
      ],
    },
  },

  wizards: {
    budget: {
      budgetFrequency: {},
      budgetIntro: [
        "Welcome to the Our Family Dashboard Budgeting System!",
        "This wizard will step you through setting up your budgets so you can track and manage your household expenses.",
        "Create a budget for your household expenses, including groceries, gas, utilities, subscriptions etc.",
        "Create project budgets for renovations and one-time projects.",
        "Assign a budget amount to each budget item so you can manage your expenses.",
        "After you create your budget(s), you can add expenses to them, including uploading of receipts.",
        "A budget analysis will then be available to track your expenses against your budget items.",
        "Tap the Next button to get started. Don't worry, we will take you through each step and expain what you need to do.",
      ],
      budgetItems: {
        title: "Budget Items",
      },
      copy: {
        title: "Start From a Previous Budget",
        instructions: [
          `If you have budgets from a previous year, you can start with a copy of it and change from there.`,
        ],
      },
      template: {
        title: "Start with a Template",
        instructions: [`Pick one of our standard templates.`],
      },
      name: {
        title: "Budget Name",
        instructions: [
          "Give your budget a name that represents the type of expenses that will be tracked.",
          "For example, 'Household', 'Cottage', 'Kitchen Reno', etc.",
          "Individual items such as grocery, gas etc., will be added in a following step",
        ],
      },
      budgetSecurity: {
        instructions: [
          "Select who in your family can view, update and delete this budget",
        ],
      },
    },
    folder: {
      folderIntro: [
        "Not sure what to do? Don't worry, we will take you through step by step to create new folder.",
        "The steps are quite simple.",
        "First, you will pick from a list of available fields that you can add to a folder for a {entityName}",
        "You will be able to add as many fields as you like. Some fields allow you to customize your own label.",
        "You will be able to add security to each field that controls who can see, update and/or delete each field.",
        "Then you will give the folder a name like 'Medical', 'Music Lessons', 'Soccer', etc.",
        "When you are ready, tap the 'NEXT' button to get started!",
      ],
      availableFields: {
        fieldLabel: "How do you want to label this field?",
        fieldSecurity: "What level of access do you want on this field?",
      },
      folderName: {
        instructions: [
          "Give your folder a name that represents the fields you have added.",
          "For example, 'Medical', 'Lessons', 'Notes'",
        ],
      },
      folderSecurity: {
        instructions: [
          "Select who in your family can view, update and delete this folder",
        ],
      },
    },
    grocery: {
      groceryIntro: [
        "Welcome to the Our Family Dashboard Grocery wizard!",
        "This wizard will step you through setting up your grocery lists and assigning them to a budget",
        "Create a grocery lists by retailer or by occasion",
        "Pick items from our grocery list template or simply enter them.",
        "Track your grocery spend by assigning your grocery bill to a budget and upload your receipt.",
        "Tap the Next button to get started. Don't worry, we will take you through each step and explain what you need to do.",
      ],
      groceryItems: {
        title: "Grocery Items",
      },
      name: {
        title: "Grocery Name",
        instructions: [
          "Give your grocery list a name such as the retailer or the occasion you are shopping for.",
          "For example, 'Whole Foods', 'Costco', 'Cottage', 'Birthday Party', etc.",
        ],
      },
      copy: {
        title: "Copy from previous list",
        instructions: [
          "You can start with a previous grocery list.",
          "If you select a previous list, you can change it in the next step.",
          "Select from the list below or skip this step and start from scratch.",
        ],
      },
      shoppingDate: {
        title: "Shopping Date",
        instructions: [
          "You can assign a date when you plan to shop this list.",
          "You can leave it blank and/or assign it later.",
        ],
      },
      budgetItem: {
        title: "Budget Item",
        instructions: [
          "Assign this grocery list to an item in your budget.",
          "You can do this later if you wish",
        ],
      },
      grocerySecurity: {
        instructions: [
          "Select who in your family can view, update and delete this grocery list",
        ],
      },
    },
    mealPlan: {
      mealPlanIntro: [
        "Welcome to the Our Family Dashboard Meal Planner!",
        "Plan your daily meals ahead of time and add needed items to your grocery list",
      ],
      weekOf: {
        title: "Week of Meal Plan",
        instructions: ["Select the week this plan is for"],
      },
      mealplanSecurity: {
        instructions: [
          "Select who in your family can view, update and delete this meal plan",
        ],
      },
    },
    recipe: {
      recipeIntro: [
        "Welcome to the Our Family Dashboard Recipes!",
        "You can enter your own recipes or provide a link to your favorites from the web.",
      ],
      name: {
        title: "Recipe Name",
        instructions: ["Give your recipe a name."],
      },
      ingredients: {
        title: "Ingredients",
      },
      instructions: {
        title: "Instructions",
      },
      preparation: {
        title: "Preparation",
      },
      image: {
        title: "Recipe Image",
        instructions: ["Upload or take a picture of the dish"],
      },
      externalUrl: {
        title: "Recipe External Link",
        instructions: [
          "Provide a link to an external recipe site.",
          "This allows you to combine all your favorite recipes in one place.",
        ],
      },
      recipeSecurity: {
        instructions: [
          "Select who in your family can view, update and delete this recipe",
        ],
      },
    },
    setup: {
      intro: {
        title: "Welcome!",
        text: [
          `We are going to help you get started quickly by taking you through a few important steps.`,
          `We will also explain the core components and how they work.`,
          `Please take a few minutes to step through this wizard.`,
          `This wizard opens automatically on your first login only. You return here by using the "Setup"
          option in the header menu`,
        ],
      },
      avatarAndColor: {
        title: "Avatar and Colour",
        text: [
          "Let's start with uploading your avatar and assigning you a color",
        ],
      },
      notifications: {
        title: "Notifications",
        text: [
          "One of the core features of Our Family Dashboard is to setup reminders.",
          "Choose one or more ways you wish to receive reminder notifications.",
        ],
      },
      familyMembers: {
        title: "Family Members",
        text: [`Now let's get your family members added.`],
        labels: {
          addFamilyMember: "Add Family Member",
        },
      },
      memberAccounts: {
        title: "Member Login Accounts",
        text: [
          `We now want to setup logins for any family member you want to access Our Family Dashboard directly.`,
        ],
      },
      final: {
        title: "What's Next?",
        text: [
          `You are all set to use Our Family Dashboard.`,
          `Use Help in the System Menu to find answers to common questions, watch a video, or submit a support ticket.`,
          `You can return to this Setup Wizard anytime by tapping Setup in the System menu.`,
          `The System Menu can be found by tapping the hamburger icon in the upper right of the app.`,
        ],
      },
    },
  },

  grocery: {
    departments: [
      "Vegetables",
      "Fruit",
      "Frozen",
      "Condiments",
      "Canned",
      "Spices & Herbs",
      "Dairy",
      "Cheese",
      "Meat",
      "Seafood",
      "Beverages",
      "Alcohol",
      "Bakery",
      "Baking",
      "Snacks",
      "Baby",
      "Pets",
      "Personal",
      "Medicine",
      "Kitchen",
      "Cleaning",
      "Various",
    ],

    items: [
      { department: "Vegetables", item: "Asparagus" },
      { department: "Vegetables", item: "Broccoli" },
      { department: "Vegetables", item: "Carrots" },
      { department: "Vegetables", item: "Cauliflower" },
      { department: "Vegetables", item: "Celery" },
      { department: "Vegetables", item: "Corn" },
      { department: "Vegetables", item: "Cucumbers" },
      { department: "Vegetables", item: "Lettuce " },
      { department: "Vegetables", item: "Mushrooms" },
      { department: "Vegetables", item: "Onions" },
      { department: "Vegetables", item: "Peppers" },
      { department: "Vegetables", item: "Potatoes" },
      { department: "Vegetables", item: "Spinach" },
      { department: "Vegetables", item: "Squash" },
      { department: "Vegetables", item: "Tomatoes" },
      { department: "Vegetables", item: "Zucchini" },

      { department: "Fruit", item: "Apples" },
      { department: "Fruit", item: "Avocados" },
      { department: "Fruit", item: "Bananas" },
      { department: "Fruit", item: "Berries" },
      { department: "Fruit", item: "Cherries" },
      { department: "Fruit", item: "Grapefruit" },
      { department: "Fruit", item: "Grapes" },
      { department: "Fruit", item: "Kiwis" },
      { department: "Fruit", item: "Lemons " },
      { department: "Fruit", item: "Limes" },
      { department: "Fruit", item: "Melon" },
      { department: "Fruit", item: "Oranges" },
      { department: "Fruit", item: "Peaches" },
      { department: "Fruit", item: "Nectarines" },
      { department: "Fruit", item: "Pears" },
      { department: "Fruit", item: "Plums" },

      { department: "Frozen", item: "Fries" },
      { department: "Frozen", item: "Ice cream" },
      { department: "Frozen", item: "Juice concentrate" },
      { department: "Frozen", item: "Pizza" },
      { department: "Frozen", item: "Popsicles" },
      { department: "Frozen", item: "Sorbet" },
      { department: "Frozen", item: "TV dinners" },
      { department: "Frozen", item: "Vegetables" },

      { department: "Condiments", item: "BBQ sauce" },
      { department: "Condiments", item: "Gravy" },
      { department: "Condiments", item: "Honey" },
      { department: "Condiments", item: "Hot sauce" },
      { department: "Condiments", item: "Jam" },
      { department: "Condiments", item: "Jelly" },
      { department: "Condiments", item: "Ketchup " },
      { department: "Condiments", item: "Mustard" },
      { department: "Condiments", item: "Mayonnaise" },
      { department: "Condiments", item: "Pasta sauce" },
      { department: "Condiments", item: "Relish" },
      { department: "Condiments", item: "Salad dressing" },
      { department: "Condiments", item: "Salsa" },
      { department: "Condiments", item: "Soy sauce" },
      { department: "Condiments", item: "Steak sauce" },
      { department: "Condiments", item: "Syrup" },
      { department: "Condiments", item: "Worcestershire " },

      { department: "Canned", item: "Applesauce" },
      { department: "Canned", item: "Baked beans" },
      { department: "Canned", item: "Chili" },
      { department: "Canned", item: "Fruit" },
      { department: "Canned", item: "Olives" },
      { department: "Canned", item: "Tuna" },
      { department: "Canned", item: "Soup" },
      { department: "Canned", item: "Tomatoes" },
      { department: "Canned", item: "Veggies" },

      { department: "Spices & Herbs", item: "Basil" },
      { department: "Spices & Herbs", item: "Black pepper" },
      { department: "Spices & Herbs", item: "Cilantro" },
      { department: "Spices & Herbs", item: "Cinnamon" },
      { department: "Spices & Herbs", item: "Garlic" },
      { department: "Spices & Herbs", item: "Ginger" },
      { department: "Spices & Herbs", item: "Mint" },
      { department: "Spices & Herbs", item: "Oregano" },
      { department: "Spices & Herbs", item: "Paprika" },
      { department: "Spices & Herbs", item: "Parsley" },
      { department: "Spices & Herbs", item: "Red pepper" },
      { department: "Spices & Herbs", item: "Salt" },
      { department: "Spices & Herbs", item: "Spice mix" },
      { department: "Spices & Herbs", item: "Vanilla" },

      { department: "Dairy", item: "Butter" },
      { department: "Dairy", item: "Coffee Cream" },
      { department: "Dairy", item: "Cottage cheese" },
      { department: "Dairy", item: "Cream cheese" },
      { department: "Dairy", item: "Eggs" },
      { department: "Dairy", item: "Half & half" },
      { department: "Dairy", item: "Margarine" },
      { department: "Dairy", item: "Milk" },
      { department: "Dairy", item: "Table Cream" },
      { department: "Dairy", item: "Sour cream" },
      { department: "Dairy", item: "Whipped cream" },
      { department: "Dairy", item: "Yogurt" },

      { department: "Cheese", item: "Bleu cheese" },
      { department: "Cheese", item: "Cheddar" },
      { department: "Cheese", item: "Feta" },
      { department: "Cheese", item: "Goat cheese" },
      { department: "Cheese", item: "Mozzarella" },
      { department: "Cheese", item: "Provolone" },
      { department: "Cheese", item: "Parmesan" },
      { department: "Cheese", item: "Ricotta" },
      { department: "Cheese", item: "Sandwich slices" },
      { department: "Cheese", item: "Swiss" },

      { department: "Meat", item: "Bacon" },
      { department: "Meat", item: "Sausage" },
      { department: "Meat", item: "Beef" },
      { department: "Meat", item: "Chicken" },
      { department: "Meat", item: "Ground beef" },
      { department: "Meat", item: "Ground chicken" },
      { department: "Meat", item: "Ground turkey" },
      { department: "Meat", item: "Ham" },
      { department: "Meat", item: "Pork" },
      { department: "Meat", item: "Hot dogs" },
      { department: "Meat", item: "Lunchmeat" },
      { department: "Meat", item: "Turkey" },

      { department: "Seafood", item: "Catfish" },
      { department: "Seafood", item: "Crab" },
      { department: "Seafood", item: "Lobster" },
      { department: "Seafood", item: "Mussels" },
      { department: "Seafood", item: "Oysters" },
      { department: "Seafood", item: "Salmon" },
      { department: "Seafood", item: "Shrimp" },
      { department: "Seafood", item: "Tilapia" },
      { department: "Seafood", item: "Tuna" },
      { department: "Seafood", item: "Scallops" },
      { department: "Seafood", item: "Haddock/Halibut" },

      { department: "Beverages", item: "Club soda" },
      { department: "Beverages", item: "Juice" },
      { department: "Beverages", item: "Pop/Soda" },
      { department: "Beverages", item: "Sports drink" },

      { department: "Alcohol", item: "Beer" },
      { department: "Alcohol", item: "Champagne" },
      { department: "Alcohol", item: "Gin" },
      { department: "Alcohol", item: "Mixers" },
      { department: "Alcohol", item: "Red wine" },
      { department: "Alcohol", item: "White wine" },
      { department: "Alcohol", item: "Rum" },
      { department: "Alcohol", item: "Saké" },
      { department: "Alcohol", item: "Whiskey" },
      { department: "Alcohol", item: "Vodka" },
      { department: "Alcohol", item: "Scotch" },

      { department: "Bakery", item: "Bagels" },
      { department: "Bakery", item: "Croissants" },
      { department: "Bakery", item: "Buns" },
      { department: "Bakery", item: "Cake" },
      { department: "Bakery", item: "Cookies" },
      { department: "Bakery", item: "Donuts" },
      { department: "Bakery", item: "Pastries" },
      { department: "Bakery", item: "Bread" },
      { department: "Bakery", item: "Pie" },
      { department: "Bakery", item: "Pita" },

      { department: "Baking", item: "Baking powder" },
      { department: "Baking", item: "Baking soda" },
      { department: "Baking", item: "Bread crumbs" },
      { department: "Baking", item: "Cake mix" },
      { department: "Baking", item: "Brownie mix" },
      { department: "Baking", item: "Cake icing" },
      { department: "Baking", item: "Chocolate chips" },
      { department: "Baking", item: "Cocoa" },
      { department: "Baking", item: "Flour" },
      { department: "Baking", item: "Shortening" },
      { department: "Baking", item: "Sugar" },
      { department: "Baking", item: "Yeast" },

      { department: "Snacks", item: "Candy" },
      { department: "Snacks", item: "Gum" },
      { department: "Snacks", item: "Cookies" },
      { department: "Snacks", item: "Crackers" },
      { department: "Snacks", item: "Dried fruit" },
      { department: "Snacks", item: "Granola bars" },
      { department: "Snacks", item: "Nuts" },
      { department: "Snacks", item: "Oatmeal" },
      { department: "Snacks", item: "Popcorn" },
      { department: "Snacks", item: "Potato chips" },
      { department: "Snacks", item: "Pretzels" },

      { department: "Baby", item: "Baby food" },
      { department: "Baby", item: "Diapers" },
      { department: "Baby", item: "Formula" },
      { department: "Baby", item: "Lotion" },
      { department: "Baby", item: "Baby wash" },
      { department: "Baby", item: "Wipes" },

      { department: "Pets", item: "Canned food" },
      { department: "Pets", item: "Dry food" },
      { department: "Pets", item: "Treats" },
      { department: "Pets", item: "Kitty Litter" },
      { department: "Pets", item: "Pet Shampoo" },

      { department: "Personal", item: "Antiperspirant" },
      { department: "Personal", item: "Deodorant" },
      { department: "Personal", item: "Bath soap" },
      { department: "Personal", item: "Hand soap" },
      { department: "Personal", item: "Condoms " },
      { department: "Personal", item: "Cosmetics" },
      { department: "Personal", item: "Cotton swabs" },
      { department: "Personal", item: "Facial cleanser" },
      { department: "Personal", item: "Facial tissue" },
      { department: "Personal", item: "Feminine  products" },
      { department: "Personal", item: "Floss" },
      { department: "Personal", item: "Hair gel / Spray" },
      { department: "Personal", item: "Lip balm" },
      { department: "Personal", item: "Moisturizing lotion" },
      { department: "Personal", item: "Mouthwash" },
      { department: "Personal", item: "Razors" },
      { department: "Personal", item: "Shaving cream" },
      { department: "Personal", item: "Shampoo" },
      { department: "Personal", item: "Conditioner" },
      { department: "Personal", item: "Sunblock" },
      { department: "Personal", item: "Toilet paper" },
      { department: "Personal", item: "Toothpaste" },
      { department: "Personal", item: "Vitamins " },

      { department: "Medicine", item: "Allergy" },
      { department: "Medicine", item: "Antibiotic" },
      { department: "Medicine", item: "Antidiarrheal" },
      { department: "Medicine", item: "Aspirin" },
      { department: "Medicine", item: "Antacid" },
      { department: "Medicine", item: "Band-aids" },
      { department: "Medicine", item: "Cold / Flu / Sinus" },
      { department: "Medicine", item: "Pain reliever" },
      { department: "Medicine", item: "Prescription" },

      { department: "Kitchen", item: "Aluminum foil" },
      { department: "Kitchen", item: "Napkins" },
      { department: "Kitchen", item: "Non-stick spray" },
      { department: "Kitchen", item: "Paper towels" },
      { department: "Kitchen", item: "Plastic wrap" },
      { department: "Kitchen", item: "Sandwich bags" },
      { department: "Kitchen", item: "Freezer bags" },
      { department: "Kitchen", item: "Wax paper" },
      { department: "Kitchen", item: "Parchment paper" },

      { department: "Cleaning", item: "Air freshener" },
      { department: "Cleaning", item: "Bathroom cleaner" },
      { department: "Cleaning", item: "Bleach" },
      { department: "Cleaning", item: "Detergent" },
      { department: "Cleaning", item: "Dish soap" },
      { department: "Cleaning", item: "Dishwasher soap" },
      { department: "Cleaning", item: "Garbage bags" },
      { department: "Cleaning", item: "Glass cleaner" },
      { department: "Cleaning", item: "Sponges" },

      { department: "Various", item: "Bouillon" },
      { department: "Various", item: "Cereal" },
      { department: "Various", item: "Coffee" },
      { department: "Various", item: "Coffee filters" },
      { department: "Various", item: "Tea" },
      { department: "Various", item: "Lemon juice" },
      { department: "Various", item: "Lime juice" },
      { department: "Various", item: "Mac & cheese" },
      { department: "Various", item: "Olive oil" },
      { department: "Various", item: "Pancake mix" },
      { department: "Various", item: "Pasta" },
      { department: "Various", item: "Peanut butter" },
      { department: "Various", item: "Pickles" },
      { department: "Various", item: "Rice" },
      { department: "Various", item: "Vegetable oil" },
      { department: "Various", item: "Vinegar" },
    ],
  },

  subscription: {
    status: {
      active: "Active",
      normal: "Active",
      trialing: "Trialing",
    },
  },

  terms: {
    content: [
      {
        type: "title",
        text: [`Terms of Use`],
      },
      {
        type: "sectionTitle",
        text: [`Introduction`],
      },
      {
        type: "text",
        text: [
          `Please read these Terms of Use (these "Terms") carefully as they govern your use of 
        (which includes access to) Our Family Dashboard's personalized services for 
        managing your family, including all modules current and future (collectively, the "Our Family Dashboard Service").
        `,
          `Use of the Our Family Dashboard Service may be subject to additional terms and conditions presented by Dashboards For Life Inc, 
           which are hereby incorporated by this reference into these Terms.`,
          `By signing up for the Our Family Dashboard Service, you agree to these Terms. 
          If you do not agree to these Terms, then you must not use the Our Family Dashboard Service.
        `,
        ],
      },
      {
        type: "subTitle",
        text: [`Service Provider`],
      },
      {
        type: "text",
        text: [
          `These Terms are between you and Dashboards For Life Inc, of Barrie, Ontario, Canada`,
        ],
      },
      {
        type: "subTitle",
        text: [`Age and eligibility requirements`],
      },
      {
        type: "text",
        text: [
          `In order to subscribe to the Our Family Dashboard Service, you need to have the power to 
          enter a binding contract with us and not be barred from doing so under any applicable laws, 
          and reside in a country where the Service is available. You also promise that any registration 
          information that you submit to Dashboards For Life is true, accurate, and complete, 
          and you agree to keep it that way at all times.`,
        ],
      },
      {
        type: "sectionTitle",
        text: [`The Our Family Dashboard Service Provided By Us`],
      },
      {
        type: "subTitle",
        text: [`Our Family Dashboard Service Options`],
      },
      {
        type: "text",
        text: [
          `We provide numerous Our Family Dashboard Service options. Our Family Dashboard Service 
          options require payment before they can be accessed. We may also offer special promotional plans, 
          memberships, or services.`,
        ],
      },
      {
        type: "subTitle",
        text: [`Trial Period`],
      },
      {
        type: "text",
        text: [
          `When signup for the Our Family Dashboard Service, you are entitled to a 14 day trial 
          during which you may use all the features available in the tier that you have signed up for. 
          During this 14 day period you may cancel at any time without any payment. Once the 14 day 
          trial has lapsed, your credit card will automatically be billed based on the plan and 
          frequency that you have signup for.`,
          `If you cancel during the trial period, all the data you provided will be deleted and 
          you will not be able to recover this data should you decide to signup at a later date.`,
        ],
      },
    ],
  },

  advisorTerms: {
    content: [
      {
        type: "title",
        text: [`Advisor Agreement`],
      },
      {
        type: "sectionTitle",
        text: [`Introduction`],
      },
      {
        type: "text",
        text: [
          `As an Advisor, you will be required to provide feedback and recommendations on the use of 
          Our Family Dashboard.
          `,
          `This feedback may be used to improve the user experience and/or add new features to Our Family Dashboard.`,
          "As an Advisor you will receive free access to the Premium plan for as long as you are an advisor.",
          `Dashboards For Life has the right to remove your status as an Advisor at anytime for any reason.`,
          `You are required to answer surveys presented to you by Dashboards For Life. Surveys presented will have an expiry date
          attached to them. Not responding to these surveys may result in removing your status as an Advisor.`,
          `Should you decide to no longer be an Advisor or Dashboards For Life has removed your Advisor status, 
          you will have the option of paying for a subscription to continue usage or cancel completely at which time all of your
          information will be deleted.`,
          `All feedback provided by you is wholly owned by Dashboards For Life and may be used in anyway. Dashboards For Life
          retains all rights to all feedback and can be used without restriction or payment.`,
          `All feedback must be professional, constructive and respectful.`,
          `We are excited to have you as an Advisor and look forward to your assistance in continually improving our service offerings.`,
        ],
      },
    ],
  },

  categories: [
    { name: "appointment", label: "Appointment" },
    { name: "babysitting", label: "Baby" },
    { name: "bar", label: "Bar" },
    { name: "bank", label: "Bank" },
    { name: "baseball", label: "Baseball" },
    { name: "basketball", label: "Basketball" },
    { name: "bbq", label: "BBQ" },
    { name: "cafe", label: "Cafe" },
    { name: "calendar", label: "Calendar" },
    { name: "celebration", label: "Celebration" },
    { name: "church", label: "Church" },
    { name: "cleaning", label: "Cleaning" },
    { name: "coffee", label: "Coffee" },
    { name: "construction", label: "Construction" },
    { name: "contact", label: "Contact" },
    { name: "cricket", label: "Cricket" },
    { name: "dentist", label: "Dentist" },
    { name: "diversity", label: "Diversity" },
    { name: "doctor", label: "Doctor" },
    { name: "drive", label: "Drive" },
    { name: "emergency", label: "Emergency" },
    { name: "family", label: "Family" },
    { name: "fitness", label: "Fitness" },
    { name: "flight", label: "Flight" },
    { name: "football", label: "Football" },
    { name: "friend", label: "Friend" },
    { name: "golf", label: "Golf" },
    { name: "grocery", label: "Grocery" },
    { name: "gymnastics", label: "Gymnastics" },
    { name: "handball", label: "Handball" },
    { name: "hiking", label: "Hiking" },
    { name: "hockey", label: "Hockey" },
    { name: "homerepair", label: "Repair" },
    { name: "house", label: "House" },
    { name: "interests", label: "Interests" },
    { name: "learning", label: "Learning" },
    { name: "legal", label: "Legal" },
    { name: "luggage", label: "Luggage" },
    { name: "marriage", label: "Marriage" },
    { name: "martialarts", label: "Martial Arts" },
    { name: "medical", label: "Medical" },
    { name: "medication", label: "Medication" },
    { name: "mma", label: "Mma" },
    { name: "mosque", label: "Mosque" },
    { name: "movies", label: "Movies" },
    { name: "music", label: "Music" },
    { name: "newborn", label: "Newborn" },
    { name: "nightlife", label: "Nightlife" },
    { name: "pet", label: "Pets" },
    { name: "pool", label: "Pool" },
    { name: "restaurant", label: "Restaurant" },
    { name: "rugby", label: "Rugby" },
    { name: "school", label: "School" },
    { name: "shopping", label: "Shopping" },
    { name: "soccer", label: "Soccer" },
    { name: "spa", label: "Spa" },
    { name: "sports", label: "Sports" },
    { name: "store", label: "Store" },
    { name: "synagogue", label: "Synagogue" },
    { name: "tennis", label: "Tennis" },
    { name: "theater", label: "Theaters" },
    { name: "travel", label: "Travel" },
    { name: "vollyball", label: "Volleyball" },
    { name: "work", label: "Work" },
  ],

  faqs: [
    {
      title: `How may family members can I have?`,
      text: [
        `All subscriptions come with 10 family members.`,
        `These include pets and small children.`,
        `It doesn't matter how many of your family members have logins.`,
        `We wanted to ensure that you start using our app without hitting limits and having to unexpectedly pay more.`,
        `We feel 10 is enough for most family households.`,
        `If you need more, they can be purchased separately.`,
        `In the System Menu, go to Manage Subscription and here you can purchase more for a very small fee.`,
      ],
    },
    {
      title: `Why do you not offer tiers with different prices?`,
      text: [
        `Our Family Dashboard is a very integrated application.`,
        `Separating them would mean that you would lose some powerful features.`,
        `We decided that we would offer all our features for one low price.`,
        `By taking this approach, we can offer a full-featured application to everyone.`,
      ],
    },
    {
      title: `How are you keeping our data safe?`,
      text: [
        `We take security very seriously. In fact we challenge you to check with your current apps to see how they handle your data. I think you will be concerned how they handle and what they do with your data.`,
        `All information you enter into Our Family Dashboard will NEVER be used outside of the company.`,
        `We will not sell or send your data anywhere.`,
        `All personal data, including but not limited to, names, emails, phone numbers, notes etc. are encrypted INSIDE the database.`,
        `This means that without the proper keys, this data cannot be decrypted and read even if the database itself is compromised.`,
        `You will not see this in other applications.`,
        `When you signed up, you were asked for where you live. This allows us to make sure that your data is stored in the region you live in.`,
        `Do you know where your data in other applications reside? Chances are high that it's not where you think it is.`,
        `Other than usage statistics, our staff does not have access to your data.`,
        `Keys to your data are kept secret and are not even made available to our staff.`,
        `We have tools to assist us in supporting you, but these tools are only available to support staff.`,
        `There is no such thing as total safety in technology. We have seen many examples of data breaches.`,
        `We have gone beyond most companies in ensuring your security.`,
        `We hope you feel comfortable with our safeguards.`,
        `In the end, it is entirely your choice as to what you feel comfortable with storing in Our Family Dashboard.`,
      ],
    },
    {
      title: `How do I get all my data deleted if I decide to end my subscription??`,
      text: [
        `If you decide to terminate your subscription, all your data will automatically be deleted in 30 days after the end of your subscription.`,
        `This allows you to resubscribe and not lose any data within that 30 day term.`,
        `If you decide you want all of your data deleted immediately, simply submit a support ticket and will remove your all your accounts and data within a couple of business days.`,
      ],
    },
  ],

  familyDashboard: {
    menuItems: [
      {
        id: "home",
        label: "Home",
        requiresPin: false,
        icon: "home",
      },
      {
        id: "calendar",
        label: "Calendar",
        requiresPin: false,
        icon: "calendar",
      },
      {
        id: "chores",
        label: "Chores",
        requiresPin: false,
        icon: "chores",
      },
      {
        id: "homework",
        label: "Homework",
        requiresPin: false,
        icon: "homework",
      },
      {
        id: "groceries",
        label: "Groceries",
        requiresPin: false,
        icon: "groceries",
      },
      {
        id: "mealplan",
        label: "Meal Plan",
        requiresPin: false,
        icon: "mealplan",
      },
      {
        id: "recipes",
        label: "Recipes",
        requiresPin: false,
        icon: "recipes",
      },
      {
        id: "todo",
        label: "To Do",
        requiresPin: false,
        icon: "todo",
      },
    ],
  },
};

export default en_US;
