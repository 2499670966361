import { useEffect, useState } from "react";

import useGrocery from "../../hooks/useGrocery";
import useMessage from "../../hooks/useMessage";
import useLocalization from "../../hooks/useLocalization";

import { Box, Stack, Typography } from "@mui/material";

import OFDSelect from "../ui/OFDSelect";
import OFDToolbar from "../layout/OFDToolbar";
import OFDGroceryItems from "../ui/OFDGroceryItems";
import OFDFormControl from "../ui/OFDFormControl";
import OFDMessage from "../ui/OFDMessage";

import OFDModal from "../layout/OFDModal";

const GroceryList = ({ open, onClose, meal }) => {
  const { getGroceryLists, groceryLists, updateGroceryItems } = useGrocery();
  const { destructureResults } = useMessage();
  const { getLabel } = useLocalization();

  const [groceryOptions, setGroceryOptions] = useState([]);
  const [selectedGrocery, setSelectedGrocery] = useState(null);
  const [groceryItems, setGroceryItems] = useState([]);

  const [message, setMessage] = useState(null);

  useEffect(() => {
    getGroceryLists();
  }, []);

  useEffect(() => {
    if (!Array.isArray(groceryLists)) return;

    setGroceryOptions(() => {
      let options = [];

      let index = 0;
      for (const list of groceryLists) {
        if (!list.isCurrent) continue;

        if (index === 0) {
          setSelectedGrocery(list);
          setGroceryItems(list.groceryItems);
        }

        options.push({
          value: list.id,
          label: list.name,
        });
        index++;
      }

      return options;
    });
  }, [groceryLists]);

  const handleToolbarClick = async (action) => {
    if (action === "save") {
      await saveGroceryList();
    }
  };

  const saveGroceryList = async () => {
    const results = await updateGroceryItems(selectedGrocery.id, groceryItems);

    setMessage(destructureResults(results));
  };

  const handleGrocerySelection = (groceryId) => {
    const index = groceryLists.findIndex((item) => item.id === groceryId);
    setSelectedGrocery(groceryLists[index]);
    setGroceryItems(groceryLists[index].groceryItems);
  };

  const handleGroceryChange = (newValue) => {
    setGroceryItems(newValue);
  };

  if (!selectedGrocery) return null;

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title={getLabel("addToGrocery")}
        message={message}
        resetMessage={() => setMessage(null)}
      >
        <OFDToolbar
          sticky
          menuItems={[{ name: "save", label: "save", icon: "save" }]}
          onClick={handleToolbarClick}
        />

        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",
            padding: "1rem",
          }}
        >
          <Stack spacing={2}>
            <OFDSelect
              id="groceryLists"
              label="groceryLists"
              value={selectedGrocery.id}
              options={groceryOptions}
              onChange={handleGrocerySelection}
            />

            {Array.isArray(meal) && meal.length > 0 ? (
              <OFDFormControl label="mealItems">
                <Stack>
                  {meal.map((item) => (
                    <Box>
                      <Typography>{item.name}</Typography>
                    </Box>
                  ))}
                </Stack>
              </OFDFormControl>
            ) : null}

            <OFDGroceryItems
              value={groceryItems}
              onChange={handleGroceryChange}
            />
          </Stack>
        </Box>
      </OFDModal>
    </>
  );
};

export default GroceryList;
