import React from "react";

const ImageViewer = ({ url }) => {
  if (!url) return null;

  return (
    <div>
      <img
        src={url}
        width="100%"
      />
    </div>
  );
};

export default ImageViewer;
