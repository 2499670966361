import { useState } from "react";

import imageCompression from "browser-image-compression";

const useImage = () => {
  const [compressedImage, setCompressedImage] = useState(null);
  const [base64, setBase64] = useState(null);

  const defaultCompressionOptions = {
    maxSizeMB: 0.5,
    maxWidthOrheight: 800,
    useWebWorker: false,
  };

  const compressImage = async (file, options) => {
    setCompressedImage(null);
    if (!file) return;

    imageCompression(file, options || defaultCompressionOptions).then(
      (compressedFile) => {
        setCompressedImage(compressedFile);
        convertToBase64(compressedFile);
      }
    );
  };

  const convertToBase64 = async (file) => {
    const reader = new FileReader();

    reader.onload = () => {
      setBase64(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return {
    compressImage,
    compressedImage,
    convertToBase64,
    base64,
  };
};

export default useImage;
