import { useEffect, useState } from "react";

import useDebug from "./useDebug";
import useDevice from "./useDevice";

const iOSPushCapability = Boolean(
  window.webkit && window.webkit.messageHandlers
);

const mobiles = {
  iphone: /iphone/,
  ipad: /ipad|macintosh/,
  android: /android/,
};

const useApple = () => {
  const { logToServer } = useDebug();
  const { detectPlatform } = useDevice();

  const [connect, setConnect] = useState(false);
  const [isApple, setIsApple] = useState(false);

  const [eventsReceived, setEventsReceived] = useState(false);
  const [appleEvents, setAppleEvents] = useState();

  const [contactsReceived, setContactsReceived] = useState(false);
  const [appleContacts, setAppleContacts] = useState();

  useEffect(() => {
    const platform = detectPlatform();
    if (platform.os === "iphone" || platform.os === "ipad") {
      setIsApple(true);
    }
  }, []);

  useEffect(() => {
    if (!connect) return;

    window.addEventListener("calendar-receive", receiveCalendar);
    window.addEventListener("contacts-receive", receiveContacts);

    return () => {
      window.removeEventListener("calendar-receive", receiveCalendar);
      window.removeEventListener("contacts-receive", receiveContacts);
    };
  }, [connect]);

  const showPayWall = () => {
    if (isApple) {
      window.webkit.messageHandlers["iap-request"].postMessage("showPayWall");
    }
  };

  const resubscribe = (signupId) => {
    if (isApple) {
      window.webkit.messageHandlers["iap-expired"].postMessage(signupId);
    }
  };

  const getCalendar = () => {
    if (!isApple) return;
    setConnect(true);

    window.webkit.messageHandlers["calendar-get"].postMessage("get");
  };

  const getAppleContacts = () => {
    if (!isApple) return;
    setConnect(true);
    window.webkit.messageHandlers["contacts-get"].postMessage("get");
  };

  const receiveCalendar = async (event) => {
    setEventsReceived(true);
    setAppleEvents(event.detail);
  };

  const receiveContacts = async (event) => {
    setContactsReceived(true);
    setAppleContacts(event.detail);
  };

  return {
    iOSPushCapability,
    showPayWall,
    getCalendar,
    isApple,
    eventsReceived,
    appleEvents,
    getAppleContacts,
    appleContacts,
    resubscribe,
  };
};

export default useApple;
