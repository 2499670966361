import { useEffect, useState } from "react";

import useHomework from "../../../../hooks/useHomework";
import useLocalization from "../../../../hooks/useLocalization";
import usePage from "../../../../hooks/usePage";

import { Box, Stack, Typography } from "@mui/material";

import Homework from "./Homework";

const HomeworkWidget = ({ forDate }) => {
  const { homework, getOutstandingHomework, apiResult } = useHomework();
  const { getMessage } = useLocalization();
  const { refresh } = usePage();

  useEffect(() => {
    getOutstandingHomework();
  }, [refresh]);

  const handleHomeworkChange = async () => {
    await getOutstandingHomework();
  };

  if (homework === undefined) return;

  return (
    <>
      <Box>
        {Array.isArray(homework) && homework.length > 0 ? (
          <Stack spacing={1}>
            {homework.map((hw) => (
              <Homework
                key={hw.id}
                homework={hw}
                onChange={handleHomeworkChange}
              />
            ))}
          </Stack>
        ) : (
          <Typography>{getMessage("noHomework")}</Typography>
        )}
      </Box>
    </>
  );
};

export default HomeworkWidget;
