import { useState } from "react";
import { startOfToday, subMonths } from "date-fns";

import useApi from "./useApi";
import useFamily from "./useFamily";
import useMessage from "./useMessage";

const useGrocery = () => {
  const { family } = useFamily();
  const { getData, postData, deleteData } = useApi();
  const { destructureResults } = useMessage();

  const [groceryLists, setGroceryLists] = useState(undefined);
  const [groceryList, setGroceryList] = useState();
  const [formMessage, setFormMessage] = useState(null);

  const last3Months = {
    shoppingDate: {
      $gte: subMonths(startOfToday(), 3),
    },
  };

  const getGroceryLists = async (filter) => {
    setGroceryLists(null);
    setFormMessage(null);

    const results = await getData({
      entityName: "Grocery",
      action: "get",
      filter: {
        familyId: family.id,
        ...filter,
      },
    });

    if (results.isSuccessful) {
      setGroceryLists(results.data);
    } else {
      setGroceryLists([]);
    }
  };

  const getGroceryList = async (id) => {
    setGroceryList(null);
    setFormMessage(null);

    const results = await getData({
      entityName: "Grocery",
      action: "get",
      id,
    });

    if (results.isSuccessful) {
      setGroceryList(results.data);
    } else {
      setGroceryList(null);
    }
  };

  const updateGroceryItem = (groceryItem) => {
    setGroceryList((current) => {
      let newGroceryItems = [];

      for (const item of current.groceryItems) {
        if (item.id !== groceryItem.id) {
          newGroceryItems.push({ ...item });
          continue;
        }
        newGroceryItems.push({ ...groceryItem });
      }

      return { ...current, groceryItems: [...newGroceryItems] };
    });
  };

  const setField = (field, value) => {
    setGroceryList((current) => {
      return { ...current, [field]: value };
    });
  };

  const saveGrocery = async (data) => {
    const results = await postData({
      entityName: "Grocery",
      action: groceryList.id ? "update" : "add",
      id: groceryList.id ? groceryList.id : null,
      data: { ...groceryList, ...data },
    });

    setFormMessage(destructureResults(results));

    if (results.isSuccessful) {
      setGroceryList(results.data);
    }

    // refreshPage();

    return results;
  };

  const updateGroceryItems = async (groceryId, groceryItems) => {
    const results = await postData({
      entityName: "Grocery",
      action: "update",
      data: {
        id: groceryId,
        groceryItems,
      },
    });

    return results;
  };

  const updateGrocery = async (data) => {
    const results = await postData({
      entityName: "Grocery",
      action: "update",
      data,
    });

    return results;
  };

  const addGrocery = async (data) => {
    const results = await postData({
      entityName: "Grocery",
      action: "add",
      data,
    });

    return results;
  };

  const deleteGrocery = async (id) => {
    const results = await deleteData({
      entityName: "Grocery",
      action: "delete",
      data: {
        id,
      },
    });

    return results;
  };

  const completeGrocery = async (id, completedAt) => {
    const results = await postData({
      entityName: "Grocery",
      action: "update",
      data: {
        id,
        completedAt: completedAt ? completedAt : new Date(),
      },
    });

    return results;
  };

  const addIngredientsToList = async (id, items) => {
    if (!id) {
      setFormMessage({
        isSuccessful: false,
        displayToUser: true,
        message: "groceryRequired",
        severity: "error",
      });
      return;
    }

    const results = await postData({
      entityName: "Grocery",
      action: "addIngredients",
      data: {
        id,
        items,
      },
    });

    setFormMessage(destructureResults(results));

    return results;
  };

  const resetFormMessage = () => {
    setFormMessage(null);
  };

  const groupGroceryItems = (groceryItems, sortByName) => {
    let groupedItems = [];

    let departments = [];
    for (const item of groceryItems) {
      if (departments.includes(item.department)) continue;

      departments.push(item.department);
    }

    if (sortByName) {
      departments.sort();
    }

    for (const department of departments) {
      const departmentItems = groceryItems.filter(
        (item) => item.department === department
      );

      groupedItems.push({
        department,
        items: departmentItems,
      });
    }

    return {
      departments,
      items: groupedItems,
    };
  };

  return {
    getGroceryLists,
    groceryLists,
    last3Months,
    getGroceryList,
    setGroceryList,
    groceryList,
    updateGroceryItem,
    setField,
    saveGrocery,
    formMessage,
    resetFormMessage,
    addIngredientsToList,
    groupGroceryItems,
    updateGroceryItems,
    updateGrocery,
    addGrocery,
    deleteGrocery,
    completeGrocery,
  };
};

export default useGrocery;
