const defaultMemberPreferences = {
  theme: "light",
  mode: "novice",
  notifications: {
    app: { enabled: true, value: null },
    sms: { enabled: false, value: "" },
    email: { enabled: false, value: "" },
    push: { enabled: false, value: "" },
  },
};

export default defaultMemberPreferences;
