import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

const OFDDisplayAudio = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={displayStyle}>
        <Typography
          variant="overline"
          onClick={onClick}
        >
          {getLabel(label)}
        </Typography>

        <Box>
          <audio
            src={value}
            controls
          />
        </Box>
      </Box>
    </>
  );
};

export default OFDDisplayAudio;
