import { useEffect, useState } from "react";
import { startOfToday, getYear, parse } from "date-fns";

import { Box, Typography } from "@mui/material";

import usePage from "../../hooks/usePage";
import useExpense from "../../hooks/useExpense";
import useSearch from "../../hooks/useSearch";
import useEntity from "../../hooks/useEntity";
import useLocalization from "../../hooks/useLocalization";

import OFDTitle from "../layout/OFDTitle";
import ExpenseEditor from "../editors/ExpenseEditor";
import ExpensesByMonth from "../display/ExpensesByMonth";
import ExpensesByBudget from "../display/ExpensesByBudget";
import RecurringExpenses from "../display/RecurringExpenses";
import ExpensesByCompany from "../display/ExpensesByCompany";

import OFDYear from "../ui/OFDYear";
import Search from "../forms/Search";
import SearchCriteria from "../forms/SearchCriteria";
import Loading from "../layout/Loading";
import ExpenseSearchResults from "../display/ExpenseSearchResults";
import OFDSwitch from "../ui/OFDSwitch";
import OFDButton from "../ui/OFDButton";

const Expenses = () => {
  const { getTitle } = useLocalization();
  const {
    setEntityName,
    toolbarAction,
    resetToolbarAction,
    refresh,
    refreshPage,
  } = usePage();
  const {
    setData,
    data,
    setSearchCriteria,
    searchCriteria,
    searchData,
    searchResults,
    clearSearch,
    displaySearchCriteria,
  } = useSearch();
  const { getSearchFields } = useEntity("Expense");

  const [openEditor, setOpenEditor] = useState(false);
  const [layout, setLayout] = useState("byMonth");
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [ytd, setYTD] = useState(true);
  const [isIncome, setIsIncome] = useState(false);

  useEffect(() => {
    setOpenEditor(false);
    setEntityName("Expense");
    setSelectedYear(getYear(startOfToday()));
  }, []);

  useEffect(() => {
    switch (toolbarAction) {
      case "addExpense":
        setIsIncome(false);
        setOpenEditor(true);
        break;

      case "addIncome":
        setIsIncome(true);
        setOpenEditor(true);
        break;

      case "search":
        setOpenSearch(true);
        break;

      case "byMonth":
        setLayout("byMonth");
        break;

      case "byBudget":
        setLayout("byBudget");
        break;

      case "recurring":
        setLayout("recurring");
        break;

      case "byCompany":
        setLayout("byCompany");
        break;
    }

    resetToolbarAction();
  }, [toolbarAction]);

  const handleCloseEditor = async () => {
    setOpenEditor(false);
    resetToolbarAction();
  };

  const handleSaveExpense = async () => {
    setOpenEditor(false);
    resetToolbarAction();
    refreshPage();
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleSearch = (searchValues) => {
    clearSearch();
    setOpenSearch(false);
    setSearchCriteria(searchValues);
  };

  const pageTitle = () => {
    switch (layout) {
      case "recurring":
        return getTitle("recurringExpenses");

      case "byMonth":
        return getTitle("expensesByMonth");

      case "byBudget":
        return getTitle("expensesByBudget");

      case "byCompany":
        return getTitle("expensesByCompany");

      default:
        return getTitle("expenses");
    }
  };

  return (
    <>
      <OFDTitle title={pageTitle()} />

      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}
      >
        <OFDYear
          id="year"
          label="year"
          value={selectedYear}
          onChange={(newValue) => setSelectedYear(newValue)}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box>
          <OFDSwitch
            label="ytd"
            value={ytd}
            onChange={(newValue) => setYTD(newValue)}
          />
        </Box>
      </Box>

      {searchCriteria ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {displaySearchCriteria(searchCriteria)}
            </Typography>
          </Box>
          <Box>
            <OFDButton
              label="clear"
              onClick={clearSearch}
              variant="contained"
              color="grey"
            />
          </Box>
        </Box>
      ) : null}

      {layout === "recurring" ? (
        <RecurringExpenses
          year={selectedYear}
          filter={searchCriteria}
        />
      ) : null}

      {layout === "byMonth" ? (
        <ExpensesByMonth
          year={selectedYear}
          filter={searchCriteria}
          ytd={ytd}
        />
      ) : null}

      {layout === "byBudget" ? (
        <ExpensesByBudget
          year={selectedYear}
          filter={searchCriteria}
          ytd={ytd}
        />
      ) : null}

      {layout === "byCompany" ? (
        <ExpensesByCompany
          year={selectedYear}
          filter={searchCriteria}
          ytd={ytd}
        />
      ) : null}

      {layout === "search" ? (
        <ExpenseSearchResults year={selectedYear} />
      ) : null}

      {openEditor ? (
        <ExpenseEditor
          open={openEditor}
          onClose={handleCloseEditor}
          onSave={handleSaveExpense}
          isIncome={isIncome}
        />
      ) : null}

      {openSearch ? (
        <Search
          open={openSearch}
          searchCriteria={searchCriteria}
          onClose={handleSearchClose}
          searchFields={getSearchFields()}
          onSearch={handleSearch}
        />
      ) : null}
    </>
  );
};

export default Expenses;
