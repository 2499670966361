import { useEffect, useState } from "react";

import useMember from "../../hooks/useMember";
import useSecurity from "../../hooks/useSecurity";
import useLocalization from "../../hooks/useLocalization";
import useOccasion from "../../hooks/useOccasion";
import useMessage from "../../hooks/useMessage";

import { Box, Popover, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OccasionEditor from "../editors/OccasionEditor";
import OFDDialog from "../layout/OFDDialog";
import OFDMessage from "../ui/OFDMessage";

import OFDIcon from "../ui/OFDIcon";

const OccasionDisplay = ({ calendar, onUpdate }) => {
  const { member } = useMember();
  const { getLabel } = useLocalization();
  const { userCanDelete, userCanUpdate } = useSecurity();
  const { deleteOccasion, getOccasion } = useOccasion();
  const { destructureResults } = useMessage();

  const theme = useTheme();

  const [occasion, setOccasion] = useState(null);

  const [showMenu, setShowMenu] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);
  const [menuItems, setMenuItems] = useState(null);

  const [openEditor, setOpenEditor] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    getData();

    let items = [];

    if (userCanUpdate(calendar.security, calendar.addedBy)) {
      items.push({
        value: "edit",
        label: "edit",
        icon: "edit",
      });
    }
    if (userCanDelete(calendar.security, calendar.addedBy)) {
      items.push({
        value: "delete",
        label: "delete",
        icon: "delete",
      });
    }

    setMenuItems(items);
  }, [calendar]);

  const getData = async () => {
    const results = await getOccasion(calendar.source.id);

    if (results.isSuccessful) {
      setOccasion(results.data);
    } else {
      setOccasion(null);
    }
  };

  const handleMenuClick = (option) => {
    if (option === "edit") setOpenEditor(true);
    if (option === "delete") setShowConfirmDelete(true);
    handleCloseMenu();
  };

  const handleOpenMenu = (e) => {
    setAnchorE1(e.target);
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorE1(null);
    setShowMenu(false);
  };

  const handleUpdate = () => {
    onUpdate();
  };

  const handleConfirmDelete = async () => {
    setShowConfirmDelete(false);

    const results = await deleteOccasion(occasion);

    setMessage(destructureResults(results));

    if (results.isSuccessful) {
      onUpdate();
    }
  };

  if (!occasion) return null;

  return (
    <>
      <Box sx={{ borderBottom: "1px solid #ccc" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex" }}
            gap={1}
          >
            <OFDIcon name="celebration" />
            <Typography>{occasion.name}</Typography>
          </Box>

          {menuItems && menuItems.length > 0 ? (
            <Box onClick={handleOpenMenu}>
              <OFDIcon
                name="verticalEllipse"
                color="grey"
              />
            </Box>
          ) : null}
        </Box>

        {occasion.note ? (
          <Box sx={{ paddingLeft: "40px" }}>
            <Typography
              variant="caption"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {occasion.note}
            </Typography>
          </Box>
        ) : null}
      </Box>

      <Popover
        open={showMenu}
        onClose={handleCloseMenu}
        anchorEl={anchorE1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack
          spacing={3}
          sx={{
            backgroundColor: "#ffffff",
            padding: "1rem",
            borderRadius: "8px",
            boxShadow: theme.shadows[8],
          }}
        >
          {menuItems?.map((menuItem) => (
            <Box
              key={menuItem.value}
              onClick={() => handleMenuClick(menuItem.value)}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <OFDIcon name={menuItem.icon} />
              {getLabel(menuItem.label)}
            </Box>
          ))}
        </Stack>
      </Popover>

      {openEditor ? (
        <OccasionEditor
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          occasionId={occasion.id}
          onUpdate={handleUpdate}
        />
      ) : null}

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <OFDMessage message={message} />
    </>
  );
};

export default OccasionDisplay;
