import privacyPolicy from "../../../../assets/docs/Privacy.htm";

const PrivacyPolicy = () => {
  return (
    <>
      <div>privacy policy</div>
      {/* <iframe
        src={privacyPolicy}
        width="100%"
        height="100%"
      /> */}
    </>
  );
};

export default PrivacyPolicy;
