import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";

import OFDIcon from "./OFDIcon";
import OFDMessage from "./OFDMessage";

const OFDDisplayPassword = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
  allowShow = false,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const [message, setMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(value);
      setMessage({
        message: "copiedToClipboard",
        displayToUser: true,
        severity: "info",
      });
    } catch (err) {
      setMessage({
        message: "error",
        displayToUser: true,
        severity: "error",
      });
    }
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="overline"
            onClick={handleClick}
          >
            {getLabel(label)}
          </Typography>

          <Box
            sx={{ display: "flex" }}
            gap={2}
          >
            {showPassword ? (
              <Typography>{value}</Typography>
            ) : (
              <Typography
                noWrap
                onClick={handleClick}
              >
                ********
              </Typography>
            )}
            {allowShow ? (
              <OFDIcon
                name={showPassword ? "viewOff" : "view"}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : null}
          </Box>
        </Box>

        {value ? (
          <Box>
            <OFDIcon
              name="copy"
              onClick={handleCopy}
            />
          </Box>
        ) : null}
      </Box>

      <OFDMessage
        message={message}
        onClose={() => setMessage(null)}
      />
    </>
  );
};

export default OFDDisplayPassword;
