import React from "react";

import useLocalization from "../../hooks/useLocalization";

import { Box, Typography, MenuItem } from "@mui/material";

import OFDIcon from "../ui/OFDIcon";

const OFDMenuItem = ({ name, label, icon, onClick, additionalText }) => {
  const { getLabel } = useLocalization();

  const handleClick = () => {
    if (onClick) {
      onClick(name);
    }
  };

  return (
    <MenuItem onClick={handleClick}>
      <Box
        sx={{ display: "flex" }}
        gap={1}
      >
        <OFDIcon
          name={icon}
          color="grey"
        />
        <Typography>{`${getLabel(label)} ${
          additionalText ? additionalText : ""
        }`}</Typography>
      </Box>
    </MenuItem>
  );
};

export default OFDMenuItem;
