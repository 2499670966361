import { Box } from "@mui/material";

function OFDTabPanel({ children, value, index, fullHeight, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: fullHeight ? "100%" : "auto" }}
    >
      {value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
    </div>
  );
}

export default OFDTabPanel;
