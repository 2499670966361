import { useEffect, useState } from "react";
import { format } from "date-fns";

import useLocalization from "../../hooks/useLocalization";
import useData from "../../hooks/useData";
import useSecurity from "../../hooks/useSecurity";
import useExpense from "../../hooks/useExpense";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import OFDDate from "./OFDDate";
import OFDTextNote from "./OFDTextNote";
import OFDFieldGroup from "./OFDFieldGroup";
import OFDDisplayNote from "./OFDDisplayNote";
import OFDNumber from "./OFDNumber";
import OFDCurrency from "./OFDCurrency";
import OFDSelect from "./OFDSelect";
import OFDButton from "./OFDButton";

import OFDDialog from "../layout/OFDDialog";
import ExpenseEditor from "../editors/ExpenseEditor";

const fieldsTemplate = {
  id: null,
  date: null,
  odometer: "",
  volume: "",
  volumeType: "",
  cost: "",
  note: "",
  expenseId: null,
};

const OFDGasLog = ({
  id,
  label,
  value,
  onChange,
  disabled,
  security,
  entryAddedBy,
  source,
}) => {
  const { getLabel, getListItemLabel, displayLocalCurrency } =
    useLocalization();
  const { newId, sort, toNumber } = useData();
  const { userCanView, userCanUpdate } = useSecurity();
  const { getExpenseCalendar } = useExpense();

  const [fields, setFields] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState("add");

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [openExpense, setOpenExpense] = useState(false);
  const [fieldMessages, setFieldMessages] = useState({});

  useEffect(() => {
    initializeFields();
  }, []);

  const initializeFields = () => {
    setFields({ ...fieldsTemplate, id: newId() });
  };

  const handleCancel = () => {
    setEditMode("add");
    initializeFields();
    setSelectedItem(null);
    setFieldMessages({});
  };

  const validFields = () => {
    let isValid = true;
    let messages = {};

    if (!fields || !fields.date) {
      messages.date = { error: true, message: "isEmpty" };
      isValid = false;
    }

    setFieldMessages(messages);
    return isValid;
  };

  const handleAddItem = () => {
    setEditMode("add");

    if (!validFields()) return;

    let list = [];
    if (Array.isArray(value)) {
      list = [...value, { ...fields }];
    } else {
      list = [{ ...fields }];
    }

    handleChange(list);
  };

  const handleEditItem = (editItem) => {
    setEditMode("edit");
    setSelectedItem(editItem);
    setFields({ ...editItem });
    setFieldMessages({});
  };

  const handleUpdateItem = (newFields = fields) => {
    if (!validFields()) return;

    let list = [];

    for (const bp of value) {
      if (bp.id !== selectedItem.id) {
        list.push({ ...bp });
      } else {
        list.push({ ...newFields });
      }
    }

    handleChange(list);
  };

  const handleDeleteItem = () => {
    if (!selectedItem) return;
    setEditMode("delete");
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = (response) => {
    setShowConfirmDelete(false);
    if (response === "yes") {
      let list = [];

      for (const bp of value) {
        if (bp.id !== selectedItem.id) {
          list.push({ ...bp });
          continue;
        }
      }

      handleChange(list);
    }
  };

  const handleChange = (list) => {
    let sortedList = sort(list, "date", "desc");
    onChange(sortedList);
    setEditMode("add");
    setSelectedItem(null);
    initializeFields();
  };

  const getFieldValue = (field) => {
    if (!fields[field]) return null;

    return fields[field];
  };

  const updateFieldValue = (field, newValue) => {
    setFields((current) => {
      return { ...current, [field]: newValue };
    });
  };

  const getFieldMessage = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return null;

    return fieldMessages[field].message;
  };

  const getFieldError = (field) => {
    if (!fieldMessages || fieldMessages[field] === undefined) return false;

    return fieldMessages[field].error;
  };

  const getItemLabel = (item) => {
    let text = "";
    text = `${format(item.date, "MMM dd, yyyy")} - `;

    if (item.volume) {
      text += `${item.volume} ${getListItemLabel(
        "volumeTypes",
        item.volumeType
      )}`;
    }

    if (item.cost) {
      text += ` : ${displayLocalCurrency(item.cost)}`;
    }

    return text;
  };

  const handleExpenseSaved = async (expense) => {
    setOpenExpense(false);

    // const expenseCalendar = await getExpenseCalendar(null, null, expense.id);

    // if (!expenseCalendar || expenseCalendar.length === 0) return;

    let newFields = { ...fields, expenseId: expense.id };
    setFields(newFields);
    if (selectedItem) {
      handleUpdateItem(newFields);
    }
  };

  const handleOpenExpense = () => {
    setOpenExpense(true);
  };

  const isDisabled = () => {
    if (disabled === true) return true;

    if (security) {
      return !userCanUpdate(security, entryAddedBy);
    }

    return false;
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  if (!fields) return;

  return (
    <>
      <Box id={id}>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography
            variant="h5"
            align="center"
          >
            {label}
          </Typography>
        </Box>

        <Box>
          <OFDDate
            id="date"
            label="date"
            value={getFieldValue("date") || ""}
            onChange={(newValue) => updateFieldValue("date", newValue)}
            disabled={isDisabled()}
            required
            error={getFieldError("date")}
            message={getFieldMessage("date")}
          />
        </Box>

        <Box
          sx={{ display: "flex", marginTop: ".5rem" }}
          gap={1}
        >
          <Box sx={{ width: "50%" }}>
            <OFDNumber
              id="volume"
              label="volume"
              value={getFieldValue("volume") || ""}
              onChange={(newValue) => updateFieldValue("volume", newValue)}
              fullWidth
              disabled={isDisabled()}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <OFDSelect
              id="volumeType"
              label="volumeType"
              value={getFieldValue("volumeType") || ""}
              onChange={(newValue) => updateFieldValue("volumeType", newValue)}
              fullWidth
              listName="volumeTypes"
              variant="outlined"
              disabled={isDisabled()}
            />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", marginTop: ".5rem" }}
          gap={1}
        >
          <Box sx={{ width: "50%" }}>
            <OFDNumber
              id="odometer"
              label="odometer"
              value={getFieldValue("odometer") || ""}
              onChange={(newValue) => updateFieldValue("odometer", newValue)}
              fullWidth
              disabled={isDisabled()}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <OFDCurrency
              id="cost"
              label="cost"
              value={getFieldValue("cost") || ""}
              onChange={(newValue) => updateFieldValue("cost", newValue)}
              fullWidth
              disabled={isDisabled()}
            />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex" }}
          gap={1}
        >
          <OFDTextNote
            id="note"
            label="note"
            value={getFieldValue("note") || ""}
            onChange={(newValue) => updateFieldValue("note", newValue)}
            fullWidth
            disabled={isDisabled()}
          />
        </Box>

        <OFDFieldGroup
          justifyContent="flex-end"
          sx={{ marginTop: ".5rem" }}
        >
          <OFDButton
            variant="contained"
            color="grey"
            name="expense"
            label="expense"
            iconName="expense"
            onClick={handleOpenExpense}
          />
        </OFDFieldGroup>

        <Box sx={{ marginTop: "1rem" }}>
          <OFDFieldGroup justifyContent="flex-end">
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeleteItem}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                selectedItem ? handleUpdateItem() : handleAddItem()
              }
            >
              {selectedItem ? "Update" : "Add"}
            </Button>
          </OFDFieldGroup>
        </Box>

        <Divider sx={{ marginTop: ".5rem" }}>
          <Typography variant="overline">{getLabel("entries")}</Typography>
        </Divider>

        <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
          <Stack spacing={1}>
            {Array.isArray(value)
              ? value.map((item) => (
                  <Box key={item.id}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#ccc",
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      gap={1}
                      onClick={() => handleEditItem(item)}
                    >
                      <Typography>{getItemLabel(item)}</Typography>
                    </Box>

                    {item.note ? (
                      <Box
                        sx={{
                          backgroundColor: "#ebebeb",
                          borderRadius: "4px",
                          marginTop: "4px",
                          paddingTop: ".25rem",
                          paddingBottom: ".25rem",
                          paddingLeft: ".5rem",
                          maxHeight: "100px",
                          overflow: "auto",
                          fontSize: "12px",
                          color: "#535353",
                        }}
                      >
                        <OFDDisplayNote
                          value={item.note}
                          textOnly={true}
                          styleoverride={{
                            fontSize: "12px",
                            color: "#535353",
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ))
              : null}
          </Stack>
        </Box>
      </Box>

      <OFDDialog
        open={showConfirmDelete}
        title="confirmDelete"
        description="confirmDeleteItem"
        actions={[
          {
            id: "yes",
            iconName: "",
            label: "yes",
          },
          {
            id: "no",
            iconName: "",
            label: "no",
          },
        ]}
        onClose={handleConfirmDelete}
      />

      <ExpenseEditor
        open={openExpense}
        onClose={() => setOpenExpense(false)}
        expenseId={selectedItem ? selectedItem.expenseId : null}
        onSave={handleExpenseSaved}
        initialValues={{
          name: `${getLabel("gasLog")} : ${
            fields && fields.date ? format(fields.date, "MMM dd, yyy") : ""
          }`,
          expenseDate: getFieldValue("date"),
          expenseAmount: getFieldValue("cost"),
          frequency: "onetime",
        }}
        source={source}
        field={{
          field: "gasLog",
          itemId: selectedItem ? selectedItem.id : fields.id,
        }}
      />
    </>
  );
};

export default OFDGasLog;
