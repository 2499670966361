import { useEffect, useState } from "react";

import useLocalization from "../../../hooks/useLocalization";
import useFamily from "../../../hooks/useFamily";
import useMember from "../../../hooks/useMember";

import { Box, Stack, Button, Typography } from "@mui/material";

import FamilyMember from "./FamilyMember";
import EntityEditor from "../../editors/EntityEditor";

const FamilyMembers = () => {
  const { getWizard, getLabel } = useLocalization();
  const { familyMembers } = useFamily();
  const { getMemberState } = useMember();

  const [openEditor, setOpenEditor] = useState(false);

  const handleOpenEditor = () => {
    setOpenEditor(true);
  };

  const handleCloseEditor = async () => {
    setOpenEditor(false);
    await getMemberState();
  };

  return (
    <>
      <Box sx={{ padding: "1rem" }}>
        <Stack spacing={2}>
          <Typography variant="h5">
            {getWizard("setup").familyMembers.title}
          </Typography>
          {getWizard("setup").familyMembers.text.map((text, index) => (
            <Typography key={index}>{text}</Typography>
          ))}

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={handleOpenEditor}
            >
              {getWizard("setup").familyMembers.labels.addFamilyMember}
            </Button>
          </Box>

          <Stack spacing={2}>
            {familyMembers?.map((familyMember) => (
              <FamilyMember
                key={familyMember.id}
                familyMember={familyMember}
              />
            ))}
          </Stack>
        </Stack>
      </Box>

      <EntityEditor
        entityName="Member"
        open={openEditor}
        onClose={handleCloseEditor}
      />
    </>
  );
};

export default FamilyMembers;
