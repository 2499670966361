import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useVCard from "../../hooks/useVCard";
import useFamily from "../../hooks/useFamily";
import useData from "../../hooks/useData";
import useApi from "../../hooks/useApi";
import useGoogle from "../../hooks/useGoogle";
import useApple from "../../hooks/useApple";
import useMember from "../../hooks/useMember";
import useQueue from "../../hooks/useQueue";
import useDebug from "../../hooks/useDebug";

import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";

import OFDModal from "../layout/OFDModal";
import OFDFileUploader from "../ui/OFDFileUploader";
import OFDToolbar from "../layout/OFDToolbar";
import Loading from "../layout/Loading";
import OFDTabPanel from "../layout/OFDTabPanel";
import OFDButton from "../ui/OFDButton";

const ContactImport = ({ open, onClose }) => {
  const { getText, getLabel } = useLocalization();
  const { parseCards } = useVCard();
  const { family } = useFamily();
  const { newId } = useData();
  const { postData } = useApi();
  const { authorizeClient, importedContacts } = useGoogle("contacts");
  const { isApple, getAppleContacts, appleContacts } = useApple();
  const { memberColor, member } = useMember();
  const { addToQueue } = useQueue();
  const [source, setSource] = useState();
  const { logToServer } = useDebug();

  const [file, setFile] = useState(null);
  const [vCardString, setVCardString] = useState("");
  const [contacts, setContacts] = useState(undefined);
  const [selectAll, setSelectAll] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [messages, setMessages] = useState([]);

  const tabStyle = {
    "& .MuiTabs-indicator": {
      backgroundColor: memberColor().backgroundColor,
    },
    "& .MuiTab-root.Mui-selected": {
      color: memberColor().backgroundColor,
    },
  };

  useEffect(() => {
    if (open) {
      setFile(null);
      setContacts(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (!file) return;
    setVCardString("");

    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target.readyState != 2) return;
      if (event.target.error) {
        return;
      }

      setVCardString(event.target.result);
    };

    reader.readAsText(file);
  }, [file]);

  useEffect(() => {
    if (!vCardString) return;
    setFile(null);

    let splitCards = vCardString.split("END:VCARD\r\n");
    let list = [];
    for (const card of splitCards) {
      list.push(`${card}END:VCARD\r\n`);
    }

    setContacts(parseCards(list));
  }, [vCardString]);

  useEffect(() => {
    if (!importedContacts) return;

    setContacts(importedContacts);
  }, [importedContacts]);

  useEffect(() => {
    if (!appleContacts) return;

    setContacts(appleContacts);
  }, [appleContacts]);

  useEffect(() => {
    if (!contacts) return;
  }, [contacts]);

  const handleToolbarClick = async (action) => {
    if (!contacts) return;

    if (action === "save") {
      setSaving(true);

      await sendToQueue();

      setSaving(false);
      onClose();
    }
  };

  const sendToQueue = async () => {
    const formattedContacts = [];

    if (source === "google") {
      for (const contact of contacts) {
        formattedContacts.push(formatFromGoogle(contact));
      }
    } else if (source === "apple") {
      for (const contact of contacts) {
        let formattedContact = await formatFromApple(contact);
        if (formattedContact) {
          formattedContacts.push(formattedContact);
        }
      }
    }

    const results = await addToQueue("contactImport", {
      source,
      familyId: family.id,
      memberId: member.id,
      contacts: formattedContacts,
    });
  };

  const formatPhone = (value) => {
    let newPhone = null;

    if (value) {
      if (value.substring(0, 1) === "+") {
        newPhone = value;
      } else {
        newPhone = value.replace(/[^0-9]/g, "");
        if (newPhone.length > 10) {
          newPhone = newPhone.substring(1);
        }
        newPhone = "+1" + newPhone;
      }
    }

    return newPhone;
  };

  const formatFromGoogle = (contact) => {
    let newContact = {
      familyId: family.id,
      name: contact.name,
      company: contact.company?.length > 0 ? contact.company[0].value : null,
      email: contact.email?.length > 0 ? contact.email[0].value : null,
      phone:
        contact.phone?.length > 0 ? formatPhone(contact.phone[0].value) : null,
      address: contact.address?.length > 0 ? contact.address[0].value : null,
    };

    let folder = {
      id: newId(),
      name: getLabel("additionalInfo"),
      fields: [],
    };

    let addFolder = false;
    if (contact.company?.length > 1) {
      addFolder = true;
      for (let x = 1; x < contact.company.length; x++) {
        folder.fields.push({
          id: newId(),
          label: contact.company[x].type[0],
          fieldType: "textField",
          value: contact.company[x].value,
        });
      }
    }
    if (contact.email?.length > 1) {
      addFolder = true;
      for (let x = 1; x < contact.email.length; x++) {
        folder.fields.push({
          id: newId(),
          label: contact.email[x].type[0],
          fieldType: "email",
          value: contact.email[x].value,
        });
      }
    }
    if (contact.phone?.length > 1) {
      addFolder = true;
      for (let x = 1; x < contact.phone.length; x++) {
        folder.fields.push({
          id: newId(),
          label: contact.phone[x].type[0],
          fieldType: "phone",
          value: contact.phone[x].value,
        });
      }
    }
    if (contact.address?.length > 1) {
      addFolder = true;
      for (let x = 1; x < contact.address.length; x++) {
        folder.fields.push({
          id: newId(),
          label: contact.address[x].type[0],
          fieldType: "address",
          value: contact.address[x].value,
        });
      }
    }

    if (addFolder) {
      newContact.folders = [folder];
    }

    return newContact;
  };

  const formatFromApple = async (contact) => {
    let newContact = null;

    try {
      newContact = {
        familyId: family.id,
        name: contact.name,
        company: contact.organization ? contact.organization : null,
        email: contact.email?.length > 0 ? contact.email[0].email : null,
        phone:
          contact.phone?.length > 0
            ? formatPhone(contact.phone[0].phoneNumber)
            : null,
        address: contact.address?.length > 0 ? contact.address[0] : null,
      };

      let folder = {
        id: newId(),
        name: getLabel("additionalInfo"),
        fields: [],
      };

      let addFolder = false;
      if (contact.email?.length > 1) {
        addFolder = true;
        for (let x = 1; x < contact.email.length; x++) {
          folder.fields.push({
            id: newId(),
            label: contact.email[x].label,
            fieldType: "email",
            value: contact.email[x].email,
          });
        }
      }
      if (contact.phone?.length > 1) {
        addFolder = true;
        for (let x = 1; x < contact.phone.length; x++) {
          folder.fields.push({
            id: newId(),
            label: contact.phone[x].label,
            fieldType: "phone",
            value: contact.phone[x].phoneNumber,
          });
        }
      }
      if (contact.address?.length > 1) {
        addFolder = true;
        for (let x = 1; x < contact.address.length; x++) {
          folder.fields.push({
            id: newId(),
            label: contact.address[x].label,
            fieldType: "address",
            value: contact.address[x],
          });
        }
      }

      if (addFolder) {
        newContact.folders = [folder];
      }
    } catch (err) {
      await logToServer("Error formatting Apple contact", err);
    }

    return newContact;
  };

  const handleTabChange = (e, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleGoogleImport = () => {
    setSource("google");
    authorizeClient("contacts");
  };

  const handleAppleImport = async () => {
    setSource("apple");
    getAppleContacts();
  };

  return (
    <>
      <OFDModal
        open={open}
        onClose={onClose}
        title="importContacts"
      >
        <Box
          sx={{
            position: "sticky",
            left: 0,
            top: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <OFDToolbar
            menuItems={[{ name: "save", label: "save", icon: "save" }]}
            onClick={handleToolbarClick}
          />
        </Box>

        {contacts === undefined ? (
          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              sx={tabStyle}
            >
              <Tab label={getLabel("uploadFile")} />
              <Tab label={getLabel("myDevice")} />
            </Tabs>

            <OFDTabPanel
              value={0}
              index={tabIndex}
            >
              <Box sx={{ padding: "1rem" }}>
                <Typography>{getText("contactImportIntro")}</Typography>
              </Box>
              <Box sx={{ padding: "1rem" }}>
                <OFDFileUploader
                  onUpload={setFile}
                  accept=".vcf"
                />
              </Box>
            </OFDTabPanel>

            <OFDTabPanel
              value={1}
              index={tabIndex}
            >
              {!isApple ? (
                <Box>
                  <Box sx={{ padding: "1rem" }}>
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {getText("googleContactImport")}
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "1rem" }}>
                    <OFDButton
                      label="continue"
                      onClick={handleGoogleImport}
                    />
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box sx={{ padding: "1rem" }}>
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {getText("appleContactImport")}
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "1rem" }}>
                    <OFDButton
                      label="continue"
                      onClick={handleAppleImport}
                    />
                  </Box>
                </Box>
              )}
            </OFDTabPanel>
          </Box>
        ) : null}

        {contacts && contacts.length > 0 ? (
          <Box sx={{ padding: "1rem" }}>
            <Typography>{`${contacts.length} ${getLabel(
              "contactsToImport"
            )}`}</Typography>

            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {getText("completeImport")}
            </Typography>
          </Box>
        ) : null}
      </OFDModal>

      <OFDModal
        open={saving}
        title="savingContacts"
      >
        <Loading />
      </OFDModal>
    </>
  );
};

export default ContactImport;
