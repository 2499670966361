import Introduction from "../assets/images/thumbnails/Video Cover_Introduction.png";
import Budgets from "../assets/images/thumbnails/Video Cover_Budgets – 1.png";
import Calendar from "../assets/images/thumbnails/Video Cover_Calendar – 1.png";
import Chores from "../assets/images/thumbnails/Video Cover_Chores – 1.png";
import Contacts from "../assets/images/thumbnails/Video Cover_Contacts – 1.png";
import Dashboard from "../assets/images/thumbnails/Video Cover_Dashboard.png";
import Documents from "../assets/images/thumbnails/Video Cover_Documents – 1.png";
import EditingSchedules from "../assets/images/thumbnails/Video Cover_Editing Scheduling.png";
import ExpenseMonitoring from "../assets/images/thumbnails/Video Cover_Expense Monitoring.png";
import Expenses from "../assets/images/thumbnails/Video Cover_Expenses – 1.png";
import Family from "../assets/images/thumbnails/Video Cover_Family – 1.png";
import Folders from "../assets/images/thumbnails/VideoCover-Folders – 1.png";
import GetStarted from "../assets/images/thumbnails/Video Cover_Getting Started.jpg";
import Groceries from "../assets/images/thumbnails/Video Cover_Groceries – 1.png";
import Homework from "../assets/images/thumbnails/Video Cover_Homework – 1.png";
import MealPlans from "../assets/images/thumbnails/Video Cover_MealPlans – 1.png";
import Notes from "../assets/images/thumbnails/Video Cover_Notes – 1.png";
import PasswordManager from "../assets/images/thumbnails/Video Cover_Passwords – 1.png";
import Recipes from "../assets/images/thumbnails/Video Cover_Recipes – 1.png";
import Reminders from "../assets/images/thumbnails/Video Cover_Reminders – 1.png";
import Scheduling from "../assets/images/thumbnails/Video Cover_Scheduling.png";
import SpecialDays from "../assets/images/thumbnails/VideoCover_SpecialDays – 1.png";
import SubscriptionManager from "../assets/images/thumbnails/Video Cover_Subscriptions – 1.png";
import Trips from "../assets/images/thumbnails/Video Cover-Trips – 1.png";
import Vehicles from "../assets/images/thumbnails/Video Cover_Vehicles – 1.png";

const HelpVideos = [
  {
    name: "introduction",
    thumbnail: Introduction,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Introduction.mp4",
  },
  {
    name: "getStarted",
    thumbnail: GetStarted,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/GetStarted.mp4",
  },
  {
    name: "family",
    thumbnail: Family,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Family.mp4",
  },
  {
    name: "folders",
    thumbnail: Folders,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Folders.mp4",
  },
  {
    name: "dashboard",
    thumbnail: Dashboard,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Dashboard.mp4",
  },
  {
    name: "calendar",
    thumbnail: Calendar,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Calendar.mp4",
  },
  {
    name: "scheduling",
    thumbnail: Scheduling,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Scheduling.mp4",
  },
  {
    name: "editSchedules",
    thumbnail: EditingSchedules,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/EditingSchedules.mp4",
  },
  {
    name: "specialOccasions",
    thumbnail: SpecialDays,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Special_Occasions.mp4",
  },
  {
    name: "reminders",
    thumbnail: Reminders,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Reminders.mp4",
  },
  {
    name: "chores",
    thumbnail: Chores,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Chores.mp4",
  },
  {
    name: "homework",
    thumbnail: Homework,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Homework.mp4",
  },
  {
    name: "contacts",
    thumbnail: Contacts,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Contacts.mp4",
  },
  {
    name: "budgets",
    thumbnail: Budgets,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Budgets.mp4",
  },
  {
    name: "expenses",
    thumbnail: Expenses,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Expenses.mp4",
  },
  {
    name: "expenseMonitoring",
    thumbnail: ExpenseMonitoring,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/ExpenseMonitoring.mp4",
  },
  {
    name: "groceries",
    thumbnail: Groceries,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Groceries.mp4",
  },
  {
    name: "mealPlans",
    thumbnail: MealPlans,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/MealPlans.mp4",
  },
  {
    name: "recipes",
    thumbnail: Recipes,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Recipes.mp4",
  },
  {
    name: "documents",
    thumbnail: Documents,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Documents.mp4",
  },
  {
    name: "notes",
    thumbnail: Notes,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Notes.mp4",
  },

  {
    name: "trips",
    thumbnail: Trips,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Trips.mp4",
  },
  {
    name: "vehicles",
    thumbnail: Vehicles,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Vehicles.mp4",
  },
  {
    name: "subscriptionManager",
    thumbnail: SubscriptionManager,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/Subscriptions.mp4",
  },
  {
    name: "passwordManager",
    thumbnail: PasswordManager,
    src: "https://ofd.nyc3.cdn.digitaloceanspaces.com/videos/PasswordManager.mp4",
  },
];

export default HelpVideos;
