import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";

import useMember from "../../hooks/useMember";
import useLocalization from "../../hooks/useLocalization";

import { Box, Modal, Typography } from "@mui/material";

import OFDDisplayHtml from "../ui/OFDDisplayHtml";

const NotificationDisplay = ({ notification }) => {
  const { memberColor } = useMember();
  const { getLabel } = useLocalization();

  const [display, setDisplay] = useState(undefined);

  useEffect(() => {
    if (!notification) return;

    if (notification.descriptionHtml) {
      setDisplay(notification.descriptionHtml);
    } else if (notification.description) {
      setDisplay(notification.description);
    } else {
      setDisplay(notification.name);
    }
  }, [notification]);

  if (Array.isArray(notification) || !notification || display === undefined)
    return;

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "12px",
        marginTop: "3rem",
        padding: ".5rem",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Box>
        <OFDDisplayHtml value={display} />
      </Box>
    </Box>
  );
};

export default NotificationDisplay;
