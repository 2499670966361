import { useState } from "react";
import {
  getYear,
  isBefore,
  isAfter,
  isDate,
  isEqual,
  startOfDay,
  parseISO,
  addMinutes,
  startOfToday,
  endOfToday,
  format,
} from "date-fns";

import useApi from "./useApi";
import useData from "./useData";
import useDate from "./useDate";
import useMember from "./useMember";
import useRecurrence from "./useRecurrence";

const useReminder = () => {
  const { getData, postData } = useApi();
  const {
    putRecordToIDB,
    getRecordFromIDB,
    deleteRecordFromIDB,
    getAllFromIDB,
    getAllKeysFromIDB,
    sort,
  } = useData();
  const { member } = useMember();
  const { transformRecurrence, validateRecurrence } = useRecurrence();
  const { toUTCDate } = useDate();

  const [reminders, setReminders] = useState(null);
  const [reminder, setReminder] = useState(null);

  const getReminders = async (passedFilter) => {
    let filter = passedFilter
      ? passedFilter
      : {
          memberId: member.id,
          datetime: {
            $gte: startOfToday(),
            $lte: endOfToday(),
          },
        };

    const results = await getData({
      entityName: "Reminder",
      action: "get",
      filter,
    });

    if (results.isSuccessful) {
      setReminders(sort(results.data, "datetime"));
    } else {
      setReminders([]);
    }
  };

  const getReminder = async (id) => {
    const results = await getData({
      entityName: "Reminder",
      action: "get",
      id,
      data: {
        id,
      },
    });

    if (results.isSuccessful) {
      setReminder(results.data);
    } else {
      setReminder(null);
    }
  };

  const delayReminder = async (id, delay) => {
    const results = await getData({
      entityName: "Reminder",
      action: "get",
      id,
      data: {
        id,
      },
    });

    if (results.isSuccessful) {
      const savedResults = await postData({
        entityName: "Reminder",
        action: "updateReminder",
        data: {
          ...results.data,
          datetime: addMinutes(results.data.datetime, delay),
        },
      });
    }
  };

  const syncToLocalDB = async () => {
    const results = await getData({
      entityName: "Reminder",
      action: "get",
      filter: {
        memberId: member.id,
        datetime: {
          $gte: startOfToday(),
        },
      },
    });

    if (results.isSuccessful) {
      await getAllFromIDB("reminders");

      if (results.data.length > 0) {
        for (const reminder of results.data) {
          await putRecordToIDB("reminders", reminder.id, reminder);
        }
      }
    }
  };

  const transformReminder = (passedReminder, option) => {
    let newReminder = { ...passedReminder };

    if (
      passedReminder.frequency !== "onetime" &&
      passedReminder.frequency !== "schedule"
    ) {
      newReminder.forever = Boolean(passedReminder.recurrence.forever);
    }

    if (option === "series") {
      newReminder.overridden = false;
    }

    if (option === "occurrence") {
      newReminder.time = newReminder.startDate;
    }

    if (
      !Array.isArray(newReminder.members) ||
      newReminder.members.length === 0
    ) {
      newReminder.members = [member.id];
    }

    newReminder.calendarType = "reminder";

    newReminder.recurrence = transformRecurrence(passedReminder.recurrence);

    if (passedReminder.frequency === "onetime") {
      newReminder.time = newReminder.startDate;
    }

    newReminder.timeString = newReminder.time
      ? format(newReminder.time, "HH:mm")
      : null;

    newReminder.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const today = new Date();
    const utcDate = toUTCDate(today);
    const offset = (utcDate.getTimezoneOffset() / 60) * -1;

    newReminder.tzOffset = offset;

    return newReminder;
  };

  const validateReminder = (passedReminder, option) => {
    let results = {
      isSuccessful: true,
      message: "ok",
      displayToUser: false,
      fieldErrors: [],
      severity: "info",
    };

    results = validateRecurrence(passedReminder.recurrence);

    // name cannot be empty
    if (!passedReminder.name || passedReminder.name.trim().length === 0) {
      results.isSuccessful = false;
      results.fieldErrors.push({
        name: "name",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    // must have at least one member
    if (
      !Array.isArray(passedReminder.members) ||
      passedReminder.members.length === 0
    ) {
      results.isSuccessful = false;
      results.fieldErrors.push({
        name: "members",
        message: "isEmpty",
        severity: "error",
        error: true,
      });
    }

    if (!results.isSuccessful) {
      results.message = "fieldErrors";
      results.displayToUser = true;
      results.severity = "error";
    }

    return results;
  };

  const getRemindersForSource = async (source) => {
    const results = await getData({
      entityName: "Reminder",
      action: "get",
      filter: {
        "source.model": source.model,
        "source.id": source.id,
      },
    });

    let reminders = [];
    let now = new Date();

    if (results && results.isSuccessful) {
      if (Array.isArray(results.data) && results.data.length > 0) {
        for (const reminder of results.data) {
          if (reminder.endDate) {
            if (isAfter(reminder.endDate, now)) {
              reminders.push({ ...reminder });
            }
          } else {
            reminders.push({ ...reminder });
            // if (isAfter(reminder.startDate, now)) {
            // }
          }
        }
      }
    }

    return reminders;
  };

  const addReminderToSource = async (source, reminderId) => {
    const results = await postData({
      entityName: source.model,
      action: "addReminder",
      data: {
        source,
        reminderId,
      },
    });
  };

  const deleteReminderFromSource = async (source, reminderId) => {
    const results = await postData({
      entityName: source.model,
      action: "deleteReminder",
      data: {
        source,
        reminderId,
      },
    });

    return results;
  };

  return {
    syncToLocalDB,
    getReminders,
    reminders,
    validateReminder,
    getReminder,
    reminder,
    transformReminder,
    delayReminder,
    getRemindersForSource,
    addReminderToSource,
    deleteReminderFromSource,
  };
};

export default useReminder;
