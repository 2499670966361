import { useEffect, useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

const OFDDisplayReminders = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box sx={displayStyle}>
      <Box onClick={handleClick}>
        <Typography variant="overline">{getLabel(label)}</Typography>
      </Box>

      <Box>
        <Stack spacing={0.5}>
          {Array.isArray(value) && value.length > 0 ? (
            value?.map((item, index) => (
              <Box key={`item-${index}`}>
                <Typography>{`${item.value} ${item.duration}`}</Typography>
                {item.note ? (
                  <Typography
                    variant="caption"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {item.note}
                  </Typography>
                ) : null}
              </Box>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default OFDDisplayReminders;
