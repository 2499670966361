import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import OFDSelect from "./OFDSelect";
import OFDDate from "./OFDDate";
import OFDNumber from "./OFDNumber";

const OFDRecurrenceCount = ({
  type,
  value,
  onChange,
  defaultValue,
  disabled,
}) => {
  const changeType = (newType) => {
    if (newType === "forever") {
      onChange(newType, true);
    } else {
      onChange(newType, null);
    }
  };

  const changeValue = (newValue) => {
    if (disabled && type === "forever") {
      onChange("until", newValue);
    } else {
      onChange(type, newValue);
    }
  };

  return (
    <Box
      sx={{ display: "flex" }}
      gap={1}
    >
      <Box sx={{ width: "45%" }}>
        <OFDSelect
          id="count"
          label="howLong"
          value={type ? type : "forever"}
          listName="recurrenceCount"
          onChange={changeType}
          fullWidth
          disabled={disabled}
        />
      </Box>
      <Box sx={{ width: "55%" }}>
        {type === "until" || (type === "forever" && disabled) ? (
          <OFDDate
            id="until"
            label="until"
            value={value ? value : defaultValue}
            onChange={changeValue}
          />
        ) : null}

        {type === "count" ? (
          <OFDNumber
            id="count"
            label="occurrences"
            value={value ? value : null}
            onChange={changeValue}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default OFDRecurrenceCount;
