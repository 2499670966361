import React from "react";

import useLocalization from "../../../hooks/useLocalization";
import useWindow from "../../../hooks/useWindow";

import { Box, Typography } from "@mui/material";

import OFDIngredients from "../../ui/OFDIngredients";
import OFDFieldContainer from "../../ui/OFDFieldContainer";

const RecipeIngredients = ({ ingredients, onChange }) => {
  const { getLabel, getTitle, getWizard } = useLocalization();
  const { isDesktop, windowHeight } = useWindow();

  const handleChange = (newItems) => {
    onChange(newItems);
  };

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <Typography
          variant="h5"
          sx={{ marginBottom: "1rem" }}
        >
          {getWizard("recipe").ingredients.title}
        </Typography>

        <OFDFieldContainer>
          <OFDIngredients
            value={ingredients}
            onChange={handleChange}
            wizard
          />
        </OFDFieldContainer>
      </Box>
    </>
  );
};

export default RecipeIngredients;
