import { useState } from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import PictureDisplay from "../display/PictureDisplay";

const OFDDisplayReceipts = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel, getMessage } = useLocalization();
  const { userCanView } = useSecurity();

  const [src, setSrc] = useState(null);
  const [openPicture, setOpenPicture] = useState(false);

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid grey",
    ...styleoverride,
  };

  const handleReceiptClick = (receipt) => {
    setSrc(receipt.src);
    setOpenPicture(true);
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <>
      <Box
        sx={{ ...displayStyle, display: "flex", alignItems: "flex-end" }}
        onClick={onClick}
      >
        <Box sx={{ width: "100%" }}>
          <Typography variant="overline">{getLabel(label)}</Typography>

          {Array.isArray(value) && value.length > 0 ? (
            <Grid
              container
              columnGap={1}
              rowGap={1}
            >
              {value.map((receipt, index) => (
                <Grid
                  key={`receipt-${index}`}
                  mobile={4}
                >
                  <img
                    alt="receipt"
                    src={receipt.src}
                    width="100%"
                    loading="lazy"
                    height="auto"
                    onClick={() => handleReceiptClick(receipt)}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>{getMessage("noReceipts")}</Typography>
          )}
        </Box>
      </Box>

      <PictureDisplay
        open={openPicture}
        src={src}
        onClose={() => setOpenPicture(false)}
      />
    </>
  );
};

export default OFDDisplayReceipts;
