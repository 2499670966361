import { useState } from "react";

import useLocalization from "../../../../hooks/useLocalization";

import { Box, Modal, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DailyPlan from "./DailyPlan";

import OFDIcon from "../../../ui/OFDIcon";

const DailyPlanContainer = ({
  dow,
  dailyPlan,
  height,
  mealPlanId,
  onUpdated,
}) => {
  const { calendarLists } = useLocalization();
  const theme = useTheme();

  return (
    <>
      <Box>
        <Box
          sx={{
            height: height,
            width: "300px",
            border: `1px solid ${theme.palette.primary.main}`,
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              height: "50px",
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem",
            }}
            gap={1}
          >
            <OFDIcon
              name="mealPlan"
              color="grey"
              shade={50}
            />

            <Typography variant="h6">
              {calendarLists.weekdays[dow].label}
            </Typography>
          </Box>

          <Box
            sx={{
              height: `${height - 50}px`,
              overflow: "auto",
              backgroundColor: "#efefef",
              padding: "1rem",
            }}
          >
            <DailyPlan
              dailyPlan={dailyPlan}
              dow={dow}
              mealPlanId={mealPlanId}
              onUpdated={onUpdated}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DailyPlanContainer;
