import React from "react";

import useLocalization from "../../hooks/useLocalization";
import useSecurity from "../../hooks/useSecurity";

import { Box, Typography, Stack } from "@mui/material";

const OFDDisplayAddress = ({
  label,
  value,
  styleoverride,
  onClick,
  security,
  entryAddedBy,
}) => {
  const { getLabel } = useLocalization();
  const { userCanView } = useSecurity();

  const displayStyle = {
    padding: ".5rem",
    width: "100%",
    borderBottom: "1px solid #e0e0e0",
    ...styleoverride,
  };

  const fontStyle = {
    fontSize: "14px",
    lineHeight: "16px",
  };

  if (security && !userCanView(security, entryAddedBy)) return null;

  return (
    <Box
      sx={displayStyle}
      onClick={onClick}
    >
      <Typography variant="overline">{getLabel(label)}</Typography>

      {value ? (
        <Box>
          <Stack spacing={0.5}>
            {value.name ? (
              <Typography
                sx={{
                  ...fontStyle,
                  fontWeight: "bold",
                }}
              >
                {value.name}
              </Typography>
            ) : null}
            {value.streetAddress ? (
              <Typography sx={fontStyle}>{value.streetAddress}</Typography>
            ) : null}
            {value.otherAddress ? (
              <Typography sx={fontStyle}>{value.otherAddress}</Typography>
            ) : null}
            {value.city || value.province ? (
              <Typography sx={fontStyle}>{`${value.city ? value.city : ""}, ${
                value.province ? value.province : ""
              }`}</Typography>
            ) : null}
            {value.country || value.postal ? (
              <Typography sx={fontStyle}>{`${
                value.country ? value.country : ""
              }, ${value.postal ? value.postal : ""}`}</Typography>
            ) : null}
          </Stack>
        </Box>
      ) : (
        <Typography>-</Typography>
      )}
    </Box>
  );
};

export default OFDDisplayAddress;
