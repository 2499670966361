import { useEffect } from "react";

import useLocalization from "../../hooks/useLocalization";
import useWindow from "../../hooks/useWindow";

import { Box, Grid, Typography } from "@mui/material";

import OFDCategoryIcon from "./OFDCategoryIcon";

const OFDCategory = ({ id, label, value, onChange }) => {
  const { getLabel, categories } = useLocalization();

  const handleClick = (category) => {
    onChange(category);
  };

  return (
    <>
      <Box id={id}>
        <Box
          sx={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="h5"
            align="center"
          >
            {getLabel(label)}
          </Typography>
        </Box>

        <Box sx={{ height: "500px", overflow: "auto", paddingBottom: "10rem" }}>
          <Grid
            container
            justifyContent="center"
          >
            {categories?.map((category) => (
              <Grid
                key={category.name}
                display="flex"
                justifyContent="center"
              >
                <Box sx={{ margin: "8px" }}>
                  <OFDCategoryIcon
                    id={category.name}
                    label={category.label}
                    onClick={() => handleClick(category.name)}
                    selected={value === category.name}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default OFDCategory;
