import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidV4 } from "uuid";

const initialState = {
  page: null,
  entityName: null,
  toolbarAction: null,
  refresh: null,
  selectedDate: null,
  isSaving: false,
  modules: null,
  navMenu: null,
  mealPlans: {
    daysOpen: [],
    mealsOpen: [],
  },
  mode: "user", // user | dashboard
  dashboardMode: "display",
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPage(state, action) {
      const { page } = action.payload;
      state.page = page;
    },

    setEntityName(state, action) {
      const { entityName } = action.payload;
      state.entityName = entityName;
    },

    setToolbarAction(state, action) {
      const { toolbarAction } = action.payload;
      state.toolbarAction = toolbarAction;
    },

    setCalendarDate(state, action) {
      const { selectedDate } = action.payload;
      state.selectedDate = selectedDate;
    },

    setModules(state, action) {
      const { modules } = action.payload;
      state.modules = modules;
    },

    setNavMenu(state, action) {
      const { navMenu } = action.payload;
      state.navMenu = navMenu;
    },

    refreshPage(state) {
      state.refresh = uuidV4();
    },

    setIsSaving(state, action) {
      state.isSaving = action.payload;
    },

    setDaysOpen(state, action) {
      state.mealPlans.daysOpen = action.payload;
    },

    setMealsOpen(state, action) {
      state.mealPlans.mealsOpen = action.payload;
    },

    resetMealPlans(state) {
      state.mealPlans = {
        daysOpen: [],
        mealsOpen: [],
      };
    },

    setMode(state, action) {
      state.mode = action.payload;
    },

    setDashboardMode(state, action) {
      state.dashboardMode = action.payload;
    },
  },
});

export const pageActions = pageSlice.actions;
export default pageSlice.reducer;
